import { AbilityBuilder, defineAbility } from "@casl/ability";
import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import { roleAbilityTypes, roleFeatureTypes, userRoles } from "./constant";

export const ability = defineAbility((can) => {
    can(roleAbilityTypes.READ_ABILITY, roleFeatureTypes.STAFF_EDIT_FEATURE);
});

export const updateAbility = (ability, role) => {
    const { can, rules } = new AbilityBuilder();

    if (role === userRoles.ADMIN) {
        can(roleAbilityTypes.MANAGE_ABILITY, roleFeatureTypes.STAFF_EDIT_FEATURE);
    }

    if (role === (userRoles.BRANCH_ADMIN || userRoles.PHARMACY_ADMIN)) {
        can(roleAbilityTypes.MANAGE_ABILITY, [
            roleFeatureTypes.PHARMACY_EDIT,
            roleFeatureTypes.STAFF_PERSONAL_INFO_EDIT,
        ]);
        can(
            roleAbilityTypes.READ_ABILITY,
            roleFeatureTypes.STAFF_PERSONAL_INFO_GPHC_VIEW
        );
    }

    if (
        role === userRoles.PHARMACY_MANAGER ||
        role === userRoles.ADMIN ||
        role === userRoles.RELIEF ||
        role === userRoles.BRANCH_PHARMACIST ||
        role === userRoles.LOCUM
    ) {
        can(
            roleAbilityTypes.READ_ABILITY,
            roleFeatureTypes.STAFF_PERSONAL_INFO_GPHC_VIEW
        );
    }

    if (
        role === userRoles.PHARMACY_MANAGER ||
        role === userRoles.BRANCH_PHARMACIST ||
        role === userRoles.RELIEF
    ) {
        can(
            roleAbilityTypes.MANAGE_ABILITY,
            roleFeatureTypes.STAFF_PERSONAL_INFO_EDIT
        );
    }

    if (role === userRoles.LOCUM) {
        can(
            roleAbilityTypes.MANAGE_ABILITY,
            roleFeatureTypes.LOCUM_PERSONAL_INFO_EDIT
        );
    }

    if (rules.length) {
        ability.update(rules);
    }
};

export const AbilityContext = createContext(ability);
export const Can = createContextualCan(AbilityContext.Consumer);
