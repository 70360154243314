import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { EmptyData } from "../../../../../components/comon/EmptyData";
import { Heading } from "../../../../../components/UI/heading";
import { TableComponet } from "../../../../../components/UI/table";
import { getPharmacyStaffListThunk } from "../../../../../redux/super-admin/superAdminThunk";
import { notify } from "../../../../../utils/helper";

export const StaffListPage = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { pharmacyStaffList } = useSelector((state) => state.superAdmin);
  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);

  const columns = useMemo(
    () => [
      {
        Header: "STAFF ID",
        accessor: "id",
      },
      {
        Header: "FIRST NAME",
        accessor: "name",
      },

      {
        Header: "LAST NAME",
        accessor: "surname",
      },
      {
        Header: "EMAIL",
        accessor: "email",
      },
      {
        Header: "PHONE",
        accessor: "phone",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row: { original } }) => {
          return (
            <>
              <>
                {original.status === "isArchived" ? (
                  <div>
                    <span
                      className="px-4 rounded-pill py-2"
                      style={{ backgroundColor: "#FEFDE9", color: "#EE872D" }}
                    >
                      {original.status}
                    </span>
                  </div>
                ) : original.status === "active" ? (
                  <div>
                    <span
                      className="px-4 rounded-pill py-2"
                      style={{ backgroundColor: "#E8F5E9", color: "#1B5E1F" }}
                    >
                      {original.status}
                    </span>
                  </div>
                ) : original.status === "unfilled" ||
                  original.status === "pending" ? (
                  <div>
                    <span
                      className="px-4 rounded-pill py-2"
                      style={{ backgroundColor: "#FEFDE9", color: "#EE872D" }}
                    >
                      {" "}
                      {original.status}
                    </span>
                  </div>
                ) : (
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#FFEBEE", color: "#EF5350" }}
                  >
                    {original.status}
                  </span>
                )}
              </>
            </>
          );
        },
      },
    ],
    []
  );

  const {
    count: totalDataCount,
    page,
    pages: totalPagesCount,
    perPage,
    rows: pharmacyData,
  } = useMemo(() => pharmacyStaffList, [pharmacyStaffList]);

  useEffect(() => {
    if (!page && !perPage) return;
    setPerPageCount(perPage);
    setCurrentPageCount(page);
  }, [page, perPage]);

  const getPharmacyList = useCallback(
    async (values) => {
      try {
        dispatch(
          getPharmacyStaffListThunk({
            filters: {
              ...values,
            },
            id,
            pagination: { page: currentPageCount, perPage: perPageCount },
          })
        );
      } catch (error) {
        notify("error", error.message);
      }
    },
    [currentPageCount, dispatch, id, perPageCount]
  );

  useEffect(() => {
    getPharmacyList();
  }, [currentPageCount, getPharmacyList, perPageCount]);

  return (
    <>
      <div>
        <Heading
          style={{ position: "relative", right: "24px" }}
          label="Staff"
        />

        <div>
          {pharmacyStaffList?.rows?.length > 0 ? (
            <TableComponet
              rowCursor="pointer"
              isBorderShown
              currentPageCount={currentPageCount}
              perPageCount={perPageCount}
              totalDataCount={totalDataCount}
              setCurrentPage={setCurrentPageCount}
              setPerPageCount={setPerPageCount}
              showPagination
              maxPageCount={totalPagesCount}
              columns={columns}
              data={pharmacyData ?? []}
            />
          ) : (
            <div style={{ width: "100%", marginTop: "60px" }}>
              <EmptyData title="Staff" subtitle="Staff" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
