import { createAsyncThunk } from "@reduxjs/toolkit";
import { getLocumSettingData, getLocumAttendanceStats, updateLocumSettingData, getLocumAttendance, getLocumAttendanceJobs, applyJob, cancelJob, acceptJobInvite, rejectJobInvite, onMarkAttendance, getPeronalSchedule, getStaffContract, getAllNotification, getNotificationById, applyRequiredAccreditations } from "../API";

export const getLocumSettingDataThunk = createAsyncThunk("getLocumSettingData", async () => {
  const response = await getLocumSettingData()
  return response.data.data
})

export const updateLocumSettingDataThunk = createAsyncThunk("updateLocumSettingData", async (payload) => {
  const response = await updateLocumSettingData(payload)
  return response.data.data
})

export const getLocumAttendanceStatsThunk = createAsyncThunk("getLocumAttendanceStats", async (payload) => {
  const response = await getLocumAttendanceStats(payload)
  return response.data.data
})

export const getLocumAttendanceThunk = createAsyncThunk("getLocumAttendance", async (payload) => {
  const response = await getLocumAttendance(payload)
  return response.data.data
})

export const getLocumAttendanceJobsThunk = createAsyncThunk("getLocumAttendanceJobs", async (payload) => {
  const response = await getLocumAttendanceJobs(payload)
  return response.data.data
})

export const applyJobThunk = createAsyncThunk("applyJob", async (payload) => {
  const response = await applyJob(payload);
  return response.data.data;
});

export const cancelJobThunk = createAsyncThunk("cancelJob", async (payload) => {
  const response = await cancelJob(payload);
  return response.data.data;
});

export const acceptJobInviteThunk = createAsyncThunk(
  "acceptJobInvite",
  async (payload) => {
    const response = await acceptJobInvite(payload);
    return response.data.data;
  }
);

export const rejectJobInviteThunk = createAsyncThunk(
  "rejectJobInvite",
  async (payload) => {
    const response = await rejectJobInvite(payload);
    return response.data.data;
  }
);

export const onMarkAttendanceThunk = createAsyncThunk(
  "onMarkAttendance",
  async (payload) => {
    const response = await onMarkAttendance(payload);
    return response.data.data;
  }
);

export const getPeronalScheduleThunk = createAsyncThunk(
  "getPeronalSchedule",
  async (payload) => {
    const response = await getPeronalSchedule(payload);
    return response.data.data;
  }
);

export const getStaffContractThunk = createAsyncThunk(
  "getStaffContract",
  async (payload) => {
    const response = await getStaffContract(payload);
    return response.data.data;
  }
);

export const getAllNotificationThunk = createAsyncThunk(
  "getAllNotification",
  async (payload) => {
    const response = await getAllNotification(payload);
    return response.data.data;
  }
);

export const getNotificationByIdThunk = createAsyncThunk(
  "getNotificationById",
  async (payload) => {
    const response = await getNotificationById(payload);
    return response.data.data;
  }
);

export const applyRequiredAccreditationsThunk = createAsyncThunk(
  "applyRequiredAccreditations",
  async (payload) => {
    const response = await applyRequiredAccreditations(payload);
    return response.data.data;
  }
);
