import { Button, Popover } from "antd";
import { useNavigate } from "react-router-dom";
import informationIcon from "../../assets/icons/infoIcon.png";
import { BranchCard } from "../UI/cards/profileCard";
import { AddModal } from "../UI/modals/addModal";

export const UserProfileModal = ({
  showUserProfileModal,
  selectedUser,
  setShowUserProfileModal,
}) => {
  const navigate = useNavigate();
  return (
    <AddModal
      title=""
      handleModal={showUserProfileModal}
      secondaryButtonText="Cancel"
      setHandleModal={() => setShowUserProfileModal(false)}
      secondaryButtonHandler={() => setShowUserProfileModal(false)}
    >
      <div className="d-flex flex-column gap-3 align-items-center">
        <BranchCard
          logo={selectedUser?.image}
          primaryText={selectedUser?.name + " " + selectedUser?.surname}
          secondaryText={
            selectedUser.roleTitle
              ? selectedUser.roleTitle
              : selectedUser?.userRole.label
          }
        />
        <Button
          onClick={() =>
            navigate(`/staff/${selectedUser?.id}/details/information`)
          }
          type="primary"
          size="large"
          style={{ width: "100%" }}
        >
          View Profile
        </Button>
      </div>
      <div className="d-flex ">
        <div
          className="d-flex flex-column gap-4 mt-5"
          style={{ minWidth: "45%" }}
        >
          <div className="d-flex flex-column gap-2">
            <div className="p3">Hours Worked this Week</div>
            <div className="b3 fw-bolder">
              {selectedUser?.stats?.weeklyHours || "-"}
            </div>
          </div>
          <div className="d-flex flex-column gap-2">
            <div className="p3">Hours Worked this Month</div>
            <div className="b3 fw-bolder">
              {selectedUser?.stats?.monthlyHours || "-"}
            </div>
          </div>
          <div className="d-flex flex-column gap-2">
            <div className="p3">Absent Days this Month</div>
            <div className="b3 fw-bolder">
              {selectedUser?.absenceCount || "0"}
            </div>
          </div>
        </div>
        <div className="mt-5 text-align-center" style={{ minWidth: "10%" }}>
          <div
            className="border border-3 "
            style={{ width: 3, height: "100%" }}
          />
        </div>
        <div
          className="d-flex flex-column gap-2  mt-5"
          style={{ minWidth: "45%" }}
        >
          <div className="p3 d-flex align-items-center gap-2">
            <div>Over Time Hours</div>
            <Popover
              overlayStyle={{ zIndex: 3000, width: 300 }}
              content="If you're unable to see overtime numbers this may be because you do not have an active contract assigned to this individual or they may not have any accumulated overtime yet."
              placement="left"
            >
              <img
                width={15}
                className="cursor-pointer"
                src={informationIcon}
                alt=""
              />
            </Popover>
          </div>
          <div className="b3 fw-bolder">
            {selectedUser?.stats.overTime || "-"}
          </div>
        </div>
      </div>
    </AddModal>
  );
};
