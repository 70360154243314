import { createAsyncThunk } from "@reduxjs/toolkit";
import { exportGapsCSV, getPendingJobApprovalData, getPharmacyGapsData, pharmacyDashboard } from "../dashboardAPI";


export const getPharmacyDashboardDetailThunk = createAsyncThunk("pharmacyDashboard", async (payload) => {
    const request = await pharmacyDashboard(payload)
    return request.data.data
})
export const getPharmacyGapsDataThunk = createAsyncThunk("getPharmacyGapsData", async (payload) => {
    const request = await getPharmacyGapsData(payload)
    return request.data.data
})
export const getPendingJobDetailThunk = createAsyncThunk("getPendingJobApprovalDetail", async (payload) => {
    const request = await getPendingJobApprovalData(payload)
    return request.data.data
})


export const exportGapsCSVThunk = createAsyncThunk("exportGapsCSV", async (payload) => {
    const request = await exportGapsCSV(payload)
    return request.data
})
