import React, { useMemo } from "react";
import crossIcon from "../../assets/icons/cross.png";
import checkIcon from "../../assets/icons/tick.png";
import editIcons from "../../assets/icons/edit.png";
import { ConfirmModal } from "../UI/modals/confirmModal";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateMileageThunk } from "../../redux/locum-shifts/locumShiftsThunk";
import { useParams } from "react-router-dom";
import { Form, Input } from "antd";

const Tooltip = ({ data }) => {
  return (
    <div
      className="d-flex flex-column gap-1 position-absolute p-3 shadow"
      style={{ width: 300, background: "white", right: 25, top: -5, zIndex: 1 }}
    >
      {Object.entries(data).map(([key, value]) => {
        return (
          <div className="d-flex justify-content-between align-items-center">
            <div>{key}</div>
            <div>{value}</div>
          </div>
        );
      })}
    </div>
  );
};

export const CoveredExpense = ({ shiftDetails, editExpense }) => {
  const [form] = Form.useForm();
  const formId = useMemo(() => "cancelForm", []);

  const dispatch = useDispatch();
  const { shiftId } = useParams();

  const [showExpenseModal, setShowExpenseModal] = useState(false);

  useEffect(() => {
    if (!shiftDetails) return;
    form.setFieldsValue({
      mileageValue: shiftDetails?.mileageValue,
      accommodationValue: shiftDetails?.accommodationValue,
      parkingValue: shiftDetails?.parkingValue,
      transportValue: shiftDetails?.transportValue,
    });
  }, [shiftDetails]);

  const onUpdateMileageValue = (values) => {
    const formData = {
      accommodationValue: Number(values?.accommodationValue),
      mileageValue: Number(values?.mileageValue),
      parkingValue: Number(values?.parkingValue),
      transportValue: Number(values?.transportValue),
      jobId: shiftId,
    };
    dispatch(
      updateMileageThunk({
        jobId: shiftId,
        formData,
      })
    ).then((res) => {
      if (res.payload) {
        setShowExpenseModal(false);
      }
    });
  };

  return (
    <>
      <div
        className="p-5 d-flex flex-column  border-bottom"
        style={{ gap: 29, width: "100%" }}
      >
        <div className="h5">
          Covered Expenses
          {(shiftDetails?.mileageRuleId ||
            shiftDetails?.accommodationAndFoodRuleId ||
            shiftDetails?.parkingRuleId ||
            shiftDetails?.transportRuleId) &&
            (shiftDetails?.status !== "paid" ||
              shiftDetails?.status !== "payment_approved" ||
              shiftDetails?.status !== "cancelled") &&
            editExpense && (
              <img
                className="cursor-pointer ms-2"
                width={10}
                src={editIcons}
                onClick={() => setShowExpenseModal(!showExpenseModal)}
                alt="edit"
              />
            )}
        </div>
        <div className="d-flex flex-wrap" style={{ width: "100%" }}>
          <div
            className="d-flex py-2 align-items-center"
            style={{ gap: 10, width: "33%" }}
          >
            <img
              width={35}
              src={shiftDetails?.paidLunch ? checkIcon : crossIcon}
              alt="contact"
            />
            <div className="d-flex flex-column gap-1">
              <div className="p3">Paid Lunch</div>
            </div>
          </div>
          <div
            className="d-flex py-2 align-items-center"
            style={{ gap: 10, width: "33%" }}
          >
            <img
              width={35}
              src={shiftDetails?.mileageRuleId ? checkIcon : crossIcon}
              alt="contact"
            />
            <div className="d-flex flex-column gap-1">
              <div className="p3">Mileage</div>
              {shiftDetails?.mileageRuleId && (
                <div className="b1">
                  £
                  {shiftDetails?.mileageExpense < 0
                    ? 0
                    : shiftDetails?.mileageExpense}
                </div>
              )}
            </div>
          </div>
          <div
            className="d-flex py-2 align-items-center"
            style={{ gap: 10, width: "33%" }}
          >
            <img
              width={35}
              src={
                shiftDetails?.accommodationAndFoodRuleId ? checkIcon : crossIcon
              }
              alt="contact"
            />
            <div className="d-flex flex-column gap-1">
              <div className="p3">Accommodation</div>
              {shiftDetails?.accommodationAndFoodRuleId && (
                <div className="b1">£{shiftDetails?.accommodationValue}</div>
              )}
            </div>
          </div>
          <div
            className="d-flex py-2 align-items-center"
            style={{ gap: 10, width: "33%" }}
          >
            <img
              width={35}
              src={shiftDetails?.parkingRuleId ? checkIcon : crossIcon}
              alt="contact"
            />
            <div className="d-flex flex-column gap-1">
              <div className="p3">Parking</div>
              {shiftDetails?.parkingRuleId && (
                <div className="b1">£{shiftDetails?.parkingValue}</div>
              )}
            </div>
          </div>
          <div
            className="d-flex py-2 align-items-center"
            style={{ gap: 10, width: "33%" }}
          >
            <img
              width={35}
              src={shiftDetails?.transportRuleId ? checkIcon : crossIcon}
              alt="contact"
            />
            <div className="d-flex flex-column gap-1">
              <div className="p3">Transport</div>
              {shiftDetails?.transportRuleId && (
                <div className="b1">£{shiftDetails?.transportValue}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      {
        <ConfirmModal
          title={"Edit Cover Expenses"}
          open={showExpenseModal}
          cancelButtonText="Cancel"
          confirmButtonText="Update"
          onClose={() => setShowExpenseModal(false)}
          formId={formId}
        >
          <Form
            form={form}
            id={formId}
            layout="vertical"
            onFinish={onUpdateMileageValue}
            autoComplete="off"
          >
            <Form.Item name="mileageValue" hidden={!shiftDetails?.mileageRuleId} label="Mileage">
              <Input
                onBlur={(e) =>
                  form.setFieldsValue({ mileageValue: e.target.value.trim() })
                }
                type="number"
                placeholder="Mileage"
                className="text-input-field p-2 pe-3 t1"
              />
            </Form.Item>
            <Form.Item name="accommodationValue" hidden={!shiftDetails?.accommodationAndFoodRuleId} label="Accommodation">
              <Input
                onBlur={(e) =>
                  form.setFieldsValue({
                    accommodationValue: e.target.value.trim(),
                  })
                }
                type="number"
                placeholder="Accommodation"
                className="text-input-field p-2 pe-3 t1"
              />
            </Form.Item>
            <Form.Item name="parkingValue" hidden={!shiftDetails?.parkingRuleId} label="Parking">
              <Input
                onBlur={(e) =>
                  form.setFieldsValue({ parkingValue: e.target.value.trim() })
                }
                type="number"
                placeholder="Parking"
                className="text-input-field p-2 pe-3 t1"
              />
            </Form.Item>
            <Form.Item name="transportValue" hidden={!shiftDetails?.transportRuleId} label="Transport">
              <Input
                type="number"
                onBlur={(e) =>
                  form.setFieldsValue({
                    transportValue: e.target.value.trim(),
                  })
                }
                placeholder="Transport"
                className="text-input-field p-2 pe-3 t1"
              />
            </Form.Item>
          </Form>
          <div className="mb-2" />
        </ConfirmModal>
      }{" "}
    </>
  );
};
