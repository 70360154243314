import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Outlet } from "react-router-dom";
import { BranchCard } from "../../../components/UI/cards/profileCard";
import { ContentContainer } from "../../../components/UI/container";
import { Sidebar } from "../../../components/UI/sideBar";
import { getProfileDataThunk } from "../../../redux/profile/profileThunk";
import { RouterConfig } from "../../../routerConfig";

export const ProfileDetail = () => {
  const dispatch = useDispatch();
  const { profileInfo } = useSelector((state) => state.profile);

  useEffect(() => {
    dispatch(getProfileDataThunk());
  }, [dispatch]);

  const sidebarLinks = useMemo(
    () => [
      {
        text: "Personal Information",
        to: generatePath(RouterConfig.branchAdminProfileInfo),
        disabled: false,
        hidden: false,
      },
    ],
    []
  );
  return (
    <div className="content-view-page details-page">
      <div className="main-wrapper p-5">
        <div className="content-holder">
          <Sidebar links={sidebarLinks} visible>
            <BranchCard
              logo={profileInfo?.image}
              primaryText={`${profileInfo?.name} ${profileInfo?.surname}`}
              secondaryText={profileInfo?.label}
            />
          </Sidebar>
          <ContentContainer className="content-container">
            <Outlet />
          </ContentContainer>
        </div>
      </div>
    </div>
  );
};
