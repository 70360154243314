import React from "react";
import "./style.scss";
// import "./style.scss";

export const DetailTab = (params) => {
  return (
    <div
      className="detail-tab-container"
      style={{ backgroundColor: params.color }}
    >
      <span className="b1" style={{ marginBottom: "12px" , whiteSpace:"nowrap" }}>
        {params.label || " Label"}
      </span>
      {params.content ? (
        <>{params.content}</>
      ) : (
        <>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{
              width: "100%",
            }}
          >
            <div className="d-flex align-items-center">
              <span className="heading2 fw-bold">
                {params.count1 ? params.count1 : params.count}
              </span>
              {params.percentage && (
                <div className="breadCrumps-detail-box">
                  <i className="arrow up icon"></i>
                  <span>{params.percentage} </span>
                </div>
              )}
              {params.role1 && (
                <div
                  className=" t2 breadCrumps-role-detail"
                  style={{ color: "#7B7B7B", marginLeft: "5px" }}
                >
                  {params.role1}
                </div>
              )}
            </div>
            {params.count2 ? <div className="center-border"></div> : ""}

            <div className="d-flex align-items-center">
              <span className="heading2 fw-bold">{params.count2}</span>

              {params.role2 && (
                <div
                  className=" t2 breadCrumps-role-detail"
                  style={{ color: "#7B7B7B", marginLeft: "5px" }}
                >
                  {params.role2}
                </div>
              )}
            </div>
            {params.count3 && <div className="center-border"></div>}
            <div className="d-flex align-items-center ">
              <span className="heading2">{params.count3}</span>

              {params.role3 && (
                <div
                  className=" t2 breadCrumps-role-detail"
                  style={{ color: "#7B7B7B", marginLeft: "5px" }}
                >
                  {params.role3}
                </div>
              )}
            </div>
          </div>

          {/* <div>
            <img src={syncIcon} alt=" " style={{ cursor: "pointer" }} />
            <span
              className="t2"
              style={{ color: "#7B7B7B", marginLeft: "5px" }}
            >
              Last Updated 10 min ago
            </span>
          </div> */}
        </>
      )}
    </div>
  );
};
