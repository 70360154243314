import { Input } from "antd";
import React from "react";
const TimeInput = ({ value, onChange }) => {
  const handleOnchnage = (e) => {
    e.stopPropagation();
    const input = e.target.value;
    const digitsOnly = input.replace(/\D/g, ""); // remove non-digits
    let formattedTime = "";
    if (digitsOnly.length < 3) {
      formattedTime = digitsOnly;
    } else if (digitsOnly.length === 3) {
      formattedTime = `${digitsOnly[0]}:${digitsOnly.substring(1, 3)}`;
    } else if (digitsOnly.length > 3 && digitsOnly.length < 5) {
      const hours = digitsOnly.substring(0, 2);
      const minutes = digitsOnly.substring(2, 4);
      if (hours > 23 && minutes > 59) {
        formattedTime = "23:59";
      } else if (hours > 23) {
        formattedTime = `23:${minutes}`;
      } else if (minutes > 59) {
        formattedTime = `${hours}:59`;
      } else {
        formattedTime = `${hours}:${minutes}`;
      }
    } else if (digitsOnly.length < 7) {
      formattedTime = `${digitsOnly.substring(0, 2)}:${digitsOnly.substring(
        2,
        4
      )}-${digitsOnly.substring(4, 6)}`;
    } else if (digitsOnly.length === 7) {
      formattedTime = `${digitsOnly.substring(0, 2)}:${digitsOnly.substring(
        2,
        4
      )}-${digitsOnly.substring(4, 5)}:${digitsOnly.substring(5, 7)}`;
    } else if (digitsOnly.length > 7 && digitsOnly.length < 8) {
      const hours = digitsOnly.substring(4, 6);
      const minutes = digitsOnly.substring(6, 8);
      if (hours > 23 && minutes > 59) {
        formattedTime = `${digitsOnly.substring(0, 2)}:${digitsOnly.substring(
          2,
          4
        )}-23:59`;
      } else if (hours > 23) {
        formattedTime = `${digitsOnly.substring(0, 2)}:${digitsOnly.substring(
          2,
          4
        )}-23:${minutes}`;
      } else if (minutes > 59) {
        formattedTime = `${digitsOnly.substring(0, 2)}:${digitsOnly.substring(
          2,
          4
        )}-${hours}:59`;
      } else {
        formattedTime = `${digitsOnly.substring(0, 2)}:${digitsOnly.substring(
          2,
          4
        )}-${hours}:${minutes}`;
      }
    } else {
      const hours = digitsOnly.substring(4, 6);
      const minutes = digitsOnly.substring(6, 8);
      if (hours > 23 && minutes > 59) {
        formattedTime = `${digitsOnly.substring(0, 2)}:${digitsOnly.substring(
          2,
          4
        )}-23:59`;
      } else if (hours > 23) {
        formattedTime = `${digitsOnly.substring(0, 2)}:${digitsOnly.substring(
          2,
          4
        )}-23:${minutes}`;
      } else if (minutes > 59) {
        formattedTime = `${digitsOnly.substring(0, 2)}:${digitsOnly.substring(
          2,
          4
        )}-${hours}:59`;
      } else {
        formattedTime = `${digitsOnly.substring(0, 2)}:${digitsOnly.substring(
          2,
          4
        )}-${hours}:${minutes}`;
      }
    }

    onChange(formattedTime);
  };
  return (
    <Input
      className="text-input-field px-3 pe-3 t1"
      placeholder="Eg: 01:00 - 15:00"
      value={value}
      onChange={handleOnchnage}
    />
  );
};

export default TimeInput;
