import { Form, Space, Typography } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { editProfileDataThunk } from "../../redux/profile/profileThunk";
import { prepareFilePreview } from "../../utils/document";
import { notify } from "../../utils/helper";
import CustomIconButton from "../UI/IconButton/IconButton";
import EditIcon2 from "../UI/icons/EditIcon2";
import CommonModal from "../UI/modals/CommonModal/CommonModal";
import CustomAvatar from "../comon/CustomAvatar";
import { FlexRow } from "../comon/flexRows";
import PersonalInfoAddEditModal, {
  countryList,
} from "./PersonalInfoAddEditModal";

const PersonalInfoSection = ({ details, onSuccess }) => {
  const [form] = Form.useForm();
  const image = Form.useWatch("image", form);
  const [showModal, setshowModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!details) return;

    showModal &&
      form.setFieldsValue({
        name: details?.name,
        surname: details?.surname,
        gphcNumber: details?.gphcNumber,
        dob: details?.dob ? dayjs(details?.dob, '"YYYY-MM-DD"') : null,
        phone: details?.phone,
        email: details?.email,
        address1: details?.address1,
        address2: details?.address2,
        countryId: details?.countryId,
        city: details?.city,
        zipCode: details?.zipCode,
        image: prepareFilePreview(details?.image),
      });
  }, [details, showModal]);

  const handleModal = () => {
    setshowModal(!showModal);
  };

  const submitForm = () => {
    form.validateFields().then((data) => {
      const {
        name,
        surname,
        dob,
        phone,
        email,
        address1,
        address2,
        countryId,
        city,
        zipCode,
      } = form.getFieldValue();

      const formData = new FormData();

      const reqBody = {
        name,
        surname,
        dob: dayjs(dob).format("YYYY-MM-DD"),
        phone,
        email,
        address1,
        address2,
        countryId,
        city,
        zipCode,
        id: details?.id,
      };

      delete reqBody["email"];

      for (const key in reqBody) {
        if (reqBody.hasOwnProperty(key)) {
          formData.append(key, reqBody[key]);
        }
      }

      formData.append(
        "image",
        !image || image?.file?.status === "removed"
          ? ""
          : image.file
          ? image.file
          : image?.fileList[0].url
      );

      dispatch(editProfileDataThunk(formData)).then((res) => {
        if (!!res?.payload) {
          notify("success", "Personal info edited sucessfully");
          onSuccess();
          handleModal();
        }
      });
    });
  };

  const handleAddressSelect = async ({ name, data: address }) => {
    form.setFieldsValue({
      ...form,
      address1: address.line_1,
      address2: address.line_2 ?? "",
      latitude: address.latitude,
      longitude: address.longitude,
      city: address.town_or_city,
      zipCode: address.postcode,
    });
  };

  const findCountry = useMemo(() => {
    if (!!details?.countryId) {
      const country = countryList?.find((i) => i?.value === details?.countryId);
      return country?.label;
    }
    return "";
  }, [details?.countryId]);

  return (
    <>
      <div className="personal-info-wrapper">
        <div className="header">
          <Typography.Title level={4}>‍🧑🏻‍💻 Personal Info</Typography.Title>
          <CustomIconButton icon={<EditIcon2 />} onClick={handleModal} />
        </div>

        <div className="info-list-wrap">
          <FlexRow
            primaryTitle="Full Name"
            primaryDescription={
              <Space size={4} className="full_name">
                <CustomAvatar
                  size="small"
                  alt={details?.name}
                  src={details?.image}
                />

                <p>
                  {details?.name ? details?.name : "-"}{" "}
                  {details?.surname ? details?.surname : "-"}
                </p>
              </Space>
            }
          />
          <FlexRow
            primaryTitle="Date of Birth"
            primaryDescription={details?.dob ? details?.dob : "-"}
          />
          <FlexRow
            primaryTitle="Phone Number"
            primaryDescription={details?.phone || "-"}
          />
          <FlexRow
            primaryTitle="Email Address"
            primaryDescription={details?.email || "-"}
          />
          <FlexRow
            primaryTitle="Address Line 1"
            primaryDescription={details?.address1 || "-"}
          />
          <FlexRow
            primaryTitle="Address Line 2"
            primaryDescription={details?.address2 || "-"}
          />
          <FlexRow
            primaryTitle="Country"
            primaryDescription={findCountry || "-"}
          />
          <FlexRow
            primaryTitle="City"
            primaryDescription={details?.city || "-"}
          />
          <FlexRow
            primaryTitle="Postal Code"
            primaryDescription={details?.zipCode || "-"}
          />
        </div>
      </div>

      <CommonModal
        handleModal={showModal}
        setHandleModal={handleModal}
        title="Personal Information"
        secondaryButtonText="Cancel"
        primaryButtonText="Save"
        primaryButtonHandler={submitForm}
        secondaryButtonHandler={handleModal}
      >
        <Form
          // onFinish={onFinish}
          form={form}
          // onValuesChange={onValuesChange}
          layout="vertical"
          autoComplete="off"
        >
          <PersonalInfoAddEditModal
            form={form}
            handleAddressSelect={handleAddressSelect}
            image={image}
          />
        </Form>
      </CommonModal>
    </>
  );
};

export default PersonalInfoSection;
