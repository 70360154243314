import React from "react";
import { AddModal } from "../UI/modals/addModal";
import { useDispatch } from "react-redux";
import { downloadJobsCSVThunk } from "../../redux/locum-shifts/locumShiftsThunk";
import moment from "moment/moment";
import { Checkbox, DatePicker, Form, Select } from "antd";
import { useMemo } from "react";
import dayjs from "dayjs";

export const DownloadJobsFileModal = ({
    showModal,
    handleModalToggle,
}) => {
    const [form] = Form.useForm();
    const formId = useMemo(() => 'downloadCSVForm', [])
    const dispatch = useDispatch()
    const dateFrom = Form.useWatch("dateFrom", form);
    const dateTo = Form.useWatch("dateTo", form);


    const JobStatuses = [
        { value: 'active', label: 'Active' },
        { value: 'unfilled', label: 'Unfilled' },
        { value: 'pending', label: 'Pending' },
        { value: 'cancelled', label: 'Cancelled' },
        { value: 'paid', label: 'Paid' },
        { value: 'payment_approved', label: 'Payment' },
        { value: 'completed', label: 'Completed' },
    ]

    const branchDetailsCheckboxItems = [
        {
            label: "Branch Name",
            value: `branchName`,
        },
        {
            label: "Branch Number",
            value: `branchNumber`,
        },
        {
            label: "Branch Address",
            value: `branchAddress`,
        },
    ];

    const locumDetailsCheckboxItems = [
        {
            label: "Locum Name",
            value: `locumName`,
        },
        {
            label: "Locum GPhC Number",
            value: `locumGPHCNumber`,
        },
        {
            label: "Locum Phone Number",
            value: `locumPhoneNumber`,
        },
        {
            label: "Locum Address",
            value: `locumAddress`,
        },
    ];

    const jobDetailsCheckboxItems = [
        {
            label: "Job Date",
            value: `jobDate`,
        },
        {
            label: "Job Time",
            value: `jobTime`,
        },
        {
            label: "Job Status",
            value: `jobStatus`,
        },
        {
            label: "Hourly Rate",
            value: `hourlyRate`,
        },
        {
            label: "Mileage Expense",
            value: `mileageExpense`,
        },
        {
            label: "Accommodation & Food Expense",
            value: `accommodation`,
        },
        {
            label: "Transport Expense",
            value: `transport`,
        },
        {
            label: "Parking Expense",
            value: `parking`,
        },
        {
            label: "Total Expense Amount",
            value: `totalExpenseAmount`,
        },
        {
            label: "Total Amount",
            value: `totalAmount`,
        },

    ];

    const onDownloadJobsCSV = (values) => {
        const reqData = {
            ...values,
            columns: [...values?.columns1, ...values?.columns2, ...values?.columns3],
            dateFrom: moment(values?.dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(values?.dateTo).format('YYYY-MM-DD'),
        }
        delete reqData['columns1']
        delete reqData['columns2']
        delete reqData['columns3']
        dispatch(downloadJobsCSVThunk({
            formData: {
                filters: {
                    ...reqData
                }
            }
        })).then(res => {
            if (res.payload.status === "success") {
                form.resetFields()
                let fetchDataModified = `data:application/csv;base64,${res.payload.data}`;
                let a = document.createElement('a');
                a.href = fetchDataModified;
                a.download = 'result.csv';
                a.click();
                handleModalToggle();
                return;
            }
        })
    }

    return (
        <AddModal
            title={`Download All Jobs as CSV`}
            handleModal={showModal}
            setHandleModal={handleModalToggle}
            primaryButtonText={`Download`}
            formId={formId}
            secondaryButtonText="Cancel"
            secondaryButtonHandler={handleModalToggle}
        >
            <Form
                onFinish={onDownloadJobsCSV}
                form={form}
                layout="vertical"
                id={formId}
                autoComplete="off"
            >
                <div className="d-flex align-items-center gap-4" style={{ color: "#7B7B7B", width: "100%" }}>
                    <Form.Item
                        label="Start Date"
                        name="dateFrom"
                    >
                        <DatePicker
                            inputReadOnly
                            format="DD-MM-YYYY"
                            placeholder="Start Date"
                            getPopupContainer={(trigger) => trigger.parentElement}
                            className="text-input-field p-2 pe-3 t1"
                            style={{ width: '100%' }}
                            disabledDate={(current) => {
                                return (
                                    dayjs(dateTo) <= current
                                );
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="End Date"
                        name="dateTo"
                    >
                        <DatePicker
                            inputReadOnly
                            placeholder="End Date"
                            format="DD-MM-YYYY"
                            getPopupContainer={(trigger) => trigger.parentElement}
                            className="text-input-field p-2 pe-3 t1"
                            style={{ width: '100%' }}
                            disabledDate={(current) => {
                                return (
                                    dayjs(dateFrom) >= current
                                );
                            }}
                        />
                    </Form.Item>
                </div>
                <Form.Item
                    label="Jobs Status"
                    name="statuses"
                >
                    <Select
                        className="text-input-field p-1 pe-3 t1"
                        bordered={false}
                        mode="multiple"
                        options={JobStatuses}
                        dropdownStyle={{ zIndex: 2000 }}
                        placeholder="Select Status"
                    />
                </Form.Item>
                <div>Select details of shifts to be present in the CSV document.</div>
                <Form.Item
                    label="Branch Details"
                    name="columns1"
                >
                    <Checkbox.Group style={{ display: 'flex', flexDirection: 'column' }} options={branchDetailsCheckboxItems} />
                </Form.Item>
                <Form.Item
                    label="Locum Details"
                    name="columns2"
                >
                    <Checkbox.Group style={{ display: 'flex', flexDirection: 'column' }} options={locumDetailsCheckboxItems} />
                </Form.Item>
                <Form.Item
                    label="Job Details"
                    name="columns3"
                >
                    <Checkbox.Group style={{ display: 'flex', flexDirection: 'column' }} options={jobDetailsCheckboxItems} />
                </Form.Item>
            </Form>
        </AddModal>
    );
};
