import * as moment from "moment";

export const getDuration = (startTime, endTime) => {
  // if (startTime && endTime && typeof startTime === "object") {
  //     startTime.set({ second: 0 });
  //     endTime.set({ second: 0 });
  // }

  return endTime?.diff(startTime, "minutes");
};

export const getLunchHours = (startTime, endTime) => {
  const diffHours = endTime.diff(startTime, "hours");
  const lunchTimeStart = startTime.clone().add(diffHours / 2, "hours");
  const lunchTimeFinish = lunchTimeStart.clone().add(1, "hours");

  return {
    lunchTimeStart,
    lunchTimeFinish,
  };
};

export const getDurationFormat = (duration, isSpaceSeparator = false) => {
  let h = Math.floor(duration / 60);
  let m = duration % 60;
  // const separator = isSpaceSeparator ? " " : ":";
  h = h >= 0 ? h : "0";
  m = m < 10 && m >= 0 ? "0" + m : m > 0 ? m : "0";

  return `${h}h ${m}m`;
};

export const getShiftCellDurationSize = (branchEndWorkingTime, endTime) => {
  if (branchEndWorkingTime && endTime) {
    branchEndWorkingTime.set({ second: 0 });
    endTime.set({ second: 0 });
  }

  const timeDiffGap = moment(branchEndWorkingTime, "HH:mm").diff(
    moment(endTime, "HH:mm"),
    "minutes"
  );

  if (timeDiffGap < 120) {
    return "width-100";
  } else if (timeDiffGap >= 120 && timeDiffGap < 240) {
    return "width-80";
  } else if (timeDiffGap >= 240 && timeDiffGap < 360) {
    return "width-60";
  } else if (timeDiffGap >= 360 && timeDiffGap < 480) {
    return "width-40";
  } else if (timeDiffGap >= 480 && timeDiffGap < 600) {
    return "width-20";
  }
};

export const weekDays = Array.apply(null, Array(7)).map(function (_, i) {
  return moment(i, "e")
    .startOf("week")
    .isoWeekday(i + 1)
    .format("dddd")
    .toLowerCase();
});

export const sortDays = function (a, b, daysArray) {
  a = daysArray.indexOf(a);
  b = daysArray.indexOf(b);
  return a < b ? 0 : 1;
};

export const parseTime = (timeStr) => {
  let parts,
    hours,
    minutes,
    date,
    d = new Date().getTime(),
    time = timeStr,
    timeReg = /(\d+):(\d+)/;

  parts = time.match(timeReg);

  hours = parseInt(parts[1], 10);
  minutes = parseInt(parts[2], 10);

  date = moment(d).toDate();

  date.setHours(hours);
  date.setMinutes(minutes);

  return date;
};

export const getCurrentWeekDays = (
  startDate,
  weekStartFormat,
  outputFormat
) => {
  const weekStart = moment(startDate).startOf("isoWeek").format("MM-DD-YYYY");
  const days = [];
  for (let i = 0; i <= 6; i++) {
    days.push(
      moment(weekStart, "MM-DD-YYYY").add(i, "days").format(outputFormat)
    );
  }

  return days;
};

export const getAllMonthDays = (startDate, endDate, outputFormat) => {
  const monthStart = moment(startDate).startOf("month").format("MM-DD-YYYY");
  const monthEnd = moment(endDate).endOf("month").format("MM-DD-YYYY");
  const days = [];

  for (
    let i = 0;
    i <=
    moment(monthEnd, "MM-DD-YYYY").diff(
      moment(monthStart, "MM-DD-YYYY"),
      "days"
    );
    i++
  ) {
    days.push(
      moment(monthStart, "MM-DD-YYYY").add(i, "days").format(outputFormat)
    );
  }

  return days;
};

export const getShiftDates = (date, period, isEditMode = false) => {
  const day = 864e5; // 864e5 == 86400000 == 24*60*60*1000 = 1 day
  let startDate = new Date();
  let endDate = new Date();
  let initDate = date
    ? moment(date).set("hour", 0)
    : moment().set("hour", 0).set("minute", 0);

  if (period === "start") {
    if (initDate && moment(initDate).isSameOrAfter(moment())) {
      startDate = new Date(initDate);
    } else {
      startDate = new Date(Date.now() - day);
    }

    return startDate;
  }

  if (period === "end") {
    if (initDate && !moment(initDate, "YYYY-MM-DD").isBefore(moment())) {
      endDate = new Date(initDate);
    }
    if (
      initDate &&
      !moment(initDate, "YYYY-MM-DD").isBefore(moment()) &&
      isEditMode
    ) {
      endDate = new Date(initDate - day);
    }

    endDate = new Date(initDate);

    return endDate;
  }
};

export const validateShiftSubmit = ({
  startDate,
  endDate,
  withEndDate,
  timeStart,
  timeFinish,
  duration,
  paidLunch,
  lunchTimeStart,
  lunchTimeFinish,
  lunchDuration,
  selectedDays,
}) => {
  if (
    !startDate ||
    !moment(startDate, "YYYY-MM-DD").isSameOrAfter(
      moment().format("YYYY-MM-DD")
    ) ||
    duration <= 0 ||
    (paidLunch && lunchDuration <= 0) ||
    (withEndDate && !endDate) ||
    (lunchTimeStart &&
      !(
        moment(lunchTimeStart).isAfter(moment(timeStart)) &&
        moment(lunchTimeFinish).isBefore(moment(timeFinish))
      )) ||
    selectedDays.length === 0
  ) {
    return true;
  } else return false;
};

export const getDateRange = (startDate, endDate) => {
  const result = [];
  for (
    let m = moment(startDate);
    m.diff(endDate, "days") <= 0;
    m.add(1, "days")
  ) {
    const date = m.format("YYYY-MM-DD");
    result.push(date);
  }
  return result;
};

const getSplittedEvents = (events) => {
  const result = [];
  events.forEach((event) => {
    const { startDate, endDate } = event;
    if (moment(endDate).isAfter(moment(startDate))) {
      result.push(
        Object.assign(
          {},
          {
            ...event,
            startDate: moment(startDate).add(1, "days").format("YYYY-MM-DD"),
            endDate: moment(startDate).add(1, "days").format("YYYY-MM-DD"),
          }
        )
      );
    }
    result.push({
      ...event,
      endDate: startDate,
    });
  });
  return result;
};

const getDateJobs = (jobs, date) => {
  return jobs.filter(({ startDate }) => startDate === date);
};

const groupEventsByDate = (arr) =>
  arr.reduce((acc, current) => {
    const x = acc.find((item) => item.startDate === current.startDate);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

export const getCalendarEvents = (startDate, endDate, events) => {
  const dateRangeArray = getDateRange(startDate, endDate);
  const splitLognTermEvents = getSplittedEvents(events);
  const getEventDays = dateRangeArray.flatMap((date) =>
    splitLognTermEvents.filter(({ startDate }) => startDate === date)
  );
  const getNestedEventObjects = getEventDays.map((evt) => ({
    startDate: evt.startDate,
    endDate: evt.endDate,
    jobs: getDateJobs(getEventDays, evt.startDate),
  }));
  const getGroupedEvents = groupEventsByDate(getNestedEventObjects);
  return getGroupedEvents;
};

export const getTotalWorkingHours = (startTime, endTime) => {
  if (!startTime || !endTime) {
    return "0h 0m";
  }

  const _startTime = moment(startTime, "HH:mm");
  const _endTime = moment(endTime, "HH:mm");
  // Calculate the duration
  const duration = moment.duration(_endTime.diff(_startTime));
  // Extract hours and minutes from the duration
  const hours = duration.hours();
  const minutes = duration.minutes();
  // Format the result as "Xh Ym"
  const formattedResult = `${Number(hours)}h ${Number(minutes)}m`;
  return formattedResult;
};

export const getTotalMinutes = (startTime, endTime) => {
  if (startTime?.length && endTime?.length) {
    const _startTime = moment(startTime, "HH:mm");
    const _endTime = !!endTime ? moment(endTime, "HH:mm") : moment();
    // Calculate the duration
    const durationinMin = moment.duration(_endTime.diff(_startTime, "minutes"));

    return Number(durationinMin);
  }

  return 0;
};

export const minuteTohour = (min, format) => {
  const duration = moment.duration(min, "minutes");
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  if (format === "type1") {
    return `${hours}:${minutes}`;
  }

  return `${hours}h ${minutes}m`;
};
