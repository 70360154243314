import {
  Avatar,
  Button,
  Col,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import StarIcon from '../UI/icons/StarIcon';
import VerifiedIcon from '../UI/icons/VerofiedIcon';
import { ConfirmModal } from '../UI/modals/confirmModal';

const AssignedlocumCard = ({
  jobId,
  staffDetails,
  status,
  handleAcceptJobRequest,
  handleRejectJobRequest,
}) => {
  const [openAcceptModal, setopenAcceptModal] = useState(false);

  return staffDetails?.map((staff) =>
    staff.status === 'applied' ? (
      <div className="assign-locum-cards mt-4">
        <Typography.Title level={5}>Assigned Locum</Typography.Title>
        <div className="assigned-locum-crd">
          <div className="w-100 pb-3">
            <Space className="card-left pending-card">
              {staff?.image ? (
                <Avatar
                  icon={<img src={staff?.image} alt={staff?.name} />}
                  size={48}
                />
              ) : (
                <Avatar size={48}>{staff?.name.slice(0, 1)}</Avatar>
              )}
              <div className="button-wrap">
                <Button
                  className="custom_btn outlined"
                  onClick={() => handleRejectJobRequest(jobId, staff.id)}
                >
                  Decline
                </Button>
                <Button
                  className="custom_btn "
                  onClick={() => setopenAcceptModal(true)}
                >
                  Accept
                </Button>
              </div>
            </Space>
            <div className="locum-details pending">
              <Space>
                <p className="locum-name">{`${staff?.name} ${staff?.surname}`}</p>
                <Tooltip title="ID Verified">
                  <i>
                    <VerifiedIcon />
                  </i>
                </Tooltip>
              </Space>
              <Space size={1} className="card-right">
                <StarIcon />{' '}
                <Typography.Text className="rating">
                  {Number(staff.rating).toFixed(2)}
                </Typography.Text>
              </Space>
            </div>
          </div>

          <Row gutter={[12, 12]} style={{ width: '100%' }}>
            <Col span={12}>
              <div className="assinged-locum-cols">
                <p>Applied Jobs</p>
                <Typography.Text>12</Typography.Text>
              </div>
            </Col>
            <Col span={12}>
              <div className="assinged-locum-cols">
                <p>Terms & Conditions</p>
                <Typography.Text>Accepted</Typography.Text>
              </div>
            </Col>
            <Col span={12}>
              <div className="assinged-locum-cols">
                <p>Cancelled Jobs</p>
                <Typography.Text>0</Typography.Text>
              </div>
            </Col>
            <Col span={12}>
              <div className="assinged-locum-cols">
                <p>Required Documents</p>
                <Typography.Text>7/7</Typography.Text>
              </div>
            </Col>
            <Col span={12}>
              <div className="assinged-locum-cols">
                <p>Completed Jobs</p>
                <Typography.Text>11</Typography.Text>
              </div>
            </Col>
          </Row>

          <ConfirmModal
            open={openAcceptModal}
            onClose={() => setopenAcceptModal(false)}
            title="Accept Request"
            cancelButtonText="Cancel"
            confirmButtonText="Accept Request"
            onConfirm={() => handleAcceptJobRequest(jobId, staff.id)}
          >
            <Typography.Text className="confirm-locum">
              Accept {`${staff?.name} ${staff?.surname}`} request for locum
              pharmacist.
            </Typography.Text>
          </ConfirmModal>
        </div>
      </div>
    ) : (
      <div className="assign-locum-cards mt-4">
        <Typography.Title level={5}>Assigned Locum</Typography.Title>
        <div className="assigned-locum-crd" key={staff.id}>
          <Space className="card-left">
            {staff?.image ? (
              <Avatar
                icon={<img src={staff?.image} alt={staff?.name} />}
                size={48}
              />
            ) : (
              <Avatar size={48}>{staff?.name.slice(0, 1)}</Avatar>
            )}

            <div className="locum-details">
              <Space>
                <p className="locum-name">{`${staff?.name} ${staff?.surname}`}</p>
                {staff.isVerified && (
                  <Tooltip title="ID Verified">
                    <i>
                      <VerifiedIcon />
                    </i>
                  </Tooltip>
                )}
                <Tag color="blue">{staff?.status}</Tag>
              </Space>

              <p className="email">{staff?.email}</p>
              <p className="phone">{staff?.phone}</p>
            </div>
          </Space>

          <Space size={1} className="card-right">
            <StarIcon />
            <Typography.Text className="rating">
              {Number(staff.rating).toFixed(2)}
            </Typography.Text>
          </Space>
        </div>
      </div>
    ),
  );
};

export default AssignedlocumCard;
