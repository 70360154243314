import React from "react";

import DeleteIcon from "../../../../src/assets/icons/trash.svg";
import CrossIcon from "../../../../src/assets/icons/x.png";
// import Avatar from "../../../../src/assets/img/Avatar2.png"

import { Avatar } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import { deleteLeaveThunk } from "../../../redux/staffs/staffsThunk";
import { leaveTypeIcons, leaveTypes } from "../../../utils/constant.js";

function calculateDayDifference(startDateStr, endDateStr) {
  const startDate = moment(startDateStr);
  const endDate = moment(endDateStr);

  const dayDifference = endDate.diff(startDate, "days");

  return dayDifference > 0 ? `${dayDifference} days` : "";
}

const LeaveDetailsView = ({ onClose, leave, role }) => {
  const dispatch = useDispatch();

  function getLeaveName(type) {
    return leaveTypes[type] || "Unknown Leave Type";
  }

  const handleDelete = () => {
    dispatch(
      deleteLeaveThunk({
        id: leave.id,
        data: [
          {
            id: leave.id,
            date: moment(leave?.from).format("YYYY-MM-DD"),
          },
        ],
      })
    );
  };

  return (
    <div className="shift_popup">
      <div className="popup_header d-flex align-items-center justify-content-between px-2 border-bottom">
        <span className="badge bg-success text-capitalize">{role}</span>
        <div className="popup_buttons">
          {/* <button className="btn">
            <img src={EditIcon} alt="edit" />
          </button> */}
          <button className="btn" onClick={handleDelete}>
            <img src={DeleteIcon} alt="delete" />
          </button>
          <button className="btn" onClick={onClose}>
            <img src={CrossIcon} alt="cross" />
          </button>
        </div>
      </div>
      <div className="popup_body">
        <div className="body_top">
          <div className="leave_detailspopup_body_content">
            <ul>
              {!!leave?.branchName ? (
                <li>
                  <p>Branch Name:</p>{" "}
                  <div className="li_right_side">
                    <span className="badge bg-secondary infoImg">
                      {!!leave?.branchLogo ? (
                        <Avatar
                          src={leave?.branchLogo}
                          alt={leave?.branchName}
                          size="small"
                          className="mx-1"
                        />
                      ) : (
                        <Avatar size="small" className="mx-1">
                          {leave?.branchName[0]}
                        </Avatar>
                      )}

                      {leave?.branchName}
                    </span>
                  </div>
                </li>
              ) : null}

              <li>
                <p>Employee Name:</p>{" "}
                <div className="li_right_side">
                  <span className="badge bg-info">
                    {!!leave?.image ? (
                      <Avatar
                        src={leave?.image}
                        alt={leave?.name}
                        size="small"
                        className="mx-1"
                      />
                    ) : (
                      <Avatar size="small" className="mx-1">
                        {leave?.name[0]}
                      </Avatar>
                    )}

                    {/* Wilson Herwitz */}
                    {`${leave.name} ${leave.surname}`}
                  </span>
                </div>
              </li>
              {!!leave?.gphcNumber ? (
                <li>
                  <p>GPhC Number:</p>
                  <div className="li_right_side">
                    {" "}
                    <span> {leave?.gphcNumber}</span>
                  </div>
                </li>
              ) : null}

              <li>
                <p>Leave Type: </p>{" "}
                <div className="li_right_side">
                  <span>
                    {leaveTypeIcons[leave?.type || leaveTypes.annual_leave]}

                    {getLeaveName(leave?.type)}
                  </span>
                </div>
              </li>
              <li>
                <p>Duration: </p>
                <div className="d-flex li_right_side align-items-center">
                  <p className="duration">
                    {`${moment(leave.from).format("MMM D, YYYY")}-${moment(
                      leave.to
                    ).format("MMM D, YYYY")}`}
                  </p>
                  <span className="badge bg-secondary">
                    {calculateDayDifference(leave.from, leave.to)}
                  </span>
                </div>
              </li>
              {!!leave.notes ? (
                <li>
                  <p>Notes:</p>
                  <div className="li_right_side">
                    <span className="wrapped-notes">{leave.notes}</span>
                  </div>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveDetailsView;
