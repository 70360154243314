import { Badge } from "antd";
import React, { useMemo } from "react";
import BlueClock from "../../../assets/icons/blueclock.svg";
import Danger from "../../../assets/icons/danger.svg";
import Approve from "../../../assets/icons/tick-circle-filled.svg";

import { useSelector } from "react-redux";
import {
  ATTENDANCE_TYPE,
  leaveTypeIcons,
  leaveTypes,
} from "../../../utils/constant";
const StatusBadge = ({ index }) => {
  const { staffTimeSheetEdited } = useSelector((s) => s.staffs);

  const shiftDetails = useMemo(() => {
    const details = staffTimeSheetEdited?.[index];

    return details;
  }, [index, staffTimeSheetEdited]);

  const isEmpty =
    !shiftDetails?.attendance?.status && !shiftDetails?.attendance?.type;

  const badgeClassName = isEmpty
    ? "shifttime_wrapper timesheet-badge"
    : "shifttime_wrapper timesheet-badge";

  const leaveType = useMemo(() => {
    if (!!staffTimeSheetEdited[index]?.leaveType)
      return staffTimeSheetEdited[index]?.leaveType;

    if (!!shiftDetails?.attendance?.type) return shiftDetails?.attendance?.type;

    return undefined;
  }, [index, shiftDetails?.attendance?.type, staffTimeSheetEdited]);

  const LeaveBadge = useMemo(() => {
    return (
      <Badge className="overtime_badge">
        <i>{leaveTypeIcons[leaveType]}</i>
        <span>{leaveTypes[leaveType]}</span>
      </Badge>
    );
  }, [index, shiftDetails?.attendance?.type, staffTimeSheetEdited]);

  const badgeContent = useMemo(() => {
    if (staffTimeSheetEdited[index]?.type === ATTENDANCE_TYPE.NO_SHIFT) {
      return "";
    }

    if (
      staffTimeSheetEdited[index]?.type === ATTENDANCE_TYPE.LEAVE
      // shiftDetails?.attendance?.type === ATTENDANCE_TYPE.LEAVE
    ) {
      return LeaveBadge;
    }

    switch (shiftDetails?.attendance?.status) {
      case "pending":
        return (
          <Badge className="pending_badge">
            <i>
              <img src={BlueClock} alt="clock" />
            </i>
            <span>Pending</span>
          </Badge>
        );

      case "unfilled":
        return (
          <Badge className="nocheckin_badge">
            <i>
              <img src={Danger} alt="clock" />
            </i>
            <span>Unfilled</span>
          </Badge>
        );
      case "approved":
        return (
          <Badge className="approved">
            <i>
              <img src={Approve} alt="approve" />
            </i>
            <span>Approved</span>
          </Badge>
        );
      case "leave":
        return LeaveBadge;
      default:
        return null;
    }
  }, [
    shiftDetails?.attendance?.status,
    shiftDetails?.attendance?.type,
    JSON.stringify(staffTimeSheetEdited[index]),
  ]);

  return <div className={badgeClassName}>{badgeContent}</div>;
};

export default StatusBadge;
