export const moduleKeys = {
  PROFILE: "profile",
  ACCREDITATIONS: "accreditations",
  ATTENDANCES: "attendances",
  BANK_HOLIDAYS: "bankHolidays",
  ADMIN_STAFF: "adminStaff",
  BRANCH_BLOCK_LIST: "branchBlockList",
  BRANCH_DASHBOARD: "branchDashboard",
  BRANCH_INFORMATION: "branchInformation",
  BRANCH_SCHEDULE: "branchSchedule",
  BRANCH_STAFF: "branchStaff",
  BRANCH_TEMPLATE: "branchTemplate",
  BRANCHES: "branches",
  GAP_APPLICATION: "gapApplication",
  LOCUM_BANK: "locumBank",
  LOCUM_JOBS: "locumJobs",
  PHARMACY_DASHBOARD: "pharmacyDashboard",
  PHARMACY_SCHEDULER: "pharmacyScheduler",
  REGIONS: "regions",
  SETTINGS: "settings",
  STAFF: "staff",
  STAFF_LEAVES: "staffLeaves",
  STAFF_DOCUMENT: "staffDocument",

  STAFF_CONTRACT: "staffContract",
  STAFF_MEMBER: "staffMember",
  STAFF_PERSONAL_INFORMATION:
    "staffPersonalInformation",
  STAFF_SCHEDULE: "staffSchedule",
  STAFF_TIME_SHEET: "staffTimeSheet",
  STAFF_VERIFICATION: "staffVerification",
  SUBSCRIPTION_AND_BILLING:
    "subscriptionAndBilling",
  LOCUM_TIME_SHEET: "timesheet",
};
