import { useMutation } from "@tanstack/react-query";
import { Form, Table } from "antd";
import React, {
  useEffect,
  useId,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { queryClient } from "../..";
import CommonModal from "../../components/UI/modals/CommonModal/CommonModal";
import {
  deleteFile,
  updateRightToWorkDoc,
} from "../../redux/staffs/staffsAPI";
import {
  editStaffAccrediationsThunk,
  getStaffAccrediationsThunk,
} from "../../redux/staffs/staffsThunk";
import { notify } from "../../utils/helper";

import dayjs from "dayjs";
import { applyRequiredAccreditationsThunk } from "../../redux/locum-flow/Thunk";
import { prepareFilePreview } from "../../utils/document";
import DragNDrop from "./components/DragNDrop";
import Actions from "./components/TableCellComponents/Actions";
import DateRender from "./components/TableCellComponents/DateRender";
import DocumentType from "./components/TableCellComponents/DocumentType";
import FileName from "./components/TableCellComponents/FileName";
import UploadDocument from "./components/UploadDocument";
import "./document.scss";

const columns = [
  {
    title: "Uploaded File",
    dataIndex: "filename",
    width: 200,
  },
  {
    title: "Document Type",
    dataIndex: "type",
  },
  {
    title: "Uploaded Date",
    dataIndex: "uploaddate",
  },
  {
    title: "Expiry Date",
    dataIndex: "expiry",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    width: 112,
  },
];

const DocumentTab = ({
  staffId,
  fileList,
  tab,
  accreditationRules,
}) => {
  const [form] = Form.useForm();
  const [openUploadModalNew, setNewUploadModal] =
    useState(false);
  const id = Form.useWatch("id", form);
  const accreditationName = Form.useWatch(
    "accreditationName",
    form
  );
  const accreditationLabel = Form.useWatch(
    "accreditationLabel",
    form
  );
  const filePath = Form.useWatch(
    "filePath",
    form
  );
  const expiryDate = Form.useWatch(
    "expiryDate",
    form
  );
  const type = Form.useWatch("type", form);
  const date_key = Form.useWatch(
    "date_key",
    form
  );
  const isExpiryRequired = Form.useWatch(
    "isExpiryRequired",
    form
  );
  const answer = Form.useWatch("answer", form);
  const isNumber = Form.useWatch(
    "isNumber",
    form
  );
  const number_key = Form.useWatch(
    "number_key",
    form
  );
  const document_type = Form.useWatch(
    "document_type",
    form
  );
  const number_label = Form.useWatch(
    "number_label",
    form
  );
  const accreditationRuleId = Form.useWatch(
    "accreditationRuleId",
    form
  );
  const value = Form.useWatch("value", form);
  const formID = useId();
  const [openModal, setopenModal] =
    useState(false);
  const [showDeleteModal, setshowDeleteModal] =
    useState(false);

  const { mutate } = useMutation({
    mutationFn: updateRightToWorkDoc,
  });

  const _delete = useMutation({
    mutationFn: deleteFile,
  });

  const dispatch = useDispatch();

  const openDeleteModal = (item) => {
    setshowDeleteModal(true);
    form.setFieldsValue(item);
  };

  const handleOpenModal = () => {
    setopenModal(true);
  };

  const handleInput = (name, value) => {
    form.setFieldValue(name, value);
  };

  const payload = useMemo(() => {
    return {
      id,
      accreditationName,
      accreditationLabel,
      filePath,
      expiryDate,
      type,
      isExpiryRequired,
      date_key,
      answer,
      isNumber,
      number_key,
      number_label,
      value,
      document_type,
      accreditationRuleId,
    };
  }, [
    id,
    accreditationName,
    accreditationRuleId,
    accreditationLabel,
    filePath,
    expiryDate,
    type,
    isExpiryRequired,
    date_key,
    answer,
    isNumber,
    number_key,
    number_label,
    value,
    document_type,
  ]);

  const findObj = useMemo(() => {
    let required = false;
    let file = null;

    if (
      payload?.accreditationLabel?.length &&
      fileList?.length
    ) {
      const _file = fileList?.find(
        (item) =>
          item?.accreditationLabel ===
          payload?.accreditationLabel
      );

      required = Boolean(
        _file?.isExpiryDateRequired
      );
      file =
        _file?.filePath?.length > 0
          ? _file?.filePath
          : null;
    }

    return { required, file };
  }, [fileList, payload?.accreditationLabel]);

  const afterAccredationUpdate = () => {
    setopenModal(false);
    setNewUploadModal(false);
    notify(
      "success",
      "Staff Accreditations Updated!"
    );
    form.resetFields();
    dispatch(
      getStaffAccrediationsThunk({ staffId })
    );
  };

  const onSubmitCustomAccredations = (values) => {
    let data = {
      accreditationRuleId:
        values.accreditationRuleId,
      isDocument:
        values.document_type === "document"
          ? 1
          : 0,
      isMandatory: values.isMandatory,
      isExpiryRequired: values.isExpiryRequired,
      expiryDate:
        values.isExpiryRequired === 0
          ? undefined
          : dayjs(values.expiryDate).format(
              "YYYY-MM-DD"
            ),
      answer: values.answer,
    };

    if (values?.filePath?.fileList?.length) {
      data = {
        ...data,
        file: values.filePath?.fileList[0],
      };
    }

    const formData = new FormData();

    if (data.isDocument === 1 && !!data.file) {
      formData.append(
        `${data?.file?.name}`,
        data?.file?.originFileObj
      );
    }

    if (data.isDocument === 1 && !!data?.file) {
      data.filename = data.file.name;
      delete data["answer"];
      delete data["file"];
    }

    const questions = [data];
    formData.append(
      "request",
      JSON.stringify({ questions })
    );

    dispatch(
      applyRequiredAccreditationsThunk({
        id: staffId,
        formData,
      })
    ).then((res) => {
      if (res.payload === "success") {
        afterAccredationUpdate();
      }
    });
  };

  const onAccrediationEdit = () => {
    form.validateFields().then((val) => {
      if (val?.type === "pharmacyAccreditation") {
        onSubmitCustomAccredations(val);
        return;
      }

      // if (!!val) return;

      let file = "";

      if (payload?.document_type === "document") {
        file =
          payload?.filePath?.file?.status ===
          "removed"
            ? ""
            : payload?.filePath.file
            ? payload?.filePath.file
            : payload?.filePath?.fileList[0].url;
      }

      let _formData = new FormData();
      _formData.append(
        payload?.accreditationLabel,
        payload?.document_type === "document"
          ? file
          : payload?.value
      );

      _formData.append("id", staffId);

      val?.isExpiryRequired &&
        _formData.append(
          val?.date_key,
          dayjs(val?.expiryDate).format(
            "YYYY-MM-DD"
          )
        );

      if (tab === "rightToWork") {
        mutate(_formData, {
          onSuccess: (res) => {
            setopenModal(false);
            setNewUploadModal(false);
            notify(
              "success",
              "Right to work Updated!"
            );
            form.resetFields();
            queryClient.invalidateQueries(
              "getRightToWorkList"
            );
          },
        });
      }

      if (tab === "accrediations") {
        dispatch(
          editStaffAccrediationsThunk(_formData)
        ).then((res) => {
          if (res.payload.status === "success") {
            afterAccredationUpdate();
          }
        });
      }
    });
  };

  const deleteDocument = () => {
    let _formData = new FormData();

    _formData.append(
      payload?.accreditationLabel,
      ""
    );
    payload?.isExpiryRequired &&
      _formData.append(payload?.date_key, "");

    //     profileType:
    // profileRightToWork
    // profileAccreditations
    // userProfile

    _delete.mutate(
      {
        profileType:
          tab === "rightToWork"
            ? "profileRightToWork"
            : "profileAccreditations",
        fileName: payload?.accreditationLabel,
        id: parseInt(staffId),
      },
      {
        onSuccess: (res) => {
          notify(
            "success",
            "Document deleted successfully!"
          );
          queryClient.invalidateQueries(
            "getRightToWorkList"
          );
          dispatch(
            getStaffAccrediationsThunk({
              staffId,
            })
          );
          form.resetFields();
        },
      }
    );

    // if (tab === "rightToWork") {
    //   mutate(_formData, {
    //     onSuccess: (res) => {
    //       queryClient.invalidateQueries("getRightToWorkList");
    //     },
    //   });
    // }

    // if (tab === "accrediations") {
    //   _formData.append("id", staffId);

    //   dispatch(editStaffAccrediationsThunk(_formData)).then((res) => {
    //     if (res.payload.status === "success") {
    //       dispatch(getStaffAccrediationsThunk({ staffId }));
    //     }
    //   });
    // }

    setshowDeleteModal(false);
  };

  const onUploadClick = (item) => {
    form.setFieldsValue({
      ...item,
      expiryDate: !!item?.expiryDate
        ? dayjs(item?.expiryDate, "YYYY-MM-DD")
        : null,
      filePath: prepareFilePreview(
        item?.filePath
      ),
    });

    handleOpenModal();
  };

  const handleSetItem = (v) => {
    const _temp = fileList.find(
      (i) => i.accreditationLabel === v
    );
    if (_temp) {
      form.setFieldsValue({
        ..._temp,
        expiryDate: !!_temp?.expiryDate
          ? dayjs(_temp?.expiryDate, "YYYY-MM-DD")
          : null,
      });
    }
  };

  const [_tableData, setTabledata] = useState([]);

  useEffect(() => {
    let data = [];

    if (fileList?.length) {
      fileList?.forEach((item) => {
        data.push({
          filename:
            item?.document_type === "document" ? (
              <FileName
                filePath={item?.filePath}
                filename={item?.filePath}
                // size={item?.fileSize}
                // type={item?.fileType}
              />
            ) : (
              <p>
                {item?.value ||
                  item?.answer ||
                  "-"}
              </p>
            ),
          type: (
            <DocumentType
              type={
                item?.accreditationName ||
                item?.title
              }
            />
          ),
          uploaddate: (
            <DateRender date={item?.createdAt} />
          ),
          expiry: (
            <DateRender date={item?.expiryDate} />
          ),
          actions: (
            <Actions
              item={item}
              editable={
                Boolean(item?.filePath?.length) ||
                item?.value?.length
              }
              onUploadClick={() => {
                onUploadClick(item);
              }}
              disabled={Boolean(item?.disabled)}
              onDeleteClick={() =>
                openDeleteModal(item)
              }
            />
          ),
        });
      });
    }

    setTabledata(data);
  }, [JSON.stringify(fileList), form]);

  const handleOpenUploadModal = () => {
    form.resetFields();
    handleOpenModal();
  };

  return (
    <div className="accrediation_wrapper">
      <DragNDrop
        handleOpenDeleteModal={openDeleteModal}
        handleOpenUploadModal={
          handleOpenUploadModal
        }
        fileList={fileList}
        handleInput={handleInput}
        form={form}
        onAccrediationEdit={onAccrediationEdit}
        setNewUploadModal={setNewUploadModal}
        openUploadModal={openUploadModalNew}
        formID={formID}
      />

      <div className="document_table_container">
        <Table
          columns={columns}
          dataSource={_tableData}
          bordered
          pagination={false}
          // scroll={{ x:1096, y: "calc(100vh - 400px)" }}
          // style={{overflowX:'auto'}}
        />
      </div>

      <CommonModal
        handleModal={openModal}
        setHandleModal={setopenModal}
        title="Upload Documents"
        secondaryButtonText="Cancel"
        primaryButtonText="Save"
        primaryButtonHandler={onAccrediationEdit}
        secondaryButtonHandler={() =>
          setopenModal(false)
        }
      >
        <UploadDocument
          handleInput={handleInput}
          payload={payload}
          key={payload?.id}
          staffId={staffId}
          valueKey={"accreditationLabel"}
          setItem={handleSetItem}
          accredationList={fileList}
          displayKey={"accreditationName"}
          isExpiryDateReuired={findObj?.required}
          initialFile={findObj?.file}
          form={form}
          formID={formID}
        />
      </CommonModal>
      <CommonModal
        handleModal={showDeleteModal}
        setHandleModal={setshowDeleteModal}
        title="Delete Document"
        secondaryButtonText="Cancel"
        primaryButtonText="Delete Document"
        primaryButtonHandler={deleteDocument}
        secondaryButtonHandler={() =>
          setshowDeleteModal(false)
        }
      >
        <Form form={form} id={formID}>
          <Form.Item
            name="accreditationLabel"
            hidden
          />
          <Form.Item
            name="accreditationName"
            hidden
          />
          <Form.Item name="filePath" hidden />
          <Form.Item name="expiryDate" hidden />
          <p className="delete_document">
            Are you sure you want to delete this
            document.
          </p>
        </Form>
      </CommonModal>
    </div>
  );
};

export default DocumentTab;
