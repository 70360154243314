import { API } from "../../../axios";

export const getLocumBankList = (payload) => {
    const { filters, pagination } = payload;
    const request = API.post(`/locum-banks/list`, {
        filters, pagination
    });
    return request
}

export const getLocumByGphC = (payload) => {
    const { gphcNumber } = payload
    const request = API.get(`/locum-banks/gphc/${gphcNumber}`);
    return request
}

export const AddLocumBank = (payload) => {
    const request = API.post(`/locum-banks/add`, payload);
    return request
}

export const removeLocumBank = (payload) => {
    const request = API.post(`/locum-banks/remove`, payload);
    return request
}
