import React from "react";

const MoneyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15.9758 5.53359C15.6174 3.72526 14.2758 2.93359 12.4091 2.93359H5.09245C2.89245 2.93359 1.42578 4.03359 1.42578 6.60026V10.8919C1.42578 12.7419 2.18411 13.8253 3.43411 14.2919C3.61745 14.3586 3.81745 14.4169 4.02578 14.4503C4.35911 14.5253 4.71745 14.5586 5.09245 14.5586H12.4174C14.6174 14.5586 16.0841 13.4586 16.0841 10.8919V6.60026C16.0841 6.20859 16.0508 5.85859 15.9758 5.53359ZM4.60911 10.0003C4.60911 10.3419 4.32578 10.6253 3.98411 10.6253C3.64245 10.6253 3.35911 10.3419 3.35911 10.0003V7.50026C3.35911 7.15859 3.64245 6.87526 3.98411 6.87526C4.32578 6.87526 4.60911 7.15859 4.60911 7.50026V10.0003ZM8.75078 10.9503C7.53411 10.9503 6.55078 9.96693 6.55078 8.75026C6.55078 7.53359 7.53411 6.55026 8.75078 6.55026C9.96745 6.55026 10.9508 7.53359 10.9508 8.75026C10.9508 9.96693 9.96745 10.9503 8.75078 10.9503ZM14.1341 10.0003C14.1341 10.3419 13.8508 10.6253 13.5091 10.6253C13.1674 10.6253 12.8841 10.3419 12.8841 10.0003V7.50026C12.8841 7.15859 13.1674 6.87526 13.5091 6.87526C13.8508 6.87526 14.1341 7.15859 14.1341 7.50026V10.0003Z"
        fill="#9D9D9D"
      />
      <path
        d="M18.5822 9.10056V13.3922C18.5822 15.9589 17.1155 17.0672 14.9072 17.0672H7.5905C6.9655 17.0672 6.40716 16.9756 5.92383 16.7922C5.53216 16.6506 5.1905 16.4422 4.9155 16.1756C4.7655 16.0339 4.88216 15.8089 5.0905 15.8089H12.4072C15.4905 15.8089 17.3238 13.9756 17.3238 10.9006V6.60056C17.3238 6.40056 17.5488 6.27556 17.6905 6.42556C18.2572 7.02556 18.5822 7.90056 18.5822 9.10056Z"
        fill="#9D9D9D"
      />
    </svg>
  );
};

export default MoneyIcon;
