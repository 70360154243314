import dayjs from "dayjs";
import SickLeaveIcon, {
  AnnualLeaveIcon,
  BankLeaveIcon,
  BranchSwapIcon,
  MaternityLeaveIcon,
  PaternityLeave,
} from "../components/UI/icons/SickLeaveIcons";

export const userRoles = {
  ADMIN: "admin",
  PHARMACY_ADMIN: "pharmacy",
  LOCUM: "locum",
  STAFF: "staff",
};

export const moduleKeys = {
  USER_ROLE_MANAGEMENT: "userRoles",
  REGION: "region",
  USER: "user",
  STAFF: "staff",
  SETTINGS: "setings",
  PERSONAL: "personal",
  PAYMENTS: "payments",
  LOCUM_BANK: "locumBank",
  LOCUMS: "locums",
  JOBS: "jobs",
  INVITE: "invites",
  FILES: "files",
  BRANCHES: "branches",
  AUTH: "authentication",
  ADMIN: "admin",
  DASHBOARD: "dashboard",
  SCHEDULER: "scheduler",
  PROFILE: "profile",
  TIME_SHEET: "timesheet",
  DOCUMENT: "document",
};

export const subModuleKeys = {
  PERSONAL_INFORMATION: "personalInformation",
  SUBSCRIPTION_AND_BILLING:
    "subscriptionAndBilling",
  BANK_HOLIDAYS: "bankHolidays",
  ACCREDITION: "accredition",
  SETTINGS: "settings",
  ADMIN_STAFF: "adminStaff",
  VERFICATION: "verification",
  SCHEDULE: "schedule",
  ABSENCES: "absences",
  CONTRACT: "contract",
  TIME_SHEET: "time_sheet",
  INFORMATION: "information",
  TEMPLATE: "template",
  STAFF: "staff",
  BLOCK_LIST: "blockList",
  LOCUM_JOBS: "locumJobs",
  NOTIFICATION_SETTINGS: "notificationSettings",
};
export const userStatuses = {
  new: "New",
  onboarding: "Onboarding",
  pending: "Pending",
  active: "Active",
  rejected: "Rejected",
  deactivated: "Deactivated",
};

export const userRolesEnum = {
  admin: "Admin",
  branch_admin: "Branch Admin",
  pharmacy_manager: "Pharmacy Manager",
  locum: "Locum",
  relief: "Relief",
  technician: "Technician",
  branch_manager: "Branch Manager",
  branch_pharmacist: "Branch Pharmacist",
  pharmacy_admin: "Pharmacy Admin",
  locum_coordinator: "Locum Coordinator",
  full_time_staff_coordinator:
    "Full Time Staff Coordinator",
};

export const userStatusesList = {
  ONBOARDING: "onboarding",
  PENDING: "pending",
  ACTIVE: "active",
  NEW: "new",
  REJECTED: "rejected",
  DEACTIVATED: "deactivated",
};

export const locumStatusesList = {
  ACCEPTED: "accepted",
  APPLIED: "applied",
  REJECTED: "rejected",
  INVITED: "invited",
  DECLINED: "declined",
};

// types of abilities
export const roleAbilityTypes = {
  CREATE_ABILITY: "create",
  READ_ABILITY: "read",
  UPDATE_ABILITY: "update",
  DELETE_ABILITY: "delete",
  MANAGE_ABILITY: "manage",
};

// user features abilities
export const roleFeatureTypes = {
  STAFF_EDIT_FEATURE: "staff_edit",
  STAFF_PERSONAL_INFO_EDIT:
    "staff_personal_info_edit",
  LOCUM_PERSONAL_INFO_EDIT:
    "locum_personal_info_edit",
  STAFF_PERSONAL_INFO_GPHC_VIEW:
    "staff_personal_info_gphc_view",
  PHARMACY_EDIT: "pharmacy_edit",
};

// google places api config
export const searchAddressConfig = {
  street_number: "short_name",
  route: "long_name",
  locality: "long_name",
  administrative_area_level_1: "short_name",
  administrative_area_level_2: "short_name",
  political: "short_name",
  country: "long_name",
  postal_code: "short_name",
  postal_town: "long_name",
};

export const staffAbsencesType = {
  sick_leave: "Sick Leave",
  annual_leave: "Annual Leave",
  maternity_leave: "Maternity Leave",
  paternity_leave: "Paternity Leave",
  bank_holiday: "Bank Holiday",
  branch_swap: "Branch Swap",
  sabatical: "Sabatical",
  unpaid: "Unpaid",
  lieu: "Lieu",
  training: "Training",
  double_cover: "Double Cover",
  dispenser_cover: "Dispenser Cover",
  none: "None",
};

export const staffAbsencesTypeIcon = {
  sick_leave: "Sick Leave",
  annual_leave: "Annual Leave",
  maternity_leave: "Maternity Leave",
  paternity_leave: "Paternity Leave",
  bank_holiday: "Bank Holiday",
  branch_swap: "Branch Swap",
  sabatical: "Sabatical",
  unpaid: "Unpaid",
  lieu: "Lieu",
  training: "Training",
  double_cover: "Double Cover",
  dispenser_cover: "Dispenser Cover",
};

export const leavesType = {
  absent: "Absent",
  leave: "Leave",
  present: "Present",
  shift_not_started: "Shift Not Started",
};

export const VIEW_OPTIONS = [
  { value: "monthly", label: "Month" },
  { value: "weekly", label: "Week" },
  { value: "daily", label: "Day" },
];
export const VIEW_OPTIONS_LOCUMJOB = [
  { value: "weekly", label: "Week" },
  { value: "monthly", label: "Month" },
];

export const VIEW_OPTIONS_TIMESHEET = [
  { value: "monthly", label: "Month" },
  { value: "weekly", label: "Week" },
];

export const ROLES_COLOR = {
  4: "#FF9200",
  5: "#FFC500",
  6: "#F67280",
  7: "#1F87E5",
  8: "#581845",
  9: "#43A046",
  10: "#68D9D3",
};

export const JOBS_OPTIONS = [
  {
    value: "",
    label: "All Shifts & Jobs",
    color: "",
  },
  { value: "unfilled", label: "Posted" },
  { value: "pending", label: "Pending" },
];

export const MAX_FILE_SIZE = 5242880; // 5 MB in bytes
export const validFileTypes = [
  "application/pdf",
  "image/jpeg",
  "image/png",
  "image/svg+xml",
];

export const roleOptionList = [
  "pharmacist",
  "technician",
  "dispenser",
  "counter",
  "pre-reg",
];

export const defaultRoleOptions = [
  roleOptionList[0],
  roleOptionList[1],
  roleOptionList[2],
];

export const shiftStatus = {
  SHIT_NOT_STARTED: "shift_not_started",
  PRESENT: "present",
  ABSENT: "absent",
  LEAVE: "leave",
  UNFILLED: "unfilled",
};

export const ATTENDANCE_TYPE = {
  SHIT_NOT_STARTED: "shift_not_started",
  PRESENT: "present",
  ABSENT: "absent",
  LEAVE: "leave",
  NO_SHIFT: "no_shift",
};

export const absenceType = [
  {
    label: "Annual Leave",
    value: "annual_leave",
    icon: <AnnualLeaveIcon />,
  },

  {
    label: "Sick Leave",
    value: "sick_leave",
    icon: <SickLeaveIcon />,
  },
  {
    label: "Bank Holiday",
    value: "bank_holiday",
    icon: <BankLeaveIcon />,
  },
  {
    label: "Branch Swap",
    value: "branch_swap",
    icon: <BranchSwapIcon />,
  },
  {
    label: "Maternity Leave",
    value: "maternity_leave",
    icon: <MaternityLeaveIcon />,
  },
  {
    label: "Paternity Leave",
    value: "paternity_leave",
    icon: <PaternityLeave />,
  },
  {
    label: "None",
    value: "none",
    icon: <AnnualLeaveIcon />,
  },
];

export const leaveTypeIcons = {
  sick_leave: <SickLeaveIcon />,
  annual_leave: <AnnualLeaveIcon />,
  maternity_leave: <MaternityLeaveIcon />,
  paternity_leave: <PaternityLeave />,
  bank_holiday: <BankLeaveIcon />,
  branch_swap: <BranchSwapIcon />,
  sabbatical: <AnnualLeaveIcon />,
  unpaid: <AnnualLeaveIcon />,
  lieu: <AnnualLeaveIcon />,
  training: <AnnualLeaveIcon />,
  double_cover: <AnnualLeaveIcon />,
  dispenser_cover: <AnnualLeaveIcon />,
  none: <AnnualLeaveIcon />,
  leave: <AnnualLeaveIcon />,
};

export const leaveTypes = {
  sick_leave: "SICK LEAVE",
  annual_leave: "ANNUAL LEAVE",
  maternity_leave: "MATERNITY LEAVE",
  paternity_leave: "PATERNITY LEAVE",
  bank_holiday: "BANK HOLIDAY",
  branch_swap: "BRANCH SWAP",
  sabbatical: "SABATICAL",
  unpaid: "UNPAID",
  lieu: " LIEU",
  training: "TRAINING",
  double_cover: "DOUBLE COVER",
  dispenser_cover: "DISPENSER COVER",
  none: "None",
};

export const THUNK_STATUS = {
  idle: "idle",
  loading: "loading",
  rejecetd: "rejected",
};

export const JobStatuses = {
  active: "Active",
  unfilled: "Unfilled",
  pending: "Pending",
  cancelled: "Cancelled",
  paid: "Paid",
  payment_approved: "Payment Approved",
  completed: "Completed",
  assigned: "Assigned",
  payment_pending: "Payment pending",
};

export const jobStatusesForFilter = [
  {
    value: "",
    label: "All",
  },
  {
    value: "active",
    label: "Active",
  },
  {
    value: "unfilled",
    label: "Unfilled",
  },
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
  {
    value: "paid",
    label: "Paid",
  },
  {
    value: "payment_approved",
    label: "Payment Approved",
  },
  {
    value: "completed",
    label: "Completed",
  },
];

export const DASHBOARD_DETAILS_DATA = {
  pending_jobs: `When Locums apply to jobs you've posted they become 'Pending Jobs.

  You can click on anyone in the list and review which Locum you wish to 'Approve' ✅ or 'Disapprove'  ❌`,
  pharmacy_gap:
    "Gaps are time periods in which your schedule is missing a Pharmacist. You can schedule a shift or job against a gap and fill it! 🕒",
};

export const QUESTIONS = [
  {
    question:
      "Provide a brief of employees key responsibilities",
    answer: "",
    id: dayjs().unix() + 1,
  },
  {
    question:
      "Outline knowledge of job skills (ability to learn and apply new skills, requires minimal supervision, etc.)",
    answer: "",
    id: dayjs().unix() + 2,
  },
  {
    question: "Describe the quality of work",
    answer: "",
    id: dayjs().unix() + 3,
  },
  {
    question:
      "Outline the employee's customer service skills and communication",
    answer: "",
    id: dayjs().unix() + 4,
  },
  {
    question:
      "Provide an overview on punctuality and attendance",
    answer: "",
    id: dayjs().unix() + 5,
  },
  {
    question:
      "Outline the employee's overall performance",
    answer: "",
    id: dayjs().unix() + 6,
  },
  {
    question: "Follow up tasks and dates",
    answer: "",
    id: dayjs().unix() + 7,
  },
];

export const maxSizeInMB = 3;
