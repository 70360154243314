import { Button, Form, Select, Space } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Dropdown from "../../../assets/icons/downDrop.svg";
import { jobStatusesForFilter } from "../../../utils/constant";

const LocumJobFilter = ({ onApply, onCancel, filterValues, open }) => {
  const [form] = Form.useForm();
  const formId = useMemo(() => "jobListFilterForm", []);
  const [params] = useSearchParams();
  const { regionBranches } = useSelector((state) => state.regions);

  const onFinish = (values) => {
    const filterPrams = {
      status: values.status ?? "",
      branchTitles: values?.branchTitles?.length ? values?.branchTitles : [],
    };

    onApply(filterPrams);
  };

  const handleOnCancel = () => {
    form.resetFields();
    onCancel();
  };
  const [o, seto] = useState(false);

  useEffect(() => {
    if (!!params) {
      if (!!params.get("open") && parseInt(params.get("open")) > 0) {
        seto(true);
      }
    }
  }, [params, params.get("open")]);

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        ...filterValues,
        branchTitles: filterValues?.branchTitles?.length
          ? filterValues?.branchTitles
          : [],
      });
    } else {
    }
  }, [filterValues, open]);

  return (
    <div className="locum-job-filter-popover">
      <Form
        form={form}
        id={formId}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
        className="locum-filter-form"
      >
        <Form.Item
          valuePropName="status"
          label="Select"
          name="status"
          initialValue=""
          className="not-required"
        >
          <Select
            style={{
              width: "100%",
            }}
            dropdownStyle={{
              zIndex: 1200,
            }}
            defaultValue=""
            defaultActiveFirstOption
            placeHolder="Select Status"
            options={jobStatusesForFilter}
            suffixIcon={<img src={Dropdown} alt="dropdown" />}
          />
        </Form.Item>

        <Form.Item
          label="Branches"
          className="not-required"
          name={"branchTitles"}
        >
          <Select
            mode="multiple"
            // open={o}
            style={{
              width: "100%",
            }}
            dropdownStyle={{
              zIndex: 1200,
            }}
            placeholder="Select one or more branches"
            optionLabelProp="label"
            // optionRender={(option) => (
            //   <Space>
            //     <span role="img" aria-label={option?.title}>
            //       {option?.title}
            //     </span>
            //   </Space>
            // )}
            suffixIcon={<img src={Dropdown} alt="dropdown" />}
          >
            {regionBranches?.map((data, idx) => (
              <Select.Option key={idx} value={data.title}>
                <span
                  style={{
                    color: "black !important",
                  }}
                  className="opacity-75"
                >
                  {data?.title}
                </span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Space>
          <Button
            htmlType="button"
            className="cancel-btn"
            onClick={handleOnCancel}
          >
            Cancel
          </Button>
          <Button onClick={onFinish} htmlType="submit" className="apply-btn">
            Apply Filter
          </Button>
        </Space>
      </Form>
    </div>
  );
};

export default LocumJobFilter;
