import React from "react";

export const AppleAppAssosiation = () => {
  return (
    <code>
      {`
      {
        "applinks": {
        "apps": [],
      "details": [
      {
        "appID": "TB62B9A27J.com.locum.scheduling.app.rn",
      "paths": [ "*"]
            }
      ]
    }
}
`}
    </code>
  );
};
