import { Button } from "antd";
import React from "react";
import "./style.scss";
const CustomIconButton = ({ icon, onClick, className, style }) => {
  return (
    <Button
      type="text"
      icon={icon}
      onClick={onClick}
      className={className}
      style={style}
    />
  );
};

export default CustomIconButton;
