import React, { useMemo } from "react";
import { DatePicker, Form } from "antd";
import { FilterComponent } from "../../../../../../components/Filter";
import dayjs from "dayjs";

export const StaffTimeSheetFilterComponent = ({ dateRange, onApplyFilter }) => {
  const [form] = Form.useForm();
  const formId = useMemo(() => "time-sheet-filter-form", []);
  const startDate = Form.useWatch("startDate", form);
  const endDate = Form.useWatch("endDate", form);

  const clearFilterFormHandler = () => {
    form.resetFields();
    onApplyFilter();
  };

  const onSubmit = (values) => {
    onApplyFilter(values);
  };

  return (
    <FilterComponent
      formId={formId}
      onApplyFilter={form.submit}
      clearFormHandler={form.isFieldsTouched() && clearFilterFormHandler}
    >
      <Form
        id={formId}
        onFinish={onSubmit}
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={{
          startDate: dateRange.startDate,
          endDate: dateRange?.endDate
        }}
      >
        <div className="p3 mt-3">Date Range</div>
        <div
          className="my-3 d-flex align-items-center gap-3 "
          style={{ width: "100%" }}
        >
          <Form.Item
            style={{ width: "50%" }}
            label="Start Date"
            name="startDate"
            rules={[{ required: true, message: "Please Enter Start Date!" }]}
          >
            <DatePicker
              inputReadOnly
              format="DD-MM-YYYY"
              placeholder="Select"
              getPopupContainer={(trigger) => trigger.parentElement}
              className="text-input-field p-2 pe-3 t1"
              style={{ width: "100%" }}
              disabledDate={(current) => {
                return current >= dayjs(endDate);
              }}
            />
          </Form.Item>
          <Form.Item
            style={{ width: "50%" }}
            label="End Date"
            name="endDate"
            rules={[{ required: true, message: "Please Enter End Date!" }]}
          >
            <DatePicker
              inputReadOnly format="DD-MM-YYYY"
              placeholder="Select"
              getPopupContainer={(trigger) => trigger.parentElement}
              className="text-input-field p-2 pe-3 t1"
              style={{ width: "100%" }}
              disabledDate={(current) => {
                return dayjs() <= current || current <= dayjs(startDate);
              }}
            />
          </Form.Item>
        </div>
      </Form>
    </FilterComponent>
  );
};
