import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Popover,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { groupBy, mapValues } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import actionBtnArr from "../../../../../assets/icons/actionBtnArr.png";
import ArrowrightIcon from "../../../../../assets/icons/arrow-right.png";
import ArrowLeftIcon from "../../../../../assets/icons/back.png";
import blankExpense from "../../../../../assets/img/blankExpense.png";
import blankJobApproval from "../../../../../assets/img/blankJobApproval.png";
import blankPharmacy from "../../../../../assets/img/blankPharmacy.png";
import blankStaff from "../../../../../assets/img/blankStaff.png";
import TotalStaffChart from "../../../../../components/DashboardNew/Charts/TotalStaffChart";
import CommonDataTab from "../../../../../components/DashboardNew/CommonDataTab";
import DashboardChartcontainer from "../../../../../components/DashboardNew/DashboardChartcontainer";
import DashboardTableContainer from "../../../../../components/DashboardNew/DashboardTableContainer";
import DashboardCommonTable from "../../../../../components/DashboardNew/Tables/DashboardCommonTable";
import CustomIconButton from "../../../../../components/UI/IconButton/IconButton";
import { WarningBar } from "../../../../../components/UI/header/warningBar";
import { Heading } from "../../../../../components/UI/heading";
import AddIcon from "../../../../../components/UI/icons/AddIcon";
import BriefCaseIcon from "../../../../../components/UI/icons/BriefCaseIcon";
import BuildingsIcon from "../../../../../components/UI/icons/BuildingsIcon";
import CalendarIconNormal from "../../../../../components/UI/icons/CalendarIconNormal";
import ClockIconPurple from "../../../../../components/UI/icons/ClockIconPurple";
import CloseIcon from "../../../../../components/UI/icons/CloseIcon";
import DocumentDownloadBtn from "../../../../../components/UI/icons/DocumentDownloadBtn";
import DropdownIcon from "../../../../../components/UI/icons/DropdownIcon";
import InfoCircleIcon from "../../../../../components/UI/icons/InfoCircleIcon";
import UserTagIcon from "../../../../../components/UI/icons/UserTagIcon";
import { ShiftJobFormNew } from "../../../../../components/comon/AddShiftFormNew";
import { EmptyData } from "../../../../../components/comon/EmptyData";
import {
  exportBranchGapsCSVThunk,
  getBranchDashboardDetailThunk,
  getBranchDataThunk,
  getBranchGapsDataThunk,
  getPendingJobDetailThunk,
} from "../../../../../redux/branches/branchesThunk";
import {
  getAccommodationAndFoodRulesThunk,
  getMileageRulesThunk,
  getParkingRulesThunk,
  getTransportRulesThunk,
} from "../../../../../redux/profile/profileThunk";
import { getRegionsListDropDownThunk } from "../../../../../redux/regions/regionsThunk";
import {
  getRolesThunk,
  getStaffsThunk,
} from "../../../../../redux/schedule/scheduleThunk";
import { RouterConfig } from "../../../../../routerConfig";
import {
  branchApiKeys,
  locumShiftApiKeys,
  regionApiKeys,
  settingsApiKeys,
  staffApiKeys,
} from "../../../../../utils/apiKeyConstants";
import { DASHBOARD_DETAILS_DATA } from "../../../../../utils/constant";
import { hasAccess } from "../../../../../utils/helper";
import { moduleKeys } from "../../../../../utils/modulesKeyConstants";
import ExpenseBarChart from "./components/ExpenseBarChart";

const { RangePicker } = DatePicker;
dayjs.extend(isoWeek);

const branchSideBarModulesArray = [
  {
    key: moduleKeys.BRANCH_INFORMATION,
  },
  {
    key: moduleKeys.BRANCH_SCHEDULE,
  },
  {
    key: moduleKeys.BRANCH_TEMPLATE,
  },
  {
    key: moduleKeys.BRANCH_STAFF,
  },
  {
    key: moduleKeys.BRANCH_BLOCK_LIST,
  },
];
export const BranchDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [showAddShiftModal, setShowAddShiftModal] = useState(false);
  const [dateRange, setDateRange] = useState();
  const [flag, setFlag] = useState(true);
  const [gapData, setGapData] = useState();
  const { accessibleModules: modules } = useSelector((state) => state.auth);
  const [showViewButton, setViewButton] = useState(false);
  const [filterDateRange, setFilterDateRange] = useState();
  const [openPopover, setOpenPopover] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const { branchInfo } = useSelector((state) => state.branches);
  const [showExportModal, setshowExportModal] = useState(false);
  const handleShowExportModal = () => {
    setshowExportModal(!showExportModal);
  };

  useEffect(() => {
    const showViewBtnFunction = () => {
      if (!modules) return false;

      const modulesArray = Object.values(modules);
      const linksToDisplay = branchSideBarModulesArray.filter((link) =>
        modulesArray.some(
          (module) =>
            link?.key?.toLowerCase() === module?.moduleKey?.toLowerCase()
        )
      );

      setViewButton(linksToDisplay.length > 0);
      setLoading(false);
    };

    setDateRange({
      startDateRange: dayjs().startOf("isoWeek"),
      endDateRange: dayjs().endOf("isoWeek"),
    });

    showViewBtnFunction();
  }, [modules, branchSideBarModulesArray]);

  const { branchDashboardData, branchGapsData, pendingJobApprovalData } =
    useSelector((state) => state.branches);

  const DashboardHeaderData = useMemo(() => {
    const { branch_jobs, branch_staff, activeLocums, locum_expenses } =
      branchDashboardData || {};

    const activeJobs =
      branch_jobs?.find((val) => val.status === "active")?.total_jobs || 0;
    const pendingJobs = pendingJobApprovalData?.count || 0;

    var locumStaffGrouped = mapValues(
      groupBy(activeLocums?.locum_jobs, "roleType.label"),
      (staffList) => staffList.map((staff) => staff)
    );

    const staffs = {
      branch_staff: Number(branch_staff?.count) ?? 0,
      locum_staff: {
        pharmacist: Number(locumStaffGrouped["Locum Pharmacist"]?.length) ?? 0,
        technician: locumStaffGrouped["Locum Technician"]?.length ?? 0,
      },
    };

    const locumPharmacist = activeLocums?.locum_jobs?.filter(
      (item) => item?.roleId === 4
    );
    const locumtechnician = activeLocums?.locum_jobs?.filter(
      (item) => item?.roleId === 5
    );

    return {
      activeJobs,
      pendingJobs,
      staffs,
      branchJobs: branch_jobs,
      locumExpenses: locum_expenses,
      activeLocums: {
        locumPharmacist: locumPharmacist?.length,
        locumtechnician: locumtechnician?.length,
      },
    };
  }, [branchDashboardData, pendingJobApprovalData]);

  const pendingJobsColumns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "startDate",
        Cell: ({ row: { original } }) => {
          return moment(original.startDate).format("DD-MM-YYYY");
        },
      },

      {
        Header: "Job ID",
        accessor: "id",
      },
      {
        Header: "Branch",
        accessor: "branchTitle",
      },
      {
        Header: "Duration",
        accessor: "startTime",
        Cell: ({ row: { original } }) => {
          return original.startTime + " - " + original.endTime;
        },
      },
      {
        Header: "Total Rate",
        accessor: "hourlyRate",
        Cell: ({ row: { original } }) => {
          return `£${original?.hourlyRate}`;
        },
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row: { original } }) => {
          return (
            <img
              style={{ cursor: "pointer" }}
              src={actionBtnArr}
              alt=""
              onClick={() => {
                navigate(
                  generatePath(RouterConfig.branchAdminJobPreview, {
                    shiftId: original.id,
                  })
                );
              }}
            />
          );
        },
      },
    ],
    []
  );

  const brancGapColumns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ row: { original } }) => {
          return moment(original.date).format("DD-MM-YYYY") ?? "---";
        },
      },
      {
        Header: "Time Slot",
        accessor: "timeSlot",
        Cell: ({ row: { original } }) => {
          return `${original.startTime ?? "---"} - ${
            original.endTime ?? "---"
          }`;
        },
      },
      {
        Header: "Missing Hours",
        accessor: "missingHours",
        Cell: ({ row: { original } }) => {
          return <p>{original.duration ?? "---"}</p>;
        },
      },
      {
        Header: "",
        accessor: "action",

        Cell: ({ row: { original } }) => {
          if (
            hasAccess(branchApiKeys.CREATE_BULK_JOB) ||
            hasAccess(branchApiKeys.SAVE_SHIFT_BULK)
          )
            return (
              <div className="d-flex justify-content-center">
                <CustomIconButton
                  icon={<AddIcon />}
                  onClick={() => {
                    setGapData(original);
                    setShowAddShiftModal(!showAddShiftModal);
                  }}
                />
              </div>
            );
          else return <></>;
        },
      },
    ],
    []
  );

  const branchExpenses = useMemo(() => {
    const expenses = branchDashboardData?.locum_expenses;

    if (!expenses || expenses.length === 0) {
      return { totalExpense: 0, highestExpense: 0 };
    }

    const totalExpense = expenses.reduce(
      (sum, item) => Number(sum) + Number(item.locum_expenses),
      0
    );

    const highestExpense = expenses.reduce(
      (maxBranchExpense, currentBranch) => {
        return Number(currentBranch.locum_expenses) >
          Number(maxBranchExpense.locum_expenses)
          ? currentBranch
          : maxBranchExpense;
      },
      expenses[0]
    );

    return {
      totalExpense,
      highestExpense: Number(highestExpense.locum_expenses),
    };
  }, [branchDashboardData?.locum_expenses]);

  const viewDetailsBtnHandler = () => {
    navigate(generatePath(RouterConfig.branchAdminBranchInformation, { id }));
  };

  const FilterPopover = () => {
    return (
      <div className="filter-popup-wrapper">
        <div className="popup-header modal-header">
          <Typography.Title level={5} className="modal-title">
            Filter
          </Typography.Title>
          <CustomIconButton
            icon={<CloseIcon />}
            onClick={() => setOpenPopover(!openPopover)}
          />
        </div>

        <div className="export-gap-modal popup-body">
          <div className="range-picker-wrap filter-modal">
            <Form.Item label="Start Date" name="filterDateRange" colon={false}>
              <RangePicker
                // disabled={gapData}
                // disabledDate={data && disabledDate}
                // onCalendarChange={(val) => {
                //   setDates(val);
                // }}
                // value={dates}
                // onOpenChange={data && onOpenChange}
                value={[
                  filterDateRange?.startDateRange,
                  filterDateRange?.endDateRange,
                ]}
                onChange={(value) => {
                  setFilterDateRange({
                    startDateRange: value[0],
                    endDateRange: value[1],
                  });
                }}
                allowClear={false}
                inputReadOnly
                className="text-input-field px-3 t1 picker"
                popupStyle={{ zIndex: 1000000 }}
                // // format={customFormat}
                superNextIcon={null}
                superPrevIcon={null}
                suffixIcon={null}
                prevIcon={<img src={ArrowLeftIcon} alt="calendar" />}
                nextIcon={<img src={ArrowrightIcon} alt="calendar" />}
                separator={null}
                // renderExtraFooter={CalenderPopupFooter}
              />
            </Form.Item>
          </div>
        </div>
        <div className="popup-footer modal-footer">
          <div className="b1 d-flex justify-content-between align-items-center add-shift-footer gap-3">
            <Button
              className="p-2 btn-style secondary-btn"
              block
              onClick={() => setOpenPopover(!openPopover)}
            >
              Cancel
            </Button>

            <Button
              className="p-2 btn-style primary-btn"
              type="primary"
              htmlType="submit"
              block
              onClick={() => {
                setDateRange(filterDateRange);
                setFlag(true);
                setOpenPopover(!openPopover);
              }}
            >
              Apply Filter
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const { rows: pendingJobsTableData } = useMemo(
    () => pendingJobApprovalData,
    [pendingJobApprovalData]
  );

  useEffect(() => {
    if (!dateRange || !flag) return;

    if (hasAccess(branchApiKeys.BRANCH_DASHBOARD)) {
      dispatch(
        getBranchDashboardDetailThunk({
          id,
          startDate: dateRange?.startDateRange.format("YYYY-MM-DD"),
          endDate: dateRange?.endDateRange.format("YYYY-MM-DD"),
        })
      );
    }

    if (hasAccess(branchApiKeys.BRANCH_GAPS)) {
      dispatch(
        getBranchGapsDataThunk({
          id,
          startDateRange: dateRange?.startDateRange.format("YYYY-MM-DD"),
          endDateRange: dateRange?.endDateRange.format("YYYY-MM-DD"),
        })
      );
    }

    if (hasAccess(locumShiftApiKeys.GET_JOB_LIST)) {
      dispatch(
        getPendingJobDetailThunk({
          filters: {
            branchId: Number(id),
            status: "pending",
            startDate: dateRange?.startDateRange.format("YYYY-MM-DD"),
            endDate: dateRange?.endDateRange.format("YYYY-MM-DD"),
          },
        })
      );
      setFlag(false);
    }
  }, [dateRange, dispatch, id, flag]);

  useEffect(() => {
    if (hasAccess(settingsApiKeys.GET_SETTINGS_ALL_MILEAGE_RULES))
      dispatch(getMileageRulesThunk());
    if (hasAccess(settingsApiKeys.GET_SETTINGS_PARKING_RULES))
      dispatch(getParkingRulesThunk());
    if (hasAccess(settingsApiKeys.GET_ALL_TRANSPORT_RULES))
      dispatch(getTransportRulesThunk());
    if (hasAccess(settingsApiKeys.GET_ALL_FOOD_RULES))
      dispatch(getAccommodationAndFoodRulesThunk());
    if (hasAccess(regionApiKeys.GET_REGION_DROP_DOWN_LIST))
      dispatch(getRegionsListDropDownThunk());
    dispatch(
      getRolesThunk({
        group: ["staff", "locum"],
        type: "Default",
      })
    );
    if (hasAccess(staffApiKeys.GET_ALL_STAFF)) dispatch(getStaffsThunk());
    if (hasAccess(branchApiKeys.GET_BRANCH)) {
      dispatch(getBranchDataThunk({ id }));
    }
  }, [dispatch, id]);

  const downloadGapsCSV = () => {
    dispatch(
      exportBranchGapsCSVThunk({
        id,
        filter: {
          startDateRange: dateRange?.startDateRange?.format("YYYY-MM-DD"),
          endDateRange: dateRange?.endDateRange?.format("YYYY-MM-DD"),
        },
      })
    ).then((res) => {
      if (res.payload.status === "success") {
        let fetchDataModified = `data:application/csv;base64,${res.payload.data}`;
        let a = document.createElement("a");
        a.href = fetchDataModified;
        a.download = "result.csv";
        a.click();
        return;
      }
    });
  };

  return (
    <div className="content-view-page details-page">
      {branchDashboardData && !branchDashboardData?.active && (
        <WarningBar>
          This Branch Does Not Seem To Have An Active Subscription, To Enjoy
          Un-Interrupted Access, Please
          <u
            className="cursor-pointer"
            onClick={() => navigate("/profile/subscription")}
          >
            Activate Your Subscription!
          </u>
        </WarningBar>
      )}

      <div className="main-wrapper">
        <div className="dashboard-page">
          <Heading
            label={branchDashboardData?.title}
            dateFilter
            lastupated={"10 min"} // needs to be dynamic
            viewDetailsBtn
            viewDetailsBtnHandler={viewDetailsBtnHandler}
            filterChildren={
              <Popover
                content={FilterPopover}
                title=""
                trigger="click"
                showArrow={false}
                placement="bottomLeft"
                open={openPopover}
                onOpenChange={() => setOpenPopover(!openPopover)}
              >
                <Button className="date-filter-button">
                  <Space>
                    <i>
                      <CalendarIconNormal />
                    </i>
                    <p>
                      {`${dayjs(dateRange?.startDateRange).format(
                        "DD MMM, YYYY"
                      )} - 
                     ${dayjs(dateRange?.endDateRange).format("DD MMM, YYYY")}`}
                    </p>

                    <i>
                      <DropdownIcon />
                    </i>
                  </Space>
                </Button>
              </Popover>
            }
            view="weekly"
          />

          <div className="content-container-dashboard p-4">
            <div
              className="d-flex"
              style={{ flex: 1, gap: "15px", margin: "20px 0px" }}
            >
              <div
                className="d-flex "
                style={{
                  flex: 1,
                  gap: "15px",
                  justifyContent: "space-between",
                }}
              >
                {/* Active Jobs */}
                <CommonDataTab
                  Title={"Active Jobs"}
                  icon={<BriefCaseIcon />}
                  iconBackground={"#E1F4F4"}
                >
                  <Space size={16}>
                    <Typography.Title level={4}>
                      {DashboardHeaderData?.activeJobs}
                    </Typography.Title>
                    {/* <CustomTag
                      text="23%" // this needs to be dynamic
                      icon={<UpArrowIcon />}
                      color="success"
                    /> */}
                  </Space>
                </CommonDataTab>

                {/* Pending Job Approvals */}
                <CommonDataTab
                  Title={"Pending Job Approvals"}
                  icon={<ClockIconPurple />}
                  iconBackground={"#F4EBFF"}
                >
                  <Typography.Title level={4}>
                    {DashboardHeaderData?.pendingJobs}
                  </Typography.Title>
                </CommonDataTab>

                {/* Active Locums */}
                <CommonDataTab
                  Title={"Active Locums"}
                  icon={<UserTagIcon />}
                  iconBackground={"#E2E4EC"}
                >
                  <Space className="locum-card-space-items">
                    <div className="separated-item">
                      <Typography.Title level={4}>
                        {/* {DashboardHeaderData?.staffs?.locum_staff?.pharmacist ||
                          0} */}
                        {DashboardHeaderData?.activeLocums?.locumPharmacist ||
                          0}
                      </Typography.Title>
                      <p>Pharmacists</p>
                    </div>
                    <Divider type="vertical" style={{ height: "100%" }} />
                    <div className="separated-item">
                      <Typography.Title level={4}>
                        {DashboardHeaderData?.activeLocums?.locumtechnician ||
                          0}
                        {/* {DashboardHeaderData?.staffs?.locum_staff?.technician} */}
                      </Typography.Title>
                      <p>Technician</p>
                    </div>
                  </Space>
                </CommonDataTab>

                {/* Active Staff */}
                <CommonDataTab
                  Title={"Active Staffs"}
                  icon={<BuildingsIcon />}
                  iconBackground={"#E3F2FD"}
                >
                  <Typography.Title level={4}>
                    {DashboardHeaderData?.staffs?.branch_staff}
                  </Typography.Title>
                </CommonDataTab>
              </div>
            </div>

            <Row gutter={[24, 24]}>
              <Col lg={16}>
                <Row gutter={[24, 24]}>
                  {/** Branch Gaps */}
                  <Col span={24}>
                    <DashboardTableContainer
                      title={`Branch Gaps : ${branchGapsData?.length}`}
                      tableRight={
                        <Space size={1}>
                          <Tooltip title="Export CSV">
                            <CustomIconButton
                              icon={<DocumentDownloadBtn />}
                              onClick={handleShowExportModal}
                            />
                          </Tooltip>
                          <Divider type="vertical" />
                          <Tooltip title={DASHBOARD_DETAILS_DATA.pharmacy_gap}>
                            <CustomIconButton icon={<InfoCircleIcon />} />
                          </Tooltip>
                        </Space>
                      }
                    >
                      {branchGapsData?.length ? (
                        <DashboardCommonTable
                          tableCols={brancGapColumns}
                          data={branchGapsData}
                        />
                      ) : (
                        <EmptyData
                          img={blankPharmacy}
                          description={"No Branch Gaps"}
                        />
                      )}
                    </DashboardTableContainer>
                  </Col>

                  {/* Pending Job Approvals */}
                  <Col lg={24}>
                    <DashboardTableContainer
                      title={`Pending Job Approvals : ${pendingJobsTableData?.length}`}
                      tableRight={
                        <Tooltip title={DASHBOARD_DETAILS_DATA.pending_jobs}>
                          <CustomIconButton icon={<InfoCircleIcon />} />
                        </Tooltip>
                      }
                    >
                      {pendingJobsTableData?.length ? (
                        <DashboardCommonTable
                          tableCols={pendingJobsColumns}
                          data={pendingJobsTableData}
                        />
                      ) : (
                        <EmptyData
                          img={blankJobApproval}
                          description={"No Jobs In Pending Status"}
                        />
                      )}
                    </DashboardTableContainer>
                  </Col>
                </Row>
              </Col>

              <Col lg={8}>
                <Row gutter={[24, 24]}>
                  {/* Expense Overview */}
                  <Col lg={24}>
                    <DashboardChartcontainer
                      title="Expense Overview"
                      subtitle={`£${Number(branchExpenses.totalExpense).toFixed(
                        2
                      )}`}
                    >
                      {DashboardHeaderData?.locumExpenses?.length > 0 ? (
                        <ExpenseBarChart
                          barChartData={DashboardHeaderData?.locumExpenses}
                        />
                      ) : (
                        <EmptyData
                          img={blankExpense}
                          description="No Expenses"
                        />
                      )}
                    </DashboardChartcontainer>
                  </Col>

                  {/* Employees */}
                  <Col lg={24}>
                    <DashboardChartcontainer
                      title="Employees"
                      subtitle={
                        branchDashboardData?.branch_staff?.count +
                        branchDashboardData?.activeLocums?.total_locums
                      }
                    >
                      {branchDashboardData?.branch_staff?.count ||
                      branchDashboardData?.activeLocums?.total_locums ? (
                        <TotalStaffChart
                          totalBranches={1}
                          item1={{
                            name: "Permanent Staff",
                            value: branchDashboardData?.branch_staff?.count,
                          }}
                          item2={{
                            name: "Locum",
                            value:
                              branchDashboardData?.activeLocums?.total_locums,
                          }}
                        />
                      ) : (
                        <EmptyData
                          img={blankStaff}
                          description="No Employees"
                        />
                      )}
                    </DashboardChartcontainer>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {showAddShiftModal && (
        <ShiftJobFormNew
          isGap={true}
          gapData={gapData}
          onSuccess={() => setFlag(true)}
          branchId={gapData?.branchId}
          selectedDate={gapData?.date}
          showModal={showAddShiftModal}
          setHandleModal={() => setShowAddShiftModal(!showAddShiftModal)}
          operatingTime={branchInfo?.operatingTime}
        />
      )}

      {/* {showAddShiftModal && (
        <ShiftJobFormUpdated
          isGap={true}
          gapData={gapData}
          onSuccess={() => setFlag(true)}
          branchId={gapData?.branchId}
          selectedDate={gapData?.date}
          showModal={showAddShiftModal}
          setHandleModal={() => setShowAddShiftModal(!showAddShiftModal)}
        />
      )} */}
    </div>
  );
};
