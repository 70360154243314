import { Form, Input, Select } from "antd";
import React, { useState } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddModal } from "../../../../../../components/UI/modals/addModal";
import { CheckboxGroup } from "../../../../../../components/UI/checkboxes/checkboxGroup";
import "./style.scss";
import {
  addNewAdmin,
  addNewRole,
} from "../../../../../../redux/profile/profileAPI";
import { hasAccess } from "../../../../../../utils/helper";
import { inviteApiKeys } from "../../../../../../utils/apiKeyConstants";

export const CreateAdminStaff = ({
  showCreateModal,
  handleModalToggle,
  form,
  actionType,
  id,
  getAdminStaff,
}) => {
  const formId = useMemo(() => "addadmin", []);
  const [formData, setFormData] = useState();
  const { regions } = useSelector((state) => state.staffs.staffRelatedData);
  const { adminRoles } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const { isloading } = useSelector((state) => state.loader);

  const adminRoles1 = [
    { id: 1, title: "Branch Admin" },
    { id: 2, title: "Finance Admin" },
    { id: 3, title: "Admin" },
  ];
  const RegionsOptions = [
    { id: 1, Value: "London", label: "London" },
    { id: 2, Value: "South East", label: "South East" },
    { id: 3, Value: "North West", label: "North West" },
    { id: 4, Value: "East of England", label: "East of England" },
  ];
  const londonDetailsCheckboxItems = [
    {
      id: 1,
      label: "Pharmacy 1",
      name: `Pharmacy1`,
      onChange: (e, { checked }) => {
        onChangeHandler(e, {
          name: "columns",
          value: { checked, id: "Pharmacy1" },
        });
      },
    },
    {
      id: 2,
      label: "Pharmacy 2",
      name: `Pharmacy2`,
      onChange: (e, { checked }) => {
        onChangeHandler(e, {
          name: "columns",
          value: { checked, id: "Pharmacy2" },
        });
      },
    },
  ];
  const onChangeHandler = (syntheticEvent, eventObj) => {
    const { name, value } =
      eventObj === undefined ? syntheticEvent.target : eventObj;

    if (name === "columns") {
      const { checked, id } = value;
      let selectedCheckbox = formData?.columns || [];

      if (checked) {
        selectedCheckbox.push(id);
      } else {
        const index = selectedCheckbox.indexOf(id);
        if (index > -1) {
          selectedCheckbox.splice(index, 1);
        }
      }
      setFormData({
        ...formData,
        [name]: selectedCheckbox,
      });
      return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const onFinish = async (values) => {
    if (actionType === "Add") {
      const payload = {
        branchId: 0,
        name: values.name,
        surname: values.surname,
        email: values.email,
        phone: values.phone,
        regionId: 0,
        roleId: values.adminrole,
      };
      const response = await addNewAdmin(payload);
      if (response.data.status === "success") {
        getAdminStaff();
        handleModalToggle();
      }
    } else {
      const role = adminRoles.find((role) => role.label === values.adminrole);
      const payload = {
        staffId: id,
        branchId: 0,
        name: values.name,
        surname: values.surname,
        email: values.email,
        phone: values.phone,
        regionId: 0,
        roleId: role ? role.id : values.adminrole,
      };
      const response = await addNewAdmin(payload);
      if (response.data.status === "success") {
        getAdminStaff();
      }
    }
  };
  return (
    <AddModal
      formId={formId}
      title={
        actionType === "Edit"
          ? "Update Admin"
          : actionType === "Add" && hasAccess(inviteApiKeys.ADD_ADMIN)
            ? `Add New Admin`
            : undefined
      }
      handleModal={showCreateModal}
      setHandleModal={handleModalToggle}
      primaryButtonText={actionType === "Add" ? `Add` : "Update"}
      primaryButtonHandler={form.submit}
      secondaryButtonText="Cancel"
      secondaryButtonHandler={handleModalToggle}
      isLoading={isloading}
    >
      <div style={{ width: "100%" }}>
        <Form
          formId={formId}
          onFinish={onFinish}
          form={form}
          initialValues={{}}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            label="First Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your First Name!",
              },
            ]}
          >
            <Input
              onBlur={(e) =>
                form.setFieldsValue({ name: e.target.value.trim() })
              }
              type="text"
              className="text-input-field p-2 t1"
              placeholder="First Name"
            />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="surname"
            rules={[
              {
                required: true,
                message: "Please input your Last Name!",
              },
            ]}
          >
            <Input
              onBlur={(e) =>
                form.setFieldsValue({ surname: e.target.value.trim() })
              }
              type="text"
              className="text-input-field p-2 t1"
              placeholder="First Name"
            />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phone"
            rules={[
              {
                required: true,

                message: "Phone Number is required!",
              },
              {
                pattern: /^(?:\d*)$/,
                message: "Value should contain just number",
              },
              { min: 8, message: "Phone number must be minimum 8 characters." },
            ]}
          >
            <Input
              type="number"
              onBlur={(e) =>
                form.setFieldsValue({ phone: e.target.value.trim() })
              }
              className="text-input-field p-2 t1"
              placeholder="Phone Number"
            />
          </Form.Item>

          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input
              className="text-input-field p-2 t1"
              onBlur={(e) =>
                form.setFieldsValue({ email: e.target.value.trim() })
              }
              placeholder="Email Address"
            />
          </Form.Item>
          <Form.Item
            label="Admin Role"
            name="adminrole"
            rules={[{ required: true, message: "Please Select Admin role!" }]}
          >
            <Select
              dropdownStyle={{ zIndex: 2000 }}
              placeholder="Admin Role"
              bordered={false}
              className="text-input-field pe-3 t1"
            >
              {adminRoles.map((data, index) => (
                <Select.Option key={index} value={data.id}>
                  {data.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </div>
    </AddModal>
  );
};
