import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { authReducer } from "./auth/authSlicer";
import { branchesReducer } from "./branches/branchesSlicer";
import { dashboardReducer } from "./dashboard/dashboardSlicer";
import { loaderReducer } from "./loader";
import { locumBankReducer } from "./locum-bank/locumBankSlicer";
import { locumFlowReducer } from "./locum-flow/Slicer";
import { locumShiftsReducer } from "./locum-shifts/locumShiftsSlicer";
import { profileReducer } from "./profile/profileSlicer";
import { regionsReducer } from "./regions/regionsSlicer";
import { scheduleReducer } from "./schedule/scheduleSlicer";
import { staffsReducer } from "./staffs/staffsSlicer";
import { superAdminReducer } from "./super-admin/superAdminSlice";
import { combineReducers } from "redux";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    'auth'
  ],
};
const reducers = combineReducers({
  dashboard: dashboardReducer.reducer,
  superAdmin: superAdminReducer.reducer,
  loader: loaderReducer.reducer,
  auth: authReducer.reducer,
  schedule: scheduleReducer.reducer,
  branches: branchesReducer.reducer,
  regions: regionsReducer.reducer,
  locumBank: locumBankReducer.reducer,
  staffs: staffsReducer.reducer,
  locumShifts: locumShiftsReducer.reducer,
  profile: profileReducer.reducer,
  locum: locumFlowReducer.reducer,
});

export const rootReducer = (state, action) => {
  if (action.type === 'logOutUserThunk/fulfilled') {
    return reducers({
      dashboard: dashboardReducer.getInitialState(),
      superAdmin: superAdminReducer.getInitialState(),
      loader: loaderReducer.getInitialState(),
      auth: authReducer.getInitialState(),
      schedule: scheduleReducer.getInitialState(),
      branches: branchesReducer.getInitialState(),
      regions: regionsReducer.getInitialState(),
      locumBank: locumBankReducer.getInitialState(),
      staffs: staffsReducer.getInitialState(),
      locumShifts: locumShiftsReducer.getInitialState(),
      profile: profileReducer.getInitialState(),
      locum: locumFlowReducer.getInitialState(),
    }, action);
  }
  return reducers(state, action);
};


const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'production' ? false : true,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
});
