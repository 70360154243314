import React from "react";

const DropdownIcon = ({rotate}) => {
  return (
    <div style={{transform:`rotate(${rotate}deg)`}}>
     
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
      >
        <path
          d="M5.5 7.5L10.5 12.5L15.5 7.5"
          stroke="#667085"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default DropdownIcon;
