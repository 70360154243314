export const prepareRightToWorkData = (rightToWorkObj) => {
  let temp = [];
  if (!!rightToWorkObj) {
    temp = [
      {
        id: 1,
        accreditationName: "Enhanced DBS",
        accreditationLabel: "DBSCertificate",
        filePath: rightToWorkObj?.DBSCertificate,
        expiryDate: rightToWorkObj?.DBSCertificateDate,
        date_key: "DBSCertificateDate",
        isExpiryRequired: 1,
        document_type: "document",
      },
      {
        id: 9,
        accreditationName: "Enhanced DBS Number",
        accreditationLabel: "DBSAnnualUpdate",
        filePath: null,
        expiryDate: null,
        value: rightToWorkObj?.DBSAnnualUpdate,
        date_key: "",
        isExpiryRequired: 0,
        document_type: "number",
        number_key: "DBSAnnualUpdate",
        number_label: "DBS Annual Update",
      },
      {
        id: 2,
        accreditationName: "Identification",
        accreditationLabel: "identification",
        filePath: rightToWorkObj?.identification,
        expiryDate: rightToWorkObj?.identificationExpDate,
        isExpiryRequired: 1,
        date_key: "identificationExpDate",
        document_type: "document",
      },
      {
        id: 3,
        accreditationName: "Insurance Document",
        accreditationLabel: "insuranceNumberDoc",
        filePath: rightToWorkObj?.insuranceNumberDoc,
        expiryDate: null,
        isExpiryRequired: 0,
        document_type: "document",
      },
      {
        id: 10,
        accreditationName: "Insurance Number",
        accreditationLabel: "insuranceNumber",
        filePath: null,
        expiryDate: null,
        value: rightToWorkObj?.insuranceNumber,
        date_key: "",
        isExpiryRequired: 0,
        document_type: "number",
        number_key: "insuranceNumber",
      },
      {
        id: 4,
        accreditationName: "Indemnity Insurance",
        accreditationLabel: "professionalInsurance",
        filePath: rightToWorkObj?.professionalInsurance,
        expiryDate: rightToWorkObj?.professionalInsuranceExpDate,
        isExpiryRequired: 1,
        date_key: "professionalInsuranceExpDate",
        document_type: "document",
      },
      {
        id: 5,
        accreditationName: "Work Visa",
        accreditationLabel: "visa",
        filePath: rightToWorkObj?.visa,
        expiryDate: rightToWorkObj?.visaExpDate,
        date_key: "visaExpDate",
        isExpiryRequired: 1,
        document_type: "document",
      },
      {
        id: 6,
        accreditationName: "NVQ Level Two",
        accreditationLabel: "NVQLevelTwo",
        filePath: rightToWorkObj?.NVQLevelTwo,
        expiryDate: null,
        document_type: "document",
        isExpiryRequired: 0,
      },
      {
        id: 7,
        accreditationName: "Qualifications",
        accreditationLabel: "qualifications",
        filePath: rightToWorkObj?.qualifications,
        expiryDate: rightToWorkObj?.qualificationsYear,
        isExpiryRequired: 1,
        date_key: "qualificationsYear",
        document_type: "document",
      },
      {
        id: 8,
        accreditationName: "Right To Work",
        accreditationLabel: "rightToWork",
        filePath: rightToWorkObj?.rightToWork,
        expiryDate: null,
        document_type: "document",
        isExpiryRequired: 0,
      },
    ];
  }

  return temp;
};

export const extractFileName = (filePath, labelName) => {
  let fileName = "";

  // Extract the file name from the URL
  let fileNameWithExtension = filePath?.split("/").pop();

  // Extract the file extension
  let fileExtension = fileNameWithExtension?.split(".").pop();

  // Remove the file extension from the file name
  let fileNameWithoutExtension = fileNameWithExtension?.replace(
    `.${fileExtension}`,
    ""
  );

  // Concatenate label, file name without extension, and file extension
  fileName = `${labelName}.${fileExtension}`;

  return fileName;
};

export const enhanceAccreditations = (accreditations, enhancements) => {
  return (
    accreditations?.map((accr) => {
      const enhancedAccr = {};
      for (const key in enhancements) {
        if (typeof enhancements[key] === "function") {
          enhancedAccr[key] = enhancements[key](accr);
        } else {
          enhancedAccr[key] = enhancements[key];
        }
      }
      return {
        ...accr,
        ...enhancedAccr,
      };
    }) || []
  );
};

export const prepareFilePreview = (image, name) => {
  return image
    ? {
        fileList: [
          {
            uid: Math.random(),
            name: name || image,
            status: "done",
            url: image,
          },
        ],
      }
    : undefined;
};

export const findAnswer = (data, id, key) => {
  if (data?.length > 0) {
    const _t = data?.find((item) => item?.accreditationRuleId === id);

    return _t[key] || null;
  }
  return null;
};

export const allowedAccreditations = [
  { roles: [4, 5], label: "professionalInsurance" },
  { roles: [4, 6], label: "DBSCertificate" },
  { roles: [8], label: "NVQLevelTwo" },
  { labels: ["proofOfId", "visa", "insuranceNumberDoc"] },
];
