import { Tag } from "antd";

import { JobStatuses } from "../../utils/constant";
import BlueTickIcon from "../UI/icons/BlueTickIcon";
import CancelIcon from "../UI/icons/CancelIcon";
import PaymentCompletedIcon from "../UI/icons/PaymentCompletedIcon";
import PaymentPendingIcon from "../UI/icons/PaymentPendingIcon";
import YellowClock from "../UI/icons/YellowClock";
import "./style.scss";

const statusMappings = {
  unfilled: { className: "unfilled", icon: null },
  pending: { className: "pending", icon: <YellowClock /> },
  assigned: { className: "assigned", icon: <BlueTickIcon /> },
  payment_pending: {
    className: "payment-pending",
    icon: <PaymentPendingIcon />,
  },
  cancelled: { className: "cancelled", icon: <CancelIcon /> },
  completed: { className: "completed", icon: <PaymentCompletedIcon /> },
};
const statusMapping = (status) =>
  statusMappings[status] || statusMappings.completed;

const LocumJobStatusChip = ({ status, handleOnclick }) => {
  return (
    <div className="tag-wrap">
      <Tag
        className={statusMapping(status).className}
        icon={statusMapping(status).icon}
        onClick={handleOnclick}
        style={{ cursor: "pointer" }}
      >
        {JobStatuses[status]}
      </Tag>
    </div>
  );
};

export default LocumJobStatusChip;
