import { Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import NoBranchImg from "../../../assets/img/NoStaff.png";
import { generateRandomNumber } from "../../../utils/helper";
const NoStaff = ({ text, subText, hideButton }) => {
  const navigate = useNavigate();
  return (
    <div className=" d-flex align-items-center justify-content-center flex-column px-2 py-4">
      <img src={NoBranchImg} alt="nobranch" className="mb-5" />
      <Typography.Title level={3} className="mb-2">
        {text}
      </Typography.Title>
      <p
        className="text-center mb-4"
        style={{
          maxWidth: "448px",
          color: "#7B7B7B",
        }}
      >
        {subText}
      </p>

      {!Boolean(hideButton) && (
        <button
          onClick={() =>
            navigate(`/staff?addstaff=${generateRandomNumber()}`, {
              replace: true,
            })
          }
          className="btn custom_btn no-branch-btn"
        >
          Add Staff
        </button>
      )}
    </div>
  );
};

export default NoStaff;
