import { Button, Form, Radio, Switch, Tooltip } from "antd";
import React, { memo, useCallback, useEffect, useState } from "react";
import ArrowrightIcon from "../../assets/icons/arrow-right.png";
import ArrowLeftIcon from "../../assets/icons/back.png";
import Clipboardicon from "../../assets/icons/clipboard-text.svg";
import CopyIcon from "../../assets/icons/copy.svg";
import Dropwdown from "../../assets/icons/downDrop.svg";
import HelpCircle from "../../assets/icons/help-circle.svg";
import infoIcon from "../../assets/icons/infoIcon1.png";
import NoteAddIcon from "../../assets/icons/note-add.svg";
import { AddModal } from "../UI/modals/addModal";

import { AutoComplete, Checkbox, DatePicker, Input, Select } from "antd";
import dayjs from "dayjs";
import moment from "moment/moment";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createJobBulkThunk,
  createShiftBulkThunk,
  editJobThunk,
  editShiftThunk,
  searchLocumByFilterThunk,
} from "../../redux/schedule/scheduleThunk";
import { branchApiKeys, locumShiftApiKeys } from "../../utils/apiKeyConstants";
import { copyToClipBoard, hasAccess, notify } from "../../utils/helper";
import { ConfirmModal } from "../UI/modals/confirmModal";

import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import _ from "lodash";
import {
  addTemplateShiftThunk,
  editTemplateShiftThunk,
} from "../../redux/branches/branchesThunk";

import { getDuration, weekDays } from "../../utils/dates";
import eventEmitter, { events } from "../../utils/eventEmitter";
import { validateTime, validateTimeObj } from "../../utils/timeshhet";
import TimeInput from "../UI/input/TimeInput";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
const { RangePicker } = DatePicker;

const options = [
  { value: "Shift", label: "Staff Shift" },
  { value: "Job", label: "Locum Job" },
];

export const findObj = (id, dataSet) => {
  if (dataSet?.length) {
    const temp = dataSet.find((item) => item.id === id);
    return temp;
  }

  return null;
};

export const CoverExpenseFormLabels = ({ label, data }) => {
  if (data === null) {
    return;
  }
  return (
    <div className="d-flex">
      <p className="me-1 mb-0">{label}</p>
      {!!data && (
        <Tooltip
          title={
            <>
              {!!data?.ratePerMile && (
                <>
                  <p className="mb-0">
                    Rate per Mile: {data?.ratePerMile} pence
                  </p>
                  <br />
                </>
              )}

              {!!data?.upTo && (
                <>
                  <p className="mb-0">Up to: {data?.upTo} pence</p>
                  <br />
                </>
              )}

              {!!data?.payMileageAfter && (
                <>
                  <p className="mb-0">
                    Pay Milage After: {data?.payMileageAfter} Mile
                  </p>
                  <br />
                </>
              )}

              {!!data?.maximumMileage && (
                <>
                  <p className="mb-0">
                    Maximum Millage: {data?.maximumMileage} mile
                  </p>
                  <br />
                </>
              )}
            </>
          }
        >
          <img src={HelpCircle} alt="" />
        </Tooltip>
      )}
    </div>
  );
};

export const CopyButton = ({ onClick }) => {
  const [copied, setCopied] = useState(false);

  const handleOnClick = () => {
    onClick();
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 500);
  };
  return (
    <Tooltip title={copied ? "Copied" : "Copy"}>
      {" "}
      <Button onClick={handleOnClick}>
        {copied ? (
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.5 12.5L10.167 17L19.5 8"
              stroke="#434343"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ) : (
          <img src={CopyIcon} alt="copyicon" />
        )}
      </Button>
    </Tooltip>
  );
};

export const CopyBranchTimeButton = ({ onClick }) => {
  const [copied, setCopied] = useState(false);

  const handleOnClick = () => {
    onClick();
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 500);
  };

  return (
    <Tooltip title={copied ? "Copied" : "Copy Branch Timings"}>
      {" "}
      <Button onClick={handleOnClick}>
        {copied ? (
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.5 12.5L10.167 17L19.5 8"
              stroke="#434343"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ) : (
          <img src={NoteAddIcon} alt="addnote" />
        )}
      </Button>
    </Tooltip>
  );
};

// This add shift / form follows the new design

export const ShiftJobFormNew = memo(
  ({
    gapData,
    showModal,
    setHandleModal,
    branchId,
    operatingTime,
    selectedDate,
    onSuccess,
    data,
    isTemplate,
    selectedDay,
    weekNumber,
    onDelete,
    roleTypes,
  }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const formId = useMemo(() => "jobShiftForm", []);
    const roleId = Form.useWatch("roleId", form);
    const dateRange = Form.useWatch("dateRange", form);
    const timeObj = Form.useWatch("timeObj", form);

    const hasCoverExpense = Form.useWatch("hasCoverExpense", form);
    const hasExternalLocum = Form.useWatch("hasExternalLocum", form);
    const externalLocumBooking = Form.useWatch("externalLocumBooking", form);
    const [memoDate, setMemoDate] = useState(null);
    const [dates, setDates] = useState([]);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorData, setErrorData] = useState([]);
    const [type, setType] = useState("Shift");
    const [locumRoles, setLocumRoles] = useState([]);
    const [staffRoles, setStaffRoles] = useState([]);

    const [toggleShiftTiming, settoggleShiftTiming] = useState(false);
    const { roles } = useSelector((state) => {
      return state.schedule;
    });

    const { staffs, locums } = useSelector((state) => {
      return state.schedule;
    });
    const {
      mileageRules,
      accommodationAndFoodRules,
      parkingRules,
      transportRules,
    } = useSelector((state) => state.profile);

    const [milageRuleId, setmileageRuleID] = useState(null);
    const [transportRuleId, settransportRuleId] = useState(null);
    const [parkingRuleId, setparkingRuleId] = useState(null);
    const [accommodationAndFoodRuleId, setaccommodationAndFoodRuleId] =
      useState(null);

    const locumOptions = useMemo(
      () =>
        locums
          ?.filter(
            (i) =>
              i?.roleId === roleId ||
              (roleId === 4 && i.type === "pharmacist") ||
              (roleId === 5 && i.type === "technician")
          )
          ?.map((data, idx) => {
            return {
              key: idx,
              label: `${
                data?.name ? data?.name : data?.forenames ? data?.forenames : ""
              } ${data?.surname ? data?.surname : ""}`,
              value: data?.gphcNumber,
            };
          }),
      [locums, roleId]
    );

    function capitalizeFLetter(string) {
      if (!!string && string?.length) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    }

    const setTimeObjForEdit = useCallback(
      (data) => {
        if (isTemplate) return;

        setDates([
          moment(data.startTime, "YYYY-MM-DD"),
          moment(data.endTime, "YYYY-MM-DD"),
        ]);
        setTimeout(() => {
          form.setFieldsValue({
            formId: data.id,
            paidLunch: data.paidLunch,
            description: data?.description,
            coverExpenses: {
              mileageRuleId: data?.mileageRuleId || null,
              transportRuleId: data?.transportRuleId || null,
              parkingRuleId: data?.parkingRuleId || null,
              accommodationAndFoodRuleId:
                data?.accommodationAndFoodRuleId || null,
            },
            timeObj: [
              {
                breakTime:
                  !!data.lunchStartTime && !!data.lunchStartTime
                    ? `${data.lunchStartTime}-${data.lunchEndTime}`
                    : "",
                checked: true,
                hourlyRate: data?.hourlyRate,
                lunchEndTime: data.lunchEndTime,
                lunchStartTime: data.lunchStartTime,
                time: `${data.startTime}-${data.endTime}`,
                weekDay: capitalizeFLetter(
                  data?.days
                    ? data?.days[0].dayOfWeek
                    : moment(data?.startDate, "YYYY-MM-DD").format("dddd")
                ),
                hasCoverExpense: false,
                coverExpenses: {
                  mileageRuleId: data?.mileageRuleId || null,
                  transportRuleId: data?.transportRuleId || null,
                  parkingRuleId: data?.parkingRuleId || null,
                  accommodationAndFoodRuleId:
                    data?.accommodationAndFoodRuleId || null,
                },
              },
            ],
          });

          setmileageRuleID(data?.mileageRuleId);
          settransportRuleId(data?.transportRuleId);
          setparkingRuleId(data?.parkingRuleId);
          setaccommodationAndFoodRuleId(data?.accommodationAndFoodRuleId);

          if (
            data?.mileageRuleId !== null ||
            data?.transportRuleId !== null ||
            data?.transportRuleId !== null ||
            data?.transportRuleId !== null
          ) {
            form.setFieldsValue({
              hasCoverExpense: true,
            });
          }
        }, 200);
      },
      [form, isTemplate, selectedDate]
    );

    useEffect(() => {
      eventEmitter.on(events.openEdit, setTimeObjForEdit);
      return () => {
        eventEmitter.off(events.openEdit, setTimeObjForEdit);
      };
    }, [setTimeObjForEdit]);

    useEffect(() => {
      if (type !== "Job") return;
      let filters = {};
      if (externalLocumBooking?.gphcNumber) {
        filters["name"] = externalLocumBooking?.gphcNumber;
        filters["gphcNumber"] = externalLocumBooking?.gphcNumber;
        filters["roleId"] = roleId;
      }
      dispatch(searchLocumByFilterThunk({ filters }));
    }, [dispatch, externalLocumBooking?.gphcNumber, type, roleId]);

    useEffect(() => {
      if (!selectedDate) return;
      form.setFieldsValue({
        dateRange: [dayjs(selectedDate), dayjs(selectedDate)],
      });
    }, [selectedDate]);

    useEffect(() => {
      if (isTemplate) {
        const weekDaysValue = weekDays
          .map((d) => {
            const weekTime = operatingTime?.find(
              (ot) => ot.dayOfWeek.toUpperCase() === d.toUpperCase()
            );

            const weekDay = d;
            const lunchStartTime = data
              ? data?.lunchStartTime
              : gapData
              ? gapData?.lunchStartTime
              : weekTime
              ? weekTime?.lunchStartTime
              : null;

            const lunchEndTime = data
              ? data?.lunchEndTime
              : gapData
              ? gapData?.lunchEndTime
              : weekTime
              ? weekTime?.lunchEndTime
              : null;

            const checked = d === selectedDay;

            const time =
              data || gapData
                ? `${data ? data.startTime : gapData.startTime}-${
                    data ? data.endTime : gapData.endTime
                  }`
                : ``;

            const breakTime =
              !!lunchStartTime && !!lunchEndTime
                ? `${lunchStartTime}-${lunchEndTime}`
                : "";

            return weekTime
              ? {
                  weekDay,
                  lunchStartTime,
                  lunchEndTime,
                  checked,
                  time,
                  breakTime,
                }
              : undefined;
          })
          .filter(
            (d) =>
              !_.isEmpty(d) &&
              (data ? d.weekDay.toLowerCase() === selectedDay.toLowerCase() : d)
          );

        form.setFieldsValue({
          timeObj: weekDaysValue,
          staffId: data?.staff?.id,
        });
        return;
      }

      if (!dateRange?.length) return;
      let startDate = dateRange[0];
      let endDate = dateRange[1];

      let days = [];

      while (
        moment(startDate.format("YYYY-MM-DD")).isSameOrBefore(
          endDate.format("YYYY-MM-DD")
        )
      ) {
        const day = startDate.format("dddd");
        if (!days.includes(day)) days.push(day);
        startDate = startDate.add(1, "day");
      }

      const weekDaysValue = days
        .map((d) => {
          const weekTime = operatingTime?.find(
            (ot) => ot.dayOfWeek.toUpperCase() === d.toUpperCase()
          );
          const weekDay = d;
          const lunchStartTime =
            data?.lunchStartTime ||
            gapData?.lunchStartTime ||
            weekTime?.lunchStartTime ||
            null;
          const lunchEndTime =
            data?.lunchEndTime ||
            gapData?.lunchEndTime ||
            weekTime?.lunchEndTime ||
            null;

          const checked = true;
          const time =
            data || gapData
              ? `${data ? data.startTime : gapData.startTime}-${
                  data ? data.endTime : gapData.endTime
                }`
              : ``;
          const hourlyRate = data && data.isJob ? data?.hourlyRate : null;
          return weekTime || gapData
            ? {
                weekDay,
                lunchStartTime,
                lunchEndTime,
                checked,
                time,
                hourlyRate,
              }
            : undefined;
        })
        .filter((d) => !_.isEmpty(d));

      form.setFieldsValue({
        timeObj: weekDaysValue,
        staffId: data && !data.isJob ? data?.staff?.id : null,
        description: data && data.isJob ? data.description : "",
        paidLunch: data && data.isJob ? data.paidLunch : false,
        externalLocumBooking:
          data && data.isJob
            ? {
                gphcNumber: data.staff && data?.staff[0]?.gphcNumber,
                email: data.staff && data?.staff[0]?.email,
                phone: data.staff && data?.staff[0]?.phone,
                id: data.staff && data?.staff[0]?.id,
              }
            : {},
        hasExternalLocum:
          data && data.isJob && data?.staff?.length ? true : false,
      });
    }, [
      dateRange,
      operatingTime,
      data,
      gapData,
      isTemplate,
      selectedDay,
      form,
    ]);

    useEffect(() => {
      if (!data) return;
      setType(data?.isJob ? "Job" : "Shift");
      // if (isTemplate) {
      //   const role = roles?.find((i) => i.role === data?.staff?.role);
      //   form.setFieldsValue({
      //     roleId: role.id,
      //   });
      //   return;
      // }
      form.setFieldsValue({
        roleId: data?.roleType?.id,
      });
    }, [data, isTemplate, roles]);

    useEffect(() => {
      if (!roles) return;
      const _locumRoles = roles
        .filter((item) => item.group === "locum")
        .map((i) => {
          return {
            label: i.label,
            value: i.id,
          };
        });
      const _staffRoles = roles
        .filter((item) => item.group === "staff")
        .map((i) => {
          return {
            label: i.label,
            value: i.id,
          };
        });

      if (!!roleTypes && roleTypes?.length) {
        let roleId = _staffRoles?.find((item) =>
          roleTypes?.includes(item?.value)
        );

        if (type !== "Shift") {
          roleId = _locumRoles?.find((item) => roleTypes?.includes(item?.id));
        }

        !!roleId &&
          form.setFieldsValue({
            roleId: roleId?.value,
          });
      }

      setLocumRoles(_locumRoles);
      setStaffRoles(_staffRoles);
    }, [type, roles, showModal]);

    useEffect(() => {
      if (_.isEmpty(gapData)) return;
      form.setFieldsValue({
        roleId: type === "Shift" ? 6 : 4,
      });
    }, [gapData, type]);

    useEffect(() => {
      if (!showModal && !!data) {
        form.setFieldValue("roleId", data?.roleType?.id);
      }

      return () => {
        form.resetFields();
        setType("Shift");
      };
    }, [showModal]);

    const handleCoverExpenseChange = () => {
      setmileageRuleID(null);
      settransportRuleId(null);
      setparkingRuleId(null);
      setaccommodationAndFoodRuleId(null);
      form.setFieldsValue({
        hasCoverExpense: !hasCoverExpense,
        mileageRuleId: null,
        transportRuleId: null,
        parkingRuleId: null,
        accommodationAndFoodRuleId: null,
      });
    };

    const handleCopyBranchTime = (day, timeObj, index) => {
      const findTime = operatingTime?.find(
        (item) => item?.dayOfWeek?.toLowerCase() === day?.toLowerCase()
      );

      if (!findTime) return;

      const data = _.cloneDeep(timeObj);
      const { startTime, endTime, lunchStartTime, lunchEndTime, locumRate } =
        findTime;

      data[index].checked = true;
      data[index].time = `${startTime}-${endTime}`;

      if (lunchStartTime && lunchEndTime) {
        data[index].breakTime = `${lunchStartTime}-${lunchEndTime}`;
      }

      data[index].hourlyRate = locumRate;

      form.setFieldsValue({
        timeObj: data,
      });
    };

    const addShift = (values) => {
      if (isTemplate) {
        const reqData = values.timeObj
          .filter((i) => i.checked === true)
          .map((d) => ({
            dayOfWeek: d.weekDay,
            startTime: d.time.split("-")[0],
            endTime: d.time.split("-")[1],
            staffId: values?.staffId,
            roleId,
            weekNumber: `${weekNumber}`,
            lunchStartTime: d.breakTime.split("-")[0]?.trim() || null,
            lunchEndTime: d.breakTime.split("-")[1]?.trim() || null,
          }));

        if (!data) {
          dispatch(
            addTemplateShiftThunk({
              id: branchId,
              data: reqData,
            })
          ).then((res) => {
            if (res.payload) {
              setHandleModal();
              form.resetFields();
              notify("success", "Staff Shift Created Successfully");
              onSuccess();
            }
          });
        } else {
          dispatch(
            editTemplateShiftThunk({
              branchId: Number(branchId),
              shiftId: data.id,
              data: reqData[0],
            })
          ).then((res) => {
            if (res.payload) {
              setHandleModal();
              form.resetFields();
              notify("success", "Staff Shift Edited Successfully");
              onSuccess();
            }
          });
        }

        return;
      }

      const newShifts = [];
      let newJobs = [];
      const id = data ? data?.id : undefined;
      let editableStartDate = values.dateRange[0].format("YYYY-MM-DD");
      const newEndDate = values.dateRange[1].format("YYYY-MM-DD");

      while (moment(editableStartDate).isSameOrBefore(newEndDate)) {
        const dayOfWeek = moment(editableStartDate)
          .format("dddd")
          .toLowerCase();
        const selectedWeek = values.timeObj?.find(
          (week) => week.weekDay.toLowerCase() === dayOfWeek
        );

        if (selectedWeek && selectedWeek.checked)
          if (type === "Shift") {
            newShifts.push({
              startDate: editableStartDate,
              endDate: editableStartDate,
              lunchStartTime:
                (!!selectedWeek.breakTime &&
                  selectedWeek.breakTime.split("-")[0]?.trim()) ||
                null,
              lunchEndTime:
                (!!selectedWeek.breakTime &&
                  selectedWeek.breakTime.split("-")[1]?.trim()) ||
                null,
              startTime: selectedWeek.time.split("-")[0]?.trim() || null,
              endTime: selectedWeek.time.split("-")[1]?.trim() || null,
              staffId: values.staffId,
              days: [dayOfWeek],
              roleId: values.roleId,
              // break_duration,
            });
          } else {
            newJobs.push({
              startDate: editableStartDate,
              endDate: editableStartDate,
              lunchStartTime:
                (!!selectedWeek.breakTime &&
                  selectedWeek.breakTime.split("-")[0]?.trim()) ||
                null,
              lunchEndTime:
                (!!selectedWeek.breakTime &&
                  selectedWeek.breakTime.split("-")[1]?.trim()) ||
                null,
              startTime: selectedWeek.time.split("-")[0]?.trim() || null,
              endTime: selectedWeek.time.split("-")[1]?.trim() || null,
              hourlyRate: selectedWeek.hourlyRate,
              roleId,
              description: values.description,
              paidLunch: values.paidLunch,
              branchId: branchId,
              // ...(hasCoverExpense && !data && values.coverExpenses),
              mileageRuleId: values?.coverExpenses?.mileageRuleId || null,
              transportRuleId: values?.coverExpenses?.transportRuleId || null,
              parkingRuleId: values?.coverExpenses?.parkingRuleId || null,
              accommodationAndFoodRuleId:
                values?.coverExpenses?.accommodationAndFoodRuleId || null,
              externalLocumBooking: values?.hasExternalLocum
                ? values?.externalLocumBooking
                : undefined,
              ...(data?.isJob && { hasExternalLocum }),
              ...(data?.isJob && { id: data.id }),
              ...(data && { split: true }),
            });
          }
        editableStartDate = moment(editableStartDate)
          .add(1, "day")
          .format("YYYY-MM-DD");
      }

      if (type === "Shift") {
        if (!data) {
          if (hasAccess(branchApiKeys.SAVE_SHIFT_BULK)) {
            dispatch(
              createShiftBulkThunk({
                branchId,
                data: newShifts,
              })
            ).then((res) => {
              if (res.payload.length) {
                form.resetFields();
                onSuccess();
                notify("success", "Staff Shift Created Successfully");
                setHandleModal();
                for (var shift in newShifts) {
                  window?.analytics?.track("Shift Added", newShifts[shift]);
                }
              }
              if (res.payload.code === "validation") {
                const thirdArray = newShifts.filter((elem) => {
                  return res.payload.array?.some((ele) => {
                    return ele.date !== elem.startDate;
                  });
                });
                setShowErrorModal(true);
                setErrorData(res.payload);
                for (var data in thirdArray) {
                  window?.analytics?.track("Shift Added", thirdArray[data]);
                }
              }
            });
          }
        } else {
          if (hasAccess(branchApiKeys.UPDATE_SHIFT))
            dispatch(
              editShiftThunk({
                shiftId: id,
                branchId: branchId,
                data: newShifts[0],
              })
            ).then((res) => {
              if (res.payload.length) {
                form.resetFields();
                notify("success", "Staff Shift Edited Successfully");
                onSuccess();
                setHandleModal();
              }
            });
        }
      }
      if (type === "Job") {
        if (data === undefined) {
          if (hasAccess(branchApiKeys.CREATE_BULK_JOB))
            dispatch(
              createJobBulkThunk({
                branchId,
                data: newJobs,
              })
            ).then((res) => {
              if (res.payload.length) {
                form.resetFields();
                notify("success", "Job Added Successfully");
                onSuccess();
                setHandleModal();

                for (var job in newJobs) {
                  if (window.analytics)
                    window.analytics.track("Job Added", newJobs[job]);
                }
              }
              if (res.payload.code === "validation") {
                const thirdArray = newJobs.filter((elem) => {
                  return res.payload.array?.some((ele) => {
                    return (
                      ele.date !== moment(elem.startDate).format("DD-MM-YYYY")
                    );
                  });
                });
                setShowErrorModal(true);
                setErrorData(res.payload);
                for (var data in thirdArray) {
                  window?.analytics?.track("Job Added", thirdArray[data]);
                }
              }
            });
        } else {
          dispatch(
            editJobThunk({
              jobId: id,
              data: newJobs[0],
            })
          ).then((res) => {
            if (res?.payload) {
              if (res?.payload?.code === "validation") {
                setShowErrorModal(true);
                setErrorData(res.payload);
              } else {
                form.resetFields();
                notify("success", "Job Edited Successfully");
                onSuccess();
                setHandleModal();
              }
            }
          });
        }
      }
    };

    const onSelect = (value) => {
      const data = locums.find((data) => data.gphcNumber === value);
      form.setFieldsValue({
        externalLocumBooking: {
          gphcNumber: data?.gphcNumber,
          email: data?.email,
          phone: data?.userProfile?.phone,
          id: data.type ? undefined : data?.id,
        },
      });
    };

    const disabledDate = (current) => {
      // if (!dates || !dates.length) {
      //   return false;
      // }

      // if (!current) {
      //   return false;
      // }

      // const dayOfWeek = current.format("dddd").toLowerCase();
      // const findDay = operatingTime?.find((item) => item?.day === dayOfWeek);
      // const tooLate = dates[0] && current.diff(dates[0], "days") >= 1;
      // const tooEarly = dates[1] && dates[1].diff(current, "days") >= 1;
      // return !!tooEarly || !!tooLate;

      return false;
    };

    const onOpenChange = (open) => {
      setMemoDate(form.getFieldValue("dateRange"));
      if (open) {
        // setDates([null, null]);
        // form.setFieldsValue({
        //   dateRange: null,
        // });
      } else {
        form.setFieldsValue({
          dateRange: dates?.length && dates[0] && dates[1] ? dates : memoDate,
        });
        // setDates(null);
      }
    };

    const calCulateTotal = (index) => {
      const _t =
        moment
          .duration(
            moment(timeObj[index].time.split("-")[1], "HH:mm").diff(
              moment(timeObj[index].time.split("-")[0], "HH:mm")
            )
          )
          .asHours() * parseInt(timeObj[index].hourlyRate || "0") || 0;

      return parseFloat(_t).toFixed(2);
    };

    const copyDayTimeAndBreak = (time, break_duration, hourlyRate) => {
      const data = {
        time: time,
        break: break_duration || "",
        hourlyRate: hourlyRate || "",
      };

      copyToClipBoard(JSON.stringify(data));
    };

    const pasteDayTimeAndBreak = async (field) => {
      let clipboard_data = await navigator.clipboard.readText();
      clipboard_data = JSON.parse(clipboard_data);
      const data = _.cloneDeep(timeObj);
      data[field].checked = true;
      data[field].time = clipboard_data?.time;
      data[field].breakTime = clipboard_data?.break;
      data[field].hourlyRate = clipboard_data?.hourlyRate;
      form.setFieldsValue({
        timeObj: data,
      });
    };

    return (
      <>
        <ConfirmModal
          backdrop="static"
          title="Hold up!"
          open={showErrorModal}
          confirmButtonText="Close"
          image={infoIcon}
          onConfirm={() => {
            setErrorData(undefined);
            // setHandleModal();
            // form.resetFields();
            setShowErrorModal(false);
            // onSuccess();
          }}
        >
          <div className="d-flex flex-column gap-3">
            <div>
              There seem to be a few conflicts within this schedule attempt.
              However, we've created shifts for dates prior to and following the
              date(s) of conflict.
            </div>
            <div>
              You can resolve these conflicts and schedule again for the
              following days,
            </div>
          </div>
          <div className="d-flex flex-column gap-3 align-items-center">
            {errorData?.array?.map((data, idx) => (
              <div className="d-flex align-items-center gap-2" key={idx}>
                <div style={{ whiteSpace: "nowrap" }}>{data.date}</div>
                <div style={{ border: "1px solid", height: 20 }} />
                <div>{data.day[0].toUpperCase() + data.day.slice(1)}</div>
                <div style={{ border: "1px solid", height: 20 }} />
                <div>{data.reason}</div>
              </div>
            ))}
          </div>
          {errorData?.successCount > 0 && (
            <div>
              {errorData?.successCount} Shifts/Jobs Were Scheduled Successfully
            </div>
          )}
        </ConfirmModal>

        <AddModal
          title={`${data?.id ? "Edit" : "Add"} ${type}`}
          handleModal={showModal}
          formId={formId}
          primaryButtonText={
            type === "Job" && hasAccess(locumShiftApiKeys.UPDATE_JOB)
              ? data
                ? data?.status === "unfilled" || data.status === "active"
                  ? "Edit Job"
                  : ""
                : "Add Job"
              : type === "Shift" && hasAccess(branchApiKeys.UPDATE_SHIFT)
              ? data
                ? data.status === "unfilled" ||
                  data.status === "active" ||
                  isTemplate
                  ? "Edit Shift"
                  : ""
                : "Add Shift"
              : ""
          }
          secondaryButtonText={
            data &&
            (hasAccess(locumShiftApiKeys.UPDATE_JOB) ||
              hasAccess(branchApiKeys.UPDATE_SHIFT))
              ? "Delete"
              : data && isTemplate
              ? "Delete"
              : "Cancel"
          }
          setHandleModal={setHandleModal}
          isDelete={
            data &&
            (hasAccess(locumShiftApiKeys.UPDATE_JOB) ||
              hasAccess(branchApiKeys.UPDATE_SHIFT))
              ? true
              : false
          }
          secondaryButtonHandler={
            data &&
            (hasAccess(locumShiftApiKeys.UPDATE_JOB) ||
              hasAccess(branchApiKeys.UPDATE_SHIFT))
              ? () => onDelete(data)
              : setHandleModal
          }
        >
          {!isTemplate && (
            <div className="shift_job_radio_group">
              <Radio.Group
                disabled={data ? true : false}
                className="p2 mb-3 shift_radio_group"
                options={
                  data
                    ? options
                    : hasAccess(branchApiKeys.CREATE_BULK_JOB) &&
                      hasAccess(branchApiKeys.SAVE_SHIFT_BULK)
                    ? options
                    : hasAccess(branchApiKeys.CREATE_BULK_JOB)
                    ? options.filter((option) => option.value === "job")
                    : options.filter((option) => option.value === "shift")
                }
                onChange={(e) => {
                  setType(e.target.value);
                  form.setFieldsValue({
                    roleId: undefined,
                    staffId: undefined,
                  });
                }}
                value={type}
              />
            </div>
          )}
          <Form
            form={form}
            id={formId}
            onFinish={addShift}
            layout="vertical"
            autoComplete="off"
            className="add_shift_form"
          >
            <Form.Item
              label={isTemplate ? "Select Role" : "Role"}
              name="roleId"
              rules={[
                {
                  required: true,
                  message: "You'll Have To Select A Role To Proceed!",
                },
              ]}
              className="not-required"
            >
              <Select
                disabled={gapData}
                className="text-input-field p-1 pe-3 t1"
                bordered={false}
                onChange={(value) => {
                  form.setFieldsValue({
                    roleId: value,
                    staffId: undefined,
                    hasExternalLocum: false,
                  });
                }}
                options={type === "Shift" ? staffRoles : locumRoles}
                dropdownStyle={{ zIndex: 2000 }}
                placeholder="Select Role"
                suffixIcon={<img src={Dropwdown} alt="dropdown" />}
              />
            </Form.Item>
            <Form.Item
              label="Start Date"
              name="dateRange"
              hidden={isTemplate}
              rules={
                isTemplate
                  ? undefined
                  : [
                      {
                        required: true,
                        message:
                          "You'll Have To Select A Start/End Date To Proceed!",
                      },
                    ]
              }
              // valuePropName="dateRange"
            >
              <RangePicker
                disabled={gapData}
                disabledDate={data && disabledDate}
                onCalendarChange={(val) => {
                  setDates(val);
                }}
                value={dates}
                onOpenChange={data && onOpenChange}
                allowClear={false}
                inputReadOnly
                className="text-input-field px-3 t1 picker"
                popupStyle={{ zIndex: 1000000 }}
                // format={customFormat}
                superNextIcon={null}
                superPrevIcon={null}
                suffixIcon={null}
                prevIcon={<img src={ArrowLeftIcon} alt="calendar" />}
                nextIcon={<img src={ArrowrightIcon} alt="calendar" />}
                separator={null}
                // renderExtraFooter={CalenderPopupFooter}
              />
            </Form.Item>

            <Form.List
              name="timeObj"
              rules={[
                {
                  validator: async (_, names) => {
                    await validateTimeObj(names, dates, operatingTime, type);
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => {
                return (
                  <div className="d-flex flex-column align-items-center gap-4 pt-3 border-top pb-0">
                    {fields.map((field, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex align-items-center gap-2"
                          style={{ width: "100%" }}
                        >
                          <Form.Item
                            name={[field.name, "checked"]}
                            className="m-0 add_shift_selected"
                            style={{ width: "100%" }}
                          >
                            <div
                              className="d-flex align-items-center justify-content-between "
                              style={{ width: "100%" }}
                            >
                              <div className="d-flex justify-content-start align-items-center">
                                {" "}
                                {/* {JSON.stringify(field)} */}
                                <Switch
                                  checked={
                                    timeObj && timeObj[field.name]?.checked
                                  }
                                  onChange={() => {
                                    const data = _.cloneDeep(timeObj);
                                    data[index].checked = !data[index].checked;
                                    form.setFieldsValue({
                                      timeObj: data,
                                    });
                                    settoggleShiftTiming((prev) => !prev);
                                  }}
                                />
                                <p className="selected_week">
                                  {!!timeObj &&
                                    !!timeObj[field.name]?.weekDay &&
                                    timeObj[
                                      field.name
                                    ]?.weekDay[0].toUpperCase() +
                                      timeObj[field.name].weekDay.slice(1)}
                                </p>
                              </div>

                              <div className="selected_shift_icons">
                                {" "}
                                <CopyBranchTimeButton
                                  onClick={() =>
                                    handleCopyBranchTime(
                                      !!timeObj &&
                                        !!timeObj[field.name]?.weekDay &&
                                        timeObj[
                                          field.name
                                        ]?.weekDay[0].toUpperCase() +
                                          timeObj[field.name].weekDay.slice(1),
                                      timeObj,
                                      index
                                    )
                                  }
                                />
                                <Tooltip title="Paste">
                                  {" "}
                                  <Button
                                    onClick={() =>
                                      pasteDayTimeAndBreak(field.name)
                                    }
                                  >
                                    <img src={Clipboardicon} alt="clipboard" />
                                  </Button>
                                </Tooltip>
                                <CopyButton
                                  onClick={() => {
                                    !!timeObj &&
                                      copyDayTimeAndBreak(
                                        timeObj[field.name].time,
                                        timeObj[field.name].breakTime,
                                        timeObj[field.name].hourlyRate
                                      );
                                  }}
                                />
                              </div>
                            </div>
                            {!!timeObj && timeObj[field.name]?.checked && (
                              <div className="assign_locum d-flex flex-wrap align-items-end justify-content-between gap-1">
                                <Form.Item
                                  label="Shift Timings"
                                  name={[field.name, "time"]}
                                  className="m-0"
                                  // hidden={false}
                                  style={{ width: "49%" }}
                                  validateTrigger={["onChange", "onBlur"]}
                                  rules={[
                                    {
                                      validator: async (a, names) => {
                                        if (timeObj[field.name]?.checked) {
                                          if (
                                            timeObj[field.name]?.time.length ===
                                            0
                                          ) {
                                            return Promise.reject();
                                          } else if (
                                            timeObj[field.name]?.time.length >
                                              0 &&
                                            timeObj[field.name]?.time.length <
                                              11
                                          ) {
                                            return Promise.reject();
                                          } else if (
                                            timeObj[field.name]?.time.length ===
                                            11
                                          ) {
                                            const startTime =
                                              timeObj[field.name]?.time.split(
                                                "-"
                                              )[0];
                                            const endTime =
                                              timeObj[field.name]?.time.split(
                                                "-"
                                              )[1];
                                            const d = getDuration(
                                              dayjs(startTime, "HH:mm"),
                                              dayjs(endTime, "HH:mm")
                                            );
                                            if (d < 0) {
                                              return Promise.reject();
                                            } else if (d === 0) {
                                              return Promise.reject();
                                            } else if (d > 0 && d < 5) {
                                              return Promise.reject();
                                            } else {
                                              return Promise.resolve();
                                            }
                                          } else {
                                            return Promise.resolve();
                                          }
                                        }
                                      },
                                    },
                                  ]}
                                >
                                  <Input
                                    className="text-input-field px-3 pe-3 t1"
                                    placeholder="Eg: 01:00 - 15:00"
                                    value={
                                      !!timeObj && timeObj[field.name].time
                                    }
                                    disabled={!timeObj[field.name]?.checked}
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      const input = e.target.value;
                                      const digitsOnly = input.replace(
                                        /\D/g,
                                        ""
                                      ); // remove non-digits
                                      let formattedTime = "";
                                      if (digitsOnly.length < 3) {
                                        formattedTime = digitsOnly;
                                      } else if (digitsOnly.length === 3) {
                                        formattedTime = `${
                                          digitsOnly[0]
                                        }:${digitsOnly.substring(1, 3)}`;
                                      } else if (
                                        digitsOnly.length > 3 &&
                                        digitsOnly.length < 5
                                      ) {
                                        const hours = digitsOnly.substring(
                                          0,
                                          2
                                        );
                                        const minutes = digitsOnly.substring(
                                          2,
                                          4
                                        );
                                        if (hours > 23 && minutes > 59) {
                                          formattedTime = "23:59";
                                        } else if (hours > 23) {
                                          formattedTime = `23:${minutes}`;
                                        } else if (minutes > 59) {
                                          formattedTime = `${hours}:59`;
                                        } else {
                                          formattedTime = `${hours}:${minutes}`;
                                        }
                                      } else if (digitsOnly.length < 7) {
                                        formattedTime = `${digitsOnly.substring(
                                          0,
                                          2
                                        )}:${digitsOnly.substring(
                                          2,
                                          4
                                        )}-${digitsOnly.substring(4, 6)}`;
                                      } else if (digitsOnly.length === 7) {
                                        formattedTime = `${digitsOnly.substring(
                                          0,
                                          2
                                        )}:${digitsOnly.substring(
                                          2,
                                          4
                                        )}-${digitsOnly.substring(
                                          4,
                                          5
                                        )}:${digitsOnly.substring(5, 7)}`;
                                      } else if (
                                        digitsOnly.length > 7 &&
                                        digitsOnly.length < 8
                                      ) {
                                        const hours = digitsOnly.substring(
                                          4,
                                          6
                                        );
                                        const minutes = digitsOnly.substring(
                                          6,
                                          8
                                        );
                                        if (hours > 23 && minutes > 59) {
                                          formattedTime = `${digitsOnly.substring(
                                            0,
                                            2
                                          )}:${digitsOnly.substring(
                                            2,
                                            4
                                          )}-23:59`;
                                        } else if (hours > 23) {
                                          formattedTime = `${digitsOnly.substring(
                                            0,
                                            2
                                          )}:${digitsOnly.substring(
                                            2,
                                            4
                                          )}-23:${minutes}`;
                                        } else if (minutes > 59) {
                                          formattedTime = `${digitsOnly.substring(
                                            0,
                                            2
                                          )}:${digitsOnly.substring(
                                            2,
                                            4
                                          )}-${hours}:59`;
                                        } else {
                                          formattedTime = `${digitsOnly.substring(
                                            0,
                                            2
                                          )}:${digitsOnly.substring(
                                            2,
                                            4
                                          )}-${hours}:${minutes}`;
                                        }
                                      } else {
                                        const hours = digitsOnly.substring(
                                          4,
                                          6
                                        );
                                        const minutes = digitsOnly.substring(
                                          6,
                                          8
                                        );
                                        if (hours > 23 && minutes > 59) {
                                          formattedTime = `${digitsOnly.substring(
                                            0,
                                            2
                                          )}:${digitsOnly.substring(
                                            2,
                                            4
                                          )}-23:59`;
                                        } else if (hours > 23) {
                                          formattedTime = `${digitsOnly.substring(
                                            0,
                                            2
                                          )}:${digitsOnly.substring(
                                            2,
                                            4
                                          )}-23:${minutes}`;
                                        } else if (minutes > 59) {
                                          formattedTime = `${digitsOnly.substring(
                                            0,
                                            2
                                          )}:${digitsOnly.substring(
                                            2,
                                            4
                                          )}-${hours}:59`;
                                        } else {
                                          formattedTime = `${digitsOnly.substring(
                                            0,
                                            2
                                          )}:${digitsOnly.substring(
                                            2,
                                            4
                                          )}-${hours}:${minutes}`;
                                        }
                                      }
                                      const data = _.cloneDeep(timeObj);
                                      data[index].time = formattedTime;
                                      form.setFieldsValue({
                                        timeObj: data,
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label="Break"
                                  name={[field.name, "breakTime"]}
                                  className="m-0"
                                  hidden={false}
                                  style={{ width: "49%" }}
                                  // rules={
                                  //   type !== "Shift" && hasExternalLocum
                                  //     ? []
                                  //     : undefined
                                  // }
                                  validateTrigger={["onChange", "onBlur"]}
                                  rules={
                                    type !== "Shift" && hasExternalLocum
                                      ? [
                                          {
                                            required: false,
                                            validator: async (a, names) => {
                                              await validateTime(
                                                field,
                                                timeObj
                                              );
                                            },
                                          },
                                        ]
                                      : undefined
                                  }
                                >
                                  <TimeInput
                                    className="text-input-field px-3 pe-3 t1"
                                    placeholder="Eg: 1h"
                                    onChange={(value) => {
                                      const data = _.cloneDeep(timeObj);
                                      data[index].breakTime = value;
                                      form.setFieldsValue({
                                        timeObj: data,
                                      });
                                    }}
                                    value={timeObj[index].breakTime}
                                  />
                                </Form.Item>

                                {type === "Job" && (
                                  <div className="d-flex align-items-end justify-content-between">
                                    <Form.Item
                                      label="Amount/hr"
                                      className="m-0"
                                      name={[field.name, "hourlyRate"]}
                                      hidden={false}
                                      style={{ width: "auto" }}
                                      // rules={
                                      //   type !== "Shift" && hasExternalLocum
                                      //     ? [
                                      //         {
                                      //           required: true,
                                      //           message:
                                      //             "You'll Have To Enter The Rate  To Proceed!",
                                      //         },
                                      //       ]
                                      //     : undefined
                                      // }
                                      rules={
                                        type !== "Shift"
                                          ? [
                                              {
                                                required: true,
                                                message:
                                                  "You'll Have To Enter The Rate  To Proceed!",
                                              },
                                            ]
                                          : undefined
                                      }
                                    >
                                      <Input
                                        className="text-input-field px-3 t1"
                                        placeholder="Enter pounds per hour"
                                      />
                                    </Form.Item>
                                    <div className="total">
                                      <p>Total = £{calCulateTotal(index)}</p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </Form.Item>
                        </div>
                      );
                    })}
                    <Form.ErrorList className="text-danger" errors={errors} />
                  </div>
                );
              }}
            </Form.List>
            <div className="d-flex gap-3 align-items-center border-top mt-4">
              <Form.Item
                name="paidLunch"
                className="mt-3"
                valuePropName="checked"
                hidden={type === "Shift"}
              >
                <Checkbox>Paid Lunch</Checkbox>
              </Form.Item>
              <Form.Item
                name="hasCoverExpense"
                className="mt-3"
                valuePropName="checked"
                hidden={type === "Shift"}
              >
                <Checkbox
                  checked={hasCoverExpense}
                  onChange={() => {
                    handleCoverExpenseChange();
                  }}
                >
                  Cover Expense
                </Checkbox>
              </Form.Item>
            </div>
            {type === "Job" && hasCoverExpense && (
              <div
                className="d-flex flex-column day-box"
                style={{
                  width: "100%",
                  paddingBottom: 10,
                }}
              >
                <Form.Item
                  name={["coverExpenses", "mileageRuleId"]}
                  label={CoverExpenseFormLabels({
                    label: "Mileage Rule",
                    data: findObj(milageRuleId, mileageRules),
                  })}
                  className="m-0"
                  valuePropName="checked"
                  hidden={type === "Shift"}
                >
                  <Select
                    className="text-input-field p-1 pe-3 t1 bg-white"
                    bordered={false}
                    dropdownStyle={{ zIndex: 2000 }}
                    placeholder="Mileage Rule"
                    suffixIcon={<img src={Dropwdown} alt="dropdown" />}
                    onChange={(value) => setmileageRuleID(value)}
                    value={milageRuleId}
                  >
                    {mileageRules.map((data, idx) => (
                      <Select.Option key={idx} value={data.id}>
                        {data.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={["coverExpenses", "accommodationAndFoodRuleId"]}
                  label={CoverExpenseFormLabels({
                    label: "Accommodation & Food Rule",
                    data: findObj(
                      accommodationAndFoodRuleId,
                      accommodationAndFoodRules
                    ),
                  })}
                  className="m-0"
                  valuePropName="checked"
                  hidden={type === "Shift"}
                >
                  <Select
                    className="text-input-field p-1 pe-3 t1 bg-white"
                    bordered={false}
                    dropdownStyle={{ zIndex: 2000 }}
                    placeholder="Accommodation & Food Rule"
                    suffixIcon={<img src={Dropwdown} alt="dropdown" />}
                    onChange={(value) => setaccommodationAndFoodRuleId(value)}
                    value={accommodationAndFoodRuleId}
                  >
                    {accommodationAndFoodRules.map((data, idx) => (
                      <Select.Option key={idx} value={data.id}>
                        {data.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name={["coverExpenses", "parkingRuleId"]}
                  label={CoverExpenseFormLabels({
                    label: "Parking Rule",
                    data: findObj(parkingRuleId, parkingRules),
                  })}
                  className="m-0"
                  valuePropName="checked"
                  hidden={type === "Shift"}
                >
                  <Select
                    className="text-input-field p-1 pe-3 t1 bg-white"
                    bordered={false}
                    dropdownStyle={{ zIndex: 2000 }}
                    placeholder="Parking Rule"
                    suffixIcon={<img src={Dropwdown} alt="dropdown" />}
                    value={parkingRuleId}
                    onChange={(value) => setparkingRuleId(value)}
                  >
                    {parkingRules.map((data, idx) => (
                      <Select.Option key={idx} value={data.id}>
                        {data.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={["coverExpenses", "transportRuleId"]}
                  label={CoverExpenseFormLabels({
                    label: "Transport Rule",
                    data: findObj(transportRuleId, transportRules),
                  })}
                  className="m-0"
                  valuePropName="checked"
                  hidden={type === "Shift"}
                >
                  <Select
                    className="text-input-field p-1 pe-3 t1 bg-white"
                    bordered={false}
                    value={transportRuleId}
                    dropdownStyle={{ zIndex: 2000 }}
                    placeholder="Transport Rule"
                    suffixIcon={<img src={Dropwdown} alt="dropdown" />}
                    onChange={(value) => settransportRuleId(value)}
                  >
                    {transportRules.map((data, idx) => (
                      <Select.Option key={idx} value={data.id}>
                        {data.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            )}
            {/* {type === "Job" && !hasCoverExpense && ( */}
            <Form.Item
              label="Description"
              name="description"
              className="mt-3"
              hidden={type === "Shift"}
              rules={
                type === "Shift"
                  ? undefined
                  : [
                      {
                        required: true,
                        message:
                          "You'll Have To Enter A Description To Proceed!",
                      },
                    ]
              }
            >
              <Input.TextArea
                className="px-3 py-2 t1"
                rows={5}
                maxLength={256}
                name="description"
                placeholder="Write your job description here..."
              />
            </Form.Item>
            {/* )} */}
            {type === "Shift" && (
              <Form.Item
                label="Assign Staff"
                name="staffId"
                className="mt-3 not-required"
                hidden={type !== "Shift"}
                rules={
                  type === "Shift" && (gapData || !isTemplate)
                    ? [
                        {
                          required: true,
                          message: "You'll Have To Assign A Staff To Proceed!",
                        },
                      ]
                    : [
                        {
                          required: true,
                          message: "You'll Have To Assign A Staff To Proceed!",
                        },
                      ]
                }
              >
                <Select
                  allowClear
                  showSearch
                  className="text-input-field p-1 pe-3 t1"
                  bordered={false}
                  optionFilterProp="label"
                  dropdownStyle={{ zIndex: 2000 }}
                  placeholder="Select"
                  suffixIcon={<img src={Dropwdown} alt="dropdown" />}
                  onChange={(value) =>
                    form.setFieldsValue({
                      staffId: value,
                    })
                  }
                >
                  {staffs
                    ?.filter((i) => i.roleId === roleId)
                    .map((data, idx) => (
                      <Select.Option key={idx} value={data.id}>
                        {data.name} {data.surname}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            )}
            {type === "Job" && (
              <Form.Item
                name="hasExternalLocum"
                className="mt-3"
                valuePropName="checked"
                hidden={type === "Shift"}
                // rules={
                //   type === "Job" && gapData
                //     ? [
                //         {
                //           validator: (_, value) =>
                //             value
                //               ? Promise.resolve()
                //               : Promise.reject(
                //                   new Error(
                //                     "You'll Have To Assign A Locum To Proceed!"
                //                   )
                //                 ),
                //         },
                //       ]
                //     : undefined
                // }
              >
                <Checkbox>Assign Locum</Checkbox>
              </Form.Item>
            )}

            {(type === "Job" || data?.status === "active") &&
              hasExternalLocum && (
                <Form.Item
                  className="d-flex flex-column gap-2 "
                  name="externalLocumBooking"
                  style={{ gap: "15px" }}
                >
                  <Form.Item
                    label="GPhC No. / Name"
                    name={["externalLocumBooking", "gphcNumber"]}
                    className="m-0"
                    hidden={type === "Shift"}
                    rules={
                      type !== "Shift" && hasExternalLocum
                        ? [
                            {
                              required: true,
                              message:
                                "You'll Have To Enter The GPhC Number To Proceed!",
                            },
                          ]
                        : undefined
                    }
                  >
                    <AutoComplete
                      dropdownStyle={{ zIndex: 2000 }}
                      className="text-input-field pe-3 p-1 t1"
                      options={locumOptions}
                      maxLength={7}
                      onSelect={onSelect}
                      bordered={false}
                      style={{ borderRadius: "8px" }}
                      onSearch={(e) => {
                        if (
                          e.length < 7 ||
                          e !== externalLocumBooking?.gphcNumber
                        ) {
                          form.setFieldsValue({
                            externalLocumBooking: {
                              gphcNumber: e,
                              email: "",
                              phone: "",
                              id: undefined,
                            },
                          });
                          return;
                        }
                        form.setFieldsValue({
                          externalLocumBooking: {
                            gphcNumber: e,
                            ...externalLocumBooking,
                          },
                        });
                      }}
                      placeholder="GPhC No."
                    />
                  </Form.Item>
                  <div className="assign_locum">
                    <Form.Item
                      label="Id"
                      name={["externalLocumBooking", "id"]}
                      className="m-0"
                      hidden={true}
                    >
                      <Input
                        className="text-input-field px-3 pe-3 t1"
                        placeholder="Email Address"
                      />
                    </Form.Item>
                    <Form.Item
                      label="Email Address"
                      name={["externalLocumBooking", "email"]}
                      className="m-0"
                      hidden={type === "Shift"}
                      rules={
                        type !== "Shift" && hasExternalLocum
                          ? [
                              {
                                type: "email",
                                required: true,
                                message:
                                  "You'll Have To Enter The Email Address To Proceed!",
                              },
                            ]
                          : undefined
                      }
                    >
                      <Input
                        className="text-input-field px-3 pe-3 t1"
                        placeholder="Email Address"
                      />
                    </Form.Item>

                    <Form.Item
                      label="Phone Number"
                      className="m-0"
                      name={["externalLocumBooking", "phone"]}
                      hidden={type === "Shift"}
                      rules={
                        type !== "Shift" && hasExternalLocum
                          ? [
                              {
                                required: true,
                                message:
                                  "You'll Have To Enter The Phone Number To Proceed!",
                              },
                            ]
                          : undefined
                      }
                    >
                      <Input
                        className="text-input-field px-3 t1"
                        placeholder="Phone Number"
                      />
                    </Form.Item>
                  </div>
                </Form.Item>
              )}
          </Form>
        </AddModal>
      </>
    );
  }
);
