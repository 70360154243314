import {
  ABSENCE,
  ADMIN_INFO,
  BILLING,
  BRANCHES,
  CONTRACT,
  DASHBOARD,
  JOB_OFFERS,
  LOCUM_BANK,
  MODERATION,
  ONBOARDING,
  PERSONAL_INFO,
  PHARMACIES,
  PHARMACY_INFO,
  POSTED_JOBS,
  REGIONS,
  SCHEDULE,
  SETTINGS,
  STAFF,
  TimeSheet,
  USERS,
  VERIFICATION,
} from "./routerPaths";

export const RouterConfig = {
  root: "/",
  pageNotFound: "page-not-found",
  privacyPolicy: "privacy-policy",
  appleAppSiteAssosiation: "apple-app-site-association",
  // authentication
  login: "/login/",
  signup: "/signup",
  login_new: "/login/new",
  signup_new: "/signup/new",
  segmentation: "/segmentation",
  invite: "/invite/:id",
  locumCheckIn: "/check-in/:id",

  // branch admin main navigation
  branchAdminDashboard: DASHBOARD,
  branchAdminSchedule: SCHEDULE,
  branchAdminRegions: REGIONS,
  branchAdminBranches: BRANCHES,
  branchAdminStaff: STAFF,
  branchAdminPostedJobs: POSTED_JOBS,
  branchAdminPharmacyInfo: PHARMACY_INFO,
  branchAdminLocumBank: LOCUM_BANK,
  branchAdminTimesheet: TimeSheet,

  // branch admin dashboard details page navigation
  branchAdminDashboardPharmacyGaps: `${DASHBOARD}/pharmacy-gaps`,
  branchAdminDashboardPendingJobs: `${DASHBOARD}/pending-jobs`,

  // branch admin branch details page navigation
  branchAdminBranchRoot: `${BRANCHES}/:id/*`,
  branchAdminBranchDetails: `${BRANCHES}/:id/details/*`,
  branchAdminBranchInformation: `${BRANCHES}/:id/details/information/`,
  branchAdminBranchSchedule: `${BRANCHES}/:id/details/schedule/`,
  branchAdminBranchScheduleDayView: `${BRANCHES}/:id/details/schedule-day-view/:date`,
  branchAdminBranchTemplate: `${BRANCHES}/:id/details/template/`,
  branchAdminBranchStaff: `${BRANCHES}/:id/details/staff/`,
  branchAdminBranchBlockList: `${BRANCHES}/:id/details/block-list/`,
  branchAdminBranchAnalytics: `${BRANCHES}/:id/details/analytics/`,
  branchAdminBranchSettings: `${BRANCHES}/:id/details/settings/`,

  // branch admin staff details page navigation
  branchAdminStaffRoot: `${STAFF}/:staffId/details`,
  branchAdminStaffInformation: `${STAFF}/:staffId/details/information`,
  branchAdminStaffSchedule: `${STAFF}/:staffId/details/schedule`,
  branchAdminStaffAbsence: `${STAFF}/:staffId/details/leaves`,
  branchAdminStaffDocument: `${STAFF}/:staffId/details/document`,
  branchAdminStaffPerformanceReview: `${STAFF}/:staffId/details/review`,
  branchAdminStaffPerformanceReviewAddQuestion: `${STAFF}/:staffId/details/review/add-question`,
  branchAdminStaffContract: `${STAFF}/:staffId/details/contract/*`,
  branchAdminStaffContractDetail: `${STAFF}/:staffId/details/contract/:contractId`,
  branchAdminStaffTimeSheet: `${STAFF}/:staffId/details/time-sheet`,
  branchAdminStaffVerification: `${STAFF}/:staffId/details/verification`,

  branchAdminJobPreview: `${POSTED_JOBS}/:shiftId/details/*`,

  // branch admin profile page navigation
  branchAdminProfile: `profile`,
  branchAdminProfileInfo: `information`,
  branchAdminProfileSubscription: `subscription`,
  branchAdminProfileBankHolidays: `bank-holidays`,
  branchAdminProfileAccrediations: `accrediations`,
  branchAdminProfileSetting: `setting`,
  branchAdminProfileAdminStaff: `admin-staff`,

  // super admin main navigation
  superAdminPharmacies: PHARMACIES,
  superAdminBranches: BRANCHES,
  superAdminUsers: USERS,
  superAdminModeration: MODERATION,
  superAdminJobOffers: JOB_OFFERS,
  superAdminBilling: BILLING,
  superAdminInfo: ADMIN_INFO,

  // super admin pharmacy details page navigation
  superAdminPharmacyRoot: `${PHARMACIES}/details/:id`,
  superAdminPharmacyInformation: `${PHARMACIES}/:id/details/information/`,
  superAdminPharmacyBranches: `${PHARMACIES}/:id/details/branches/`,
  superAdminPharmacyStaff: `${PHARMACIES}/:id/details/staff/`,
  superAdminPharmacyJobs: `${PHARMACIES}/:id/details/jobs/`,
  superAdminPharmacyBilling: `${PHARMACIES}/:id/details/billing/`,

  // super admin user details page navigation
  superAdminUserRoot: `${USERS}/details/:staffId`,
  superAdminUserInformation: `${USERS}/:staffId/details/information/`,
  superAdminUserVerification: `${USERS}/:staffId/details/verification/`,

  // super admin profile page navigation
  superAdminInfoProfile: `${ADMIN_INFO}/profile`,
  superAdminInfoSettings: `${ADMIN_INFO}/settings`,

  // managers view
  manager: {
    onboarding: ONBOARDING,
    personalInfo: PERSONAL_INFO,
    verification: VERIFICATION,
    settings: SETTINGS,
    schedule: SCHEDULE,
    absence: ABSENCE,
    contract: CONTRACT,
  },

  locum: {
    onboarding: ONBOARDING,
    personalInfo: PERSONAL_INFO,
    verification: VERIFICATION,
    schedule: SCHEDULE,
    jobOffers: JOB_OFFERS,
    settings: SETTINGS,
    absence: ABSENCE,
  },
};
