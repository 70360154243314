import { useSelector } from "react-redux";
import { DocumentSlide } from "../../../../../../components/staffs/documentSlide";
import { sortAccredArray } from "../../../../../../utils/helper";

export const Accrediations = () => {
  const { staffAccrediations } = useSelector(
    (state) => state.staffs
  );
  const { userData } = useSelector((state) => state.superAdmin)
  return (
    <div className="border rounded">
      <div className="s1 text-row m-0">System Accreditations</div>

      <div className="d-flex flex-wrap gap-3 align-items-center m-3">
        {(userData?.personal?.group === "staff"
          ? sortAccredArray(staffAccrediations?.systemAccreditation)
          : sortAccredArray(staffAccrediations)
        )?.map((data, index) => (
          <DocumentSlide
            key={index}
            label={data.accreditationName}
            value={data.filePath}
            name={userData?.personal?.name}
          />
        ))}
      </div>
    </div>
  );
};
