import { Skeleton, Space, Typography } from "antd";
import React from "react";
import "./style.scss";

const CommonDataTab = ({ icon, Title, children, iconBackground, loading }) => {
  return (
    <Skeleton
      round
      style={{ background: "#fff", padding: 12, borderRadius: 12 }}
      loading={loading}
      active
      avatar={{size:32}}
      title={{width:160}}
      paragraph={{rows:1, width:50}}
      className="commondata-tab-sekeleton"
    >
      <div className="common_tab">
        <Space className="tab-header">
          <figure
            style={{ backgroundColor: iconBackground }}
            className="icon_wrap"
          >
            {icon}
          </figure>
          <Typography.Title level={5}>{Title}</Typography.Title>
        </Space>
        {children}
      </div>
    </Skeleton>
  );
};

export default CommonDataTab;
