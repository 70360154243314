import React from "react";

const Hospitalicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M22 21.75H2C1.59 21.75 1.25 22.09 1.25 22.5C1.25 22.91 1.59 23.25 2 23.25H22C22.41 23.25 22.75 22.91 22.75 22.5C22.75 22.09 22.41 21.75 22 21.75Z"
        fill="#78828A"
      />
      <path
        d="M17 2.5H7C4 2.5 3 4.29 3 6.5V22.5H9V16.44C9 15.92 9.42 15.5 9.94 15.5H14.07C14.58 15.5 15.01 15.92 15.01 16.44V22.5H21.01V6.5C21 4.29 20 2.5 17 2.5ZM14.5 9.75H12.75V11.5C12.75 11.91 12.41 12.25 12 12.25C11.59 12.25 11.25 11.91 11.25 11.5V9.75H9.5C9.09 9.75 8.75 9.41 8.75 9C8.75 8.59 9.09 8.25 9.5 8.25H11.25V6.5C11.25 6.09 11.59 5.75 12 5.75C12.41 5.75 12.75 6.09 12.75 6.5V8.25H14.5C14.91 8.25 15.25 8.59 15.25 9C15.25 9.41 14.91 9.75 14.5 9.75Z"
        fill="#78828A"
      />
    </svg>
  );
};

export default Hospitalicon;
