import { createAsyncThunk } from "@reduxjs/toolkit";
import { getPharmacyList, getUserList, getjobOffersList, getbillingList, getPharmacyInformation, getPharmacyBranchesList, getPharmacyStaffList, getPharmacyJobList, getPharmacySubscription, getUserData, updateZohoReportEmail } from "../superAdminAPI";


export const getPharmacyListThunk = createAsyncThunk("getPharmacyList", async (payload) => {
    const request = await getPharmacyList(payload)

    return request.data.data
})
export const getUserListThunk = createAsyncThunk("getUserList", async (payload) => {
    const request = await getUserList(payload)

    return request.data.data
})

export const getJobOffersThunk = createAsyncThunk("getJobOffersList", async (payload) => {
    const request = await getjobOffersList(payload)

    return request.data.data
})

export const getBillingThunk = createAsyncThunk("getBillingList", async (payload) => {
    const request = await getbillingList(payload)

    return request.data.data
})

export const updateZohoReportEmailThunk = createAsyncThunk("updateZohoReportEmail", async (payload) => {
    const request = await updateZohoReportEmail(payload)
    return request.data.data
})


export const getPharmacyInformationThunk = createAsyncThunk("getPharmacyInformation", async (payload) => {
    const request = await getPharmacyInformation(payload)

    return request.data.data
})
export const getPharmacyBranchesListThunk = createAsyncThunk("getPharmacyBranchesList", async (payload) => {
    const request = await getPharmacyBranchesList(payload)

    return request.data.data
})

export const getPharmacyStaffListThunk = createAsyncThunk("getPharmacyStaffList", async (payload) => {
    const request = await getPharmacyStaffList(payload)

    return request.data.data
})

export const getPharmacyJobListThunk = createAsyncThunk("getPharmacyJobsList", async (payload) => {
    const request = await getPharmacyJobList(payload)

    return request.data.data
})


export const getPharmacySubscriptionSThunk = createAsyncThunk("getSubscriptionList", async (payload) => {
    const request = await getPharmacySubscription(payload)

    return request.data.data
})

export const getUserDataThunk = createAsyncThunk("getUserData", async (payload) => {
    const request = await getUserData(payload)

    return request.data.data
})
