import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, generatePath, useParams } from "react-router-dom";
import { BranchCard } from "../../../../components/UI/cards/profileCard";
import { ContentContainer } from "../../../../components/UI/container";
import { Sidebar } from "../../../../components/UI/sideBar";
import { getStaffAccrediationsThunk } from "../../../../redux/staffs/staffsThunk";
import { getUserDataThunk } from "../../../../redux/super-admin/superAdminThunk";
import { RouterConfig } from "../../../../routerConfig";
import "./style.scss";

export const UserDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.superAdmin);

  useEffect(() => {
    let staffId = id;
    dispatch(getStaffAccrediationsThunk({ staffId }));
    dispatch(getUserDataThunk({ staffId }));
  }, [dispatch, id]);

  const sidebarLinks = useMemo(
    () => [
      {
        text: "Personal Information",
        to: generatePath(RouterConfig.superAdminUserInformation, {
          staffId: id,
        }),
        disabled: false,
        hidden: false,
      },
      {
        text: "Verification",
        to: generatePath(RouterConfig.superAdminUserVerification, {
          staffId: id,
        }),
        disabled: false,
        hidden: false,
      },
    ],
    [id]
  );

  return (
    <div className="content-view-page details-page">
      <div className="main-wrapper p-5">
        <div className="content-holder">
          <Sidebar links={sidebarLinks} visible>
            <BranchCard
              userStatus={userData.personal?.isVerified}
              staffInfo={userData?.personal}
              logo={userData?.personal?.image}
              primaryText={`${userData?.personal?.name} ${userData?.personal?.surname}`}
              secondaryText={userData?.personal?.label}
            />
          </Sidebar>
          <ContentContainer className="content-container">
            <Outlet />
          </ContentContainer>
        </div>
      </div>
    </div>
  );
};
