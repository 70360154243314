import { createAsyncThunk } from "@reduxjs/toolkit";
import { archiveBranch, blockLocum, createBranch, deleteBranch, getBranchBlockList, getBranchData, getBranchesList, getBranchRelatedData, getBranchStaffList, unBlockLocum, updateBranch, updateBranchOperatingTime, branchDashboard, getBranchGapsData, getPendingJobApprovalData, getBranchSchedule, unArchiveBranch, getBranchTemplate, addStaffAbsence, applyBranchGap, addTemplateShift, actionTemplateWeek, publishTemplate, editTemplateShift, deleteTemplateShift, editBranchOperatingTime, findAddress, exportBranchGapsCSV, getAddressDetails } from "../branchesAPI";

export const getbranchesListThunk = createAsyncThunk("get/branches", async (payload) => {
    const request = await getBranchesList(payload)
    return request.data.data
})

export const getBranchDataThunk = createAsyncThunk("get/branchData", async (payload) => {
    const request = await getBranchData(payload)
    return request.data.data
})
export const getBranchDashboardDetailThunk = createAsyncThunk("post/branches", async (payload) => {
    const request = await branchDashboard(payload)
    return request.data.data
})
export const getBranchGapsDataThunk = createAsyncThunk("getBranchGapsData", async (payload) => {
    const request = await getBranchGapsData(payload)
    return request.data.data
})

export const applyBranchGapThunk = createAsyncThunk("applyBranchGap", async (payload) => {
    const request = await applyBranchGap(payload)
    return request.data.data
})

export const getPendingJobDetailThunk = createAsyncThunk("getPendingJobApprovalDetail", async (payload) => {
    const request = await getPendingJobApprovalData(payload)
    return request.data.data
})

export const getBranchRelatedDataThunk = createAsyncThunk("get/branchRelatedData", async () => {
    const request = await getBranchRelatedData()
    return request.data.data
})

export const getBranchStaffListThunk = createAsyncThunk("get/branchStaff", async (payload) => {
    const request = await getBranchStaffList(payload)
    return request.data.data
})

export const getBranchBlockListThunk = createAsyncThunk("get/branchBlock", async (payload) => {
    const request = await getBranchBlockList(payload)
    return request.data.data
})

export const blockLocumThunk = createAsyncThunk("post/blockLocum", async (payload) => {
    const request = await blockLocum(payload)
    return request.data.data
})

export const unBlockLocumThunk = createAsyncThunk("post/unblockLocum", async (payload) => {
    const request = await unBlockLocum(payload)
    return request.data.data
})

export const deleteBranchThunk = createAsyncThunk("delete/branch", async (payload) => {
    const request = await deleteBranch(payload)
    return request.data.data
})

export const archiveBranchThunk = createAsyncThunk("post/archiveBranch", async (payload) => {
    const request = await archiveBranch(payload)
    return request.data.data
})
export const unArchiveBranchThunk = createAsyncThunk("unArchiveBranch", async (payload) => {
    const request = await unArchiveBranch(payload)
    return request.data.data
})

export const createBranchThunk = createAsyncThunk("post/createbranch", async (payload) => {
    const request = await createBranch(payload)
    return request.data.data
})

export const updateBranchThunk = createAsyncThunk("post/updateBranch", async (payload) => {
    const request = await updateBranch(payload)
    return request.data.data
})

export const updateBranchOperatingTimeThunk = createAsyncThunk("post/updateBranchOperatingTime", async (payload) => {
    const request = await updateBranchOperatingTime(payload)
    return request.data.data
})

export const getBranchScheduleThunk = createAsyncThunk("getBranchSchedule", async (payload) => {
    const request = await getBranchSchedule(payload)
    return request.data.data
})

export const getBranchTemplateThunk = createAsyncThunk("getBranchTemplate", async (payload) => {
    const request = await getBranchTemplate(payload)
    return request.data.data
})


export const addStaffAbsenceThunk = createAsyncThunk("addStaffAbsence", async (payload) => {
    const request = await addStaffAbsence(payload)
    return request.data.data
})

export const addTemplateShiftThunk = createAsyncThunk("addTemplateShift", async (payload) => {
    const request = await addTemplateShift(payload)
    return request.data.data
})

export const actionTemplateWeekThunk = createAsyncThunk("actionTemplateWeek", async (payload) => {
    const request = await actionTemplateWeek(payload)
    return request.data.data
})

export const publishTemplateThunk = createAsyncThunk("publishTemplate", async (payload) => {
    const request = await publishTemplate(payload)
    return request.data.data
})

export const editTemplateShiftThunk = createAsyncThunk("editTemplateShift", async (payload) => {
    const request = await editTemplateShift(payload)
    return request.data.data
})

export const deleteTemplateShiftThunk = createAsyncThunk("deleteTemplateShift", async (payload) => {
    const request = await deleteTemplateShift(payload)
    return request.data.data
})

export const editBranchOperatingTimeThunk = createAsyncThunk("editBranchOperatingTime", async (payload) => {
    const request = await editBranchOperatingTime(payload)
    return request.data.data
})


export const findAddressThunk = createAsyncThunk("findAddress", async (payload) => {
    const request = await findAddress(payload)
    return request.data
})

export const getAddressDetailsThunk = createAsyncThunk("getAddressDetailsThunk", async (payload) => {
    const request = await getAddressDetails(payload)
    return request.data
})


export const exportBranchGapsCSVThunk = createAsyncThunk("exportBranchGapsCSV", async (payload) => {
    const request = await exportBranchGapsCSV(payload)
    return request.data
})