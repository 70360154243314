import { Popover } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CalenderNoIcon from "../../../../src/assets/icons/calendar no.svg";
import DropDownIcon from "../../../../src/assets/icons/downDrop.svg";
import DropUp from "../../../../src/assets/icons/dropUp.png";
import FilterIcon from "../../../../src/assets/icons/filter.svg";
import LeaveDetailsWeekView from "./LeaveDetailsTables/LeaveDetailsWeekView";
import LeaveFilter from "./LeaveFilter";

const LeaveDetailsTable = ({
  startDate,
  endDate,
  selectedView,
  weekArray,
  selectedPharmacies,
  setselectedPharmacies,
}) => {
  const [openPopover, setOpenPopover] = useState(false);
  const { staffLeaves } = useSelector((state) => state.staffs);
  const [_dates, setDates] = useState([]);

  const handlePopover = (newOpen) => {
    setOpenPopover(newOpen);
  };

  const [openTable, setopenTable] = useState(false);

  const toggleTable = () => {
    setopenTable((prev) => !prev);
  };

  useEffect(() => {
    if (selectedView !== "daily") {
      setDates(weekArray);
    }
  }, [JSON.stringify(weekArray), selectedView]);

  return (
    <div
      className={`mb-4 leave_table_box leave_table_page rounded-top bg-white border ${
        openTable ? "rounded-top" : "rounded-bottom"
      }`}
    >
      <div
        className={`d-flex justify-content-between cursor-pointer align-items-center  rounded-top-inner px-4 py-4 ${
          openTable
            ? "border-bottom-0 rounded-top-inner"
            : "rounded-bottom-inner"
        } `}
        onClick={toggleTable}
      >
        <div className="d-flex align-items-center ">
          <figure className="icon_wrapper">
            <img src={CalenderNoIcon} alt="" />
          </figure>
          <div className="leaves">
            <p className="leaves_header">Leaves</p>
            <div className="d-flex my-2 align-items-center leave_count">
              <p>
                Total : <span>{staffLeaves?.leaveStats?.totalLeaves}</span>
              </p>
              <span className="separator">|</span>
              <p>
                Upcoming :{" "}
                <span>{staffLeaves?.leaveStats?.upcomingLeaves}</span>
              </p>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <ul className="d-flex rgt_side" onClick={(e) => e.stopPropagation()}>
            <li>
              <Popover
                content={
                  <LeaveFilter
                    selectedPharmacies={selectedPharmacies}
                    setselectedPharmacies={setselectedPharmacies}
                  />
                }
                trigger="click"
                open={openPopover}
                showArrow={false}
                placement="rightTop"
                onOpenChange={handlePopover}
              >
                {" "}
                <button
                  className={
                    selectedPharmacies?.length ? "btn filter_btn" : "btn"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenPopover(true);
                  }}
                >
                  <img src={FilterIcon} alt="filter" />
                </button>
              </Popover>
            </li>
            <li>
              <button className="dropdown_btn btn" onClick={toggleTable}>
                <img src={openTable ? DropUp : DropDownIcon} alt="" />
              </button>
            </li>
          </ul>
        </div>
      </div>

      {openTable && <LeaveDetailsWeekView weekArray={_dates} />}
    </div>
  );
};

export default LeaveDetailsTable;
