import React, { useMemo } from "react";
import "./style.scss";
import { FilterComponent } from "../../Filter";
import { Form, Select } from "antd";

export const BranchFilterComponent = ({ regions, onApplyFilter }) => {
  const [form] = Form.useForm();
  const formId = useMemo(() => "filter-form", []);

  const clearFilterFormHandler = () => {
    form.resetFields();
    onApplyFilter();
  };

  const onSubmit = (values) => {
    onApplyFilter(values);
  };
  return (
    <FilterComponent
      formId={formId}
      onApplyFilter={form.submit}
      clearFormHandler={clearFilterFormHandler}
    >
      <Form
        id={formId}
        onFinish={onSubmit}
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item label="Regions" name="regions">
          <Select
            dropdownStyle={{ zIndex: 2000 }}
            placeholder="Select Regions"
            mode="multiple"
            bordered={false}
            className="text-input-field p-1 t1"
          >
            {regions?.map((data, idx) => (
              <Select.Option key={idx} value={data.title}>
                {data.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Status" name="status">
          <Select
            dropdownStyle={{ zIndex: 2000 }}
            placeholder="Select Status"
            bordered={false}
            className="text-input-field p-1 t1"
          >
            <Select.Option value="active">Open</Select.Option>
            <Select.Option value="inactive">Closed</Select.Option>
            <Select.Option value="archive">Archived</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </FilterComponent>
  );
};
