import { uniqBy } from "lodash";
import moment from "moment";
import React, {
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import AddCircle from "../../../../src/assets/icons/add-circle.svg";
import {
  defaultRoleOptions,
  roleOptionList,
} from "../../../utils/constant";
import eventEmitter, {
  events,
} from "../../../utils/eventEmitter";
import { returnIdOnly } from "../../../utils/helper";

import {
  getUniqueShiftsSorted,
  uniqShiftByGphc,
} from "../../../utils/schedule";
import { convertToUniqueLabel } from "../../../utils/timeshhet";
import ShiftDetailsWeekView from "../ShiftDeatails/ShiftDetailsWeekView";
import TemplateViewHead from "../headers/TemplateViewHead";
import WeekViewHead from "../headers/WeekViewHead";

const WeekViewRow = ({
  branchInfo,
  weekArray,
  handleAddShift,
  weekDayArray,
  templateView = false,
  weekName,
  actionWeekTemplate,
  clearWeekTemplate,
  onDelete,
  weekNumber,
}) => {
  const [openTable, setopenTable] =
    useState(false);
  const [showDropdown, setshowDropdown] =
    useState(false);
  const [roleOption, setRoleOption] = useState(
    roleOptionList
  );
  const [selectedOption, setSelectedOption] =
    useState(defaultRoleOptions);

  const { roleList } = useSelector((state) => {
    return state.schedule;
  });

  const toggleTable = () => {
    setopenTable(!openTable);
  };

  const setRoleOnFilter = (data) => {
    if (!!data) {
      let temp = [
        ...selectedOption,
        convertToUniqueLabel(data),
      ];
      setSelectedOption(uniqBy(temp));
    }
  };

  useEffect(() => {
    eventEmitter.on(
      events.roleId,
      setRoleOnFilter
    );

    return () => {
      eventEmitter.off(
        events.roleId,
        setRoleOnFilter
      );
    };
  }, []);

  const allShifts = getUniqueShiftsSorted(
    branchInfo?.events
  );

  const _uniqShiftGphc =
    uniqShiftByGphc(allShifts);

  const branchMap = (_roleArray) => {
    return branchInfo?.events?.map(
      (events, _index) => {
        return (
          <td
            key={Math.random().toString()}
            className={`shift_details_week_view_td ${
              moment().format("DD-MM-YYYY") ===
                moment(events.startDate).format(
                  "DD-MM-YYYY"
                ) && !weekName
                ? "current_day"
                : ""
            }`}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "center",
              }}
            >
              <ShiftDetailsWeekView
                viewDetails
                weekNumber={weekNumber}
                addShift
                onDelete={onDelete}
                handleAddShift={handleAddShift}
                branchName={branchInfo.title}
                branchId={branchInfo.id}
                day={{
                  ...events,
                  active: branchInfo?.active,
                }}
                operatingTime={
                  branchInfo?.operatingTime
                }
                type={returnIdOnly(_roleArray)}
                templateView={templateView}
                allShifts={allShifts}
                uniqShiftByGphc={_uniqShiftGphc}
              />
            </div>
          </td>
        );
      }
    );
  };

  return (
    <div
      className={`mb-4  border leave_table_box bg-white cursor-pointer rounded-top ${
        openTable
          ? "rounded-top"
          : "rounded-bottom"
      }`}
      style={{
        paddingBottom: openTable && "60px",
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
      }}
    >
      <div onClick={toggleTable}>
        {templateView ? (
          <TemplateViewHead
            openTable={openTable}
            weekName={weekName}
            actionWeekTemplate={
              actionWeekTemplate
            }
            clearWeekTemplate={clearWeekTemplate}
          />
        ) : (
          <WeekViewHead
            branchInfo={branchInfo}
            openTable={openTable}
          />
        )}
      </div>

      {openTable && (
        <>
          <div className="table_container">
            <table className="table table-bordered bg-white weekview_table mb-0">
              <thead>
                <tr>
                  <th
                    scope="col "
                    className="week_array"
                  >
                    <div className="p-1 roles">
                      <button
                        className="btn"
                        onClick={() =>
                          setshowDropdown(true)
                        }
                      >
                        <img
                          src={AddCircle}
                          alt="people"
                        />{" "}
                        Roles
                      </button>
                      {showDropdown && (
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          {roleOption.map(
                            (option) => (
                              <li
                                style={{
                                  display:
                                    selectedOption.includes(
                                      option
                                    )
                                      ? "none"
                                      : "block",
                                }}
                              >
                                <button
                                  className="dropdown-item text-capitalize"
                                  onClick={() => {
                                    setSelectedOption(
                                      [
                                        ...selectedOption,
                                        option,
                                      ]
                                    );
                                    setshowDropdown(
                                      false
                                    );
                                  }}
                                >
                                  {option}
                                </button>
                              </li>
                            )
                          )}
                        </ul>
                      )}
                    </div>
                  </th>

                  {!!weekArray
                    ? weekArray.map(
                        (item, index) => (
                          <th
                            ley={`${index}_weekarray`}
                            scope="col"
                            className={`week_array ${
                              moment().format(
                                "DD-MM-YYYY"
                              ) ===
                              moment(item).format(
                                "DD-MM-YYYY"
                              )
                                ? "current_day"
                                : ""
                            }`}
                            key={index}
                          >
                            <p className=" dates">
                              {moment(
                                item
                              ).format(
                                "ddd"
                              )}{" "}
                              <b>
                                {moment(
                                  item
                                ).format("D MMM")}
                              </b>
                            </p>
                          </th>
                        )
                      )
                    : weekDayArray.map(
                        (item, index) => (
                          <th
                            ley={`${index}_weekarray`}
                            scope="col"
                            className={`week_array ${
                              moment().format(
                                "DD-MM-YYYY"
                              ) ===
                              moment(item).format(
                                "DD-MM-YYYY"
                              )
                                ? "current_day"
                                : ""
                            }`}
                            key={index}
                          >
                            <p className="p-3 dates">
                              {item.slice(0, 3)}
                            </p>
                          </th>
                        )
                      )}
                </tr>
              </thead>
              <tbody>
                {/* {roles?.map((_role, innerIndex) => (
                  <tr key={`roles_${innerIndex}`}>
                    <th scope="row">
                      <p className="item_type">{_role?.role}</p>
                    </th>
                    {branchMap(_role)}
                  </tr>
                ))} */}

                {Object.keys(roleList)?.map(
                  (key, innerIndex) =>
                    selectedOption.includes(
                      key
                    ) ? (
                      <tr
                        key={`roles_${innerIndex}`}
                        role={key}
                      >
                        <th scope="row">
                          <p className="item_type  text-capitalize">
                            {key}
                          </p>
                        </th>
                        {branchMap(roleList[key])}
                      </tr>
                    ) : null
                )}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default WeekViewRow;
