import { createAsyncThunk } from "@reduxjs/toolkit";
import { createRegion, deleteRegions, editRegion, getRegionsBranchesList, getRegionsList, getRegionsListDropDown } from "../regionsAPI";


export const getRegionsListThunk = createAsyncThunk("getRegionsList", async (payload) => {
    const request = await getRegionsList(payload)
    return request.data.data
})

export const getRegionsListDropDownThunk = createAsyncThunk("getRegionsListDropDown", async (payload) => {
    const request = await getRegionsListDropDown(payload)
    return request.data.data
})

export const getRegionsBranchesListThunk = createAsyncThunk("getRegionsBranchesList", async (payload) => {
    const request = await getRegionsBranchesList(payload)
    return request.data.data
})

export const deleteRegionsThunk = createAsyncThunk("deleteRegions", async (payload) => {
    const request = await deleteRegions(payload)
    return request.data.data
})

export const createRegionThunk = createAsyncThunk("createRegion", async (payload) => {
    const request = await createRegion(payload)
    return request.data.data
})

export const editRegionThunk = createAsyncThunk("editRegion", async (payload) => {
    const request = await editRegion(payload)
    return request.data.data
})