import { API } from "../../../axios";

export const getRegionsList = (payload) => {
    const { filters, pagination } = payload;
    const request = API.post(`/regions/list`, {
        filters, pagination
    });
    return request
}

export const getRegionsListDropDown = (payload) => {
    const request = API.post(`regions/dropdown/list`);
    return request
}

export const getRegionsBranchesList = (payload) => {
    const request = API.get(`invites/short-branches`);
    return request
}

export const deleteRegions = (payload) => {
    const { regionIds } = payload
    const request = API.delete(`/regions`, { data: regionIds });
    return request
}

export const createRegion = (payload) => {
    const request = API.post(`/regions`, payload);
    return request
}

export const editRegion = (payload) => {
    const { id, payload: _payload } = payload
    const request = API.post(`/regions/${id}`, _payload);
    return request
}
