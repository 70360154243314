import React from "react";

const DeactivateIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.81 2H16.19C19.83 2 22 4.17 21.99 7.81V16.19C21.99 19.83 19.82 22 16.18 22H7.81C4.17 22 2 19.83 2 16.18V7.81C2 4.17 4.17 2 7.81 2ZM15.7123 16.7729L12 13.0606L8.28769 16.7729C7.99778 17.0628 7.51694 17.0628 7.22703 16.7729C6.93711 16.483 6.93711 16.0021 7.22703 15.7122L10.9393 11.9999L7.22703 8.28761C6.93712 7.9977 6.93712 7.51686 7.22703 7.22695C7.51694 6.93704 7.99778 6.93704 8.28769 7.22695L12 10.9393L15.7123 7.22695C16.0022 6.93704 16.4831 6.93704 16.773 7.22695C17.0629 7.51686 17.0629 7.9977 16.773 8.28761L13.0607 11.9999L16.773 15.7122C17.0629 16.0021 17.0629 16.483 16.773 16.7729C16.4831 17.0628 16.0022 17.0628 15.7123 16.7729Z"
        fill="#434343"
      />
    </svg>
  );
};

export default DeactivateIcon;
