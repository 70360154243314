import { Select } from "antd";
import React, { useMemo } from "react";
// import Dropdown from "../../../assets/icons/downDrop.svg";
import Delete from "../../../assets/icons/trash-red.svg";
import CustomImage from "../../comon/CustomImage";

const FileUploadRow = ({
  fileList,
  handleOpenDeleteModal,
  item,
  deleteDocument,
}) => {
  return (
    <div className="input_wrapper">
      <div className="input_file_name">
        <i>
          {/* <img src={DocumentText} alt="" /> */}
          <CustomImage
            src={item?.filePath}
            style={{
              width: "20px",
              height: "20px",
              objectFit: "cover",
              borderRadius: "5px",
            }}
          />
        </i>

        <a
          target="_blank"
          href={item?.filePath}
          rel="noreferrer"
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {item?.filePath}
        </a>
      </div>

      <Select
        placeholder="Select Type"
        bordered={false}
        className="text-input-field pe-3 p-1 t1"
        // suffixIcon={<img src={Dropdown} alt="img" />}
        defaultValue={item?.accreditationLabel}
        disabled
      >
        {fileList?.length &&
          fileList?.map((data, index) => (
            <Select.Option key={data?.id} value={data?.accreditationLabel}>
              {data?.accreditationName || data?.title}
            </Select.Option>
          ))}
      </Select>
      <button
        className="btn del_btn"
        onClick={() => {
          handleOpenDeleteModal(item);
        }}
      >
        <img src={Delete} alt="delete" />
      </button>
    </div>
  );
};

const FileUploadRows = ({
  file,
  handleOpenDeleteModal,
  setopenModal,
  fileList,
}) => {
  const uploadedFileList = useMemo(() => {
    let temp = fileList?.filter((item) => item?.filePath);

    if (temp?.length === 0) {
      setopenModal(false);
    }

    return temp;
  }, [JSON.stringify(fileList), setopenModal]);

  return (
    <>
      {uploadedFileList?.map(
        (item) =>
          !!item?.filePath && (
            <FileUploadRow
              key={item?.id}
              fileList={fileList}
              item={item}
              handleOpenDeleteModal={handleOpenDeleteModal}
            />
          )
      )}
    </>
  );
};

export default FileUploadRows;
