import { Form, Popover, TimePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { Dropdown } from "semantic-ui-react";
import infoIcon from "../../../assets/icons/infoIcon.png";
import { ConfirmModal } from "../../../components/UI/modals/confirmModal";
import { TableComponet } from "../../../components/UI/table";
import { TableToolbar } from "../../../components/UI/table/tableToolBar";
import { EmptyData } from "../../../components/comon/EmptyData";
import { DetailTab } from "../../../components/dashboard/DetailTabs";
import {
  applyManualAttendanceThunk,
  getStaffTimeSheetThunk,
} from "../../../redux/staffs/staffsThunk";
import {
  leavesType,
  staffAbsencesType,
} from "../../../utils/constant";
import { StaffTimeSheetFilterComponent } from "../../admin-view/staffs/details/time-sheet/components/dateRangeFilter";
import "./style.scss";

export const StaffTimeSheet = () => {
  const dispatch = useDispatch();
  const { staffTimeSheet } = useSelector(
    (state) => state.staffs
  );
  const { user } = useSelector(
    (state) => state.auth
  );
  const [form] = Form.useForm();
  const formId = useMemo(() => "form", []);
  const [
    showAddAttendanceModal,
    setShowAddAttendanceModal,
  ] = useState(false);
  const [selectData, setSelectedData] =
    useState();
  const [dateRange, setDateRange] = useState({
    startDate: dayjs().startOf("month"),
    endDate: dayjs(),
  });
  const [flag, setFlag] = useState(true);

  const startTime = Form.useWatch(
    "checkIn",
    form
  );
  const endTime = Form.useWatch("checkOut", form);

  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ row: { original } }) => {
          return moment(original.date).format(
            "DD-MM-YYYY"
          );
        },
      },

      {
        Header: "Check In",
        accessor: "actualCheckIn",
        Cell: ({ row: { original } }) => {
          return original.actualCheckIn
            ? moment(
                original.actualCheckIn,
                "HH:mm:ss"
              ).format("HH:mm")
            : "-";
        },
      },
      {
        Header: "Check Out",
        accessor: "actualCheckOut",
        Cell: ({ row: { original } }) => {
          return original.actualCheckOut
            ? moment(
                original.actualCheckOut,
                "HH:mm:ss"
              ).format("HH:mm")
            : "-";
        },
      },
      {
        Header: "Working Hours",
        accessor: "hour",
        Cell: ({ row: { original } }) => {
          if (!original.actualCheckIn) return "-";
          const _startTime = moment(
            original.actualCheckIn,
            "hh:mm a"
          );
          const _endTime = original.actualCheckOut
            ? moment(
                original.actualCheckOut,
                "hh:mm a"
              )
            : moment();
          const mins = moment
            .utc(
              moment(_endTime, "HH:mm").diff(
                moment(_startTime, "HH:mm")
              )
            )
            .format("mm");
          return (
            _endTime.diff(_startTime, "hours") +
            "h " +
            mins +
            "m"
          );
        },
      },
      {
        Header: "Absence Type",
        accessor: "type",
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.id ? (
                <div>
                  {original.type === "leave"
                    ? staffAbsencesType[
                        original.leaveType
                      ]
                    : original.status ===
                      "pending"
                    ? "Pending"
                    : leavesType[original.type] ||
                      "-"}
                </div>
              ) : (
                <div style={{ color: "red" }}>
                  No Shift
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row: { original } }) => {
          return (
            <>
              {original.status === "pending" ? (
                <>
                  <Popover
                    placement="left"
                    content={
                      <div className="pending-job-poppover">
                        Seems like you've already
                        applied for attendence on
                        this day- it's currently
                        under review by your
                        Pharmacy Administrator.{" "}
                      </div>
                    }
                    trigger="click"
                  >
                    <img src={infoIcon} alt="" />
                  </Popover>
                </>
              ) : !original.id ||
                (original.status === "approved" &&
                  original.type ===
                    "leave") ? null : (
                <Dropdown
                  icon="ellipsis vertical"
                  pointing="top right"
                >
                  <Dropdown.Menu direction="left">
                    <Dropdown.Item
                      text="Apply Attendance"
                      onClick={() => {
                        setSelectedData(original);
                        setShowAddAttendanceModal(
                          !showAddAttendanceModal
                        );
                      }}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  const {
    attendance,
    avgChecInTime,
    avgChecOutTime,
    sick_leaves,
    annual_leaves,
  } = useMemo(
    () => staffTimeSheet,
    [staffTimeSheet]
  );

  const { rows: staffTimeSheetList } = useMemo(
    () => attendance ?? {},
    [attendance]
  );

  useEffect(() => {
    if (!flag) return;
    dispatch(
      getStaffTimeSheetThunk({
        filters: {
          from: dateRange.startDate.format(
            "YYYY-MM-DD"
          ),
          to: dateRange.endDate.format(
            "YYYY-MM-DD"
          ),
          staffIds: [user.id],
          deviceType: "web",
          status: "",
          name: "",
        },
        pagination: {
          page: 1,
          perPage:
            (dateRange.endDate.diff(
              dateRange.startDate,
              "days"
            ) +
              1) *
            10,
        },
      })
    ).then((res) => {
      if (res.payload) {
        setFlag(false);
      }
    });
  }, [
    dispatch,
    dateRange.startDate,
    dateRange.endDate,
    user.id,
    flag,
  ]);

  const onApplyFilter = useCallback((values) => {
    setDateRange({
      startDate: values.startDate,
      endDate: values.endDate,
    });
    setFlag(true);
  }, []);

  const onMarkManualAttendance = (values) => {
    const data = {
      id: selectData.id,
      checkIn: dayjs(
        values.checkIn,
        "HH:mm"
      ).format("HH:mm"),
      checkOut: dayjs(
        values.checkOut,
        "HH:mm"
      ).format("HH:mm"),
    };
    dispatch(
      applyManualAttendanceThunk({
        data,
      })
    ).then((res) => {
      if (res.payload) {
        setShowAddAttendanceModal(false);
        form.resetFields();
      }
    });
  };

  const _disabledHours = (disableFor) => {
    let disabledHours = [];

    if (disableFor === "startTime") {
      for (
        let i = Number(endTime?.format("H")) + 1;
        i < 24;
        i++
      ) {
        disabledHours.push(i);
      }
      return [...disabledHours];
    }

    if (disableFor === "endTime") {
      for (
        let i = 0;
        i < Number(startTime?.format("H"));
        i++
      ) {
        disabledHours.push(i);
      }
      return [...disabledHours];
    }

    return disabledHours;
  };

  const _disabledMinute = (
    disableFor,
    selectedHour
  ) => {
    const minutes = [];

    if (disableFor === "startTime") {
      if (
        selectedHour ===
        Number(endTime?.format("H"))
      ) {
        const currentMinute = Number(
          endTime?.format("mm")
        );
        for (
          let i = currentMinute + 1;
          i <= 60;
          i++
        ) {
          minutes.push(i);
        }
        return minutes;
      }
    }

    if (disableFor === "endTime") {
      if (
        selectedHour ===
        Number(startTime?.format("H"))
      ) {
        const currentMinute = Number(
          startTime?.format("mm")
        );
        for (
          let i = currentMinute - 1;
          i >= 0;
          i--
        ) {
          minutes.push(i);
        }
        return minutes;
      }
    }

    return minutes;
  };

  return (
    <div>
      <ConfirmModal
        title={`Manual Attendance`}
        description={`Please enter the manual attendance for the date ${moment(
          selectData?.date
        ).format("DD/MM/YY")}`}
        formId={formId}
        open={showAddAttendanceModal}
        confirmButtonText="Update"
        onClose={() =>
          setShowAddAttendanceModal(false)
        }
      >
        <div className="d-flex flex-column gap-3">
          <Form
            form={form}
            id={formId}
            layout="vertical"
            autoComplete="off"
            onFinish={onMarkManualAttendance}
          >
            <div
              className="d-flex gap-3 align-items-center"
              style={{ width: "100%" }}
            >
              <Form.Item
                label="Check In"
                name="checkIn"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message:
                      "Please Enter Check In Time!",
                  },
                ]}
              >
                <TimePicker
                  disabledHours={() =>
                    _disabledHours("startTime")
                  }
                  disabledMinutes={(time) =>
                    _disabledMinute(
                      "startTime",
                      time
                    )
                  }
                  inputReadOnly
                  allowClear={false}
                  format="HH:mm"
                  minuteStep={5}
                  onSelect={(value) =>
                    !!value &&
                    form.setFieldsValue({
                      checkIn: value,
                    })
                  }
                  className="text-input-field p-2 pe-3 t1"
                  style={{ width: "100%" }}
                  getPopupContainer={(trigger) =>
                    trigger.parentElement
                  }
                />
              </Form.Item>
              <Form.Item
                label="Check Out"
                style={{ width: "100%" }}
                name="checkOut"
                rules={[
                  {
                    required: true,
                    message:
                      "Please Enter Check Out Time!",
                  },
                ]}
              >
                <TimePicker
                  disabledHours={() =>
                    _disabledHours("endTime")
                  }
                  disabledMinutes={(time) =>
                    _disabledMinute(
                      "endTime",
                      time
                    )
                  }
                  inputReadOnly
                  allowClear={false}
                  format="HH:mm"
                  minuteStep={5}
                  onSelect={(value) =>
                    !!value &&
                    form.setFieldsValue({
                      checkOut: value,
                    })
                  }
                  className="text-input-field p-2 pe-3 t1"
                  style={{ width: "100%" }}
                  getPopupContainer={(trigger) =>
                    trigger.parentElement
                  }
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </ConfirmModal>
      <div className="">
        <TableToolbar
          headingText="Time Sheet"
          filterButton
          FilterComponent={
            <StaffTimeSheetFilterComponent
              dateRange={dateRange}
              onApplyFilter={onApplyFilter}
            />
          }
        />
        <div
          className="d-flex align-items-center gap-4"
          style={{ padding: "5px 24px" }}
        >
          <div
            className="d-flex align-items-center gap-4"
            style={{ width: "50%" }}
          >
            <DetailTab
              color="#CDF2EA"
              label="Avg Check-in "
              count={avgChecInTime}
            />
            <DetailTab
              color="#FFDB75"
              label="Avg Check-in "
              count={avgChecOutTime}
            />
          </div>
          <div style={{ width: "50%" }}>
            <DetailTab
              color="#FFCFC5"
              label="Absences "
              count2={sick_leaves?.count}
              count={annual_leaves?.count}
              role1="Annual"
              role2="Sick "
            />
          </div>
        </div>
        <div style={{ padding: "5px 24px" }}>
          {attendance?.rows.length <= 0 ? (
            <EmptyData
              title="Time Sheet Data"
              secondaryText="Clock In-to Your First Shift To Populate This Time Sheet"
            />
          ) : (
            <TableComponet
              rowCursor="pointer"
              perPageCount={
                (dateRange.endDate.diff(
                  dateRange.startDate,
                  "days"
                ) +
                  1) *
                10
              }
              isBorderShown
              columns={columns}
              data={staffTimeSheetList ?? []}
            />
          )}
        </div>
      </div>
    </div>
  );
};
