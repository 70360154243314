import React from "react";
import NoBranchImg from "../../../assets/img/blankStaff.png";
const NoTotalStaff = () => {
  return (
    <div className=" d-flex align-items-center justify-content-center flex-column px-2 py-4" style={{height:'calc(100% - 100px)'}}>
      <img src={NoBranchImg} alt="nobranch" className="mb-3" />

      <p
        className="text-center "
        style={{
          color: "#000",
          maxWidth: "249px",
        }}
      >
        No Total Staff, head over to section to add
      </p>
    </div>
  );
};

export default NoTotalStaff;
