import { Button } from "antd";
import React from "react";
import downlodicon from "../../../assets/icons/download_green.svg";
import DatePicker from "../../schedule/SchedularToolbar/datePicker/DatePicker";
import "./style.scss";

export const Heading = ({
  label,
  viewDetailsBtn,
  viewDetailsBtnHandler,
  showDatePicker,
  view,
  onDateChange,
  dateFilter,
  filterChildren,
  dateRange,
  downloadbtn,
  lastupated,
  lastupdatedPosition,
}) => {
  return (
    <div
      className="d-flex justify-content-between align-items-center heading border-bottom-header"
      style={{ padding: "16px 24px" }}
    >
      <div
        className={`d-flex ${
          lastupdatedPosition === "right"
            ? "justify-content-between w-100 "
            : "flex-column"
        }`}
      >
        <span className="heading4">{label}</span>
        {/* {lastupated && (
          <Typography.Text className="last-updated">
            <RefreshIcon /> Last Updated {lastupated} ago
          </Typography.Text>
        )} */}
      </div>
      <div className="d-flex " style={{ gap: "15px" }}>
        {viewDetailsBtn && (
          <Button
            onClick={viewDetailsBtnHandler}
            className="p-2 px-4 btn-style d-flex align-items-center gap-1 "
            type="primary"
            block
          >
            View Details
          </Button>
        )}
        {showDatePicker && (
          <DatePicker
            dateRange={dateRange}
            view={view}
            onDateChange={onDateChange}
          />
        )}
        {dateFilter && filterChildren}
        {downloadbtn && (
          <button className="custom_btn outlined btn">
            {" "}
            <img src={downlodicon} alt="download" /> <span>Download</span>
          </button>
        )}
      </div>
    </div>
  );
};
