import React, { useEffect, useMemo, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { CustomTabs } from "../../../components/UI/tabs";
import DocumentTab from "../../../components/documents/DocumentTab";
import { getProfileDataThunk } from "../../../redux/profile/profileThunk";
import { getRightToWorkList } from "../../../redux/staffs/staffsAPI";
import {
  getStaffAccrediationsThunk,
  getStaffVerificationThunk,
} from "../../../redux/staffs/staffsThunk";
import {
  enhanceAccreditations,
  prepareRightToWorkData,
} from "../../../utils/document";

const LocumDocument = () => {
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { staffAccrediations, staffVerification } = useSelector(
    (state) => state.staffs
  );

  const { data } = useQuery({
    queryKey: ["getRightToWorkList"],
    queryFn: () => getRightToWorkList(),
  });

  const rightToWorkDataSetForLocum = useMemo(() => {
    return prepareRightToWorkData(data?.data);
  }, [data?.data]);

  useEffect(() => {
    dispatch(getProfileDataThunk());
    dispatch(getStaffAccrediationsThunk({ staffId: user?.id }));
    dispatch(getStaffVerificationThunk({ staffId: user.id }));
  }, [dispatch, user]);

  const allAccreditations = useMemo(() => {
    if (!!staffAccrediations) {
      const systemAccreditation = enhanceAccreditations(staffAccrediations, {
        isExpiryRequired: 0,
        isDocument: 1,
        isMandatory: 1,
        document_type: "document",
      });

      return [...(systemAccreditation || [])];
    }
    return [];
  }, [JSON.stringify(staffAccrediations), staffAccrediations]);

  const tabs = useMemo(() => {
    return [
      {
        title: "Right To Work",
        content: (
          <DocumentTab
            tab="rightToWork"
            staffId={user?.id}
            fileList={rightToWorkDataSetForLocum}
          />
        ),
      },
      {
        title: "Accreditations",
        content: (
          <DocumentTab
            tab="accrediations"
            staffId={user?.id}
            fileList={allAccreditations}
          />
        ),
      },
    ];
  }, [
    user?.id,
    JSON.stringify(allAccreditations),
    JSON.stringify(rightToWorkDataSetForLocum),
  ]);

  return (
    <div className="staff_document_tab">
      <CustomTabs
        tabs={tabs}
        currentActiveTab={currentActiveTab}
        setCurrentActiveTab={setCurrentActiveTab}
      />
    </div>
  );
};

export default LocumDocument;
