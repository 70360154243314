import { API } from "../../../axios";

export const pharmacyDashboard = (payload) => {
  const { startDate, endDate } = payload;
  const request = API.post(`/profile/pharmacy-dashboard`, {
    startDate,
    endDate,
  });
  return request;
};
export const getPharmacyGapsData = (payload) => {
  const { startDateRange, endDateRange } = payload;
  const request = API.post(`/branches/pharmacy/gaps`, {
    startDateRange,
    endDateRange,
  });
  return request;
};
export const getPendingJobApprovalData = (payload) => {
  const { filters } = payload;
  const request = API.post(`/jobs/list`, {
    filters,
  });
  return request;
};

export const exportGapsCSV = (payload) => {
  const request = API.post(`/branches/gaps/pharmacy/csv-export`, payload);
  return request;
};
