import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { BranchCard } from "../../components/UI/cards/profileCard";

import { ContentContainer } from "../../components/UI/container";
import CalendarIcon from "../../components/UI/icons/CalendarIcon";
import DocumentIcon from "../../components/UI/icons/DocumentIcon";
import NotificationIcon from "../../components/UI/icons/NotificationIcon";
import PersonalInformation from "../../components/UI/icons/PersonalInformation";
import ProfileIcon from "../../components/UI/icons/ProfileIcon";
import TimeSheetIcon from "../../components/UI/icons/TimeSheetIcon";
import { SidebarNew } from "../../components/UI/sidebar-new/SidebarNew";
import CreateProfileCard from "../../components/complete-profile/CreateProfileCard/CreateProfileCard";
export const LocumDetails = () => {
  const { user } = useSelector((state) => state.auth);
  const pathname = window.location.pathname;
  const sidebarLinks = useMemo(
    () => [
      {
        text: "Locum Shifts",
        to: "locum-market",
        disabled: false,
        hidden: false,
        icon: <ProfileIcon />,
      },
      {
        text: "Personal Information",
        to: "information",
        disabled: false,
        hidden: false,
        icon: <PersonalInformation />,
      },
      {
        text: "Documents",
        to: "document",
        disabled: false,
        hidden: false,
        icon: <DocumentIcon />,
      },
      {
        text: "Schedule",
        to: "schedule",
        disabled: false,
        hidden: false,
        icon: <CalendarIcon />,
      },
      {
        text: "Time Sheet",
        to: "time-sheet",
        disabled: false,
        hidden: false,
        icon: <TimeSheetIcon />,
      },
      {
        text: "Notification Settings",
        to: "setting",
        disabled: false,
        hidden: false,
        icon: <NotificationIcon />,
      },
      // {
      //   text: "Verification",
      //   to: "verifications",
      //   disabled: false,
      //   hidden: false,
      // },
    ],
    []
  );

  const _status = useMemo(() => {
    if (!user) return null;

    if (user?.group === "locum") {
      return user?.status;
    }

    if (user?.status === "active" && Boolean(user?.isVerified)) {
      return "active";
    }

    return null;
  }, [user]);

  const _title = useMemo(() => {
    const link = sidebarLinks.find((link) => pathname?.includes(link.to));
    return link ? link.text : "";
  }, [sidebarLinks, pathname]);

  return (
    <div className="content-view-page details-page">
      <div className="p-3 border-bottom mb-4 bg-white ">
        <h3 className="heading4">{_title}</h3>
      </div>
      <div className="main-wrapper">
        <div className="content-holder locumview">
          <SidebarNew
            links={sidebarLinks}
            visible
            hideDeactivate={true}
            completeProfileSection={<CreateProfileCard isGrouped />}
          >
            <BranchCard
              isStaff
              staffInfo={user}
              userStatus={user?.isVerified}
              logo={user?.image}
              primaryText={`${user?.name} ${user?.surname}`}
              secondaryText={user?.label}
              hasRating={false}
              stats={{
                completed: user?.stats?.completedShifts,
                cancelled: user?.stats?.cancelledShifts,
                rating: user?.rating,
              }}
              showStatus={true}
              showVerifyButton={!Boolean(user?.isVerified)}
            />
          </SidebarNew>
          <ContentContainer className="content-container">
            <Outlet />
          </ContentContainer>
        </div>
      </div>
    </div>
  );
};
