import React, { useState } from "react";
import image3 from "../../../../../assets/img/dp3.png";
import image4 from "../../../../../assets/img/dp4.png";
import add from "../../../../../assets/img/addButton.png";
import { useEffect } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { RouterConfig } from "../../../../../routerConfig";
import { notify } from "../../../../../utils/helper";

export const CategoryDetail = ({ data, id, handleAddShift, operatingTime, setShow, isTemplate }) => {
  const navigate = useNavigate()
  const [shifts, setShifts] = useState([])

  useEffect(() => {
    if (!data) return
    data.sort(function (a, b) {
      return a.startTime?.localeCompare(b.startTime)
    });
    setShifts(data)
  }, [data])

  const onEditHandler = (_data) => {
    if (isTemplate) {
      setShow(false)
      handleAddShift(operatingTime, _data?.startDate, id, _data, _data.dayOfWeek, _data.weekNumber)
    }
    if (_data.status === 'unfilled' || _data.status === 'pending' || _data.status === 'active') {
      setShow(false)
      handleAddShift(operatingTime, _data?.startDate, id, _data)
      return
    }

    if (_data.isJob) {
      if (_data.status === 'completed') {
        notify('info', 'Hold Up, Jobs with \'Complete\' Status Can Not Be Edited Or Deleted.')
      }
      if (_data.status === 'payment_approved') {
        notify('info', 'Hold Up, Jobs with \'Payment Approved\' Status Can Not Be Edited Or Deleted.')
      }
      if (_data.status === 'paid') {
        notify('info', 'Hold Up, Jobs with \'Paid\' Status Can Not Be Edited Or Deleted.')
      }
    }
  }

  return (
    <div className="d-flex flex-column justify-content-between align-items-start CategoryDetailContainer">
      {
        shifts?.map((data, index) => (
          <div key={index} className="d-flex align-items-center justify-content-between detail-row">
            <span
              onClick={() => {
                if ((data.isJob && data.staff?.length && data.staff[0].id === 100) || data.staff?.id === 101) {
                  return
                }
                onEditHandler(data)
              }}
              className="p3 cursor-pointer">{data.startTime} - {data.endTime}</span>
            {
              data.status !== 'unfilled' && data.status !== 'pending' ?
                (
                  <div className="cursor-pointer d-flex gap-1 align-items-center"
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    onClick={() => {
                      if ((data.isJob && data.staff?.length && data.staff[0].id === 100) || data.staff?.id === 101) {
                        return
                      }
                      navigate(
                        generatePath(RouterConfig.branchAdminStaffInformation, {
                          staffId: data.isJob ? data.staff[0]?.id : data.staff?.id,
                        })
                      )
                    }}
                  >
                    {
                      data?.isJob ?
                        (
                          data.staff?.length && data.staff[0].image ?
                            (
                              <img
                                src={data.staff[0]?.image}
                                className="rounded-circle"
                                width={25}
                                height={25}
                                alt=""
                                style={{ marginRight: "8px" }}
                              />
                            )
                            :
                            (
                              <div
                                style={{
                                  width: 25,
                                  height: 25,
                                  borderRadius: '50%',
                                  background: '#E1F4F4',
                                  color: ' #065A4F',
                                  textAlign: 'center',
                                  lineHeight: '25px',
                                  padding: 1
                                }}
                              >
                                {`${data?.staff[0]?.name} ${data?.staff[0]?.surname}`
                                  ?.split(" ")
                                  ?.map((d, idx) => (idx < 2 ? d?.charAt(0).toUpperCase() : null))
                                  .join("")
                                }
                              </div>
                            )
                        )
                        :
                        (
                          data.staff?.image ?
                            <img src={data.staff?.image}
                              className="rounded-circle"
                              width={25}
                              height={25}
                              alt=""
                              style={{ marginRight: "8px" }}
                            />
                            :
                            <div
                              style={{
                                width: 25,
                                height: 25,
                                borderRadius: '50%',
                                background: '#E1F4F4',
                                // fontSize: 18,
                                color: ' #065A4F',
                                textAlign: 'center',
                                lineHeight: '25px',
                                padding: 1
                              }}
                              text={'altText'}
                            >
                              {`${data.staff?.name} ${data.staff?.surname}`
                                ?.split(" ")
                                ?.map((d, idx) => (idx < 2 ? d?.charAt(0).toUpperCase() : null))
                                .join("")
                              }
                            </div>
                        )
                    }
                    <span className="p3">{data.isJob ? data.staff[0]?.name : data.staff?.name} {data.isJob ? data.staff[0]?.surname : data.staff?.surname} </span>
                  </div>
                ) : data.status === 'unfilled' ? (
                  <div>
                    <img src={image4} alt="" style={{ marginRight: "8px" }} />
                    <span className="p3">{data.isJob ? 'Job Posted' : 'Shift Posted'} </span>
                  </div>
                )
                  : data.status === 'pending' ? (
                    <div>
                      <img src={image3} alt="" style={{ marginRight: "8px" }} />
                      <span className="p3">{data.isJob ? 'Job Pending' : 'Shift Pending'} </span>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center justify-content-between  break-time-row">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="verticleline"> </div>
                        <span className="p3">09:30 - 12:30</span>
                      </div>
                      <div>
                        <span className="p3">Break Time </span>
                      </div>
                    </div>
                  )
            }
          </div>
        ))
      }
      <div className="last-row cursor-pointer" onClick={() => {
        setShow(false)
        handleAddShift(operatingTime, data[0]?.startDate, id, undefined, data[0]?.dayOfWeek, data[0]?.weekNumber)
      }}
      >
        <div>
          <img
            src={add}
            alt=""
            className="me-2"
          />
          <span className="p3" style={{ color: "#7B7B7B" }}>
            Add Shift / Job
          </span>
        </div>
      </div>
    </div >
  );
}
