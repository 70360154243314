import { Button } from 'antd';
import React, { memo } from 'react';
import { Modal } from 'react-bootstrap';
import CustomIconButton from '../IconButton/IconButton';
import CloseIcon from '../icons/CloseIcon';
import EditIcon2 from '../icons/EditIcon2';
import TrashIcon from '../icons/TrashIcon';
import './style.scss';

export const AddModal = memo(
  ({
    handleModal,
    title,
    setHandleModal,
    children,
    primaryButtonText,
    secondaryButtonText,
    primaryButtonHandler,
    secondaryButtonHandler,
    disabledPrimaryBtn,
    formId,
    isLoading,
    isDelete,
    width,
    fixWidth,
    showDeleteButton,
    deleteButtonHandler,
    showEditButton,
    editButtonHandler,
  }) => {
    return (
      <Modal
        fullscreen
        show={handleModal}
        onHide={setHandleModal}
        enforceFocus={false}
        className={`modal-animation-right create-client-modal modal-dialog-scrollable ${
          fixWidth ? 'fixWidthContent' : ''
        }`}
      >
        <Modal.Header closeButton={false}>
          <Modal.Title className="modal_title">{title}</Modal.Title>

          <div className="modal-header-right">
            <div>
              {' '}
              {showEditButton && (
                <CustomIconButton
                  icon={<EditIcon2 />}
                  onClick={editButtonHandler}
                />
              )}
              {showDeleteButton && (
                <CustomIconButton
                  icon={<TrashIcon />}
                  onClick={deleteButtonHandler}
                />
              )}
            </div>
            <CustomIconButton icon={<CloseIcon />} onClick={setHandleModal} />
          </div>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <div className="b1 d-flex justify-content-between align-items-center add-shift-footer">
            {secondaryButtonText && (
              <Button
                onClick={secondaryButtonHandler}
                className="p-2 btn-style cancel"
                block
                danger={isDelete}
                type={isDelete ? 'primary' : 'default'}
              >
                {secondaryButtonText}
              </Button>
            )}
            {primaryButtonText && (
              <Button
                loading={isLoading}
                disabled={disabledPrimaryBtn}
                form={formId}
                onClick={primaryButtonHandler}
                className="p-2 btn-style save"
                type="primary"
                htmlType="submit"
                block
              >
                {primaryButtonText}
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    );
  },
);
