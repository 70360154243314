import React from "react";
import HospitalIcon from "../../../../src/assets/icons/Pharmacy_icon.svg";
import DropDownIcon from "../../../../src/assets/icons/downDrop.svg";
import DropUp from "../../../../src/assets/icons/dropUp.png";
import SettingsIcon from "../../../../src/assets/icons/setting.svg";

import { Avatar, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";

const WeekViewHead = ({ branchInfo, openTable, toggleTable }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`p-4  d-flex align-items-center justify-content-between  ${
        openTable ? "border-bottom-0 " : ""
      }`}
    >
      <div className="d-flex justify-content-start align-items-center pharma_time_sheet">
        {!!branchInfo?.logo ? (
          <Avatar className="me-3" src={branchInfo?.logo} alt="pharma" />
        ) : (
          <Avatar className="me-3" src={branchInfo?.logo} alt="pharma">
            {branchInfo?.title[0]}
          </Avatar>
        )}

        <div className="pharma_name">
          <div className="d-flex align-items-center">
            <h6 className="me-2">{branchInfo?.title}</h6>{" "}
            {/* <p className="mb-0 me-2">{branchInfo?.title}</p>{" "} */}
            {branchInfo?.active ? (
              <span className="badge rounded-pill status_badge">Open</span>
            ) : (
              <span className="badge rounded-pill status_badge close">
                Closed
              </span>
            )}
          </div>
          {!!branchInfo?.region && (
            <div className="d-flex align-items-center">
              <img src={HospitalIcon} alt="hospital" className="me-2" />
              <p className="mb-0">{branchInfo?.region}</p>
            </div>
          )}
        </div>
      </div>
      <div className=" d-flex align-items-center">
        <ul className="d-flex align-items-center rgt_side schedule_view">
          <li>
            <button
              className="btn custom_btn"
              onClick={() =>
                navigate(`/branches/${branchInfo.id}/details/template/new`)
              }
            >
              Template
            </button>
          </li>
          <li>
            <button
              className="btn"
              onClick={() =>
                navigate(`/branches/${branchInfo?.id}/details/information`)
              }
            >
              <Tooltip title="Branch Settings">
                <img src={SettingsIcon} alt="settings" />
              </Tooltip>
            </button>
          </li>
          {/* <li>
            <button className="btn">
              <Tooltip title="Copy">
                <img src={CopyIcon} alt="copy" />
              </Tooltip>
            </button>{" "}
          </li> */}
          <li>
            <button className="dropdown_btn btn" onClick={toggleTable}>
              <img src={openTable ? DropUp : DropDownIcon} alt="" />
            </button>
          </li>
        </ul>
        <div></div>

        {/* */}
      </div>
    </div>
  );
};

export default WeekViewHead;
