import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DatePickerCalendarIcon from "../UI/icons/DatePickerCalendarIcon";
import DropdownIcon from "../UI/icons/DropdownIcon";
import {
  contractTypeOptions,
  durationOptions,
} from "../staffs/contracts/contractForm";

const HourlyRateEditModal = ({ form, formId }) => {
  const { roles } = useSelector((state) => {
    return state.schedule;
  });

  const [jobRoles, setJobRoles] = useState();

  useEffect(() => {
    if (!roles) return;
    const _locumRoles = roles.map((i) => {
      return {
        label: i.label,
        value: i.id,
      };
    });
    setJobRoles(_locumRoles);
  }, [roles]);

  return (
    <Form
      id={formId}
      // onFinish={onFinish}

      form={form}
      layout="vertical"
      autoComplete="off"
    >
      {" "}
      <Row gutter={[16, 16]} className="info-modal-row">
        <Col lg={12} xs={24}>
          <Form.Item label="Hire Date" name="email">
            <DatePicker
              className="date-picker"
              suffixIcon={<DatePickerCalendarIcon />}
              placeholder="Select Date"
              defaultValue={dayjs()}
              clearIcon
            />
          </Form.Item>
        </Col>
        <Col lg={12} xs={24}>
          <Form.Item label="Position" name="jobRoleId">
            <Select
              defaultValue="Pharmacist"
              style={{ width: "100%" }}
              options={jobRoles}
              className="select-field"
              suffixIcon={<DropdownIcon />}
            />
          </Form.Item>
        </Col>
        <Col lg={12} xs={24}>
          <Form.Item
            label="Absence Allowance"
            name="absenceDays"
            rules={[
              { required: true, message: "Please input Absence Days count!" },
            ]}
          >
            <Input type="number" className="text-input-field p-2 pe-3 t1" />
          </Form.Item>
        </Col>
        <Col lg={12} xs={24}>
          <Form.Item
            label="Employment Type"
            name="type"
            rules={[{ required: true, message: "Please Select Contact Type!" }]}
          >
            <Select
              style={{ width: "100%" }}
              options={contractTypeOptions}
              className="select-field"
              suffixIcon={<DropdownIcon />}
            />
          </Form.Item>
        </Col>
        <Col lg={12} xs={24}>
          <Form.Item
            label="Pay Type"
            name="workingPeriodType"
            rules={[
              {
                required: true,
                message: "Please Select Working Period Type!",
              },
            ]}
          >
            <Select
              className="text-input-field p-1 pe-3 t1"
              bordered={false}
              dropdownStyle={{ zIndex: 2000 }}
              placeholder="Contract Type"
              options={durationOptions.filter((i) => i.value !== "year")}
            />
          </Form.Item>
        </Col>
        <Col lg={12} xs={24}>
          <Form.Item
            label="Hourly rate"
            name="hourlyRate"
            rules={[
              {
                required: true,
                message: "Please Enter hourly rate (per hour)!",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Rate (per hour)"
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>
        </Col>
        <Col lg={12} xs={24}>
          <Form.Item
            label="Overtime Rate (per hour)"
            name="overTimePayHourly"
            rules={[
              {
                required: true,
                message: "Please Enter Overtime rate (per hour)!",
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Overtime rate (per hour)"
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>
        </Col>
        <Col lg={12} xs={24}>
          <Form.Item
            label="Gross Salary"
            name="grossSalaryPay"
            rules={[{ required: true, message: "Please input Gross Salary!" }]}
          >
            <Input
              type="number"
              placeholder="Gross Salary"
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>
        </Col>
        <Col lg={12} xs={24}>
          <Form.Item
            label="Effective Date"
            name="endDate"
            rules={[
              { required: true, message: "Please Select Effective Date!" },
            ]}
          >
            <DatePicker
              inputReadOnly
              format="DD-MM-YYYY"
              getPopupContainer={(trigger) => trigger.parentElement}
              style={{ width: "100%" }}
              className="date-picker"
              suffixIcon={<DatePickerCalendarIcon />}
              placeholder="Select Date"
              defaultValue={dayjs()}
              clearIcon
            />
          </Form.Item>
        </Col>
      </Row>
      {/* <Checkbox className="checkbox">Currently Working in this role.</Checkbox> */}
    </Form>
  );
};

export default HourlyRateEditModal;
