import React from 'react';

const EditIcon2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3.99805 20.0003H7.99805L18.498 9.5003C19.6026 8.39573 19.6026 6.60487 18.498 5.5003C17.3935 4.39573 15.6026 4.39573 14.498 5.5003L3.99805 16.0003V20.0003"
        stroke="#434343"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 6.5L17.5 10.5"
        stroke="#434343"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon2;
