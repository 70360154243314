import moment from 'moment';
import React, { useEffect, useState } from 'react';
import lock from '../../../assets/icons/lock.png';
import { weekDays } from '../../../utils/dates';
import ShiftDetailsViewBox from '../ShiftDeatails/ShiftDetailsViewBox';

const PharmacyMonthView = ({ branchData, onDelete }) => {
  const mappingWeek = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];
  const chunks = [];
  const chunkSize = 7;

  const [updatedData, setUpdatedData] = useState([]);
  useEffect(() => {
    if (!branchData?.length) return;
    const weekWiseObject = {};
    branchData.forEach((data) => {
      // Adjust week numbering to start from 0
      const branch_week =
        moment(data.startDate).isoWeek() - moment().isoWeek() + 7 - 1;

      weekWiseObject[branch_week] = weekWiseObject[branch_week]
        ? [...weekWiseObject[branch_week], data]
        : [data];
    });

    setUpdatedData(Object.values(weekWiseObject));
  }, [branchData]);

  return (
    <div className="mb-4 preview_modal_calender">
      {/* <div className={`bg-white rounded-top p-4 border d-flex align-items-center justify-content-between ${openTable ? "border-bottom-0 rounded-top" : "rounded-bottom"}` }>
        <div className="d-flex justify-content-start align-items-center pharma_time_sheet">
          <img src={PharmnaLogo} alt="pharma" className="me-3" />
          <div className="pharma_name">
            <div className="d-flex align-items-center">
              <h6 className="me-2">{pharmacy_name}</h6>
              <p className="mb-0 me-2">{branch}</p>
              {open ? (
                <span className="badge rounded-pill status_badge">Open</span>
              ) : (
                <span className="badge rounded-pill status_badge close">Close</span>
              )}
            </div>
            <div className="d-flex align-items-center">
              <img src={HospitalIcon} alt="hospital" className="me-2" />
              <p className="mb-0">{region}</p>
            </div>
          </div>
        </div>
        <div className="rgt_side d-flex align-items-center">
          <div>
            <button className="btn custom_btn">Template</button>
          </div>
          <button className="btn">
            <Tooltip title="Branch Settings">
              <img src={SettingsIcon} alt="settings" />
            </Tooltip>
          </button>
          <button className="btn">
            <Tooltip title="Copy">
              <img src={CopyIcon} alt="copy" />
            </Tooltip>
          </button>
          <button className="dropdown_btn btn" onClick={toggleTable}>
            <img src={openTable ? DropUp : DropDownIcon} alt="" />
          </button>
        </div>
      </div> */}
      {/* {openTable && ( */}
      <div className="table_container ">
        <table className="table table-bordered bg-white weekview_table monthview_table">
          <thead>
            <tr>
              {weekDays?.map((day) => {
                return (
                  <th scope="col" key={day} className="p-4 text-center">
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <span className="s1">
                        {day.charAt(0).toUpperCase() + day.slice(1, 3)}
                      </span>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {updatedData?.map((week, i) => (
              <tr key={i} className="table-row">
                {mappingWeek?.map((day, idx) => {
                  const matchingDay = week.find(
                    (_day) => _day.day.toLowerCase() === day,
                  );

                  const isDayClosed = matchingDay?.branchClosed;
                  const hasShiftDetails = !!matchingDay;

                  return (
                    <td
                      key={idx}
                      className={`month-schedular-slot-container ${
                        isDayClosed && 'week-day-closed'
                      }`}
                    >
                      {!!matchingDay?.startDate ? (
                        <span className="month_view_date">
                          {moment(matchingDay?.startDate, 'YYYY-MM-DD').format(
                            'DD',
                          )}
                        </span>
                      ) : null}

                      {isDayClosed ? (
                        <div
                          className="week-day-detail-cell-closed d-flex flex-column gap-1 justify-content-between cursor-pointer appear_on_hover"
                          style={{ borderBottom: 'none' }}
                        >
                          <div></div>
                          <div className="d-flex flex-column align-items-center gap-2">
                            <img src={lock} alt="" />
                            <span className="t2">Closed</span>
                          </div>
                          <div></div>
                        </div>
                      ) : (
                        <>
                          {' '}
                          {hasShiftDetails ? (
                            <>
                              {matchingDay?.shifts?.map((shift, idx) => (
                                <>
                                  <ShiftDetailsViewBox
                                    shift={shift}
                                    templateView={true}
                                    key={shift?.id}
                                    isPreview
                                    weekNumber={i + 1}
                                    onDelete={onDelete}
                                  />
                                </>
                              ))}

                              {/* <ShiftDetails
                              events={matchingDay}
                              monthview={true}
                              branch={branchInfo}
                            /> */}
                            </>
                          ) : (
                            <div />
                          )}
                        </>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* )} */}
    </div>
  );
};

export default PharmacyMonthView;
