import React, { useState, useRef, useEffect } from "react";
import { rolesColor } from "../../SchedularToolbar";
import { SlotDetailModal } from "../../schedule-Week-view/Slot-detail-component/SlotDetail";
import "./style.scss";
import { UserProfileModal } from '../../../comon/userProfileModal';


function useOutsideAlerter(ref, setSlotDetailModal) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setSlotDetailModal(false);
      }
      // else {
      //   setSlotDetailModal(true);
      // }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
}

export const DayViewSlotDetail = (props) => {
  const ref = useRef(null);
  const refPopover = useRef(null);
  const [isSlotModal, setSlotDetailModal] = useState(false);
  const [target, setTarget] = useState(null);
  useOutsideAlerter(refPopover, setSlotDetailModal);
  const [selectedShift, setSelectedShift] = useState();
  const [showUserProfileModal, setShowUserProfileModal] = useState(false)

  return (
    <>
      {
        showUserProfileModal &&
        <UserProfileModal
          showUserProfileModal={showUserProfileModal}
          selectedUser={selectedShift?.staff.length ? selectedShift?.staff[0] : selectedShift?.staff}
          setShowUserProfileModal={setShowUserProfileModal}
        />
      }
      <div
        className=" d-flex align-items-center slotDetailContainer"
        style={{
          width: props.width, top: props.top, marginLeft: props.margin,
        }}
        ref={ref}
        onClick={(e) => {
          setSlotDetailModal(true);
          setTarget(e.target);
          setSelectedShift(props.data)
        }}
      >
        <div className="branch-detail-row-badge"
          style={{
            background: rolesColor[props.data?.role ? props.data?.role : props.data?.roleType?.id ? props.data?.roleType?.id : props.data?.staff?.role],
          }}>
        </div>
        <span className="role-text">{props.label} </span>
        <span className="p4" style={{
          marginLeft: "5px",

        }}>
          {props.time}
        </span>
      </div>
      {isSlotModal && (
        <div ref={refPopover}>
          <SlotDetailModal
            onDelete={props.onDelete}
            handleAddShift={props.handleAddShift}
            show={isSlotModal}
            data={selectedShift}
            target={target}
            reference={refPopover}
            setSlotDetailModal={setSlotDetailModal}
            setShowUserProfileModal={setShowUserProfileModal}

          />
        </div>
      )}
    </>
  );
};
