import { sortBy, uniqBy } from "lodash";

export const sortByGphcNumber = (data) => {
  const shifts = data?.shifts;
  const temp = sortBy(shifts, (shift) =>
    parseInt(shift.staff.gphcNumber)
  );
  return temp;
};

export function getUniqueShiftsSorted(data) {
  // Extract all shifts from the dataset
  let allShifts = [];

  data?.forEach((entry) => {
    !!entry?.shifts &&
      allShifts.push(...entry.shifts);
  });

  // allShifts = uniqBy(
  //   allShifts,
  //   "staff.gphcNumber"
  // );

  // sort by staff.gphcNumber
  // allShifts = uniqBy(
  //   allShifts,
  //   "staff.gphcNumber"
  // );

  allShifts?.sort((a, b) => {
    // Accessing staff GPhC number for comparison
    let gphcA = parseInt(a?.staff?.gphcNumber);
    let gphcB = parseInt(b?.staff?.gphcNumber);

    // Comparing GPhC numbers
    if (gphcA < gphcB) {
      return -1;
    }
    if (gphcA > gphcB) {
      return 1;
    }
    return 0;
  });

  return allShifts;
}

export function uniqShiftByGphc(allShifts) {
  const uniqByGphc = uniqBy(
    allShifts,
    "staff.gphcNumber"
  );

  // let obj = {};

  // uniqByGphc.forEach((shift) => {
  //   const _gphcNumber = shift?.staff?.gphcNumber;

  //   const allShiftsForGphc = allShifts.filter(
  //     (item) =>
  //       item?.staff?.gphcNumber === _gphcNumber
  //   );

  //   obj = {
  //     ...obj,
  //     [_gphcNumber]: {
  //       staff: shift?.staff,

  //       shifts: allShiftsForGphc,
  //     },
  //   };
  // });

  return uniqByGphc;
}

export function concatMultipleShiftsDateWise(
  shifts,
  gphcNumber
) {
  const uniqByStartDate = uniqBy(
    shifts,
    "startDate"
  );

  let obj = {};

  shifts.forEach((shift) => {
    const startDate = shift?.startDate;

    const allShiftsForStartDate =
      uniqByStartDate.filter(
        (item) =>
          item?.startDate === startDate &&
          item?.staff?.gphcNumber === gphcNumber
      );

    obj = {
      ...obj,
      [startDate]: allShiftsForStartDate,
    };
  });

  return obj;
}
