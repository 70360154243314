import { useEffect, useRef } from "react";

const useLatestRef = (value) => {
  const valueRef = useRef(value);
  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return valueRef;
};

export default useLatestRef;
