import { Button } from "antd";
import React, { useEffect, useState } from "react";
import "./style.scss";
import { useSearchParams } from "react-router-dom";
export const ErrorPage = () => {
  const [message, setMessage] = useState('')

  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (!searchParams.get('message')) return
    setMessage(searchParams.get('message'))
  }, [searchParams])
  return (
    <div className="d-flex flex-column align-items-center justify-content-center errorScreen">
      <div className="errorText">404</div>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="heading2">Page not found!</div>
        <div className=" p3" style={{ width: "576px", textAlign: "center" }}>
          {
            message || `Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Sorem Ipsum has been the industry's standard dummy text ever
            since the 1500s, when anunknown printer.`
          }

        </div>
      </div>

      {/* <div>
        <Button type="primary" className="p-2 px-4 btn-style d-flex align-items-center gap-1" block>
          Back to Home
        </Button>
      </div> */}
    </div>
  );
};
