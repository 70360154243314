import React from "react";

const CalendarIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M16.7502 3.56V2C16.7502 1.59 16.4102 1.25 16.0002 1.25C15.5902 1.25 15.2502 1.59 15.2502 2V3.5H8.75023V2C8.75023 1.59 8.41023 1.25 8.00023 1.25C7.59023 1.25 7.25023 1.59 7.25023 2V3.56C4.55023 3.81 3.24023 5.42 3.04023 7.81C3.02023 8.1 3.26023 8.34 3.54023 8.34H20.4602C20.7502 8.34 20.9902 8.09 20.9602 7.81C20.7602 5.42 19.4502 3.81 16.7502 3.56Z"
        fill={color ? color : "#434343"}
      />
      <path
        d="M20 9.83984H4C3.45 9.83984 3 10.2898 3 10.8398V16.9998C3 19.9998 4.5 21.9998 8 21.9998H12.93C13.62 21.9998 14.1 21.3298 13.88 20.6798C13.68 20.0998 13.51 19.4598 13.51 18.9998C13.51 15.9698 15.98 13.4998 19.01 13.4998C19.3 13.4998 19.59 13.5198 19.87 13.5698C20.47 13.6598 21.01 13.1898 21.01 12.5898V10.8498C21 10.2898 20.55 9.83984 20 9.83984ZM9.21 17.7098C9.02 17.8898 8.76 17.9998 8.5 17.9998C8.37 17.9998 8.24 17.9698 8.12 17.9198C8 17.8698 7.89 17.7998 7.79 17.7098C7.61 17.5198 7.5 17.2698 7.5 16.9998C7.5 16.8698 7.53 16.7398 7.58 16.6198C7.63 16.4898 7.7 16.3898 7.79 16.2898C7.89 16.1998 8 16.1298 8.12 16.0798C8.48 15.9198 8.93 16.0098 9.21 16.2898C9.3 16.3898 9.37 16.4898 9.42 16.6198C9.47 16.7398 9.5 16.8698 9.5 16.9998C9.5 17.2698 9.39 17.5198 9.21 17.7098ZM9.21 14.2098C9.02 14.3898 8.76 14.4998 8.5 14.4998C8.37 14.4998 8.24 14.4798 8.12 14.4198C8 14.3698 7.89 14.2998 7.79 14.2098C7.61 14.0198 7.5 13.7598 7.5 13.4998C7.5 13.3698 7.53 13.2398 7.58 13.1198C7.63 12.9998 7.7 12.8898 7.79 12.7898C7.89 12.6998 8 12.6298 8.12 12.5798C8.48 12.4298 8.93 12.5098 9.21 12.7898C9.3 12.8898 9.37 12.9998 9.42 13.1198C9.47 13.2398 9.5 13.3698 9.5 13.4998C9.5 13.7598 9.39 14.0198 9.21 14.2098ZM12.92 13.8798C12.87 13.9998 12.8 14.1098 12.71 14.2098C12.61 14.2998 12.5 14.3698 12.38 14.4198C12.26 14.4798 12.13 14.4998 12 14.4998C11.74 14.4998 11.48 14.3898 11.29 14.2098C11.2 14.1098 11.13 13.9998 11.08 13.8798C11.03 13.7598 11 13.6298 11 13.4998C11 13.2398 11.11 12.9798 11.29 12.7898C11.57 12.5098 12.01 12.4198 12.38 12.5798C12.5 12.6298 12.61 12.6998 12.71 12.7898C12.89 12.9798 13 13.2398 13 13.4998C13 13.6298 12.98 13.7598 12.92 13.8798Z"
        fill={color ? color : "#434343"}
      />
      <path
        d="M19 15C16.79 15 15 16.79 15 19C15 21.21 16.79 23 19 23C21.21 23 23 21.21 23 19C23 16.79 21.21 15 19 15ZM20.6 20.64C20.45 20.79 20.26 20.86 20.07 20.86C19.88 20.86 19.69 20.79 19.54 20.64L19.01 20.11L18.46 20.66C18.31 20.81 18.12 20.88 17.93 20.88C17.74 20.88 17.55 20.81 17.4 20.66C17.11 20.37 17.11 19.89 17.4 19.6L17.95 19.05L17.42 18.52C17.13 18.23 17.13 17.75 17.42 17.46C17.71 17.17 18.19 17.17 18.48 17.46L19.01 18L19.51 17.5C19.8 17.21 20.28 17.21 20.57 17.5C20.86 17.79 20.86 18.27 20.57 18.56L20.07 19.06L20.6 19.59C20.89 19.88 20.89 20.35 20.6 20.64Z"
        fill={color ? color : "#434343"}
      />
    </svg>
  );
};

export default CalendarIcon;
