import EventEmitter from "eventemitter3";

const eventEmitter = new EventEmitter();

export const events = {
  openEdit: "openEdit",
  fetchLeave: "fetchLeave",
  timeSheetCelledit: "timeSheetCelledit",
  roleId: "roleId",
  openSegment: "openSegment",
};

export default eventEmitter;
