import {
  Button,
  Form,
  Input,
  Select,
} from "antd";
import _ from "lodash";
import React, {
  useEffect,
  useState,
} from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import {
  Link,
  useNavigate,
} from "react-router-dom";
import dropdown from "../../../assets/icons/downDrop.svg";
import { signUpThunk } from "../../../redux/auth/authThunk";
import { getGphcInfoThunk } from "../../../redux/staffs/staffsThunk";
import { RouterConfig } from "../../../routerConfig";
import {
  genRandonString,
  handleWheel,
  notify,
} from "../../../utils/helper";
import { phoneValidation } from "../../../utils/validation.message";
import "../style.scss";

const roleOptions = [
  { value: 4, label: "Locum Pharmacist" },
  { value: 5, label: "Locum Technician" },
];

export const LocumSignUpFormNew = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [btndisabled, setbtndisabled] =
    useState(true);
  const gphcNumber = Form.useWatch(
    "gphcNumber",
    form
  );

  const onValuesChange = (
    changedValues,
    allValues
  ) => {
    if (
      allValues.email !== "" &&
      allValues.password !== "" &&
      allValues.gphcNumber !== undefined &&
      allValues.gphcNumber !== "" &&
      allValues.name !== "" &&
      allValues.surname !== "" &&
      allValues.roleId !== null &&
      allValues.roleId !== undefined &&
      allValues.phone !== undefined &&
      allValues.phone !== ""
    ) {
      setbtndisabled(false);
    } else {
      setbtndisabled(true);
    }
  };

  const { gphcInfo } = useSelector(
    (state) => state.staffs
  );

  useEffect(() => {
    if (Number(gphcNumber?.length) !== 7) {
      form.setFieldsValue({
        name: "",
        surname: "",
        roleId: null,
      });
      return;
    }

    dispatch(
      getGphcInfoThunk({ gphcNumber })
    ).then((res) => {
      if (!res.payload) {
        form.setFieldsValue({
          name: "",
          surname: "",
          roleId: null,
        });
      }
    });
  }, [dispatch, gphcNumber]);

  useEffect(() => {
    if (_.isEmpty(gphcInfo)) return;
    if (!gphcInfo.type) {
      notify(
        "error",
        "There seems to be a problem with the GPHC number you entered; contact the system administrator for support"
      );
      form.setFieldsValue({
        name: "",
        surname: "",
        roleId: null,
      });
      return;
    }
    if (gphcInfo.type === "pharmacist") {
      form.setFieldsValue({
        roleId: 4,
      });
    } else {
      form.setFieldsValue({
        roleId: 5,
      });
    }

    form.setFieldsValue({
      name: gphcInfo.forenames,
      surname: gphcInfo.surname,
    });
  }, [gphcInfo]);

  const onFinish = (value) => {
    const reqData = {
      ...value,
      deviceToken: genRandonString(20),
    };
    dispatch(signUpThunk(reqData)).then((res) => {
      if (res.payload?.status === "success") {
        if (window.analytics && window.fbq) {
          window.analytics.identify(
            res.payload.data.id,
            {
              id: res.payload.data.id,
              email: res.payload.data.email,
              userType: res.payload.data.group,
              name: res.payload.data.name,
              surname: res.payload.data.surname,
              role: res.payload.data.role,
              gphcNumber:
                res.payload.data.gphcNumber,
              device: "web",
            }
          );

          window.analytics.track(
            res.payload.data.id,
            {
              id: res.payload.data.id,
              email: res.payload.data.email,
              userType: res.payload.data.group,
              name: res.payload.data.name,
              surname: res.payload.data.surname,
              role: res.payload.data.role,
              gphcNumber:
                res.payload.data.gphcNumber,
              device: "web",
            }
          );
          window.fbq(
            "trackCustom",
            "SignUp Locum",
            {
              id: res.payload.data.id,
              email: res.payload.data.email,
              userType: res.payload.data.group,
              name: res.payload.data.name,
              surname: res.payload.data.surname,
              role: res.payload.data.role,
              gphcNumber:
                res.payload.data.gphcNumber,
              device: "web",
            }
          );
        }

        notify(
          "success",
          "Sign In link sent to your Email!"
        );
        form.resetFields();
        navigate(RouterConfig.login);
      }
    });
  };

  return (
    <div className="locum_signup_new">
      <div className="header text-center">
        <div className="heading2">
          Getting Started
        </div>
        <div className="t1 sub_heading">
          Create an account to continue.
        </div>
      </div>
      <Form
        onFinish={onFinish}
        form={form}
        onValuesChange={onValuesChange}
        layout="vertical"
        autoComplete="off"
        className="locum_signup_form_new"
      >
        <Form.Item
          label="GPhC Number"
          name="gphcNumber"
          // normalize={(value, prevVal, prevVals) => value.trim()}
          normalize={(value) => {
            if (value.length > 7)
              return value.slice(0, 7);
            return value;
          }}
          rules={[
            {
              message:
                "Please Enter your GPhC Number!",
            },
            {
              len: 7,
              message:
                "GPhC Number should be 7 digits!",
            },
          ]}
        >
          <Input
            onBlur={(e) =>
              form.setFieldsValue({
                gphcNumber: e.target.value.trim(),
              })
            }
            onWheel={handleWheel}
            type="number"
            placeholder="Enter GPhC Number"
            className="text-input-field p-2 pe-3 t1"
          />
        </Form.Item>
        <div
          className="d-flex align-items-center gap-3"
          style={{ width: "100%" }}
        >
          <Form.Item
            normalize={(
              value,
              prevVal,
              prevVals
            ) => value.trim()}
            label="First Name"
            style={{ width: "100%" }}
            onBlur={(e) =>
              form.setFieldsValue({
                name: e.target.value.trim(),
              })
            }
            name="name"
            rules={[
              {
                message:
                  "Please Enter your First Name !",
              },
            ]}
          >
            <Input
              placeholder="Enter First Name"
              onBlur={(e) =>
                form.setFieldsValue({
                  name: e.target.value.trim(),
                })
              }
              className="text-input-field p-2 pe-3 t1"
              maxLength={50}
            />
          </Form.Item>
          <Form.Item
            normalize={(
              value,
              prevVal,
              prevVals
            ) => value.trim()}
            style={{ width: "100%" }}
            label="Last Name"
            name="surname"
            rules={[
              {
                message:
                  "Please Enter your Last Name !",
              },
            ]}
          >
            <Input
              onBlur={(e) =>
                form.setFieldsValue({
                  surname: e.target.value.trim(),
                })
              }
              placeholder="Enter Last Name"
              className="text-input-field p-2 pe-3 t1"
              maxLength={50}
            />
          </Form.Item>
        </div>
        <Form.Item
          name="roleId"
          label="Role"
          rules={[
            {
              required: true,
              message:
                "Please Enter In A Correct GPhC Number To Populate This Field!",
            },
          ]}
        >
          <Select
            placeholder="Select"
            bordered={false}
            className="text-input-field pe-3 p-1 t1 bg-white"
            suffixIcon={
              <img
                src={dropdown}
                alt="dropdown"
              />
            }
          >
            {roleOptions.map((data, index) => (
              <Select.Option
                key={index}
                value={data.value}
              >
                {data.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Email Address"
          normalize={(value, prevVal, prevVals) =>
            value.trim()
          }
          name="email"
          rules={[
            {
              type: "email",

              message:
                "Please Enter your Email Address!",
            },
          ]}
        >
          <Input
            onBlur={(e) =>
              form.setFieldsValue({
                email: e.target.value.trim(),
              })
            }
            placeholder="Enter address"
            className="text-input-field p-2 pe-3 t1"
            maxLength={50}
          />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          normalize={(value, prevVal, prevVals) =>
            value.trim()
          }
          name="phone"
          rules={phoneValidation}
        >
          <Input
            onBlur={(e) =>
              form.setFieldsValue({
                phone: e.target.value.trim(),
              })
            }
            type="number"
            placeholder="Enter phone number"
            onWheel={handleWheel}
            className="text-input-field p-2 pe-3 t1"
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              message:
                "Please Enter your password!",
            },
            {
              pattern:
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
              message: `Password doesn't match the Criteria!`,
            },
          ]}
        >
          <Input.Password
            onBlur={(e) =>
              form.setFieldsValue({
                password: e.target.value.trim(),
              })
            }
            // prefix={
            //   <Popover
            //     content={PasswordCriteria}
            //     placement="left"
            //     title="Password Criteria"
            //   >
            //     <img src={infoIcon} alt="" />
            //   </Popover>
            // }
            placeholder="Enter password"
            className="text-input-field p-2 pe-3 t1"
            maxLength={50}
          />
        </Form.Item>

        <div
          className="p2 text-center d-flex justify-content-between align-items-center form_bottom"
          style={{
            color: "#000",
            paddingTop: "10px",
          }}
        >
          <span className="">
            Already have an account?
            <Link
              to={RouterConfig.login}
              className="text-decoration-none"
              style={{ color: "#09988D" }}
            >
              Login
            </Link>
          </span>
          <Form.Item className="mb-0">
            <Button
              disabled={btndisabled}
              className=" btn-style custom_btn auth_button"
              type="primary"
              htmlType="submit"
              block
              id="signUpLocum_confirmed"
            >
              Sign Up
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
