import { Button } from "antd";
import React from "react";
import Modal from "react-bootstrap/Modal";
import "./style.scss";

const CommonModal = ({
  handleModal,
  title,
  setHandleModal,
  children,
  primaryButtonText,
  secondaryButtonText,
  primaryButtonHandler,
  secondaryButtonHandler,
  disabledPrimaryBtn,
  isLoading,
  isDelete,
  width,
  fixWidth,
  fullscreen,
}) => {
  return (
    <Modal
      show={handleModal}
      onHide={setHandleModal}
      className={`modal-dialog-scrollable common_modal${
        fixWidth ? "fixWidthContent" : ""
      }`}
      scrollable
      enforceFocus={false}
      fullscreen={Boolean(fullscreen)}
    >
      <Modal.Header closeButton>
        <Modal.Title className="modal_title">{title}</Modal.Title>{" "}
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <div className="b1 d-flex justify-content-between align-items-center add-shift-footer">
          {secondaryButtonText && (
            <Button
              onClick={secondaryButtonHandler}
              className="p-2 btn-style secondary-btn"
              block
              danger={isDelete}
              type={isDelete ? "primary" : "default"}
            >
              {secondaryButtonText}
            </Button>
          )}
          {primaryButtonText && (
            <Button
              loading={isLoading}
              disabled={disabledPrimaryBtn}
              onClick={primaryButtonHandler}
              className="p-2 btn-style primary-btn"
              type="primary"
              htmlType="submit"
              block
            >
              {primaryButtonText}
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CommonModal;
