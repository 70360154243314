import { Form, Select } from "antd";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { FilterComponent } from "../../Filter";
import "./style.scss";

const jobStatuses = [
  {
    value: "active",
    label: "Active",
  },
  {
    value: "unfilled",
    label: "Unfilled",
  },
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "cancelled",
    label: "Cancelled",
  },
  {
    value: "paid",
    label: "Paid",
  },
  {
    value: "payment_approved",
    label: "Payment Approved",
  },
  {
    value: "completed",
    label: "Completed",
  },
];

export const LocumShiftsFilterComponent = ({ onApplyFilter }) => {
  const [form] = Form.useForm();
  const formId = useMemo(() => "locum-shifts-filter-form", []);
  const { regionBranches } = useSelector((state) => state.regions);

  const clearFilterFormHandler = () => {
    form.resetFields();
    onApplyFilter();
  };

  const onSubmit = (values) => {
    onApplyFilter(values);
  };
  return (
    <FilterComponent
      formId={formId}
      onApplyFilter={form.submit}
      clearFormHandler={form.isFieldsTouched() && clearFilterFormHandler}
    >
      <Form
        id={formId}
        onFinish={onSubmit}
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item name="status" label="Status">
          <Select
            bordered={false}
            className="text-input-field p-1 pe-3 t1"
            options={jobStatuses}
          />
        </Form.Item>
        <Form.Item name="branchTitles" label="Branches">
          <Select
            mode="multiple"
            showSearch
            bordered={false}
            className="text-input-field p-1 pe-3 t1"
          >
            {regionBranches?.map((data, idx) => (
              <Select.Option key={idx} value={data.title}>
                {data.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </FilterComponent>
  );
};
