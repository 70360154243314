import React, { useMemo } from "react";

import { Avatar } from "antd";
import moment from "moment";
import { useState } from "react";
import EditIcon from "../../../../src/assets/icons/EditIcon.svg";
import DashboardIcon from "../../../../src/assets/icons/pattern.svg";
import RightArrow from "../../../../src/assets/icons/right-arrow.png";
import DeleteIcon from "../../../../src/assets/icons/trash.svg";
import CrossIcon from "../../../../src/assets/icons/x.png";
import eventEmitter, {
  events,
} from "../../../utils/eventEmitter";
import { showLabel } from "../../../utils/timeshhet";
import { ConfirmModal } from "../../UI/modals/confirmModal";
const ShiftDetailsView = ({
  type,
  onClose,
  shift,
  onDelete,
  weekNumber,
  templateView,
  isPreview,
  operatingTime,
  z,
}) => {
  const [openConfirm, setOpenConfrim] =
    useState(false);
  const handleEditClick = () => {
    onClose();

    eventEmitter.emit(events.openEdit, {
      ...shift,
      weekNumber,
    });
  };

  const handleDelete = () => {
    onDelete(shift);
    setOpenConfrim(false);
  };

  const handleDeleteOpen = () => {
    onClose();
    setTimeout(() => {
      setOpenConfrim(true);
    }, 500);
  };

  const showEditButton = useMemo(() => {
    if (shift?.isJob) {
      if (shift?.status === "completed") {
        return false;
      }
      if (shift?.status === "payment_approved") {
        return false;
      }
      if (shift?.status === "paid") {
        return false;
      }
    }

    if (shift?.status === "pending") {
      return false;
    }

    return true;
  }, [shift]);

  const showdeleteButton = useMemo(() => {
    if (shift?.isJob) {
      if (shift?.status === "completed") {
        return false;
      }
      if (shift?.status === "payment_approved") {
        return false;
      }
      if (shift?.status === "paid") {
        return false;
      }
    }

    return true;
  }, [shift]);

  return (
    <>
      <div className="shift_popup">
        <div className="popup_header d-flex align-items-center justify-content-between px-2 border-bottom">
          <span className="badge bg-success text-capitalize">
            {showLabel(shift?.roleType?.label)}
          </span>
          <div className="popup_buttons">
            {showEditButton ? (
              <>
                <button
                  className="btn"
                  onClick={handleEditClick}
                >
                  <img
                    src={EditIcon}
                    alt="edit"
                  />
                </button>
              </>
            ) : null}

            {showdeleteButton ? (
              <button
                className="btn"
                onClick={handleDeleteOpen}
              >
                <img
                  src={DeleteIcon}
                  alt="delete"
                />
              </button>
            ) : null}

            <button
              className="btn"
              onClick={onClose}
            >
              <img src={CrossIcon} alt="cross" />
            </button>
          </div>
        </div>
        <div className="popup_body">
          <div className="body_top">
            {!!shift?.startDate ? (
              <div className="date_view">
                <span>
                  {moment(
                    shift?.startDate
                  ).format("MMM")}
                </span>
                <span>
                  {moment(
                    shift?.startDate
                  ).format("DD")}
                </span>
              </div>
            ) : null}

            <div className="branch_name">
              <h5>{shift?.branchTitle}</h5>
              {!!shift?.staff && (
                <span className="badge bg-info">
                  <Avatar
                    alt={
                      shift?.roleType?.role ===
                      "locum"
                        ? shift?.staff?.surname
                        : shift?.staff?.name
                    }
                    src={shift?.staff?.image}
                    className="me-2"
                  >
                    {shift?.roleType?.role ===
                    "locum"
                      ? shift?.staff[0]?.name[0]
                      : shift?.staff?.name}
                  </Avatar>
                  {shift?.staff?.name ||
                    shift?.staff[0]?.name}{" "}
                  {shift?.staff?.surname ||
                    shift?.staff[0]?.surname}
                </span>
              )}
            </div>
            {(!!shift?.isCreatedFromTemplate ||
              Boolean(templateView)) && (
              <div className="pattern ">
                <img
                  src={DashboardIcon}
                  alt=""
                  className="me-2"
                />
                <p>Templates</p>
              </div>
            )}
          </div>
          <ul className="shift_timing_wrapper">
            <li>
              <div className="shift_times">
                {/* <p className="shift_no">Shift: 1</p> */}
                <div className="d-flex align-items-center duration">
                  <p className="mb-0">
                    {shift?.startTime}
                    {/* <span>AM</span> */}
                  </p>
                  <img src={RightArrow} alt="" />
                  <p className="mb-0">
                    {shift?.endTime}
                    {/* <span>PM</span> */}
                  </p>
                </div>
                {!!shift?.lunchStartTime && (
                  <div className="break_time">
                    <span className="badge rounded-pill bg-light text-dark">
                      Break :{" "}
                      <b>
                        {parseInt(
                          moment
                            .duration(
                              moment(
                                shift.lunchEndTime,
                                "HH:mm"
                              ).diff(
                                moment(
                                  shift.lunchStartTime,
                                  "HH:mm"
                                )
                              )
                            )
                            .asHours()
                        )}
                        h
                      </b>
                    </span>
                  </div>
                )}
              </div>
            </li>
            {/* <li>
              <div className="shift_times">
                <p className="shift_no">Shift: 2</p>
                <div className="d-flex align-items-center duration">
                  <p className="mb-0">
                    03:00 <span>PM</span>
                  </p>
                  <img src={RightArrow} alt="" />
                  <p className="mb-0">
                    07:00 <span>PM</span>
                  </p>
                </div>
              </div>
            </li> */}
          </ul>
        </div>
      </div>

      <ConfirmModal
        open={openConfirm}
        title="Are you sure to delete?"
        confirmButtonText="Delete"
        cancelButtonText="Close"
        onClose={() => setOpenConfrim(false)}
        onConfirm={() => handleDelete()}
      />
    </>
  );
};

export default ShiftDetailsView;
