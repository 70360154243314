import React from "react";
import Empty from "../components/EmptyStates/Empty/Empty";
import EmptyLocumBranch from "../components/EmptyStates/EmptyLocumBank/EmptylocumBank";
import Nobranch from "../components/EmptyStates/NoBranch/Nobranch";
import Nobranch404 from "../components/EmptyStates/NoBranch404/Nobranch404";
import NobranchFolder from "../components/EmptyStates/NoBranchFolder/NoBranchFolder";
import NoLocumJob from "../components/EmptyStates/NoLocumJob/NoLocumJob";
import NoPendingJob from "../components/EmptyStates/NoPendingJob/NoPendingJob";
import NoPharmacyGaps from "../components/EmptyStates/NoPharmacyGaps/NoPharmacyGaps";
import NoStaff from "../components/EmptyStates/NoStaff/Nostaff";
import NoTimeSheet1 from "../components/EmptyStates/NoTimeSheet1/NoTimesheet1";
import NoTimeSheet2 from "../components/EmptyStates/NoTimeSheet2/NoTimesheet2";
import NoTotalStaff from "../components/EmptyStates/NoTotalStaff/NoTotalStaff";
import NoExpence from "../components/EmptyStates/NoExpence/NoExpence";

const Nostates = () => {
  return (
    <div>
      <Nobranch />
      <Nobranch404 />
      <NobranchFolder />
      <EmptyLocumBranch />
      <NoStaff />
      <NoTimeSheet1 />
      <NoTimeSheet2 />
      <NoLocumJob />
      <Empty />
      <NoPharmacyGaps />
      <NoPendingJob />
      <NoTotalStaff />
      <NoExpence/>
    </div>
  );
};

export default Nostates;
