import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CustomTabs } from "../../../../../components/UI/tabs";
import DocumentTab from "../../../../../components/documents/DocumentTab";
import { getProfileDataThunk } from "../../../../../redux/profile/profileThunk";
import { getRightToWorkListForStaff } from "../../../../../redux/staffs/staffsAPI";
import { getStaffAccrediationsThunk } from "../../../../../redux/staffs/staffsThunk";
import {
  allowedAccreditations,
  enhanceAccreditations,
  findAnswer,
  prepareRightToWorkData,
} from "../../../../../utils/document";
import "./style.scss";

const AdminStaffDocument = () => {
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const dispatch = useDispatch();
  const { staffAccrediations, staffInfo } = useSelector(
    (state) => state.staffs
  );
  const { staffId } = useParams();

  useEffect(() => {
    dispatch(getProfileDataThunk());
    dispatch(getStaffAccrediationsThunk({ staffId }));
  }, [dispatch, staffId]);

  const { data } = useQuery({
    queryKey: ["getRightToWorkList"],
    queryFn: () => getRightToWorkListForStaff(staffId),
    enabled: !!staffId,
  });

  const rightToWorkDataSetForStaff = useMemo(() => {
    const temp = prepareRightToWorkData(data?.data);

    const _t = temp.filter((item) => {
      for (const condition of allowedAccreditations) {
        if (
          (condition.roles && condition.roles.includes(staffInfo?.roleId)) ||
          (condition.labels &&
            condition.labels.includes(item?.accreditationLabel))
        ) {
          return true;
        }
      }
      return false;
    });

    return _t;
  }, [data?.data, staffInfo]);

  const allAccreditations = useMemo(() => {
    if (!!staffAccrediations) {
      const systemAccreditation = enhanceAccreditations(
        staffAccrediations?.systemAccreditation,
        {
          isExpiryRequired: 0,
          document_type: "document",
          isMandatory: 1,
          type: "systemAccreditation",
        }
      );

      const accreditationRules = enhanceAccreditations(
        staffAccrediations?.pharmacyAccreditation?.accreditationRules,
        {
          accreditationName: (accr) => accr?.title,
          accreditationLabel: (accr) => accr?.title,
          date_key: (accr) => accr?.title,
          filePath: (accr) => {
            const ans = findAnswer(
              staffAccrediations?.pharmacyAccreditation?.staffAccreditations,
              accr?.id,
              "answer"
            );

            return accr?.isDocument ? ans : null;
          },
          answer: (accr) =>
            findAnswer(
              staffAccrediations?.pharmacyAccreditation?.staffAccreditations,
              accr?.id,
              "answer"
            ),
          expiryDate: (accr) =>
            findAnswer(
              staffAccrediations?.pharmacyAccreditation?.staffAccreditations,
              accr?.id,
              "expiryDate"
            ),
          accreditationRuleId: (accr) => accr?.id,
          type: "pharmacyAccreditation",
          disabled: true,
          document_type: (accr) => (accr?.isDocument ? "document" : "answer"),
        }
      );

      let _temp = [
        ...(accreditationRules || []),
        ...(systemAccreditation || []),
      ];

      return _temp;
    }
    return [];
  }, [JSON.stringify(staffAccrediations), staffAccrediations]);

  const tabs = useMemo(() => {
    return [
      {
        title: "Right To Work",
        content: (
          <DocumentTab
            tab="rightToWork"
            staffId={staffId}
            fileList={rightToWorkDataSetForStaff}
          />
        ),
      },
      {
        title: "Accreditations",
        content: (
          <DocumentTab
            tab="accrediations"
            staffId={staffId}
            fileList={allAccreditations}
          />
        ),
      },
    ];
  }, [
    staffId,
    JSON.stringify(allAccreditations),
    JSON.stringify(rightToWorkDataSetForStaff),
  ]);

  return (
    <div className="staff_document_tab">
      <CustomTabs
        tabs={tabs}
        currentActiveTab={currentActiveTab}
        setCurrentActiveTab={setCurrentActiveTab}
      />
    </div>
  );
};

export default AdminStaffDocument;
