import { DatePicker as DP } from "antd";
import dayjs from "dayjs";
import React from "react";
import isoWeek from "dayjs/plugin/isoWeek";

import "./style.scss";
const { RangePicker } = DP

dayjs.extend(isoWeek);

export default function DatePicker({
  onDateChange,
  view,
  dateRange,
  allViews
}) {
  const weekFormat = 'DD MMMM';
  const customWeekFormat = (value) =>
    `${dayjs(value).startOf('isoWeek').format(weekFormat)} - ${dayjs(value)
      .endOf('isoWeek')
      .format(weekFormat)}, ${dayjs(value).startOf('isoWeek').format('YYYY')}`;

  const customDayFormat = (value) => {
    return `${dayjs(value).format('DD MMMM, YYYY')}`
  }

  const onChange = (date) => {
    onDateChange(
      view === 'weekly' ? date.startOf("isoWeek").format("YYYY-MM-DD") : view === 'daily' ? date.format("YYYY-MM-DD") : date.startOf("month").format("YYYY-MM-DD"),
      view === 'weekly' ? date.endOf("isoWeek").format("YYYY-MM-DD") : view === 'daily' ? date.format("YYYY-MM-DD") : date.endOf("month").format("YYYY-MM-DD")
    )
  }

  const rangePresets = [
    {
      label: '2 Weeks',
      value: [dayjs(dateRange?.startDateRange), dayjs(dateRange?.startDateRange).add(2, 'weeks').subtract(1, "days")],
    },
    {
      label: '4 Weeks',
      value: [dayjs(dateRange?.startDateRange), dayjs(dateRange?.startDateRange).add(4, 'weeks').subtract(1, "days")],
    },
    {
      label: '6 Weeks',
      value: [dayjs(dateRange?.startDateRange), dayjs(dateRange?.startDateRange).add(6, 'weeks').subtract(1, "days")],
    },
    {
      label: '8 Weeks',
      value: [dayjs(dateRange?.startDateRange), dayjs(dateRange?.startDateRange).add(8, 'weeks').subtract(1, "days")],
    },
  ];

  return (
    <div className={allViews && view === 'weekly' ? '' : 'date-picker-container'}>
      {
        allViews && view === 'weekly' ?
          <RangePicker
            allowClear={false}
            inputReadOnly
            value={[dayjs(dateRange?.startDateRange), dayjs(dateRange?.endDateRange)]}
            className="text-input-field  px-3 t1"
            style={{ width: 300, minWidth: 300, maxWidth: 300 }}
            format="DD MMM, YYYY"
            presets={rangePresets}
            onChange={value => {
              onDateChange(
                value[0].format('YYYY-MM-DD'),
                value[1].format('YYYY-MM-DD')
              )
            }}
          />
          :
          <DP
            inputReadOnly
            style={{ maxWidth: 300 }}
            allowClear={false}
            className="text-input-field p-0 t1"
            bordered={false}
            value={dayjs(dateRange?.startDateRange)}
            onChange={onChange}
            format={view === 'weekly' ? customWeekFormat : view === 'daily' ? customDayFormat : 'MMMM, YYYY'}
            picker={view === 'weekly' ? 'week' : view === 'daily' ? 'date' : 'month'}
            suffixIcon={<></>}
            showToday={false}
          />
      }
    </div>
  );
}
