import { Button, Progress, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authUserRequestThunk } from "../../../redux/auth/authThunk";
import {
  editVerificationThunk,
  getStaffVerificationThunk,
} from "../../../redux/staffs/staffsThunk";
import { notify } from "../../../utils/helper";
import GreenRightArrow from "../../UI/icons/GreenRightArrow";
import RocketIcon from "../../UI/icons/RocketIcon";
import CompleteProfile from "../complete-profile-form/CompleteProfile";
import "../style.scss";

const twoColors = {
  "30%": "#E83F1A",
  "100%": "#27E050",
  "40%": "#DCE027",
};
const CreateProfileCard = ({ isGrouped }) => {
  const formId = useMemo(() => "form", []);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { staffVerification } = useSelector((state) => state.staffs);

  const [showEditInfoModal, setShowEditInfoModal] = useState(false);
  const [actionType, setActionType] = useState("basic");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // const [showEditInfoModal, setShowEditInfoModal] = useState(false);
  // const [actionType, setActionType] = useState("basic");

  const [openModal, setopenModal] = useState(false);
  const handleOpenModal = () => {
    setopenModal(!openModal);
  };

  useEffect(() => {
    dispatch(getStaffVerificationThunk({ staffId: user.id }));
  }, [dispatch, user]);

  useEffect(() => {
    if (!searchParams.get("verify")) return;
    if (searchParams.get("verify") === "success") {
      notify(
        "info",
        "Our security checks might take a while. We'd hate to keep you waiting, you can check back later!"
      );
      // dispatch(
      //     yotiVerificationEndThunk()
      // ).then(res => {
      //     if (res.payload)
      // })
      navigate("/verifications");
      return;
    }
    notify(
      "error",
      "Seems like there was an issue with you verification - please contact the Lopic administrator!"
    );
    navigate("/verifications");
  }, [dispatch, navigate, searchParams]);

  useEffect(() => {
    dispatch(getStaffVerificationThunk({ staffId: user.id }));
  }, [dispatch, user]);

  const onSubmit = (formData) => {
    dispatch(
      editVerificationThunk({
        staffId: user.id,
        payload: formData,
      })
    ).then((res) => {
      if (res.payload) {
        // if (!user.isVerified) {
        //   setActionType("yoti");
        //   return;
        // }
        // setShowEditInfoModal(false);
        notify("success", "Verification updated successfully.");
        handleOpenModal();
        setActionType("basic");
        dispatch(authUserRequestThunk());

        window.analytics.track("Verification Uploaded", {
          profilePicture: res.payload.image ? true : false,
          proofOfID:
            res.payload.proofOfId && res.payload.proofOfIdExp ? true : false,
          indemnity:
            res.payload.professionalInsurance &&
            res.payload.professionalInsuranceExpDate
              ? true
              : false,
          enhancedDBS: res.payload.DBSCertificate ? true : false,
          workVise: res.payload.visa && res.payload.visaExpDate ? true : false,
        });
      }
    });
  };

  // const onVerifyIdentity = () => {
  //   dispatch(yotiVerificationStartThunk()).then((res) => {
  //     if (res.payload) {
  //       window.open(res.payload, "_self");
  //     }
  //   });
  // };

  const calculateProgress = (data) => {
    // Get the total number of fields
    const totalFields = Object.keys(data).length;
    let nullFieldsCount = 0;
    // Count the number of null or undefined fields
    Boolean(totalFields) &&
      Object.keys(data).forEach((key) => {
        if (
          key === "pharmacyName" ||
          key === "branchId" ||
          key === "NVQLevelTwo" ||
          key === "rightToWork"
        )
          return;

        //check professionalInsurance for locum only
        if (!user?.role === "locum" && key === "professionalInsurance") return;

        if (
          user?.roleId !== 4 &&
          user?.roleId !== 6 &&
          key === "DBSCertificate"
        )
          return;

        if (user?.roleId !== 8 && key === "NVQLevelTwo") {
          return;
        }

        if (
          data[key] === null ||
          data[key] === undefined ||
          data[key]?.length === 0
        ) {
          nullFieldsCount++;
        }
      });

    // Calculate the progress percentage
    const progressPercentage =
      ((totalFields - nullFieldsCount) / totalFields) * 100;

    return progressPercentage;
  };

  // Use the calculateProgress function with the staffVerification data
  const progressCount = useMemo(() => {
    return !!staffVerification ? calculateProgress(staffVerification) : 0;
  }, [staffVerification]);

  return (
    <>
      {progressCount > 99 ? null : (
        <div className="create_profile_card">
          <div className="card_header">
            <i>
              <RocketIcon />
            </i>
            <p> Complete Profile</p>
          </div>
          <Typography.Text className="card_body_text">
            Increase your chances of getting hired by 80% by completing your
            profile.
          </Typography.Text>

          <div className="progress_wrapper">
            <Progress
              percent={progressCount}
              strokeColor={twoColors}
              showInfo={false}
            />
          </div>

          <Button className="complete_profile" onClick={handleOpenModal}>
            Complete Profile Now{" "}
            <i>
              <GreenRightArrow />
            </i>
          </Button>
        </div>
      )}

      <CompleteProfile
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        setActionType={setActionType}
        staffVerification={staffVerification}
        formId={formId}
        // key={staffVerification?.id}
        isGrouped={isGrouped}
        staffInfo={user}
        onSubmit={onSubmit}
        setShowEditInfoModal={setShowEditInfoModal}
        actionType={actionType}
      />
    </>
  );
};

export default CreateProfileCard;
