
export const TableTabsOptions = ({ tabsOptions, staffTableView }) => {
    return (
        <div className="d-flex align-items-center border-bottom-0 border rounded-top " style={{ height: '70%', alignSelf: 'end' }}>
            {
                tabsOptions?.map((data, index) => (
                    <div key={index} className={`px-3 d-flex cursor-pointer align-items-center ${staffTableView === data.value ? 'table-tab-active' : 'table-tab'}`} style={{ height: '100%', width: 160 }} onClick={data.onClickHandler}>
                        <div className="m-auto">
                            {data.label}
                        </div>
                    </div>
                ))
            }
        </div>
    )
}