import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Header } from "../../components/UI/header";
import { RouterConfig } from "../../routerConfig";
import { PharmacyPage } from "./pharmacies";
import { UsersPage } from "./users";
import { ProfilePage } from "./profile";
import { JobOffersPage } from "./jobOffers";
import { BillingPage } from "./billing";

export const SuperAdminView = () => {
  const tabs = [
    { id: 1, title: "Pharmacies", to: RouterConfig.superAdminPharmacies },
    { id: 2, title: "Users", to: RouterConfig.superAdminUsers },
    { id: 3, title: "Job Offers", to: RouterConfig.superAdminJobOffers },
    { id: 4, title: "Billing", to: RouterConfig.superAdminBilling },
  ];

  return (
    <div>
      <Header tabs={tabs} showProfile superAdmin />
      <Routes>
        <Route
          path={`${RouterConfig.superAdminPharmacies}/*`}
          element={<PharmacyPage />}
        />
        <Route
          path={`${RouterConfig.superAdminUsers}/*`}
          element={<UsersPage />}
        />
        <Route
          path={`${RouterConfig.superAdminJobOffers}/*`}
          element={<JobOffersPage />}
        />
        <Route
          path={`${RouterConfig.superAdminBilling}/*`}
          element={<BillingPage />}
        />

        <Route
          path={`${RouterConfig.superAdminInfo}/*`}
          element={<ProfilePage />}
          exact
        />
        <Route
          path="*"
          element={<Navigate to={RouterConfig.superAdminPharmacies} />}
        />
      </Routes>
    </div>
  );
};
