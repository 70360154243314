import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Heading } from "../../../../components/UI/heading";
import { TableComponet } from "../../../../components/UI/table";
import { TableToolbar } from "../../../../components/UI/table/tableToolBar";
import { notify } from "../../../../utils/helper";
import * as moment from "moment";
import { getBillingThunk, updateZohoReportEmailThunk } from "../../../../redux/super-admin/superAdminThunk";
import { EmptyData } from "../../../../components/comon/EmptyData";
import { Button, Form, Input } from "antd";

export const BillingListPage = (props) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const formId = useMemo(() => "form", []);

  const { billingList } = useSelector((state) => state.superAdmin);
  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);

  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ row: { original } }) => {
          return <div>{original.branch.user.email}</div>;
        },
      },
      {
        Header: "Branch",
        accessor: "title",
        Cell: ({ row: { original } }) => {
          return <div>{original.branch.title}</div>;
        },
      },
      {
        Header: "Numark Number",
        accessor: "regionName",
        Cell: ({ row: { original } }) => {
          return <div>{original.branch.numark}</div>;
        },
      },
      {
        Header: "Pharmacy",
        accessor: "pharmacy2",
        Cell: ({ row: { original } }) => {
          return <div>{original.branch.user.pharmacyName}</div>;
        },
      },
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: ({ row: { original } }) => {
          return <div>{moment(original.createdAt).format("DD-MM-YYYY")}</div>;
        },
      },
      {
        Header: "Invoice Number",
        accessor: "inoice number",
        Cell: ({ row: { original } }) => {
          return <div>{original.invoice.invoice_number}</div>;
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "Status",
        accessor: "active",
        Cell: ({ row: { original } }) => {
          return (
            <>
              {original.branch.user.status === "isArchived" ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#FEFDE9", color: "#EE872D" }}
                  >
                    {original.branch.user.status.charAt(0).toUpperCase() +
                      original.branch.user.status.slice(
                        1,
                        original.branch.user.status.length
                      )}{" "}
                  </span>
                </div>
              ) : original.branch.user.status === "active" ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#E8F5E9", color: "#1B5E1F" }}
                  >
                    {original.branch.user.status.charAt(0).toUpperCase() +
                      original.branch.user.status.slice(
                        1,
                        original.branch.user.status.length
                      )}{" "}
                  </span>
                </div>
              ) : original.branch.user.status === "unfilled" ||
                original.branch.user.status === "pending" ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#FEFDE9", color: "#EE872D" }}
                  >
                    {" "}
                    {original.branch.user.status.charAt(0).toUpperCase() +
                      original.branch.user.status.slice(
                        1,
                        original.branch.user.status.length
                      )}{" "}
                  </span>
                </div>
              ) : original.branch.user.status === "completed" ? (
                <span
                  className="px-4 rounded-pill py-2"
                  style={{ backgroundColor: "#E3F2FD", color: "#1045A1" }}
                >
                  {original.branch.user.status.charAt(0).toUpperCase() +
                    original.branch.user.status.slice(
                      1,
                      original.branch.user.status.length
                    )}{" "}
                </span>
              ) : (
                <span
                  className="px-4 rounded-pill py-2"
                  style={{ backgroundColor: "#FFEBEE", color: "#EF5350" }}
                >
                  {original.branch.user.status.charAt(0).toUpperCase() +
                    original.branch.user.status.slice(
                      1,
                      original.branch.user.status.length
                    )}{" "}
                </span>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  const {
    count: totalDataCount,
    page,
    pages: totalPagesCount,
    perPage,
    rows: billingListData,
  } = useMemo(() => billingList, [billingList]);

  useEffect(() => {
    if (!page && !perPage) return;
    setPerPageCount(perPage);
    setCurrentPageCount(page);
  }, [page, perPage]);

  const getBillingList = useCallback(
    async (values) => {
      try {
        dispatch(
          getBillingThunk({
            // filters: {
            //   email: searchQuery.trim(),
            //   ...values,
            // },
            pagination: { page: currentPageCount, perPage: perPageCount },
          })
        );
      } catch (error) {
        notify("error", error.message);
      }
    },
    [currentPageCount, dispatch, perPageCount]
  );

  useEffect(() => {
    getBillingList();
  }, [currentPageCount, perPageCount, getBillingList]);

  const updateZohoReportEmailAddress = ({ zohoReportEmail }) => {
    dispatch(
      updateZohoReportEmailThunk({
        zohoReportEmail
      })
    ).then(res => {
      if (res.payload) {
        form.resetFields()
        notify('success', "Email Updated!")
      }
    })
  }

  return (
    <>
      <div>
        <Heading label="Billing" />
        <div className="">
          <TableToolbar
            ExtraComponent={
              <div className="d-flex align-items-center gap-3">
                {/* <div className="p3" style={{ whiteSpace: 'nowrap' }}>Set up email for the reporting:</div>
                <Input
                  className='text-input-field px-3 t1'
                  placeholder='Email Address'
                  type="email"
                  onChange={e => setUpdatedEmail(e.target.value)}
                  maxLength={50}
                />
                <Button onClick={updateZohoReportEmailAddress} className="btn-style" type="primary" htmlType="submit">Submit</Button> */}
                <Form
                  id={formId}
                  onFinish={updateZohoReportEmailAddress}
                  form={form}
                  layout="inline"
                  autoComplete="off"
                >
                  <Form.Item
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: 'Please Enter your email!'
                      }
                    ]}
                    label="Set up email for the reporting:"
                    name="zohoReportEmail"
                  >
                    <Input
                      className='text-input-field px-3 t1'
                      placeholder='Email Address'
                      type="email"
                      maxLength={50}
                    />
                  </Form.Item>
                  <Button className="btn-style" type="primary" htmlType="submit">Submit</Button>
                </Form>
              </div>
            }
          />
          <div style={{ padding: "5px 24px" }}>
            {billingList?.rows?.length > 0 ? (
              <TableComponet
                rowCursor="pointer"
                isBorderShown
                currentPageCount={currentPageCount}
                perPageCount={perPageCount}
                totalDataCount={totalDataCount}
                setCurrentPage={setCurrentPageCount}
                setPerPageCount={setPerPageCount}
                showPagination
                maxPageCount={totalPagesCount}
                columns={columns}
                data={billingListData ?? []}
              />
            ) : (
              <div style={{ width: "100%", paddingTop: "80px" }}>
                <EmptyData title="Billings" subtitle="Billing" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
