import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { Heading } from "../../../../components/UI/heading";
import { TableToolbar } from "../../../../components/UI/table/tableToolBar";
import { StaffAttendancesTable } from "../../../../components/staffs/attendacesTable";
import { CreatStaff } from "../../../../components/staffs/createStaff";
import { StaffFilterComponent } from "../../../../components/staffs/filter";
import { StaffGapsTable } from "../../../../components/staffs/gapTable";
import { StaffListTable } from "../../../../components/staffs/staffTable";
import { getRegionsBranchesListThunk } from "../../../../redux/regions/regionsThunk";
import { getRolesThunk } from "../../../../redux/schedule/scheduleThunk";
import { staffManualAttendanceActionThunk } from "../../../../redux/staffs/staffsThunk";
import {
  inviteApiKeys,
  personalApiKeys,
} from "../../../../utils/apiKeyConstants";
import { hasAccess } from "../../../../utils/helper";
import "./style.scss";
import useOpenAddModals from "../../../../hooks/useOpenAddModals";

export const StaffListPage = () => {
  const [staffTableView, setStaffTableView] = useState("staff");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const dispatch = useDispatch();
  const [rerender, setRerender] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const [actionType, setActionType] = useState("");
  const [showMultiSelectBtn, setShowMultiSelectBtn] = useState(false);
  const [filterValue, setFilterValue] = useState();

  const tabsOptions = useMemo(
    () => [
      {
        label: "Staff Members",
        value: "staff",
        onClickHandler: () => setStaffTableView("staff"),
      },
      {
        label: "Gap Applications",
        value: "gap",
        onClickHandler: () => setStaffTableView("gap"),
      },
      {
        label: "Attendance",
        value: "attendances",
        onClickHandler: () => setStaffTableView("attendances"),
      },
    ],
    []
  );

  useEffect(() => {
    if (!actionType) return;

    if (hasAccess(personalApiKeys.MANUAL_ATTENDANCE_APPROVE))
      dispatch(
        staffManualAttendanceActionThunk({
          recordIds: selectedRows,
          action: actionType,
        })
      ).then((res) => {
        setSelectedRows([]);
        setRerender(true);
        setActionType("");
      });
  }, [selectedRows, actionType, dispatch]);

  useOpenAddModals("addstaff",`/staff`,()=>{
    setShowCreateModal(!showCreateModal)
  })

  useEffect(() => {
    dispatch(
      getRolesThunk({
        group: ["staff"],
        type: "Default",
      })
    );

    if (hasAccess(inviteApiKeys.GET_SHORT_BRANCHES))
      dispatch(getRegionsBranchesListThunk());
  }, [dispatch]);

  const onSearch = (e) => {
    setRerender(true);
  };

  return (
    <div>
      <Heading
        label={
          staffTableView === "staff"
            ? "Staff"
            : staffTableView === "gap"
            ? "Gap Applications"
            : "Attendance"
        }
      />
      <div className="mt-3">
        <TableToolbar
          searchPlaceholder={staffTableView !== "gap" && "Name"}
          primaryBtnText={staffTableView === "staff" && "Add Staff"}
          showSearchField={staffTableView === "gap" ? false : true}
          staffTableView={staffTableView}
          tabsOptions={tabsOptions}
          onSearch={onSearch}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          primaryBtnHandler={() => setShowCreateModal(!showCreateModal)}
          acceptButton={showMultiSelectBtn}
          filterButton={staffTableView === "staff"}
          FilterComponent={
            <StaffFilterComponent
              onApplyFilter={(values) => {
                setFilterValue(values);
                setRerender(true);
              }}
            />
          }
          rejectButton={showMultiSelectBtn}
          setActionType={setActionType}
        />
        {staffTableView === "staff" && (
          <StaffListTable
            rerender={rerender}
            filterValue={filterValue}
            setRerender={setRerender}
            searchQuery={searchQuery}
          />
        )}
        {staffTableView === "gap" && <StaffGapsTable />}
        {staffTableView === "attendances" && (
          <StaffAttendancesTable
            rerender={rerender}
            setRerender={setRerender}
            searchQuery={searchQuery}
            setSelectedRows={setSelectedRows}
            setAttendanceAtcion={setActionType}
            setShowMultiSelectBtn={setShowMultiSelectBtn}
          />
        )}

        {showCreateModal && (
          <CreatStaff
            showCreateModal={showCreateModal}
            handleModalToggle={() => setShowCreateModal(!showCreateModal)}
          />
        )}
      </div>
      <Outlet />
    </div>
  );
};
