import { Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import NoBranchImg from "../../../assets/img/emptyFolder.png";
import { generateRandomNumber } from "../../../utils/helper";

const NobranchFolder = () => {
  const navigate = useNavigate();

  return (
    <div className=" d-flex align-items-center justify-content-center flex-column px-2 py-4">
      <img src={NoBranchImg} alt="nobranch" className="mb-2" />
      <Typography.Title level={3} className="mb-2">
        No Branches{" "}
      </Typography.Title>
      <ps
        className="text-center mb-4"
        style={{
          maxWidth: "448px",
          color: "#7B7B7B",
        }}
      >
        We cant find any of your branches, head over to the branches section to
        add your first branch!
      </ps>

      <button
        onClick={() =>
          navigate(`/branches?addbranch=${generateRandomNumber()}`, {
            replace: true,
          })
        }
        className="btn custom_btn no-branch-btn"
      >
        Back to Home
      </button>
    </div>
  );
};

export default NobranchFolder;
