import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./style.scss";
import { Modal } from "antd";
import { InformationTab } from "./components/information";
import { Accrediations } from "./components/accrediations";
import { activateUser, approveUser, deactivateUser, rejectUser } from "../../../../../redux/super-admin/superAdminAPI";
import { CustomTabs } from "../../../../../components/UI/tabs";

const getTabsData = () => {
  const tabs = [
    {
      title: "Personal Information",
      content: <InformationTab />,
    },
    {
      title: "Accreditations",
      content: <Accrediations />,
    },
  ];
  return tabs;
};

export const UserInformation = () => {
  const { id } = useParams();
  const [status, setStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [apiResponseStatus, setApiResponseStatus] = useState("");
  const { userData } = useSelector((state) => state.superAdmin);
  const [currentActiveTab, setCurrentActiveTab] = useState(0);

  useEffect(() => {
    const _tabs = getTabsData();
    setTabs(_tabs);
    setApiResponseStatus(userData?.personal?.status);
  }, [userData?.personal?.status]);

  const changeStatus = async () => {
    if (status === "approve") {
      const res = await approveUser(id);
      if (res.data.status === "success" && userData.personal.role === 'locum') {
        window.analytics.track('Locum Approved', {
          gphcNumber: userData.personal.gphcNumber,
          id: userData.personal.id,
          name: userData.personal.name,
          surname: userData.personal.surname,
          email: userData.personal.email,
          role: userData.personal.label,
          userType: userData.personal.role,
          phone: userData.personal.phone,
        });
      }
      setApiResponseStatus(
        res.data.status === "success" ? "active" : "failedapprove"
      );
    } else if (status === "reject") {
      const res = await rejectUser(id);
      if (res.data.status === "success" && userData.personal.role === 'locum') {
        window.analytics.track('Locum Rejected', {
          gphcNumber: userData.personal.gphcNumber,
          id: userData.personal.id,
          name: userData.personal.name,
          surname: userData.personal.surname,
          email: userData.personal.email,
          role: userData.personal.label,
          userType: userData.personal.role,
          phone: userData.personal.phone,
        });
      }
      setApiResponseStatus(
        res.data.status === "success" ? "reject" : "failedreject"
      );
    } else if (status === "deactivate") {
      const res = await deactivateUser(id);
      setApiResponseStatus(
        res.data.status === "success" ? "deactivated" : "faileddeactivate"
      );
    } else if (status === "activate") {
      const res = await activateUser(id);
      setApiResponseStatus(
        res.data.status === "success" ? "active" : "failedactivate"
      );
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    changeStatus();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="branch-info-page  rounded-4">
      <CustomTabs
        currentActiveTab={currentActiveTab}
        setCurrentActiveTab={setCurrentActiveTab}
        tabs={tabs}
        onActivateDeactivate
        staffInfoStatus={apiResponseStatus}
        onApproveReject={userData?.personal?.status}
        showModal={showModal}
        setStatus={setStatus}
      />

      <Modal
        title={`${status === "approve"
          ? "Approve"
          : status === "reject"
            ? "Reject"
            : status === "deactivate"
              ? "Deactivate"
              : status === "activate"
                ? "Activate"
                : undefined
          }`}
        bodyStyle={{ height: 70 }}
        centered={true}
        width={350}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure you want to {`${status}`}</p>
      </Modal>
    </div>
  );
};
