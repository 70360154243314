import React, { useState } from "react";
import { CategoryDetail } from "./CategoryDetail";
import arrowRight from '../../../../../assets/icons/right-arrow.png'
import arrowDown from '../../../../../assets/icons/arrowDown.png'
import { rolesColor } from "../../../SchedularToolbar";

export const SlotCategory = ({
  data,
  id,
  handleAddShift,
  operatingTime,
  isTemplate,
  setShow
}) => {
  const [isDetail, setDetail] = useState(false);

  return (
    <div className="d-flex flex-column justify-content-between align-items-start slotCategory">
      <div
        className="d-flex justify-content-between align-items-center slot-category-option"
        onClick={(e) => {
          setDetail(!isDetail);
        }}
      >
        <div className="d-flex align-items-center" >
          <div
            style={{
              width: 8,
              height: 8,
              borderRadius: "50%",
              marginRight: 8,
              background: rolesColor[data?.shifts.length ? data?.shifts[0].roleType.id : 4],
            }}
          />
          <div className="d-flex align-items-center gap-2 ">
            <span className="p2" style={{ maxWidth: "180px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: 'ellipsis' }}>
              {data.label}
            </span>
            <div className="t2 rounded px-2  rounded" style={{ backgroundColor: '#E1F4F4', color: '#09988D' }}>
              {data.shifts.length}
            </div>
          </div>
        </div>
        <div>
          <img
            src={isDetail ? arrowDown : arrowRight}
            alt=""
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
      {isDetail &&
        <CategoryDetail
          data={data.shifts}
          id={id}
          isTemplate={isTemplate}
          setShow={setShow}
          operatingTime={operatingTime}
          handleAddShift={handleAddShift}
        />
      }
    </div>
  );
}
