import { Route, Routes } from "react-router-dom";
import { BranchDetails } from "./details";
import { Subscription } from "./details/billing";
import { PharmacyBranchesListPage } from "./details/branches";
import { BranchInformation } from "./details/information";
import { JobsListPage } from "./details/jobs";
import { StaffListPage } from "./details/staff";
import { PharmaciesListPage } from "./pharmaciesList";

export const PharmacyPage = () => {
  return (
    <Routes>
      <Route path={`/`} element={<PharmaciesListPage />} />
      <Route path="/:id/details/*" element={<BranchDetails />}>
        <Route path="information" element={<BranchInformation />} />
        <Route path="branches" element={<PharmacyBranchesListPage />} />
        <Route path="Staff" element={<StaffListPage />} />
        <Route path="Jobs" element={<JobsListPage />} />
        <Route path="billing" element={<Subscription />} />
      </Route>
    </Routes>
  );
};
