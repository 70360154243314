import { Select } from "antd";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import blankData from "../../assets/img/emptyGroup.png";
import { getDuration } from "../../utils/dates";
import { EmptyData } from "../comon/EmptyData";
import { AttendanceTimer } from "./timer";

dayjs.extend(duration);

export const AttendanceModal = memo(({ onClosePopOver }) => {
  const { attedanceJobs } = useSelector((state) => state.locum);
  const { user } = useSelector((state) => state.auth);
  const [selectedAttendance, setSelectedAttendance] = useState(
    attedanceJobs[0]?.id
  );

  const time = useMemo(() => {
    const data = attedanceJobs.find((item) => item.id === selectedAttendance);
    const duration = getDuration(
      dayjs(data?.job ? data?.job?.startTime : data?.shift?.startTime, "HH:mm"),
      dayjs(data?.job ? data?.job?.endTime : data?.shift?.endTime, "HH:mm")
    );
    const remainingTime =
      data?.actualCheckIn && !data?.actualCheckOut
        ? getDuration(dayjs(data?.actualCheckIn, "HH:mm"), dayjs())
        : data?.actualCheckIn && data?.actualCheckOut
        ? getDuration(
            dayjs(data?.actualCheckIn, "HH:mm"),
            dayjs(data?.actualCheckOut, "HH:mm")
          )
        : null;

    return (
      ((isNaN(duration) ? 0 : duration) -
        (isNaN(remainingTime) ? 0 : remainingTime)) *
      60
    );
  }, [attedanceJobs, selectedAttendance]);

  useEffect(() => {
    if (!attedanceJobs.length) return;
    setSelectedAttendance(attedanceJobs[0]?.id);
  }, [attedanceJobs]);

  return (
    <div className="notificationContainer" style={{ width: "348px" }}>
      <div className="w-100 d-flex align-items-center justify-content-between">
        <span className="b1" style={{ color: "#000000" }}>
          Attendance
        </span>
      </div>
      <Select
        dropdownStyle={{ zIndex: 2000 }}
        placeholder={`View All ${user.group === "locum" ? "Jobs" : "Shifts"}`}
        bordered={false}
        value={selectedAttendance}
        onChange={(e) => {
          setSelectedAttendance(e);
        }}
        className="text-input-field p-1 t1"
      >
        {attedanceJobs?.map((data, idx) => (
          <Select.Option key={idx} value={data.id}>
            <div className="d-flex align-items-center gap-2">
              <div
                style={{
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  background: `${
                    data.actualCheckIn && !data.actualCheckOut ? "green" : "red"
                  }`,
                }}
              ></div>
              {data.branch.title}
            </div>
          </Select.Option>
        ))}
      </Select>
      {time >= 0 && selectedAttendance ? (
        <AttendanceTimer
          onClosePopOver={onClosePopOver}
          time={time}
          selectedAttendance={selectedAttendance}
        />
      ) : (
        <EmptyData
          font="bolder"
          img={blankData}
          description={`No  ${
            user.group === "locum" ? "Jobs" : "Shifts"
          } For You Today!`}
        />
      )}
    </div>
  );
});
