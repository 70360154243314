import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EmptyData } from "../../../../../components/comon/EmptyData";
import { FlexRow } from "../../../../../components/comon/flexRows";
import { TableComponet } from "../../../../../components/UI/table";
import "../style.scss";


export const Subscription = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { subscription } = useSelector(
    (state) => state.superAdmin
  );
  const { branchStaffList } = useSelector((state) => state.branches);

  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);

  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
      },

      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "Subscription Type",
        accessor: "type",
      },
      {
        Header: "Status",
        accessor: "active",
        Cell: ({ row: { original } }) => {
          return (
            <>
              {original.status === "active" ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#E8F5E9", color: "#1B5E1F" }}
                  >
                    Active
                  </span>
                </div>
              ) : original.status === "pending" ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#FEFDE9", color: "#EE872D" }}
                  >
                    Pending
                  </span>
                </div>
              ) : (
                <span
                  className="px-4 rounded-pill py-2"
                  style={{ backgroundColor: "#FFEBEE", color: "#EF5350" }}
                >
                  InActive
                </span>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  const {
    count: totalDataCount,
    page,
    pages: totalPagesCount,
    perPage,
    rows: staffList,
  } = useMemo(() => branchStaffList, [branchStaffList]);

  useEffect(() => {
    // dispatch(
    //   getBranchStaffListThunk({
    //     id,
    //     pagination: { page: currentPageCount, perPage: perPageCount },
    //   })
    // );
  }, [dispatch, id, currentPageCount, perPageCount]);

  useEffect(() => {
    if (!page && !perPage) return;
    setPerPageCount(perPage);
    setCurrentPageCount(page);
  }, [page, perPage]);

  return (
    <div
      className="d-flex flex-column flex-1"
      style={{ gap: 30, height: "100%" }}
    >
      <div className="heading4 d-flex align-items-center justify-content-between">
        Billing And Subscription
      </div>

      <div className="border rounded">
        <div className="text-row d-flex align-items-center justify-content-between">
          <div className="s1 m-0"> Subscription Details</div>
        </div>

        <FlexRow
          primaryTitle="Subscription Type"
          primaryDescription={(subscription && subscription?.type) || "-"}
        />
        <FlexRow
          primaryTitle="Price"
          primaryDescription={(subscription && subscription.price) || "-"}
        />
        <FlexRow
          primaryTitle="Billing Period"
          primaryDescription={(subscription && subscription?.period) || "-"}
        />
        <FlexRow
          primaryTitle="Next Payment"
          primaryDescription={
            subscription && subscription?.payment ? subscription?.payment : "-"
          }
        />
      </div>
      <div className="heading4 d-flex align-items-center justify-content-between">
        List of Transactions
      </div>
      {totalDataCount > 0 ? (
        <div>
          <TableComponet
            rowCursor="pointer"
            currentPageCount={currentPageCount}
            perPageCount={perPageCount}
            totalDataCount={totalDataCount}
            setCurrentPage={setCurrentPageCount}
            setPerPageCount={setPerPageCount}
            showPagination
            maxPageCount={totalPagesCount}
            isBorderShown
            columns={columns}
            data={[]}
          />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <EmptyData title="Transactions" subtitle="Transaction" />
        </div>
      )}
    </div>
  );
};
