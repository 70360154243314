import { Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import NoBranchImg from "../../../assets/img/blankJobApproval.png";
const NoPendingJob = () => {
  const navigate = useNavigate();
  return (
    <div className=" d-flex align-items-center justify-content-center flex-column px-2 py-4" style={{height:'calc(100% - 100px)'}}>
      <img src={NoBranchImg} alt="nobranch" className="mb-3" />

      <p
        className="text-center "
        style={{
          color: "#000",
          maxWidth: "249px",
        }}
      >
      No Jobs In Pending Status
      </p>
    </div>
  );
};

export default NoPendingJob;
