import React from "react";

const MedalIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M9 11.25C11.7959 11.25 14.0625 9.06739 14.0625 6.375C14.0625 3.68261 11.7959 1.5 9 1.5C6.20406 1.5 3.9375 3.68261 3.9375 6.375C3.9375 9.06739 6.20406 11.25 9 11.25Z"
        fill="#09988D"
      />
      <path
        d="M11.8425 11.7088C12.09 11.5813 12.375 11.7688 12.375 12.0463V15.6838C12.375 16.3588 11.9025 16.6888 11.3175 16.4113L9.3075 15.4588C9.135 15.3838 8.865 15.3838 8.6925 15.4588L6.6825 16.4113C6.0975 16.6813 5.625 16.3513 5.625 15.6763L5.64 12.0463C5.64 11.7688 5.9325 11.5888 6.1725 11.7088C7.02 12.1363 7.98 12.3763 9 12.3763C10.02 12.3763 10.9875 12.1363 11.8425 11.7088Z"
        fill="#09988D"
      />
    </svg>
  );
};

export default MedalIcon;
