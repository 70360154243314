import { createSlice } from "@reduxjs/toolkit";
import { getAllNotificationThunk, getLocumAttendanceJobsThunk, getLocumAttendanceStatsThunk, getLocumAttendanceThunk, getLocumSettingDataThunk, getPeronalScheduleThunk, getStaffContractThunk, onMarkAttendanceThunk, updateLocumSettingDataThunk } from "../Thunk";

const initialState = {
    settingInfo: {},
    attendanceStats: {},
    attendance: {},
    attedanceJobs: [],
    peronsalSchedule: [],
    staffContract: {},
    notifications: {}
}

const locumFlowSlicer = createSlice({
    name: "locumFlowReducer",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => (
        builder
            .addCase(getLocumSettingDataThunk.fulfilled, (state, action) => {
                state.settingInfo = action.payload
            })
            .addCase(updateLocumSettingDataThunk.fulfilled, (state, action) => {
                state.settingInfo = action.payload
            })
            .addCase(getLocumAttendanceStatsThunk.fulfilled, (state, action) => {
                state.attendanceStats = action.payload
            })
            .addCase(getLocumAttendanceThunk.fulfilled, (state, action) => {
                state.attendance = action.payload
            })
            .addCase(getLocumAttendanceJobsThunk.fulfilled, (state, action) => {
                state.attedanceJobs = action.payload
            })
            .addCase(onMarkAttendanceThunk.fulfilled, (state, action) => {
                const index = state.attedanceJobs.findIndex((list) => list.id === action.payload.id)
                state.attedanceJobs[index].actualCheckIn = action.payload.actualCheckIn
                state.attedanceJobs[index].actualCheckOut = action.payload.actualCheckOut
            })
            .addCase(getPeronalScheduleThunk.fulfilled, (state, action) => {
                state.peronsalSchedule = action.payload
            })
            .addCase(getStaffContractThunk.fulfilled, (state, action) => {
                state.staffContract = action.payload
            })
            .addCase(getAllNotificationThunk.fulfilled, (state, action) => {
                state.notifications = action.payload
            })
    )
})

export const locumFlowReducer = locumFlowSlicer