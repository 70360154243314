import { Form, Select } from "antd";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { FilterComponent } from "../Filter";

export const StaffFilterComponent = ({ onApplyFilter }) => {
  const [form] = Form.useForm();
  const formId = useMemo(() => "filter-form", []);

  const { roles } = useSelector((state) => {
    return state.schedule;
  });
  const { regionBranches } = useSelector((state) => state.regions);

  const clearFilterFormHandler = () => {
    form.resetFields();
    onApplyFilter();
  };

  const onSubmit = (values) => {
    onApplyFilter(values);
  };
  return (
    <FilterComponent
      formId={formId}
      onApplyFilter={form.submit}
      clearFormHandler={form.isFieldsTouched() && clearFilterFormHandler}
    >
      <Form
        id={formId}
        onFinish={onSubmit}
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item label="Role" name="roleIds">
          <Select
            dropdownStyle={{ zIndex: 2000 }}
            placeholder="Role"
            mode="multiple"
            bordered={false}
            className="text-input-field p-1 t1"
          >
            {roles?.map((data, idx) => (
              <Select.Option key={idx} value={data.id}>
                {data.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Status" name="status">
          <Select
            dropdownStyle={{ zIndex: 2000 }}
            placeholder="Status"
            allowClear
            bordered={false}
            className="text-input-field p-1 t1"
          >
            <Select.Option value="active">Active</Select.Option>
            <Select.Option value="pending">Pending</Select.Option>
            <Select.Option value="deactivated">Deactivated</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Assigned Branch" name="branchIds">
          <Select
            dropdownStyle={{ zIndex: 2000 }}
            placeholder="Assigned Branch"
            mode="multiple"
            bordered={false}
            className="text-input-field p-1 t1"
          >
            {regionBranches?.map((data, idx) => (
              <Select.Option key={idx} value={data.id}>
                {data.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </FilterComponent>
  );
};
