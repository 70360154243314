import {
  AutoComplete,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import dayjs from "dayjs";
import moment from "moment/moment";
import ArrowrightIcon from "../../assets/icons/arrow-right.png";
import ArrowLeftIcon from "../../assets/icons/back.png";
import Clipboardicon from "../../assets/icons/clipboard-text.svg";
import Dropwdown from "../../assets/icons/downDrop.svg";
import infoIcon from "../../assets/icons/infoIcon1.png";
import {
  createJobBulkThunk,
  searchLocumByFilterThunk,
} from "../../redux/schedule/scheduleThunk";
import { getDuration } from "../../utils/dates";
import { copyToClipBoard, hasAccess, notify } from "../../utils/helper";
import { ConfirmModal } from "../UI/modals/confirmModal";

import { branchApiKeys } from "../../utils/apiKeyConstants";
import { validateBreakTime } from "../../utils/timeshhet";
import LocumJobStatusChip from "../LocumJobs/LocumJobStatusChip";
import CustomIconButton from "../UI/IconButton/IconButton";
import TimeInput from "../UI/input/TimeInput";
import { AddModal } from "../UI/modals/addModal";
import {
  CopyBranchTimeButton,
  CopyButton,
  CoverExpenseFormLabels,
} from "./AddShiftFormNew";
const { RangePicker, WeekPicker } = DatePicker;

const PERIOD_OPTIONS = [
  { item: "Day", value: "Day" },
  { item: "Week", value: "Week" },
  { item: "Month", value: "Month" },
];

const findObj = (id, dataSet) => {
  if (dataSet?.length) {
    const temp = dataSet.find((item) => item.id === id);
    return temp;
  }

  return null;
};

const CreateOptions = (rules) => {
  if (!rules) return;

  return rules.map((i) => {
    return {
      label: i.name,
      value: i.id,
    };
  });
};

const CalculateStartEndDates = (period, dateRange) => {
  if (period === "Day" && dateRange) {
    return { startDate: dateRange, endDate: dateRange };
  } else if (period === "Week" && dateRange) {
    return {
      startDate: dateRange.clone().startOf("week"),
      endDate: dateRange.clone().endOf("week"),
    };
  } else {
    return {
      startDate: dateRange?.[0],
      endDate: dateRange?.[1],
    };
  }
};

const AddJobFormNew = ({
  showModal,
  setshowModal,
  locumRolesOptions,
  branchOptions,
  gapData,
  editProps,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [errorData, setErrorData] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const { branches } = useSelector((state) => {
    return state.branches;
  });
  const { locums } = useSelector((state) => {
    return state.schedule;
  });
  const {
    mileageRules,
    accommodationAndFoodRules,
    parkingRules,
    transportRules,
  } = useSelector((state) => state.profile);

  const formId = useMemo(() => "jobShiftForm", []);
  const roleId = Form.useWatch("roleId", form);
  const branchId = Form.useWatch("branchId", form);
  const period = Form.useWatch("period", form);
  const timeObj = Form.useWatch("timeObj", form);
  const dateRange = Form.useWatch("dateRange", form);

  const hasCoverExpense = Form.useWatch("hasCoverExpense", form);
  const hasExternalLocum = Form.useWatch("hasExternalLocum", form);
  const mileageRuleId = Form.useWatch(["coverExpenses", "mileageRuleId"], form);
  const accommodationAndFoodRuleId = Form.useWatch(
    ["coverExpenses", "accommodationAndFoodRuleId"],
    form
  );
  const parkingRuleId = Form.useWatch(["coverExpenses", "parkingRuleId"], form);
  const transportRuleId = Form.useWatch(
    ["coverExpenses", "transportRuleId"],
    form
  );
  const externalLocumBooking = Form.useWatch("externalLocumBooking", form);

  useEffect(() => {
    let filters = {};
    if (externalLocumBooking?.gphcNumber) {
      filters["name"] = externalLocumBooking?.gphcNumber;
      filters["gphcNumber"] = externalLocumBooking?.gphcNumber;
      filters["roleId"] = roleId;
    }
    dispatch(searchLocumByFilterThunk({ filters }));
  }, [dispatch, externalLocumBooking?.gphcNumber, roleId]);

  const operatingTime = useMemo(() => {
    if (!branches.rows) return;

    const selectedBranch = branches.rows.find((row) => row.id === branchId);
    const branchOperatingTime = selectedBranch?.operatingTime;

    return branchOperatingTime;
  }, [branches, branchId]);

  const MileageRulesOption = useMemo(
    () => CreateOptions(mileageRules),
    [mileageRules]
  );

  const AccommodationAndFoodRulesOption = useMemo(
    () => CreateOptions(accommodationAndFoodRules),
    [accommodationAndFoodRules]
  );

  const ParkingRulesOption = useMemo(
    () => CreateOptions(parkingRules),
    [parkingRules]
  );

  const TransportRulesOption = useMemo(
    () => CreateOptions(transportRules),
    [transportRules]
  );

  // for getting default values {EDIT MODE}
  const formDefaultValue = useMemo(() => {
    if (!editProps) return;

    const {
      role,
      branchName,
      description,
      startDate,
      endDate,
      mileageRule,
      accommodationAndFoodRule,
      parkingRule,
      transportRule,
      paidLunch,
    } = editProps;

    const requiredLoumOption = locumRolesOptions.find(
      (locumRole) => locumRole.label === role
    );
    const requiredBranchOption = branchOptions.find(
      (branch) => branch.label === branchName
    );
    const requiredMileageRuleOption = MileageRulesOption.find(
      (mileageRuleOption) => mileageRuleOption.label === mileageRule?.name
    );
    const requiredAccommodationRuleOption =
      AccommodationAndFoodRulesOption.find(
        (AccomodationRule) =>
          AccomodationRule.label === accommodationAndFoodRule?.name
      );
    const requiredParkingRuleOption = ParkingRulesOption.find(
      (parkingRuleOption) => parkingRuleOption.label === parkingRule?.name
    );
    const requiredTransportRuleOption = TransportRulesOption.find(
      (TransportRule) => TransportRule.label === transportRule?.name
    );

    return {
      roleId: requiredLoumOption?.value,
      branchId: requiredBranchOption?.value,
      description: description ?? "",
      paidLunch: paidLunch,
      period: "Day",
      dateRange: moment(startDate),
      mileageRule: requiredMileageRuleOption,
      accommodationAndFoodRule: requiredAccommodationRuleOption,
      parkingRule: requiredParkingRuleOption,
      transportRule: requiredTransportRuleOption,
    };
  }, [
    JSON.stringify(editProps),
    locumRolesOptions,
    branchOptions,
    MileageRulesOption,
    AccommodationAndFoodRulesOption,
    ParkingRulesOption,
    TransportRulesOption,
  ]);

  // for setting default values in form {EDIT MODE}
  useEffect(() => {
    if (formDefaultValue) {
      const {
        mileageRule,
        accommodationAndFoodRule,
        parkingRule,
        transportRule,
        ...defaultValue
      } = formDefaultValue;

      form.setFieldsValue({
        roleId: defaultValue.roleId,
        branchId: defaultValue.branchId,
        dateRange: defaultValue?.dateRange,
        description: defaultValue.description,
        paidLunch: defaultValue.paidLunch,
        period: defaultValue.period,
        hasCoverExpense: Boolean(
          mileageRule ||
            accommodationAndFoodRule ||
            parkingRule ||
            transportRule
        ),
        coverExpenses: {
          mileageRuleId: mileageRule?.value,
          accommodationAndFoodRuleId: accommodationAndFoodRule?.value,
          parkingRuleId: parkingRule?.value,
          transportRuleId: transportRule?.value,
        },
      });
    }
  }, [formDefaultValue]);

  //  For Setting up the form timeObj value
  useEffect(() => {
    const setTimeObj = () => {
      // const { startDate: requiredStartDate, endDate: requiredEndDate } =
      //   CalculateStartEndDates(period, dateRange);
      // let startDate = requiredStartDate;
      // let endDate = requiredEndDate;
      // let days = [];

      // while (
      //   moment(startDate.format('YYYY-MM-DD')).isSameOrBefore(
      //     endDate.format('YYYY-MM-DD'),
      //   )
      // ) {
      //   const day = startDate.format('dddd');
      //   if (!days.includes(day)) days.push(day);
      //   startDate = startDate.add(1, 'day');
      // }

      // const weekDaysValue = days

      let days = new Set();
      const validPeriod = ["Day", "Week"];

      console.log("formDefaultValue: ", formDefaultValue);
      if (!!formDefaultValue && validPeriod.includes(period)) {
        days.add(formDefaultValue.dateRange.format("dddd"));
      } else {
        const { startDate: requiredStartDate, endDate: requiredEndDate } =
          CalculateStartEndDates(period, dateRange);

        for (
          let current = requiredStartDate.clone();
          current.isSameOrBefore(requiredEndDate);
          current.add(1, "day")
        ) {
          days.add(current.format("dddd"));
          current = current.add(1, "day");
        }
      }

      const weekDaysValue = Array.from(days)
        .map((d) => {
          const weekTime = operatingTime?.find(
            (ot) => ot.dayOfWeek.toUpperCase() === d.toUpperCase()
          );

          const weekDay = d;
          const lunchStartTime =
            gapData?.breakInterval?.split("-")[0] ?? weekTime?.lunchStartTime;
          const lunchEndTime =
            gapData?.breakInterval?.split("-")[1] ?? weekTime?.lunchEndTime;

          const checked = !!gapData ? true : false;
          const time = !!gapData ? `${gapData?.shiftInterval}` : ``;
          const hourlyRate = gapData?.hourlyRate;

          return weekTime
            ? {
                weekDay,
                lunchStartTime,
                lunchEndTime,
                checked,
                time,
                hourlyRate,
                breakTime: gapData?.breakInterval,
              }
            : undefined;
        })
        .filter((d) => !_.isEmpty(d));

      form.setFieldsValue({
        timeObj: weekDaysValue,
      });
    };

    if (Boolean(dateRange || formDefaultValue?.dateRange.length) && !!period)
      setTimeObj();
  }, [branchId, dateRange, operatingTime, , gapData, formDefaultValue, period]);

  const locumOptions = useMemo(
    () =>
      locums
        ?.filter(
          (i) =>
            i?.roleId === roleId ||
            (roleId === 4 && i.type === "pharmacist") ||
            (roleId === 5 && i.type === "technician")
        )
        ?.map((data, idx) => {
          return {
            key: idx,
            label: `${
              data?.name ? data?.name : data?.forenames ? data?.forenames : ""
            } ${data?.surname ? data?.surname : ""}`,
            value: data?.gphcNumber,
          };
        }),
    [locums, roleId]
  );

  const onSelect = (value) => {
    const data = locums.find((data) => data.gphcNumber === value);
    form.setFieldsValue({
      externalLocumBooking: {
        gphcNumber: data?.gphcNumber,
        email: data?.email,
        phone: data?.userProfile?.phone,
      },
    });
  };

  const handleCoverExpenseChange = () => {
    form.setFieldsValue({
      hasCoverExpense: !hasCoverExpense,
      mileageRuleId: null,
      transportRuleId: null,
      parkingRuleId: null,
      accommodationAndFoodRuleId: null,
    });
  };

  const handleCopyBranchTime = (day, timeObj, index) => {
    const findTime = operatingTime?.find(
      (item) => item?.dayOfWeek?.toLowerCase() === day?.toLowerCase(),
      (item) => item?.dayOfWeek?.toLowerCase() === day?.toLowerCase()
    );

    if (!findTime) return;

    const data = _.cloneDeep(timeObj);
    const { startTime, endTime, lunchStartTime, lunchEndTime, locumRate } =
      findTime;

    data[index].checked = true;
    data[index].time = `${startTime}-${endTime}`;

    if (lunchStartTime && lunchEndTime) {
      data[index].breakTime = `${lunchStartTime}-${lunchEndTime}`;
    }

    data[index].hourlyRate = locumRate;

    form.setFieldsValue({
      timeObj: data,
    });
  };

  const calCulateTotal = (index) => {
    const _t =
      moment
        .duration(
          moment(timeObj[index]?.time?.split("-")[1], "HH:mm").diff(
            moment(timeObj[index]?.time?.split("-")[0], "HH:mm")
          )
        )
        .asHours() * parseInt(timeObj[index].hourlyRate || "0") || 0;

    return parseFloat(_t).toFixed(2);
  };

  const copyDayTimeAndBreak = (time, break_duration, hourlyRate) => {
    const data = {
      time: time,
      break: break_duration || "",
      hourlyRate: hourlyRate || "",
    };

    copyToClipBoard(JSON.stringify(data));
  };

  const validateTimeObj = (names) => {
    const selectedNames = names.filter((name) => name.checked);

    if (selectedNames.length < 1) {
      return Promise.reject(new Error("One Day Must Be Selected To Proceed"));
    }

    for (const name of selectedNames) {
      // check time validation
      if (!name.time) {
        return Promise.reject(new Error("Please Enter Start & End Times"));
      }

      const timeParts = name.time.split("-");
      if (
        timeParts.length !== 2 ||
        timeParts.some((part) => part.trim().length !== 5)
      ) {
        return Promise.reject(
          new Error("Entered Time Should Follow The Format, HH:MM-HH:MM!")
        );
      }

      const [startTime, endTime] = timeParts.map((part) =>
        dayjs(part, "HH:mm")
      );
      const duration = endTime.diff(startTime, "minute");

      if (duration < 0) {
        return Promise.reject(
          new Error("Entered Start Time Can Not Precede The End Time!")
        );
      } else if (duration === 0) {
        return Promise.reject(
          new Error("Entered Start/End Times Can Not Be Identical!")
        );
      } else if (duration < 5) {
        return Promise.reject(
          new Error("Duration Should Be Longer Than 5 Minutes!")
        );
      }

      // Break time validation
      const breakTimeValidation = validateBreakTime(name.breakTime);

      if (breakTimeValidation !== null) return breakTimeValidation;

      // Hourly rate validation

      if (!name.hourlyRate) {
        return Promise.reject(
          new Error("You'll Have To Enter The 'Hourly Rate' To Proceed!")
        );
      }
    }

    return Promise.resolve();
  };

  const validateTime = (field) => {
    if (timeObj[field.name]?.checked) {
      if (timeObj[field.name]?.time.length === 0) {
        return Promise.reject();
      } else if (
        timeObj[field.name]?.time.length > 0 &&
        timeObj[field.name]?.time.length < 11
      ) {
        return Promise.reject();
      } else if (timeObj[field.name]?.time.length === 11) {
        const startTime = timeObj[field.name]?.time.split("-")[0];
        const endTime = timeObj[field.name]?.time.split("-")[1];
        const d = getDuration(
          dayjs(startTime, "HH:mm"),
          dayjs(endTime, "HH:mm")
        );
        if (d < 0) {
          return Promise.reject();
        } else if (d === 0) {
          return Promise.reject();
        } else if (d > 0 && d < 5) {
          return Promise.reject();
        } else {
          return Promise.resolve();
        }
      } else {
        return Promise.resolve();
      }
    }
  };

  const pasteDayTimeAndBreak = async (field) => {
    let clipboard_data = await navigator.clipboard.readText();
    clipboard_data = JSON.parse(clipboard_data);
    const data = _.cloneDeep(timeObj);
    data[field].checked = true;
    data[field].time = clipboard_data?.time;
    data[field].breakTime = clipboard_data?.break;
    data[field].hourlyRate = clipboard_data?.hourlyRate;
    form.setFieldsValue({
      timeObj: data,
    });
  };

  const addJob = (values) => {
    let newJobs = [];

    const { startDate, endDate } = CalculateStartEndDates(
      values.period,
      values.dateRange
    );

    for (
      let current = startDate.clone();
      current.isSameOrBefore(endDate);
      current = current.add(1, "day")
    ) {
      const dayOfWeek = moment(current.format("YYYY-MM-DD"))
        .format("dddd")
        .toLowerCase();

      const selectedWeek = values.timeObj?.find((week) => {
        return week.checked && week.weekDay.toLowerCase() === dayOfWeek;
      });

      if (selectedWeek) {
        // Add a new property to the found object
        selectedWeek.startEndDate = current.format("YYYY-MM-DD");
      }

      if (selectedWeek) {
        newJobs.push({
          startDate: selectedWeek.startEndDate,
          endDate: selectedWeek.startEndDate,
          lunchStartTime: selectedWeek?.breakTime?.split("-")[0]?.trim(),
          lunchEndTime: selectedWeek?.breakTime?.split("-")[1]?.trim(),
          startTime: selectedWeek?.time?.split("-")[0]?.trim(),
          endTime: selectedWeek?.time?.split("-")[1]?.trim(),
          hourlyRate: selectedWeek?.hourlyRate,
          roleId,
          description: values.description,
          paidLunch: values.paidLunch,
          branchId: branchId,
          ...(hasCoverExpense && values.coverExpenses),
          mileageRuleId: values?.coverExpenses?.mileageRuleId ?? null,
          transportRuleId: values?.coverExpenses?.transportRuleId ?? null,
          parkingRuleId: values?.coverExpenses?.parkingRuleId ?? null,
          accommodationAndFoodRuleId:
            values?.coverExpenses?.accommodationAndFoodRuleId ?? null,
          externalLocumBooking: values?.hasExternalLocum
            ? values?.externalLocumBooking
            : undefined,
          ...{ hasExternalLocum },
        });
      }

      // console.log('dayOfWeek: ', dayOfWeek);
      // console.log('selectedWeek: ', selectedWeek);
      // console.log('newJobs: ', newJobs);
    }

    if (hasAccess(branchApiKeys.CREATE_BULK_JOB)) {
      dispatch(
        createJobBulkThunk({
          branchId,
          data: newJobs,
        })
      ).then((res) => {
        if (res.payload.length) {
          form.resetFields();
          notify("success", "Job Added Successfully");
          setshowModal();

          for (var job in newJobs) {
            if (window.analytics)
              window.analytics.track("Job Added", newJobs[job]);
          }
        }
        if (res.payload.code === "validation") {
          const thirdArray = newJobs.filter((elem) => {
            return res.payload.array?.some((ele) => {
              return ele.date !== moment(elem.startDate).format("DD-MM-YYYY");
            });
          });

          setShowErrorModal(true);
          setErrorData(res.payload);
          for (var data in thirdArray) {
            window?.analytics?.track("Job Added", thirdArray[data]);
          }
        }
      });
    }
  };

  const validate = () => {
    form.validateFields().then((values) => {
      addJob(values);
    });
  };

  console.log("Form Value: ", form.getFieldsValue());
  // console.log('Form Value: ', formDefaultValue?.mileageRule?.label);
  return (
    <>
      <ConfirmModal
        backdrop="static"
        title="Hold up!"
        open={showErrorModal}
        confirmButtonText="Close"
        image={infoIcon}
        onConfirm={() => {
          setErrorData(undefined);
          // setHandleModal();
          form.resetFields();
          setShowErrorModal(false);
          // onSuccess();
        }}
      >
        <div className="d-flex flex-column gap-3">
          <div>
            There seem to be a few conflicts within this schedule attempt.
            However, we've created shifts for dates prior to and following the
            date(s) of conflict.
          </div>
          <div>
            You can resolve these conflicts and schedule again for the following
            days,
          </div>
        </div>
        <div className="d-flex flex-column gap-3 align-items-center">
          {errorData?.array?.map((data, idx) => (
            <div className="d-flex align-items-center gap-2" key={idx}>
              <div style={{ whiteSpace: "nowrap" }}>{data.date}</div>
              <div style={{ border: "1px solid", height: 20 }} />
              <div>{data.day[0].toUpperCase() + data.day.slice(1)}</div>
              <div style={{ border: "1px solid", height: 20 }} />
              <div>{data.reason}</div>
            </div>
          ))}
        </div>
        {errorData?.successCount > 0 && (
          <div>
            {errorData?.successCount} Shifts/Jobs Were Scheduled Successfully
          </div>
        )}
      </ConfirmModal>

      <AddModal
        title={
          editProps ? (
            <Space align="center">
              <Typography.Title level={4}>Job Details</Typography.Title>{" "}
              <LocumJobStatusChip status={"unfilled"} />
            </Space>
          ) : (
            "Add Job"
          )
        }
        handleModal={showModal}
        setHandleModal={setshowModal}
        primaryButtonText="Add Job"
        secondaryButtonText="Cancel"
        secondaryButtonHandler={() => {
          setshowModal();
          form.resetFields();
        }}
        primaryButtonHandler={validate}
      >
        <Form
          form={form}
          id={formId}
          // onFinish={(value) => console.log(value)}
          layout="vertical"
          autoComplete="off"
          className="add_shift_form"
        >
          {/* ROLE_ID: roleId */}
          <Form.Item
            label={"Role"}
            name="roleId"
            rules={[
              {
                required: true,
                message: "You'll Have To Select A Role To Proceed!",
              },
            ]}
            className="not-required"
          >
            <Select
              className="text-input-field p-1 pe-3 t1"
              bordered={false}
              onChange={(value) => {
                form.setFieldsValue({
                  roleId: value,
                });
              }}
              options={locumRolesOptions}
              dropdownStyle={{ zIndex: 2000 }}
              placeholder="Select Role"
              suffixIcon={<img src={Dropwdown} alt="dropdown" />}
            />
          </Form.Item>

          {/* BRANCH_ID: branchId */}
          <Form.Item
            label="Branch"
            name="branchId"
            rules={[
              {
                required: true,
                message: "You'll Have To Select A Branch To Proceed!",
              },
            ]}
            className="not-required"
          >
            <Select
              className="text-input-field p-1 pe-3 t1"
              bordered={false}
              onChange={(label) => {
                form.setFieldsValue({
                  branchId: label,
                  period: undefined,
                  dateRange: undefined,
                  timeObj: undefined,
                });
              }}
              options={branchOptions}
              dropdownStyle={{ zIndex: 2000 }}
              placeholder="Select Branch"
              suffixIcon={<img src={Dropwdown} alt="dropdown" />}
              validateFirst
            />
          </Form.Item>

          {/* PERIOD: period */}
          <Form.Item
            label="Period"
            name="period"
            rules={[
              {
                required: true,
                message: "You'll Have To Select A Period To Proceed!",
              },
            ]}
            className="not-required"
          >
            <Select
              className="text-input-field p-1 pe-3 t1"
              bordered={false}
              defaultValue={formDefaultValue?.period}
              options={PERIOD_OPTIONS}
              dropdownStyle={{ zIndex: 2000 }}
              placeholder="Select Period"
              suffixIcon={<img src={Dropwdown} alt="dropdown" />}
              onChange={(value) => {
                form.setFieldsValue({
                  period: value,
                  dateRange: undefined,
                  timeObj: undefined,
                });
              }}
            />
          </Form.Item>

          {console.log(dateRange, "dateRange")}

          {/* BASES ON THE SELECTED PERIOD: daterange */}
          <Form.Item
            label="Start Date"
            name="dateRange"
            dependencies={["branchId", "period"]}
            rules={[
              {
                required: true,
                message: "You'll Have To Select A Start/End Date To Proceed!",
              },
            ]}
            valuePropName="dateRange"
          >
            {period === "Day" || period === "Week" ? (
              <DatePicker
                defaultValue={
                  formDefaultValue?.dateRange
                    ? dayjs(formDefaultValue?.dateRange)
                    : ""
                }
                disabledDate={(current) => {
                  return dayjs(current) <= formDefaultValue?.dateRange;
                }} // Specify the date that cannot be selected
                value={!!dateRange && dayjs(dateRange)}
                onChange={(value) => {
                  form.setFieldsValue({
                    dateRange: value,
                  });
                }}
                allowClear={false}
                picker={period === "Week" ? "week" : "date"}
                inputReadOnly
                className="text-input-field px-3 t1 picker dayweek"
                popupStyle={{ zIndex: 1000000 }}
                format={"YYYY-MM-DD"}
                superNextIcon={null}
                superPrevIcon={null}
                suffixIcon={null}
                prevIcon={<img src={ArrowLeftIcon} alt="calendar" />}
                nextIcon={<img src={ArrowrightIcon} alt="calendar" />}
                separator={null}
                // renderExtraFooter={CalenderPopupFooter}
              />
            ) : (
              <RangePicker
                defaultValue={
                  formDefaultValue?.dateRange
                    ? dayjs(formDefaultValue?.dateRange[0])
                    : ""
                }
                disabledDate={(current) => {
                  return dayjs(current) <= formDefaultValue?.dateRange;
                }} // Specify the date that cannot be selected
                // onCalendarChange={(val) => {
                //   setDates(val);
                // }}
                // onOpenChange={data && onOpenChange}
                value={
                  !!dateRange && [dayjs(dateRange[0]), dayjs(dateRange[1])]
                }
                onChange={(values) => {
                  console.log("first", values);
                  form.setFieldsValue({
                    dateRange: values,
                  });
                }}
                allowClear={false}
                inputReadOnly
                className="text-input-field px-3 t1 picker"
                popupStyle={{ zIndex: 1000000 }}
                // format={'YYYY-MM-DD'}
                superNextIcon={null}
                superPrevIcon={null}
                suffixIcon={null}
                prevIcon={<img src={ArrowLeftIcon} alt="calendar" />}
                nextIcon={<img src={ArrowrightIcon} alt="calendar" />}
                separator={null}
                // renderExtraFooter={CalenderPopupFooter}
              />
            )}
          </Form.Item>

          {dateRange && (
            <Form.List
              name="timeObj"
              rules={[
                {
                  validator: async (_, names) => {
                    await validateTimeObj(names);
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => {
                return (
                  <div className="d-flex flex-column align-items-center gap-4 pb-0 mb-4">
                    {fields.map((field, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex align-items-center gap-2"
                          style={{ width: "100%" }}
                        >
                          <Form.Item
                            name={[field.name, "checked"]}
                            className="m-0 add_shift_selected"
                            style={{ width: "100%" }}
                          >
                            <div
                              className="d-flex align-items-center justify-content-between "
                              style={{ width: "100%" }}
                            >
                              <div className="d-flex justify-content-start align-items-center">
                                <Switch
                                  checked={
                                    timeObj && timeObj[field.name]?.checked
                                  }
                                  onChange={() => {
                                    const data = _.cloneDeep(timeObj);
                                    data[index].checked = !data[index].checked;
                                    form.setFieldsValue({
                                      timeObj: data,
                                    });
                                    // settoggleShiftTiming((prev) => !prev);
                                  }}
                                />
                                <p className="selected_week">
                                  {!!timeObj &&
                                    !!timeObj[field.name]?.weekDay &&
                                    timeObj[
                                      field.name
                                    ]?.weekDay[0].toUpperCase() +
                                      timeObj[field.name].weekDay.slice(1)}
                                </p>
                              </div>

                              <div className="selected_shift_icons">
                                <CopyBranchTimeButton
                                  onClick={() =>
                                    handleCopyBranchTime(
                                      !!timeObj &&
                                        !!timeObj[field.name]?.weekDay &&
                                        timeObj[
                                          field.name
                                        ]?.weekDay[0].toUpperCase() +
                                          timeObj[field.name].weekDay.slice(1),
                                      timeObj,
                                      index,
                                      index
                                    )
                                  }
                                />
                                <Tooltip title="Paste">
                                  <CustomIconButton
                                    onClick={() =>
                                      pasteDayTimeAndBreak(field.name)
                                    }
                                    icon={
                                      <img
                                        src={Clipboardicon}
                                        alt="clipboard"
                                      />
                                    }
                                    className="clipicon"
                                    style={{
                                      padding: 0,
                                      height: "auto",
                                    }}
                                  />
                                </Tooltip>
                                <CopyButton
                                  onClick={() => {
                                    !!timeObj &&
                                      copyDayTimeAndBreak(
                                        timeObj[field.name].time,
                                        timeObj[field.name].breakTime,
                                        timeObj[field.name].hourlyRate,
                                        timeObj[field.name].hourlyRate
                                      );
                                  }}
                                />
                              </div>
                            </div>
                            {!!timeObj && timeObj[field.name]?.checked && (
                              <div className="assign_locum d-flex flex-wrap align-items-end justify-content-between gap-1">
                                <Form.Item
                                  label="Shift Timings"
                                  name={[field.name, "time"]}
                                  className="m-0"
                                  // hidden={false}
                                  style={{ width: "49%" }}
                                  validateTrigger={["onChange", "onBlur"]}
                                  rules={[
                                    {
                                      validator: async (a, names) => {
                                        if (timeObj[field.name]?.checked) {
                                          if (
                                            timeObj[field.name]?.time.length ===
                                            0
                                          ) {
                                            return Promise.reject();
                                          } else if (
                                            timeObj[field.name]?.time.length >
                                              0 &&
                                            timeObj[field.name]?.time.length <
                                              11
                                          ) {
                                            return Promise.reject();
                                          } else if (
                                            timeObj[field.name]?.time.length ===
                                            11
                                          ) {
                                            const startTime =
                                              timeObj[field.name]?.time.split(
                                                "-"
                                              )[0];
                                            const endTime =
                                              timeObj[field.name]?.time.split(
                                                "-"
                                              )[1];
                                            const d = getDuration(
                                              dayjs(startTime, "HH:mm"),
                                              dayjs(endTime, "HH:mm")
                                            );
                                            if (d < 0) {
                                              return Promise.reject();
                                            } else if (d === 0) {
                                              return Promise.reject();
                                            } else if (d > 0 && d < 5) {
                                              return Promise.reject();
                                            } else {
                                              return Promise.resolve();
                                            }
                                          } else {
                                            return Promise.resolve();
                                          }
                                        }
                                      },
                                    },
                                  ]}
                                >
                                  <Input
                                    className="text-input-field px-3 pe-3 t1"
                                    placeholder="Eg: 01:00 - 15:00"
                                    value={
                                      !!timeObj && timeObj[field.name].time
                                    }
                                    disabled={!timeObj[field.name]?.checked}
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      const input = e.target.value;
                                      const digitsOnly = input.replace(
                                        /\D/g,
                                        ""
                                      ); // remove non-digits
                                      let formattedTime = "";
                                      if (digitsOnly.length < 3) {
                                        formattedTime = digitsOnly;
                                      } else if (digitsOnly.length === 3) {
                                        formattedTime = `${
                                          digitsOnly[0]
                                        }:${digitsOnly.substring(1, 3)}`;
                                      } else if (
                                        digitsOnly.length > 3 &&
                                        digitsOnly.length < 5
                                      ) {
                                        const hours = digitsOnly.substring(
                                          0,
                                          2,
                                          2
                                        );
                                        const minutes = digitsOnly.substring(
                                          2,
                                          4,
                                          4
                                        );
                                        if (hours > 23 && minutes > 59) {
                                          formattedTime = "23:59";
                                        } else if (hours > 23) {
                                          formattedTime = `23:${minutes}`;
                                        } else if (minutes > 59) {
                                          formattedTime = `${hours}:59`;
                                        } else {
                                          formattedTime = `${hours}:${minutes}`;
                                        }
                                      } else if (digitsOnly.length < 7) {
                                        formattedTime = `${digitsOnly.substring(
                                          0,
                                          2,
                                          2
                                        )}:${digitsOnly.substring(
                                          2,
                                          4,
                                          4
                                        )}-${digitsOnly.substring(4, 6)}`;
                                      } else if (digitsOnly.length === 7) {
                                        formattedTime = `${digitsOnly.substring(
                                          0,
                                          2,
                                          2
                                        )}:${digitsOnly.substring(
                                          2,
                                          4,
                                          4
                                        )}-${digitsOnly.substring(
                                          4,
                                          5,
                                          5
                                        )}:${digitsOnly.substring(5, 7)}`;
                                      } else if (
                                        digitsOnly.length > 7 &&
                                        digitsOnly.length < 8
                                      ) {
                                        const hours = digitsOnly.substring(
                                          4,
                                          6,
                                          6
                                        );
                                        const minutes = digitsOnly.substring(
                                          6,
                                          8,
                                          8
                                        );
                                        if (hours > 23 && minutes > 59) {
                                          formattedTime = `${digitsOnly.substring(
                                            0,
                                            2,
                                            2
                                          )}:${digitsOnly.substring(
                                            2,
                                            4,
                                            4
                                          )}-23:59`;
                                        } else if (hours > 23) {
                                          formattedTime = `${digitsOnly.substring(
                                            0,
                                            2,
                                            2
                                          )}:${digitsOnly.substring(
                                            2,
                                            4,
                                            4
                                          )}-23:${minutes}`;
                                        } else if (minutes > 59) {
                                          formattedTime = `${digitsOnly.substring(
                                            0,
                                            2,
                                            2
                                          )}:${digitsOnly.substring(
                                            2,
                                            4,
                                            4
                                          )}-${hours}:59`;
                                        } else {
                                          formattedTime = `${digitsOnly.substring(
                                            0,
                                            2,
                                            2
                                          )}:${digitsOnly.substring(
                                            2,
                                            4,
                                            4
                                          )}-${hours}:${minutes}`;
                                        }
                                      } else {
                                        const hours = digitsOnly.substring(
                                          4,
                                          6,
                                          6
                                        );
                                        const minutes = digitsOnly.substring(
                                          6,
                                          8,
                                          8
                                        );
                                        if (hours > 23 && minutes > 59) {
                                          formattedTime = `${digitsOnly.substring(
                                            0,
                                            2,
                                            2
                                          )}:${digitsOnly.substring(
                                            2,
                                            4,
                                            4
                                          )}-23:59`;
                                        } else if (hours > 23) {
                                          formattedTime = `${digitsOnly.substring(
                                            0,
                                            2,
                                            2
                                          )}:${digitsOnly.substring(
                                            2,
                                            4,
                                            4
                                          )}-23:${minutes}`;
                                        } else if (minutes > 59) {
                                          formattedTime = `${digitsOnly.substring(
                                            0,
                                            2,
                                            2
                                          )}:${digitsOnly.substring(
                                            2,
                                            4,
                                            4
                                          )}-${hours}:59`;
                                        } else {
                                          formattedTime = `${digitsOnly.substring(
                                            0,
                                            2,
                                            2
                                          )}:${digitsOnly.substring(
                                            2,
                                            4,
                                            4
                                          )}-${hours}:${minutes}`;
                                        }
                                      }
                                      const data = _.cloneDeep(timeObj);
                                      data[index].time = formattedTime;
                                      form.setFieldsValue({
                                        timeObj: data,
                                      });
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label="Break"
                                  name={[field.name, "breakTime"]}
                                  className="m-0"
                                  hidden={false}
                                  style={{ width: "49%" }}
                                  validateTrigger={["onChange", "onBlur"]}
                                  rules={
                                    hasExternalLocum && [
                                      {
                                        required: true,
                                        validator: async (a, names) => {
                                          await validateTime(field);
                                        },
                                      },
                                    ]
                                  }
                                >
                                  <TimeInput
                                    className="text-input-field px-3 pe-3 t1"
                                    placeholder="Eg: 1h"
                                    onChange={(value) => {
                                      const data = _.cloneDeep(timeObj);
                                      data[index].breakTime = value;
                                      form.setFieldsValue({
                                        timeObj: data,
                                      });
                                    }}
                                    value={timeObj[index].breakTime}
                                  />
                                </Form.Item>

                                <div className="d-flex align-items-end justify-content-between">
                                  <Form.Item
                                    label="Amount/hr"
                                    className="m-0"
                                    name={[field.name, "hourlyRate"]}
                                    hidden={false}
                                    style={{ width: "auto" }}
                                    rules={
                                      hasExternalLocum && [
                                        {
                                          required: true,
                                          message:
                                            "You'll Have To Enter The Rate  To Proceed!",
                                        },
                                      ]
                                    }
                                  >
                                    <Input
                                      className="text-input-field px-3 t1"
                                      placeholder="Enter pounds per hour"
                                    />
                                  </Form.Item>
                                  <div className="total">
                                    <p>Total = £{calCulateTotal(index)}</p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Form.Item>
                        </div>
                      );
                    })}
                    <Form.ErrorList className="text-danger" errors={errors} />
                  </div>
                );
              }}
            </Form.List>
          )}

          <div className="d-flex gap-3 align-items-center border-top ">
            <Form.Item
              name="paidLunch"
              className="mt-3"
              valuePropName="checked"
              // hidden={type === "Shift"}
              rules={[
                {
                  required: true,
                  message: "You'll Have To Select A Start/End Date To Proceed!",
                },
              ]}
              required
            >
              <Checkbox>Paid Lunch</Checkbox>
            </Form.Item>

            {/* hasCoverExpense */}
            <Form.Item
              name="hasCoverExpense"
              className="mt-3"
              valuePropName="checked"
            >
              <Checkbox
                checked={hasCoverExpense}
                onChange={() => {
                  handleCoverExpenseChange();
                }}
              >
                Cover Expense
              </Checkbox>
            </Form.Item>
          </div>

          {hasCoverExpense && (
            <div
              className="d-flex flex-column day-box"
              style={{
                width: "100%",
                paddingBottom: 10,
              }}
            >
              {/* mileageRuleId */}
              <Form.Item
                name={["coverExpenses", "mileageRuleId"]}
                label={CoverExpenseFormLabels({
                  label: "Mileage Rule",
                  data: findObj(mileageRuleId, mileageRules),
                })}
                className="m-0"
                valuePropName="checked"
              >
                <Select
                  className="text-input-field p-1 pe-3 t1 bg-white"
                  bordered={false}
                  dropdownStyle={{ zIndex: 2000 }}
                  placeholder="Mileage Rule"
                  suffixIcon={<img src={Dropwdown} alt="dropdown" />}
                  defaultValue={formDefaultValue?.mileageRule?.label}
                  options={MileageRulesOption}
                ></Select>
              </Form.Item>

              {/* accommodationAndFoodRuleId */}
              <Form.Item
                name={["coverExpenses", "accommodationAndFoodRuleId"]}
                label={CoverExpenseFormLabels({
                  label: "Accommodation & Food Rule",
                  data: findObj(
                    accommodationAndFoodRuleId,
                    accommodationAndFoodRules
                  ),
                })}
                className="m-0"
                valuePropName="checked"
              >
                <Select
                  className="text-input-field p-1 pe-3 t1 bg-white"
                  bordered={false}
                  dropdownStyle={{ zIndex: 2000 }}
                  placeholder="Accommodation & Food Rule"
                  suffixIcon={<img src={Dropwdown} alt="dropdown" />}
                  defaultValue={
                    formDefaultValue?.accommodationAndFoodRule?.label
                  }
                  options={AccommodationAndFoodRulesOption}
                ></Select>
              </Form.Item>

              {/* parkingRuleId */}
              <Form.Item
                name={["coverExpenses", "parkingRuleId"]}
                label={CoverExpenseFormLabels({
                  label: "Parking Rule",
                  data: findObj(parkingRuleId, parkingRules),
                })}
                className="m-0"
                valuePropName="checked"
                //   hidden={type === "Shift"}
              >
                <Select
                  className="text-input-field p-1 pe-3 t1 bg-white"
                  bordered={false}
                  dropdownStyle={{ zIndex: 2000 }}
                  placeholder="Parking Rule"
                  suffixIcon={<img src={Dropwdown} alt="dropdown" />}
                  defaultValue={formDefaultValue?.parkingRule?.label}
                  options={ParkingRulesOption}
                ></Select>
              </Form.Item>

              {/* transportRuleId */}
              <Form.Item
                name={["coverExpenses", "transportRuleId"]}
                label={CoverExpenseFormLabels({
                  label: "Transport Rule",
                  data: findObj(transportRuleId, transportRules),
                })}
                className="m-0"
                valuePropName="checked"
                //   hidden={type === "Shift"}
              >
                <Select
                  className="text-input-field p-1 pe-3 t1 bg-white"
                  bordered={false}
                  dropdownStyle={{ zIndex: 2000 }}
                  placeholder="Transport Rule"
                  suffixIcon={<img src={Dropwdown} alt="dropdown" />}
                  options={TransportRulesOption}
                  defaultValue={formDefaultValue?.transportRule?.label}
                >
                  {transportRules.map((data, idx) => (
                    <Select.Option key={idx} value={data.id}>
                      {data.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          )}

          <Form.Item
            label="Description"
            name="description"
            className="mt-3"
            rules={[
              {
                required: true,
                message: "You'll Have To Enter A Description To Proceed!",
              },
            ]}
          >
            <Input.TextArea
              className="px-3 py-2 t1"
              rows={5}
              maxLength={256}
              name="description"
              placeholder="Write your job description here..."
            />
          </Form.Item>

          <Form.Item
            name="hasExternalLocum"
            className="mt-3"
            valuePropName="checked"
            // rules={[
            //   {
            //     validator: (_, value) =>
            //       value
            //         ? Promise.resolve()
            //         : Promise.reject(
            //             new Error("You'll Have To Assign A Locum To Proceed!"),
            //             "You'll Have To Assign A Locum To Proceed!",
            //           ),
            //   },
            // ]}
          >
            <Checkbox>Assign Locum</Checkbox>
          </Form.Item>

          {hasExternalLocum && (
            <Form.Item
              className="d-flex flex-column gap-2 "
              name="externalLocumBooking"
              style={{ gap: "15px" }}
            >
              <Form.Item
                label="GPhC No. / Name"
                name={["externalLocumBooking", "gphcNumber"]}
                className="m-0"
                rules={[
                  {
                    required: true,
                    message: "You'll Have To Enter The GPhC Number To Proceed!",
                  },
                ]}
              >
                <AutoComplete
                  dropdownStyle={{ zIndex: 2000 }}
                  className="text-input-field pe-3 p-1 t1"
                  options={locumOptions}
                  maxLength={7}
                  onSelect={onSelect}
                  bordered={false}
                  style={{ borderRadius: "8px" }}
                  onSearch={(e) => {
                    if (
                      e.length < 7 ||
                      e !== externalLocumBooking?.gphcNumber
                    ) {
                      console.log(e,"e")
                      form.setFieldsValue({
                        externalLocumBooking: {
                          gphcNumber: e,
                          email: "",
                          phone: "",
                          id: undefined,
                        },
                      });
                      return;
                    }
                    form.setFieldsValue({
                      externalLocumBooking: {
                        gphcNumber: e,
                        ...externalLocumBooking,
                      },
                    });
                  }}
                  placeholder="GPhC No."
                />
              </Form.Item>
              <div className="assign_locum">
                <Form.Item
                  label="Id"
                  name={["externalLocumBooking", "id"]}
                  className="m-0"
                  hidden={true}
                >
                  <Input
                    className="text-input-field px-3 pe-3 t1"
                    placeholder="Email Address"
                  />
                </Form.Item>
                <Form.Item
                  label="Email Address"
                  name={["externalLocumBooking", "email"]}
                  className="m-0"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message:
                        "You'll Have To Enter The Email Address To Proceed!",
                    },
                  ]}
                >
                  <Input
                    className="text-input-field px-3 pe-3 t1"
                    placeholder="Email Address"
                  />
                </Form.Item>

                <Form.Item
                  label="Phone Number"
                  className="m-0"
                  name={["externalLocumBooking", "phone"]}
                  rules={[
                    {
                      required: true,
                      message:
                        "You'll Have To Enter The Phone Number To Proceed!",
                    },
                  ]}
                >
                  <Input
                    className="text-input-field px-3 t1"
                    placeholder="Phone Number"
                  />
                </Form.Item>
              </div>
            </Form.Item>
          )}
        </Form>
      </AddModal>
    </>
  );
};

export default AddJobFormNew;
