import React from "react";
import { EmptyData } from "../comon/EmptyData";
import BranchesList from "./BranchesList";
import HourlyRateInfo from "./HourlyRateInfo";
import "./style.scss";

const JobInfoTab = ({ data, canEdit }) => {
  return (
    <div>
      {!!data ? (
        <>
          {!!data?.id && <HourlyRateInfo data={data} canEdit={canEdit} />}

          {data?.workedBranches?.length > 0 ? (
            <BranchesList branchList={data?.workedBranches} />
          ) : null}
        </>
      ) : (
        <EmptyData title="Active Contract" descriptio="Contract" />
      )}
    </div>
  );
};

export default JobInfoTab;
