import { Form, Input } from "antd";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { EmptyData } from "../../../../../components/comon/EmptyData";
import { AddModal } from "../../../../../components/UI/modals/addModal";
import { TableComponet } from "../../../../../components/UI/table";
import {
  deleteTransportRulesThunk,
  saveTransportRulesThunk,
} from "../../../../../redux/profile/profileThunk";
import { settingsApiKeys } from "../../../../../utils/apiKeyConstants";
import { hasAccess, notify } from "../../../../../utils/helper";

export const TransportRules = () => {
  const formId = useMemo(() => "form", []);
  const [form] = Form.useForm();
  const { transportRules } = useSelector((state) => state.profile);

  const transportRulesColumn = useMemo(
    () => [
      {
        Header: "Rule Name",
        accessor: "name",
      },
      {
        Header: "Pay Over (Pence)",
        accessor: "payOver",
      },
      {
        Header: "Up to (Pence)",
        accessor: "upTo",
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row: { original } }) => {
          return (
            <div>
              <Dropdown icon="ellipsis vertical" pointing="top right">
                <Dropdown.Menu direction="left">
                  <Dropdown.Item
                    text="Edit"
                    onClick={() => {
                      form.setFieldsValue({
                        name: original.name,
                        payOver: original.payOver,
                        upTo: original.upTo,
                        id: original.id,
                      });
                      setShowModal(!showModal);
                    }}
                  />
                  <Dropdown.Item
                    style={{ color: "#EF5350" }}
                    onClick={() => {
                      if (hasAccess(settingsApiKeys.DELETE_TRANSPORT_RULE))
                        onDeleteRule(original.id);
                    }}
                    text="Delete"
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    []
  );
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const onSubmit = (values) => {
    dispatch(saveTransportRulesThunk(values)).then((res) => {
      if (res.payload) {
        notify("success", "Transport Rule Saved!");
        setShowModal(false);
        form.resetFields();
      }
    });
  };

  const onDeleteRule = (id) => {
    dispatch(
      deleteTransportRulesThunk({
        id,
      })
    ).then((res) => {
      if (res.payload.status === "success") {
        notify("success", "Transport Rule Deleted!");
      }
    });
  };

  return (
    <div className="border rounded">
      <div className="text-row d-flex align-items-center justify-content-between">
        <div className="s1 m-0">Transport</div>
        <div
          className="cursor-pointer"
          onClick={() => setShowModal(true)}
          style={{ color: "#51C2BC" }}
        >
          ADD RULE
        </div>
      </div>
      {transportRules?.length <= 0 ? (
        <EmptyData title="Transport Rules" />
      ) : (
        <TableComponet
          rowCursor="pointer"
          isBorderShown
          columns={transportRulesColumn}
          data={transportRules}
        />
      )}
      <AddModal
        title="Transport"
        handleModal={showModal}
        setHandleModal={setShowModal}
        primaryButtonText="Save"
        formId={formId}
      >
        <Form
          onFinish={
            hasAccess(settingsApiKeys.SAVE_TRANSPORT_RULES)
              ? onSubmit
              : undefined
          }
          form={form}
          id={formId}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            label="Rule Name"
            name="name"
            rules={[{ required: true, message: "Please Enter Rule Name!" }]}
          >
            <Input
              maxLength={50}
              onBlur={(e) =>
                form.setFieldsValue({ name: e.target.value.trim() })
              }
              placeholder="Rule Name"
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>
          <Form.Item
            label="Pay Over"
            name="payOver"
            rules={[
              { required: true, message: "Please Enter Pay Over Amount!" },
              () => ({
                validator(rule, value) {
                  if (value >= 100) {
                    return Promise.reject(
                      "Rate Per Mile can not be greater than or equal to 100"
                    ); // The validator should always return a promise on both success and error
                  } else if (value < 0) {
                    return Promise.reject(
                      "Rate Per Mile can not be less than 0"
                    ); // The validator should always return a promise on both success and error
                  }
                  return Promise.resolve(value);
                },
              }),
            ]}
          >
            <Input
              type="number"
              placeholder="Pay Over"
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>
          <Form.Item
            label="Up to"
            name="upTo"
            rules={[
              { required: true, message: "Please Enter Up to Amount!" },
              () => ({
                validator(rule, value) {
                  if (value >= 100) {
                    return Promise.reject(
                      "Rate Per Mile should be less than 100"
                    ); // The validator should always return a promise on both success and error
                  } else if (value < 0) {
                    return Promise.reject(
                      "Rate Per Mile can not be less than 0"
                    ); // The validator should always return a promise on both success and error
                  }
                  return Promise.resolve(value);
                },
              }),
            ]}
          >
            <Input
              type="number"
              placeholder="Up To"
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>
          <Form.Item hidden label="id" name="id">
            <Input placeholder="id" className="text-input-field p-2 pe-3 t1" />
          </Form.Item>
        </Form>
      </AddModal>
    </div>
  );
};
