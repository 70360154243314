import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
// import CalendarIcon from "../../../../../assets/icons/calendar.svg";
import { useMutation } from "@tanstack/react-query";
import { DatePicker, Skeleton } from "antd";
import dayjs from "dayjs";
import React from "react";
import { ConfirmModal } from "../../../../../components/UI/modals/confirmModal";
import { EmptyData } from "../../../../../components/comon/EmptyData";
import {
  StaffGetReviewList,
  StaffReviewDelete,
} from "../../../../../redux/staffs/staffsAPI";
import { RouterConfig } from "../../../../../routerConfig";
import { notify } from "../../../../../utils/helper";
import ReviewCards from "./components/ReviewCards";
import "./style.scss";

const StaffReview = () => {
  const [showDeleteReview, setshowDeleteReview] = useState(false);
  const navigate = useNavigate();
  const { staffId } = useParams();
  const [selectedItem, setSelectedItems] = useState(null);
  const { mutate: deleteMutate } = useMutation({
    mutationFn: StaffReviewDelete,
  });

  const [filter, setFilters] = useState({
    filters: {
      startDate: dayjs().format("YYYY-MM-DD"),
      endDate: dayjs().format("YYYY-MM-DD"),
    },
    pagination: {
      page: 1,
      perPage: 10,
    },
  });

  // get questions
  const { data, refetch, isFetching } = useQuery({
    queryKey: ["review-list", filter],
    queryFn: () =>
      StaffGetReviewList({
        id: staffId,
        data: filter,
      }),
    retry: 0,
  });

  const toggleshowDeleteReview = () => {
    setshowDeleteReview(!showDeleteReview);
  };

  const ToggleAddQuestions = () => {
    navigate(
      generatePath(RouterConfig.branchAdminStaffPerformanceReviewAddQuestion, {
        staffId,
      })
    );
  };

  const handleDelete = () => {
    let reviewIds = [];
    selectedItem?.reviews?.forEach((element) => {
      reviewIds.push(element?.id);
    });

    const payload = {
      id: staffId,
      data: {
        reviewIds,
      },
    };

    deleteMutate(payload, {
      onSuccess: (data) => {
        if (data?.status === "success") {
          notify("success", "Review deleted successfully");
          refetch();
          toggleshowDeleteReview();
        }
      },
    });
  };

  return (
    <div style={{ position: "relative" }} className="staffReview">
      <div className="search-section">
        <div className="datepicker-wrap mx-3">
          <DatePicker
            allowClear={false}
            inputReadOnly
            format="DD-MM-YYYY"
            getPopupContainer={(trigger) => trigger.parentElement}
            className="text-input-field p-3 pe-3 t1"
            style={{ width: "100%", borderRadius: "50px" }}
            onChange={(val) => {
              setFilters({
                ...filter,
                filters: {
                  ...filter.filters,
                  startDate: val.format("YYYY-MM-DD"),
                  endDate: val.format("YYYY-MM-DD"),
                },
              });
            }}
          />
        </div>

        <button className="custom_btn btn" onClick={ToggleAddQuestions}>
          {" "}
          + Add Performance Review
        </button>
      </div>

      <div className="staffReview-inner">
        {isFetching ? (
          <div>
            <Skeleton avatar paragraph={{ rows: 0 }} />
            <Skeleton avatar paragraph={{ rows: 0 }} />
            <Skeleton avatar paragraph={{ rows: 0 }} />
          </div>
        ) : (
          <>
            {data?.data?.length > 0 ? (
              data?.data?.map((supervisor) => (
                <ReviewCards
                  supervisor={supervisor}
                  key={supervisor?.id}
                  setSelectedItems={setSelectedItems}
                  toggleshowDeleteReview={toggleshowDeleteReview}
                />
              ))
            ) : (
              <EmptyData title="Performance Reviews" />
            )}
          </>
        )}
      </div>
      <ConfirmModal
        title="Delete Performance Review"
        open={showDeleteReview}
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        onClose={toggleshowDeleteReview}
        onConfirm={handleDelete}
      >
        <div className="performance-review-modal">
          <p className="confirm_text">
            Are you sure you want to delete performance review from ?
          </p>
        </div>
      </ConfirmModal>
    </div>
  );
};

export default StaffReview;
