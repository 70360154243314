import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { TableComponet } from "../../../../../components/UI/table";
import { EmptyData } from "../../../../../components/comon/EmptyData";
import arrowRightIcon from '../../../../../assets/icons/arrow-right.png'
import { AddModal } from "../../../../../components/UI/modals/addModal";
import moment from "moment";
import './style.scss'

export const Transactions = () => {
    const { paymentInvoices } = useSelector(
        (state) => state.profile
    );
    const [showTransactionHistoryModal, setShowTransactionHistoryModal] = useState(false)
    const [transactionDetails, setTransactionDetails] = useState()
    const [debitDate, setDebitDate] = useState()
    const [VAT, setVAT] = useState()

    const {
        rows: invoicesData,
    } = useMemo(() => paymentInvoices, [paymentInvoices]);

    const columns = React.useMemo(
        () => [
            {
                Header: "Date of Debit",
                accessor: "date",
                Cell: ({ row: { original } }) => {
                    return original.date ? moment(original.date).format('DD-MM-YYYY') : '-'
                },
            },

            {
                Header: "No. of Branches",
                accessor: "branches",
                Cell: ({ row: { original } }) => {
                    return original.transactionDetail?.length
                },
            },
            {
                Header: "Amount",
                accessor: "amount",
                Cell: ({ row: { original } }) => {
                    return `£${original.amount}`
                },
            },
            {
                Header: "Subscription Type",
                accessor: "planName",
                Cell: ({ row: { original } }) => {
                    return original.planName || '-'
                },
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: ({ row: { original } }) => {
                    return `${original.status[0].toUpperCase() + original.status.slice(1)}`
                },
            },
            {
                Header: "Action",
                accessor: "active",
                Cell: ({ row: { original } }) => {
                    return (
                        <img
                            onClick={() => {
                                setShowTransactionHistoryModal(true)
                                setTransactionDetails(original.transactionDetail)
                                setDebitDate(original.date)
                                setVAT(original.VAT)
                            }}
                            style={{ cursor: "pointer" }}
                            src={arrowRightIcon}
                            alt=""
                        />
                    );
                },
            },
        ],
        []
    );

    return (
        <div
            className="d-flex flex-column flex-1"
            style={{ gap: 30, height: "100%" }}
        >
            <div>
                {
                    invoicesData?.length > 0 ?
                        <TableComponet
                            isBorderShown
                            columns={columns}
                            perPageCount={invoicesData?.length}
                            data={invoicesData}
                            rowCursor="pointer"
                        />
                        :
                        <EmptyData title="Transactions" />
                }
            </div>
            <AddModal
                title="Branches"
                handleModal={showTransactionHistoryModal}
                setHandleModal={setShowTransactionHistoryModal}
                secondaryButtonHandler={() => setShowTransactionHistoryModal(false)}
                secondaryButtonText="Dismiss"
            >
                <div
                    className="d-flex flex-column align-items-start add-shift-modal-content"
                    style={{ gap: "15px", color: "#7B7B7B", paddingTop: "25px" }}
                >
                    <div className="d-flex gap-4 justify-content-between text-row border rounded" style={{ width: '100%', backgroundColor: '#f5f5f5' }}>
                        <div className="p3 primary-text" >Date of Debit</div>
                        <div className="seprator"></div>
                        <div className="p3 secondary-text" style={{ color: '#7B7B7B', minWidth: 200, textAlign: 'right' }}>{debitDate ? moment(debitDate).format('DD-MM-YYYY') : '-'}</div>
                    </div>
                    <div style={{ width: '100%' }}>
                        <div className="b1 mb-3">Branch Information</div>
                        <div className="border rounded" >
                            <div className="d-flex gap-4 justify-content-between text-row">
                                <div className="p3 primary-text">Branch</div>
                                <div className="seprator"></div>
                                <div style={{ minWidth: 200 }} className="d-flex align-items-center gap-4">
                                    <div className="p3 primary-text">Base</div>
                                    <div className="p3 primary-text">VAT</div>
                                    <div className="p3 primary-text">Gross</div>
                                </div>
                            </div>
                            {
                                transactionDetails?.map((data, index) => {
                                    const taxAmount = (data?.amount * VAT / 100).toFixed(2)
                                    const gorssAmount = Number(data?.amount) + Number(taxAmount)
                                    return (
                                        <div key={index} className="d-flex gap-4 justify-content-between text-row">
                                            <div className="p3 primary-text">{data?.branchTitle}</div>
                                            <div className="seprator"></div>
                                            <div style={{ minWidth: 200 }} className="d-flex align-items-center gap-4">
                                                <div className="p3 primary-text">{`£${data.amount}`}</div>
                                                <div className="p3 primary-text">{`£${taxAmount}`}</div>
                                                <div className="p3 secondary-text">{`£${gorssAmount.toFixed(2)}`}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </AddModal>
        </div>
    );
};
