import React from "react";

const UserTagIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M13.808 3.89967L9.84805 1.61301C9.20138 1.23967 8.40138 1.23967 7.74805 1.61301L3.79471 3.89967C3.14805 4.27301 2.74805 4.96634 2.74805 5.71967V10.2797C2.74805 11.0263 3.14805 11.7197 3.79471 12.0997L7.75471 14.3863C8.40138 14.7597 9.20138 14.7597 9.85471 14.3863L13.8147 12.0997C14.4614 11.7263 14.8614 11.033 14.8614 10.2797V5.71967C14.8547 4.96634 14.4547 4.27967 13.808 3.89967ZM8.80138 4.89301C9.66138 4.89301 10.3547 5.58634 10.3547 6.44634C10.3547 7.30634 9.66138 7.99967 8.80138 7.99967C7.94138 7.99967 7.24805 7.30634 7.24805 6.44634C7.24805 5.59301 7.94138 4.89301 8.80138 4.89301ZM10.588 11.1063H7.01471C6.47471 11.1063 6.16138 10.5063 6.46138 10.0597C6.91471 9.38634 7.79471 8.93301 8.80138 8.93301C9.80805 8.93301 10.688 9.38634 11.1414 10.0597C11.4414 10.4997 11.1214 11.1063 10.588 11.1063Z"
        fill="#162B65"
      />
    </svg>
  );
};

export default UserTagIcon;
