import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Outlet, useNavigate } from "react-router-dom";
import { Heading } from "../../../../components/UI/heading";
import { TableComponet } from "../../../../components/UI/table";
import { TableToolbar } from "../../../../components/UI/table/tableToolBar";
import {
  getLocumShiftsListThunk,
  ratingShiftThunk,
} from "../../../../redux/locum-shifts/locumShiftsThunk";
import { RouterConfig } from "../../../../routerConfig";
import "./style.scss";
import startRatingIcon from "../../../../assets/icons/star-rating.png";
import disabledStar from "../../../../assets/icons/disabledStar.png";
import { Rate } from "antd";
import downladIcon from "../../../../assets/icons/download.png";
import { DownloadJobsFileModal } from "../../../../components/locum-shifts/downloadFileModal";
import { LocumShiftsFilterComponent } from "../../../../components/locum-shifts/filter";
import { hasAccess } from "../../../../utils/helper";
import {
  inviteApiKeys,
  locumApiKeys,
  locumShiftApiKeys,
} from "../../../../utils/apiKeyConstants";

import { ConfirmModal } from "../../../../components/UI/modals/confirmModal";
import { getRegionsBranchesListThunk } from "../../../../redux/regions/regionsThunk";
import moment from "moment";
import { EmptyData } from "../../../../components/comon/EmptyData";

const JobStatuses = {
  active: "Active",
  unfilled: "Unfilled",
  pending: "Pending",
  cancelled: "Cancelled",
  paid: "Paid",
  payment_approved: "Payment Approved",
  completed: "Completed",
};

export const LocumShiftListPage = () => {
  const [rating, setRating] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const { locumShifts } = useSelector((state) => state.locumShifts);
  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);
  const [staffTableView, setStaffTableView] = useState("all");
  const [showDownloadFileModal, setShowDownloadFileModal] = useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [ratingId, setRatingId] = useState();
  const [ratingValue, setRatingValue] = useState();
  const [rerender, setRerender] = useState(false);
  const [filterValue, setFilterValue] = useState();

  const columns = React.useMemo(
    () => [
      {
        Header: "Dates",
        accessor: "startDate",
        Cell: ({ row: { original } }) => {
          return moment(original.startDate).format("DD-MM-YYYY");
        },
      },
      {
        Header: "Branch",
        accessor: "branchTitle",
      },
      {
        Header: "Total Rate",
        accessor: "total",
        Cell: ({ row: { original } }) => {
          return `£${original.total}`;
        },
      },

      {
        Header: "Locum",
        accessor: "locumName",
        Cell: ({ row: { original } }) => {
          return original.locumName ?? "-";
        },
      },
      {
        Header: "Job Type",
        accessor: "roleType.label",
      },
      {
        Header: "Status",
        accessor: "status",

        Cell: ({ row: { original } }) => {
          return (
            <>
              {original.status === "pending" ||
                original.status === "unfilled" ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#FEFDE9", color: "#EE872D" }}
                  >
                    {JobStatuses[original.status]}
                  </span>
                </div>
              ) : original.status === "active" ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#E8F5E9", color: "#1B5E1F" }}
                  >
                    {JobStatuses[original.status]}
                  </span>
                </div>
              ) : original.status === "cancelled" ? (
                <span
                  className="px-4 rounded-pill py-2"
                  style={{ backgroundColor: "#FFEBEE", color: "#EF5350" }}
                >
                  {JobStatuses[original.status]}
                </span>
              ) : (
                <span
                  className="px-4 rounded-pill py-2"
                  style={{ backgroundColor: "#E3F2FD", color: "#1045A1" }}
                >
                  {JobStatuses[original.status]}
                </span>
              )}
            </>
          );
        },
      },
      {
        Header: "Rating",
        accessor: "ratingValue",
        Cell: ({ row: { original } }) => {
          return (
            <div
              style={{
                width: "fit-content",
                gap: "3px",
                backgroundColor: "white",
                // cursor: original.status === "paid" ? "pointer" : "auto",
              }}
              className="px-3 lh-1 rounded-pill py-2 border d-flex align-items-center "
              onClick={(e) => {
                if (hasAccess(locumApiKeys.CREATE_UPDATE_RATING)) {
                  handleRatingsBtn(
                    e,
                    original.status,
                    original.id,
                    original.ratingValue
                  );
                }
              }}
            >
              <img
                src={
                  original.status === "paid" ? startRatingIcon : disabledStar
                }
                alt="disabledStar"
              />
              {Number(original?.ratingValue).toFixed(1) ?? 0.0}
            </div>
          );
        },
      },
    ],
    []
  );
  const handleRatingsBtn = (e, status, id, ratingVal) => {
    e.stopPropagation();
    if (status === "paid") {
      setRatingId(id);
      setRatingValue(ratingVal);
      setShowRatingModal(true);
    }
    return;
  };
  const {
    count: totalDataCount,
    page,
    pages: totalPagesCount,
    perPage,
    rows: locumShiftsList,
  } = useMemo(() => locumShifts, [locumShifts]);

  useEffect(() => {
    if (!page && !perPage) return;
    setPerPageCount(perPage);
    setCurrentPageCount(page);
  }, [page, perPage]);

  useEffect(() => {
    if (hasAccess(inviteApiKeys.GET_SHORT_BRANCHES))
      dispatch(getRegionsBranchesListThunk());
  }, [dispatch])

  useEffect(() => {
    setRerender(true)
  }, [currentPageCount, perPageCount, setRerender])

  useEffect(() => {
    if (searchQuery !== '') return
    setRerender(true)
  }, [searchQuery, setRerender])

  useEffect(() => {
    if (!(rerender)) return;
    dispatch(
      getLocumShiftsListThunk({
        filters: {
          ...filterValue,
          branchTitles: [searchQuery.trim()],
          tab: staffTableView,
        },
        pagination: { page: currentPageCount, perPage: perPageCount },
      })
    )
    return setRerender(false);
  }, [currentPageCount, dispatch, filterValue, perPageCount, rerender, searchQuery, staffTableView]);

  const tabsOptions = [
    {
      label: "All Jobs",
      value: "all",
      onClickHandler: () => {
        setPerPageCount(10);
        setRerender(true)
        setCurrentPageCount(1);
        setStaffTableView("all");
      },
    },
    {
      label: "Upcoming",
      value: "upcoming",
      onClickHandler: () => {
        setRerender(true)
        setPerPageCount(10);
        setCurrentPageCount(1);
        setStaffTableView("upcoming");
      },
    },
    {
      label: "Past",
      value: "past",
      onClickHandler: () => {
        setRerender(true)
        setPerPageCount(10);
        setCurrentPageCount(1);
        setStaffTableView("past");
      },
    },
  ];

  const formatTrProps = ({ original }) => {
    return {
      onClick: () =>
        navigate(
          generatePath(RouterConfig.branchAdminJobPreview, {
            shiftId: original.id,
          })
        ),
    };
  };

  const handleRatings = () => {
    dispatch(
      ratingShiftThunk({
        jobId: ratingId,
        value: rating,
      })
    ).then((res) => {
      dispatch(
        getLocumShiftsListThunk({
          filters: {
            tab: staffTableView,
          },
          pagination: { page: currentPageCount, perPage: perPageCount },
        })
      );
    });
    setShowRatingModal(false);
  };

  const onSearch = (e) => {
    setRerender(true)
  };

  return (
    <div>
      {showRatingModal && (
        <ConfirmModal
          title="Rate Locum"
          description="Give rating to locum according to your recent experince."
          open={showRatingModal}
          confirmButtonText="Submit"
          cancelButtonText="Cancel"
          onClose={() => setShowRatingModal(!showRatingModal)}
          onConfirm={handleRatings}
        >
          <Rate
            defaultValue={ratingValue}
            onChange={(val) => setRating(val)}
            style={{ fontSize: 70 }}
          />
        </ConfirmModal>
      )}
      <Heading label="Locum Jobs" />
      <div className="mt-3">
        <TableToolbar
          searchPlaceholder="Branch Name"
          primaryIcon={<img src={downladIcon} alt="downladIcon" />}
          primaryBtnText={
            hasAccess(locumShiftApiKeys.DOWNLOAD_CSV) ? "Download CSV" : ""
          }
          FilterComponent={
            <LocumShiftsFilterComponent
              onApplyFilter={(values) => {
                setFilterValue(values)
                setRerender(true)
              }} />
          }
          filterButton
          showSearchField
          staffTableView={staffTableView}
          tabsOptions={tabsOptions}
          onSearch={onSearch}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          primaryBtnHandler={() =>
            setShowDownloadFileModal(!showDownloadFileModal)
          }
        />
        <div style={{ padding: "0px 24px" }}>
          {locumShifts?.rows?.length > 0 ? (
            <TableComponet
              rowCursor="pointer"
              isBorderShown
              currentPageCount={currentPageCount}
              perPageCount={perPageCount}
              totalDataCount={totalDataCount}
              setCurrentPage={setCurrentPageCount}
              setPerPageCount={setPerPageCount}
              showPagination
              maxPageCount={totalPagesCount}
              formatRowProps={
                hasAccess(locumShiftApiKeys.GET_JOB_BY_ID) ? formatTrProps : ""
              }
              columns={columns}
              data={locumShiftsList}
            />
          ) : (
            <div style={{ border: "1px solid #E9E9E9", paddingTop: "80px" }}>
              <EmptyData title="Locum Jobs" />
            </div>
          )}
        </div>
      </div>
      <Outlet />
      <DownloadJobsFileModal
        showModal={showDownloadFileModal}
        handleModalToggle={() =>
          setShowDownloadFileModal(!showDownloadFileModal)
        }
      />
    </div>
  );
};
