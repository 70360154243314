import { Avatar, Checkbox, Empty, Form, Input } from "antd";
import React, { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import SearchIcon from "../../../assets/icons/Search.svg";
import { showLabel } from "../../../utils/timeshhet";

export const DropdownSubMenuBranch = ({
  selectedItems,
  handleSelect,
  keyName,
}) => {
  const { branches } = useSelector((state) => state.branches);
  const [inputvalue, setinputvalue] = useState("");

  const handleInputValue = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setinputvalue(e.target.value);
  };

  const inputRef = useRef();
  const _filter = useMemo(() => {
    const temp = branches?.rows?.filter((item) =>
      item?.title?.toLowerCase()?.includes(inputvalue?.toLowerCase())
    );

    return temp;
  }, [JSON.stringify(branches), inputvalue, JSON.stringify(selectedItems)]);

  return (
    <div className="leave_dropdown">
      <div className="dropdown_input">
        <Form>
          <Form.Item>
            <Input
              id="form"
              placeholder="Search By Branch Name"
              type="text"
              value={inputvalue}
              ref={inputRef}
              onChange={handleInputValue}
              onFocus={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              // autoFocus
              prefix={<img src={SearchIcon} alt="search" />}
            />
          </Form.Item>
        </Form>
      </div>
      <div className="dropdownItems">
        <ul className="pharma_options_wrapper">
          {_filter?.length ? (
            _filter?.map((item) => (
              <li
                className={`d-flex align-items-center cursor-pointer justify-content-between 
               
                
                `}
                key={item?.id}
                onClick={() => handleSelect(keyName, item?.id)}
              >
                <div className="pharma_info">
                  <i>
                    {!!item?.logo ? (
                      <Avatar src={item?.logo} alt={item?.title} />
                    ) : (
                      <Avatar>{item?.title?.charAt(0).toUpperCase()}</Avatar>
                    )}
                  </i>
                  <p>{item?.title}</p>
                </div>

                <Checkbox
                  checked={selectedItems?.includes(item?.id)}
                  value={item?.id}
                  // onChange={() => handleOnChange(item)}
                />
              </li>
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </ul>
      </div>
    </div>
  );
};

export const DropdownSubMenuRole = ({
  onclose,
  selectedItems,
  handleSelect,
  keyName,
}) => {
  const { roles } = useSelector((state) => {
    return state.schedule;
  });

  const [inputvalue, setinputvalue] = useState("");

  const handleInputValue = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setinputvalue(e.target.value);
  };
  const inputRef = useRef();
  const _filter = useMemo(() => {
    const temp = roles?.filter((item) =>
      item?.label?.toLowerCase()?.includes(inputvalue?.toLowerCase())
    );

    return temp;
  }, [JSON.stringify(roles), inputvalue, JSON.stringify(selectedItems)]);

  return (
    <div className="leave_dropdown">
      <div className="dropdown_input">
        <Form>
          <Form.Item>
            <Input
              id="form"
              placeholder="Search"
              type="text"
              value={inputvalue}
              ref={inputRef}
              onChange={handleInputValue}
              onFocus={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              // autoFocus
              prefix={<img src={SearchIcon} alt="search" />}
            />
          </Form.Item>
        </Form>
      </div>
      <div className="dropdownItems">
        <ul className="pharma_options_wrapper">
          {" "}
          {_filter?.length ? (
            _filter?.map((item) => (
              <li
                className={`d-flex align-items-center cursor-pointer justify-content-between 
               
                
                `}
                key={item?.id}
                onClick={() => handleSelect(keyName, item?.id)}
              >
                <div className="pharma_info">
                  <p className="text-capitalize">{showLabel(item?.label)}</p>
                </div>

                <Checkbox
                  checked={selectedItems?.includes(item?.id)}
                  value={item?.id}
                  // onChange={() => handleOnChange(item)}
                />
              </li>
            ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </ul>
      </div>
    </div>
  );
};

export const DropdownSubMenuRegion = ({
  selectedItems,
  handleSelect,
  keyName,
}) => {
  const { regionsListDropDown } = useSelector((state) => {
    return state.regions;
  });

  const [inputvalue, setinputvalue] = useState("");

  const handleInputValue = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setinputvalue(e.target.value);
  };
  const inputRef = useRef();

  const renderList = useMemo(() => {
    const _filter = regionsListDropDown?.filter((item) =>
      item?.title?.toLowerCase()?.includes(inputvalue?.toLowerCase())
    );

    return (
      <>
        {_filter?.length ? (
          _filter?.map((item) => (
            <li
              className={`d-flex align-items-center cursor-pointer justify-content-between ${
                selectedItems?.includes(item.id) ? "activeLi" : ""
              }`}
              onClick={() => handleSelect(keyName, item?.id)}
            >
              <div className="pharma_info">
                <p>{item?.title}</p>
              </div>
            </li>
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </>
    );
  }, [
    JSON.stringify(regionsListDropDown),
    inputvalue,
    JSON.stringify(selectedItems),
  ]);

  return (
    <div className="leave_dropdown">
      <div className="dropdown_input">
        <Form>
          <Form.Item>
            <Input
              id="form"
              placeholder="Search"
              type="text"
              value={inputvalue}
              ref={inputRef}
              onChange={handleInputValue}
              onFocus={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              // autoFocus
              prefix={<img src={SearchIcon} alt="search" />}
            />
          </Form.Item>
        </Form>
      </div>
      <div className="dropdownItems">
        <ul className="pharma_options_wrapper">{renderList}</ul>
      </div>
    </div>
  );
};
