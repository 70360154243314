import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Popover,
  Row,
  Select,
  Space,
} from "antd";
import React from "react";
import SearchIcon from "../../assets/icons/Search.svg";
import PharmaImg from "../../assets/img/Pharma1.jpeg";
import CustomIconButton from "../UI/IconButton/IconButton";
import ArrowRight from "../UI/icons/ArrowRight";
import DropdownIcon from "../UI/icons/DropdownIcon";
import GreenCrossicon from "../UI/icons/GreenCrossicon";

const BranchesListEditModal = () => {
  const PopoverContent = () => {
    return (
      <Space align="center" direction="vertical" className="propover-content">
        <Input prefix={<img src={SearchIcon} alt="search" />} />

        <ul className="pharma_options_wrapper">
          <li
            className={`d-flex align-items-center cursor-pointer justify-content-between pe-2`}
          >
            <p>Select all</p>
            <Checkbox

            //   checked={selectedItems?.includes(item?.id)}
            //   value={item?.id}
            // onChange={() => handleOnChange(item)}
            />
          </li>
          <li
            className={`d-flex align-items-center cursor-pointer justify-content-between pe-2 `}

            // onClick={() => handleSelect(keyName, item?.id)}
          >
            <div className="pharma_info gap-2">
              {/* {showLabel(item?.label)} */}
              <figure className="pharma-img-wrap">
                <img src={PharmaImg} alt="phmarma" />
              </figure>

              <p>signature Pharmacy</p>
            </div>

            <Checkbox
            //   checked={selectedItems?.includes(item?.id)}
            //   value={item?.id}
            // onChange={() => handleOnChange(item)}
            />
          </li>
          <li
            className={`d-flex align-items-center cursor-pointer justify-content-between pe-2 `}
            key={1}
            // onClick={() => handleSelect(keyName, item?.id)}
          >
            <div className="pharma_info gap-2">
              {/* {showLabel(item?.label)} */}
              <figure className="pharma-img-wrap">
                <img src={PharmaImg} alt="phmarma" />
              </figure>

              <p>signature Pharmacy</p>
            </div>

            <Checkbox
            //   checked={selectedItems?.includes(item?.id)}
            //   value={item?.id}
            // onChange={() => handleOnChange(item)}
            />
          </li>
          <li
            className={`d-flex align-items-center cursor-pointer justify-content-between pe-2 `}
            key={1}
            // onClick={() => handleSelect(keyName, item?.id)}
          >
            <div className="pharma_info gap-2">
              {/* {showLabel(item?.label)} */}
              <figure className="pharma-img-wrap">
                <img src={PharmaImg} alt="phmarma" />
              </figure>

              <p>signature Pharmacy</p>
            </div>

            <Checkbox
            //   checked={selectedItems?.includes(item?.id)}
            //   value={item?.id}
            // onChange={() => handleOnChange(item)}
            />
          </li>
          <li
            className={`d-flex align-items-center cursor-pointer justify-content-between pe-2`}
            key={1}
            // onClick={() => handleSelect(keyName, item?.id)}
          >
            <div className="pharma_info gap-2">
              {/* {showLabel(item?.label)} */}
              <figure className="pharma-img-wrap">
                <img src={PharmaImg} alt="phmarma" />
              </figure>

              <p>signature Pharmacy</p>
            </div>

            <Checkbox
            //   checked={selectedItems?.includes(item?.id)}
            //   value={item?.id}
            // onChange={() => handleOnChange(item)}
            />
          </li>
        </ul>
      </Space>
    );
  };
  const options = [
    {
      value: "1",
      label: "East Midland",
    },
    {
      value: "2",
      label: "East of England",
    },
    {
      value: "3",
      label: "Greater London",
    },
    {
      value: "4",
      label: "North East",
    },
    {
      value: "5",
      label: "North West",
    },
  ];

  return (
    <div>
      <Row gutter={[16, 16]} className="info-modal-row">
        <Col span={24}>
          <Form.Item label="Region" name="region">
            <Select
              mode="multiple"
              size="large"
              placeholder="Please select"
              style={{ width: "100%" }}
             
              dropdownRender={(menu) => (
                <Space direction="vertical" className="dropdown-render">
                  <Button className="clear_all_btn" icon={<GreenCrossicon />}>
                    Clear all
                  </Button>
                  {options.map((item) => (
                    <Popover
                      content={PopoverContent}
                      trigger="click"
                      placement="bottomRight"
                      showArrow={false}
                    >
                      <Space className="dropdown_item">
                        <p>{item.label}</p>
                        <CustomIconButton icon={<ArrowRight />} />
                      </Space>
                    </Popover>
                  ))}
                </Space>
              )}
              options={options}
              suffixIcon={<DropdownIcon />}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default BranchesListEditModal;
