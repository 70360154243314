import { createSlice } from "@reduxjs/toolkit";
import { THUNK_STATUS } from "../../../utils/constant";
import eventEmitter, { events } from "../../../utils/eventEmitter";
import {
  addContractThunk,
  applyManualAttendanceThunk,
  deleteLeaveThunk,
  editContractThunk,
  editStaffProfileThunk,
  editVerificationThunk,
  gapApplicationActionThunk,
  getGphcInfoThunk,
  getStaffAbsencesThunk,
  getStaffAccrediationsThunk,
  getStaffContractListThunk,
  getStaffContractThunk,
  getStaffDataThunk,
  getStaffGapsThunk,
  getStaffLeavesListThunk,
  getStaffRelatedDataThunk,
  getStaffScheduleThunk,
  getStaffsListThunk,
  getStaffTimeSheetThunk,
  getStaffVerificationThunk,
  getUsersActiveContractThunk,
  staffStatusChangeThunk,
} from "../staffsThunk";

const staffRelatedData = {
  branches: [],
  regions: [],
  roles: [],
};

const initialState = {
  staffs: [],
  staffContractsList: [],
  staffContract: {},
  staffTimeSheet: {},
  staffInfo: {},
  staffAccrediations: [],
  staffAbsences: [],
  staffVerification: {},
  staffRelatedData,
  staffGaps: {},
  gphcInfo: {},
  staffSchedule: [],
  activeContract: {},
  staffLeaves: [],
  staffTimeSheetEdited: [],
  currentEditItemForShiftOrJob: {},
  staffInfoStatus: THUNK_STATUS.idle,
};

const staffsSlicer = createSlice({
  name: "staffsSlicer",
  initialState: initialState,
  reducers: {
    setstaffTimeSheetEdited: (state, { payload }) => {
      state.staffTimeSheetEdited = payload;
    },
    setStaffTimeSheetEditedUsingCases: (state, { payload }) => {
      const prevData = [...state.staffTimeSheetEdited];

      const findRecordIndex = prevData?.findIndex(
        (item) => item?.recordId === payload?.recordId
      );

      if (findRecordIndex > -1) {
        const temp = prevData;
        const mutationType = payload.mutationType;
        if ("mutationType" in payload) {
          delete payload.mutationType;
        }

        switch (mutationType) {
          case "updateFields":
            temp[findRecordIndex] = {
              ...temp[findRecordIndex],
              ...payload,
            };
            break;
          case "logError":
            temp[findRecordIndex] = {
              ...temp[findRecordIndex],
              errors: {
                ...temp[findRecordIndex]?.errors,
                ...payload,
              },
            };
            break;
          default:
            // Handle other cases if needed
            break;
        }
        state.staffTimeSheetEdited = temp;
      }
    },
    setcurrentEditItemForShiftOrJob: (state, { payload }) => {
      state.currentEditItemForShiftOrJob = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStaffsListThunk.fulfilled, (state, action) => {
        state.staffs = action.payload;
      })
      .addCase(getStaffRelatedDataThunk.fulfilled, (state, action) => {
        state.staffRelatedData = action.payload;
      })
      .addCase(staffStatusChangeThunk.fulfilled, (state, action) => {
        if (action.payload.status === "success") {
          state.staffInfo.status = action.payload.data.status;
        }
      })
      .addCase(getStaffDataThunk.pending, (state, action) => {
        state.staffInfoStatus = THUNK_STATUS.loading;
      })
      .addCase(getStaffDataThunk.fulfilled, (state, action) => {
        state.staffInfo = action.payload;
        state.staffInfoStatus = THUNK_STATUS.idle;
      })
      .addCase(getStaffAccrediationsThunk.fulfilled, (state, action) => {
        state.staffAccrediations = action.payload;
      })
      .addCase(getStaffAbsencesThunk.fulfilled, (state, action) => {
        state.staffAbsences = action.payload;
      })
      .addCase(editStaffProfileThunk.fulfilled, (state, action) => {
        state.staffInfo = action.payload;
      })
      .addCase(getStaffContractListThunk.fulfilled, (state, action) => {
        state.staffContractsList = action.payload;
      })
      .addCase(addContractThunk.fulfilled, (state, action) => {
        state.staffContractsList.rows.push(action.payload);
      })
      .addCase(getStaffContractThunk.fulfilled, (state, action) => {
        state.staffContract = action.payload;
      })
      .addCase(editContractThunk.fulfilled, (state, action) => {
        state.staffContract = action.payload;
      })
      .addCase(getStaffTimeSheetThunk.fulfilled, (state, action) => {
        state.staffTimeSheet = action.payload;
      })
      .addCase(applyManualAttendanceThunk.fulfilled, (state, action) => {
        const index = state.staffTimeSheet?.attendance.rows.findIndex(
          (list) => list.id === action.payload.id
        );
        state.staffTimeSheet.attendance.rows[index] = action.payload;
      })
      .addCase(getStaffVerificationThunk.fulfilled, (state, action) => {
        state.staffVerification = action.payload;
      })
      .addCase(editVerificationThunk.fulfilled, (state, action) => {
        state.staffVerification = action.payload;
      })
      .addCase(getStaffGapsThunk.fulfilled, (state, action) => {
        state.staffGaps = action.payload;
      })
      .addCase(gapApplicationActionThunk.fulfilled, (state, action) => {
        state.staffGaps = action.payload;
      })
      .addCase(getGphcInfoThunk.fulfilled, (state, action) => {
        state.gphcInfo = action.payload;
      })
      .addCase(getStaffScheduleThunk.fulfilled, (state, action) => {
        state.staffSchedule = action.payload;
      })
      .addCase(getUsersActiveContractThunk.fulfilled, (state, action) => {
        state.activeContract = action.payload;
      })

      // for leaves
      .addCase(getStaffLeavesListThunk.fulfilled, (state, { payload }) => {
        state.staffLeaves = payload;
      })
      .addCase(deleteLeaveThunk.fulfilled, (state, { payload }) => {
        eventEmitter.emit(events.fetchLeave, true);
      });
  },
});

export const staffsReducer = staffsSlicer;
export const {
  setstaffTimeSheetEdited,
  setStaffTimeSheetEditedUsingCases,
  setcurrentEditItemForShiftOrJob,
} = staffsSlicer.actions;
