import { UploadOutlined } from "@ant-design/icons";
import { Button, Checkbox, DatePicker, Form, Input, Upload } from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccreditionModal } from "../../../../components/UI/modals/accreditionModdal";
import {
  applyRequiredAccreditationsThunk,
} from "../../../../redux/locum-flow/Thunk";
import { beforeUpload, compressImage, notify } from "../../../../utils/helper";

export const AdditionalAccrediationForm = ({
    showAccreditionModal,
    setAccreditionModal,
    shiftInfo,
    setAPIFlag,
}) => {

    const formIdAccreditions = useMemo(() => "form", []);
    const [formAccreditions] = Form.useForm();
    const [additionalAccrediation, setAdditionalAccrediation] = useState()
    const {
        user,
    } = useSelector((state) => state.auth);

    const dispatch = useDispatch()

    useEffect(() => {
        if (!shiftInfo?.accreditationRules) return
        const data = shiftInfo?.accreditationRules.filter(i => (i.roleId === user.roleId || !i.roleId)).map((item) => {
            const d = shiftInfo?.locumAccreditations?.find((ot) => ot.accreditationRuleId === item.id);
            return ({
                ...item,
                answer: item.isDocument === 0 ? d?.answer : d?.answer ? {
                    fileList: [
                        {
                            uid: Math.random(),
                            name: `${user.name}_${item.title}`,
                            status: d?.answer ? 'done' : '',
                            url: d?.answer
                        }
                    ]
                } : undefined,
                expiryDate: d?.expiryDate ? dayjs(d?.expiryDate).format('YYYY-MM-DD') : null
            });
        });
        const obj = { ...data }
        setAdditionalAccrediation(obj)
    }, [formAccreditions, shiftInfo?.accreditationRules, shiftInfo?.locumAccreditations, user])


    const onSubmit = (values) => {
        const arrayOfObj = Object.entries(values).map((e) => ({ [e[0]]: e[1] }));
        const array = arrayOfObj.map((data) => {
            for (let key in data) {
                return ({
                    accreditationRuleId: data[key]?.id,
                    answer: data[key]?.answer,
                    expiryDate: data[key]?.expiryDate
                })
            }
        }
        ).filter(i => i.accreditationRuleId)
        const data = shiftInfo?.accreditationRules.filter(i => (i.roleId === user.roleId || !i.roleId)).map((item) => {
            const d = array?.find((ot) => ot.accreditationRuleId === item.id);
            return ({
                accreditationRuleId: d.accreditationRuleId,
                isDocument: item.isDocument,
                isMandatory: item.isMandatory,
                isExpiryRequired: item.isExpiryRequired,
                expiryDate: item.isExpiryRequired === 0 ? undefined : dayjs(d.expiryDate).format('YYYY-MM-DD'),
                answer: d.answer
            });
        });

        const formData = new FormData()
        for (let key in data) {
            if (data[key].isDocument === 1 && data[key].answer.length && data[key].answer[0].url) {
                // formData.append(`${data[key].answer[0].name}`, data[key].answer[0].url)
            } else if (data[key].isDocument === 1 && data[key].answer.length) {
                formData.append(`${data[key].answer[0].name}`, data[key].answer[0].originFileObj)
            }
        }
        const removedAnswerData = data.map((d) => {
            if (d.isDocument === 1 && d.answer) {
                d.filename = d.answer[0].name
                delete d['answer']
            }
            return d
        })
        const questions = [...removedAnswerData]
        formData.append('request', JSON.stringify({ questions }))

        dispatch(
            applyRequiredAccreditationsThunk({ id: shiftInfo?.id, formData })
        )
            .then(res => {
                if (res.payload === 'success') {
                    setAccreditionModal(false)
                    formAccreditions.resetFields()
                    setAPIFlag(true)
                }
            });
    }

    const onMediChange = (val, id, name) => {
        const data = { ...additionalAccrediation }
        Object.keys(data).map((milestone) => {
            if (data[milestone].id === id && name === 'answer') {
                data[milestone].answer = val
            }
            else if (data[milestone].id === id && name === 'date') {
                data[milestone].expiryDate = dayjs(val).format('YYYY-MM-DD')

            }
        })
        setAdditionalAccrediation(data)
    }

    return (
        <AccreditionModal
            title="Job Requirements 📄"
            description="Please fill the following requirements to apply for this Job."
            open={showAccreditionModal}
            formId={formIdAccreditions}
            onClose={() => setAccreditionModal(false)}
            confirmButtonText="Apply"
        >
            <Form
                form={formAccreditions}
                id={formIdAccreditions}
                layout="vertical"
                autoComplete="off"
                onFinish={onSubmit}
            >
                <div
                    style={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#000000",
                        marginTop: "30px",
                        marginBottom: "10px",
                    }}
                >
                    Accreditations Required
                </div>
                {additionalAccrediation &&
                    Object.keys(additionalAccrediation).map((accreditation) => {
                        formAccreditions.setFieldValue([additionalAccrediation[accreditation].id, 'answer'], additionalAccrediation[accreditation]?.answer?.fileList ? additionalAccrediation[accreditation]?.answer?.fileList : additionalAccrediation[accreditation]?.answer)
                        formAccreditions.setFieldValue([additionalAccrediation[accreditation].id, 'title'], additionalAccrediation[accreditation].title)
                        formAccreditions.setFieldValue([additionalAccrediation[accreditation].id, 'expiryDate'], additionalAccrediation[accreditation].expiryDate ? dayjs(additionalAccrediation[accreditation].expiryDate) : null)
                        formAccreditions.setFieldValue([additionalAccrediation[accreditation].id, 'id'], additionalAccrediation[accreditation].id)

                        return (
                            <Form.Item>
                                <Form.Item
                                    hidden
                                    label={additionalAccrediation[accreditation]?.title}
                                    name={[additionalAccrediation[accreditation]?.id, "title"]}
                                >
                                    <Input
                                        maxLength={100}
                                        className="text-input-field p-2 pe-3 t1"
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    hidden
                                    label={additionalAccrediation[accreditation]?.id}
                                    name={[additionalAccrediation[accreditation]?.id, "id"]}
                                >
                                    <Input
                                        className="text-input-field p-2 pe-3 t1"
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                                {additionalAccrediation[accreditation]?.isExpiryRequired === 1 &&
                                    additionalAccrediation[accreditation]?.isDocument && (
                                        <Form.Item
                                            label={additionalAccrediation[accreditation]?.title}
                                            name={[additionalAccrediation[accreditation]?.id, "expiryDate"]}
                                            rules={[
                                                {
                                                    required:
                                                        additionalAccrediation[accreditation]?.isMandatory === 1 &&
                                                            !additionalAccrediation[accreditation]?.expiryDate
                                                            ? true
                                                            : false,
                                                    message: "Please select expiry date!",
                                                },
                                            ]}
                                        >
                                            <DatePicker
                                                allowClear={false}
                                                inputReadOnly onChange={e => onMediChange(e, additionalAccrediation[accreditation].id, 'date')}
                                                format="DD-MM-YYYY"
                                                getPopupContainer={(trigger) => trigger.parentElement}
                                                className="text-input-field p-2 pe-3 t1"
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    )}
                                <Form.Item
                                    label={additionalAccrediation[accreditation]?.title}
                                    name={[additionalAccrediation[accreditation]?.id, "answer"]}
                                    rules={[
                                        {
                                            required:
                                                additionalAccrediation[accreditation]?.isMandatory === 1,
                                            message: `${additionalAccrediation[accreditation]?.title} is required`,
                                        },
                                    ]}
                                >
                                    {additionalAccrediation[accreditation]?.isDocument === 1 ? (
                                        <Upload
                                            accept={'image/*, .doc, .pdf'}
                                            fileList={additionalAccrediation[accreditation]?.answer?.fileList}
                                            beforeUpload={beforeUpload}
                                            onChange={async ({ file, fileList }) => {
                                                try {
                                                    const compressedFile = await compressImage(file);
                                                    onMediChange({ file: compressedFile, fileList }, additionalAccrediation[accreditation].id, 'answer');
                                                } catch (error) {
                                                    notify('error', 'Error compressing the image:');
                                                }
                                            }}
                                            multiple={false}
                                            showUploadList={{
                                                showPreviewIcon: false,
                                            }}
                                            maxCount={1}
                                            listType="picture"
                                        >
                                            {
                                                (!additionalAccrediation[accreditation]?.answer?.file?.status === 'removed' || !additionalAccrediation[accreditation]?.answer?.fileList.length) &&
                                                <Button
                                                    style={{
                                                        width: "100%",
                                                        height: "40px",
                                                        color: "#51C2BC",
                                                        border: "2px dashed #51C2BC",
                                                        backgroundColor: "#F0F9F9",
                                                    }}
                                                    icon={<UploadOutlined />}
                                                >
                                                    Upload Document
                                                </Button>
                                            }
                                        </Upload>
                                    ) : (
                                        <Input
                                            maxLength={100}

                                            onChange={e => onMediChange(e.target.value, additionalAccrediation[accreditation].id, 'answer')}
                                            placeholder={additionalAccrediation[accreditation]?.title}
                                            className="text-input-field p-2 pe-3 t1"
                                        />
                                    )}
                                </Form.Item>
                            </Form.Item>
                        );
                    })}
                {shiftInfo?.isRequired === 1 && (
                    <>
                        <div
                            style={{
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#000000",
                            }}
                        >
                            Terms & Conditions
                        </div>

                        <div>
                            <Form.Item
                                name="termsAndCondition"
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Agree Terms and Conditions",
                                    },
                                ]}
                            >
                                <div
                                    style={{
                                        fontWeight: 400,
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        color: "#7B7B7B",
                                        backgroundColor: "#F9F9F9",
                                        padding: "16px",
                                        marginTop: "10px",
                                    }}
                                >
                                    {shiftInfo?.termConditions}
                                    <br/>

                                    <Checkbox
                                        style={{
                                            fontWeight: 400,
                                            fontSize: "14px",
                                            lineHeight: "20px",
                                            color: "#7B7B7B",
                                            marginTop: 20,
                                        }}
                                    >
                                        {" "}
                                        I agree to the Terms & Conditions
                                    </Checkbox>
                                </div>
                            </Form.Item>
                        </div>
                    </>
                )}
            </Form>
        </AccreditionModal>
    )
}