import React from "react";
import warningIcon from "../../../assets/icons/warningIcon.png";

export const WarningBar = ({
    children
}) => {
    return (
        <div className="main-banner d-flex align-items-center gap-2">
            <img src={warningIcon} width={15} alt="" />
            {children}
        </div>
    );
};
