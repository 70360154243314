import React from "react";

const YellowClock = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M10.6654 1.66797C6.0737 1.66797 2.33203 5.40964 2.33203 10.0013C2.33203 14.593 6.0737 18.3346 10.6654 18.3346C15.257 18.3346 18.9987 14.593 18.9987 10.0013C18.9987 5.40964 15.257 1.66797 10.6654 1.66797ZM14.2904 12.9763C14.1737 13.1763 13.9654 13.2846 13.7487 13.2846C13.6404 13.2846 13.532 13.2596 13.432 13.193L10.8487 11.6513C10.207 11.268 9.73203 10.4263 9.73203 9.68464V6.26797C9.73203 5.9263 10.0154 5.64297 10.357 5.64297C10.6987 5.64297 10.982 5.9263 10.982 6.26797V9.68464C10.982 9.98464 11.232 10.4263 11.4904 10.5763L14.0737 12.118C14.3737 12.293 14.4737 12.6763 14.2904 12.9763Z"
        fill={color ? color : "#F79009"}
      />
    </svg>
  );
};

export default YellowClock;
