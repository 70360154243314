import { Avatar, Collapse, Typography } from "antd";
import React, { useMemo, useState } from "react";
import DropdownIcon from "../UI/icons/DropdownIcon";
import CommonModal from "../UI/modals/CommonModal/CommonModal";
import BranchesListEditModal from "./BranchesListEditModal";
const { Panel } = Collapse;

const BranchesList = ({ branchList }) => {
  const [showModal, setshowModal] = useState(false);
  const handleModal = () => {
    setshowModal(!showModal);
  };
  const panelStyle = {
    marginBottom: 24,
    border: "none",
  };

  const branchListmemo = useMemo(() => {
    if (branchList?.length > 0) {
      const groupedByRegion = branchList.reduce((result, branch) => {
        const key = branch.regionName !== null ? branch.regionName : "Other";
        result[key] = result[key] || [];
        result[key].push(branch);
        return result;
      }, {});

      const items = Object.entries(groupedByRegion).map(([key, branches]) => ({
        key: key,
        label: key === "Other" ? "Other Regions" : key,
        children: branches.map((branch) => ({
          key: branch?.id,
          label: branch.title,
          ...branch,
        })),
      }));

      return items;
    }

    return [];
  }, [branchList]);

  return (
    <>
      {" "}
      <div className="personal-info-wrapper">
        <div className="header">
          <Typography.Title level={4}>Branches List</Typography.Title>
          {/* <CustomIconButton icon={<EditIcon2 />} onClick={handleModal} /> */}
        </div>

        <div className="info-list-wrap text-capitalize">
          <Collapse
            bordered={false}
            defaultActiveKey={["1"]}
            expandIcon={({ isActive }) => (
              <DropdownIcon rotate={isActive ? 180 : 0} />
            )}
          >
            {branchListmemo?.map((item) => (
              <Panel header={item.label} key={item.key} style={panelStyle}>
                <ul className="pharma_options_wrapper">
                  {item?.children?.map((child) => (
                    <li
                      className={`d-flex align-items-center cursor-pointer justify-content-between pe-2 `}
                      key={child?.id}
                      // onClick={() => handleSelect(keyName, item?.id)}
                    >
                      <div className="pharma_info gap-2">
                        {/* {showLabel(item?.label)} */}
                        <figure className="pharma-img-wrap">
                          {!!child?.logo ? (
                            <Avatar
                              size="small"
                              src={child?.logo}
                              alt={child?.title}
                            />
                          ) : (
                            <Avatar size="small">{child?.title[0]}</Avatar>
                          )}
                        </figure>

                        <p className="mx-2">{child?.title}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
      <CommonModal
        handleModal={showModal}
        setHandleModal={handleModal}
        title="Edit Branches List"
        secondaryButtonText="Cancel"
        primaryButtonText="Save"
      >
        <div className="branchListModal">
          <BranchesListEditModal />
        </div>
      </CommonModal>
    </>
  );
};

export default BranchesList;
