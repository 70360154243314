import axios from "axios";
import { API } from "../../../axios";

export const getProfileData = () => {
  const request = API.get("profile/personal-info");
  return request;
};

export const editProfileData = (payload) => {
  const request = API.post("profile/personal-info", payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return request;
};

export const editProfilePass = (payload) => {
  const request = API.post("auth/password-reset", payload);
  return request;
};

export const getProfileAccrediations = (payload) => {
  const request = API.post(
    "settings/accreditation-rules/get-by-filter",
    payload
  );
  return request;
};

export const saveProfileAccrediations = (payload) => {
  const request = API.post("settings/accreditation-rules/save", payload);
  return request;
};
export const deleteProfileAccrediations = (payload) => {
  const { id } = payload;
  const request = API.delete(`settings/accreditation-rules/${id}`);
  return request;
};

export const getPharmacySettings = () => {
  const request = API.get(`settings/get-pharmacy-setting`);
  return request;
};

export const updatePharmacySettings = (payload) => {
  const request = API.post(`settings/update-pharmacy-setting`, payload);
  return request;
};

export const getMileageRules = () => {
  const request = API.get(`settings/mileage-rules/get-all`);
  return request;
};

export const saveMileageRules = (payload) => {
  const request = API.post(`settings/mileage-rules/save`, payload);
  return request;
};

export const deleteMileageRules = (payload) => {
  const { id } = payload;
  const request = API.delete(`settings/mileage-rules/${id}`);
  return request;
};

export const getParkingRules = () => {
  const request = API.get(`settings/parking-rules/get-all`);
  return request;
};

export const saveParkingRules = (payload) => {
  const request = API.post(`settings/parking-rules/save`, payload);
  return request;
};

export const deleteParkingRules = (payload) => {
  const { id } = payload;
  const request = API.delete(`settings/parking-rules/${id}`);
  return request;
};
export const getAccommodationAndFoodRules = () => {
  const request = API.get(`settings/accommodation-and-food-rules/get-all`);
  return request;
};

export const saveAccommodationAndFoodRules = (payload) => {
  const request = API.post(
    `settings/accommodation-and-food-rules/save`,
    payload
  );
  return request;
};

export const deleteAccommodationAndFoodRules = (payload) => {
  const { id } = payload;
  const request = API.delete(`settings/accommodation-and-food-rules/${id}`);
  return request;
};

export const getTransportRules = () => {
  const request = API.get(`settings/transport-rules/get-all`);
  return request;
};

export const saveTransportRules = (payload) => {
  const request = API.post(`settings/transport-rules/save`, payload);
  return request;
};

export const deleteTransportRules = (payload) => {
  const { id } = payload;
  const request = API.delete(`settings/transport-rules/${id}`);
  return request;
};

export const getBankHolidays = async () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_GOVT_UK_URL,
  });

  const res = await instance.get("/bank-holidays.json", {
    timeout: 5000,
  });

  return res;
};

export const addBankHoliday = (payload) => {
  const request = API.post(`settings/add-bank-holiday`, payload);
  return request;
};

export const getPharmacyFlows = () => {
  const request = API.get(`/module/pharmacy`);
  return request;
};

export const addNewRole = (payload) => {
  const request = API.post(`/role/add`, payload);
  return request;
};

export const addNewAdmin = (payload) => {
  const request = API.post(`/invites/add-admin`, payload);
  return request;
};

export const getAdminRoles = () => {
  const request = API.get(`/role/pharmacy/admin-staff`);
  return request;
};

export const getAdminStaff = (payload) => {
  const request = API.post(`/staff/dynamic-admin`, payload);
  return request;
};

export const getRolePermissionsById = (id) => {
  const request = API.get(`/role/${id}/permissions`);
  return request;
};

export const updateRolePermissions = (payload) => {
  const request = API.post(`/role/update`, payload);
  return request;
};

// billing

export const paymentInvoices = (payload) => {
  const request = API.post(`payments/invoices`, payload);
  return request;
};

export const paymentBranches = (payload) => {
  const request = API.post(`payments/branches`, payload);
  return request;
};

export const paymentSubscriptionPlan = () => {
  const request = API.get(`payments/subscription-standard-plan`);
  return request;
};

export const getSubscriptions = () => {
  const request = API.get(`profile/get-subscription`);
  return request;
};


export const numarkAction = (payload) => {
  const { data, id } = payload
  const request = API.post(`/branches/${id}/numark`, data);
  return request;
};

export const updateBranchSubscription = (payload) => {
  const { data } = payload
  const request = API.put(`/branches/update-subscriptions`, { branches: data });
  return request;
};

export const cancelBranchSubscription = (payload) => {
  const request = API.post(`/payments/cancel-subscription`, payload);
  return request;
};


export const updateHostedPage = (payload) => {
  const request = API.put(`/profile/update-hosted-page`, payload);
  return request;
};
