import { createSlice } from "@reduxjs/toolkit";
import { convertToUniqueLabel } from "../../../utils/timeshhet";
import {
  getRolesThunk,
  getScheduleListThunk,
  getStaffsThunk,
  searchLocumByFilterThunk,
} from "../scheduleThunk";

const initialState = {
  branches: [],
  isloading: false,
  roles: [],
  staffs: [],
  locums: [],
  roleList: [],
};

const convertToRoleList = (data) => {
  const _processedData = data.reduce((acc, item) => {
    // Extract the role from the item
    const { label } = item;

    // Determine the category based on the role
    let category = convertToUniqueLabel(label);

    if (!acc[category]) {
      // Create the category array if it doesn't exist
      acc[category] = [];
    }

    // Add the item to the appropriate category
    acc[category].push(item);

    return acc;
  }, {});

  return _processedData;
};

const scheduleSlicer = createSlice({
  name: "scheduleSlicer",
  initialState: initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getScheduleListThunk.fulfilled, (state, action) => {
        state.branches = action.payload;
      })
      .addCase(getRolesThunk.fulfilled, (state, action) => {
        state.roles = action.payload;
        state.roleList = convertToRoleList(action.payload);
      })
      .addCase(getStaffsThunk.fulfilled, (state, action) => {
        state.staffs = action.payload;
      })
      .addCase(searchLocumByFilterThunk.fulfilled, (state, action) => {
        state.locums = action.payload;
      });
  },
});

export const scheduleReducer = scheduleSlicer;
