import { Form, Input, Upload } from "antd";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { FlexRow } from "../../../../components/comon/flexRows";
import { AddModal } from "../../../../components/UI/modals/addModal";
import { ConfirmModal } from "../../../../components/UI/modals/confirmModal";
import {
  editProfileDataThunk,
  editProfilePasswordThunk,
  getProfileDataThunk,
} from "../../../../redux/profile/profileThunk";
import { beforeUpload, compressImage, getAddressFields, notify } from "../../../../utils/helper";

export const ProfileInformation = (props) => {
  const dispatch = useDispatch();
  const { profileInfo } = useSelector((state) => state.profile);
  const [showEditModal, setShowEditModal] = useState(false);

  const formId = useMemo(() => "form", []);
  const [form] = Form.useForm();
  const image = Form.useWatch("image", form);

  const [showSecurityModal, setShowSecurityModal] = useState(false);

  useEffect(() => {
    if (!profileInfo && !showEditModal) return;
    form.setFieldsValue({
      name: profileInfo?.name,
      surname: profileInfo?.surname,
      phone: Number(profileInfo?.phone),
      address1: profileInfo?.address1,
      image: profileInfo.image
        ? {
          fileList: [
            {
              uid: Math.random(),
              name: "image.png",
              status: "done",
              url: profileInfo?.image,
            },
          ],
        }
        : undefined,
      email: profileInfo?.email,
      pharmacyName: profileInfo?.pharmacyName,
      latitude: profileInfo?.latitude,
      longitude: profileInfo?.longitude,
    });
  }, [profileInfo, showEditModal]);

  const handleAddressSelect = async ({ name, address }) => {
    const results = await geocodeByAddress(address);
    let getAddressProps = {
      streetNumber: "street_number",
      streetName: "route",
      district: ["administrative_area_level_2", "political"],
    };
    const getAddressLine = Object.values(
      getAddressFields(results[0], getAddressProps)
    ).join(", ");
    const { lat, lng } = await getLatLng(results[0]);
    form.setFieldsValue({
      ...form.getFieldsValue,
      [name]: getAddressLine,
      latitude: lat,
      longitude: lng,
    });
  };

  const onEditProfile = (values) => {
    const reqBody = {
      ...values,
      image:
        !values?.image || values?.image?.file?.status === "removed"
          ? ""
          : values?.image?.file,
    };
    delete reqBody["email"];
    let formData = new FormData();
    for (let key in reqBody) {
      if (reqBody[key] instanceof Blob) {
        formData.append(key, reqBody[key], reqBody[key]?.name);
      } else {
        formData.append(key, reqBody[key]);
      }
    }
    dispatch(editProfileDataThunk(formData)).then((res) => {
      setShowEditModal(false);
      dispatch(getProfileDataThunk());

      form.resetFields();
    });
  };

  return (
    <div className="d-flex flex-column flex-1" style={{ gap: 30 }}>
      <div className="heading4 d-flex align-items-center justify-content-between">
        Personal Information
        <div
          className="s1 m-0 cursor-pointer border py-2 px-3 rounded"
          onClick={() => setShowEditModal(true)}
          style={{ color: "white", backgroundColor: "#51C2BC" }}
        >
          EDIT
        </div>
      </div>
      <div className="border rounded">
        <div className="text-row d-flex align-items-center justify-content-between">
          <div className="s1 m-0">Details</div>
        </div>
        <FlexRow
          primaryTitle="First Name"
          primaryDescription={profileInfo?.name}
        />
        <FlexRow
          primaryTitle="Last Name"
          primaryDescription={profileInfo?.surname || "-"}
        />
        <FlexRow primaryTitle="Email" primaryDescription={profileInfo?.email} />
      </div>

      <AddModal
        formId={formId}
        title="Edit Profile"
        handleModal={showEditModal}
        setHandleModal={setShowEditModal}
        primaryButtonText="Update"
      >
        <Form
          onFinish={onEditProfile}
          form={form}
          id={formId}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item label="Profile Photo" name="image">
            <Upload
              accept={'image/*'}
              showUploadList={{
                showPreviewIcon: false,
                showRemoveIcon: true,
              }}
              fileList={image?.fileList}
              onPreview={() => { }}
              beforeUpload={beforeUpload}
              onChange={async ({ file, fileList }) => {
                try {
                  const compressedFile = await compressImage(file);
                  form.setFieldsValue({ image: { file: compressedFile, fileList } });
                } catch (error) {
                  notify('error', 'Error compressing the image:');
                }
              }}
              multiple={false}
              maxCount={1}
              listType="picture-card"
            >
              {(!image ||
                image?.file?.status === "removed") && (
                  <div>
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
            </Upload>
          </Form.Item>
          <Form.Item
            label="First Name"
            name="name"
            rules={[
              { required: true, message: "Please Input Your First Name!" },
            ]}
          >
            <Input
              onBlur={e => form.setFieldsValue({ 'name': e.target.value.trim() })}
              label="First Name"
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="surname"
            rules={[
              { required: true, message: "Please Input Your Last Name!" },
            ]}
          >
            <Input
              onBlur={e => form.setFieldsValue({ 'surname': e.target.value.trim() })}
              label="Last Name"
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please Input Your Phone Number!",
              },
            ]}
          >
            <Input
              disabled
              label="Email"
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>
        </Form>
        <div className="" style={{ color: "#7B7B7B", width: "100%" }}>
          <div
            onClick={() => {
              setShowSecurityModal(true);
            }}
            className="p-2 text-center rounded cursor-pointer"
            style={{
              width: "100%",
              color: "#51C2BC",
              border: "2px dashed #51C2BC",
            }}
          >
            Change password
          </div>
        </div>
      </AddModal>
      <PasswordChangeModal
        email={profileInfo?.email}
        showSecurityModal={showSecurityModal}
        setShowSecurityModal={setShowSecurityModal}
      />
    </div>
  );
};

const PasswordChangeModal = ({
  email,
  showSecurityModal,
  setShowSecurityModal,
}) => {
  const [form] = Form.useForm();
  const formId = useMemo(() => "pass-form", []);
  const dispatch = useDispatch();
  const onPassword = (password) => {
    dispatch(editProfilePasswordThunk(password)).then((res) => {
      if (res.payload.status === "success") {
        notify("success", "Password Chnaged");

        setShowSecurityModal(false);
        form.resetFields();
      }
    });
  };
  return (
    <ConfirmModal
      formId={formId}
      title="Change Password"
      open={showSecurityModal}
      cancelButtonText="Cancel"
      confirmButtonText="Submit"
      onConfirm={form.submit}
      onClose={() => setShowSecurityModal(false)}
    >
      <Form
        id={formId}
        onFinish={onPassword}
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item label="Email" name="email">
          <Input
            disabled
            defaultValue={email}
            label="Email"
            className="text-input-field p-2 pe-3 t1"
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your Password!" }]}
        >
          <Input.Password
            onBlur={e => form.setFieldsValue({ 'password': e.target.value.trim() })}
            label="Password"
            type="password"
            className="text-input-field p-2 pe-3 t1"
          />
        </Form.Item>
      </Form>
      <div className="mb-2" />
    </ConfirmModal>
  );
};
