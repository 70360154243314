import { cloneDeep, groupBy, mapValues } from "lodash";
import React, { useEffect, useState } from "react";
import arrow from "../../../../../assets/icons/arrow-up.png";
import cross from "../../../../../assets/icons/cross1.png";
import { rolesColor } from "../../../SchedularToolbar";
import { SlotCategory } from "./SlotCategory";

export const MoreDetailModal = ({
  data,
  show,
  target,
  reference,
  setShow,
  branchName,
  branchId,
  handleAddShift,
  locumFlow,
  isTemplate,
  operatingTime,
}) => {
  const [filteredShiftsData, setFilteredShiftsData] = useState();

  useEffect(() => {
    if (!data && !data.shifts.length) return;

    const _data = cloneDeep(data.shifts);
    _data.splice(0, 2);

    var grouped = mapValues(groupBy(_data, "roleType.label"));
    const d = Object.entries(grouped).map((e) => {
      return {
        label: e[0],
        shifts: e[1],
      };
    });
    setFilteredShiftsData(d);
  }, [data]);

  return (
    <>
      {/* <Overlay
        className="slot-detail-modal-layout"
        show={show}
        target={target}
        placement="auto"
        container={reference}
        containerPadding={20}
        flip={true}
      > */}
      {/* <Popover id="popover-contained" > */}
      {locumFlow && locumFlow !== undefined ? (
        <>
          {filteredShiftsData?.map((item, i) => (
            <div key={i}>
              {item.shifts?.map((shift, i) => (
                <div key={i} className="branch-detail-row">
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: "50%",
                      marginRight: 8,
                      background:
                        rolesColor[
                          shift?.role ? shift?.role : shift?.roleType?.role
                        ],
                    }}
                  />
                  <span className="t2">
                    {" "}
                    {shift?.startTime} - {shift.endTime}
                  </span>
                </div>
              ))}
            </div>
          ))}
        </>
      ) : (
        <div style={{ width: "100%" }}>
          <div className="d-flex justify-content-between">
            <div className="branch-profile-detail">
              <div className="cursor-pointer">
                <span className="s1"> {branchName}</span>
                <img
                  src={arrow}
                  alt=""
                  style={{ marginLeft: "9.83px", cursor: "pointer" }}
                />
              </div>
              <div>
                <span className="t2" style={{ color: "#7B7B7B" }}>
                  {data?.startDate}
                </span>
              </div>
            </div>
            <div>
              <img
                src={cross}
                alt=" "
                onClick={(e) => setShow(false)}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div>
            {filteredShiftsData?.map((item, i) => (
              <div key={i}>
                <SlotCategory
                  branchId={branchId}
                  operatingTime={operatingTime}
                  handleAddShift={handleAddShift}
                  isTemplate={isTemplate}
                  data={item}
                  setShow={setShow}
                  id={branchId}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {/* </Popover> */}
      {/* </Overlay> */}
    </>
  );
};
