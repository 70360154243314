import { Space, Typography } from 'antd';
import React from 'react';
import './style.scss';

const LocumCards = ({ title, subtitle, icon, iconBackground }) => {
  return (
    <div className="locum-card">
      {icon && (
        <figure
          style={{ backgroundColor: iconBackground }}
          className="icon_wrap"
        >
          {icon}
        </figure>
      )}
      <Space className="tab-header" direction="vertical">
        <Typography.Text>{title}</Typography.Text>
        <Typography.Title level={5}>{subtitle}</Typography.Title>
      </Space>
    </div>
  );
};

export default LocumCards;
