import { Radio } from "antd";
import React, {
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import infoIcon from "../../assets/icons/infoIcon1.png";
import { AddModal } from "../UI/modals/addModal";

import {
  AutoComplete,
  Checkbox,
  DatePicker,
  Input,
  Select,
  TimePicker,
} from "antd";
import dayjs from "dayjs";
import moment from "moment/moment";
import { useMemo } from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import {
  createJobBulkThunk,
  createShiftBulkThunk,
  editJobThunk,
  editShiftThunk,
  searchLocumByFilterThunk,
} from "../../redux/schedule/scheduleThunk";
import {
  branchApiKeys,
  locumShiftApiKeys,
} from "../../utils/apiKeyConstants";
import {
  getDuration,
  getDurationFormat,
  parseTime,
  weekDays,
} from "../../utils/dates";
import { hasAccess } from "../../utils/helper";
import { ConfirmModal } from "../UI/modals/confirmModal";

import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import {
  addTemplateShiftThunk,
  editTemplateShiftThunk,
} from "../../redux/branches/branchesThunk";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const options = [
  { value: "Shift", label: "Staff Shift" },
  { value: "Job", label: "Locum Job" },
];

export const ShiftJobForm = memo(
  ({
    gapData,
    showModal,
    setHandleModal,
    branchId,
    operatingTime,
    selectedDate,
    onSuccess,
    data,
    isTemplate,
    selectedDay,
    weekNumber,
    onDelete,
  }) => {
    const dispatch = useDispatch();

    const { roles } = useSelector((state) => {
      return state.schedule;
    });

    const { staffs, locums } = useSelector(
      (state) => {
        return state.schedule;
      }
    );
    const {
      mileageRules,
      accommodationAndFoodRules,
      parkingRules,
      transportRules,
    } = useSelector((state) => state.profile);

    const [showErrorModal, setShowErrorModal] =
      useState(false);
    const [errorData, setErrorData] = useState(
      []
    );
    const [type, setType] = useState("Shift");
    const [locumRoles, setLocumRoles] =
      useState();
    const [staffRoles, setStaffRoles] =
      useState();
    const [hasCoverExpense, setHasCoverExpense] =
      useState(false);
    const [
      hasExternalLocum,
      setHasExternalLocum,
    ] = useState(false);
    const [paidLunch, setPaidLucnh] =
      useState(false);
    const [jobDescription, setJobDescription] =
      useState("");
    const [coverExpenses, setCoverExpenses] =
      useState();
    const [
      externalLocumBooking,
      setExternalLocumBooking,
    ] = useState();
    const [roleId, setRoleId] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [attachedStaff, setAttachedStaff] =
      useState();
    const [weekDaysOptions, setWeekDaysOptions] =
      useState();
    const locumOptions = useMemo(
      () =>
        locums
          ?.filter(
            (i) =>
              i?.roleId === roleId ||
              (roleId === 4 &&
                i.type === "pharmacist") ||
              (roleId === 5 &&
                i.type === "technician")
          )
          ?.map((data, idx) => {
            return {
              key: idx,
              label: `${
                data?.name
                  ? data?.name
                  : data?.forenames
                  ? data?.forenames
                  : ""
              } ${
                data?.surname ? data?.surname : ""
              }`,
              value: data?.gphcNumber,
            };
          }),
      [locums]
    );

    useEffect(() => {
      if (!roles) return;
      const _locumRoles = roles
        .filter((item) => item.group === "locum")
        .map((i) => {
          return {
            label: i.label,
            value: i.id,
          };
        });
      const _staffRoles = roles
        .filter((item) => item.group === "staff")
        .map((i) => {
          return {
            label: i.label,
            value: i.id,
          };
        });
      setLocumRoles(_locumRoles);
      setStaffRoles(_staffRoles);
    }, [type, roles]);

    useEffect(() => {
      if (type !== "Job") return;
      let filters = {};
      if (externalLocumBooking?.gphcNumber) {
        filters["name"] =
          externalLocumBooking?.gphcNumber;
        filters["gphcNumber"] =
          externalLocumBooking?.gphcNumber;
        filters["roleId"] = roleId;
      }
      dispatch(
        searchLocumByFilterThunk({ filters })
      );
    }, [
      dispatch,
      externalLocumBooking?.gphcNumber,
      type,
      roleId,
    ]);

    useEffect(() => {
      if (!data) return;
      setType(data?.isJob ? "Job" : "Shift");
      if (isTemplate) {
        const role = roles?.find(
          (i) => i.role === data?.staff?.role
        );
        setRoleId(role?.id);
        return;
      }
      setRoleId(data?.roleType?.id);
    }, [data, isTemplate, roles]);

    useEffect(() => {
      if (isTemplate) {
        const weekDaysValue = weekDays.map(
          (item) => {
            const weekTime = operatingTime?.find(
              (ot) => ot.dayOfWeek === item
            );
            const startTime =
              weekTime &&
              moment(
                parseTime(
                  data
                    ? data?.startTime
                    : gapData
                    ? gapData.startTime
                    : weekTime?.startTime
                )
              );
            const endTime =
              (data || weekTime || gapData) &&
              moment(
                parseTime(
                  data
                    ? data?.endTime
                    : gapData
                    ? gapData.endTime
                    : weekTime?.endTime
                )
              );
            return {
              title: item,
              disabled: !weekTime,
              startTime,
              checked: selectedDay === item,
              endTime,
            };
          }
        );
        setWeekDaysOptions(weekDaysValue);
        setAttachedStaff(data?.staff?.id);
        return;
      }

      const weekDaysValue = weekDays.map(
        (item) => {
          const weekTime = operatingTime?.find(
            (ot) =>
              ot.date
                ? moment(ot.date).format(
                    "DD-MM-YYYY"
                  ) ===
                    moment(selectedDate).format(
                      "DD-MM-YYYY"
                    ) && ot.dayOfWeek === item
                : ot.dayOfWeek === item
          );
          const startTime =
            (data || weekTime || gapData) &&
            moment(
              parseTime(
                data
                  ? data?.startTime
                  : gapData
                  ? gapData.startTime
                  : weekTime?.startTime
              )
            );
          const endTime =
            (data || weekTime || gapData) &&
            moment(
              parseTime(
                data
                  ? data?.endTime
                  : gapData
                  ? gapData.endTime
                  : weekTime?.endTime
              )
            );
          let lunchStartTime = data
            ? data?.lunchStartTime
            : gapData
            ? gapData?.lunchStartTime
            : weekTime
            ? weekTime?.lunchStartTime
            : null;
          let lunchEndTime = data
            ? data?.lunchEndTime
            : gapData
            ? gapData?.lunchEndTime
            : weekTime
            ? weekTime?.lunchEndTime
            : null;
          lunchStartTime =
            lunchStartTime &&
            moment(parseTime(lunchStartTime));
          lunchEndTime =
            lunchStartTime &&
            moment(parseTime(lunchEndTime));
          return {
            title: item,
            startTime,
            endTime,
            checked:
              selectedDate &&
              moment(selectedDate)
                .format("dddd")
                .toLowerCase() === item,
            lunchStartTime,
            lunchEndTime,
            disabled: !weekTime,
            hourlyRate: data?.hourlyRate,
            total: data?.total,
            paidLunch: data?.paidLunch,
          };
        }
      );
      setAttachedStaff(
        data && !data.isJob
          ? data?.staff?.id
          : null
      );
      setWeekDaysOptions(weekDaysValue);
      setStartDate(dayjs(selectedDate));
      setEndDate(dayjs(selectedDate));
      setJobDescription(
        data && data.isJob ? data.description : ""
      );
      setPaidLucnh(
        data && data.isJob
          ? data.paidLunch
          : false
      );
      setExternalLocumBooking(
        data && data.isJob
          ? {
              gphcNumber:
                data.staff &&
                data?.staff[0]?.gphcNumber,
              email:
                data.staff &&
                data?.staff[0]?.email,
              phone:
                data.staff &&
                data?.staff[0]?.phone,
              id:
                data.staff && data?.staff[0]?.id,
            }
          : {}
      );
      setHasExternalLocum(
        data && data.isJob && data?.staff?.length
          ? true
          : false
      );
    }, [
      operatingTime,
      selectedDate,
      data,
      gapData,
      isTemplate,
      selectedDay,
    ]);

    const onSelect = (value) => {
      const data = locums.find(
        (data) => data.gphcNumber === value
      );
      setExternalLocumBooking({
        gphcNumber: data?.gphcNumber,
        email: data?.email,
        phone: data?.userProfile?.phone,
        id: data.type ? undefined : data?.id,
      });
    };

    const onChangeHandler = (value, name) => {
      let weekDaysCheckboxes = weekDaysOptions;
      const selectedWeeks = [];
      if (
        startDate &&
        name === "endDate" &&
        value
      ) {
        let dateFrom =
          dayjs(startDate).format("YYYY-MM-DD");
        while (
          moment(dateFrom).isSameOrBefore(
            dayjs(value).format("YYYY-MM-DD")
          )
        ) {
          selectedWeeks.push(
            moment(dateFrom)
              .format("dddd")
              .toLowerCase()
          );
          dateFrom = moment(dateFrom)
            .add(1, "day")
            .format("YYYY-MM-DD");
        }
        setEndDate(value);
      } else if (name === "startDate") {
        const dateFrom =
          dayjs(value).format("YYYY-MM-DD");
        selectedWeeks.push(
          moment(dateFrom)
            .format("dddd")
            .toLowerCase()
        );
        setStartDate(value);
        if (data) {
          setEndDate(value);
        }
        setEndDate(value);
      }

      const d = selectedWeeks.length
        ? weekDaysCheckboxes.map((week) => {
            const checked =
              !!selectedWeeks.find(
                (sw) => sw === week.title
              ) && !week.disabled;
            return {
              ...week,
              checked,
            };
          })
        : weekDaysCheckboxes;
      setWeekDaysOptions(d);
    };

    const onCheckboxChangeHandler = (value) => {
      let result = weekDaysOptions.map((x) => ({
        ...x,
        checked: value.includes(x.title),
      }));

      setWeekDaysOptions(result);
    };

    const onTimeChange = (day, value, name) => {
      const data = weekDaysOptions.map((week) => {
        if (week.title === day.title) {
          return {
            ...week,
            [name]: value,
          };
        }
        return week;
      });
      setWeekDaysOptions(data);
    };

    const clearFieldHandler = useCallback(() => {
      setHandleModal();
      setRoleId(undefined);
      setStartDate(undefined);
      setEndDate(undefined);
      setAttachedStaff(undefined);
      setWeekDaysOptions(undefined);
      setHasExternalLocum(false);
      setPaidLucnh(false);
      setExternalLocumBooking(undefined);
      setType("Shift");
      setHasCoverExpense(false);
      setJobDescription("");
      setCoverExpenses(false);
    }, [setHandleModal]);

    const onSuccessHandler = useCallback(() => {
      onSuccess();
      setShowErrorModal(false);
      clearFieldHandler();
    }, [clearFieldHandler, onSuccess]);

    const addShift = useCallback(() => {
      const newShifts = [];
      let newJobs = [];
      let editableStartDate =
        dayjs(startDate).format("YYYY-MM-DD");
      const newEndDate =
        dayjs(endDate).format("YYYY-MM-DD");
      const id = data ? data?.id : undefined;
      while (
        moment(editableStartDate).isSameOrBefore(
          newEndDate
        )
      ) {
        const dayOfWeek = moment(
          editableStartDate
        )
          .format("dddd")
          .toLowerCase();
        const selectedWeek =
          weekDaysOptions?.find(
            (week) => week.title === dayOfWeek
          );
        if (selectedWeek && selectedWeek.checked)
          if (type === "Shift") {
            newShifts.push({
              startDate: editableStartDate,
              endDate: editableStartDate,
              lunchStartTime:
                selectedWeek &&
                selectedWeek.lunchStartTime &&
                selectedWeek.lunchStartTime.format(
                  "HH:mm"
                ),
              lunchEndTime:
                selectedWeek &&
                selectedWeek.lunchEndTime &&
                selectedWeek.lunchEndTime.format(
                  "HH:mm"
                ),
              startTime:
                selectedWeek?.startTime?.format(
                  "HH:mm"
                ),
              endTime:
                selectedWeek?.endTime?.format(
                  "HH:mm"
                ),
              staffId: attachedStaff,
              days: [dayOfWeek],
              roleId,
            });
          } else {
            newJobs.push({
              startDate: editableStartDate,
              endDate: editableStartDate,
              lunchStartTime:
                selectedWeek &&
                selectedWeek.lunchStartTime &&
                selectedWeek.lunchStartTime.format(
                  "HH:mm"
                ),
              lunchEndTime:
                selectedWeek &&
                selectedWeek.lunchEndTime &&
                selectedWeek.lunchEndTime.format(
                  "HH:mm"
                ),
              startTime:
                selectedWeek.startTime.format(
                  "HH:mm"
                ),
              endTime:
                selectedWeek.endTime.format(
                  "HH:mm"
                ),
              hourlyRate: selectedWeek.hourlyRate,
              roleId,
              description: jobDescription,
              paidLunch: paidLunch,
              branchId: branchId,
              externalLocumBooking:
                hasExternalLocum
                  ? externalLocumBooking
                  : undefined,
              ...(data?.isJob && {
                hasExternalLocum,
              }),
              ...(hasCoverExpense &&
                !data &&
                coverExpenses),
              ...(data?.isJob && { id: data.id }),
              ...(data && { split: true }),
            });
          }
        editableStartDate = moment(
          editableStartDate
        )
          .add(1, "day")
          .format("YYYY-MM-DD");
      }

      if (isTemplate) {
        const reqData = weekDaysOptions
          .filter((i) => i.checked === true)
          .map((d) => ({
            dayOfWeek: d.title,
            startTime:
              d.startTime.format("HH:mm"),
            endTime: d.endTime.format("HH:mm"),
            staffId: attachedStaff,
            roleId,
            weekNumber: `${weekNumber}`,
          }));

        if (!data) {
          dispatch(
            addTemplateShiftThunk({
              id: branchId,
              data: reqData,
            })
          ).then((res) => {
            if (res.payload) {
              onSuccessHandler();
            }
          });
        } else {
          dispatch(
            editTemplateShiftThunk({
              branchId: Number(branchId),
              shiftId: data.id,
              data: reqData[0],
            })
          ).then((res) => {
            if (res.payload) {
              onSuccessHandler();
            }
          });
        }

        return;
      }
      if (type === "Shift") {
        if (!data) {
          if (
            hasAccess(
              branchApiKeys.SAVE_SHIFT_BULK
            )
          ) {
            dispatch(
              createShiftBulkThunk({
                branchId,
                data: newShifts,
              })
            ).then((res) => {
              if (res.payload.length) {
                onSuccessHandler();
                for (var shift in newShifts) {
                  window.analytics.track(
                    "Shift Added",
                    newShifts[shift]
                  );
                }
              }
              if (
                res.payload.code === "validation"
              ) {
                const thirdArray =
                  newShifts.filter((elem) => {
                    return res.payload.array?.some(
                      (ele) => {
                        return (
                          ele.date !==
                          elem.startDate
                        );
                      }
                    );
                  });
                for (var data in thirdArray) {
                  window.analytics.track(
                    "Shift Added",
                    thirdArray[data]
                  );
                }
                setShowErrorModal(true);
                setErrorData(res.payload);
              }
            });
          }
        } else {
          if (
            hasAccess(branchApiKeys.UPDATE_SHIFT)
          )
            dispatch(
              editShiftThunk({
                shiftId: id,
                branchId: branchId,
                data: newShifts[0],
              })
            ).then((res) => {
              if (res.payload.length) {
                onSuccessHandler();
              }
            });
        }
      }
      if (type === "Job") {
        if (!data) {
          if (
            hasAccess(
              branchApiKeys.CREATE_BULK_JOB
            )
          )
            dispatch(
              createJobBulkThunk({
                branchId,
                data: newJobs,
              })
            ).then((res) => {
              if (res.payload.length) {
                for (var job in newJobs) {
                  window.analytics.track(
                    "Job Added",
                    newJobs[job]
                  );
                }
                onSuccessHandler();
              }
              if (
                res.payload.code === "validation"
              ) {
                const thirdArray = newJobs.filter(
                  (elem) => {
                    return res.payload.array?.some(
                      (ele) => {
                        return (
                          ele.date !==
                          moment(
                            elem.startDate
                          ).format("DD-MM-YYYY")
                        );
                      }
                    );
                  }
                );
                for (var data in thirdArray) {
                  window.analytics.track(
                    "Job Added",
                    thirdArray[data]
                  );
                }
                setShowErrorModal(true);
                setErrorData(res.payload);
              }
            });
        } else {
          dispatch(
            editJobThunk({
              jobId: id,
              data: newJobs[0],
            })
          ).then((res) => {
            if (res.payload) {
              onSuccessHandler();
            }
          });
        }
      }
    }, [
      startDate,
      endDate,
      data,
      isTemplate,
      type,
      weekDaysOptions,
      attachedStaff,
      roleId,
      jobDescription,
      paidLunch,
      branchId,
      hasExternalLocum,
      externalLocumBooking,
      hasCoverExpense,
      coverExpenses,
      dispatch,
      weekNumber,
      onSuccessHandler,
    ]);

    const calculateTotal = (day) => {
      const duration = getDuration(
        day?.startTime,
        day?.endTime
      );
      const lunchDuration = getDuration(
        day?.lunchStartTime,
        day?.lunchEndTime
      );
      const d1 = day.lunchStartTime
        ? dayjs(day.lunchStartTime).isSameOrAfter(
            day.endTime
          )
        : false;
      const d2 = day.lunchEndTime
        ? dayjs(day.lunchEndTime).isSameOrBefore(
            day.startTime
          )
        : false;
      const actualAmount =
        (duration / 60) * Number(day.hourlyRate);
      const lunchAmount =
        ((lunchDuration ?? 0) / 60) *
        Number(day.hourlyRate);

      if (d1) {
        return isNaN(actualAmount) ||
          actualAmount < 0
          ? 0
          : actualAmount;
      } else if (d2) {
        return isNaN(actualAmount) ||
          actualAmount < 0
          ? 0
          : actualAmount;
      } else {
        return isNaN(
          actualAmount -
            (!paidLunch && lunchAmount)
        ) || actualAmount < 0
          ? 0
          : actualAmount -
              (!paidLunch && lunchAmount);
      }
    };

    const _disabledHours = (
      disableFor,
      day,
      startTime,
      endTime
    ) => {
      const weekTime = operatingTime?.find(
        (ot) =>
          (moment(ot.date).format(
            "DD-MM-YYYY"
          ) ===
            moment(selectedDate).format(
              "DD-MM-YYYY"
            ) &&
            ot.dayOfWeek === day) ||
          ot.dayOfWeek === day
      );
      let disabledHours = [];
      let disabledHours2 = [];

      if (disableFor === "startTime") {
        for (
          let i = 0;
          i <
          Number(
            moment(
              weekTime?.startTime,
              "HH:mm"
            )?.format("H")
          );
          i++
        ) {
          disabledHours.push(i);
        }
        for (
          let i =
            Number(endTime?.format("H")) + 1;
          i < 24;
          i++
        ) {
          disabledHours.push(i);
        }
        return [
          ...disabledHours,
          ...disabledHours2,
        ];
      }

      if (disableFor === "endTime") {
        for (
          let i = 0;
          i < Number(startTime?.format("H"));
          i++
        ) {
          disabledHours.push(i);
        }
        for (
          let i =
            Number(
              moment(
                weekTime?.endTime,
                "HH:mm"
              )?.format("H")
            ) + 1;
          i < 24;
          i++
        ) {
          disabledHours.push(i);
        }
        return [
          ...disabledHours,
          ...disabledHours2,
        ];
      }

      return [];
    };

    const _disabledMinute = (
      disableFor,
      day,
      selectedHour,
      startTime,
      endTime
    ) => {
      const weekTime = operatingTime?.find(
        (ot) =>
          (moment(ot.date).format(
            "DD-MM-YYYY"
          ) ===
            moment(selectedDate).format(
              "DD-MM-YYYY"
            ) &&
            ot.dayOfWeek === day) ||
          ot.dayOfWeek === day
      );
      const minutes = [];

      if (disableFor === "startTime") {
        if (
          selectedHour ===
          Number(
            moment(
              weekTime?.startTime,
              "HH:mm"
            )?.format("H")
          )
        ) {
          const currentMinute = Number(
            moment(
              weekTime?.startTime,
              "HH:mm"
            )?.format("mm")
          );
          for (
            let i = currentMinute - 1;
            i >= 0;
            i--
          ) {
            minutes.push(i);
          }
          return minutes;
        }
        if (
          selectedHour ===
          Number(endTime?.format("H"))
        ) {
          const currentMinute = Number(
            endTime?.format("mm")
          );
          for (
            let i = currentMinute + 1;
            i <= 60;
            i++
          ) {
            minutes.push(i);
          }
          return minutes;
        }
      }

      if (disableFor === "endTime") {
        if (
          selectedHour ===
          Number(
            moment(
              weekTime?.endTime,
              "HH:mm"
            )?.format("H")
          )
        ) {
          const currentMinute = Number(
            moment(
              weekTime?.endTime,
              "HH:mm"
            )?.format("mm")
          );
          for (
            let i = currentMinute + 1;
            i <= 60;
            i++
          ) {
            minutes.push(i);
          }
          return minutes;
        }
        if (
          selectedHour ===
          Number(startTime?.format("H"))
        ) {
          const currentMinute = Number(
            startTime?.format("mm")
          );
          for (
            let i = currentMinute - 1;
            i >= 0;
            i--
          ) {
            minutes.push(i);
          }
          return minutes;
        }
      }

      return minutes;
    };

    return (
      <>
        <ConfirmModal
          backdrop="static"
          title="Hold up!"
          open={showErrorModal}
          confirmButtonText="Close"
          image={infoIcon}
          onConfirm={onSuccessHandler}
        >
          <div className="d-flex flex-column gap-3">
            <div>
              There seem to be a few conflicts
              within this schedule attempt.
              However, we've created shifts for
              dates prior to and following the
              date(s) of conflict.
            </div>
            <div>
              You can resolve these conflicts and
              schedule again for the following
              days,
            </div>
          </div>
          <div className="d-flex flex-column gap-3 align-items-center">
            {errorData?.array?.map(
              (data, idx) => (
                <div
                  className="d-flex align-items-center gap-2"
                  key={idx}
                >
                  <div
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data.date}
                  </div>
                  <div
                    style={{
                      border: "1px solid",
                      height: 20,
                    }}
                  />
                  <div>
                    {data.day[0].toUpperCase() +
                      data.day.slice(1)}
                  </div>
                  <div
                    style={{
                      border: "1px solid",
                      height: 20,
                    }}
                  />
                  <div>{data.reason}</div>
                </div>
              )
            )}
          </div>
          {errorData?.successCount > 0 && (
            <div>
              {errorData?.successCount}{" "}
              Shifts/Jobs Were Scheduled
              Successfully
            </div>
          )}
        </ConfirmModal>
        <AddModal
          title={`${
            data ? "Edit" : "Add"
          } ${type}`}
          handleModal={showModal}
          primaryButtonText={
            type === "Job" &&
            hasAccess(
              locumShiftApiKeys.UPDATE_JOB
            )
              ? data
                ? data.status === "unfilled" ||
                  data.status === "active"
                  ? "Edit Job"
                  : ""
                : "Add Job"
              : type === "Shift" &&
                hasAccess(
                  branchApiKeys.UPDATE_SHIFT
                )
              ? data
                ? data.status === "unfilled" ||
                  data.status === "active" ||
                  isTemplate
                  ? "Edit Shift"
                  : ""
                : "Add Shift"
              : ""
          }
          secondaryButtonText={
            data &&
            (hasAccess(
              locumShiftApiKeys.UPDATE_JOB
            ) ||
              hasAccess(
                branchApiKeys.UPDATE_SHIFT
              ))
              ? "Delete"
              : data && isTemplate
              ? "Delete"
              : "Cancel"
          }
          setHandleModal={setHandleModal}
          isDelete={
            data &&
            (hasAccess(
              locumShiftApiKeys.UPDATE_JOB
            ) ||
              hasAccess(
                branchApiKeys.UPDATE_SHIFT
              ))
              ? true
              : false
          }
          secondaryButtonHandler={
            data &&
            (hasAccess(
              locumShiftApiKeys.UPDATE_JOB
            ) ||
              hasAccess(
                branchApiKeys.UPDATE_SHIFT
              ))
              ? () => onDelete(data)
              : setHandleModal
          }
          primaryButtonHandler={addShift}
        >
          {isTemplate ? undefined : (
            <div>
              <Radio.Group
                disabled={data ? true : false}
                className=" p2 mb-3"
                options={
                  data
                    ? options
                    : hasAccess(
                        branchApiKeys.CREATE_BULK_JOB
                      ) &&
                      hasAccess(
                        branchApiKeys.SAVE_SHIFT_BULK
                      )
                    ? options
                    : hasAccess(
                        branchApiKeys.CREATE_BULK_JOB
                      )
                    ? options.filter(
                        (option) =>
                          option.value === "job"
                      )
                    : options.filter(
                        (option) =>
                          option.value === "shift"
                      )
                }
                onChange={(e) => {
                  setType(e.target.value);
                  setRoleId(undefined);
                  setAttachedStaff(undefined);
                  setExternalLocumBooking(
                    undefined
                  );
                  setHasExternalLocum(undefined);
                }}
                value={type}
              />
            </div>
          )}
          <div className="d-flex flex-column gap-3">
            <div>
              <div
                className="s1"
                style={{
                  color: "#7B7B7B",
                  marginBottom: "6px",
                }}
              >
                Role
              </div>
              <Select
                className="text-input-field p-1 pe-3 t1"
                bordered={false}
                value={roleId}
                onChange={(value) => {
                  setRoleId(value);
                  setAttachedStaff(undefined);
                  setExternalLocumBooking(
                    undefined
                  );
                  setExternalLocumBooking(
                    undefined
                  );
                  setHasExternalLocum(false);
                }}
                options={
                  type === "Shift"
                    ? staffRoles
                    : locumRoles
                }
                dropdownStyle={{ zIndex: 2000 }}
                placeholder="Select Role"
              />
            </div>
            {isTemplate ? undefined : (
              <div
                className="d-flex align-items-center gap-3"
                style={{ width: "100%" }}
              >
                <div style={{ width: "50%" }}>
                  <div
                    className="s1"
                    style={{
                      color: "#7B7B7B",
                      marginBottom: "6px",
                    }}
                  >
                    Start Date
                  </div>
                  <DatePicker
                    inputReadOnly
                    format="DD-MM-YYYY"
                    disabled={
                      gapData ? true : false
                    }
                    value={startDate}
                    getPopupContainer={(
                      trigger
                    ) => trigger.parentElement}
                    className="text-input-field p-2 pe-3 t1"
                    style={{ width: "100%" }}
                    // disabledDate={(current) => {
                    //   return moment().add(-1, "days") >= current;
                    // }}
                    onChange={(date) =>
                      onChangeHandler(
                        date,
                        "startDate"
                      )
                    }
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <div
                    className="s1"
                    style={{
                      color: "#7B7B7B",
                      marginBottom: "6px",
                    }}
                  >
                    End Date
                  </div>
                  <DatePicker
                    inputReadOnly
                    format="DD-MM-YYYY"
                    disabled={
                      gapData ? true : false
                    }
                    value={endDate}
                    onChange={(date) =>
                      onChangeHandler(
                        date,
                        "endDate"
                      )
                    }
                    disabledDate={(current) => {
                      return (
                        (data &&
                          dayjs(startDate).add(
                            +1,
                            "days"
                          ) <= current) ||
                        dayjs(startDate).add(
                          -1,
                          "days"
                        ) >= current
                      );
                    }}
                    getPopupContainer={(
                      trigger
                    ) => trigger.parentElement}
                    className="text-input-field p-2 pe-3 t1"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            )}
            <div>
              <div
                className="s1"
                style={{
                  color: "#7B7B7B",
                  marginBottom: "6px",
                }}
              >
                {" "}
                Week Days
              </div>
              <Select
                disabled={
                  data || gapData ? true : false
                }
                className="text-input-field p-1 pe-3 t1"
                bordered={false}
                mode="multiple"
                value={weekDaysOptions
                  ?.filter((item) => item.checked)
                  .map((data) => data.title)}
                onChange={onCheckboxChangeHandler}
                dropdownStyle={{ zIndex: 2000 }}
                placeholder="Week Days"
              >
                {weekDaysOptions?.map(
                  (data, idx) => (
                    <Select.Option
                      disabled={data.disabled}
                      key={idx}
                      value={data.title}
                    >
                      {data.title
                        .charAt(0)
                        .toUpperCase() +
                        data.title.slice(1)}
                    </Select.Option>
                  )
                )}
              </Select>
            </div>
            {weekDaysOptions
              ?.filter((day) => day.checked)
              ?.map((day, index) => {
                return (
                  <div
                    key={index}
                    className={`border rounded p-3 day-box d-block`}
                    style={{
                      width: "100%",
                    }}
                  >
                    <div className="d-flex my-3 gap-5 justify-content-between">
                      <div className="b1">
                        {day.title[0].toUpperCase() +
                          day.title.slice(1)}
                      </div>
                      <div
                        className="t1"
                        style={{
                          whiteSpace: "nowrap",
                          color: "#7B7B7B",
                        }}
                      >
                        {getDurationFormat(
                          getDuration(
                            dayjs(day?.startTime),
                            dayjs(day?.endTime)
                          )
                        )}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between gap-3">
                      <TimePicker
                        disabledHours={() =>
                          _disabledHours(
                            "startTime",
                            day.title,
                            day.startTime,
                            day.endTime
                          )
                        }
                        disabledMinutes={(time) =>
                          _disabledMinute(
                            "startTime",
                            day.title,
                            time,
                            day.startTime,
                            day.endTime
                          )
                        }
                        inputReadOnly
                        allowClear={false}
                        value={dayjs(
                          day.startTime
                        )}
                        onChange={(time) =>
                          onTimeChange(
                            day,
                            time,
                            "startTime"
                          )
                        }
                        onSelect={(time) =>
                          onTimeChange(
                            day,
                            time,
                            "startTime"
                          )
                        }
                        minuteStep={5}
                        format="HH:mm"
                        className="text-input-field p-2 pe-3 t1"
                        style={{ width: "100%" }}
                        getPopupContainer={(
                          trigger
                        ) =>
                          trigger.parentElement
                        }
                      />
                      <TimePicker
                        disabledHours={() =>
                          _disabledHours(
                            "endTime",
                            day.title,
                            day.startTime,
                            day.endTime
                          )
                        }
                        disabledMinutes={(time) =>
                          _disabledMinute(
                            "endTime",
                            day.title,
                            time,
                            day.startTime,
                            day.endTime
                          )
                        }
                        inputReadOnly
                        allowClear={false}
                        value={dayjs(day.endTime)}
                        onSelect={(time) =>
                          onTimeChange(
                            day,
                            time,
                            "endTime"
                          )
                        }
                        onChange={(time) =>
                          onTimeChange(
                            day,
                            time,
                            "endTime"
                          )
                        }
                        minuteStep={5}
                        format="HH:mm"
                        className="text-input-field p-2 pe-3 t1"
                        style={{ width: "100%" }}
                        getPopupContainer={(
                          trigger
                        ) =>
                          trigger.parentElement
                        }
                      />
                    </div>
                    {type === "Job" && (
                      <div
                        className="d-flex align-items-center mt-3"
                        style={{ gap: " 15px" }}
                      >
                        <Input
                          className="text-input-field px-3 t1"
                          type="number"
                          value={day?.hourlyRate}
                          onChange={(e) => {
                            if (
                              e.target.value.split(
                                "."
                              )[0].length <= 3 &&
                              e.target.value >= 0
                            ) {
                              onTimeChange(
                                day,
                                e.target.value,
                                "hourlyRate"
                              );
                            }
                          }}
                          placeholder="Hourly Rate"
                          name="hourlyRate"
                        />
                        <div
                          className="t1"
                          style={{
                            whiteSpace: "nowrap",
                            color: "#7B7B7B",
                          }}
                        >
                          Total: £
                          {calculateTotal(
                            day
                          ).toFixed(2)}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            {type === "Job" && (
              <div
                className="d-flex align-items-center gap-3"
                style={{ width: "100%" }}
              >
                <Checkbox
                  checked={paidLunch}
                  onChange={(e) =>
                    setPaidLucnh(e.target.checked)
                  }
                >
                  Paid Lunch
                </Checkbox>
                {!data && (
                  <Checkbox
                    onChange={(e) =>
                      setHasCoverExpense(
                        e.target.checked
                      )
                    }
                  >
                    Cover Expense
                  </Checkbox>
                )}
              </div>
            )}
            {type === "Job" &&
              hasCoverExpense && (
                <div
                  className="border rounded p-3 d-flex flex-column day-box"
                  style={{
                    width: "100%",
                    gap: "15px",
                  }}
                >
                  <div className="b1">Rules</div>
                  <div style={{ width: "100%" }}>
                    <div
                      className="s1"
                      style={{
                        color: "#7B7B7B",
                        marginBottom: "6px",
                      }}
                    >
                      Mileage Rule
                    </div>
                    <Select
                      className="text-input-field p-1 pe-3 t1 bg-white"
                      bordered={false}
                      onChange={(value) =>
                        setCoverExpenses({
                          ...coverExpenses,
                          mileageRuleId: value,
                        })
                      }
                      value={
                        coverExpenses?.mileageRuleId
                      }
                      dropdownStyle={{
                        zIndex: 2000,
                      }}
                      placeholder="Mileage Rule"
                    >
                      {mileageRules.map(
                        (data, idx) => (
                          <Select.Option
                            key={idx}
                            value={data.id}
                          >
                            {data.name}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </div>
                  <div style={{ width: "100%" }}>
                    <div
                      className="s1"
                      style={{
                        color: "#7B7B7B",
                        marginBottom: "6px",
                      }}
                    >
                      {" "}
                      Accommodation & Food Rule
                    </div>
                    <Select
                      className="text-input-field p-1 pe-3 t1 bg-white"
                      bordered={false}
                      onChange={(value) =>
                        setCoverExpenses({
                          ...coverExpenses,
                          accommodationAndFoodRuleId:
                            value,
                        })
                      }
                      value={
                        coverExpenses?.accommodationAndFoodRuleId
                      }
                      dropdownStyle={{
                        zIndex: 2000,
                      }}
                      placeholder="Accommodation & Food Rule"
                    >
                      {accommodationAndFoodRules.map(
                        (data, idx) => (
                          <Select.Option
                            key={idx}
                            value={data.id}
                          >
                            {data.name}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </div>
                  <div style={{ width: "100%" }}>
                    <div
                      className="s1"
                      style={{
                        color: "#7B7B7B",
                        marginBottom: "6px",
                      }}
                    >
                      {" "}
                      Parking Rule
                    </div>
                    <Select
                      className="text-input-field p-1 pe-3 t1 bg-white"
                      bordered={false}
                      onChange={(value) =>
                        setCoverExpenses({
                          ...coverExpenses,
                          parkingRuleId: value,
                        })
                      }
                      value={
                        coverExpenses?.parkingRuleId
                      }
                      dropdownStyle={{
                        zIndex: 2000,
                      }}
                      placeholder="Parking Rule"
                    >
                      {parkingRules.map(
                        (data, idx) => (
                          <Select.Option
                            key={idx}
                            value={data.id}
                          >
                            {data.name}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </div>
                  <div style={{ width: "100%" }}>
                    <div
                      className="s1"
                      style={{
                        color: "#7B7B7B",
                        marginBottom: "6px",
                      }}
                    >
                      Transport Rule
                    </div>
                    <Select
                      className="text-input-field p-1 pe-3 t1 bg-white"
                      bordered={false}
                      dropdownStyle={{
                        zIndex: 2000,
                      }}
                      onChange={(value) =>
                        setCoverExpenses({
                          ...coverExpenses,
                          transportRuleId: value,
                        })
                      }
                      value={
                        coverExpenses?.transportRuleId
                      }
                      placeholder="Transport Rule"
                    >
                      {transportRules.map(
                        (data, idx) => (
                          <Select.Option
                            key={idx}
                            value={data.id}
                          >
                            {data.name}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </div>
                </div>
              )}
            {type === "Job" && (
              <div>
                <div
                  className="s1"
                  style={{
                    color: "#7B7B7B",
                    marginBottom: "6px",
                  }}
                >
                  Description
                </div>
                <Input.TextArea
                  className="text-input-field px-3 py-2 t1"
                  rows={5}
                  maxLength={256}
                  onChange={(e) =>
                    setJobDescription(
                      e.target.value
                    )
                  }
                  name="description"
                  value={jobDescription}
                  placeholder="Job Description"
                />
              </div>
            )}
            {type === "Shift" ? (
              <div>
                <div
                  className="s1"
                  style={{
                    color: "#7B7B7B",
                    marginBottom: "6px",
                  }}
                >
                  {" "}
                  Assign Staff
                </div>
                <Select
                  allowClear
                  showSearch
                  value={attachedStaff}
                  className="text-input-field p-1 pe-3 t1"
                  bordered={false}
                  optionFilterProp="label"
                  onChange={(data) =>
                    setAttachedStaff(data)
                  }
                  dropdownStyle={{ zIndex: 2000 }}
                  placeholder="Find Staff"
                >
                  {staffs
                    ?.filter(
                      (i) => i.roleId === roleId
                    )
                    .map((data, idx) => (
                      <Select.Option
                        key={idx}
                        value={data.id}
                      >
                        {data.name} {data.surname}
                      </Select.Option>
                    ))}
                </Select>
              </div>
            ) : (
              <Checkbox
                checked={hasExternalLocum}
                onChange={(e) =>
                  setHasExternalLocum(
                    e.target.checked
                  )
                }
              >
                Assign Locum
              </Checkbox>
            )}
            {(type === "Job" ||
              data?.status === "active") &&
              hasExternalLocum && (
                <div
                  className="d-flex flex-column"
                  style={{ gap: "15px" }}
                >
                  <div
                    className=""
                    style={{ width: "100%" }}
                  >
                    <div
                      className="s1"
                      style={{
                        color: "#7B7B7B",
                        marginBottom: "6px",
                      }}
                    >
                      GPhC No. / Name
                    </div>
                    <AutoComplete
                      value={
                        externalLocumBooking?.gphcNumber
                      }
                      dropdownStyle={{
                        zIndex: 2000,
                      }}
                      className="text-input-field pe-3 p-1 t1"
                      options={locumOptions}
                      maxLength={7}
                      onSelect={onSelect}
                      bordered={false}
                      onSearch={(e) => {
                        if (
                          e.length < 7 ||
                          e !==
                            externalLocumBooking?.gphcNumber
                        ) {
                          setExternalLocumBooking(
                            {
                              gphcNumber: e,
                              ...undefined,
                            }
                          );
                          return;
                        }
                        setExternalLocumBooking({
                          ...externalLocumBooking,
                          gphcNumber: e,
                        });
                      }}
                      placeholder="GPhC No."
                    />
                  </div>
                  <div
                    className=""
                    style={{ width: "100%" }}
                  >
                    <div
                      className="s1"
                      style={{
                        color: "#7B7B7B",
                        marginBottom: "6px",
                      }}
                    >
                      Email Address
                    </div>
                    <Input
                      type="email"
                      className="text-input-field px-3 pe-3 t1"
                      placeholder="Email Address"
                      name="email"
                      onChange={(e) =>
                        setExternalLocumBooking({
                          ...externalLocumBooking,
                          email: e.target.value,
                        })
                      }
                      value={
                        externalLocumBooking?.email
                      }
                      label="Email Address"
                    />
                  </div>
                  <div
                    className=""
                    style={{ width: "100%" }}
                  >
                    <div
                      className="s1"
                      style={{
                        color: "#7B7B7B",
                        marginBottom: "6px",
                      }}
                    >
                      Phone No.
                    </div>
                    <Input
                      required={true}
                      className="text-input-field px-3 t1"
                      placeholder="Phone No."
                      name="phone"
                      onChange={(e) =>
                        setExternalLocumBooking({
                          ...externalLocumBooking,
                          phone: e.target.value,
                        })
                      }
                      value={
                        externalLocumBooking?.phone
                      }
                      label="Phone No."
                    />
                  </div>
                </div>
              )}
          </div>
        </AddModal>
      </>
    );
  }
);
