import { API } from "../../../axios";
import cookies from 'browser-cookies'
import { removeAuthToken } from "../authSlicer";
import { store } from "../..";
import { genRandonString } from "../../../utils/helper";
import axios from "axios";

export const signin = (payload) => {
    const request = API.post("/auth/login", { ...payload, deviceToken: genRandonString(20) });
    return request;
};

export const authUserRequest = () => {
    if (cookies.get("accessToken")) {
        const request = API.get("/profile/short");
        return request;
    }
    store.dispatch(removeAuthToken())
}

export const getAccessibleModules = (payload) => {
    if (cookies.get("accessToken")) {

        const request = API.get(`/resource/user-access/pharmacy/${payload}`)
        return request
    }
}

export const refreshToken = (payload) => {
    const refreshToken = cookies.get("refreshToken")
    const request = axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/refresh`, {
        refreshToken
    }, {
        headers: {}
    })

    return request
}

export const getAccessibleApis = (payload) => {
    if (cookies.get("accessToken")) {

        const request = API.get(`/resource/allowed/${payload}`)
        return request
    }
}
export const resetPasswordReq = (payload) => {
    const request = API.post("/auth/request-password-reset", payload);
    return request;
}

export const resetPassword = (payload) => {
    const { token, password } = payload
    const request = API.post(`/auth/password-reset/${token}`, { password });
    return request;
}

export const logOutUser = () => {
    const request = API.post(`/auth/logout`);
    return request;
}

export const signUp = (payload) => {
    const request = API.post(`/auth/sign-up`, payload);
    return request;
}

export const deleteUser = (payload) => {
    const { id } = payload
    const request = API.delete(`profile/hard-delete-user/${id}`)
    return request
}