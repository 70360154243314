import { Form } from "antd";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createBranchThunk } from "../../../redux/branches/branchesThunk";
import { branchApiKeys } from "../../../utils/apiKeyConstants.js";
import { hasAccess } from "../../../utils/helper.js";
import { AddModal } from "../../UI/modals/addModal";
import { CreateBranchForm } from "./createBranchFormStep1";

export const CreateBranch = ({ showCreateModal, setShowCreateModal }) => {
  const formId = useMemo(() => "form", []);
  const [form] = Form.useForm();
  const { branchRelatedData: branchData } = useSelector(
    (state) => state.branches
  );
  const [branchFormStep, setBranchFormStep] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAddressSelect = async ({ name, data: address }) => {
    form.setFieldsValue({
      ...form,
      address1: address.line_1,
      address2: address.line_2,
      latitude: address.latitude,
      longitude: address.longitude,
      city: address.town_or_city,
      postalCode: address.postcode,
    });
  };

  const onFininsh = (values) => {
    if (branchFormStep === 1) {
      setBranchFormStep(2);
      return;
    }
    const reqData = {
      ...values,
      logo: values?.logo && values?.logo.file,
    };

    let formData = new FormData();

    for (let key in reqData) {
      if (reqData[key] instanceof Blob) {
        formData.append(key, reqData[key], reqData[key]?.name);
      } else {
        formData.append(key, reqData[key]);
      }
    }

    if (hasAccess(branchApiKeys.CREATE_BRANCH)) {
      dispatch(createBranchThunk(formData)).then((res) => {
        if (res.payload) {
          navigate(
            `/branches/${res.payload.id}/details/information?tab=branch-hours&open=true`
          );
          window.analytics.track("Branch Added", {
            title: res.payload.title,
            region: res.payload.regionName,
            postCode: res.payload.postalCode,
            phone: res.payload.phone,
            branchNo: res.payload.number,
          });
          setShowCreateModal(false);
          setBranchFormStep(1);
          form.resetFields();
        }
      });
    }
  };

  return (
    <AddModal
      title="Add Branch"
      handleModal={showCreateModal}
      setHandleModal={setShowCreateModal}
      primaryButtonText={branchFormStep === 1 ? "Continue" : "Add Branch"}
      secondaryButtonText={branchFormStep === 1 ? "Cancel" : "Back"}
      secondaryButtonHandler={() => {
        branchFormStep === 1 ? setShowCreateModal(false) : setBranchFormStep(1);
      }}
      formId={formId}
    >
      <Form
        onFinish={onFininsh}
        form={form}
        id={formId}
        style={{ width: "100%" }}
        layout="vertical"
        autoComplete="off"
        colon={false}
      >
        <CreateBranchForm
          form={form}
          branchFormStep={branchFormStep}
          handleAddressSelect={handleAddressSelect}
          staffSupportLevels={branchData?.staffSupportLevels}
          pharmacySystems={branchData?.pharmacySystems}
          branchServices={branchData?.branchServices}
          regions={branchData?.regions || []}
        />
      </Form>
    </AddModal>
  );
};
