import { Avatar, Button, Checkbox, DatePicker, Form, Modal } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import HospitalIcon from "../../../../../..//assets/icons/Pharmacy_icon.svg";
import {
  actionTemplateWeekThunk,
  deleteTemplateShiftThunk,
  getBranchDataThunk,
  getBranchTemplateThunk,
  publishTemplateThunk,
} from "../../../../../../redux/branches/branchesThunk";
import {
  getAccommodationAndFoodRulesThunk,
  getMileageRulesThunk,
  getParkingRulesThunk,
  getPharmacySettingsThunk,
  getTransportRulesThunk,
} from "../../../../../../redux/profile/profileThunk";
import {
  getRolesThunk,
  getStaffsThunk,
} from "../../../../../../redux/schedule/scheduleThunk";

import dayjs from "dayjs";
import moment from "moment";
import Calendericon from "../../../../../../assets/icons/calendar.svg";
import closeIcon from "../../../../../../assets/icons/close.svg";
import infoIcon from "../../../../../../assets/icons/infoIcon1.png";
import { ConfirmModal } from "../../../../../../components/UI/modals/confirmModal";
import { ShiftJobFormNew } from "../../../../../../components/comon/AddShiftFormNew";
import PharmacyMonthView from "../../../../../../components/schedule_new/views/PharmacyMonthView";
import WeekViewNew from "../../../../../../components/schedule_new/views/WeekViewNew";
import { getRegionsListDropDownThunk } from "../../../../../../redux/regions/regionsThunk";
import {
  branchApiKeys,
  regionApiKeys,
  settingsApiKeys,
  staffApiKeys,
} from "../../../../../../utils/apiKeyConstants";
import eventEmitter, { events } from "../../../../../../utils/eventEmitter";
import { hasAccess, notify } from "../../../../../../utils/helper";

export const BranchTemplateNew = () => {
  const [form] = Form.useForm();
  const formId = useMemo(() => "form", []);
  const startDate = Form.useWatch("startDate", form);
  const endDate = Form.useWatch("endDate", form);
  const indefinite = Form.useWatch("indefinite", form);

  const navigate = useNavigate();

  const { branchTemplate } = useSelector((state) => state.branches);
  const { pharmacySettings } = useSelector((state) => state.profile);
  const [errorData, setErrorData] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewMonth, setPreviewMonth] = useState(
    dayjs().format("YYYY-MM-DD")
  );

  const dispatch = useDispatch();
  const { id: branchId } = useParams();
  const { branchInfo } = useSelector((state) => state.branches);
  const [showAddShiftModal, setShowAddshiftModal] = useState(false);
  const [showPublishTemplateModal, setShowPublishTemplateModal] =
    useState(false);
  const [flag, setFlag] = useState(true);
  // const [operatingTime, setOperatingTime] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectId, setSelectedId] = useState();
  const [selectedData, setSelectedData] = useState();
  const [selectedDay, setSelectedDay] = useState();
  const [selectedWeek, setSelectedWeek] = useState();
  const [roleTypes, setRoleTypes] = useState([]);

  useEffect(() => {
    if (!flag) return;
    dispatch(getStaffsThunk());
    dispatch(getBranchDataThunk({ id: branchId }));
    if (hasAccess(settingsApiKeys.GET_PHARMACY_SETTINGS))
      dispatch(getPharmacySettingsThunk());
    if (hasAccess(branchApiKeys.GET_TEMPLATE_LIST))
      dispatch(
        getBranchTemplateThunk({
          id: branchId,
        })
      );
    dispatch(
      getRolesThunk({
        group: ["staff"],
        type: "Default",
      })
    );
    setFlag(false);
  }, [dispatch, branchId, flag]);

  useEffect(() => {
    if (!startDate) return;
    if (startDate && indefinite) {
      form.setFieldsValue({
        endDate: dayjs(startDate).add(
          pharmacySettings?.planningPeriod,
          "weeks"
        ),
      });
      return;
    }

    if(!!startDate){
      if(startDate?.isAfter(endDate)){
        form.setFieldsValue({
          endDate: startDate,
        });
      }
    }
   
    // form.setFieldsValue({
    //   endDate: startDate,
    // });
  }, [startDate, indefinite]);

  const handleAddShift = (operatingTime, date, id, data, day, type, week) => {
    if (!id) {
      setSelectedId(branchId);
    } else {
      setSelectedId(id);
    }
    setSelectedWeek(week);
    setSelectedDay(day);
    setShowAddshiftModal(!showAddShiftModal);
    // setOperatingTime(branchInfo?.operatingTime);

    setSelectedDate(date);
    setSelectedData(data);
    setRoleTypes(type);
  };

  const onEditHandler = useCallback((data) => {
    if (!!data?.id) {
      handleAddShift(
        branchInfo?.operatingTime,
        data?.startDate,
        data.branchId,
        data,
        data?.dayOfWeek,
        undefined,
        data?.weekNumber
      );
      return;
    }
  }, []);

  const onDelete = (data) => {
    if (hasAccess(branchApiKeys.DELETE_TEMPLATE)) {
      dispatch(
        deleteTemplateShiftThunk({
          branchId,
          shiftId: data.id,
        })
      ).then((res) => {
        if (res.payload === "success") {
          setFlag(true);
          setShowAddshiftModal(false);
        }
      });
    }
  };

  const actionWeekTemplate = (type, number, weekNumber) => {
    const data = {
      name: type,
      value: number ?? null,
      weekNumber,
      branchId,
    };

    dispatch(
      actionTemplateWeekThunk({
        id: branchId,
        data,
      })
    ).then((res) => {
      if (res.payload) {
        setFlag(true);
        type === "clear" && notify("success", "Shifts cleared successfully");
      }
    });
  };

  const publishTemplate = (values) => {
    const data = {
      startDate: dayjs(values.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(values.endDate).format("YYYY-MM-DD"),
      indefinite: values.indefinite,
      branchId: branchId,
    };
    dispatch(
      publishTemplateThunk({
        id: branchId,
        data,
      })
    ).then((res) => {
      if (res.payload.code === "validation") {
        setShowErrorModal(true);
        setErrorData(res.payload);
      }
      if (res.payload) {
        setShowPublishTemplateModal(false);
        form.resetFields();
        setFlag(true);
      }
    });
  };

  const [updateData, setUpdatedDate] = useState();

  useEffect(() => {
    if (!branchTemplate) return;
    let data = [];
    for (
      var m = moment(previewMonth, "YYYY-MM-DD").startOf("month");
      m.isSameOrBefore(moment(previewMonth, "YYYY-MM-DD").endOf("month"));
      m.add(1, "day")
    ) {
      const date = m.format("YYYY-MM-DD");
      const day = m.format("dddd");
      const weekNumber = m.isoWeek() - moment(m).startOf("month").isoWeek() + 1;
      if (weekNumber === 1) {
        data.push({
          startDate: date,
          endDate: date,
          ...branchTemplate.week1?.events?.find(
            (d) => d.day.toUpperCase() === day.toUpperCase()
          ),
        });
      } else if (m.isoWeek() - moment(m).startOf("month").isoWeek() + 1 === 2) {
        data.push({
          startDate: date,
          endDate: date,
          ...branchTemplate.week2?.events?.find(
            (d) => d.day.toUpperCase() === day.toUpperCase()
          ),
        });
      } else if (m.isoWeek() - moment(m).startOf("month").isoWeek() + 1 === 3) {
        data.push({
          startDate: date,
          endDate: date,
          ...branchTemplate.week3?.events?.find(
            (d) => d.day.toUpperCase() === day.toUpperCase()
          ),
        });
      } else if (m.isoWeek() - moment(m).startOf("month").isoWeek() + 1 === 4) {
        data.push({
          startDate: date,
          endDate: date,
          ...branchTemplate.week4?.events?.find(
            (d) => d.day.toUpperCase() === day.toUpperCase()
          ),
        });
      } else if (m.isoWeek() - moment(m).startOf("month").isoWeek() + 1 === 5) {
        data.push({
          startDate: date,
          endDate: date,
          ...branchTemplate?.week1?.events?.find(
            (d) => d.day.toUpperCase() === day.toUpperCase()
          ),
        });
      } else {
        data.push({
          startDate: date,
          endDate: date,
          ...branchTemplate.week2?.events?.find(
            (d) => d.day.toUpperCase() === day.toUpperCase()
          ),
        });
      }
    }
    setUpdatedDate(data);
  }, [branchTemplate, previewMonth]);

  useEffect(() => {
    eventEmitter.on(events.openEdit, onEditHandler);

    return () => {
      eventEmitter.off(events.openEdit, (data) => onEditHandler(data));
    };
  }, [onEditHandler]);

  useEffect(() => {
    if (hasAccess(settingsApiKeys.GET_SETTINGS_ALL_MILEAGE_RULES))
      dispatch(getMileageRulesThunk());
    if (hasAccess(settingsApiKeys.GET_SETTINGS_PARKING_RULES))
      dispatch(getParkingRulesThunk());
    if (hasAccess(settingsApiKeys.GET_ALL_TRANSPORT_RULES))
      dispatch(getTransportRulesThunk());
    if (hasAccess(settingsApiKeys.GET_ALL_FOOD_RULES))
      dispatch(getAccommodationAndFoodRulesThunk());
    if (hasAccess(regionApiKeys.GET_REGION_DROP_DOWN_LIST))
      dispatch(getRegionsListDropDownThunk());
    dispatch(
      getRolesThunk({
        group: ["staff", "locum"],
        type: "Default",
      })
    );
    if (hasAccess(staffApiKeys.GET_ALL_STAFF)) dispatch(getStaffsThunk());
  }, [dispatch]);

  useEffect(() => {
    if (!showPublishTemplateModal) {
      form.setFieldValue("startDate", null);
      form.setFieldValue("endDate", null);
    }
  }, [showPublishTemplateModal]);

  useEffect(() => {
    !showAddShiftModal && setSelectedDate(null);
  }, [showAddShiftModal]);


  return (
    <div className="weekly-view-container" style={{ height: "100%" }}>
      <ConfirmModal
        backdrop="static"
        title="Hold up!"
        open={showErrorModal}
        confirmButtonText="Close"
        image={infoIcon}
        onConfirm={() => {
          setShowErrorModal(false);
          setFlag(true);
        }}
      >
        <div className="d-flex flex-column gap-3">
          <div>
            There seem to be a few conflicts within this schedule attempt.
            However, we've created shifts for dates prior to and following the
            date(s) of conflict.
          </div>
          <div>
            You can resolve these conflicts and schedule again for the following
            days,
          </div>
        </div>
        <div className="d-flex flex-column gap-3 align-items-center">
          {errorData?.array?.map((data, idx) => (
            <div className="d-flex align-items-center gap-2" key={idx}>
              <div style={{ whiteSpace: "nowrap" }}>{data.date}</div>
              <div style={{ border: "1px solid", height: 20 }} />
              <div>{data.day[0].toUpperCase() + data.day.slice(1)}</div>
              <div style={{ border: "1px solid", height: 20 }} />
              <div>{data.reason}</div>
            </div>
          ))}
        </div>
        {errorData?.successCount > 0 && (
          <div>
            {errorData?.successCount} Shifts/Jobs Were Scheduled Successfully
          </div>
        )}
      </ConfirmModal>

      {showPublishTemplateModal && (
        <ConfirmModal
          title="Publish"
          description={`Select a starting and ending date for the schdeule you just created.`}
          formId={formId}
          open={showPublishTemplateModal}
          confirmButtonText="Publish"
          cancelButtonText="Cancel"
          onClose={() => setShowPublishTemplateModal(false)}
        >
          <div className="d-flex flex-column gap-3">
            <Form
              form={form}
              id={formId}
              layout="vertical"
              autoComplete="off"
              onFinish={publishTemplate}
              className="add_shift_form publish_modal"
            >
              <div
                className="d-flex gap-3 align-items-center"
                style={{ width: "100%" }}
              >
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  style={{ width: "100%" }}
                  className="not-required"
                  rules={[
                    { required: true, message: "Please Enter Start Date!" },
                  ]}
                >
                  <DatePicker
                    inputReadOnly
                    placeholder="Start Date"
                    disabledDate={(current) => {
                      return dayjs().add(-1, "day") >= current;
                    }}
                    format="DD-MM-YYYY"
                    getPopupContainer={(trigger) => trigger.parentElement}
                    className="text-input-field p-2 pe-3 t1"
                    style={{ width: "100%" }}
                    suffixIcon={<img src={Calendericon} alt="dropdown" />}
                  />
                </Form.Item>
                <Form.Item
                  label="End Date"
                  style={{ width: "100%" }}
                  name="endDate"
                >
                  <DatePicker
                    inputReadOnly
                    disabledDate={(current) => {
                      return dayjs(startDate).add(-1, "day") >= current;
                    }}
                    disabled={indefinite}
                    placeholder="End Date"
                    format="DD-MM-YYYY"
                    getPopupContainer={(trigger) => trigger.parentElement}
                    className="text-input-field p-2 pe-3 t1"
                    style={{ width: "100%" }}
                    suffixIcon={<img src={Calendericon} alt="dropdown" />}
                  />
                </Form.Item>
              </div>
              <Form.Item
                style={{ width: "100%" }}
                name="indefinite"
                valuePropName="checked"
              >
                <Checkbox>Indefinite</Checkbox>
              </Form.Item>
            </Form>
          </div>
        </ConfirmModal>
      )}

      {showAddShiftModal && (
        <ShiftJobFormNew
          onSuccess={() => {
            setFlag(true);
          }}
          isTemplate
          onDelete={
            hasAccess(branchApiKeys.DELETE_TEMPLATE) ? onDelete : undefined
          }
          weekNumber={selectedWeek}
          selectedDay={selectedDay}
          branchId={selectId}
          operatingTime={branchInfo?.operatingTime}
          selectedDate={selectedDate}
          showModal={showAddShiftModal}
          setHandleModal={() => {
            setShowAddshiftModal(!showAddShiftModal);
            setSelectedDay(undefined);
          }}
          data={selectedData}
          roleTypes={roleTypes}
        />
      )}

      <div
        className="d-flex align-items-center"
        style={{ padding: "11px 24px", borderBottom: "1px solid #E9E9E9" }}
      >
        <span
          onClick={() => navigate(-1)}
          style={{
            cursor: "pointer",
            display: "inline-block",
            marginRight: 10,
          }}
        >
          <img src={closeIcon} alt="Close Icon" />
        </span>
        <div className="heading4 fw-bold" style={{ color: "#262626" }}>
          Template
        </div>
      </div>

      <div className="px-4">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-start align-items-center pharma_time_sheet py-4">
            {!!branchInfo?.logo ? (
              <Avatar src={branchInfo?.logo} alt="pharma" className="me-2" />
            ) : (
              <Avatar className="me-3">{branchInfo?.title?.charAt(0)}</Avatar>
            )}

            <div className="pharma_name">
              <div className="d-flex align-items-center">
                <h6 className="me-2">{branchInfo?.title}</h6>{" "}
                <p className="mb-0 me-2">{branchInfo?.region}</p>{" "}
              </div>
              {!!branchInfo?.region && (
                <div className="d-flex align-items-center">
                  <img src={HospitalIcon} alt="hospital" className="me-2" />
                  <p className="mb-0">{branchInfo?.region || ""}</p>
                </div>
              )}
            </div>
          </div>
          <div
            className="d-flex align-items-center justify-content-between right-side-container"
            style={{ gap: 15 }}
          >
            <Button
              className="btn-style new-custom-button bordered"
              type="primary"
              onClick={() => {
                setShowPreviewModal(true);
              }}
            >
              Preview
            </Button>
            <Button
              className="btn-style  new-custom-button"
              type="primary"
              onClick={() =>
                hasAccess(branchApiKeys.PUBLISH_TEMPLATE)
                  ? setShowPublishTemplateModal(true)
                  : undefined
              }
            >
              Publish
            </Button>
          </div>
        </div>
        {Object.keys(branchTemplate).map((item, index) => {
          return (
            <WeekViewNew
              key={item}
              addShift
              weekNumber={index + 1}
              isTemplate
              onDelete={onDelete}
              handleAddShift={(...props) => handleAddShift(...props, index + 1)}
              weekDayArray={branchTemplate[item]?.events.map(
                (event) => event.day
              )}
              branches={[{ ...branchTemplate[item], ...branchInfo }] ?? []}
              templateView={true}
              weekName={`Week ${index + 1}`}
              actionWeekTemplate={actionWeekTemplate}
              clearWeekTemplate={() =>
                actionWeekTemplate("clear", null, index + 1)
              }
            />
          );
        })}

        {/* <div className="add-week-button">
          <Button>+ Add Week</Button>
        </div> */}

        <Modal
          centered
          open={showPreviewModal}
          width={"auto"}
          onCancel={() => setShowPreviewModal(false)}
          footer={<></>}
          className="template_preview_modal"
          closeIcon={<img src={closeIcon} alt="Close Icon" />}
        >
          <div className="d-flex gap-3 align-items-center pb-3">
            <div className="h3 fw-bold">{moment().format("MMMM YYYY")}</div>
          </div>
          <PharmacyMonthView
            onDelete={onDelete}
            branchData={updateData}
            branchInfo={branchInfo}
          />
        </Modal>
      </div>
    </div>
  );
};
