import { DatePicker, Form, Input } from "antd";
import React from "react";
import { useMemo } from "react";
import { FilterComponent } from "../Filter";



export const LocumBankFilterComponent = ({
    onApplyFilter
}) => {
    const [form] = Form.useForm();
    const formId = useMemo(() => "locum-bank-filter-form", [])

    const clearFilterFormHandler = () => {
        form.resetFields()
        onApplyFilter()
    }

    const onSubmit = (values) => {
        onApplyFilter(values)
    }

    return (
        <FilterComponent formId={formId} onApplyFilter={form.submit} clearFormHandler={form.isFieldsTouched() && clearFilterFormHandler}>
            <Form
                id={formId}
                onFinish={onSubmit}
                form={form}
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    label="Phone No."
                    name="phNumber"
                >
                    <Input
                        onBlur={e => form.setFieldsValue({ 'phNumber': e.target.value.trim() })}
                        type='number'
                        placeholder="Phone No."
                        className='text-input-field p-2 pe-3 t1'
                    />
                </Form.Item>
                <Form.Item
                    label="Date of Birth"
                    name="dob"
                >
                    <DatePicker inputReadOnly format="DD-MM-YYYY" placeholder="Select Date" className="text-input-field p-2 pe-3 t1" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    label="GphC Number"
                    name="gphcNumber"
                    normalize={value => {
                        if (value.length > 7)
                            return value.slice(0, 7)
                        return value
                    }}
                >
                    <Input
                        onBlur={e => form.setFieldsValue({ 'gphcNumber': e.target.value.trim() })}
                        placeholder="GphC Number"
                        className='text-input-field p-2 pe-3 t1'
                    />
                </Form.Item>
                <Form.Item
                    label="Rating"
                    name="rating"
                >
                    <Input
                        onBlur={e => form.setFieldsValue({ 'rating': e.target.value.trim() })}
                        placeholder="Rating"
                        className='text-input-field p-2 pe-3 t1'
                    />
                </Form.Item>
            </Form>
        </FilterComponent>
    );
};
