export const phoneValidation = [
  { required: true, message: "Please enter the phone number!" },
  {
    min: 10,
    message: "Phone number must be at least 10 digits long.",
  },
  {
    max: 20,
    message: "Phone number can be at most 20 digits long.",
  },
];
