import React from "react";
import classnames from "classnames";

import "./style.scss";

export const ContentContainer = ({
    transparent,
    smallPaddings,
    fluid,
    xSmallPaddings,
    autoHeight,
    fullWidth,
    ...props
}) => {
    return (
        <main
            className={classnames("content-container", {
                transparent,
                smallPaddings,
                fluid,
                autoHeight,
                xSmallPaddings,
            })}
            style={{ width: fullWidth ? 'calc(100% - 20%)' : '100%' }}
        >
            {props.children}
        </main>
    );
};
