import { Route, Routes } from "react-router-dom";
import { BillingListPage } from "./biliingList";

export const BillingPage = () => {
  return (
    <Routes>
      <Route path={`/`} element={<BillingListPage />} />
    </Routes>
  );
};
