import React from "react";
import { FlexRow } from "../../../../../../../components/comon/flexRows";
import { weekDays } from "../../../../../../../utils/dates";

export const branchGeneralInfoTab = (data, generalData) => {
  const region = generalData?.regions?.find((i) => i.id === data?.regionId);

  return (
    <div className="d-flex flex-column border rounded flex-1">
      <div className="s1 text-row m-0">Details</div>
      <FlexRow
        primaryTitle="Branch Name"
        primaryDescription={data?.title === 'null' || data?.title === 'undefined' || data?.title === '' ? '-' : data?.title}
      />
      <FlexRow
        primaryTitle="Branch No."
        primaryDescription={data?.number === 'null' || data?.number === 'undefined' || data?.number === '' ? "-" : data?.number}
      />
      <FlexRow
        primaryTitle="Address Line 1"
        primaryDescription={data?.address1 === 'null' || data?.address1 === 'undefined' || data?.address1 === '' ? '-' : data?.address1}
      />
      <FlexRow
        primaryTitle="Address Line 2"
        primaryDescription={data?.address2 === 'null' || data?.address2 === 'undefined' || data?.address2 === '' ? '-' : data?.address2}
      />
      <FlexRow
        primaryTitle="Town/City"
        primaryDescription={data?.city === 'null' || data?.city === 'undefined' || data?.city === '' ? '-' : data?.city}
      />
      <FlexRow
        primaryTitle="Postal Code"
        primaryDescription={data?.postalCode === 'null' || data?.postalCode === 'undefined' || data?.postalCode === '' ? '-' : data?.postalCode}
      />
      <FlexRow
        primaryTitle="Region"
        primaryDescription={region?.title === 'null' || region?.title === 'undefined' || region?.title === '' ? '-' : region?.title}
      />
      <FlexRow
        primaryTitle="Phone No."
        primaryDescription={data?.phone === 'null' || data?.phone === 'undefined' || data?.phone === '' ? '-' : data?.phone}
      />
      <FlexRow
        primaryTitle="About Branch"
        primaryDescription={data?.description === 'null' || data?.description === 'undefined' || data?.description === '' ? '-' : data?.description}
      />
    </div>
  );
};

export const pharmacyInformationTab = (data, generalData) => {
  const staffLevel = generalData?.staffSupportLevels?.find(
    (i) => i.id === data?.staffSupportLevelId
  );

  const systemUser = generalData?.pharmacySystems?.find(
    (i) => i.id === data?.pharmacySystemId
  );

  const getServices = data?.branchServices
    ? data?.branchServices.flatMap((service) => [
      generalData?.branchServices?.find((item) => item.id === service),
    ])
    : [];
  const servicesItems = getServices?.map((service) => service?.title);

  return (
    <div className="d-flex flex-column border rounded flex-1">
      <div className="s1 text-row m-0">Details</div>
      <FlexRow
        primaryTitle="Item Per Month"
        primaryDescription={data?.itemsPerMonth || "-"}
      />
      <FlexRow
        primaryTitle="MDS Per Month"
        primaryDescription={data?.mdsPerMonth || "-"}
      />
      <FlexRow
        primaryTitle="Methadone Patients Per Day"
        primaryDescription={data?.methadonePatientsPerDay || "-"}
      />
      <FlexRow
        primaryTitle="Care Homes Supplied"
        primaryDescription={data?.careHomesSupplied || "-"}
      />
      <FlexRow
        primaryTitle="Support Level"
        primaryDescription={`${staffLevel?.title} Staff`}
      />
      <FlexRow
        primaryTitle="Systems Used"
        primaryDescription={systemUser?.title}
      />
      <FlexRow
        primaryTitle="Services Provided"
        primaryDescription={servicesItems.join(", ") || "-"}
      />
    </div>
  );
};

export const branchHoursTab = (data) => {
  const _data = weekDays.map((day) => {
    const weekTime = data?.operatingTime?.find(
      ({ dayOfWeek }) => dayOfWeek === day
    );
    return {
      day,
      operatingTime: weekTime,
      rate: weekTime ? weekTime.locumRate : "",
      disabled: !weekTime,
    };
  });

  return (
    <div className="d-flex flex-column border rounded flex-1">
      <div className="s1 text-row m-0">Details</div>
      {_data.map((day, idx) => {
        const workingTime = day.operatingTime && (
          <span>{`${day.operatingTime.startTime} - ${day.operatingTime.endTime}`}</span>
        );
        const lunchTime =
          day.operatingTime && day.operatingTime.lunchStartTime ? (
            <span>{`${day.operatingTime.lunchStartTime} - ${day.operatingTime.lunchEndTime}`}</span>
          ) : null;

        return (
          <FlexRow
            key={idx}
            primaryTitle={day.day}
            primaryDescription={workingTime || "Closed"}
            secondaryTitle={lunchTime ? "Lunch" : null}
            secondaryDescription={lunchTime}
          />
        );
      })}
    </div>
  );
};
