import { useQuery } from "@tanstack/react-query";
import { Avatar, Button, Col, Radio, Row, Space, Typography } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NoLocumJob from "../../../components/EmptyStates/NoLocumJob/NoLocumJob";
import LocumCards from "../../../components/LocumJobs/LocumCards";
import LocumJobDetailsModal from "../../../components/LocumJobs/LocumJobDetailsModal";
import LocumJobStatusChip from "../../../components/LocumJobs/LocumJobStatusChip";
import Locumtable from "../../../components/LocumJobs/locumTable";
import CommonToolbar from "../../../components/Toolbar/CommonToolbar";
import { Heading } from "../../../components/UI/heading";
import BlueTickIcon from "../../../components/UI/icons/BlueTickIcon";
import CancelIcon from "../../../components/UI/icons/CancelIcon";
import PaymentCompletedIcon from "../../../components/UI/icons/PaymentCompletedIcon";
import PaymentPendingIcon from "../../../components/UI/icons/PaymentPendingIcon";
import PlusIcon from "../../../components/UI/icons/PlusIcon";
import StarIcon from "../../../components/UI/icons/StarIcon";
import YellowClock from "../../../components/UI/icons/YellowClock";
import AddJobFormNew from "../../../components/comon/AddJobFormNew";
import LocumJobFilter from "../../../components/locum-shifts/filter-new/LocumJobFilter";
import useDebounce from "../../../hooks/useDebounce";
import useOpenAddModals from "../../../hooks/useOpenAddModals";
import { getbranchesListThunk } from "../../../redux/branches/branchesThunk";
import { cancelJobThunk } from "../../../redux/locum-flow/Thunk";
import {
  acceptJobRequestThunk,
  addJobRatingThunk,
  getJobCancelReasonsThunk,
  getWorkedLocumThunk,
  inviteLocumForJobThunk,
  rejectJobRequestThunk,
  updateJobStatusThunk,
} from "../../../redux/locum-shifts/locumShiftsThunk";
import {
  getAccommodationAndFoodRulesThunk,
  getMileageRulesThunk,
  getParkingRulesThunk,
  getTransportRulesThunk,
} from "../../../redux/profile/profileThunk";
import {
  getRegionsBranchesListThunk,
  getRegionsListDropDownThunk,
} from "../../../redux/regions/regionsThunk";
import {
  getRolesThunk,
  getStaffsThunk,
} from "../../../redux/schedule/scheduleThunk";
import { getjobOffersList } from "../../../redux/super-admin/superAdminAPI";
import {
  branchApiKeys,
  inviteApiKeys,
  locumShiftApiKeys,
  regionApiKeys,
  settingsApiKeys,
  staffApiKeys,
} from "../../../utils/apiKeyConstants";
import { VIEW_OPTIONS_LOCUMJOB } from "../../../utils/constant";
import { hasAccess, notify } from "../../../utils/helper";
import "./style.scss";

const RoleMapping = {
  pharmacist: 4,
  technician: 5,
};

// Destructure API keys outside the component
const {
  GET_SETTINGS_ALL_MILEAGE_RULES,
  GET_SETTINGS_PARKING_RULES,
  GET_ALL_TRANSPORT_RULES,
  GET_ALL_FOOD_RULES,
} = settingsApiKeys;
const { GET_REGION_DROP_DOWN_LIST } = regionApiKeys;
const { GET_ALL_STAFF } = staffApiKeys;
const { GET_BRANCH_LIST } = branchApiKeys;
const { GET_SHORT_BRANCHES } = inviteApiKeys;

const LocumJobsNew = () => {
  const dispatch = useDispatch();

  const [view, setview] = useState("weekly");
  const [locumJobId, setLocumJobId] = useState();
  const [selectedview, setselectedview] = useState("pharmacist"); //! Note 4: pharmacist || 5: technician
  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);
  const [isAddJobModalOpen, setIsAddJobModalOpen] = useState(false);
  const [isJobDetailsModalOpen, setIsJobDetailsModalOpen] = useState(false);
  const [jobStatusType, setJobtStatusType] = useState();
  const [search, setSearch] = useState("");

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(7, "day"));

  const [dateRange, setDateRange] = useState({
    startDateRange: startDate,
    endDateRange: endDate,
  });
  const _searchValue = useDebounce(search, 500);

  const [filterValues, setFilterValue] = useState({
    filters: {
      roleId: [RoleMapping.pharmacist],
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      branchTitles: null,
      tab: "all",
      status: "",
    },
    pagination: {
      page: 1,
      perPage: 10,
    },
  });

  const { roles } = useSelector((state) => {
    return state.schedule;
  });
  const { branches } = useSelector((state) => {
    return state.branches;
  });

  const { data, refetch } = useQuery({
    queryKey: ["fetchjobList", filterValues],
    // getLocumShiftsList
    queryFn: async () => await getjobOffersList(filterValues),
    refetchOnWindowFocus: "always",
  });

  //  FOR DATA FETCHING
  useEffect(() => {
    if (hasAccess(GET_SHORT_BRANCHES)) dispatch(getRegionsBranchesListThunk());
    if (hasAccess(GET_SETTINGS_ALL_MILEAGE_RULES)) {
      dispatch(getMileageRulesThunk());
    }
    if (hasAccess(GET_SETTINGS_PARKING_RULES)) dispatch(getParkingRulesThunk());
    if (hasAccess(GET_ALL_TRANSPORT_RULES)) dispatch(getTransportRulesThunk());
    if (hasAccess(GET_ALL_FOOD_RULES))
      dispatch(getAccommodationAndFoodRulesThunk());
    if (hasAccess(locumShiftApiKeys.GET_CANCELLATION_REASON)) {
      dispatch(getJobCancelReasonsThunk());
    }
    if (hasAccess(staffApiKeys.GET_STAFF_WORKED_LOCUMS)) {
      dispatch(getWorkedLocumThunk({ roleId: 4 }));
    }
    if (hasAccess(GET_REGION_DROP_DOWN_LIST))
      dispatch(getRegionsListDropDownThunk());
    dispatch(
      getRolesThunk({
        group: ["staff", "locum"],
        type: "Default",
      })
    );
    if (hasAccess(GET_ALL_STAFF)) dispatch(getStaffsThunk());

    if (hasAccess(GET_BRANCH_LIST)) {
      dispatch(
        getbranchesListThunk({
          filters: {
            title: "",
          },
          pagination: { page: 1, perPage: 100 },
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    setFilterValue((prevFilterValues) => ({
      ...prevFilterValues,
      filters: {
        ...prevFilterValues.filters,
        branchTitles: _searchValue?.length ? [_searchValue] : [],
      },
    }));
  }, [_searchValue]);

  useEffect(() => {
    const viewToDates = {
      weekly: {
        startDateRange: moment().startOf("isoWeek"),
        endDateRange: moment().endOf("isoWeek"),
      },
      daily: {
        startDateRange: moment(),
        endDateRange: moment(),
      },
      monthly: {
        startDateRange: moment().startOf("month"),
        endDateRange: moment().endOf("month"),
      },
    };

    if (viewToDates.hasOwnProperty(view)) {
      const dateData = viewToDates[view];
      setDateRange(dateData);

      if (view !== "daily") {
        setFilterValue((prevFilterValues) => ({
          ...prevFilterValues,
          filters: {
            ...prevFilterValues.filters,
            startDate: dateData.startDateRange.format("YYYY-MM-DD"),
            endDate: dateData.endDateRange.format("YYYY-MM-DD"),
          },
        }));
      }
    }
  }, [view]);

  const HandleAddJobModal = () => {
    setIsAddJobModalOpen((prev) => !prev);
  };

  const HandleFilterValues = (filter_val) => {
    setFilterValue({
      ...filterValues,
      filters: {
        ...filterValues.filters,
        ...filter_val,
      },
    });
    setIsFilterPopoverOpen(false);
  };

  const onSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      HandleFilterValues({ branchTitles: _searchValue });
    }
  };

  const onSearchValueChange = (e) => {
    setSearch(e.target.value);
  };

  const HandleJobDetailsModalOpen = (value) => {
    setIsJobDetailsModalOpen(true);
    setJobtStatusType(value.status);
    setLocumJobId(value.id);
  };

  const handleJobDetailsModalClose = () => {
    setIsJobDetailsModalOpen(false);
  };

  const HandleRoleChange = (e) => {
    setselectedview(e.target.value);

    HandleFilterValues({ roleId: [RoleMapping[e.target.value]] || [] });
  };

  const handleViewChange = (view) => {
    setview(view);
  };

  const handlePerPage = (perPage = 10) => {
    setFilterValue({
      ...filterValues,
      pagination: {
        ...filterValues.pagination,
        perPage,
      },
    });
  };

  const handleMoveToPage = (page = 1) => {
    setFilterValue({
      ...filterValues,
      pagination: {
        ...filterValues.pagination,
        page,
      },
    });
  };

  const handlePrevious = useCallback(() => {
    if (view === "weekly") {
      const _dateStart = moment(dateRange?.startDateRange).subtract(1, "weeks");
      const _dateEnd = moment(dateRange?.endDateRange).subtract(1, "weeks");

      setDateRange({
        startDateRange: _dateStart,
        endDateRange: _dateEnd,
      });

      HandleFilterValues({
        startDate: _dateStart.format("YYYY-MM-DD"),
        endDate: _dateEnd.format("YYYY-MM-DD"),
      });
    }

    if (view === "monthly") {
      const _dateStart = moment(dateRange?.startDateRange).subtract(
        1,
        "months"
      );
      const _dateEnd = moment(dateRange?.endDateRange).subtract(1, "months");

      setDateRange({
        startDateRange: _dateStart,
        endDateRange: _dateEnd,
      });

      HandleFilterValues({
        startDate: _dateStart.format("YYYY-MM-DD"),
        endDate: _dateEnd.format("YYYY-MM-DD"),
      });
    }
  }, [dispatch, dateRange?.endDateRange, dateRange?.startDateRange, view]);

  const handleNext = useCallback(() => {
    if (view === "weekly") {
      const _dateStart = moment(dateRange?.startDateRange).add(1, "weeks");
      const _dateEnd = moment(dateRange?.endDateRange).add(1, "weeks");

      setDateRange({
        startDateRange: _dateStart,
        endDateRange: _dateEnd,
      });

      setFilterValue({
        ...filterValues,
        filters: {
          ...filterValues.filters,
          startDate: _dateStart.format("YYYY-MM-DD"),
          endDate: _dateEnd.format("YYYY-MM-DD"),
        },
      });
    }

    if (view === "monthly") {
      const _dateStart = moment(dateRange?.startDateRange).add(1, "months");
      const _dateEnd = moment(dateRange?.endDateRange).add(1, "months");

      setDateRange({
        startDateRange: _dateStart,
        endDateRange: _dateEnd,
      });

      setFilterValue({
        ...filterValues,
        filters: {
          ...filterValues.filters,
          startDate: _dateStart.format("YYYY-MM-DD"),
          endDate: _dateEnd.format("YYYY-MM-DD"),
        },
      });
    }
  }, [dispatch, dateRange?.endDateRange, dateRange?.startDateRange, view]);

  // Calculate the sum for each key grouped by roleId
  const RoleJobCount = useMemo(() => {
    if (data?.data?.data?.jobsStats) {
      const sumsByRoleId = data?.data?.data?.jobsStats?.reduce((acc, obj) => {
        const roleId = obj.roleId;
        Object.keys(obj).forEach((key) => {
          if (key !== "roleId") {
            acc[roleId] = (acc[roleId] || 0) + parseInt(obj[key], 10);
          }
        });
        return acc;
      }, {});

      return sumsByRoleId;
    }

    return { pharmacist: 0, technician: 0 };
  }, [data?.data?.data]);

  const handlePopoverOpenChange = () => {
    setIsFilterPopoverOpen((prev) => !prev);
  };

  const RenderValue = useMemo(() => {
    if (view === "weekly") {
      return `${dateRange?.startDateRange?.format(
        "DD MMM"
      )} - ${dateRange?.endDateRange?.format("DD MMM YYYY")}`;
    }

    if (view === "monthly") {
      return `${dateRange?.startDateRange?.format("MMM YYYY")}`;
    }

    if (view === "daily") {
      return `${dateRange?.startDateRange?.format("DD MMM YYYY")}`;
    }

    return "";
  }, [dateRange?.endDateRange, dateRange?.startDateRange, view]);

  const getSubtitle = useCallback(
    (selectedview, roleId, keysToSum) => {
      const roleStats = data?.data?.data?.jobsStats.find(
        (stat) => stat.roleId === roleId
      );

      if (!roleStats) return null;

      if (keysToSum) {
        return keysToSum.reduce((sum, key) => sum + Number(roleStats[key]), 0);
      }

      return roleStats[selectedview];
    },
    [JSON.stringify(data?.data?.data?.jobsStats)]
  );

  const InfoCards = [
    {
      title: "Unfilled",
      subtitle:
        getSubtitle(selectedview, selectedview === "pharmacist" ? 4 : 5, [
          "unfilled",
        ]) ?? 0,
      icon: "",
    },
    {
      title: "Pending Review",
      subtitle:
        getSubtitle(selectedview, selectedview === "pharmacist" ? 4 : 5, [
          "pending",
        ]) ?? 0,
      icon: <YellowClock />,
      iconBg: "rgba(247, 144, 9, 0.15)",
    },
    {
      title: "Assigned",
      subtitle:
        getSubtitle(selectedview, selectedview === "pharmacist" ? 4 : 5, [
          "active",
        ]) ?? 0,
      icon: <BlueTickIcon />,
      iconBg: "rgba(10, 146, 208, 0.15)",
    },
    {
      title: "Payment Pending",
      subtitle:
        getSubtitle(selectedview, selectedview === "pharmacist" ? 4 : 5, [
          "payment_approved",
        ]) ?? 0,
      icon: <PaymentPendingIcon />,
      iconBg: "rgba(235, 243, 246, 1)",
    },
    {
      title: "Completed & Paid",
      subtitle:
        getSubtitle(selectedview, selectedview === "pharmacist" ? 4 : 5, [
          "paid",
          "completed",
        ]) ?? 0,
      icon: <PaymentCompletedIcon />,
      iconBg: "rgba(2, 121, 72, 0.1)",
    },
    {
      title: "Cancelled ",
      subtitle:
        getSubtitle(selectedview, selectedview === "pharmacist" ? 4 : 5, [
          "cancelled",
        ]) ?? 0,
      icon: <CancelIcon />,
      iconBg: "rgba(252, 239, 239, 1)",
    },
  ];

  const locumsCols = [
    {
      Header: "Locum Date",
      accessor: "startDate",
      Cell: ({ row: { original } }) => {
        return (
          <Space direction="vertical" className="locumdate">
            <p>{moment(original?.startDate).format("DD MMMM ") ?? "---"}</p>

            <Typography.Text>{original.status || "-"}</Typography.Text>
          </Space>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row: { original } }) => {
        return (
          <LocumJobStatusChip
            status={original?.status}
            handleOnclick={() => HandleJobDetailsModalOpen(original)}
          />
        );
      },
    },
    {
      Header: "Branch",
      accessor: "branch",
      Cell: ({ row: { original } }) => {
        return (
          <Space className="branch">
            {!!original?.logo ? (
              <Avatar size={24} src={original.logo} />
            ) : (
              !!original.branchTitle && (
                <Avatar size={24}>{original.branchTitle[0]}</Avatar>
              )
            )}
            <Typography.Text>{original?.branchTitle}</Typography.Text>
          </Space>
        );
      },
    },
    {
      Header: "Locum",
      accessor: "locum",
      Cell: ({ row: { original } }) => {
        const isPending = original?.status === "pending";

        return (
          <Space className="locum">
            {isPending ? (
              <>
                <Avatar size={24}>{original?.applicationsCount}</Avatar>
                <Typography.Text>Locums to review</Typography.Text>
              </>
            ) : (
              <>
                {!!original.locumName && (
                  <Avatar size={24}>{original.locumName[0]}</Avatar>
                )}

                <Typography.Text>{original.locumName ?? "-"}</Typography.Text>
              </>
            )}
          </Space>
        );
      },
    },
    {
      Header: "Total",
      accessor: "total",
      Cell: ({ row: { original } }) => {
        return original?.total > 0 ? (
          <div className="total">£{original.total}</div>
        ) : (
          "-"
        );
      },
    },
    {
      Header: "Ratings",
      accessor: "ratingValue",
      Cell: ({ row: { original } }) => {
        return original?.ratingValue > 0 ? (
          <Space className="ratings">
            <StarIcon /> {Number(original?.ratingValue).toFixed(1) ?? 0.0}
          </Space>
        ) : (
          <Space className="ratings">
            <StarIcon iconColor="#C4C4C4" /> No Ratings
          </Space>
        );
      },
    },
  ];

  const LocumRolesOptions = useMemo(() => {
    if (!roles) return;

    return roles
      .filter((item) => item.group === "locum")
      .map((i) => {
        return {
          label: i.label,
          value: i.id,
        };
      });
  }, [roles]);

  const BranchOptions = useMemo(() => {
    if (!branches.rows) return;

    return branches.rows.map((i) => {
      return {
        label: i.title,
        value: i.id,
      };
    });
  }, [branches.rows]);

  const handleRating = (ratingFormData) => {
    console.log("ratingFormData: ", ratingFormData);

    dispatch(
      addJobRatingThunk({
        jobId: locumJobId,
        ...ratingFormData,
      })
    ).then((res) => {
      if (
        res?.type === "add-job-rating/fulfilled" ||
        res?.payload === "Success"
      ) {
        handleJobDetailsModalClose();
        notify("success", "Review Added Successful");
        refetch();
      }
    });
  };

  const onAcceptJobRequest = (jobId, locumId) => {
    dispatch(
      acceptJobRequestThunk({
        jobId: jobId,
        locumId,
      })
    ).then((res) => {});
  };

  const onRejectJobRequest = (jobId, locumId) => {
    dispatch(
      rejectJobRequestThunk({
        jobId: jobId,
        locumId,
      })
    ).then((res) => {});
  };

  //  jobStatus: {should be current job status} shiftInfo?.status
  const handleUpdateJobStatus = (jobId, jobStatus) => {
    if (jobStatus === "completed") {
      dispatch(
        updateJobStatusThunk({
          shiftId: jobId,
          status: "payment_approved",
        })
      ).then((res) => {
        if (res) {
          if (res?.payload?.status === "payment_approved") {
            notify(
              "success",
              'The current job has been shifted to the "Payment Approved" status. '
            );
            refetch();
          }
        }
      });
    } else if (jobStatus === "payment_approved") {
      dispatch(
        updateJobStatusThunk({
          shiftId: jobId,
          status: "paid",
        })
      ).then((res) => {
        if (res) {
          if (res?.payload?.status === "paid") {
            notify(
              "success",
              'The current job has been shifted the "Paid" status, you can now rate this Locum Job.'
            );
            refetch();
          }
        }
      });
    }
    handleJobDetailsModalClose();
  };

  const onCancelJob = (values) => {
    dispatch(
      cancelJobThunk({
        id: locumJobId,
        data: { cancelData: values },
      })
    ).then((res) => {
      console.log("res?.type: ", res?.type);
      if (res?.type == "cancelJob/fulfilled") {
        notify("success", "Job cancellation successful");
        refetch();
      }
    });
  };

  const onInviveLocum = (value) => {
    dispatch(
      inviteLocumForJobThunk({
        jobId: locumJobId,
        locumId: value,
      })
    ).then((res) => {
      if (res.payload) {
        notify("success", "Invitation Sent");
      }
    });
  };

  useOpenAddModals("addjob", `/locum-jobs`, () => {
    HandleAddJobModal();
  });

  const _hasFilter = useMemo(() => {
    return (
      Boolean(filterValues?.filters?.branchTitles?.length) ||
      Boolean(filterValues?.filters?.status?.length)
    );
  }, [filterValues?.filters]);

  return (
    <div className="content-view-page details-page">
      <div className="main-wrapper">
        <div className="dashboard-page">
          <Heading
            label="Locum Jobs"
            dateFilter
            lastupated={"10 min"}
            view="weekly"
            lastupdatedPosition="right"
          />

          <div className="content-container-dashboard p-4">
            <div className="d-flex align-items-center">
              <div className="common-toolbar-wrapper">
                <CommonToolbar
                  displayOptions={{
                    hideSearch: true,
                    hideFilter: true,
                    hideRole: true,
                    hideJobs: true,
                    hideView: false,
                  }}
                  selectedView={view}
                  handlePrevious={handlePrevious}
                  handleNext={handleNext}
                  RenderValue={RenderValue}
                  handleViewChange={handleViewChange}
                  viewOptions={VIEW_OPTIONS_LOCUMJOB}
                />
              </div>

              <Radio.Group
                className="locum-radio-group"
                value={selectedview}
                onChange={HandleRoleChange}
              >
                <Radio.Button value="pharmacist">
                  Pharmacist {`(${RoleJobCount?.["4"] ?? 0})`}
                </Radio.Button>
                <Radio.Button value="technician">
                  Technician {`(${RoleJobCount?.["5"] ?? 0})`}
                </Radio.Button>
              </Radio.Group>
            </div>

            <Row className="data-tabs-wrapper" gutter={[16, 16]}>
              {InfoCards.map((item) => (
                <Col span={4} key={item.title}>
                  <LocumCards
                    title={item.title}
                    subtitle={item.subtitle}
                    icon={item.icon}
                    iconBackground={item.iconBg}
                  />
                </Col>
              ))}
            </Row>

            <CommonToolbar
              displayOptions={{
                hideSearch: false,
                hideFilter: false,
                hideRole: true,
                hideJobs: true,
                hideView: true,
                hideCalendar: true,
              }}
              searchPlaceHolder="Search By Branch Name"
              searchInputOnChange={onSearchValueChange}
              searchInputValue={search}
              searchInputonKeyDown={onSearchKeyDown}
              popoverOpen={isFilterPopoverOpen}
              handlePopoverOpenChange={handlePopoverOpenChange}
              filterPopover={
                <LocumJobFilter
                  filterValues={filterValues.filters}
                  onApply={HandleFilterValues}
                  open={isFilterPopoverOpen}
                  onCancel={handlePopoverOpenChange}
                />
              }
              endAdroment={
                <Button
                  className="locum-btn"
                  icon={<PlusIcon />}
                  onClick={HandleAddJobModal}
                >
                  Add Locum Job
                </Button>
              }
              _hasFilter={_hasFilter}
            />

            <div className="locum-table-container">
              {/* {view === '4weeks' && (
                <div className="table-header py-3 px-4">
                  <CustomTableHeader
                    weekCount={4}
                    selectedWeek={selectedWeek}
                    setSelectedWeek={setSelectedWeek}
                  />
                </div>
              )} */}

              {data?.data?.data?.rows?.length ? (
                <Locumtable
                  tableCols={locumsCols}
                  data={data?.data}
                  // hideFooter={view === '4weeks'}
                  page={filterValues.pagination.page}
                  perPage={filterValues.pagination.perPage}
                  handlePerPage={handlePerPage}
                  handleMoveToPage={handleMoveToPage}
                />
              ) : (
                <NoLocumJob />
              )}
            </div>
          </div>
        </div>
      </div>

      {isAddJobModalOpen && (
        <AddJobFormNew
          showModal={isAddJobModalOpen}
          setshowModal={HandleAddJobModal}
          locumRolesOptions={LocumRolesOptions}
          branchOptions={BranchOptions}
        />
      )}

      {isJobDetailsModalOpen && (
        <LocumJobDetailsModal
          showModal={isJobDetailsModalOpen}
          setshowModal={handleJobDetailsModalClose}
          status={jobStatusType}
          id={locumJobId}
          locumRolesOptions={LocumRolesOptions}
          branchOptions={BranchOptions}
          handleRating={handleRating}
          handleAcceptJobRequest={onAcceptJobRequest}
          handleRejectJobRequest={onRejectJobRequest}
          handleUpdateJobStatus={handleUpdateJobStatus}
          onCancelJob={onCancelJob}
          onInviveLocum={onInviveLocum}
        />
      )}
    </div>
  );
};

export default LocumJobsNew;
