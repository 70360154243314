import { Popover } from "antd";
import React, { useState } from "react";
import { leaveTypeIcons, leaveTypes } from "../../../utils/constant";
import LeaveDetailsView from "./LeaveDeatilsView";

const LeaveDetails = ({ leave, role }) => {
  const [openPopover, setOpenPopover] = useState(false);

  const handlePopover = () => {
    setOpenPopover(!openPopover);
  };

  return (
    <Popover
      content={
        <LeaveDetailsView role={role} onClose={handlePopover} leave={leave} />
      }
      trigger="click"
      open={openPopover}
      showArrow={false}
      placement="rightTop"
      onOpenChange={handlePopover}
    >
      <div
        className={`leave_table_td leave_table sick_leave ${
          leave?.sickleave && "sick_leave"
        }`}
      >
        <h6 className="">
          {/* {monthView && leave?.startDate ? moment(leave?.startDate).format("DD") : ""}
      {weekview ? `${leave?.employee?.name} ${leave?.employee?.surname}` : leave?.name} */}
          {leave?.name} {leave?.surname}
        </h6>
        <div className="d-flex py-1  align-items-center justify-content-between">
          <p
            style={{
              overflowX: "visible !important",
            }}
          >
            {leave?.branchName}
          </p>{" "}
          {leaveTypeIcons[leave?.type || leaveTypes.annual_leave]}
        </div>
      </div>
    </Popover>
  );
};

export default LeaveDetails;
