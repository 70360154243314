import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CustomTabs } from "../../../../../components/UI/tabs";
import PersonalInfo from "../../../../../components/staff-information/PersonalInfo";
import JobInfoTab from "../../../../../components/staff-job-information/JobInfoTab";
import { AddLocumBankThunk } from "../../../../../redux/locum-bank/locumBankThunk";
import { getStaffCurrentContract } from "../../../../../redux/staffs/staffsAPI";
import { getStaffDataThunk } from "../../../../../redux/staffs/staffsThunk";
import { staffApiKeys } from "../../../../../utils/apiKeyConstants";
import { hasAccess } from "../../../../../utils/helper";
import "./style.scss";

export const StaffInformation = () => {
  const dispatch = useDispatch();
  const { staffId } = useParams();
  const [tabs, setTabs] = useState([]);
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const { staffInfo } = useSelector((state) => state.staffs);

  const onSuccess = () => {
    if (hasAccess(staffApiKeys.GET_STAFF_PROFILE)) {
      dispatch(getStaffDataThunk({ staffId }));
    }
  };

  const { data } = useQuery({
    queryKey: ["getStaffCurrentContract", staffInfo?.group, staffId],
    queryFn: () => getStaffCurrentContract({ staffId }),
    enabled: !!staffId && staffInfo?.group === "staff",
  });

  const getTabsData = useCallback(() => {
    const tabs = [
      {
        title: "Personal Information",
        content: <PersonalInfo onSuccess={onSuccess} details={staffInfo} />,
      },
      {
        ...(staffInfo?.group === "staff" && {
          title: "Contract Details",
          content: <JobInfoTab canEdit={true} data={data?.data} />,
        }),
      },
    ];
    return tabs;
  }, [staffInfo, JSON.stringify(data?.data)]);

  useEffect(() => {
    const _tabs = getTabsData();
    setTabs(_tabs);
  }, [JSON.stringify(staffInfo), JSON.stringify(data?.data)]);

  const addLocumBank = (data) => {
    const reqData = {
      gphcNumber: data?.gphcInfo?.gphcNumber,
      email: data.email,
      phone: data.phone,
      userId: data.id,
      gphcId: data?.gphcInfo?.id,
    };

    dispatch(AddLocumBankThunk(reqData)).then((res) => {
      if (res.payload) {
        window.analytics.track("Locum Bank Added", {
          gphcNumber: reqData.gphcNumber,
          email: reqData.email,
          phone: reqData.phone,
        });
        if (hasAccess(staffApiKeys.GET_STAFF_PROFILE)) {
          dispatch(getStaffDataThunk({ staffId }));
        }
      }
    });
  };

  return (
    <div className="branch-info-page rounded-4">
      <CustomTabs
        tabs={tabs}
        currentActiveTab={currentActiveTab}
        setCurrentActiveTab={setCurrentActiveTab}
        // onEditHandler={
        //   staffInfo?.group === "staff" &&
        //   currentActiveTab === 0 &&
        //   onEditHandler
        // }
        staffInfo={staffInfo}
        addLocumBank={
          staffInfo?.group === "locum" && currentActiveTab === 0 && addLocumBank
        }
      />

      {/* {editTab === "Personal Information" ? (
        <div
          className="d-flex flex-column align-items-start add-shift-modal-content"
          style={{ gap: "15px", color: "#7B7B7B", paddingTop: "25px" }}
        >
          <PersonalInfoForm setEditTab={setEditTab} />
        </div>
      ) : (
        <AddModal
          title="Edit Accrediations"
          handleModal={showAccrediationModal}
          setHandleModal={() => {
            setEditTab("");
            setShowAccrediationModal(!showAccrediationModal);
          }}
          primaryButtonHandler={onAccrediationEdit}
          primaryButtonText="Update"
          secondaryButtonText="Cancel"
        >
          <div className="d-flex gap-3 flex-column">
            {accrediationData?.map((data, idx) => (
              <div key={idx}>
                <div className="p3" style={{ color: "#7B7B7B" }}>
                  {data.label}
                </div>
                <Upload
                  accept={"image/*, .doc, .pdf"}
                  fileList={data?.filePath?.fileList}
                  onChange={async ({ file, fileList }) => {
                    try {
                      const compressedFile = await compressImage(file);
                      _onChangeHandler(
                        { file: compressedFile, fileList },
                        data.name
                      );
                    } catch (error) {
                      notify("error", "Error compressing the image:");
                    }
                  }}
                  onPreview={() => {}}
                  beforeUpload={beforeUpload}
                  multiple={false}
                  maxCount={1}
                  listType="picture"
                >
                  {(!data?.filePath?.fileList.length ||
                    data?.filePath?.fileList[0]?.status === "removed") && (
                    <Button
                      className="my-3"
                      style={{
                        width: "100%",
                        height: "40px",
                        color: "#51C2BC",
                        border: "2px dashed #51C2BC",
                        backgroundColor: "#F0F9F9",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      icon={<UploadOutlined />}
                    >
                      Upload Document
                    </Button>
                  )}
                </Upload>
              </div>
            ))}
          </div>
        </AddModal>
      )} */}
    </div>
  );
};
