import { Form, Radio, TimePicker } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import React from "react";
import { useCallback } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomCheckbox } from "../../../../../components/UI/checkboxes/checkbox";
import { AddModal } from "../../../../../components/UI/modals/addModal";
import { ConfirmModal } from "../../../../../components/UI/modals/confirmModal";
import { addBankHolidayThunk } from "../../../../../redux/profile/profileThunk";
import { getRegionsBranchesListThunk } from "../../../../../redux/regions/regionsThunk";
import { inviteApiKeys } from "../../../../../utils/apiKeyConstants";
import { getDuration, getDurationFormat } from "../../../../../utils/dates";
import { hasAccess, notify } from "../../../../../utils/helper";

const TIME_FORMAT = "HH:mm";

export const AddScheduleHoliday = ({
  selectedHolidayDate,
  showModal,
  setShowModal,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formId = useMemo(() => "multiAddHoliday", []);
  const branchClosed = Form.useWatch("branchClosed", form);

  const startTime = Form.useWatch("startTime", form);
  const endTime = Form.useWatch("endTime", form);
  const { regionBranches } = useSelector((state) => state.regions);
  const [formData, setFormData] = useState([])
  const [showBulkEditModal, setShowBulkEditModal] = useState(false)
  const [flag, setFlag] = useState(true)

  useEffect(() => {
    if (hasAccess(inviteApiKeys.GET_SHORT_BRANCHES))
      dispatch(getRegionsBranchesListThunk());
  }, [dispatch]);

  useEffect(() => {
    if (!regionBranches.length && !flag) return;
    const data = regionBranches?.map((i) => {
      return {
        isChecked: false,
        title: i.title,
        branchId: i.id,
        startTime: dayjs(),
        endTime: dayjs(),
        dayOfWeek: dayjs(selectedHolidayDate).format("dddd").toLowerCase(),
        branchClosed: true,
        date: selectedHolidayDate,
      };
    });
    setFormData(data);
    setFlag(false);
  }, [regionBranches, selectedHolidayDate, flag]);

  const onChange = (value, name, id) => {
    const index = formData.findIndex((i) => i.branchId === id);
    const _formData = [...formData];

    if (name === "branchClosed") {
      _formData[index]["isChecked"] = !value;
    }

    _formData[index][name] = value;
    setFormData(_formData);
  };

  const clearAllHandler = () => {
    const data = formData.map((i) => ({
      isChecked: false,
      title: i.title,
      branchId: i.branchId,
      startTime: dayjs(),
      endTime: dayjs(),
      dayOfWeek: dayjs(selectedHolidayDate).format("dddd").toLowerCase(),
      branchClosed: true,
      date: selectedHolidayDate,
    }));
    setFormData(data);
  };

  const onMultiApply = (values) => {
    const _data = _.cloneDeep(formData);
    const _formData = _data.map((data) => ({
      ...data,
      branchClosed: values?.branchClosed,
      startTime: values?.startTime,
      endTime: values?.endTime,
    }));

    const data = _formData.filter((i) => i.isChecked === true);
    const bankholidays = data.map((i) => {
      delete i.isChecked;
      delete i.title;
      return {
        ...i,
        startTime: i.startTime?.format("HH:mm"),
        endTime: i.endTime?.format("HH:mm"),
      };
    });

    dispatch(
      addBankHolidayThunk({
        bankholidays,
      })
    ).then((res) => {
      if (res.payload.status === "success") {
        notify("success", "Holiday Successfully Added to Scheduler!!");
        setShowModal(false);
        setShowBulkEditModal(false);
        form.resetFields();
        setFlag(true);
      }
    });
  };

  const onSubmit = useCallback(() => {
    const _data = _.cloneDeep(formData);
    const data = _data.filter((i) => i.isChecked === true);
    const bankholidays = data.map((i) => {
      delete i.isChecked;
      delete i.title;
      return {
        ...i,
        startTime: i.startTime.format("HH:mm"),
        endTime: i.endTime.format("HH:mm"),
      };
    });

    dispatch(
      addBankHolidayThunk({
        bankholidays,
      })
    ).then((res) => {
      if (res.payload.status === "success") {
        notify("success", "Holiday Successfully Added to Scheduler!!");
        setShowBulkEditModal(false);
        setShowModal(false);
        setFlag(true);
      }
    });
  }, [dispatch, formData, setShowModal]);

  const _disabledHours = (disableFor) => {
    let disabledHours = []


    if (disableFor === 'startTime') {
      for (let i = Number(endTime?.format('H')) + 1; i < 24; i++) {
        disabledHours.push(i)
      }
      return [...disabledHours]
    }

    if (disableFor === 'endTime') {
      for (let i = 0; i < Number(startTime?.format('H')); i++) {
        disabledHours.push(i)
      }
      return [...disabledHours]
    }
  }

  const _disabledMinute = (disableFor, selectedHour) => {
    const minutes = [];

    if (disableFor === 'startTime') {
      if (selectedHour === Number(endTime?.format('H'))) {
        const currentMinute = Number(endTime?.format('mm'));
        for (let i = currentMinute + 1; i <= 60; i++) {
          minutes.push(i);
        }
        return minutes;
      }
    }

    if (disableFor === 'endTime') {
      if (selectedHour === Number(startTime?.format('H'))) {
        const currentMinute = Number(startTime?.format('mm'));
        for (let i = currentMinute - 1; i >= 0; i--) {
          minutes.push(i);
        }
        return minutes;
      }
    }
  }

  return (
    <AddModal
      title="Add to Scheduler"
      handleModal={showModal}
      setHandleModal={setShowModal}
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      primaryButtonHandler={onSubmit}
      secondaryButtonHandler={() => setShowModal(false)}
    >
      <div
        className="d-flex flex-column align-items-start add-shift-modal-content"
        style={{ gap: "15px", color: "#7B7B7B" }}
      >
        {formData?.map((data, index) => (
          <div
            key={index}
            className="d-flex flex-column border-bottom"
            style={{ width: "100%", gap: "15px", minHeight: 40 }}
          >
            <div
              className="d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <CustomCheckbox
                name="branchClosed"
                label={data.title}
                className="end-date-checkbox"
                checked={data?.isChecked}
                onChange={(e, { checked }) =>
                  onChange(!checked, "branchClosed", data.branchId)
                }
              />
            </div>
            <div
              className={`border rounded p-3 d-flex flex-column day-box ${!data?.isChecked ? "d-none" : "d-block"
                } `}
              style={{
                width: "100%",
                gap: "15px",
              }}
            >
              {data?.isChecked && (
                <div className="d-flex flex-column">
                  <div className="d-flex my-3 gap-5 justify-content-between">
                    <div className="b1">Shift Times</div>
                    <div>
                      {getDurationFormat(
                        getDuration(data?.startTime, data?.endTime)
                      )}
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-between"
                    style={{ gap: 15 }}
                  >
                    <div
                      className="d-flex flex-column gap-1 justify-content-between"
                      style={{ background: "light-grey", width: "100%" }}
                    >
                      <div>Start Time</div>
                      <TimePicker
                        inputReadOnly
                        allowClear={false}
                        value={dayjs(data.startTime)}
                        onChange={(time) => onChange(time, "startTime", data.branchId)}
                        onSelect={(time) => onChange(time, "startTime", data.branchId)}
                        format={TIME_FORMAT}
                        className="text-input-field p-2 pe-3 t1"
                        minuteStep={5}
                        style={{ width: "100%" }}
                        getPopupContainer={(trigger) => trigger.parentElement}
                      />
                    </div>
                    <div
                      style={{ background: "light-grey", width: "100%" }}
                      className="d-flex flex-column gap-1 justify-content-between"
                    >
                      <div>End Time</div>
                      <TimePicker
                        inputReadOnly
                        allowClear={false}
                        value={dayjs(data.endTime)}
                        onChange={(time) => onChange(time, "endTime", data.branchId)}
                        onSelect={(time) => onChange(time, "endTime", data.branchId)}
                        format={TIME_FORMAT}
                        className="text-input-field p-2 pe-3 t1"
                        minuteStep={5}
                        style={{ width: "100%" }}
                        getPopupContainer={(trigger) => trigger.parentElement}
                      />
                    </div >
                  </div >
                </div >
              )}
            </div >
          </div >
        ))}
        {
          formData?.findIndex((i) => i.isChecked) >= 0 && (
            <div
              className=" position-fixed p-3 border rounded d-flex align-items-center justify-content-between"
              style={{
                width: "-webkit-fill-available",
                bottom: "100px",
                backgroundColor: "white",
                marginRight: 24,
              }}
            >
              <div
                className="cursor-pointer"
                style={{ textDecoration: "underline", color: "#51C2BC" }}
                onClick={clearAllHandler}
              >
                Clear All
              </div>
              <div
                onClick={() => setShowBulkEditModal(true)}
                className="px-3 py-2 cursor-pointer border rounded"
                style={{
                  border: "1px solid #51C2BC",
                  backgroundColor: "#51C2BC",
                  color: "white",
                }}
              >
                EDIT
              </div>
            </div>
          )
        }
        <ConfirmModal
          title="Shift Time"
          description="In the event of clicking 'Apply'. All the selected branches will subject to the entered operating hours."
          open={showBulkEditModal}
          onClose={() => setShowBulkEditModal(false)}
          cancelButtonText="Cancel"
          confirmButtonText="Apply"
          formId={formId}
        >
          <Form
            onFinish={onMultiApply}
            form={form}
            id={formId}
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              label="Do you want Close the selected Branches?"
              name="branchClosed"
            >
              <Radio.Group defaultValue={false}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            <div
              className="d-flex justify-content-between mb-3"
              style={{ gap: 15 }}
            >
              <Form.Item
                hidden={branchClosed ? true : false}
                label="Start Time"
                name="startTime"
              >
                <TimePicker
                  disabledHours={() => _disabledHours('startTime')}
                  disabledMinutes={(time) => _disabledMinute('startTime', time,)}
                  inputReadOnly
                  onSelect={(value) => form.setFieldsValue({ startTime: value })}
                  format={TIME_FORMAT}
                  className="text-input-field p-2 pe-3 t1"
                  minuteStep={5}
                  style={{ width: "100%" }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
              </Form.Item>
              <Form.Item
                label="End Time"
                name="endTime"
                hidden={branchClosed ? true : false}
              >
                <TimePicker
                  disabledHours={() => _disabledHours('endTime')}
                  disabledMinutes={(time) => _disabledMinute('endTime', time,)}
                  inputReadOnly
                  allowClear={false}
                  format={TIME_FORMAT}
                  className="text-input-field p-2 pe-3 t1"
                  minuteStep={5}
                  onSelect={(value) => form.setFieldsValue({ endTime: value })}
                  style={{ width: "100%" }}
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
              </Form.Item>
            </div>
          </Form>
        </ConfirmModal>
      </div >
    </AddModal >
  );
};
