import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { EmptyData } from "../../../components/comon/EmptyData";
import { Heading } from "../../../components/UI/heading";
import { TableComponet } from "../../../components/UI/table";
import { TableToolbar } from "../../../components/UI/table/tableToolBar";
import { getLocumShiftsListThunk } from "../../../redux/locum-shifts/locumShiftsThunk";
import { LocumShiftsFilterComponent } from "./filter";

export const LocumJobsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const { locumShifts } = useSelector((state) => state.locumShifts);
  const { user } = useSelector((state) => state.auth);
  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);
  const [staffTableView, setStaffTableView] = useState("new");
  const [showDownloadFileModal, setShowDownloadFileModal] = useState(false);
  const { settingInfo } = useSelector((state) => state.locum);
  const [searchParams] = useSearchParams();


  const columns = React.useMemo(
    () => [
      {
        Header: "Pharmacy",
        accessor: "pharmacyName",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Dates",
        accessor: "startDate",
        Cell: ({ row: { original } }) => {
          return moment(original.startDate).format("DD-MM-YYYY");
        },
      },
      {
        Header: "Time",
        accessor: "startTime",
        Cell: ({ row: { original } }) => {
          return `${original.startTime} - ${original.endTime}`;
        },
      },
    ],
    []
  );

  const {
    count: totalDataCount,
    page,
    pages: totalPagesCount,
    perPage,
    rows: locumShiftsList,
  } = useMemo(() => locumShifts, [locumShifts]);

  useEffect(() => {
    if (!page && !perPage) return;
    setPerPageCount(perPage);
    setCurrentPageCount(page);
  }, [page, perPage]);

  const getLocumShifts = useCallback(
    (value) => {
      const startDate = value?.startDate ? value?.startDate.format("YYYY-MM-DD") : null
      const endDate = value?.endDate ? value?.endDate.format("YYYY-MM-DD") : null
      dispatch(
        getLocumShiftsListThunk({
          filters: {
            distance: !searchParams.get('alert') ? value?.distance : settingInfo?.preferred_travel_distance,
            minHourlyRate: !searchParams.get('alert') ? value?.minHourlyRate : settingInfo?.hourly_rate_from,
            alertsOn: value?.alertsOn ? value?.alertsOn : undefined,
            tab: staffTableView,
            roleId: user.roleId,
            startDate,
            endDate,
            pharmacyName: searchQuery.trim(),
          },
          pagination: { page: currentPageCount, perPage: perPageCount },
        })
      )
    },
    [currentPageCount, dispatch, perPageCount, searchParams, searchQuery, settingInfo?.hourly_rate_from, settingInfo?.preferred_travel_distance, staffTableView, user.roleId]
  );

  useEffect(() => {
    if (searchQuery !== "") return;
    getLocumShifts();
  }, [getLocumShifts, searchQuery, currentPageCount, perPageCount]);

  const tabsOptions = [
    {
      label: "New Jobs",
      value: "new",
      onClickHandler: () => {
        setPerPageCount(10);
        setCurrentPageCount(1);
        setStaffTableView("new");
      },
    },
    {
      label: "Pending",
      value: "pending",
      onClickHandler: () => {
        setPerPageCount(10);
        setCurrentPageCount(1);
        setStaffTableView("pending");
      },
    },
    {
      label: "Invitation",
      value: "invite",
      onClickHandler: () => {
        setPerPageCount(10);
        setCurrentPageCount(1);
        setStaffTableView("invite");
      },
    },
  ];

  const formatTrProps = useCallback(
    ({ original }) => {
      return {
        onClick: () => navigate(`/locum-market/${original.id}`),
      };
    },
    [navigate]
  );

  const onSearch = (e) => {
    getLocumShifts();
  };

  return (
    <div>
      <Heading label="Locum Jobs" />
      <div className="mt-3" style={{ height: "100%" }}>
        <TableToolbar
          FilterComponent={
            <LocumShiftsFilterComponent onApplyFilter={getLocumShifts} />
          }
          searchPlaceholder="Pharmacy Name"
          filterButton
          showSearchField
          staffTableView={staffTableView}
          tabsOptions={tabsOptions}
          onSearch={onSearch}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          primaryBtnHandler={() =>
            setShowDownloadFileModal(!showDownloadFileModal)
          }
        />
        <div style={{ padding: "0px 24px", height: "100%" }}>
          {locumShifts.rows?.length > 0 ? (
            <TableComponet
              rowCursor="pointer"
              isBorderShown
              currentPageCount={currentPageCount}
              perPageCount={perPageCount}
              totalDataCount={totalDataCount}
              setCurrentPage={setCurrentPageCount}
              setPerPageCount={setPerPageCount}
              showPagination
              maxPageCount={totalPagesCount}
              formatRowProps={formatTrProps}
              columns={columns}
              data={locumShiftsList}
            />
          ) : (
            <div
              style={{
                border: "1px solid #E9E9E9",
                paddingTop: "80px",
                minHeight: "603px",

              }}
            >
              <EmptyData
                title="Locum Jobs"
                secondaryText="We're Out Locum Jobs For Now!"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
