import React from "react";
import classnames from "classnames";
import { Checkbox } from "semantic-ui-react";

import "./style.scss";

export const CustomCheckbox = React.forwardRef(
  (
    { name, label, checked, size, onChange, disabled = false, className },
    ref
  ) => {
    return (
      <Checkbox
        ref={ref}
        className={classnames(`custom-checkbox p2`, {
          [className]: className,
          [size]: size,
        })}
        name={name}
        label={label}
        disabled={disabled}
        onChange={onChange}
        checked={checked}
      />
    );
  }
);

CustomCheckbox.displayName = "CustomCheckbox";
