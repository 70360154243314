import React from "react";

import { Button, Carousel, ConfigProvider } from "antd";
import messegeicon from "../../../assets/icons/messages-3.svg";
import rounded_arrow from "../../../assets/icons/rounded-arrow.svg";
import img1 from "../../../assets/img/Scheduling 11.jpg";
import img4 from "../../../assets/img/Scheduling 12.jpg";
import img3 from "../../../assets/img/Scheduling 13.jpg";
import img5 from "../../../assets/img/Scheduling 2.jpg";
import img2 from "../../../assets/img/Scheduling 4.png";

import shape from "../../../assets/img/shape.png";

const RightSlider = () => {
  const onChange = (currentSlide) => {
    // console.log(currentSlide);
  };

  return (
    <div
      className="position-relative border  d-flex align-items-center  text-center"
      style={{
        width: "517px",
        backgroundColor: "#07776C",
        height: "100%",
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
      }}
    >
      <Button className="absolute_message_btn">
        <img src={messegeicon} alt="message_icon" />
      </Button>
      <div className="absolute_top_img">
        <img src={shape} alt="shape" />
      </div>
      <div className="absolute_bottom_img">
        <img src={shape} alt="shape" />
      </div>

      <div style={{ width: "100%" }} className="slider_wrapper">
        <div className="carousel_wrapper">
          {" "}
          <ConfigProvider
            theme={{
              components: {
                Carousel: {
                  dotWidthActive: 34,
                  dotHeight: 7,
                  dotWidth: 7,
                },
              },
            }}
          >
            {" "}
            <Carousel
              afterChange={onChange}
              autoplay
              swipeToSlide
              autoplaySpeed={2000}
              infinite
              draggable
              style={{
                ".slider_wrapper": {
                  width: "99%",
                },
              }}
            >
              <div className="h-100 d-flex flex-column justify-content-between">
                <div className="img_wrapper">
                  <img src={img1} alt="slider1" />
                  <img
                    src={rounded_arrow}
                    alt="arrow"
                    className="rounded_arrow"
                  />
                </div>

                <h2>Run Your Employee Rotas How You've Always Wanted To</h2>
                <p>
                  Weather you want to schedule a few shifts for today or you're
                  planning on using one schedule for the entire year, get it
                  done in a few minutes; let Lopic do all the heavy lifting for
                  you.
                </p>
              </div>
              <div className="">
                <div className="img_wrapper">
                  <img src={img2} alt="slider1" />
                  <img
                    src={rounded_arrow}
                    alt="arrow"
                    className="rounded_arrow"
                  />
                </div>

                <h2>Let's Find You A Locum</h2>
                <p>
                  Say goodbye to hefty fees and last minute cancellations. Post
                  a Locum Job and select any Locum you think would work best for
                  you; with our ever growing community we're never short on
                  options either.
                </p>
              </div>
              <div className="">
                <div className="img_wrapper">
                  <img src={img3} alt="slider1" />
                  <img
                    src={rounded_arrow}
                    alt="arrow"
                    className="rounded_arrow"
                  />
                </div>

                <h2>Revolutionise Employee Management With 'TimeSheets'</h2>
                <p>
                  One screen to do it all; keep track of working hours and
                  allocations like never before. From under and overworked hours
                  to managing attendance, check-in and check-out times and so
                  much more.
                </p>
              </div>
              <div className="">
                <div className="img_wrapper">
                  <img src={img4} alt="slider1" />
                  <img
                    src={rounded_arrow}
                    alt="arrow"
                    className="rounded_arrow"
                  />
                </div>
                <h2>Accreditation Management </h2>
                <p>
                  A centralised storage for your employee accreditations; stick
                  to our existing set of documents or add your own, at your
                  will. With automated expiry reminders, you'll never have to
                  worry about compliance ever again!
                </p>
              </div>
              <div className="">
                <div className="img_wrapper">
                  <img src={img5} alt="slider1" />
                  <img
                    src={rounded_arrow}
                    alt="arrow"
                    className="rounded_arrow"
                  />
                </div>
                <h2>Optimise Your Pharmacy Operations</h2>
                <p>
                  Running short on time? big on tasks? A quick glance at the
                  dashboard and you'll be up-to speed on everything that
                  requires your action.
                </p>
              </div>
            </Carousel>
          </ConfigProvider>
        </div>
      </div>
    </div>
  );
};

export default RightSlider;
