import { Button } from "antd";
import React from "react";
import emptyFolder from "../../assets/img/emptyFolder.png";
export const EmptyData = (props) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "calc(100% - 64px)", width: "100%", marginTop: props.marginTop }}
    >
      <div className="empty-data-container">
        <img src={props.img || emptyFolder} alt="" style={{ maxWidth: "300px", maxHeight: "300px" }} />
        {props.title && <div className="heading2">No {props.title} Found</div>}
        {
          props.subtitle &&
          <span className="p3" style={{ width: "389px", whiteSpace: "break-spaces", textAlign: "center" }}>
            We cant find any of your {props.title}, head over to the {props.title} section to add your first {props.subtitle}!
          </span>
        }
        {
          props.secondaryText &&
          <span className="p3" style={{ width: "389px", whiteSpace: "break-spaces", textAlign: "center" }}>
            {props.secondaryText}
          </span>
        }
        {props.description && (
          <span
            className="p3"
            style={{
              maxWidth: "389px",
              whiteSpace: "break-spaces",
              textAlign: "center",
              fontWeight: props.font
            }}
          >
            {props.description}
          </span>
        )}

        {props.actionBtn && (
          <Button
            // onClick={primaryBtnHandler}
            className="p-2 px-4 btn-style rounded"
            block
            type="primary"
            style={{ maxWidth: "188px" }}
          >
            Create Branch
          </Button>
        )}
      </div>
    </div>
  );
};
