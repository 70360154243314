import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { EmptyData } from "../../../../components/comon/EmptyData";
import { Heading } from "../../../../components/UI/heading";
import { TableComponet } from "../../../../components/UI/table";
import { TableToolbar } from "../../../../components/UI/table/tableToolBar";
import { getPharmacyListThunk } from "../../../../redux/super-admin/superAdminThunk";
import { RouterConfig } from "../../../../routerConfig";


export const PharmaciesListPage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");

  const { pharmacyList } = useSelector((state) => state.superAdmin);

  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);

  const [renderer, setRerender] = useState(false)


  const columns = useMemo(
    () => [
      {
        Header: "Pharmacy ID",
        accessor: "id",
      },
      {
        Header: "Pharmacy Title",
        accessor: "pharmacyName",
      },
      {
        Header: "Resources",
        accessor: "resource",
        Cell: ({ row: { original } }) => {
          return (
            <div className="d-flex">
              <div className="px-2">
                <div>Branches</div>
                <div>{original.branchesCount}</div>
              </div>
              <div className="px-2">
                <div>Employees</div>
                <div>{original.staffCount}</div>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Subscription",
        accessor: "subscription",
        Cell: ({ row: { original } }) => {
          return (
            <div className="d-flex">
              <div className="px-2">
                <div>Numark</div>
                <div>{original.numarkCount}</div>
              </div>
              <div className="px-2">
                <div>Standard</div>
                <div>{original.standardCount}</div>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Pharmacy Admin",
        accessor: "name",
      },
      {
        Header: "Current Jobs",
        accessor: "currentJobsCount",
      },
      {
        Header: "Done Jobs",
        accessor: "doneJobsCount",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row: { original } }) => {
          return (
            <>
              {original.status === "isArchived" ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#FEFDE9", color: "#EE872D" }}
                  >
                    {original.status.charAt(0).toUpperCase() +
                      original.status.slice(1, original.status.length)}{" "}
                  </span>
                </div>
              ) : original.status === "active" ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#E8F5E9", color: "#1B5E1F" }}
                  >
                    {original.status.charAt(0).toUpperCase() +
                      original.status.slice(1, original.status.length)}{" "}
                  </span>
                </div>
              ) : original.status === "unfilled" ||
                original.status === "pending" ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#FEFDE9", color: "#EE872D" }}
                  >
                    {" "}
                    {original.status.charAt(0).toUpperCase() +
                      original.status.slice(1, original.status.length)}{" "}
                  </span>
                </div>
              ) : original.status === "completed" ? (
                <span
                  className="px-4 rounded-pill py-2"
                  style={{ backgroundColor: "#E3F2FD", color: "#1045A1" }}
                >
                  {original.status.charAt(0).toUpperCase() +
                    original.status.slice(1, original.status.length)}{" "}
                </span>
              ) : (
                <span
                  className="px-4 rounded-pill py-2"
                  style={{ backgroundColor: "#FFEBEE", color: "#EF5350" }}
                >
                  {original.status.charAt(0).toUpperCase() +
                    original.status.slice(1, original.status.length)}{" "}
                </span>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  const {
    count: totalDataCount,
    page,
    pages: totalPagesCount,
    perPage,
    rows: pharmacyData,
  } = useMemo(() => pharmacyList, [pharmacyList]);

  useEffect(() => {
    if (!page && !perPage) return;
    setPerPageCount(perPage);
    setCurrentPageCount(page);
  }, [page, perPage]);

  useEffect(
    () => {
      if (!renderer) return
      dispatch(
        getPharmacyListThunk({
          filters: {
            pharmacy: searchQuery.trim(),
          },
          pagination: { page: currentPageCount, perPage: perPageCount },
        })
      ).then(res => {
        if (res.payload) {
          setRerender(false)
        }
      })
    },
    [currentPageCount, dispatch, perPageCount, searchQuery, renderer]
  );

  useEffect(() => {
    if (searchQuery !== "") return;
    setRerender(true)
  }, [searchQuery]);


  useEffect(() => {
    setRerender(true)
  }, [currentPageCount, perPageCount]);



  const formatTrProps = ({ original }) => {
    return {
      onClick: () =>
        navigate(
          generatePath(RouterConfig.superAdminPharmacyInformation, {
            id: original.id,
          })
        ),
    };
  };

  const onSearch = (e) => {
    setRerender(true)
  };

  return (
    <>
      <div>
        <Heading label="Pharmacies" />
        <div className="">
          <TableToolbar
            showSearchField
            filterButton
            onSearch={onSearch}
            BranchesListPage
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
          <div style={{ padding: "5px 24px" }}>
            {pharmacyList?.length <= 0 ? (
              <EmptyData title="Pahrmacies" description="Pharmacy" />
            ) : (
              <TableComponet
                rowCursor="pointer"
                isBorderShown
                currentPageCount={currentPageCount}
                perPageCount={perPageCount}
                totalDataCount={totalDataCount}
                setCurrentPage={setCurrentPageCount}
                setPerPageCount={setPerPageCount}
                showPagination
                maxPageCount={totalPagesCount}
                columns={columns}
                data={pharmacyData ?? []}
                formatRowProps={formatTrProps}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
