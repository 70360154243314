import { Button } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EmptyData } from "../../../components/comon/EmptyData";
import { Heading } from "../../../components/UI/heading";
import { TableComponet } from "../../../components/UI/table";
import {
  applyBranchGapThunk,
  getBranchGapsDataThunk,
} from "../../../redux/branches/branchesThunk";
import { getDuration, getDurationFormat } from "../../../utils/dates";
import { notify } from "../../../utils/helper";

export const StaffGapsList = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [flag, setFlag] = useState(true);

  const { branchGapsData } = useSelector((state) => state.branches);

  const columns = useMemo(
    () => [
      {
        Header: "Branch Name",
        accessor: "branchName",
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ row: { original } }) => {
          return dayjs(original.date).format("DD-MM-YYYY");
        },
      },
      {
        Header: "Time",
        accessor: "time",
        Cell: ({ row: { original } }) => {
          const d = getDuration(
            dayjs(original.startTime, "HH:mm"),
            dayjs(original.endTime, "HH:mm")
          );
          return getDurationFormat(d);
        },
      },
      {
        Header: "Duration",
        accessor: "duration",
      },
      {
        Header: "Rate",
        accessor: "hourlyRate",
        Cell: ({ row: { original } }) => {
          return `£${original?.hourlyRate}`;
        },
      },
      {
        Header: "Total",
        accessor: "total",
        Cell: ({ row: { original } }) => {
          const d = original.duration.split(" ")[0];
          return `£${d * original?.hourlyRate}`;
        },
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row: { original } }) => {
          return original.applied ? (
            <Button
              disabled={original.applied}
              className="px-3 py-1"
              type="primary"
              shape="round"
              onClick={(e) => {
                e.stopPropagation();
                onApplyGaps(original);
              }}
            >
              {"Applied"}
            </Button>
          ) : (
            <Button
              disabled={original.applied}
              className="px-3 py-1"
              type="primary"
              shape="round"
              onClick={(e) => {
                e.stopPropagation();
                onApplyGaps(original);
              }}
            >
              {"Apply"}
            </Button>
          );
        },
      },
    ],
    []
  );

  const onApplyGaps = useCallback(
    (value) => {
      const reqBody = [
        {
          startDate: value.date,
          endDate: value.date,
          lunchStartTime: null,
          lunchEndTime: null,
          startTime: value.startTime,
          endTime: value.endTime,
          overtimeWage: value.hourlyRate,
          roleId: user?.roleId,
          days: [moment(value.date).format("dddd").toLowerCase()],
        },
      ];

      dispatch(
        applyBranchGapThunk({
          id: value?.branchId,
          data: reqBody,
        })
      ).then((res) => {
        if (res.payload) {
          setFlag(true);
          notify("success", "Applied to the Gap!");
        }
      });
    },
    [dispatch, user?.roleId]
  );

  useEffect(() => {
    if (!flag) return;
    const startDateRange = moment().format("YYYY-MM-DD");
    const endDateRange = moment(startDateRange)
      .add(1, "month")
      .format("YYYY-MM-DD");
    dispatch(
      getBranchGapsDataThunk({
        id: user.id,
        startDateRange,
        endDateRange,
      })
    ).then((res) => {
      if (res.payload) {
        setFlag(false);
      }
    });
  }, [dispatch, flag, user.id]);

  return (
    <div>
      <Heading label="Shift Gaps" />
      <div className="mt-3">
        <div style={{ padding: "0px 24px" }}>
          {branchGapsData?.length <= 0 ? (
            <EmptyData title="Gaps Found" />
          ) : (
            <TableComponet
              perPageCount={100}
              rowCursor="pointer"
              isBorderShown
              columns={columns}
              data={branchGapsData}
            />
          )}
        </div>
      </div>
    </div>
  );
};
