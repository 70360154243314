import React from "react";

const PaymentCompletedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M14.832 3.33203H6.4987C3.9987 3.33203 2.33203 4.58203 2.33203 7.4987V10.4654C2.33203 10.7737 2.6487 10.9654 2.9237 10.8404C3.74036 10.4654 4.68203 10.3237 5.6737 10.4987C7.86537 10.8904 9.4737 12.9237 9.41536 15.1487C9.40703 15.4987 9.35703 15.8404 9.26536 16.1737C9.1987 16.432 9.40703 16.6737 9.6737 16.6737H14.832C17.332 16.6737 18.9987 15.4237 18.9987 12.507V7.4987C18.9987 4.58203 17.332 3.33203 14.832 3.33203ZM10.6654 12.082C9.51537 12.082 8.58203 11.1487 8.58203 9.9987C8.58203 8.8487 9.51537 7.91536 10.6654 7.91536C11.8154 7.91536 12.7487 8.8487 12.7487 9.9987C12.7487 11.1487 11.8154 12.082 10.6654 12.082ZM16.707 11.6654C16.707 12.007 16.4237 12.2904 16.082 12.2904C15.7404 12.2904 15.457 12.007 15.457 11.6654V8.33203C15.457 7.99036 15.7404 7.70703 16.082 7.70703C16.4237 7.70703 16.707 7.99036 16.707 8.33203V11.6654Z"
        fill="#027948"
      />
      <path
        d="M4.83333 11.668C2.99167 11.668 1.5 13.1596 1.5 15.0013C1.5 15.6263 1.675 16.218 1.98333 16.718C2.55833 17.6846 3.61667 18.3346 4.83333 18.3346C6.05 18.3346 7.10833 17.6846 7.68333 16.718C7.99167 16.218 8.16667 15.6263 8.16667 15.0013C8.16667 13.1596 6.675 11.668 4.83333 11.668ZM6.475 14.7263L4.7 16.368C4.58333 16.4763 4.425 16.5346 4.275 16.5346C4.11667 16.5346 3.95833 16.4763 3.83333 16.3513L3.00833 15.5263C2.76667 15.2846 2.76667 14.8846 3.00833 14.643C3.25 14.4013 3.65 14.4013 3.89167 14.643L4.29167 15.043L5.625 13.8096C5.875 13.5763 6.275 13.593 6.50833 13.843C6.74167 14.093 6.725 14.493 6.475 14.7263Z"
        fill="#027948"
      />
    </svg>
  );
};

export default PaymentCompletedIcon;
