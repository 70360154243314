import React from "react";

const BriefCaseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.0607 4.65302C13.494 4.02635 12.5473 3.71302 11.174 3.71302H11.014V3.68635C11.014 2.56635 11.014 1.17969 8.50734 1.17969H7.49401C4.98734 1.17969 4.98734 2.57302 4.98734 3.68635V3.71969H4.82734C3.44734 3.71969 2.50734 4.03302 1.94068 4.65969C1.28068 5.39302 1.30068 6.37969 1.36734 7.05302L1.37401 7.09969L1.41813 7.56297C1.4324 7.71274 1.51314 7.84819 1.6393 7.93016C1.79966 8.03436 2.02088 8.17536 2.16068 8.25302C2.25401 8.31302 2.35401 8.36635 2.45401 8.41969C3.59401 9.04635 4.84734 9.46635 6.12068 9.67302C6.18068 10.2997 6.45401 11.033 7.91401 11.033C9.37401 11.033 9.66068 10.3064 9.70734 9.65969C11.0673 9.43969 12.3807 8.96635 13.5673 8.27302C13.6073 8.25302 13.634 8.23302 13.6673 8.21302C13.9111 8.07525 14.1633 7.90799 14.3971 7.74138C14.5106 7.66051 14.5828 7.53427 14.5981 7.39576L14.6007 7.37302L14.634 7.05969C14.6407 7.01969 14.6407 6.98635 14.6473 6.93969C14.7007 6.26635 14.6873 5.34635 14.0607 4.65302ZM8.72734 9.21969C8.72734 9.92635 8.72734 10.033 7.90734 10.033C7.08734 10.033 7.08734 9.90635 7.08734 9.22635V8.38635H8.72734V9.21969ZM5.94068 3.71302V3.68635C5.94068 2.55302 5.94068 2.13302 7.49401 2.13302H8.50734C10.0607 2.13302 10.0607 2.55969 10.0607 3.68635V3.71969H5.94068V3.71302Z"
        fill="#09988D"
      />
      <path
        d="M13.6461 9.28368C14.0008 9.11776 14.4082 9.39865 14.3728 9.78863L14.1605 12.1268C14.0205 13.4601 13.4739 14.8201 10.5405 14.8201H5.46055C2.52721 14.8201 1.98055 13.4601 1.84055 12.1335L1.63983 9.92564C1.60479 9.54018 2.00272 9.25964 2.35635 9.41698C3.11607 9.755 4.25657 10.2379 5.03025 10.4541C5.19384 10.4998 5.32638 10.6182 5.40447 10.7691C5.82533 11.5819 6.687 12.0135 7.91388 12.0135C9.12873 12.0135 10.0004 11.5652 10.4231 10.7493C10.5013 10.5983 10.6343 10.4799 10.798 10.4338C11.6228 10.2014 12.8456 9.65815 13.6461 9.28368Z"
        fill="#09988D"
      />
    </svg>
  );
};

export default BriefCaseIcon;
