import moment from "moment";
import React from "react";

const DateRender = ({ date }) => {
  return (
    <div>
      {" "}
      <p className="date_render">
        {date?.length > 0 ? moment(date).format("DD MMM, YYYY") : "-"}
      </p>
    </div>
  );
};

export default DateRender;
