import { Empty } from "antd";
import React from "react";
import { TableComponet } from "../../UI/table";
import NoPharmacyGap from "../../EmptyStates/NoPharmacyGaps/NoPharmacyGaps";

const DashboardCommonTable = ({ tableCols, data }) => {
  return (
    <div className="common-dashboard-table-wrapper">
      {data && data.length > 0 ? (
        <TableComponet columns={tableCols} data={data ? data : []} />
      ) : (
        null
      )}
    </div>
  );
};

export default DashboardCommonTable;
