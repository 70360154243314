import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CustomTabs } from "../../../../components/UI/tabs";
import { authUserRequestThunk } from "../../../../redux/auth/authThunk";
import { paymentInvoicesThunk, updateHostedPageThunk } from "../../../../redux/profile/profileThunk";
import { notify } from "../../../../utils/helper";
import { Subscriptions } from "./components/subscriptions";
import { Transactions } from "./components/transactions";

export const SubscriptionNBilling = () => {
  const [tabs, setTabs] = useState([]);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const [flag, setFlag] = useState(true);
  const [currentActiveTab, setCurrentActiveTab] = useState(0);


  useEffect(() => {
    const _tabs = getTabsData();
    setTabs(_tabs);
  }, []);

  useEffect(() => {
    if (!searchParams.get('hostedpage_id')) return

    dispatch(
      updateHostedPageThunk({
        hostedPageId: searchParams.get('hostedpage_id')
      })
    ).then(
      res => {
        if (res.payload) {
          window.analytics.track('Payment Attempt', {});
          window.fbq('trackCustom', 'Payment Attempt', {});
          navigate('/profile/subscription')
          dispatch(authUserRequestThunk());
          notify(
            "success",
            "Your Payment Was Successful. However, Changes May Not Reflect On The Table Below Right Away. Please Wait!"
          );
          setFlag(true)
        }
      }
    )
  }, [dispatch, navigate, searchParams])


  useEffect(() => {
    if (!flag) return
    dispatch(
      paymentInvoicesThunk({
        pagination: {
          page: 1,
          perPage: 100
        }
      })
    );
  }, [flag, dispatch,]);

  const getTabsData = () => {
    const tabs = [
      {
        title: "Subscriptions",
        content:
          <Subscriptions
            flag={flag}
            setFlag={setFlag}
          />
      },
      {
        title: "Transactions",
        content: <Transactions setFlag={setFlag} />
      },
    ];
    return tabs;
  };

  return (
    <div className="branch-info-page  rounded-4">
      <CustomTabs
        currentActiveTab={currentActiveTab}
        setCurrentActiveTab={setCurrentActiveTab}
        tabs={tabs}
      />
    </div >
  );
};
