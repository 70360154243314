import { Breadcrumb } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, generatePath } from "react-router-dom";
import { BranchCard } from "../../../components/UI/cards/profileCard";
import { ContentContainer } from "../../../components/UI/container";
import { SidebarNew } from "../../../components/UI/sidebar-new/SidebarNew";
import { getProfileDataThunk } from "../../../redux/profile/profileThunk";
import { RouterConfig } from "../../../routerConfig";
import { moduleKeys } from "../../../utils/modulesKeyConstants";

const sidebarLinks = [
  {
    text: "Subscription & Billing",
    to: generatePath(RouterConfig.branchAdminProfileSubscription),
    key: moduleKeys.SUBSCRIPTION_AND_BILLING,
    disabled: false,
    hidden: false,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M5 15C3.98 15 3.04 15.39 2.33 16.03C2.17 16.17 2.02 16.32 1.89 16.49C1.33 17.18 1 18.05 1 19C1 21.21 2.8 23 5 23C5.96 23 6.83 22.66 7.52 22.11C8.08 21.64 8.52 21.03 8.76 20.33C8.92 19.92 9 19.47 9 19C9 16.8 7.21 15 5 15ZM7.4 18.23C7.4 18.51 7.18 18.72 6.91 18.72C6.64 18.72 6.42 18.51 6.42 18.23V18.13C6.42 18.02 6.34 17.93 6.23 17.93H4.23C4.33 18.12 4.31 18.35 4.15 18.51C4.06 18.6 3.94 18.65 3.8 18.65C3.68 18.65 3.55 18.6 3.46 18.51L2.74 17.79C2.7 17.75 2.66 17.69 2.63 17.63C2.59 17.51 2.59 17.38 2.63 17.26C2.66 17.21 2.69 17.14 2.74 17.1L3.46 16.39C3.65 16.19 3.96 16.19 4.14 16.39C4.3 16.54 4.32 16.78 4.22 16.96H6.23C6.87 16.96 7.39 17.48 7.39 18.13V18.23H7.4ZM6.2 21.76C6.07 21.76 5.94 21.72 5.85 21.62C5.69 21.47 5.67 21.23 5.77 21.05H3.76C3.12 21.05 2.6 20.53 2.6 19.88V19.78C2.6 19.5 2.82 19.29 3.09 19.29C3.36 19.29 3.58 19.5 3.58 19.78V19.88C3.58 19.99 3.66 20.08 3.77 20.08H5.78C5.68 19.89 5.7 19.66 5.86 19.5C6.05 19.31 6.36 19.31 6.54 19.5L7.26 20.22C7.3 20.26 7.34 20.32 7.37 20.38C7.41 20.5 7.41 20.63 7.37 20.75C7.34 20.8 7.3 20.86 7.26 20.9L6.54 21.61C6.45 21.71 6.32 21.76 6.2 21.76Z"
          fill="#434343"
        />
        <path
          d="M14.85 3.9498V7.7498H13.35V3.9498C13.35 3.6798 13.11 3.5498 12.95 3.5498C12.9 3.5498 12.85 3.5598 12.8 3.5798L4.87 6.56981C4.34 6.7698 4 7.2698 4 7.8398V8.5098C3.09 9.1898 2.5 10.2798 2.5 11.5098V7.8398C2.5 6.6498 3.23 5.5898 4.34 5.1698L12.28 2.1698C12.5 2.0898 12.73 2.0498 12.95 2.0498C13.95 2.0498 14.85 2.8598 14.85 3.9498Z"
          fill="#434343"
        />
        <path
          d="M21.5007 14.5V15.5C21.5007 15.77 21.2907 15.99 21.0107 16H19.5507C19.0207 16 18.5407 15.61 18.5007 15.09C18.4707 14.78 18.5907 14.49 18.7907 14.29C18.9707 14.1 19.2207 14 19.4907 14H21.0007C21.2907 14.01 21.5007 14.23 21.5007 14.5Z"
          fill="#434343"
        />
        <path
          d="M19.48 12.95H20.5C21.05 12.95 21.5 12.5 21.5 11.95V11.51C21.5 9.44 19.81 7.75 17.74 7.75H6.26C5.41 7.75 4.63 8.03 4 8.51C3.09 9.19 2.5 10.28 2.5 11.51V13.29C2.5 13.67 2.9 13.91 3.26 13.79C3.82 13.6 4.41 13.5 5 13.5C8.03 13.5 10.5 15.97 10.5 19C10.5 19.72 10.31 20.51 10.01 21.21C9.85 21.57 10.1 22 10.49 22H17.74C19.81 22 21.5 20.31 21.5 18.24V18.05C21.5 17.5 21.05 17.05 20.5 17.05H19.63C18.67 17.05 17.75 16.46 17.5 15.53C17.3 14.77 17.54 14.03 18.04 13.55C18.41 13.17 18.92 12.95 19.48 12.95ZM14 12.75H9C8.59 12.75 8.25 12.41 8.25 12C8.25 11.59 8.59 11.25 9 11.25H14C14.41 11.25 14.75 11.59 14.75 12C14.75 12.41 14.41 12.75 14 12.75Z"
          fill="#434343"
        />
      </svg>
    ),
  },
  {
    text: "Bank Holidays",
    to: generatePath(RouterConfig.branchAdminProfileBankHolidays),
    key: moduleKeys.BANK_HOLIDAYS,
    disabled: false,
    hidden: false,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M22 19V22H2V19C2 18.45 2.45 18 3 18H21C21.55 18 22 18.45 22 19Z"
          fill="#434343"
          stroke="#434343"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M7 11H5V18H7V11Z" fill="#434343" />
        <path d="M11 11H9V18H11V11Z" fill="#434343" />
        <path d="M15 11H13V18H15V11Z" fill="#434343" />
        <path d="M19 11H17V18H19V11Z" fill="#434343" />
        <path
          d="M23 22.75H1C0.59 22.75 0.25 22.41 0.25 22C0.25 21.59 0.59 21.25 1 21.25H23C23.41 21.25 23.75 21.59 23.75 22C23.75 22.41 23.41 22.75 23 22.75Z"
          fill="#434343"
        />
        <path
          d="M21.37 5.74984L12.37 2.14984C12.17 2.06984 11.83 2.06984 11.63 2.14984L2.63 5.74984C2.28 5.88984 2 6.29984 2 6.67984V9.99984C2 10.5498 2.45 10.9998 3 10.9998H21C21.55 10.9998 22 10.5498 22 9.99984V6.67984C22 6.29984 21.72 5.88984 21.37 5.74984ZM12 8.49984C11.17 8.49984 10.5 7.82984 10.5 6.99984C10.5 6.16984 11.17 5.49984 12 5.49984C12.83 5.49984 13.5 6.16984 13.5 6.99984C13.5 7.82984 12.83 8.49984 12 8.49984Z"
          fill="#434343"
        />
      </svg>
    ),
  },
  {
    text: "Accrediations",
    to: generatePath(RouterConfig.branchAdminProfileAccrediations),
    key: moduleKeys.ACCREDITATIONS,
    disabled: false,
    hidden: false,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M17.8114 5.49V6.23L14.2714 4.18C12.9314 3.41 11.0614 3.41 9.73141 4.18L6.19141 6.24V5.49C6.19141 3.24 7.42141 2 9.67141 2H14.3314C16.5814 2 17.8114 3.24 17.8114 5.49Z"
          fill="#434343"
        />
        <path
          d="M17.84 7.97023L17.7 7.90023L16.34 7.12023L13.52 5.49023C12.66 4.99023 11.34 4.99023 10.48 5.49023L7.66 7.11023L6.3 7.91023L6.12 8.00023C4.37 9.18023 4.25 9.40023 4.25 11.2902V15.8102C4.25 17.7002 4.37 17.9202 6.16 19.1302L10.48 21.6202C10.91 21.8802 11.45 21.9902 12 21.9902C12.54 21.9902 13.09 21.8702 13.52 21.6202L17.88 19.1002C19.64 17.9202 19.75 17.7102 19.75 15.8102V11.2902C19.75 9.40023 19.63 9.18023 17.84 7.97023ZM14.79 13.5002L14.18 14.2502C14.08 14.3602 14.01 14.5702 14.02 14.7202L14.08 15.6802C14.12 16.2702 13.7 16.5702 13.15 16.3602L12.26 16.0002C12.12 15.9502 11.89 15.9502 11.75 16.0002L10.86 16.3502C10.31 16.5702 9.89 16.2602 9.93 15.6702L9.99 14.7102C10 14.5602 9.93 14.3502 9.83 14.2402L9.21 13.5002C8.83 13.0502 9 12.5502 9.57 12.4002L10.5 12.1602C10.65 12.1202 10.82 11.9802 10.9 11.8602L11.42 11.0602C11.74 10.5602 12.25 10.5602 12.58 11.0602L13.1 11.8602C13.18 11.9902 13.36 12.1202 13.5 12.1602L14.43 12.4002C15 12.5502 15.17 13.0502 14.79 13.5002Z"
          fill="#434343"
        />
      </svg>
    ),
  },
  {
    text: "Settings",
    to: generatePath(RouterConfig.branchAdminProfileSetting),
    key: moduleKeys.SETTINGS,
    disabled: false,
    hidden: false,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M20.1 9.21994C18.29 9.21994 17.55 7.93994 18.45 6.36994C18.97 5.45994 18.66 4.29994 17.75 3.77994L16.02 2.78994C15.23 2.31994 14.21 2.59994 13.74 3.38994L13.63 3.57994C12.73 5.14994 11.25 5.14994 10.34 3.57994L10.23 3.38994C9.78 2.59994 8.76 2.31994 7.97 2.78994L6.24 3.77994C5.33 4.29994 5.02 5.46994 5.54 6.37994C6.45 7.93994 5.71 9.21994 3.9 9.21994C2.86 9.21994 2 10.0699 2 11.1199V12.8799C2 13.9199 2.85 14.7799 3.9 14.7799C5.71 14.7799 6.45 16.0599 5.54 17.6299C5.02 18.5399 5.33 19.6999 6.24 20.2199L7.97 21.2099C8.76 21.6799 9.78 21.3999 10.25 20.6099L10.36 20.4199C11.26 18.8499 12.74 18.8499 13.65 20.4199L13.76 20.6099C14.23 21.3999 15.25 21.6799 16.04 21.2099L17.77 20.2199C18.68 19.6999 18.99 18.5299 18.47 17.6299C17.56 16.0599 18.3 14.7799 20.11 14.7799C21.15 14.7799 22.01 13.9299 22.01 12.8799V11.1199C22 10.0799 21.15 9.21994 20.1 9.21994ZM12 15.2499C10.21 15.2499 8.75 13.7899 8.75 11.9999C8.75 10.2099 10.21 8.74994 12 8.74994C13.79 8.74994 15.25 10.2099 15.25 11.9999C15.25 13.7899 13.79 15.2499 12 15.2499Z"
          fill="#434343"
        />
      </svg>
    ),
  },
  {
    text: "Admin Staff",
    to: generatePath(RouterConfig.branchAdminProfileAdminStaff),
    key: moduleKeys.ADMIN_STAFF,
    disabled: false,
    hidden: false,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z"
          fill="#434343"
        />
        <path
          d="M14.0809 14.1499C11.2909 12.2899 6.74094 12.2899 3.93094 14.1499C2.66094 14.9999 1.96094 16.1499 1.96094 17.3799C1.96094 18.6099 2.66094 19.7499 3.92094 20.5899C5.32094 21.5299 7.16094 21.9999 9.00094 21.9999C10.8409 21.9999 12.6809 21.5299 14.0809 20.5899C15.3409 19.7399 16.0409 18.5999 16.0409 17.3599C16.0309 16.1299 15.3409 14.9899 14.0809 14.1499Z"
          fill="#434343"
        />
        <path
          d="M19.9894 7.3401C20.1494 9.2801 18.7694 10.9801 16.8594 11.2101C16.8494 11.2101 16.8494 11.2101 16.8394 11.2101H16.8094C16.7494 11.2101 16.6894 11.2101 16.6394 11.2301C15.6694 11.2801 14.7794 10.9701 14.1094 10.4001C15.1394 9.4801 15.7294 8.1001 15.6094 6.6001C15.5394 5.7901 15.2594 5.0501 14.8394 4.4201C15.2194 4.2301 15.6594 4.1101 16.1094 4.0701C18.0694 3.9001 19.8194 5.3601 19.9894 7.3401Z"
          fill="#434343"
        />
        <path
          d="M21.9883 16.5899C21.9083 17.5599 21.2883 18.3999 20.2483 18.9699C19.2483 19.5199 17.9883 19.7799 16.7383 19.7499C17.4583 19.0999 17.8783 18.2899 17.9583 17.4299C18.0583 16.1899 17.4683 14.9999 16.2883 14.0499C15.6183 13.5199 14.8383 13.0999 13.9883 12.7899C16.1983 12.1499 18.9783 12.5799 20.6883 13.9599C21.6083 14.6999 22.0783 15.6299 21.9883 16.5899Z"
          fill="#434343"
        />
      </svg>
    ),
  },
];

export const ProfileDetail = () => {
  const dispatch = useDispatch();
  const { profileInfo } = useSelector((state) => state.profile);
  const { user } = useSelector((state) => state.auth);

  const { accessibleModules: modules } = useSelector((state) => state.auth);
  const [displaySidebarLinks, setDisplaySidebarLinks] = useState([]);
  useEffect(() => {
    dispatch(getProfileDataThunk());
  }, [dispatch]);

  useEffect(() => {
    if (!modules) return;
    let modulesArray = Object.values(modules);
    let linksToDisplay = [
      {
        text: "Personal Information",
        to: generatePath(RouterConfig.branchAdminProfileInfo),
        key: moduleKeys.PROFILE,
        disabled: false,
        hidden: false,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M18 3H6C3.79 3 2 4.78 2 6.97V17.03C2 19.22 3.79 21 6 21H18C20.21 21 22 19.22 22 17.03V6.97C22 4.78 20.21 3 18 3ZM8.5 7.17C9.77 7.17 10.81 8.21 10.81 9.48C10.81 10.75 9.77 11.79 8.5 11.79C7.23 11.79 6.19 10.75 6.19 9.48C6.19 8.21 7.23 7.17 8.5 7.17ZM12.37 16.66C12.28 16.76 12.14 16.82 12 16.82H5C4.86 16.82 4.72 16.76 4.63 16.66C4.54 16.56 4.49 16.42 4.5 16.28C4.67 14.6 6.01 13.27 7.69 13.11C8.22 13.06 8.77 13.06 9.3 13.11C10.98 13.27 12.33 14.6 12.49 16.28C12.51 16.42 12.46 16.56 12.37 16.66ZM19 16.75H17C16.59 16.75 16.25 16.41 16.25 16C16.25 15.59 16.59 15.25 17 15.25H19C19.41 15.25 19.75 15.59 19.75 16C19.75 16.41 19.41 16.75 19 16.75ZM19 12.75H15C14.59 12.75 14.25 12.41 14.25 12C14.25 11.59 14.59 11.25 15 11.25H19C19.41 11.25 19.75 11.59 19.75 12C19.75 12.41 19.41 12.75 19 12.75ZM19 8.75H14C13.59 8.75 13.25 8.41 13.25 8C13.25 7.59 13.59 7.25 14 7.25H19C19.41 7.25 19.75 7.59 19.75 8C19.75 8.41 19.41 8.75 19 8.75Z"
              fill="#434343"
            />
          </svg>
        ),
      },
    ];

    for (let link of sidebarLinks) {
      for (let module of modulesArray) {
        if (link?.key?.toLowerCase() === module?.moduleKey?.toLowerCase()) {
          linksToDisplay.push(link);
        }
      }
    }
    linksToDisplay = [
      ...(user.roleId === 2
        ? [
            {
              text: "Admin Staff",
              to: generatePath(RouterConfig.branchAdminProfileAdminStaff),
              key: moduleKeys.ADMIN_STAFF,
              disabled: false,
              hidden: false,
              icon: (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z"
                    fill="#434343"
                  />
                  <path
                    d="M14.0809 14.1499C11.2909 12.2899 6.74094 12.2899 3.93094 14.1499C2.66094 14.9999 1.96094 16.1499 1.96094 17.3799C1.96094 18.6099 2.66094 19.7499 3.92094 20.5899C5.32094 21.5299 7.16094 21.9999 9.00094 21.9999C10.8409 21.9999 12.6809 21.5299 14.0809 20.5899C15.3409 19.7399 16.0409 18.5999 16.0409 17.3599C16.0309 16.1299 15.3409 14.9899 14.0809 14.1499Z"
                    fill="#434343"
                  />
                  <path
                    d="M19.9894 7.3401C20.1494 9.2801 18.7694 10.9801 16.8594 11.2101C16.8494 11.2101 16.8494 11.2101 16.8394 11.2101H16.8094C16.7494 11.2101 16.6894 11.2101 16.6394 11.2301C15.6694 11.2801 14.7794 10.9701 14.1094 10.4001C15.1394 9.4801 15.7294 8.1001 15.6094 6.6001C15.5394 5.7901 15.2594 5.0501 14.8394 4.4201C15.2194 4.2301 15.6594 4.1101 16.1094 4.0701C18.0694 3.9001 19.8194 5.3601 19.9894 7.3401Z"
                    fill="#434343"
                  />
                  <path
                    d="M21.9883 16.5899C21.9083 17.5599 21.2883 18.3999 20.2483 18.9699C19.2483 19.5199 17.9883 19.7799 16.7383 19.7499C17.4583 19.0999 17.8783 18.2899 17.9583 17.4299C18.0583 16.1899 17.4683 14.9999 16.2883 14.0499C15.6183 13.5199 14.8383 13.0999 13.9883 12.7899C16.1983 12.1499 18.9783 12.5799 20.6883 13.9599C21.6083 14.6999 22.0783 15.6299 21.9883 16.5899Z"
                    fill="#434343"
                  />
                </svg>
              ),
            },
          ]
        : []),
      ...linksToDisplay,
    ];
    setDisplaySidebarLinks(linksToDisplay);
  }, [modules, user.roleId]);

  return (
    <div className="content-view-page details-page">
      <div className="breadcrumb-wrapper">
        <Breadcrumb className="admin-breadcrumb">
          <Breadcrumb.Item>Profile Information</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="main-wrapper ">
        <div className="content-holder">
          <SidebarNew hideDeactivate links={displaySidebarLinks} visible>
            <BranchCard
              logo={profileInfo?.image}
              primaryText={`${profileInfo?.name ?? ""} ${
                profileInfo?.surname ?? ""
              }`}
              secondaryText={profileInfo?.label}
              showStatus
              isBranch={true}
              userStatus={profileInfo?.status === "active" ? 1 : 0}
            />
          </SidebarNew>
          <ContentContainer className="content-container">
            <Outlet />
          </ContentContainer>
        </div>
      </div>
    </div>
  );
};
