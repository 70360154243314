import React, { useEffect } from "react";
import "./style.scss";

export const PrivacyPolicy = () => {
  return (
    <>
      <div className="d-flex flex-column align-items-center privacyPolicyPage">
        <div className="privacy-content">
          <div className="heading2" style={{ marginBottom: "10px" }}>
            {" "}
            Privacy Policy
          </div>
          <div>
            <ul>
              <li className="p2">
                We are committed to safeguarding the privacy of our website
                visitors and service users.
              </li>
              <li className="p2">
                This policy applies in those cases where we act as a data
                controller for the personal data of our website visitors and
                service users. This means those cases where we can decide the
                purposes and method of processing your personal data.
              </li>
              <li className="p2">
                By using our website and agreeing to this policy, you also
                consent to our use of cookies in accordance with the terms of
                this policy.
              </li>
              <li className="p2">
                These privacy rules explain what data we may collect from you,
                what we will do with that data and explains how you can you
                limit the publication of your information and how you can choose
                whether or not you would like to receive direct marketing
                communications.
              </li>
              <li className="p2">
                In this policy, “we”, “us” and “our” refer to Signature
                Healthcare Recruitment Limited.Further details about us can be
                found below, in section 10 of this Privacy Policy.
              </li>
            </ul>
          </div>
          <div className="heading3" style={{ marginBottom: "10px" }}>
            How we use your personal data
          </div>
          <div>
            <ul>
              <li className="p2">
                In this Section 2 we set out:
                <ul>
                  <li>
                    the general categories of personal data that we may process;
                  </li>
                  <li>
                    in the case of personal data that we did not obtain directly
                    from you, the source and specific categories of that data;
                  </li>
                  <li>
                    the purposes for which we may process personal data; and
                  </li>
                  <li>the legal bases of the processing.</li>
                </ul>
              </li>
              <li className="p2">
                We may process data about your use of our website and services
                (“usage data“). The usage data may include your IP address,
                geographical location, browser type and version, operating
                system, referral source, length of visit, page views and website
                navigation paths, as well as information about the timing,
                frequency and pattern of your website or service use. The source
                of the usage data is our analytics tracking system. This usage
                data may be processed for the purposes of analysing the use of
                the website and services. The legal basis for this processing is
                either your specific consent or where we are not legally
                required to ask for consent, we may process this data for our
                legitimate interests, namely monitoring and improving our
                website and services.
              </li>
              <li className="p2">
                We may process your account data (“account data“). The account
                data may include your name, email address, contact phone number
                and postal address. The account data may be processed for the
                purposes of operating our website, providing our services,
                ensuring the security of our website and services, maintaining
                back-ups of our databases and communicating with you. The legal
                basis for this processing is either your specific consent or
                where we are not legally required to ask for consent, we may
                process this data for our legitimate interests, namely
                monitoring and improving our website and services.
              </li>
              <li className="p2">
                We may process your information included in your personal
                profile on our website (“profile data“). The profile data may
                include your name, address, telephone number, email address,
                profile pictures, gender, date of birth, relationship status,
                interests and hobbies, educational details, messages you send to
                other users and employment details. The profile data may be
                processed for the purposes of enabling and monitoring your use
                of our website and services. The legal basis for this processing
                is either your specific consent or where we are not legally
                required to ask for consent, we may process this data for our
                legitimate interests, namely monitoring and improving our
                website and services.
              </li>
              <li className="p2">
                We may process personal data that is provided in the course of
                the use of our services (“service data“). The service data may
                be processed for the purposes of operating our website,
                providing our services, ensuring the security of our website and
                services, maintaining back-ups of our databases and
                communicating with you. The legal basis for this processing is
                either your specific consent or where we are not legally
                required to ask for consent, we may process this data for our
                legitimate interests, namely monitoring and improving our
                website and services.
              </li>
              <li>
                We may process information that you post for publication on our
                website or through our services (“publication data“). The
                publication data may be processed for the purposes of enabling
                such publication and administering our website and services. The
                legal basis for this processing is either your specific consent
                or where we are not legally required to ask for consent, we may
                process this data for our legitimate interests, namely
                monitoring and improving our website and services.
              </li>
              <li>
                We may process information contained in any enquiry you submit
                to us regarding goods and/or services (“enquiry data“). The
                enquiry data may be processed for the purposes of offering,
                marketing and selling relevant goods and/or services to you. The
                legal basis for this processing is either your specific consent
                or where we are not legally required to ask for consent, we may
                process this data for our legitimate interests, namely
                monitoring and improving our website and services.
              </li>
              <li>
                We may process information relating to transactions, including
                purchases of goods and services that you enter into with us
                and/or through our website (“transaction data“). The transaction
                data may include your contact details, your card details and the
                transaction details. The transaction data may be processed for
                the purpose of supplying goods or services and keeping proper
                records of those transactions. The legal basis for this
                processing is the performance of a contract between you and us
                and/or taking steps, at your request, to enter into such a
                contract and our legitimate interests, namely our interest in
                the proper administration of our website and business.
              </li>
              <li>
                We may process any of your personal data identified in this
                policy where necessary for administrative purposes including in
                the exercise or defence of legal claims. The legal basis for
                this processing is our legitimate interests, namely for
                administrative record keeping, processing transactions and
                maintaining business records or for the protection and assertion
                of our legal rights.
              </li>
              <li>
                Please do not supply any other person’s personal data to us,
                unless we request you to do so.
              </li>
            </ul>
          </div>
          <div className="heading3" style={{ marginBottom: "10px" }}>
            Providing your personal data to others
          </div>
          <ul>
            <li>
              We may disclose your personal data to any member of our group of
              companies (this means our subsidiaries, our holding company and
              its subsidiaries) insofar as reasonably necessary for the
              purposes, and on the legal bases, set out in this policy.
            </li>
            <li>
              We may disclose your personal data to our insurers and/or
              professional advisers insofar as reasonably necessary for the
              purposes of obtaining or maintaining insurance coverage, managing
              risks, obtaining professional advice, or to exercise or defend
              legal claims.
            </li>
            <li>
              Financial transactions relating to our website and services are
              handled by our payment services providers, Stripe. We share
              transaction data with our payment services providers to the extent
              necessary for the purposes of processing your payments, refunding
              such payments and dealing with complaints and queries relating to
              such payments and refunds. You may also wish to review our payment
              services providers’ privacy policies and practices at
              https://stripe.com/gb/legal.
            </li>
            <li>
              In addition to the specific disclosures of personal data set out
              in this Section 3, we may disclose your personal data where such
              disclosure is necessary for compliance with a legal obligation to
              which we are subject, or in order to protect your legal interests
              or the legal interests of another person.
            </li>
          </ul>
          <div className="heading3" style={{ marginBottom: "10px" }}>
            International transfers of your personal data
          </div>
          <ul>
            <li>
              In this Section 4, we provide information about the circumstances
              in which your personal data may be transferred to countries
              outside the European Economic Area (EEA).
            </li>
            <li>
              We are not aware of any current circumstances where your personal
              data may be transferred to any country outside the EEA.
            </li>
            <li>
              You acknowledge that personal data that you submit for publication
              through our website or services may be available, via the
              internet, around the world. We cannot prevent the use (or misuse)
              of such personal data by others.
            </li>
          </ul>
          <div className="heading3" style={{ marginBottom: "10px" }}>
            Retaining and deleting personal data
          </div>
          <ul>
            <li>
              This Section 5 sets out our data retention policies and procedure,
              which are designed to help ensure that we comply with our legal
              obligations in relation to the retention and deletion of personal
              data.
            </li>
            <li>
              Personal data that we process for any purpose shall not be kept
              for longer than is necessary for that purpose.
            </li>
            <li>
              It is not possible for us to specify in advance the periods for
              which your personal data will be retained. However, we will
              determine the period of retention based on your continued use of
              our website or services and based on our requirements for proper
              record keeping and accounting and legal purposes.
            </li>
            <li>
              Notwithstanding the other provisions of this Section 5, we may
              retain your personal data where such retention is necessary for
              compliance with a legal obligation to which we are subject, or in
              order to protect your legal interests or the legal interests of
              another person.
            </li>
          </ul>
          <div className="heading3" style={{ marginBottom: "10px" }}>
            Amendments
          </div>
          <ul>
            <li>
              We may update this policy from time to time by publishing a new
              version on our website.
            </li>
            <li>
              You should check this page occasionally to ensure you are happy
              with any changes to this policy.
            </li>
            <li>
              We may notify you of changes to this policy by email or through
              the private messaging system on our website.
            </li>
          </ul>
          <div className="heading3" style={{ marginBottom: "10px" }}>
            Your rights
          </div>
          <ul>
            <li>
              In this Section 7, we have summarised the rights that you have
              under data protection law. Some of the rights are complex, and not
              all of the details have been included in our summaries.
              Accordingly, you should read the relevant laws and guidance from
              the regulatory authorities for a full explanation of these rights.
            </li>
            <li>
              Your principal rights under data protection law are:
              <ul>
                <li>the right to access;</li>
                <li>the right to rectification;</li>
                <li>the right to erasure;</li>
                <li>the right to restrict processing;</li>
                <li>the right to object to processing;</li>
                <li>the right to data portability;</li>
                <li>the right to complain to a supervisory authority; and</li>
                <li>the right to withdraw consent.</li>
              </ul>
            </li>
            <li>
              You have the right to confirmation as to whether or not we process
              your personal data and, where we do, access to the personal data,
              together with certain additional information. That additional
              information includes details of the purposes of the processing,
              the categories of personal data concerned and the recipients of
              the personal data. Providing the rights and freedoms of others are
              not affected, we will supply to you a copy of your personal data,
              as described below
            </li>
            <li>
              You have the right to have any inaccurate personal data about you
              rectified and, taking into account the purposes of the processing,
              to have any incomplete personal data about you completed.
            </li>
            <li>
              In some circumstances you have the right to the erasure of your
              personal data without undue delay. Those circumstances include:
              the personal data is no longer necessary in relation to the
              purposes for which it was collected or otherwise processed; you
              withdraw consent to consent-based processing; you object to the
              processing under certain rules of applicable data protection law;
              the processing is for direct marketing purposes; and the personal
              data have been unlawfully processed. However, there are exclusions
              of the right to erasure. The general exclusions include where
              processing is necessary: for exercising the right of freedom of
              expression and information; for compliance with a legal
              obligation; or for the establishment, exercise or defence of legal
              claims.
            </li>
            <li>
              In some circumstances you have the right to restrict the
              processing of your personal data. Those circumstances are: you
              contest the accuracy of the personal data; processing is unlawful
              but you oppose erasure; we no longer need the personal data for
              the purposes of our processing, but you require personal data for
              the establishment, exercise or defence of legal claims; and you
              have objected to processing, pending the verification of that
              objection. Where processing has been restricted on this basis, we
              may continue to store your personal data. However, we will only
              otherwise process it: with your consent; for the establishment,
              exercise or defence of legal claims; for the protection of the
              rights of another natural or legal person; or for reasons of
              important public interest.
            </li>
            <li>
              You have the right to object to our processing of your personal
              data on grounds relating to your particular situation, but only to
              the extent that the legal basis for the processing is that the
              processing is necessary for: the performance of a task carried out
              in the public interest or in the exercise of any official
              authority vested in us; or the purposes of the legitimate
              interests pursued by us or by a third party. If you make such an
              objection, we will cease to process the personal information
              unless we can demonstrate compelling legitimate grounds for the
              processing which override your interests, rights and freedoms, or
              the processing is for the establishment, exercise or defence of
              legal claims.
            </li>
            <li>
              You have the right to object to our processing of your personal
              data for direct marketing purposes (including profiling for direct
              marketing purposes). If you make such an objection, we will cease
              to process your personal data for this purpose.
            </li>
            <li>
              You have the right to object to our processing of your personal
              data for scientific or historical research purposes or statistical
              purposes on grounds relating to your particular situation, unless
              the processing is necessary for the performance of a task carried
              out for reasons of public interest.
            </li>
            <li>
              To the extent that the legal basis for our processing of your
              personal data is:
              <ul>
                <li>Consent ;or</li>
                <li>
                  that the processing is necessary for the performance of a
                  contract to which you are party or in order to take steps at
                  your request prior to entering into a contract, and such
                  processing is carried out by automated means, you have the
                  right to receive your personal data from us in a structured,
                  commonly used and machine-readable format. However, this right
                  does not apply where it would adversely affect the rights and
                  freedoms of others.
                </li>
              </ul>
            </li>
            <li>
              If you consider that our processing of your personal information
              infringes data protection laws, you have a legal right to lodge a
              complaint with a supervisory authority responsible for data
              protection. You may do so in the EU member state of your habitual
              residence, your place of work or the place of the alleged
              infringement.
            </li>
            <li>
              To the extent that the legal basis for our processing of your
              personal information is consent, you have the right to withdraw
              that consent at any time. Withdrawal will not affect the
              lawfulness of processing before the withdrawal.
            </li>
            <li>
              You may request that we provide you with any personal information
              we hold about you. Provision of this information will be subject
              to:
              <ul>
                <li>
                  the payment of a fee (currently fixed at (GBP) £10.00); and
                </li>
                <li>
                  the supply of appropriate evidence of your identity (for this
                  purpose, we will usually accept a photocopy of your passport
                  certified by a solicitor or bank plus an original copy of a
                  utility bill showing your current address).
                </li>
              </ul>
            </li>
            <li></li>
          </ul>
          <div className="heading3" style={{ marginBottom: "10px" }}>
            About cookies
          </div>
          <ul>
            <li>
              A cookie is a small file containing an identifier (a string of
              letters and numbers) that is sent by a web server to a web browser
              asking permission to be placed on your computer’s hard drive. The
              file is added and the cookie helps analyse web traffic or lets you
              know when you visit a particular site. Cookies allow web
              applications to respond to you as an individual. The web
              application can tailor its operations to your needs, likes and
              dislikes by gathering and remembering information about your
              preferences.
            </li>
            <li>
              Cookies may be either “persistent” cookies or “session” cookies: a
              persistent cookie will be stored by a web browser and will remain
              valid until its set expiry date, unless deleted by the user before
              the expiry date; a session cookie, on the other hand, will expire
              at the end of the user session, when the web browser is closed.
            </li>
            <li>
              Cookies do not typically contain any information that personally
              identifies a user, but personal information that we store about
              you may be linked to the information stored in and obtained from
              cookies.
            </li>
          </ul>
          <div className="heading3" style={{ marginBottom: "10px" }}>
            Cookies that we use
          </div>
          <ul>
            <li>
              We use traffic log cookies to identify which pages are being used.
              This helps us analyse data about web page traffic and improve our
              services in order to tailor them to customer needs. We only use
              this information for statistical analysis purposes and then the
              data is removed from the system.
            </li>
            <li>
              Overall, cookies help us provide you with a better experience, by
              enabling us to monitor which pages you find useful and which you
              do not. A cookie in no way gives us access to your computer or any
              information about you, other than the data you choose to share
              with us.
            </li>
            <li>
              You can choose to accept or decline cookies. Most web browsers
              automatically accept cookies, but you can usually modify your
              browser setting to decline cookies if you prefer. This may prevent
              you from taking full advantage of our services.
            </li>
            <li>
              We may use Google Analytics and Facebook Pixel to analyse the use
              of our website. Google Analytics and Facebook Pixel gather
              information about website use by means of cookies. The information
              gathered relating to our website is used to create reports about
              the use of our website. Google’s privacy policy can be found at
              the following web address:
              https://www.google.com/policies/privacy/. Facebook Pixel’s privacy
              policy can be found here: pixel.facebook.com/terms.
            </li>
          </ul>
          <div className="heading3" style={{ marginBottom: "10px" }}>
            Our details
          </div>
          <ul>
            <li>
              This website is owned and operated by Signature Healthcare
              Recruitment Limited.
            </li>
            <li>
              We are registered in England and Wales under registration number
              10796948and our registered office is at is 6 The Marlins,
              Northwood, United Kingdom, HA6 3NP.
            </li>
            <li>
              Our principal place of business is also at the above address.
            </li>
            <li>
              You can contact us:
              <ul>
                <li>by post, to the postal address given above; or</li>
                <li>
                  by email, using the email address published on our website
                  from time to time.
                </li>
              </ul>
            </li>
          </ul>
          <div className="heading3" style={{ marginBottom: "10px" }}>
            Data protection officer
          </div>
          <ul>
            <li>
              Our data protection officer’s is Reece Samani. He can be contacted
              at: support@thelocumapp.co.uk.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
