import React, { useMemo } from "react"
import contact from '../../assets/icons/contact.png'
import email from '../../assets/icons/email.png'
import location from '../../assets/icons/location.png'
import time from '../../assets/icons/time.png'
import schedule from '../../assets/icons/schedule.png'
import price from '../../assets/icons/price.png'
import moment from "moment"


export const ContactDetail = ({ shiftDetails }) => {

    const totalHours = useMemo(() => {
        var startTime = moment(shiftDetails?.startTime, "hh:mm:ss a");
        var endTime = moment(shiftDetails?.endTime, "hh:mm:ss a");

        var mins = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("mm")

        return endTime.diff(startTime, 'hours') + "h " + mins + "m";

    }, [shiftDetails])
    return (
        <div className="p-5 d-flex border-bottom" style={{ gap: 29, width: '100%' }}>
            <div className="d-flex flex-column" style={{ gap: 15, width: '50%' }}>
                <div className="h5">Contact Details</div>
                <div className="d-flex align-items-center" style={{ gap: 10 }}>
                    <img src={contact} alt='contact' />
                    <div className="p3">{shiftDetails?.phone}</div>
                </div>
                <div className="d-flex align-items-center" style={{ gap: 10 }}>
                    <img src={email} alt='email' />
                    <div className="p3">{shiftDetails?.email}</div>
                </div>
                <div className="d-flex align-items-center" style={{ gap: 10 }}>
                    <img src={location} alt='location' />
                    <div className="p3">{`${shiftDetails?.address}, ${shiftDetails?.address2}, ${shiftDetails?.city}, ${shiftDetails?.zip}`}</div>
                </div>
            </div>
            <div className="d-flex flex-column" style={{ gap: 15, width: '50%' }}>
                <div className="h5">Other Details</div>
                <div className="d-flex align-items-center" style={{ gap: 10 }}>
                    <img src={time} alt='time' />
                    <div className="p3">{shiftDetails?.startTime} - {shiftDetails?.endTime} ({totalHours})</div>
                </div>
                <div className="d-flex align-items-center" style={{ gap: 10 }}>
                    <img src={schedule} alt='schedule' />
                    <div className="p3">{moment(shiftDetails?.startDate).format('DD-MM-YYYY')} (Job Date)</div>
                </div>
                <div className="d-flex align-items-center" style={{ gap: 10 }}>
                    <img src={price} alt='price' />
                    <div className="p3">£{shiftDetails?.hourlyRate}/h </div>
                </div>
            </div>
        </div>
    )
}