export const dayviewdataMock = [
  {
    type: "Pharmasict",
    timings: [
      {
        startTime: "00:00",
        endTime: "01:00",
        shifts: [
          {
            name: "Cameron Williamson",
            startTime: "00:00",
            endTime: "01:00",
            breakDuration: "2h",
            isStaff: true,
            isLocum: false,
            isLocumReviewPending: false,
            reviewPendingCount: 5,
          },
          {
            name: "Cameron Williamson",
            startTime: "00:00",
            endTime: "01:00",
            breakDuration: "2h",
            isStaff: true,
            isLocum: false,
            isLocumReviewPending: false,
            reviewPendingCount: 5,
          },
        ],
      },
      {
        startTime: "01:00",
        endTime: "02:00",
        shifts: [],
      },
      {
        startTime: "02:00",
        endTime: "03:00",
        shifts: [],
      },
      {
        startTime: "03:00",
        endTime: "04:00",
        shifts: [],
      },
      {
        startTime: "04:00",
        endTime: "05:00",
        shifts: [],
      },
      {
        startTime: "05:00",
        endTime: "06:00",
        shifts: [],
      },
      {
        startTime: "06:00",
        endTime: "07:00",
        shifts: [],
      },
      {
        startTime: "07:00",
        endTime: "08:00",
        shifts: [],
      },
      {
        startTime: "08:00",
        endTime: "09:00",
        shifts: [],
      },
      {
        startTime: "09:00",
        endTime: "10:00",
        shifts: [],
      },

      {
        startTime: "10:00",
        endTime: "11:00",
        shifts: [
          {
            name: "Cameron Williamson",
            startTime: "10:00",
            endTime: "14:00",
            breakDuration: "2h",
            isStaff: true,
            isLocum: false,
            isLocumReviewPending: false,
            reviewPendingCount: 5,
          },
        ],
      },
      {
        startTime: "11:00",
        endTime: "12:00",
        shifts: [],
      },
      {
        startTime: "12:00",
        endTime: "13:00",
        shifts: [],
      },
      {
        startTime: "13:00",
        endTime: "14:00",
        shifts: [],
      },
      {
        startTime: "14:00",
        endTime: "15:00",
        shifts: [],
      },
      {
        startTime: "15:00",
        endTime: "16:00",
        shifts: [
          {
            name: "Cameron Williamson",
            startTime: "15:00",
            endTime: "19:00",
            breakDuration: "2h",
            isStaff: true,
            isLocum: false,
            isLocumReviewPending: false,
            reviewPendingCount: 5,
          },
        ],
      },
      {
        startTime: "16:00",
        endTime: "17:00",
        shifts: [],
      },
      {
        startTime: "17:00",
        endTime: "18:00",
        shifts: [],
      },
      {
        startTime: "18:00",
        endTime: "19:00",
        shifts: [],
      },
      {
        startTime: "19:00",
        endTime: "20:00",
        shifts: [],
      },
      {
        startTime: "20:00",
        endTime: "21:00",
        shifts: [],
      },
      {
        startTime: "21:00",
        endTime: "22:00",
        shifts: [],
      },
      {
        startTime: "22:00",
        endTime: "23:00",
        shifts: [
          {
            name: "Cameron Williamson",
            startTime: "21:00",
            endTime: "22:00",
            breakDuration: "2h",
            isStaff: true,
            isLocum: false,
            isLocumReviewPending: false,
            reviewPendingCount: 5,
          },
        ],
      },
    ],
  },
  {
    type: "Counter",
    timings: [
      {
        startTime: "00:00",
        endTime: "01:00",
        shifts: [],
      },
      {
        startTime: "01:00",
        endTime: "02:00",
        shifts: [
          {
            name: "Cameron Williamson",
            startTime: "01:00",
            endTime: "02:00",
            breakDuration: "2h",
            isStaff: false,
            isLocum: true,
            isLocumReviewPending: false,
            reviewPendingCount: 5,
          },
        ],
      },
      {
        startTime: "02:00",
        endTime: "03:00",
        shifts: [],
      },
      {
        startTime: "03:00",
        endTime: "04:00",
        shifts: [],
      },
      {
        startTime: "04:00",
        endTime: "05:00",
        shifts: [],
      },
      {
        startTime: "05:00",
        endTime: "06:00",
        shifts: [],
      },
      {
        startTime: "06:00",
        endTime: "07:00",
        shifts: [],
      },
      {
        startTime: "07:00",
        endTime: "08:00",
        shifts: [],
      },
      {
        startTime: "08:00",
        endTime: "09:00",
        shifts: [],
      },
      {
        startTime: "09:00",
        endTime: "10:00",
        shifts: [],
      },
      {
        startTime: "10:00",
        endTime: "11:00",
        shifts: [
          {
            name: "Cameron Williamson",
            startTime: "10:00",
            endTime: "14:00",
            breakDuration: "2h",
            isStaff: true,
            isLocum: false,
            isLocumReviewPending: false,
            reviewPendingCount: 5,
          },
        ],
      },
      {
        startTime: "11:00",
        endTime: "12:00",
        shifts: [],
      },
      {
        startTime: "12:00",
        endTime: "13:00",
        shifts: [],
      },
      {
        startTime: "13:00",
        endTime: "14:00",
        shifts: [],
      },
      {
        startTime: "14:00",
        endTime: "15:00",
        shifts: [],
      },
      {
        startTime: "15:00",
        endTime: "16:00",
        shifts: [
          {
            name: "Cameron Williamson",
            startTime: "15:00",
            endTime: "19:00",
            breakDuration: "2h",
            isStaff: true,
            isLocum: false,
            isLocumReviewPending: false,
            reviewPendingCount: 5,
          },
        ],
      },
      {
        startTime: "16:00",
        endTime: "17:00",
        shifts: [],
      },
      {
        startTime: "17:00",
        endTime: "18:00",
        shifts: [],
      },
      {
        startTime: "18:00",
        endTime: "19:00",
        shifts: [],
      },
      {
        startTime: "19:00",
        endTime: "20:00",
        shifts: [],
      },
      {
        startTime: "20:00",
        endTime: "21:00",
        shifts: [],
      },
      {
        startTime: "21:00",
        endTime: "22:00",
        shifts: [],
      },
      {
        startTime: "22:00",
        endTime: "23:00",
        shifts: [],
      },
    ],
  },
  {
    type: "Technician",
    timings: [
      {
        startTime: "00:00",
        endTime: "01:00",
        shifts: [],
      },
      {
        startTime: "01:00",
        endTime: "02:00",
        shifts: [],
      },
      {
        startTime: "02:00",
        endTime: "03:00",
        shifts: [
          {
            name: "Cameron Williamson",
            startTime: "02:00",
            endTime: "03:00",
            breakDuration: "2h",
            isStaff: true,
            isLocum: false,
            isLocumReviewPending: false,
            reviewPendingCount: 5,
          },
        ],
      },
      {
        startTime: "03:00",
        endTime: "04:00",
        shifts: [],
      },
      {
        startTime: "04:00",
        endTime: "05:00",
        shifts: [],
      },
      {
        startTime: "05:00",
        endTime: "06:00",
        shifts: [],
      },
      {
        startTime: "06:00",
        endTime: "07:00",
        shifts: [],
      },
      {
        startTime: "07:00",
        endTime: "08:00",
        shifts: [],
      },
      {
        startTime: "08:00",
        endTime: "09:00",
        shifts: [],
      },
      {
        startTime: "09:00",
        endTime: "10:00",
        shifts: [],
      },
      {
        startTime: "10:00",
        endTime: "11:00",
        shifts: [
          {
            name: "Cameron Williamson",
            startTime: "10:00",
            endTime: "14:00",
            breakDuration: "2h",
            isStaff: true,
            isLocum: false,
            isLocumReviewPending: false,
            reviewPendingCount: 5,
          },
        ],
      },
      {
        startTime: "11:00",
        endTime: "12:00",
        shifts: [],
      },
      {
        startTime: "12:00",
        endTime: "13:00",
        shifts: [],
      },
      {
        startTime: "13:00",
        endTime: "14:00",
        shifts: [],
      },
      {
        startTime: "14:00",
        endTime: "15:00",
        shifts: [],
      },
      {
        startTime: "15:00",
        endTime: "16:00",
        shifts: [
          {
            name: "Cameron Williamson",
            startTime: "15:00",
            endTime: "19:00",
            breakDuration: "2h",
            isStaff: true,
            isLocum: false,
            isLocumReviewPending: false,
            reviewPendingCount: 5,
          },
        ],
      },
      {
        startTime: "16:00",
        endTime: "17:00",
        shifts: [],
      },
      {
        startTime: "17:00",
        endTime: "18:00",
        shifts: [],
      },
      {
        startTime: "18:00",
        endTime: "19:00",
        shifts: [],
      },
      {
        startTime: "19:00",
        endTime: "20:00",
        shifts: [],
      },
      {
        startTime: "20:00",
        endTime: "21:00",
        shifts: [],
      },
      {
        startTime: "21:00",
        endTime: "22:00",
        shifts: [],
      },
      {
        startTime: "22:00",
        endTime: "23:00",
        shifts: [],
      },
    ],
  },
];
