import React from "react";

const PaymentPendingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M4.16732 11.668C2.32565 11.668 0.833984 13.1596 0.833984 15.0013C0.833984 16.843 2.32565 18.3346 4.16732 18.3346C6.00898 18.3346 7.50065 16.843 7.50065 15.0013C7.50065 13.1596 6.00898 11.668 4.16732 11.668ZM5.00065 14.7346C5.00065 15.243 4.72565 15.7263 4.29232 15.9846L3.65898 16.368C3.55898 16.4346 3.44232 16.4596 3.33398 16.4596C3.12565 16.4596 2.91732 16.3513 2.80065 16.1596C2.62565 15.868 2.71732 15.4763 3.01732 15.3013L3.65065 14.918C3.70898 14.8846 3.75065 14.8096 3.75065 14.743V13.968C3.75065 13.618 4.03398 13.3346 4.37565 13.3346C4.71732 13.3346 5.00065 13.618 5.00065 13.9596V14.7346Z"
        fill="#025E8F"
      />
      <path
        d="M14.166 3.33203H5.83268C3.33268 3.33203 1.66602 4.58203 1.66602 7.4987V10.4654C1.66602 10.7737 1.98268 10.9654 2.25768 10.8404C3.07435 10.4654 4.01602 10.3237 5.00768 10.4987C7.19935 10.8904 8.80768 12.9237 8.74935 15.1487C8.74102 15.4987 8.69102 15.8404 8.59935 16.1737C8.53268 16.432 8.74102 16.6737 9.00768 16.6737H14.166C16.666 16.6737 18.3327 15.4237 18.3327 12.507V7.4987C18.3327 4.58203 16.666 3.33203 14.166 3.33203ZM9.99935 12.082C8.84935 12.082 7.91602 11.1487 7.91602 9.9987C7.91602 8.8487 8.84935 7.91536 9.99935 7.91536C11.1493 7.91536 12.0827 8.8487 12.0827 9.9987C12.0827 11.1487 11.1493 12.082 9.99935 12.082ZM16.041 11.6654C16.041 12.007 15.7577 12.2904 15.416 12.2904C15.0743 12.2904 14.791 12.007 14.791 11.6654V8.33203C14.791 7.99036 15.0743 7.70703 15.416 7.70703C15.7577 7.70703 16.041 7.99036 16.041 8.33203V11.6654Z"
        fill="#025E8F"
      />
    </svg>
  );
};

export default PaymentPendingIcon;
