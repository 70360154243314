import { createSlice } from "@reduxjs/toolkit";
import {
  archiveBranchThunk,
  blockLocumThunk,
  createBranchThunk,
  getBranchBlockListThunk,
  getBranchDataThunk,
  getbranchesListThunk,
  getBranchDashboardDetailThunk,
  getBranchRelatedDataThunk,
  getBranchStaffListThunk,
  updateBranchOperatingTimeThunk,
  updateBranchThunk,
  getPendingJobDetailThunk,
  getBranchScheduleThunk,
  getBranchGapsDataThunk,
  unArchiveBranchThunk,
  getBranchTemplateThunk,
} from "../branchesThunk";

const branchRelatedData = {
  branchServices: [],
  countries: [],
  pharmacySystems: [],
  regions: [],
  staffSupportLevels: [],
};

const initialState = {
  branches: [],
  branchStaffList: [],
  branchBlockList: [],
  branchInfo: {},
  isloading: false,
  branchDashboardData: [],
  branchGapsData: [],
  pendingJobApprovalData: [],
  branchRelatedData,
  branchSchedule: [],
  branchTemplate: []
};

const branchesSlicer = createSlice({
  name: "branchesSlicer",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getbranchesListThunk.fulfilled, (state, action) => {
        state.isloading = false;
        state.branches = action.payload;
      })
      .addCase(getBranchDashboardDetailThunk.fulfilled, (state, action) => {
        state.isloading = false;
        state.branchDashboardData = action.payload;
      })

      .addCase(getBranchDataThunk.fulfilled, (state, action) => {
        state.isloading = false;
        state.branchInfo = action.payload;
      })
      .addCase(getBranchGapsDataThunk.fulfilled, (state, action) => {
        state.isloading = false;
        state.branchGapsData = action.payload;
      })
      .addCase(getPendingJobDetailThunk.fulfilled, (state, action) => {
        state.isloading = false;
        state.pendingJobApprovalData = action.payload;
      })
      .addCase(getBranchStaffListThunk.fulfilled, (state, action) => {
        state.isloading = false;
        state.branchStaffList = action.payload;
      })
      .addCase(getBranchBlockListThunk.fulfilled, (state, action) => {
        state.isloading = false;
        state.branchBlockList = action.payload;
      })
      .addCase(getBranchRelatedDataThunk.fulfilled, (state, action) => {
        state.isloading = false;
        state.branchRelatedData = action.payload;
      })
      .addCase(createBranchThunk.fulfilled, (state, action) => {
        state.isloading = false;
        state.branches.rows.unshift(action.payload);
      })
      .addCase(updateBranchThunk.fulfilled, (state, action) => {
        state.isloading = false;
        state.branchInfo = action.payload;
      })
      // .addCase(updateBranchOperatingTimeThunk.fulfilled, (state, action) => {
      //   state.branchInfo.operatingTime = action.payload;
      // })
      .addCase(archiveBranchThunk.fulfilled, (state, action) => {
        state.branches.rows = state.branches.rows.map((item) => {
          const updatedItem = action.payload.find((list) => list === item.id);
          if (updatedItem) {
            return { ...item, isArchived: true };
          }
          return item;
        });
      })
      .addCase(unArchiveBranchThunk.fulfilled, (state, action) => {
        state.branches.rows = state.branches.rows.map((item) => {
          const updatedItem = action.payload.find((list) => list === item.id);
          if (updatedItem) {
            return { ...item, isArchived: false };
          }
          return item;
        });
      })
      .addCase(blockLocumThunk.fulfilled, (state, action) => {
        state.branchBlockList = action.payload;
      })
      .addCase(getBranchScheduleThunk.fulfilled, (state, action) => {
        state.branchSchedule = action.payload;
      })
      .addCase(getBranchTemplateThunk.fulfilled, (state, action) => {
        state.branchTemplate = action.payload;
      });
  },
});

export const branchesReducer = branchesSlicer
