import { API } from "../../../axios";

export const getLocumSettingData = () => {
  const request = API.get("settings/locum");
  return request;
};

export const updateLocumSettingData = (payload) => {
  const request = API.post("settings/locum", payload);
  return request;
};

export const getLocumAttendanceStats = (payload) => {
  const request = API.post("personal/attendance/stats", payload);
  return request;
};

export const getLocumAttendance = (payload) => {
  const request = API.post("personal/attendance/details", payload);
  return request;
};

export const getLocumAttendanceJobs = (payload) => {
  const request = API.get("personal/attendance", payload);
  return request;
};

export const cancelJob = (payload) => {
  const { id, data } = payload;
  const request = API.post(`jobs/${id}/cancel`, data);
  return request;
};

export const applyJob = (payload) => {
  const { id, data } = payload;
  const request = API.post(`jobs/${id}/apply`, data);
  return request;
};

export const acceptJobInvite = (payload) => {
  const { id } = payload;
  const request = API.post(`jobs/${id}/accept-invite`);
  return request;
};

export const rejectJobInvite = (payload) => {
  const { id } = payload;
  const request = API.post(`jobs/${id}/decline-invite`);
  return request;
};

export const getPeronalSchedule = (payload) => {
  const request = API.post(`personal/schedule`, payload);
  return request;
};

export const onMarkAttendance = (payload) => {
  const request = API.post(`personal/mark/attendance`, payload, {
    loader: false,
  });
  return request;
};

export const getStaffContract = (payload) => {
  const { staffId } = payload;
  const request = API.get(`/staff/${staffId}/contract/`);
  return request;
};

export const getAllNotification = (payload) => {
  const request = API.post(`profile/notifications`, payload, {
    loader: false
  });
  return request;
};

export const getNotificationById = (payload) => {
  const { id } = payload;
  const request = API.get(`profile/notifications/${id}`);
  return request;
};

export const applyRequiredAccreditations = (payload) => {
  const { id, formData } = payload;
  const request = API.post(`/jobs/${id}/required-accreditations`, formData);
  return request;
};
