import React from 'react';

const CalendarIconNormal = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_21825_16874)">
        <path
          d="M13.334 2.5V5.83333M6.66732 2.5V5.83333M3.33398 9.16667H16.6673M8.33398 13.3333H11.6673M5.00065 4.16667H15.0007C15.9211 4.16667 16.6673 4.91286 16.6673 5.83333V15.8333C16.6673 16.7538 15.9211 17.5 15.0007 17.5H5.00065C4.08018 17.5 3.33398 16.7538 3.33398 15.8333V5.83333C3.33398 4.91286 4.08018 4.16667 5.00065 4.16667Z"
          stroke="#434343"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_21825_16874">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CalendarIconNormal;
