import React from "react";
import { NavLink } from "react-router-dom";
import {
    RightOutlined,
} from '@ant-design/icons';
import "./style.scss";

export const Sidebar = ({ links = [], visible, ...props }) => {
    const navLinks = links.map((link) => {
        const { to, text, disabled, hidden, isActive } = link;
        return (
            <NavLink
                key={to}
                to={to}
                isActive={isActive}
                className={`${hidden ? "hidden" : disabled ? "disabled" : ""
                    } sidebar__link`}
            >
                {text}
                <RightOutlined />
            </NavLink>
        );
    });
    return (
        <aside className={`sidebar ${visible ? "" : "hidden"}`}>
            <div className="sidebar__top">{props.children}</div>
            {links.length > 0 ? <div className="sidebar__bottom">{navLinks}</div> : ""}
        </aside>
    );
};
