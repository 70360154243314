import {
  Badge,
  Button,
  Select,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { queryClient } from "../..";
import Dropdown from "../../assets/icons/chevron-down (1).svg";
import ArrowLeft from "../../assets/icons/chevron-left.svg";
import ArrowRight from "../../assets/icons/chevron-right.svg";
import CloseCircle from "../../assets/icons/close-circle.svg";
import TickCircle from "../../assets/icons/tick-circle.svg";
import useWindowEvent from "../../hooks/useWindowEvent";
import { updateAttendanceThunk } from "../../redux/staffs/staffsThunk";
import { shiftStatus } from "../../utils/constant";
import {
  getTotalMinutes,
  minuteTohour,
} from "../../utils/dates";
import { notify } from "../../utils/helper";
import {
  checkAnyPendingShift,
  getScheduleHourAndWorkedHour,
  showLabel,
} from "../../utils/timeshhet";
import Employeename from "./Employeename";
import ShiftTimeBox from "./ShiftTimeBox";

const perPageOptions = [
  { value: 10, label: "10" },
  { value: 25, label: "25" },
  { value: 50, label: "50" },
];

export const CustomFooter = ({
  perPage,
  handlePerPage,
  data,
  page,
  handleMoveToPage,
}) => {
  const pageOptions = useMemo(() => {
    let temp = [];

    for (
      let index = 0;
      index < data?.data?.pages;
      index++
    ) {
      temp.push({
        value: index + 1,
        label: index + 1,
      });
    }

    return temp;
  }, [JSON.stringify(data)]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between ">
        <div className="footer_left d-flex align-items-center justify-content-between">
          <form>
            {" "}
            <label htmlFor="perpage">
              Items per page
            </label>
            <Select
              defaultValue={perPage}
              options={perPageOptions}
              bordered={false}
              suffixIcon={
                <img
                  src={Dropdown}
                  alt="dropdown"
                />
              }
              onChange={handlePerPage}
              placement="topRight"
            />
          </form>
          <p className="items_toshow">
            {data?.data?.rows?.length} of{" "}
            {data?.data?.count} items
          </p>
        </div>
        <div className="footer-right d-flex align-items-center justify-content-between">
          <form>
            {" "}
            <Select
              defaultValue={data?.data?.page}
              //   style={{ width: 120 }}
              bordered={false}
              options={pageOptions}
              value={page}
              suffixIcon={
                <img
                  src={Dropdown}
                  alt="dropdown"
                />
              }
              onChange={handleMoveToPage}
              placement="topRight"
            />
            <label htmlFor="perpage">
              of {data?.data?.pages} pages
            </label>
          </form>

          <div className="arrow-buttons">
            <Button
              onClick={() =>
                handleMoveToPage(
                  data?.data?.page - 1
                )
              }
              icon={
                <img src={ArrowLeft} alt="left" />
              }
              disabled={page === 1}
            />

            <Button
              onClick={() =>
                handleMoveToPage(
                  data?.data?.page + 1
                )
              }
              icon={
                <img
                  src={ArrowRight}
                  alt="right"
                />
              }
              disabled={
                page > data?.data?.pages - 1
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

const RenderMultipleShift = ({
  date,
  shifts,
  absence,
}) => {
  const findShifts = shifts?.length
    ? shifts?.filter(
        (_s) => _s?.startDate === date
      )
    : [];

  const findAbsense = absence?.length
    ? absence?.filter(
        (_s) =>
          moment(_s?.from).format(
            "YYYY-MM-DD"
          ) === date
      )
    : [];

  // if (findShifts?.length === 0 && findAbsense?.length === 0) return null;

  return (
    <div className="row">
      {findShifts?.map((_shift) => (
        <div
          className="col-12 mb-1"
          key={_shift?.id}
        >
          <ShiftTimeBox item={_shift} />
        </div>
      ))}

      {findAbsense?.map((_absence) => (
        <div
          className="col-12 mb-1"
          key={_absence?.id}
        >
          <ShiftTimeBox
            type="leave"
            absence={_absence}
          />
        </div>
      ))}
    </div>
  );
};

const TimeSheetNewTable = ({
  weekArray,
  data,
  perPage,
  handlePerPage,
  handleMoveToPage,
  page,
  startDate,
  endDate,
  initialView,
}) => {
  const [dataset, setdataset] = useState([]);
  const dispatch = useDispatch();
  const _tableRef = useRef(null);

  const calculate = () => {
    const className = "add-scroll";
    // const ele = _tableRef?.current?.getElementsByClassName("ant-table-body");
    // const scrollHeight = ele[0].scrollHeight || 1;
    // const clientHeight = ele[0].offsetHeight || 0;
    // if (!!_tableRef.current) {
    //   const hasClass = _tableRef.current.classList.contains(className);
    //   if (scrollHeight - clientHeight > 10) {
    //     _tableRef.current.classList.remove(className);
    //   } else {
    //     _tableRef.current.classList.add(className);
    //   }
    // }
  };

  useWindowEvent("resize", calculate);

  useEffect(() => {
    calculate();
  }, [_tableRef?.current]);

  const _weekDayList = useMemo(() => {
    const temp = [];
    weekArray.forEach((element) => {
      temp.push({
        title: (
          <p>
            {moment(element)
              .format("ddd")
              .toString()}{" "}
            <b>
              {moment(element)
                .format("DD MMM")
                .toString()}
            </b>
          </p>
        ),
        dataIndex:
          moment(element).format("YYYY-MM-DD"),
        width: 130,
        key: "day",
      });
    });

    return temp;
  }, [JSON.stringify(weekArray)]);

  const columns = [
    {
      title: "",
      dataIndex: "name",
      //   rowScope: "row",
      width: 350,
      fixed: "left",
    },
    ..._weekDayList,
    {
      title: "Actions",
      dataIndex: "action",
      fixed: "right",
      width: 109,
      key: "action",
    },
  ];

  const handleAttandanceAcceptOrRejectPerRow = (
    shifts,
    type,
    userId
  ) => {
    let payload = [];

    if (shifts?.length) {
      shifts.forEach((_shift) => {
        if (
          _shift?.attendance?.status === "pending"
        ) {
          payload?.push({
            recordId: _shift?.attendance?.id,
            checkIn:
              _shift?.attendance?.manualCheckIn,
            checkOut:
              _shift?.attendance?.manualCheckOut,
            type,
          });
        }
      });
    }

    dispatch(
      updateAttendanceThunk({ payload, userId })
    ).then((res) => {
      if (res.payload) {
        queryClient.invalidateQueries(
          "getStaffTimeSheet"
        );
        notify(
          "success",
          "Shifts updated successfully."
        );
      }
    });
  };

  const prepareData = () => {
    let temp = [];
    let totalScheduled = 0;
    let totalWorked = 0;
    let allShifts = [];

    data?.data?.rows?.forEach((item, index) => {
      const wokredMin =
        getScheduleHourAndWorkedHour(item);

      totalScheduled += wokredMin.scheduled;
      totalWorked += wokredMin.worked;
      allShifts = [...allShifts, ...item?.shifts];

      let obj = {
        key: index,
        name: (
          <Employeename
            name={`${item?.name} ${item?.surname}`}
            role={showLabel(item?.roleTitle)}
            roleId={item?.roleId}
            s={wokredMin?.scheduled}
            w={wokredMin?.worked}
            img={item?.userProfile?.image}
            userId={item?.id}
            initialStartDate={startDate}
            initialEndDate={endDate}
            initialView={initialView}
          />
        ),
      };

      item?.shifts?.forEach((_shifts) => {
        obj = {
          ...obj,
          [_shifts?.startDate]: (
            <>
              <RenderMultipleShift
                date={_shifts?.startDate}
                shifts={item?.shifts}
                absence={item?.absences}
              />
            </>
          ),
        };
      });

      obj = {
        ...obj,
        action: (
          <div className="action_buttons">
            {checkAnyPendingShift(
              item?.shifts
            ) ? (
              <>
                <Tooltip title="Approve">
                  {" "}
                  <Button
                    className="btn"
                    icon={
                      <img
                        src={TickCircle}
                        alt="tick"
                      />
                    }
                    onClick={() =>
                      handleAttandanceAcceptOrRejectPerRow(
                        item?.shifts,
                        shiftStatus.PRESENT,
                        item?.id
                      )
                    }
                  />
                </Tooltip>
                <Tooltip title="Decline">
                  {" "}
                  <Button
                    onClick={() =>
                      handleAttandanceAcceptOrRejectPerRow(
                        item?.shifts,
                        shiftStatus.ABSENT,
                        item?.id
                      )
                    }
                    className="btn"
                    icon={
                      <img
                        src={CloseCircle}
                        alt="close"
                      />
                    }
                  />
                </Tooltip>
              </>
            ) : null}
          </div>
        ),
      };

      temp.push(obj);
    });

    // calculate wokred hours for each day;
    let _totalWorkedPerDay = {};

    weekArray?.forEach((date) => {
      const shifts = allShifts.filter(
        (_shifts) =>
          _shifts?.startDate ===
          moment(date).format("YYYY-MM-DD")
      );

      let _hours = 0;

      shifts?.forEach((_shift) => {
        const actualCheckIn =
          _shift?.attendance?.actualCheckIn ||
          _shift?.attendance?.manualCheckIn;
        const actualCheckOut =
          _shift?.attendance?.actualCheckOut ||
          _shift?.attendance?.manualCheckOut;

        _hours =
          _hours +
          getTotalMinutes(
            actualCheckIn,
            actualCheckOut
          );
      });

      _totalWorkedPerDay = {
        ..._totalWorkedPerDay,
        [moment(date).format("YYYY-MM-DD")]:
          minuteTohour(_hours),
      };
    });

    temp.push({
      key: "table-footer",
      name: (
        <div className="d-flex justify-content-between align-items-center table-footer">
          <p>
            Employees: {data?.data?.rows?.length}
          </p>
          <div className="badge_wrapper">
            <Tooltip
              title={
                <>
                  <p className="mb-0">
                    Scheduled:{" "}
                    {minuteTohour(totalScheduled)}
                  </p>
                  <p>
                    Worked:{" "}
                    {minuteTohour(totalWorked)}
                  </p>
                </>
              }
            >
              <Badge>
                <span>
                  S:{minuteTohour(totalScheduled)}
                </span>{" "}
                <span>
                  W:{minuteTohour(totalWorked)}
                </span>
              </Badge>
            </Tooltip>
          </div>
        </div>
      ),

      ..._totalWorkedPerDay,
      action: checkAnyPendingShift(allShifts) ? (
        <div className="action_buttons">
          <Tooltip title="Approve All">
            <Button
              className="btn"
              icon={
                <img
                  src={TickCircle}
                  alt="tick"
                />
              }
              onClick={() =>
                handleAttandanceAcceptOrRejectPerRow(
                  allShifts,
                  shiftStatus.PRESENT
                )
              }
            />
          </Tooltip>
          <Tooltip title="Decline All">
            <Button
              className="btn"
              onClick={() =>
                handleAttandanceAcceptOrRejectPerRow(
                  allShifts,
                  shiftStatus.ABSENT
                )
              }
              icon={
                <img
                  src={CloseCircle}
                  alt="close"
                />
              }
            />
          </Tooltip>
        </div>
      ) : null,
    });

    setdataset(temp);
  };

  useEffect(() => {
    prepareData();
  }, [
    JSON.stringify(data),
    JSON.stringify(data?.data?.rows),
  ]);

  return (
    <div className="timesheet_table_container">
      <Table
        columns={columns}
        ref={_tableRef}
        dataSource={dataset}
        bordered
        pagination={false}
        footer={() => (
          <CustomFooter
            handlePerPage={handlePerPage}
            data={data}
            perPage={perPage}
            handleMoveToPage={handleMoveToPage}
            page={page}
          />
        )}
        scroll={{
          x: 1200,
          y: "calc(100vh - 450px)",
        }}
        // style={{overflowX:'auto'}}
      />
    </div>
  );
};

export default TimeSheetNewTable;
