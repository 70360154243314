import React, { useMemo } from "react"
import amountIcon from '../../assets/icons/amount.png'

export const DetailsComponent = ({ shiftDetails }) => {
    const altText = useMemo(() => {
        return shiftDetails?.branchTitle?.split(' ')?.map((d, idx) => idx < 2 ? d?.charAt(0).toUpperCase() : null).join('')
    }, [shiftDetails])
    return (
        <div className="p-5 d-flex flex-column border-bottom" style={{ gap: 29 }}>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center" style={{ gap: 15 }}>
                    {
                        shiftDetails?.logo ?
                            <img className="border rounded-circle" width={70} height={70} src={shiftDetails?.logo} alt="avatar" />
                            :
                            <div
                                style={{
                                    width: 70,
                                    height: 70,
                                    borderRadius: '50%',
                                    background: '#E1F4F4',
                                    fontSize: 35,
                                    color: ' #065A4F',
                                    textAlign: 'center',
                                    lineHeight: '70px',
                                    margin: '20px 0'
                                }}
                            >
                                {altText}
                            </div>
                    }
                    <div>
                        <div className="h5 fw-bold">{shiftDetails?.roleType?.label}</div>
                        <div className="t2">{shiftDetails?.branchTitle}</div>
                    </div>
                </div>
                <div className="d-flex align-items-center" style={{ gap: 15 }}>
                    <img width={25} src={amountIcon} alt="avatar" />
                    <div>
                        <div className="p3">Total</div>
                        <div className="b1">£{shiftDetails?.total}</div>
                    </div>
                </div>
            </div>
            <div className="p3">{shiftDetails?.description}</div>
        </div>

    )
}