import React from "react";

const CancelIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M10.3333 1.66797C5.74167 1.66797 2 5.40964 2 10.0013C2 14.593 5.74167 18.3346 10.3333 18.3346C14.925 18.3346 18.6667 14.593 18.6667 10.0013C18.6667 5.40964 14.925 1.66797 10.3333 1.66797ZM13.1333 11.918C13.375 12.1596 13.375 12.5596 13.1333 12.8013C13.0083 12.9263 12.85 12.9846 12.6917 12.9846C12.5333 12.9846 12.375 12.9263 12.25 12.8013L10.3333 10.8846L8.41667 12.8013C8.29167 12.9263 8.13333 12.9846 7.975 12.9846C7.81667 12.9846 7.65833 12.9263 7.53333 12.8013C7.29167 12.5596 7.29167 12.1596 7.53333 11.918L9.45 10.0013L7.53333 8.08464C7.29167 7.84297 7.29167 7.44297 7.53333 7.2013C7.775 6.95964 8.175 6.95964 8.41667 7.2013L10.3333 9.11797L12.25 7.2013C12.4917 6.95964 12.8917 6.95964 13.1333 7.2013C13.375 7.44297 13.375 7.84297 13.1333 8.08464L11.2167 10.0013L13.1333 11.918Z"
        fill="#D32F2F"
      />
    </svg>
  );
};

export default CancelIcon;
