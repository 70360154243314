import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { Header } from "../../components/UI/header";
import { getLocumAttendanceJobsThunk } from "../../redux/locum-flow/Thunk";
import { StaffAbsences } from "./absences";
import { StaffContractDetails } from "./contracts";
import StaffDocument from "./document";
import { StaffInformation } from "./information";
import { StaffSchedule } from "./scheduler";
import { StaffSetting } from "./setting";
import { StaffGapsList } from "./shift-gaps";
import { StaffDetails } from "./staffView";
import { StaffTimeSheet } from "./time-sheet";
import { StaffVerification } from "./verifications";

export const StaffView = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLocumAttendanceJobsThunk());
  }, []);

  return (
    <div className="content-view-page details-page">
      <Header showNotification showAttendance />
      <Routes>
        <Route path="/*" element={<StaffDetails />}>
          <Route path="information" element={<StaffInformation />} />
          <Route path="shift-gaps" element={<StaffGapsList />} />
          <Route path="schedule" element={<StaffSchedule />} />
          <Route path="document" element={<StaffDocument />} />
          <Route path="contract" element={<StaffContractDetails />} />
          <Route path="time-sheet" element={<StaffTimeSheet />} />
          <Route path="leaves" element={<StaffAbsences />} />
          <Route path="verifications" element={<StaffVerification />} />
          <Route path="setting" element={<StaffSetting />} />
          <Route path="*" element={<Navigate to="/information" />} />
        </Route>
      </Routes>
    </div>
  );
};
