import { Col, DatePicker, Form, Input, Row, Select, Upload } from "antd";
import React from "react";
import {
  beforeUpload,
  compressImage,
  filterSpecialCharacterAndNumber,
  notify,
  replaceAlphabet,
} from "../../utils/helper";
import { phoneValidation } from "../../utils/validation.message";
import { LocationSearchInput } from "../UI/input/locationSearchInput";
import ClickToUpload from "../comon/ClickToUpload";

export const countryList = [
  {
    value: 1,
    label: "Switzerland",
    status: 1,
  },
  {
    value: 2,
    label: "Ukraine",
    status: 1,
  },
  {
    value: 3,
    label: "United States",
    status: 1,
  },
  {
    value: 4,
    label: "Canada",
    status: 1,
  },
  {
    value: 5,
    label: "Germany",
    status: 1,
  },
  {
    value: 6,
    label: "United Kingdom",
    status: 1,
  },
  {
    value: 7,
    label: "Sweden",
    status: 1,
  },
  {
    value: 8,
    label: "Australia",
    status: 1,
  },
  {
    value: 9,
    label: "France",
    status: 1,
  },
  {
    value: 10,
    label: "Norway",
    status: 1,
  },
];

const PersonalInfoAddEditModal = ({ handleAddressSelect, form, image }) => {
  const address1 = Form.useWatch("address1", form);

  return (
    <Row gutter={[16, 16]} className="info-modal-row">
      <Col span={24}>
        <Form.Item
          label="Profile Picture"
          name="image"
          rules={[
            {
              required: true,
              message: "Profile Picture is required",
            },
          ]}
        >
          <Upload
            accept={"image/*"}
            fileList={image?.fileList}
            showUploadList={{
              showPreviewIcon: false,
              showRemoveIcon: true,
            }}
            onPreview={() => {}}
            beforeUpload={beforeUpload}
            onChange={async ({ file, fileList }) => {
              try {
                const compressedFile = await compressImage(file);
                form.setFieldsValue({
                  image: { file: compressedFile, fileList },
                });
              } catch (error) {
                notify("error", "Error compressing the image:");
              }
            }}
            multiple={false}
            maxCount={1}
            listType="picture"
            name="image"
          >
            <ClickToUpload />
          </Upload>
        </Form.Item>
      </Col>

      <Col lg={12} xs={24}>
        <Form.Item
          label="First Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please Enter your First Name !",
              max: 50,
            },
          ]}
        >
          <Input
            // defaultValue="Adam Alexander"
            onChange={(e) =>
              form.setFieldsValue({
                name: filterSpecialCharacterAndNumber(e.target.value.trim()),
              })
            }
            className="text-input-field p-2 pe-3 t1"
          />
        </Form.Item>
      </Col>

      <Col lg={12} xs={24}>
        <Form.Item
          label="Last Name"
          name="surname"
          rules={[
            {
              required: true,
              message: "Please Enter your Last Name !",
              max: 50,
            },
          ]}
        >
          <Input
            onChange={(e) =>
              form.setFieldsValue({
                surname: filterSpecialCharacterAndNumber(e.target.value.trim()),
              })
            }
            className="text-input-field p-2 pe-3 t1"
          />
        </Form.Item>
      </Col>

      <Col lg={12} xs={24}>
        <Form.Item
          label="GPHC Number"
          name="gphcNumber"
          normalize={(value) => {
            if (value.length > 7) return value.slice(0, 7);
            return value;
          }}
        >
          <Input
            disabled
            type="number"
            placeholder="GPhC Number"
            className="text-input-field p-2 pe-3 t1"
          />
        </Form.Item>
      </Col>

      {/* DOB */}
      <Col lg={12} xs={24}>
        <Form.Item
          label="Date of Birth"
          name="dob"
          rules={[
            {
              required: true,
              type: "date",
              message: "Please select your Date of Birth!",
            },
          ]}
        >
          {/* <Input
              defaultValue="9 Nov 1999"
              className="text-input-field p-2 pe-3 t1"
            /> */}
          <DatePicker
            inputReadOnly
            format="DD-MM-YYYY"
            getPopupContainer={(trigger) => trigger.parentElement}
            className="text-input-field p-2 pe-3 t1"
            style={{ width: "100%" }}
            // disabledDate={(current) => {
            //   return dayjs().subtract(18, 'years') <= current;
            // }}
          />
        </Form.Item>
      </Col>

      <Col lg={12} xs={24}>
        <Form.Item label="Phone Number" name="phone" rules={phoneValidation}>
          <Input
            onChange={(e) =>
              form.setFieldsValue({
                phone: replaceAlphabet(e.target.value.trim()),
              })
            }
            type="text"
            placeholder="Phone Number"
            className="text-input-field p-2 pe-3 t1"
          />
        </Form.Item>
      </Col>

      <Col lg={12} xs={24}>
        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              type: "email",
              message: "Please Enter your email!",
            },
          ]}
          disabled
        >
          <Input
            // defaultValue="adam@gmail.com"
            placeholder="Enter your email"
            // onBlur={(e) =>
            //   form.setFieldsValue({ email: e.target.value.trim() })
            // }
            type="email"
            disabled
            maxLength={50}
            className="text-input-field p-2 pe-3 t1"
          />
        </Form.Item>
      </Col>

      <Col lg={12} xs={24}>
        <Form.Item
          label="Address Line 1"
          name="address1"
          rules={[{ required: true, message: "Please Enter Address!" }]}
        >
          <LocationSearchInput
            className="text-input-field p-2 pe-3 t1"
            name="address1"
            value={address1}
            onAdressChange={(val) =>
              form.setFieldsValue({
                address1: val,
              })
            }
            placeholder="Address Line 1"
            onHandleSelect={handleAddressSelect}
          />
        </Form.Item>
      </Col>

      <Col lg={12} xs={24}>
        <Form.Item label="Address Line 2" name="address2">
          <LocationSearchInput
            value={form?.address2}
            className="text-input-field p-2 pe-3 t1"
            name="address2"
            onHandleSelect={handleAddressSelect}
          />
        </Form.Item>
      </Col>

      <Col lg={12} xs={24}>
        <Form.Item
          label="Country"
          name="countryId"
          rules={[{ required: true, message: "Please Enter country!" }]}
        >
          <Select
            style={{ width: "100%" }}
            onChange={(e) => {
              form.setFieldsValue({ countryId: e });
            }}
            options={countryList}
            className="select-field"
            // suffixIcon={<DropdownIcon />}
          />
        </Form.Item>
      </Col>

      <Col lg={12} xs={24}>
        <Form.Item label="City" name="city">
          <Input
            onBlur={(e) => form.setFieldsValue({ city: e.target.value.trim() })}
            placeholder="Town/City"
            className="text-input-field p-2 pe-3 t1"
          />
        </Form.Item>
      </Col>

      <Col lg={12} xs={24}>
        <Form.Item
          label="Postal Code"
          rules={[{ required: true, message: "Please Enter Postal Code!" }]}
          style={{ width: "100%" }}
          name="zipCode"
        >
          <Input
            className="text-input-field p-2 pe-3 t1"
            max={20}
            onBlur={(e) =>
              form.setFieldsValue({ zipCode: e.target.value.trim() })
            }
            placeholder="Postal Code"
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default PersonalInfoAddEditModal;
