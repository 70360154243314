import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import editIcon from "../../../../../assets/icons/editIcon.png";
import closeCollaps from "../../../../../assets/icons/closeCollaps.png";
import openCollaps from "../../../../../assets/icons/openCollaps.png";
import addIcon from "../../../../../assets/img/addButton.png";
import infoIcon from "../../../../../assets/icons/infoIcon.png";
import checkOutImage from "../../../../../assets/icons/checkOutImage.png";

import { Button, Collapse, Form, Input, Modal } from "antd";
import {
  cancelBranchSubscriptionThunk,
  numarkActionThunk,
  paymentBranchesThunk,
  updateBranchSubscriptionThunk,
} from "../../../../../redux/profile/profileThunk";
import { TableComponet } from "../../../../../components/UI/table";
import { EmptyData } from "../../../../../components/comon/EmptyData";
import { ConfirmModal } from "../../../../../components/UI/modals/confirmModal";
import moment from "moment/moment";
import { groupBy, mapValues } from "lodash";
import { notify } from "../../../../../utils/helper";
import { AddModal } from "../../../../../components/UI/modals/addModal";
import { authUserRequestThunk } from "../../../../../redux/auth/authThunk";
import { Dropdown } from "semantic-ui-react";

const { Panel } = Collapse;
const generalBranchRate = 34.99;
const numarkBranchRate = 29.99;

export const Subscriptions = ({ setFlag, flag }) => {
  const dispatch = useDispatch();
  const { paymentBranches } = useSelector((state) => state.profile);
  const [selectedRows, setSelectedRows] = useState([]);
  const [numarkBranchId, setNumarkBranchId] = useState();
  const [form] = Form.useForm();
  const formId = useMemo(() => "numarkMemeber", []);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [branchIds, setBranchIds] = useState();

  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);
  const [showDeleteNumark, setShowDeleteNumark] = useState(false);
  const [showNumarkModal, setShowNumarkModal] = useState(false);
  const [showFAQsModal, setShowFAQsModal] = useState(false);
  const [showCheckOutModal, setShowCheckOutModal] = useState(false);
  const [zohoPaymentUrl, setZohoPaymentUrl] = useState('');

  const [calculatedData, setCalculatedData] = useState({
    general: 0.00,
    numark: 0.00,
    total: 0.00,
    generalBranches: 0,
    numarkBranches: 0,
  });

  const {
    count: totalDataCount,
    page,
    pages: totalPagesCount,
    perPage,
    rows: branchesData,
  } = useMemo(() => paymentBranches, [paymentBranches]);

  const getSubscriptions = useCallback(() => {
    dispatch(
      paymentBranchesThunk({
        pagination: {
          page: currentPageCount,
          perPage: perPageCount,
        },
      })
    );
  }, [currentPageCount, dispatch, perPageCount]);

  useEffect(() => {
    if (!page && !perPage) return;
    setPerPageCount(perPage);
    setCurrentPageCount(page);
  }, [page, perPage, setCurrentPageCount, setPerPageCount]);

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions, currentPageCount, perPageCount, flag]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Branch Name",
        accessor: "title",
      },
      {
        Header: "Previous Payment",
        accessor: "periodFrom",
        Cell: ({ row: { original } }) => {
          return original.periodFrom
            ? moment(original.periodFrom).format("DD-MM-YYYY")
            : "-";
        },
      },
      {
        Header: "Next Payment",
        accessor: "periodTo",
        Cell: ({ row: { original } }) => {
          return original.periodTo
            ? moment(original.periodTo).format("DD-MM-YYYY")
            : "-";
        },
      },
      {
        Header: "Payment",
        accessor: "payment",
        Cell: ({ row: { original } }) => {
          return original.amount ? `£${original.amount}` : "-";
        },
      },
      {
        Header: "Numark Member",
        accessor: "isNumark",
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.isNumark ? (
                <div className="d-flex gap-2 align-items-center">
                  <span>{original.numark}</span>
                  <img
                    width={25}
                    src={editIcon}
                    alt="editIcon"
                    onClick={() => {
                      form.setFieldsValue({
                        numark: original.numark,
                      });
                      setShowDeleteNumark(true);
                      setNumarkBranchId(original.id);
                      setShowNumarkModal(true);
                    }}
                  />
                </div>
              ) : (
                <img
                  width={25}
                  src={addIcon}
                  alt="addIcon"
                  onClick={() => {
                    setNumarkBranchId(original.id);
                    setShowNumarkModal(true);
                  }}
                />
              )}
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "active",
        Cell: ({ row: { original } }) => {
          return (
            <>
              {original.active ? (
                <span
                  className="px-4 rounded-pill py-2"
                  style={{
                    backgroundColor: "#E8F5E9",
                    whiteSpace: "nowrap",
                    color: "#1B5E1F",
                  }}
                >
                  Active
                </span>
              ) : (
                <span
                  className="px-4 rounded-pill py-2"
                  style={{
                    backgroundColor: "#FFEBEE",
                    whiteSpace: "nowrap",
                    color: "#EF5350",
                  }}
                >
                  In Active
                </span>
              )}
            </>
          );
        },
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {
                !original.active || selectedRows.length ? null :
                  <Dropdown icon="ellipsis vertical" pointing="top right">
                    <Dropdown.Menu direction="left">
                      <Dropdown.Item
                        text="Cancel Subscription"
                        onClick={() => {
                          setShowConfirmModal(true)
                          const id = [original.id];
                          setBranchIds(id);
                        }}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
              }
            </div>
          );
        },
      },
    ],
    [selectedRows.length]
  );

  const calculateAmount = (amount) => {
    const totalDays = moment().daysInMonth();
    const day = moment().date();
    const amountPerDay = amount / totalDays;
    const remainingDays = totalDays - (day - 1);
    return (remainingDays * amountPerDay).toFixed(2);
  };

  useEffect(() => {
    if (!selectedRows.length) {
      setCalculatedData({
        general: 0.0,
        numark: 0.0,
        total: 0.0,
        generalBranches: 0,
        numarkBranches: 0,
      });
      return;
    }
    const thirdArray = branchesData.filter((elem) => {
      return selectedRows.some((ele) => {
        return ele === elem.id && elem.active !== true && (!elem.periodTo || moment(elem.periodTo).isBefore(moment()));
      });
    });
    var grouped = mapValues(groupBy(thirdArray, "isNumark"));
    const numark = grouped[true]?.length
      ? grouped[true].length * calculateAmount(numarkBranchRate)
      : 0.0;
    const general = grouped[false]?.length
      ? grouped[false].length * calculateAmount(generalBranchRate)
      : 0.0;
    const total = (numark + general).toFixed(2);

    setCalculatedData({
      general: general.toFixed(2),
      numark: numark.toFixed(2),
      total,
      generalBranches: grouped[false]?.length || 0,
      numarkBranches: grouped[true]?.length || 0,
    });
  }, [branchesData, selectedRows]);

  const onPaySubscription = () => {
    if (!selectedRows.length) {
      notify("error", "Please Select A Branch Before Proceeding.");
      return;
    }
    const thirdArray = branchesData.filter((elem) => {
      return selectedRows.some((ele) => {
        return ele === elem.id && elem.active !== true;
      });
    });

    if (!thirdArray.length) {
      notify(
        "error",
        "The Branches You've Selected Have Already Been Paid For In This Billing Cycle."
      );
      return;
    }

    const updatedBranches = thirdArray.map((data) => ({
      paid: false,
      originalNumark: data.numark,
      updated: true,
      validNumark: data.numark ? true : false,
      inactiveDate: data.inactiveDate,
      id: data.id,
      title: data.title,
      regionName: data.regionName,
      active: true,
      numark: data.numark,
      isNumark: data.isNumark,
    }));

    dispatch(
      updateBranchSubscriptionThunk({
        data: updatedBranches,
      })
    ).then((res) => {
      if (res.payload.url) {
        setZohoPaymentUrl(res.payload.url)
        setShowCheckOutModal(true)
        setSelectedRows([]);
        getSubscriptions();
        window.analytics.track('Initiate Payment', {
          'generalBranches': calculatedData?.generalBranches,
          'numarkBranches': calculatedData?.numarkBranches,
          'amount': `£${calculatedData?.total}`,
        });
        window.fbq('trackCustom', 'Initiate Payment', {
          'generalBranches': calculatedData?.generalBranches,
          'numarkBranches': calculatedData?.numarkBranches,
          'amount': `£${calculatedData?.total}`,
        });
        return;
      }
      if (res.payload) {
        setSelectedRows([]);
        notify(
          "success",
          "Your Payment Being Processed. However, Changes May Not Reflect On The Table Below Right Away. Please Wait!"
        );
        dispatch(authUserRequestThunk());

        setFlag(true);
        getSubscriptions();
      }
    });
  };

  const onFinish = ({ numark }) => {
    dispatch(
      numarkActionThunk({
        id: numarkBranchId,
        data: {
          numark: numark,
          isNumark: numark ? true : false,
        },
      })
    ).then((res) => {
      if (res.payload) {
        form.resetFields();
        getSubscriptions();
        setShowDeleteNumark(false);
        setFlag(true);
        setShowNumarkModal(false);
        setNumarkBranchId(undefined);
      }
    });
  };

  const onCancelSubscription = () => {
    dispatch(
      cancelBranchSubscriptionThunk({
        branchIds
      })
    ).then(
      res => {
        if (res.payload.status === 'success') {
          getSubscriptions();
          setShowConfirmModal(false)
          setBranchIds([])
        }
      }
    )
  }

  return (
    <div
      className="d-flex flex-column flex-1"
      style={{ gap: 30, height: "100%" }}
    >
      {
        showConfirmModal &&
        <ConfirmModal
          title="We're Sad To See You Go 😔"
          description="Are You Sure You Want To Proceed With The Cancellation? Choosing To Do So Would Make You Unable To Utilise And Execute Actions Associated With This Particular Branch."
          open={showConfirmModal}
          confirmButtonText="Cancel My Subscription"
          cancelButtonText="No, I Don’t Wish To Cancel"
          onConfirm={onCancelSubscription}
          onClose={() => setShowConfirmModal(!showConfirmModal)}
        />}
      {
        showCheckOutModal &&
        <Modal
          centered
          open={showCheckOutModal}
          width={1000}
          onCancel={() => setShowCheckOutModal(false)}
          footer={<></>}
        >
          <div className="d-flex align-items-center gap-3 mt-4" style={{ width: '100%', height: '70vh' }}>
            <div style={{ width: '50%', textAlign: 'center' }}>
              <img src={checkOutImage} alt="checkOutImage" />
            </div>
            <iframe style={{ width: '50%', height: '100%' }} src={zohoPaymentUrl} title="dsds"></iframe>
          </div>
        </Modal >
      }
      {
        showFAQsModal && (
          <AddModal
            title="Payment FAQ’s"
            handleModal={showFAQsModal}
            setHandleModal={setShowFAQsModal}
            fixWidth={true}
          >
            <div
              className="p1"
              style={{ color: "#667085", marginBottom: "20px" }}
            >
              Everything you need to know about the product and billing.
            </div>
            <Collapse
              className="faqContainer"
              ghost
              expandIcon={({ isActive }) => {
                return (
                  <>
                    {isActive ? (
                      <img width={20} src={closeCollaps} alt="editIcon" />
                    ) : (
                      <img width={20} src={openCollaps} alt="editIcon" />
                    )}
                  </>
                );
              }}
              defaultActiveKey={["1"]}
            >
              <Panel
                // style={{ backgroundColor: 'red' }}
                header="Will I be billed for the whole month if I pay on a date other than the 1st?"
                key="1"
              >
                <p>
                  No, you won't. The Lopic billing engine calculates on a pro-rata
                  basis. What does that mean? That means you'll pay for what you
                  use always!
                </p>
              </Panel>
              <Panel
                header="What are the available subscription choices?"
                key="2"
              >
                <p>
                  You can have unlimited access to Lopic for 34.99. Yes, you heard
                  that right. A 'Numark' membership will get this down to 29.99 as
                  soon as you enter your verified 'Numark' membership ID.{" "}
                </p>
              </Panel>
              <Panel
                header="Will I be charged for branches I've already paid for if I bulk select? or select them again individually?"
                key="3"
              >
                <p>
                  You will not, you can only pay for a branch once it is in
                  'in-active' status. However, once you've entered your card
                  details the payment will be deducted automatically when the next
                  billing cycle is initiated on the 1st. This is in place for you
                  to have uninterrupted access; you can cancel your subscription
                  anytime.
                </p>
              </Panel>
              <Panel
                header="Why can't I see updated information in the table right away after having paid?"
                key="4"
              >
                <p>
                  To make sure your payments are secure and routed through all
                  channels that are free of money-hungry trolls, we follow a
                  sequential code execution protocol. This may cause a slight
                  delay when it comes to changes being reflected on your
                  'Subscriptions' page.
                </p>
              </Panel>
            </Collapse>
          </AddModal>
        )
      }
      {
        showNumarkModal && (
          <ConfirmModal
            title="Numark Membership"
            open={showNumarkModal}
            formId={formId}
            onClose={() => setShowNumarkModal(false)}
          >
            <Form
              onFinish={onFinish}
              form={form}
              id={formId}
              layout="vertical"
              autoComplete="off"
            >
              <Form.Item
                label="Numark"
                name="numark"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Numark Number!",
                  },
                  {
                    min: 7,
                    message: "Minimum length is 7!",
                  },
                ]}
                normalize={(value) => {
                  if (value.length > 7) return value.slice(0, 7);
                  return value;
                }}
              >
                <Input
                  type="number"
                  className="text-input-field p-2 t1"
                  placeholder="Numark"
                />
              </Form.Item>
            </Form>
            <div
              className="d-flex align-content-between align-items-end"
              style={{ gap: "15px", flex: "1" }}
            >
              <Button
                block
                size="large"
                onClick={() => {
                  if (showDeleteNumark) {
                    onFinish({ numark: null });
                    setNumarkBranchId(undefined);
                    return;
                  }
                  setShowNumarkModal(false);
                  setNumarkBranchId(undefined);
                  form.resetFields();
                }}
              >
                {showDeleteNumark ? "Remove" : "Cancel"}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                form={formId}
                block
                size="large"
              >
                {showDeleteNumark ? "Update" : "Add"}
              </Button>
            </div>
          </ConfirmModal>
        )
      }
      <div
        className="border rounded p-4 d-flex flex-column gap-3"
        style={{ backgroundColor: "#F7FAFC" }}
      >
        <div>
          <div className="b1 h4">
            Payments
            <img
              title="FAQs"
              onClick={() => setShowFAQsModal(true)}
              src={infoIcon}
              className="cursor-pointer ms-2"
              alt="info"
            />
          </div>
          <div className="p3">
            We're so glad to have you onboard, for continued access to Lopic,
            please select your branches and proceed by clicking the 'Pay'
            button. Branches you've already paid for will be excluded from your
            total charge automatically!{" "}
          </div>
        </div>
        <div
          style={{ width: "100%", backgroundColor: "#EDF2F7" }}
          className="d-flex rounded p-3 align-items-center gap-5 justify-content-between"
        >
          <div className="d-flex align-items-center gap-5">
            <div className="d-flex flex-column gap-2">
              <div className="b1">
                Lopic - General #{calculatedData.generalBranches}
              </div>
              <div>£{calculatedData.general}</div>
            </div>
            <div className="border" />
            <div className="d-flex flex-column gap-2">
              <div className="b1">Numark #{calculatedData.numarkBranches}</div>
              <div>£{calculatedData.numark}</div>
            </div>
            <div />
            <div className="d-flex flex-column gap-2">
              <div className="b1">
                Total #
                {calculatedData.generalBranches + calculatedData.numarkBranches}
              </div>
              <div className="b1">£{calculatedData.total}</div>
            </div>
          </div>
          <div>
            <Button
              className="btn-style"
              type="primary"
              onClick={onPaySubscription}
            >
              Pay £{calculatedData.total}
            </Button>
          </div>
        </div>
      </div>
      <div>
        {branchesData?.length > 0 ? (
          <TableComponet
            disableSelectionBy="active"
            currentPageCount={currentPageCount}
            perPageCount={perPageCount}
            totalDataCount={totalDataCount}
            setCurrentPage={setCurrentPageCount}
            setPerPageCount={setPerPageCount}
            showPagination
            maxPageCount={totalPagesCount}
            showRowSelection
            isBorderShown
            columns={columns}
            data={branchesData}
            rowCursor="pointer"
            setSelectedRows={setSelectedRows}
          />
        ) : (
          <EmptyData title="Branches" />
        )}
      </div>
    </div >
  );
};
