import React from 'react';

const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M5.5 1H4.5C2 1 1 2 1 4.5V7.5C1 10 2 11 4.5 11H7.5C10 11 11 10 11 7.5V6.5"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.01896 1.51061L4.07896 5.45061C3.92896 5.60061 3.77896 5.89561 3.74896 6.11061L3.53396 7.61561C3.45396 8.16061 3.83896 8.54061 4.38396 8.46561L5.88896 8.25061C6.09896 8.22061 6.39396 8.07061 6.54896 7.92061L10.489 3.98061C11.169 3.30061 11.489 2.51061 10.489 1.51061C9.48896 0.510615 8.69896 0.830615 8.01896 1.51061Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.45508 2.07422C7.79008 3.26922 8.72508 4.20422 9.92508 4.54422"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
