import React from "react";

import Uploadicon from "../../assets/icons/upload-cloud-green.svg";
import "./clicktoupload.scss";

const ClickToUpload = () => {
  return (
    <div className="dragndrop_clickToUpload upload_box">
      <figure className="icon_wrapper">
        <img src={Uploadicon} alt="" />
      </figure>

      <div>
        <label
          for="file_input"
          className="file_input_area"
        >
          <span style={{ paddingRight: "6px" }}>
            Click to upload
          </span>
          or drag and drop files
        </label>
        <p className="filetypes">
          PDF,SVG, PNG, JPG or JPEG{" "}
        </p>
      </div>
    </div>
  );
};

export default ClickToUpload;
