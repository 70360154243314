import { createSlice } from "@reduxjs/toolkit";
import {
  deleteAccommodationAndFoodRulesThunk,
  deleteMileageRulesThunk,
  deleteParkingRulesThunk,
  deleteTransportRulesThunk,
  editProfileDataThunk,
  getAccommodationAndFoodRulesThunk,
  getAdminRolesThunk,
  getAdminStaffThunk,
  getMileageRulesThunk,
  getParkingRulesThunk,
  getPharmacyFlowsThunk,
  getPharmacySettingsThunk,
  getProfileAccrediationsThunk,
  getProfileDataThunk,
  getSubscriptionsThunk,
  getTransportRulesThunk,
  paymentBranchesThunk,
  paymentInvoicesThunk,
  paymentSubscriptionPlanThunk,
  saveAccommodationAndFoodRulesThunk,
  saveMileageRulesThunk,
  saveParkingRulesThunk,
  saveProfileAccrediationsThunk,
  saveTransportRulesThunk,
  updatePharmacySettingsThunk,
} from "../profileThunk";

const initialState = {
  profileInfo: {},
  accrediationsList: {},
  pharmacySettings: {},
  mileageRules: [],
  parkingRules: [],
  transportRules: [],
  accommodationAndFoodRules: [],
  pharmacyFlows: [],
  adminRoles: [],
  adminStaffList: [],
  paymentInvoices: {},
  paymentBranches: {},
  paymentSubscriptionPlan: {},
  getSubscription: {},
};

const profileSlicer = createSlice({
  name: "profileReducer",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getProfileDataThunk.fulfilled, (state, action) => {
        state.profileInfo = action.payload;
      })
      .addCase(getAdminStaffThunk.fulfilled, (state, action) => {
        state.adminStaffList = action.payload;
      })
      .addCase(editProfileDataThunk.fulfilled, (state, action) => {
        state.profileInfo = action.payload;
      })
      .addCase(getAdminRolesThunk.fulfilled, (state, action) => {
        state.adminRoles = action.payload;
      })
      .addCase(getProfileAccrediationsThunk.fulfilled, (state, action) => {
        state.accrediationsList = action.payload;
      })
      .addCase(saveProfileAccrediationsThunk.fulfilled, (state, action) => {
        const index = state.accrediationsList.rows.findIndex(
          (list) => list.id === action.payload.id
        );
        if (index >= 0) {
          state.accrediationsList.rows[index] = action.payload;
          return;
        }
        state.accrediationsList.rows.unshift(action.payload);
      })
      .addCase(getPharmacySettingsThunk.fulfilled, (state, action) => {
        state.pharmacySettings = action.payload;
      })

      .addCase(updatePharmacySettingsThunk.fulfilled, (state, action) => {
        state.pharmacySettings = action.payload;
      })

      .addCase(getMileageRulesThunk.fulfilled, (state, action) => {
        state.mileageRules = action.payload;
      })

      .addCase(saveMileageRulesThunk.fulfilled, (state, action) => {
        const index = state.mileageRules.findIndex(
          (list) => list.id === action.payload.id
        );
        if (index >= 0) {
          state.mileageRules[index] = action.payload;
          return;
        }
        state.mileageRules.push(action.payload);
      })
      .addCase(deleteMileageRulesThunk.fulfilled, (state, action) => {
        const index = state.mileageRules.findIndex(
          (list) => list.id === Number(action.payload)
        );
        state.mileageRules.splice(index, 1);
      })
      .addCase(getParkingRulesThunk.fulfilled, (state, action) => {
        state.parkingRules = action.payload;
      })
      .addCase(saveParkingRulesThunk.fulfilled, (state, action) => {
        const index = state.parkingRules.findIndex(
          (list) => list.id === action.payload.id
        );
        if (index >= 0) {
          state.parkingRules[index] = action.payload;
          return;
        }
        state.parkingRules.push(action.payload);
      })
      .addCase(deleteParkingRulesThunk.fulfilled, (state, action) => {
        const index = state.parkingRules.findIndex(
          (list) => list.id === Number(action.payload)
        );
        state.parkingRules.splice(index, 1);
      })
      .addCase(getTransportRulesThunk.fulfilled, (state, action) => {
        state.transportRules = action.payload;
      })
      .addCase(saveTransportRulesThunk.fulfilled, (state, action) => {
        const index = state.transportRules.findIndex(
          (list) => list.id === action.payload.id
        );
        if (index >= 0) {
          state.transportRules[index] = action.payload;
          return;
        }
        state.transportRules.push(action.payload);
      })

      .addCase(deleteTransportRulesThunk.fulfilled, (state, action) => {
        const index = state.transportRules.findIndex(
          (list) => list.id === Number(action.payload)
        );
        state.transportRules.splice(index, 1);
      })

      .addCase(getAccommodationAndFoodRulesThunk.fulfilled, (state, action) => {
        state.accommodationAndFoodRules = action.payload;
      })
      .addCase(
        saveAccommodationAndFoodRulesThunk.fulfilled,
        (state, action) => {
          const index = state.accommodationAndFoodRules.findIndex(
            (list) => list.id === action.payload.id
          );
          if (index >= 0) {
            state.accommodationAndFoodRules[index] = action.payload;
            return;
          }
          state.accommodationAndFoodRules.push(action.payload);
        }
      )
      .addCase(
        deleteAccommodationAndFoodRulesThunk.fulfilled,
        (state, action) => {
          const index = state.accommodationAndFoodRules.findIndex(
            (list) => list.id === Number(action.payload)
          );
          state.accommodationAndFoodRules.splice(index, 1);
        }
      )
      .addCase(getPharmacyFlowsThunk.fulfilled, (state, action) => {
        state.pharmacyFlows = action.payload;
      })
      //billing
      .addCase(paymentInvoicesThunk.fulfilled, (state, action) => {
        state.paymentInvoices = action.payload;
      })
      .addCase(paymentBranchesThunk.fulfilled, (state, action) => {
        state.paymentBranches = action.payload;
      })
      .addCase(paymentSubscriptionPlanThunk.fulfilled, (state, action) => {
        state.paymentSubscriptionPlan = action.payload;
      })
      .addCase(getSubscriptionsThunk.fulfilled, (state, action) => {
        state.getSubscription = action.payload;
      }),
});

export const profileReducer = profileSlicer;
