import { productFruits } from "product-fruits";
import React, { useEffect } from "react";
import { ProductFruits } from "react-product-fruits";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import "semantic-ui-css/semantic.min.css";
import { GlobalLoader } from "./components/UI/spiner/globalLoader";
import { Authentication } from "./pages/auth";
import { Root } from "./pages/root";
import {
  authUserRequestThunk,
  getAccessibleApisThunk,
  getAccessibleModulesThunk,
} from "./redux/auth/authThunk";
import "./style.scss";

export const App = () => {
  const dispatch = useDispatch();
  const { isAuth, user } = useSelector(
    (state) => state.auth
  );

  productFruits.init("flHZfHc5W7EPTklX", "en", {
    username: `${user.name} ${user.surname}`,
  });
  const { isloading } = useSelector(
    (state) => state.loader
  );
  useEffect(() => {
    if (isAuth) dispatch(authUserRequestThunk());
  }, [dispatch, isAuth]);

  useEffect(() => {
    if (isAuth && user?.group === "pharmacy") {
      dispatch(
        getAccessibleModulesThunk(user?.id)
      );
      dispatch(
        getAccessibleApisThunk(user?.roleId)
      );
      return;
    }
  }, [dispatch, isAuth]);

  // const fixZIndexOnProductFruit = () => {
  //   const hostElements = document.getElementsByClassName(
  //     "productfruits--container"
  //   );
  //   // Iterate through each element with the specified class
  //   for (const hostElement of hostElements) {
  //     // Open the Shadow DOM
  //     const shadowRoot = hostElement.shadowRoot;
  //     if (shadowRoot) {
  //       // Access an element within the Shadow DOM
  //       const elementInsideShadowDOM = shadowRoot.querySelectorAll(
  //         ".productfruits--context-help-help-icon"
  //       );

  //       // Perform operations on the element
  //       if (elementInsideShadowDOM) {
  //         elementInsideShadowDOM.forEach((node) => {
  //           node?.classList.add("z-index-fix");
  //         });
  //       }
  //     }
  //   }
  // };

  // fixZIndexOnProductFruit();

  return isAuth ? (
    <>
      <GlobalLoader
        color="blue"
        loaderState={isloading}
      />
      <ProductFruits
        workspaceCode="flHZfHc5W7EPTklX"
        language="en"
        user={{
          username:
            user.name + " " + user.surname,
          ...user,
        }}
      />

      <Root />
    </>
  ) : (
    <>
      <GlobalLoader
        color="blue"
        loaderState={isloading}
      />
      <Authentication />
    </>
  );
};
