import { createSlice } from "@reduxjs/toolkit";
import { acceptJobRequestThunk, cancelJobThunk, declineJobInviteThunk, getJobCancelReasonsThunk, getLocumShiftInfoThunk, getLocumShiftsListThunk, getWorkedLocumThunk, inviteLocumForJobThunk, rejectJobRequestThunk, updateJobStatusThunk, updateJobThunk, updateMileageThunk } from "../locumShiftsThunk";

const initialState = {
    locumShifts: [],
    shiftInfo: {},
    workedLocumsList: [],
    cancelReasons: [],

}

const locumShiftsSlicer = createSlice({
    name: "locumShiftsSlicer",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getLocumShiftsListThunk.fulfilled, (state, action) => {
                state.locumShifts = action.payload
            })
            .addCase(getLocumShiftInfoThunk.fulfilled, (state, action) => {
                state.shiftInfo = action.payload
            })
            .addCase(getWorkedLocumThunk.fulfilled, (state, action) => {
                state.workedLocumsList = action.payload
            })
            .addCase(inviteLocumForJobThunk.fulfilled, (state, action) => {
                state.shiftInfo.staff = action.payload.staff
            })
            .addCase(declineJobInviteThunk.fulfilled, (state, action) => {
                state.shiftInfo.staff = action.payload.staff
            })
            .addCase(acceptJobRequestThunk.fulfilled, (state, action) => {
                state.shiftInfo.staff = action.payload.staff
            })
            .addCase(rejectJobRequestThunk.fulfilled, (state, action) => {
                state.shiftInfo.staff = action.payload.staff
            })
            .addCase(cancelJobThunk.fulfilled, (state, action) => {
                state.shiftInfo.status = action.payload.status
            })
            .addCase(updateJobThunk.fulfilled, (state, action) => {
                state.shiftInfo = action.payload
            })
            .addCase(updateMileageThunk.fulfilled, (state, action) => {
                state.shiftInfo = action.payload
            })
            .addCase(getJobCancelReasonsThunk.fulfilled, (state, action) => {
                state.cancelReasons = action.payload
            })
            .addCase(updateJobStatusThunk.fulfilled, (state, action) => {
                state.shiftInfo.status = action.payload.status
            })
    }
})

export const locumShiftsReducer = locumShiftsSlicer