import { Col, DatePicker, Form, Input, Row, Steps, Upload } from "antd";
import React, { useEffect, useState } from "react";
import Contact from "../../../assets/icons/ContactBig.svg";
import EditIcon from "../../UI/icons/EditIcon";
import CommonModal from "../../UI/modals/CommonModal/CommonModal";

import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { beforeUpload, compressImage, notify } from "../../../utils/helper";
import { LocationSearchInput } from "../../UI/input/locationSearchInput";
import ClickToUpload from "../../comon/ClickToUpload";
import "../style.scss";

const CompleteProfile = ({
  openModal,
  handleOpenModal,
  staffInfo,
  onSubmit,
  setActionType,
  actionType,
  formId,
  areSteps,
  isGrouped,
}) => {
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const { staffVerification } = useSelector((state) => state.staffs);

  const image = Form.useWatch("image", form);
  const address = Form.useWatch("address", form);
  const address1 = Form.useWatch("address1", form);
  const address2 = Form.useWatch("address2", form);

  const DBSCertificate = Form.useWatch("DBSCertificate", form);
  const identification = Form.useWatch("identification", form);
  const visa = Form.useWatch("visa", form);
  const professionalInsurance = Form.useWatch("professionalInsurance", form);
  const insuranceNumberDoc = Form.useWatch("insuranceNumberDoc", form);
  const NVQLevelTwo = Form.useWatch("NVQLevelTwo", form);
  const visaExpDate = Form.useWatch("visaExpDate", form);
  const gphcNumber = Form.useWatch("gphcNumber", form);

  //   const onChange = (value) => {
  //     console.log("onChange:", value);
  //     setCurrent(value);
  //   };
  const setcurrentstep = (index) => {
    setCurrent(index);
  };
  const GotoNext = () => {
    form.validateFields().then((values) => {
      console.log(values, "image");
      setCurrent(current + 1);
    });
  };
  const GotoPrev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    if (isEmpty(staffVerification) && !openModal) return;
    setCurrent(0);
    const _info = {
      address: staffVerification?.address,
      address1: staffVerification?.address1 ?? "",
      address2: staffVerification?.address2 ?? "",
      city: staffVerification?.city ?? "",
      DBSAnnualUpdate: staffVerification?.DBSAnnualUpdate ?? "",
      gphcNumber: staffVerification?.gphcInfo?.gphcNumber ?? "",
      id: staffVerification?.id,
      insuranceNumber: staffVerification?.insuranceNumber ?? "",
      latitude: staffVerification?.latitude,
      longitude: staffVerification?.longitude,
      name: staffVerification?.name,
      pharmacyName: staffVerification?.pharmacyName ?? "",
      phone: staffVerification?.phone ?? "",
      professionalInsuranceExpDate:
        staffVerification?.professionalInsuranceExpDate
          ? dayjs(staffVerification?.professionalInsuranceExpDate)
          : null,
      surname: staffVerification?.surname ?? "",
      visaExpDate: staffVerification?.visaExpDate
        ? dayjs(staffVerification?.visaExpDate)
        : null,
      zipCode: staffVerification?.zipCode ?? "",
      image: staffVerification?.image
        ? {
            fileList: [
              {
                uid: Math.random(),
                name: "image.png",
                status: "done",
                url: staffVerification?.image,
              },
            ],
          }
        : undefined,
      professionalInsurance: staffVerification?.professionalInsurance
        ? {
            fileList: [
              {
                uid: Math.random(),
                name: `${
                  staffVerification?.name
                }.${staffVerification?.professionalInsurance
                  ?.split(".")
                  .pop()}`,
                status: "done",
                url: staffVerification?.professionalInsurance,
              },
            ],
          }
        : undefined,
      DBSCertificate: staffVerification?.DBSCertificate
        ? {
            fileList: [
              {
                uid: Math.random(),
                name: "image.png",
                status: "done",
                url: staffVerification?.DBSCertificate,
              },
            ],
          }
        : undefined,
      identification: staffVerification?.proofOfId
        ? {
            fileList: [
              {
                uid: Math.random(),
                name: "image.png",
                status: "done",
                url: staffVerification?.proofOfId,
              },
            ],
          }
        : undefined,
      visa: staffVerification?.visa
        ? {
            fileList: [
              {
                uid: Math.random(),
                name: "image.png",
                status: "done",
                url: staffVerification?.visa,
              },
            ],
          }
        : undefined,
      insuranceNumberDoc: staffVerification?.insuranceNumberDoc
        ? {
            fileList: [
              {
                uid: Math.random(),
                name: "image.png",
                status: "done",
                url: staffVerification?.insuranceNumberDoc,
              },
            ],
          }
        : undefined,
      rightToWork: staffVerification?.rightToWork
        ? {
            fileList: [
              {
                uid: Math.random(),
                name: "image.png",
                status: "done",
                url: staffVerification?.rightToWork,
              },
            ],
          }
        : undefined,
      NVQLevelTwo: staffVerification?.NVQLevelTwo
        ? {
            fileList: [
              {
                uid: Math.random(),
                name: "image.png",
                status: "done",
                url: staffVerification?.NVQLevelTwo,
              },
            ],
          }
        : undefined,
      identificationExpDate: staffVerification?.proofOfIdExp
        ? dayjs(staffVerification?.proofOfIdExp)
        : null,
    };

    form.setFieldsValue(_info);
  }, [JSON.stringify(staffVerification), openModal]);

  const handleAddressSelect = async ({ name, data: address }) => {
    form.setFieldsValue({
      ...form,
      address1: address.line_1,
      address2: address.line_2 ?? "",
      latitude: address.latitude,
      longitude: address.longitude,
      city: address.town_or_city,
      postalCode: address.postcode,
    });
  };

  const finalSubmit = () => {
    form.validateFields().then((values) => {
      _onSubmit(values);
    });
  };

  const _onSubmit = (values) => {
    try {
      const reqBody = {
        ...values,
        address,
        address1,
        address2,
        id: staffVerification.id,
        image:
          !image || image?.file?.status === "removed"
            ? ""
            : image.file
            ? image.file
            : image?.fileList[0].url,
        insuranceNumberDoc:
          !values?.insuranceNumberDoc ||
          values?.insuranceNumberDoc?.file?.status === "removed"
            ? ""
            : values?.insuranceNumberDoc.file
            ? values?.insuranceNumberDoc.file
            : values?.insuranceNumberDoc?.fileList[0].url,
        DBSCertificate:
          !values?.DBSCertificate ||
          values?.DBSCertificate?.file?.status === "removed"
            ? ""
            : values?.DBSCertificate?.file
            ? values?.DBSCertificate?.file
            : values?.DBSCertificate?.fileList[0].url,
        rightToWork:
          !values?.rightToWork ||
          values?.rightToWork?.file?.status === "removed"
            ? ""
            : values?.rightToWork?.file
            ? values?.rightToWork?.file
            : values?.rightToWork?.fileList[0].url,
        identification:
          !values?.identification ||
          values?.identification?.file?.status === "removed"
            ? ""
            : values?.identification?.file
            ? values?.identification?.file
            : values?.identification?.fileList[0].url,
        professionalInsurance:
          !values?.professionalInsurance ||
          values?.professionalInsurance?.file?.status === "removed"
            ? ""
            : values?.professionalInsurance?.file
            ? values?.professionalInsurance?.file
            : values?.professionalInsurance?.fileList[0].url,
        visa:
          !values?.visa || values?.visa?.file?.status === "removed"
            ? ""
            : values?.visa?.file
            ? values?.visa?.file
            : values?.visa?.fileList[0].url,
        NVQLevelTwo:
          !values?.NVQLevelTwo ||
          values?.NVQLevelTwo?.file?.status === "removed"
            ? ""
            : values?.NVQLevelTwo?.file
            ? values?.NVQLevelTwo?.file
            : values?.NVQLevelTwo?.fileList[0].url,
        visaExpDate: values.visaExpDate
          ? dayjs(values.visaExpDate).format("YYYY-MM-DD")
          : "",
        identificationExpDate: values.identificationExpDate
          ? dayjs(values.identificationExpDate).format("YYYY-MM-DD")
          : "",
        professionalInsuranceExpDate: values.professionalInsuranceExpDate
          ? dayjs(values.professionalInsuranceExpDate).format("YYYY-MM-DD")
          : "",
        gphcNumber: staffVerification?.gphcInfo?.gphcNumber ?? "",
      };

      // if ("gphcNumber" in reqBody) {
      //   delete reqBody.gphcNumber;
      // }

      let formData = new FormData();

      for (let key in reqBody) {
        if (reqBody[key] instanceof Blob) {
          formData.append(key, reqBody[key], reqBody[key]?.name);
        } else {
          formData.append(key, reqBody[key]);
        }
      }

      onSubmit(formData);
    } catch (error) {
      console.log(error, "test");
    }

    // if (areSteps) {
    //   if (actionType === "other") {
    //     setActionType("basic");
    //     return;
    //   }
    //   if (actionType === "basic") {
    //     setActionType("docs");
    //     return;
    //   }
    // }

    // if (!!values) return;
  };

  return (
    <div className="complete_profile_modal">
      <CommonModal
        title="Profile Completion"
        handleModal={openModal}
        setHandleModal={handleOpenModal}
        primaryButtonText={current === 0 ? "Next" : "Finish"}
        primaryButtonHandler={current === 0 ? GotoNext : finalSubmit}
        secondaryButtonHandler={current === 1 && GotoPrev}
        secondaryButtonText={current === 0 ? "Cancel" : "Back"}
      >
        <div className="ant-step-wrap">
          <Steps
            current={current}
            // onChange={onChange}
            items={[
              {
                title: "Personal Info",
                icon: <>01</>,
                onClick: () => {
                  setCurrent(0);
                },
              },
              {
                title: "Documents",
                icon: <>02</>,
                onClick: () => {
                  GotoNext();
                },
              },
            ]}
          />
        </div>
        <Form
          form={form}
          //   name="validateOnly"
          layout="vertical"
          autoComplete="off"
          className="steptwoform"
          onFinish={_onSubmit}
        >
          <Form.Item label="First Name" name="name" hidden>
            <Input
              defaultValue={undefined}
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>
          <Form.Item label="Last name" name="surname" hidden>
            <Input // hidden={
              //   isGrouped
              //     ? false
              //     : actionType === "other" && !areSteps
              //     ? false
              //     : true
              // }
              defaultValue={undefined}
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>
          <Form.Item label="Last name" name="longitude" hidden>
            <Input
              defaultValue={undefined}
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>
          <Form.Item label="Last name" name="latitude" hidden>
            <Input
              defaultValue={undefined}
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>

          {/* {current === 0 ? ( */}
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Profile Picture"
                name="image"
                hidden={current === 1}
                rules={[
                  {
                    required: isGrouped && current === 0 ? true : false,
                    message: "Profile Picture is required",
                  },
                ]}
              >
                <Upload
                  accept={"image/*"}
                  fileList={image?.fileList}
                  showUploadList={{
                    showPreviewIcon: false,
                    showRemoveIcon: true,
                  }}
                  onPreview={() => {}}
                  beforeUpload={beforeUpload}
                  onChange={async ({ file, fileList }) => {
                    try {
                      const compressedFile = await compressImage(file);
                      form.setFieldsValue({
                        image: { file: compressedFile, fileList },
                      });
                    } catch (error) {
                      notify("error", "Error compressing the image:");
                    }
                  }}
                  multiple={false}
                  maxCount={1}
                  listType="picture"
                  name="image"
                >
                  {(image?.file?.status === "removed" || !image) && (
                    <figure className="contact_card">
                      <img src={Contact} alt="contact" />
                      <i className="edit_icon">
                        <EditIcon />
                      </i>
                    </figure>
                  )}
                </Upload>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Phone Number"
                name="phone"
                // hidden={
                //   isGrouped ? false : actionType === "basic" ? false : true
                // }
                rules={[
                  {
                    required:
                      (isGrouped || actionType === "basic") && current === 0
                        ? true
                        : false,
                    message: "Phone No is Required",
                  },
                ]}
                hidden={current === 1}
              >
                <Input
                  type="number"
                  placeholder="Phone"
                  defaultValue={undefined}
                  className="text-input-field p-2 pe-3 t1"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Home Address Line 1"
                // hidden={
                //   isGrouped ? false : actionType === "basic" ? false : true
                // }
                hidden={current === 1}
                name="address1"
                rules={[
                  {
                    required:
                      (isGrouped || actionType === "basic") && current === 0
                        ? true
                        : false,
                    message: "Address Line 1 No is Required",
                  },
                ]}
              >
                <LocationSearchInput
                  className="text-input-field p-2 pe-3 t1"
                  name="address1"
                  value={form?.address1}
                  onHandleSelect={handleAddressSelect}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Home Address Line 2"
                defaultValue=""
                name="address2"
                // hidden={
                //   isGrouped ? false : actionType === "basic" ? false : true
                // }
                hidden={current === 1}
              >
                <LocationSearchInput
                  value={form?.address2}
                  className="text-input-field p-2 pe-3 t1"
                  name="address2"
                  onHandleSelect={handleAddressSelect}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="GPHC"
                hidden={current === 1}
                // hidden={
                //   isGrouped
                //     ? false
                //     : actionType === "other" && !areSteps
                //     ? false
                //     : true
                // }
                name="gphcNumber"
                normalize={(value) => {
                  if (value.length > 7) return value.slice(0, 7);
                  return value;
                }}
                rules={[
                  {
                    len: 7,
                    message: "GPhC Number should be 7 character long.",
                  },
                ]}
              >
                <Input
                  disabled
                  type="number"
                  defaultValue={undefined}
                  placeholder="GPhC Number"
                  className="text-input-field p-2 pe-3 t1"
                />
              </Form.Item>
            </Col>
          </Row>
          {/* ) : null} */}

          {current === 1 ? (
            <>
              {/* Proof of ID (Passport or License) */}
              <Form.Item
                name="identificationExpDate"
                label="Proof of ID (Passport or License)"
                rules={[
                  {
                    required:
                      (isGrouped || actionType === "docs") && current === 1
                        ? true
                        : false,
                    message: "Please Select Proof of Id Expire Date",
                  },
                ]}
              >
                <DatePicker
                  inputReadOnly
                  format="DD-MM-YYYY"
                  placeholder="Expiry Date"
                  getPopupContainer={(trigger) => trigger.parentElement}
                  className="text-input-field p-2 pe-3 t1"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                hidden={
                  isGrouped ? false : actionType === "docs" ? false : true
                }
                label="Proof Of ID (Passport or Driving License)"
                name="identification"
                rules={[
                  {
                    required: isGrouped || actionType === "docs" ? true : false,
                    message: "Proof of ID is required",
                  },
                ]}
              >
                <Upload
                  accept={"image/*, .doc, .pdf"}
                  fileList={identification?.fileList}
                  onPreview={() => {}}
                  beforeUpload={beforeUpload}
                  onChange={async ({ file, fileList }) => {
                    try {
                      const compressedFile = await compressImage(file);
                      form.setFieldsValue({
                        identification: { file: compressedFile, fileList },
                      });
                    } catch (error) {
                      notify("error", "Error compressing the image:");
                    }
                  }}
                  multiple={false}
                  maxCount={1}
                  listType="picture"
                >
                  {(identification?.file?.status === "removed" ||
                    !identification) && <ClickToUpload />}
                </Upload>
              </Form.Item>
              {/* WORK VISA */}
              <Form.Item
                style={{ width: "100%" }}
                hidden={
                  isGrouped ? false : actionType === "docs" ? false : true
                }
                label="Work Visa Expiry Date"
                rules={[
                  {
                    required:
                      (isGrouped || actionType === "docs") &&
                      !(visa?.file?.status === "removed" || !visa)
                        ? true
                        : false,
                    message: "Work Visa Expiry Date is required",
                  },
                ]}
                name="visaExpDate"
              >
                <DatePicker
                  inputReadOnly
                  format="DD-MM-YYYY"
                  getPopupContainer={(trigger) => trigger.parentElement}
                  className="text-input-field p-2 pe-3 t1"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                hidden={
                  isGrouped ? false : actionType === "docs" ? false : true
                }
                label="Work Visa (For Non UK-Citizens)"
                rules={[
                  {
                    required:
                      (isGrouped || actionType === "docs") && visaExpDate
                        ? true
                        : false,
                    message: "Work Visa is required",
                  },
                ]}
                name="visa"
              >
                <Upload
                  accept={"image/*, .doc, .pdf"}
                  fileList={visa?.fileList}
                  onPreview={() => {}}
                  beforeUpload={beforeUpload}
                  onChange={async ({ file, fileList }) => {
                    try {
                      const compressedFile = await compressImage(file);
                      form.setFieldsValue({
                        visa: { file: compressedFile, fileList },
                      });
                    } catch (error) {
                      notify("error", "Error compressing the image:");
                    }
                  }}
                  multiple={false}
                  maxCount={1}
                  listType="picture"
                >
                  {(visa?.file?.status === "removed" || !visa) && (
                    <ClickToUpload />
                  )}
                </Upload>
              </Form.Item>
              {/* Indemnity Insurance */}
              <Form.Item
                style={{ width: "100%" }}
                // hidden={
                //   staffInfo?.role === "locum"

                // }
                label="Indemnity Insurance Expiry Date"
                name="professionalInsuranceExpDate"
                rules={[
                  {
                    required:
                      (isGrouped || actionType === "docs") &&
                      staffInfo?.role === "locum"
                        ? true
                        : false,
                    message: "Please Select Insurance Expire Date",
                  },
                ]}
              >
                <DatePicker
                  inputReadOnly
                  format="DD-MM-YYYY"
                  getPopupContainer={(trigger) => trigger.parentElement}
                  className="text-input-field p-2 pe-3 t1"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                // hidden={
                //   staffInfo?.role === "locum"
                //     ? isGrouped
                //       ? false
                //       : actionType === "docs"
                //       ? false
                //       : true
                //     : true
                // }
                label="Indemnity Insurance"
                name="professionalInsurance"
                // for 4 & 5 - role
                rules={[
                  {
                    required:
                      (isGrouped || actionType === "docs") &&
                      staffInfo?.role === "locum"
                        ? true
                        : false,
                    message: "Indemnity is required",
                  },
                ]}
              >
                <Upload
                  accept={"image/*, .doc, .pdf"}
                  fileList={professionalInsurance?.fileList}
                  onPreview={() => {}}
                  beforeUpload={beforeUpload}
                  onChange={async ({ file, fileList }) => {
                    try {
                      const compressedFile = await compressImage(file);
                      form.setFieldsValue({
                        professionalInsurance: {
                          file: compressedFile,
                          fileList,
                        },
                      });
                    } catch (error) {
                      notify("error", "Error compressing the image:");
                    }
                  }}
                  multiple={false}
                  maxCount={1}
                  listType="picture"
                >
                  {(professionalInsurance?.file?.status === "removed" ||
                    !professionalInsurance) && <ClickToUpload />}
                </Upload>
              </Form.Item>

              {/* Enhanced DBS Certificate */}
              <Form.Item
                label="Enhanced DBS Certificate"
                hidden={
                  staffInfo?.roleId === 4 || staffInfo?.roleId === 6
                    ? isGrouped
                      ? false
                      : actionType === "docs"
                      ? false
                      : true
                    : true
                }
                required={
                  (staffInfo?.roleId === 4 || staffInfo?.roleId === 6) &&
                  isGrouped
                }
                name="DBSCertificate"
              >
                <Upload
                  accept={"image/*, .doc, .pdf"}
                  fileList={DBSCertificate?.fileList}
                  onPreview={() => {}}
                  beforeUpload={beforeUpload}
                  onChange={async ({ file, fileList }) => {
                    try {
                      const compressedFile = await compressImage(file);
                      form.setFieldsValue({
                        DBSCertificate: { file: compressedFile, fileList },
                      });
                    } catch (error) {
                      notify("error", "Error compressing the image:");
                    }
                  }}
                  multiple={false}
                  maxCount={1}
                  listType="picture"
                >
                  {(DBSCertificate?.file?.status === "removed" ||
                    !DBSCertificate) && <ClickToUpload />}
                </Upload>
              </Form.Item>

              {/* National Insurance Number */}
              <Form.Item
                label="National Insurance Number"
                // hidden={
                //   staffInfo?.roleId === 4 || staffInfo?.roleId === 6
                //     ? isGrouped
                //       ? false
                //       : actionType === "docs"
                //       ? false
                //       : true
                //     : true
                // }
                // required={
                //   (staffInfo?.roleId === 4 || staffInfo?.roleId === 6) &&
                //   isGrouped
                // }
                name="insuranceNumber"
              >
                <Input
                  type="text"
                  defaultValue={undefined}
                  placeholder="Insurance Number"
                  className="text-input-field p-2 pe-3 t1"
                />
              </Form.Item>
              <Form.Item
                hidden={
                  isGrouped ? false : actionType === "docs" ? false : true
                }
                label="National Insurance"
                name="insuranceNumberDoc"
                // rules={[
                //   {
                //     required: isGrouped || actionType === "docs" ? true : false,
                //     message: "National Insurance is required",
                //   },
                // ]}
              >
                <Upload
                  accept={"image/*, .doc, .pdf"}
                  fileList={insuranceNumberDoc?.fileList}
                  onPreview={() => {}}
                  beforeUpload={beforeUpload}
                  onChange={async ({ file, fileList }) => {
                    try {
                      const compressedFile = await compressImage(file);
                      form.setFieldsValue({
                        insuranceNumberDoc: { file: compressedFile, fileList },
                      });
                    } catch (error) {
                      notify("error", "Error compressing the image:");
                    }
                  }}
                  multiple={false}
                  maxCount={1}
                  listType="picture"
                >
                  {(insuranceNumberDoc?.file?.status === "removed" ||
                    !insuranceNumberDoc) && <ClickToUpload />}
                </Upload>
              </Form.Item>

              {/* DBS Annual Update Service Number */}
              <Form.Item
                label="DBS Annual Update Service Number"
                // hidden={
                //   staffInfo?.roleId === 4 || staffInfo?.roleId === 6
                //     ? isGrouped
                //       ? false
                //       : actionType === "docs"
                //       ? false
                //       : true
                //     : true
                // }
                // required={
                //   (staffInfo?.roleId === 4 || staffInfo?.roleId === 6) &&
                //   isGrouped
                // }
                name="DBSAnnualUpdate"
              >
                <Input
                  type="text"
                  defaultValue={undefined}
                  placeholder="DBS Annual Update Number"
                  className="text-input-field p-2 pe-3 t1"
                />
              </Form.Item>

              {/* NVQ Level 2 */}
              <Form.Item
                label="NVQ Level 2"
                hidden={
                  staffInfo?.roleId === 8
                    ? isGrouped
                      ? false
                      : actionType === "docs"
                      ? false
                      : true
                    : true
                }
                name="NVQLevelTwo"
              >
                <Upload
                  accept={"image/*, .doc, .pdf"}
                  fileList={NVQLevelTwo?.fileList}
                  onPreview={() => {}}
                  beforeUpload={beforeUpload}
                  onChange={async ({ file, fileList }) => {
                    try {
                      const compressedFile = await compressImage(file);
                      form.setFieldsValue({
                        NVQLevelTwo: { file: compressedFile, fileList },
                      });
                    } catch (error) {
                      notify("error", "Error compressing the image:");
                    }
                  }}
                  multiple={false}
                  maxCount={1}
                  listType="picture"
                >
                  {(NVQLevelTwo?.file?.status === "removed" ||
                    !NVQLevelTwo) && <ClickToUpload />}
                </Upload>
              </Form.Item>
            </>
          ) : null}
        </Form>
      </CommonModal>
    </div>
  );
};

export default CompleteProfile;
