import moment from "moment"
import { useSelector } from "react-redux"
import { FlexRow } from "../../../../../../components/comon/flexRows"

export const InformationTab = () => {
    const { userData } = useSelector((state) => state.superAdmin)
    return (
        <div className="d-flex flex-column flex-1" style={{ gap: 30 }}>
            <div className="border rounded">
                <div className="s1 text-row m-0">Bio Details</div>
                <FlexRow
                    primaryTitle="First Name"
                    primaryDescription={userData?.personal?.name ? userData?.personal?.name : "-"}
                />
                <FlexRow
                    primaryTitle="Last Name"
                    primaryDescription={userData?.personal?.surname || "-"}
                />
                <FlexRow
                    primaryTitle="Gender"
                    primaryDescription={userData?.personal?.gender || "-"}
                />
                <FlexRow
                    primaryTitle="Date of Birth"
                    primaryDescription={userData?.personal?.dob ? moment(userData?.personal?.dob).format('DD-MM-YYYY') : '-'}
                />

            </div>
            <div className="border rounded">
                <div className="s1 text-row m-0">Contact Details</div>
                <FlexRow
                    primaryTitle="Email Address"
                    primaryDescription={userData?.personal?.email ? userData?.personal?.email : "-"}
                />

            </div>
        </div>
    )
}