import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { Heading } from "../../../../components/UI/heading";
import { TableComponet } from "../../../../components/UI/table";
import { TableToolbar } from "../../../../components/UI/table/tableToolBar";

import { RouterConfig } from "../../../../routerConfig";

import { UserFilterComponent } from "../filter";
import { getUserListThunk } from "../../../../redux/super-admin/superAdminThunk";
import * as moment from "moment";
import { EmptyData } from "../../../../components/comon/EmptyData";

export const UsersListPage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");

  const { usersList } = useSelector((state) => state.superAdmin);

  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);
  const [filterValue, setFilterValue] = useState();
  const [renderer, setRerender] = useState(false)

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "First Name",
        accessor: "name",
      },
      {
        Header: "Last Name",
        accessor: "surname",
      },
      {
        Header: "Label",
        accessor: "label",
        // Cell: ({ row: { original } }) => {
        //   return (
        //     <div>
        //       {original.role.charAt(0).toUpperCase() +
        //         original.role.slice(1, original.role.length)}
        //     </div>
        //   );
        // },
      },
      {
        Header: "Creation Date",
        accessor: "createdAt",
        Cell: ({ row: { original } }) => {
          return <div>{moment(original.createdAt).format("DD-MM-YYYY")}</div>;
        },
      },
      {
        Header: "Pharmacy",
        accessor: "pharmacy" || "pharmacyName",
      },
      {
        Header: "Status",
        accessor: "active",
        Cell: ({ row: { original } }) => {
          return (
            <>
              {original.status === "isArchived" ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#FEFDE9", color: "#EE872D" }}
                  >
                    {original.status.charAt(0).toUpperCase() +
                      original.status.slice(1, original.status.length)}{" "}
                  </span>
                </div>
              ) : original.status === "active" ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#E8F5E9", color: "#1B5E1F" }}
                  >
                    {original.status.charAt(0).toUpperCase() +
                      original.status.slice(1, original.status.length)}{" "}
                  </span>
                </div>
              ) : original.status === "unfilled" ||
                original.status === "pending" ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#FEFDE9", color: "#EE872D" }}
                  >
                    {" "}
                    {original.status.charAt(0).toUpperCase() +
                      original.status.slice(1, original.status.length)}{" "}
                  </span>
                </div>
              ) : original.status === "completed" ? (
                <span
                  className="px-4 rounded-pill py-2"
                  style={{ backgroundColor: "#E3F2FD", color: "#1045A1" }}
                >
                  {original.status.charAt(0).toUpperCase() +
                    original.status.slice(1, original.status.length)}{" "}
                </span>
              ) : (
                <span
                  className="px-4 rounded-pill py-2"
                  style={{ backgroundColor: "#FFEBEE", color: "#EF5350" }}
                >
                  {original.status
                    ? original.status.charAt(0).toUpperCase() +
                    original.status.slice(1, original.status.length)
                    : "no status"}
                </span>
              )}
            </>
          );
        },
      },
      {
        Header: "KYC",
        accessor: "isVerified",
        Cell: ({ row: { original } }) => {
          return (
            <>
              {!original.isVerified ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#FFEBEE", color: "#EF5350" }}
                  >
                    Not Verified
                  </span>
                </div>
              ) : (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#E8F5E9", color: "#1B5E1F" }}
                  >
                    Verified
                  </span>
                </div>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  const {
    count: totalDataCount,
    page,
    pages: totalPagesCount,
    perPage,
    rows: usersListData,
  } = useMemo(() => usersList, [usersList]);

  useEffect(() => {
    if (!page && !perPage) return;
    setPerPageCount(perPage);
    setCurrentPageCount(page);
  }, [page, perPage]);

  useEffect(
    () => {
      if (!renderer) return
      dispatch(
        getUserListThunk({
          filters: {
            name: searchQuery.trim(),
            ...filterValue,
          },
          pagination: { page: currentPageCount, perPage: perPageCount },
        })
      ).then(res => {
        if (res.payload) {
          setRerender(false)
        }
      })

    },
    [currentPageCount, dispatch, renderer, perPageCount, searchQuery, filterValue]
  );

  useEffect(() => {
    setRerender(true)
  }, [currentPageCount, perPageCount]);

  useEffect(() => {
    if (searchQuery !== "") return;
    setRerender(true)
  }, [searchQuery]);

  const formatTrProps = ({ original }) => {
    return {
      onClick: () =>
        navigate(
          generatePath(RouterConfig.superAdminUserInformation, {
            staffId: original.id,
          })
        ),
    };
  };

  const onSearch = (e) => {
    setRerender(true)
  };
  return (
    <>
      <div>
        <Heading label="Locums" />
        <div className="">
          <TableToolbar
            showSearchField
            FilterComponent={
              <UserFilterComponent onApplyFilter={(values) => {
                setRerender(true)
                setFilterValue(values)
              }} />
            }
            filterButton
            onSearch={onSearch}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
          <div style={{ padding: "5px 24px" }}>
            {usersList?.rows?.length > 0 ? (
              <TableComponet
                rowCursor="pointer"
                isBorderShown
                currentPageCount={currentPageCount}
                perPageCount={perPageCount}
                totalDataCount={totalDataCount}
                setCurrentPage={setCurrentPageCount}
                setPerPageCount={setPerPageCount}
                showPagination
                maxPageCount={totalPagesCount}
                columns={columns}
                data={usersListData ?? []}
                formatRowProps={formatTrProps}
              />
            ) : (
              <div style={{ width: "100%", paddingTop: "80px" }}>
                <EmptyData title="Locums" subtitle="Locum" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
