import { DatePicker, Form, Select } from "antd";
import dayjs from "dayjs";
import React, {
  useEffect,
  useState,
} from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { queryClient } from "../..";
import infoIcon from "../../assets/icons/infoIcon1.png";
import { MultipleDatePicker } from "../../pages/admin-view/staffs/details/time-sheet/components/multiDatePicker";
import {
  markStaffAttendanceThunk,
  // markStaffAttendanceThunk,
  updateAttendanceThunk,
} from "../../redux/staffs/staffsThunk";
import { notify } from "../../utils/helper";
import { validateBreakTime } from "../../utils/timeshhet";
import TimeInput from "../UI/input/TimeInput";
import { AddModal } from "../UI/modals/addModal";
import { ConfirmModal } from "../UI/modals/confirmModal";
const AddAttendanceForm = ({
  open,
  onClose,
  staffList,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const startDate = Form.useWatch(
    "startDate",
    form
  );
  const endDate = Form.useWatch("endDate", form);
  const startTime = Form.useWatch(
    "startTime",
    form
  );
  const endTime = Form.useWatch("endTime", form);
  const staffId = Form.useWatch("staffId", form);
  const shiftTime = Form.useWatch(
    "shiftTime",
    form
  );
  const excludeDays = Form.useWatch(
    "excludeDays",
    form
  );
  const [errorData, setErrorData] = useState([]);
  const [showErrorModal, setShowErrorModal] =
    useState(false);
  // const { branches } = useSelector((state) => state.regions);
  const { branches } = useSelector((state) => {
    return state.branches;
  });

  const [flag, setFlag] = useState(true);
  const [isEdit, setIsEdit] = useState();
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [type, setType] = useState("");
  const [APIFlag, setAPIFlag] = useState(false);
  const [
    selectedAttendance,
    setSelectedAttendance,
  ] = useState();

  useEffect(() => {
    if (!APIFlag) return;
    if (
      type === "present" &&
      (!checkIn || !checkOut)
    ) {
      notify(
        "error",
        "Please Enter both Check In and Check Out!"
      );
      setAPIFlag(false);
      return;
    }
    if (!type) {
      notify("error", "Type Can't be Empty!");
      setAPIFlag(false);
      return;
    }
    const data = [
      {
        recordId: selectedAttendance?.id,
        checkIn:
          selectedAttendance?.type === "leave" ||
          type === "absent"
            ? undefined
            : checkIn,
        checkOut:
          selectedAttendance?.type === "leave" ||
          type === "absent"
            ? undefined
            : checkOut,
        type:
          selectedAttendance?.type === "leave"
            ? "leave"
            : type,
        leaveId:
          selectedAttendance?.type === "leave"
            ? selectedAttendance?.leaveId
            : undefined,
        leaveType:
          selectedAttendance?.type === "leave"
            ? type
            : undefined,
      },
    ];

    dispatch(updateAttendanceThunk(data)).then(
      (res) => {
        if (res.payload) {
          setFlag(true);
          setCheckIn("");
          setCheckOut("");
          setType("");
          setIsEdit("");
          setAPIFlag(false);
        }
      }
    );
    setAPIFlag(false);
  }, [
    APIFlag,
    checkIn,
    checkOut,
    dispatch,
    isEdit,
    selectedAttendance,
    type,
  ]);

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open]);

  const onMarkAttendance = (values) => {
    const _diff = values?.endDate?.diff(
      values?.startDate,
      "days"
    );

    // if (values.startTime.format("HH:mm") === values.endDate.format("HH:mm")) {
    //   notify("error", "End time & start time cannot be same.");
    //   return;
    // }

    // if (values.startTime.isSameOrAfter(values.endTime)) {
    //   notify("error", "End time must be greater than start time");
    //   return;
    // }

    if (
      _diff + 1 ===
      values?.excludeDays?.length
    ) {
      notify(
        "error",
        "Cannot exclude all the dates."
      );
      return;
    }

    const staffInfo = staffList.find(
      (i) => i.id === staffId
    );

    const data = {
      ...values,
      startDate:
        values.startDate.format("YYYY-MM-DD"),
      endDate:
        values.endDate.format("YYYY-MM-DD"),
      startTime:
        (!!values.shiftTime &&
          values.shiftTime
            .split("-")[0]
            ?.trim()) ||
        null,
      endTime:
        (!!values.shiftTime &&
          values.shiftTime
            .split("-")[1]
            ?.trim()) ||
        null,
      staffId,
      excludeDays: !values?.excludeDays?.length
        ? []
        : values?.excludeDays,
      roleId: staffInfo?.roleId,
    };

    dispatch(markStaffAttendanceThunk(data)).then(
      (res) => {
        if (res.payload) {
          if (res.payload.code === "validation") {
            setShowErrorModal(true);
            setErrorData(res.payload);

            return;
          } else {
            notify(
              "success",
              "Attendance added successfully"
            );
            queryClient.invalidateQueries(
              "getStaffTimeSheet"
            );
          }
          setFlag(true);
          onClose();
          form.resetFields();
        }
      }
    );
  };

  return (
    <>
      <ConfirmModal
        backdrop="static"
        title="Hold up!"
        open={showErrorModal}
        confirmButtonText="Close"
        image={infoIcon}
        onConfirm={() => {
          setShowErrorModal(false);
          onClose();
          setFlag(true);
        }}
      >
        <div className="d-flex flex-column gap-3">
          <div>
            There seem to be a few conflicts
            within this schedule attempt. However,
            we've created shifts for dates prior
            to and following the date(s) of
            conflict.
          </div>
          <div>
            You can resolve these conflicts and
            schedule again for the following days,
          </div>
        </div>
        <div className="d-flex flex-column gap-3 align-items-center">
          {errorData?.array?.map((data, idx) => (
            <div
              className="d-flex align-items-center gap-2"
              key={idx}
            >
              {data.date ? (
                <>
                  <div
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    {data.date}
                  </div>
                  <div
                    style={{
                      border: "1px solid",
                      height: 20,
                    }}
                  />
                </>
              ) : null}
              <div>
                {data.day[0].toUpperCase() +
                  data.day.slice(1)}
              </div>
              <div
                style={{
                  border: "1px solid",
                  height: 20,
                }}
              />
              <div>{data.reason}</div>
            </div>
          ))}
        </div>
        {errorData?.successCount > 0 && (
          <div>
            {errorData?.successCount} Attendance
            Were Marked Successfully
          </div>
        )}
      </ConfirmModal>
      <AddModal
        title="Add Attendance"
        handleModal={open}
        primaryButtonText="Add"
        secondaryButtonText="Cancel"
        formId={form}
        setHandleModal={onClose}
        secondaryButtonHandler={onClose}
        primaryButtonHandler={form.submit}
      >
        <Form
          onFinish={onMarkAttendance}
          form={form}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            label="Branch"
            name="branchId"
            rules={[
              {
                required: true,
                message:
                  "Please Select a branch!",
              },
            ]}
          >
            <Select
              dropdownStyle={{ zIndex: 2000 }}
              placeholder="Select Branch"
              bordered={false}
              className="text-input-field p-1 t1"
            >
              {branches?.rows?.map(
                (data, idx) => (
                  <Select.Option
                    key={idx}
                    value={data.id}
                  >
                    {data.title}
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>

          <Form.Item
            label="Staff"
            name="staffId"
            rules={[
              {
                required: true,
                message: "Please Select a staff!",
              },
            ]}
          >
            <Select
              dropdownStyle={{ zIndex: 2000 }}
              placeholder="Select staff"
              bordered={false}
              className="text-input-field p-1 t1"
            >
              {staffList?.map((data, idx) => (
                <Select.Option
                  key={idx}
                  value={data.id}
                >
                  {data?.name} {data?.surname}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div className="d-flex gap-3">
            <Form.Item
              label="Start Date"
              name="startDate"
              rules={[
                {
                  required: true,
                  message:
                    "Please Select Start Date!",
                },
              ]}
              style={{
                width: "100%",
              }}
            >
              <DatePicker
                allowClear={false}
                inputReadOnly
                format="DD-MM-YYYY"
                getPopupContainer={(trigger) =>
                  trigger.parentElement
                }
                className="text-input-field p-2 pe-3 t1"
                style={{ width: "100%" }}
                onChange={(val) => {
                  form.setFieldsValue({
                    endDate: val,
                  });
                  form.setFieldValue({
                    excludeDays: [],
                  });
                }}
                disabledDate={(current) => {
                  return dayjs() < current;
                }}
              />
            </Form.Item>
            <Form.Item
              label="End Date"
              name="endDate"
              rules={[
                {
                  required: true,
                  message:
                    "Please Select End Date!",
                },
              ]}
              style={{
                width: "100%",
              }}
            >
              <DatePicker
                allowClear={false}
                inputReadOnly
                format="DD-MM-YYYY"
                getPopupContainer={(trigger) =>
                  trigger.parentElement
                }
                className="text-input-field p-2 pe-3 t1"
                style={{ width: "100%" }}
                disabledDate={(current) => {
                  return (
                    dayjs(startDate).add(
                      -1,
                      "day"
                    ) >= current ||
                    dayjs() <= current
                  );
                }}
                onChange={(val) => {
                  form.setFieldValue({
                    excludeDays: [],
                  });
                }}
              />
            </Form.Item>
          </div>

          <div className="d-flex gap-3">
            <Form.Item
              label="Shift Time"
              name="shiftTime"
              className="m-0"
              hidden={false}
              style={{ width: "100%" }}
              validateTrigger={[
                "onChange",
                "onBlur",
              ]}
              rules={[
                {
                  required: true,
                  validator: async (a, value) => {
                    await validateBreakTime(
                      value,
                      true
                    );
                  },
                },
              ]}
            >
              <TimeInput
                className="text-input-field px-3 pe-3 t1"
                placeholder="Eg: 1h"
                onChange={(value) => {
                  form.setFieldValue(
                    "shiftTime",
                    value
                  );
                }}
                value={shiftTime}
              />
            </Form.Item>
          </div>

          {/*           <div className="d-flex gap-3">

            <Form.Item
              label="Start Time"
              name="startTime"
              rules={[
                {
                  required: true,
                  message: "Please Select Start Time!",
                },
              ]}
              style={{
                width: "100%",
              }}
            >
              <TimePicker
                inputReadOnly
                allowClear={false}
                format="HH:mm"
                minuteStep={5}
                value={startTime}
                onSelect={(value) => form.setFieldsValue({ startTime: value })}
                onChange={(value) => form.setFieldsValue({ startTime: value })}
                className="text-input-field p-2 pe-3 t1"
                style={{ width: "100%" }}
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Form.Item>
            <Form.Item
              label="End Time"
              name="endTime"
              rules={[
                {
                  required: true,

                  message: "Please Select End time!",
                },
              ]}
              style={{
                width: "100%",
              }}
            >
              <TimePicker
                inputReadOnly
                allowClear={false}
                format="HH:mm"
                value={endTime}
                minuteStep={5}
                onSelect={(value) => form.setFieldsValue({ endTime: value })}
                onChange={(value) => form.setFieldsValue({ endTime: value })}
                className="text-input-field p-2 pe-3 t1"
                style={{ width: "100%" }}
                showNow
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </Form.Item>
          </div> */}
          <Form.Item
            label="Exclude Days"
            className="mt-3"
            name="excludeDays"
          >
            <MultipleDatePicker
              onChange={(days) =>
                form.setFieldsValue({
                  excludeDays: days,
                })
              }
              startDate={startDate}
              endDate={endDate}
              excludeDays={excludeDays}
              form={form}
            />
          </Form.Item>
        </Form>
      </AddModal>
    </>
  );
};

export default AddAttendanceForm;
