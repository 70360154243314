import { Popover } from "antd";
import React, { useState } from "react";
import ChevornRight from "../../../assets/icons/chevron-right.svg";
import {
  DropdownSubMenuBranch,
  DropdownSubMenuRole,
} from "../TableCellComponents/DropdownSubmenu";

const FilterPopover = ({ handleFilterValues }) => {
  const [filters, setFilter] = useState({
    branchIds: [],
    roleIds: [],
    regionIds: [],
  });

  const handleSelect = (key, item) => {
    setFilter((prevFilters) => {
      // Create a copy of the selected items array for the current key
      let selectedItems = [...prevFilters[key]];

      if (selectedItems.some((selectedItem) => selectedItem === item)) {
        selectedItems = selectedItems.filter(
          (selectedItem) => selectedItem !== item
        );
      } else {
        selectedItems = [...selectedItems, item];
      }

      // Create a new object with the updated selected items array
      const updatedFilters = {
        ...prevFilters,
        [key]: selectedItems,
      };

      // Update the state with the new object
      handleFilterValues(updatedFilters);
      return updatedFilters;
    });
  };

  return (
    <ul className="modal_popover">
      <li>
        <Popover
          content={
            <DropdownSubMenuBranch
              handleSelect={handleSelect}
              selectedItems={filters.branchIds}
              keyName="branchIds"
            />
          }
          trigger="click"
          showArrow={false}
          placement="rightBottom"
          // open={openPopover}
        >
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0 ant_sub_menu">
              Branch{" "}
              {filters?.branchIds?.length
                ? `(${filters?.branchIds?.length})`
                : null}
            </p>
            <img src={ChevornRight} alt="right" />
          </div>
        </Popover>
      </li>

      {/* <li>
        <Popover
          content={
            <DropdownSubMenuRegion
              handleSelect={handleSelect}
              selectedItems={filters.regionIds}
              keyName="regionIds"
            />
          }
          trigger="click"
          showArrow={false}
          placement="rightBottom"
          // open={openPopover}
        >
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0 ant_sub_menu">
              Region{" "}
              {filters?.regionIds?.length
                ? `(${filters?.regionIds?.length})`
                : null}
            </p>
            <img src={ChevornRight} alt="right" />
          </div>
        </Popover>
      </li> */}

      <li>
        <Popover
          content={
            <DropdownSubMenuRole
              handleSelect={handleSelect}
              selectedItems={filters.roleIds}
              keyName="roleIds"
            />
          }
          trigger="click"
          showArrow={false}
          placement="rightBottom"
          // open={openPopover}
        >
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0 ant_sub_menu">
              Roles{" "}
              {filters?.roleIds?.length
                ? `(${filters?.roleIds?.length})`
                : null}
            </p>
            <img src={ChevornRight} alt="right" />
          </div>
        </Popover>
      </li>
    </ul>
  );
};

export default FilterPopover;
