import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { store } from "./redux";
import reportWebVitals from "./reportWebVitals";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConfigProvider } from "antd";
import locale from "antd/locale/en_GB";
import "bootstrap/dist/css/bootstrap.min.css";
import dayjs from "dayjs";
import engb from "dayjs/locale/en-gb";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { App } from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
let persistor = persistStore(store);
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: 0,
    },
  },
});

dayjs.locale(engb);
moment.locale("en-gb");

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ConfigProvider
          locale={locale}
          theme={{
            token: {
              colorPrimary: "#51C2BC",
            },
            components: {
              Radio: {
                colorPrimary: "#51C2BC",
              },
              Checkbox: {
                colorPrimary: "#51C2BC",
              },
            },
          }}
        >
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </ConfigProvider>
        <ToastContainer autoClose={1000} />
      </Provider>
    </QueryClientProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
