import React from "react";

const MedalIcon2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M17.8856 5.99V6.73L14.3456 4.68C13.0056 3.91 11.1356 3.91 9.80562 4.68L6.26562 6.74V5.99C6.26562 3.74 7.49563 2.5 9.74563 2.5H14.4056C16.6556 2.5 17.8856 3.74 17.8856 5.99Z"
        fill="#78828A"
      />
      <path
        d="M17.9162 8.47023L17.7762 8.40023L16.4162 7.62023L13.5962 5.99023C12.7362 5.49023 11.4162 5.49023 10.5562 5.99023L7.73617 7.61023L6.37617 8.41023L6.19617 8.50023C4.44617 9.68023 4.32617 9.90023 4.32617 11.7902V16.3102C4.32617 18.2002 4.44617 18.4202 6.23617 19.6302L10.5562 22.1202C10.9862 22.3802 11.5262 22.4902 12.0762 22.4902C12.6162 22.4902 13.1662 22.3702 13.5962 22.1202L17.9562 19.6002C19.7162 18.4202 19.8262 18.2102 19.8262 16.3102V11.7902C19.8262 9.90023 19.7062 9.68023 17.9162 8.47023ZM14.8662 14.0002L14.2562 14.7502C14.1562 14.8602 14.0862 15.0702 14.0962 15.2202L14.1562 16.1802C14.1962 16.7702 13.7762 17.0702 13.2262 16.8602L12.3362 16.5002C12.1962 16.4502 11.9662 16.4502 11.8262 16.5002L10.9362 16.8502C10.3862 17.0702 9.96617 16.7602 10.0062 16.1702L10.0662 15.2102C10.0762 15.0602 10.0062 14.8502 9.90617 14.7402L9.28617 14.0002C8.90617 13.5502 9.07617 13.0502 9.64617 12.9002L10.5762 12.6602C10.7262 12.6202 10.8962 12.4802 10.9762 12.3602L11.4962 11.5602C11.8162 11.0602 12.3262 11.0602 12.6562 11.5602L13.1762 12.3602C13.2562 12.4902 13.4362 12.6202 13.5762 12.6602L14.5062 12.9002C15.0762 13.0502 15.2462 13.5502 14.8662 14.0002Z"
        fill="#78828A"
      />
    </svg>
  );
};

export default MedalIcon2;
