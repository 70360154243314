import { Avatar, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HospitalIcon from "../../../../src/assets/icons/Pharmacy_icon.svg";
import AddCircle from "../../../../src/assets/icons/add-circle.svg";
import DropDownIcon from "../../../../src/assets/icons/downDrop.svg";
import DropUp from "../../../../src/assets/icons/dropUp.png";
import SettingsIcon from "../../../../src/assets/icons/setting.svg";
import { defaultRoleOptions, roleOptionList } from "../../../utils/constant";
import { returnIdOnly } from "../../../utils/helper";
import Nobranch from "../../EmptyStates/NoBranch/Nobranch";
import ShiftDetailsDayView from "../ShiftDeatails/ShiftDetailsDayView";

const _slots = [
  { id: 1, hour: "00:00" },
  { id: 2, hour: "01:00" },
  { id: 3, hour: "02:00" },
  { id: 4, hour: "03:00" },
  { id: 5, hour: "04:00" },
  { id: 6, hour: "05:00" },
  { id: 7, hour: "06:00" },
  { id: 8, hour: "07:00" },
  { id: 9, hour: "08:00" },
  { id: 10, hour: "09:00" },
  { id: 11, hour: "10:00" },
  { id: 12, hour: "11:00" },
  { id: 13, hour: "12:00" },
  { id: 14, hour: "13:00" },
  { id: 15, hour: "14:00" },
  { id: 16, hour: "15:00" },
  { id: 17, hour: "16:00" },
  { id: 18, hour: "17:00" },
  { id: 19, hour: "18:00" },
  { id: 20, hour: "19:00" },
  { id: 21, hour: "20:00" },
  { id: 22, hour: "21:00" },
  { id: 23, hour: "22:00" },
  { id: 24, hour: "23:00" },
];

const DayViewRow = ({ branchInfo, onDelete, handleAddShift }) => {
  const [openTable, setopenTable] = useState(false);
  const [showDropdown, setshowDropdown] = useState(false);
  const [roleOption, setRoleOption] = useState(roleOptionList);
  const [selectedOption, setSelectedOption] = useState(defaultRoleOptions);

  const toggleTable = () => {
    setopenTable((prev) => !prev);
  };

  const navigate = useNavigate();

  const { roles, roleList } = useSelector((state) => {
    return state.schedule;
  });
  const populatehourArray = () => {
    const arr = [];
    for (let index = 0; index < 24; index++) {
      const h = moment(index, "HH");
      arr.push(h.format("hh A"));
    }
    return arr;
  };
  const hourArray = populatehourArray();
  return (
    <div className="mb-4 border" style={{ borderRadius: "16px" }}>
      <div
        className={`bg-white rounded-top leave_table_box p-4 cursor-pointer  d-flex align-items-center justify-content-between ${
          openTable ? "border-bottom-0 rounded-top" : "rounded-bottom"
        }`}
        onClick={toggleTable}
      >
        <div className="d-flex justify-content-start align-items-center pharma_time_sheet">
          {!!branchInfo?.logo ? (
            <Avatar className="me-3" src={branchInfo?.logo} alt="pharma" />
          ) : (
            <Avatar className="me-3" src={branchInfo?.logo} alt="pharma">
              {branchInfo?.title[0]}
            </Avatar>
          )}

          <div className="pharma_name">
            <div className="d-flex align-items-center">
              <h6 className="me-2">{branchInfo?.title}</h6>{" "}
              {/* <p className="mb-0 me-2">{branchInfo?.title}</p>{" "} */}
              {branchInfo?.active ? (
                <span className="badge rounded-pill status_badge">Open</span>
              ) : (
                <span className="badge rounded-pill status_badge close">
                  Closed
                </span>
              )}
            </div>
            {!!branchInfo?.region && (
              <div className="d-flex align-items-center">
                <img src={HospitalIcon} alt="hospital" className="me-2" />
                <p className="mb-0">{branchInfo?.region}</p>
              </div>
            )}
          </div>
        </div>
        <div className=" d-flex align-items-center">
          <ul className="d-flex align-items-center rgt_side schedule_view">
            <li>
              <button
                className="btn custom_btn"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/branches/${branchInfo?.id}/details/template/new`);
                }}
              >
                Template
              </button>
            </li>
            <li>
              <button
                className="btn"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/branches/${branchInfo?.id}/details/information`);
                }}
              >
                <Tooltip title="Branch Settings">
                  <img src={SettingsIcon} alt="settings" />
                </Tooltip>
              </button>
            </li>

            <li>
              <button className="dropdown_btn btn">
                <img src={openTable ? DropUp : DropDownIcon} alt="" />
              </button>
            </li>
          </ul>
        </div>
      </div>
      {openTable && (
        <div className="table_container">
          <table className="table table-bordered bg-white weekview_table dayview_table">
            <thead>
              <tr>
                <th scope="col roles">
                  <div className="p-3 roles">
                    <button
                      className="btn"
                      onClick={() => setshowDropdown(true)}
                    >
                      <img src={AddCircle} alt="people" /> Roles
                    </button>
                    {showDropdown && (
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        {roleOption.map((option) => (
                          <li
                            style={{
                              display: selectedOption.includes(option)
                                ? "none"
                                : "block",
                            }}
                          >
                            <button
                              className="dropdown-item text-capitalize"
                              onClick={() => {
                                setSelectedOption([...selectedOption, option]);
                                setshowDropdown(false);
                              }}
                            >
                              {option}
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </th>
                {hourArray.map((item, index) => (
                  <th scope="col" key={item}>
                    <p className="p-3 dates">{item}</p>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(roleList).map((key, innerIndex) =>
                selectedOption.includes(key) ? (
                  <tr key={`roles_${innerIndex}`}>
                    <th scope="row">
                      <p className="item_type text-capitalize">{key}</p>
                    </th>
                    {_slots?.map((slot, i) =>
                      branchInfo?.events?.map((slots, i) => {
                        return (
                          <td key={i} className="schedular-slot-container">
                            <div>
                              {!!slots.shifts ? (
                                slots?.shifts?.map((_shift, i) => {
                                  return moment(slot.hour, "HH:mm").format(
                                    "HH"
                                  ) ===
                                    moment(_shift.startTime, "HH:mm").format(
                                      "HH"
                                    ) &&
                                    returnIdOnly(roleList[key]).includes(
                                      _shift?.roleType?.id
                                    ) ? (
                                    <ShiftDetailsDayView
                                      viewDetails
                                      shift={_shift}
                                      dayView={true}
                                      addShift={false}
                                      onDelete={onDelete}
                                      handleAddShift={handleAddShift}
                                      branchName={branchInfo.title}
                                      branchId={branchInfo.id}
                                      operatingTime={branchInfo?.operatingTime}
                                      type={returnIdOnly(roleList[key])}
                                    />
                                  ) : (
                                    <div></div>
                                  );
                                })
                              ) : (
                                <div />
                              )}
                            </div>
                          </td>
                        );
                      })
                    )}
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const DayviewNew = ({ branches, ...props }) => {
  if (branches?.length > 0) {
    return branches?.map((branch) => {
      return <DayViewRow branchInfo={branch} {...props} key={branch.id} />;
    });
  } else {
    return (
      <div>
        <Nobranch />
      </div>
    );
  }
};

export default DayviewNew;
