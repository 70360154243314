import React from "react";

import { useState } from "react";
import { useParams } from "react-router-dom";
import { ResetPassEmailForm } from "../../../components/auth/login/resetPassEmailForm";
import { ResetPassForm } from "../../../components/auth/login/resetPassForm";
import { LoginFormNew } from "../../../components/auth/loginNew/LoginNewform";
import LopicLogo from '../../../assets/img/LopicLogo.svg'

export const LoginPageNew = () => {
  const { token } = useParams();
  const [formView, setFormView] = useState("login");
  return (
    <div
      className="d-flex align-items-center flex-column justify-content-center"
      style={{ height: "100vh", gap: "15px" }}
    >
      <div className="logo-wrap">
        <img src={LopicLogo} alt="logo" />
      </div>
      {token ? (
        <ResetPassForm />
      ) : formView === "login" ? (
        <LoginFormNew setFormView={setFormView} />
      ) : (
        <ResetPassEmailForm setFormView={setFormView} />
      )}
    </div>
  );
};
