import React from 'react';

const AddIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M6.66602 10.2002H13.3327"
        stroke="#434343"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 13.5339V6.86719"
        stroke="#434343"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49935 18.5339H12.4993C16.666 18.5339 18.3327 16.8672 18.3327 12.7005V7.70052C18.3327 3.53385 16.666 1.86719 12.4993 1.86719H7.49935C3.33268 1.86719 1.66602 3.53385 1.66602 7.70052V12.7005C1.66602 16.8672 3.33268 18.5339 7.49935 18.5339Z"
        stroke="#434343"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddIcon;
