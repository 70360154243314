import { Button } from "antd";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import DeactivateIcon from "../icons/DeactivateIcon";
import { ConfirmModal } from "../modals/confirmModal";
import "./style.scss";

export const SidebarNew = ({
  links = [],
  visible,
  hideDeactivate,
  handleDeactivateAccount,
  completeProfileSection,
  activateButtonText,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const navLinks = links.map((link) => {
    const { to, text, disabled, hidden, isActive, icon } = link;
    return (
      <NavLink
        key={to}
        to={to}
        isActive={isActive}
        className={`${
          hidden ? "hidden" : disabled ? "disabled" : ""
        } sidebarnew__link`}
      >
        <i>{icon}</i>
        {text}
      </NavLink>
    );
  });

  return (
    <>
      <aside className={`sidebarnew ${visible ? "" : "hidden"}`}>
        <div className="sidebarnew__top">{props.children}</div>
        {links.length > 0 ? (
          <div className="sidebarnew__bottom">{navLinks}</div>
        ) : (
          ""
        )}

        <div style={{ padding: "16px" }}>
          {completeProfileSection}
          {/* <CreateProfileCard /> */}
        </div>
        {!hideDeactivate && (
          <div className="sidebarnew__footer">
            <Button
              onClick={showModal}
              isActive={false}
              className={`sidebarnew__link`}
              style={{
                border: "none",
              }}
            >
              <i>{<DeactivateIcon />}</i>
              {activateButtonText || "Deactivate Account"}
            </Button>
          </div>
        )}
      </aside>

      <ConfirmModal
        open={isModalOpen}
        title={`Are you sure you want to ${activateButtonText}?`}
        confirmButtonText={activateButtonText}
        cancelButtonText="Close"
        onClose={() => handleCancel()}
        onConfirm={() => {
          handleDeactivateAccount();
          handleCancel();
        }}
      />
    </>
  );
};
