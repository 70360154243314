import moment from "moment";
import React, { useState } from "react";

import { useCallback } from "react";
import ShiftDetailsDayView from "./ShiftDetailsDayView";
import ShiftDetailsMonthView from "./ShiftDetailsMonthView";
import ShiftDetailsWeekView from "./ShiftDetailsWeekView";

const ShiftDetails = ({
  branch,
  events,
  day,
  monthview,
  dayview,
  weekview,
  type,
  date,
  vertical,
}) => {
  const [openPopover, setOpenPopover] =
    useState(false);

  const handlePopover = (newOpen) => {
    setOpenPopover(newOpen);
  };

  const RenderDetailView = useCallback(() => {
    if (weekview) {
      return (
        <ShiftDetailsWeekView
          shift={events?.shifts}
          type={type}
          handlePopover={handlePopover}
          monthview={monthview}
          openPopover={openPopover}
          setOpenPopover={setOpenPopover}
        />
      );
    }
    if (dayview) {
      return (
        <ShiftDetailsDayView
          shift={branch}
          type={type}
          handlePopover={handlePopover}
          dayview={dayview}
          openPopover={openPopover}
          setOpenPopover={setOpenPopover}
        />
      );
    }

    if (monthview) {
      return (
        <ShiftDetailsMonthView
          shifts={events?.shifts}
          type={type}
          handlePopover={handlePopover}
          monthview={monthview}
          openPopover={openPopover}
          setOpenPopover={setOpenPopover}
          vertical={vertical}
          events={events}
          branchId={branch.id}
        />
      );
    }
  }, []);

  return (
    <div
      style={
        dayview && {
          position: "relative",
          height: "74px",
        }
      }
    >
      {monthview && events.startDate ? (
        <p className="text-center">
          {" "}
          {moment(events.startDate).format("DD")}
        </p>
      ) : (
        ""
      )}

      <RenderDetailView />
    </div>
  );
};

export default ShiftDetails;
