import { useMutation } from "@tanstack/react-query";
import { Button, Col, Row, Typography } from "antd";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import HandGesture from "../../assets/icons/HandGesture.png";
import { setopenSegment } from "../../redux/dashboard/dashboardSlicer";
import { profileChooseSegment } from "../../redux/staffs/staffsAPI";
import Calendar from "../UI/icons/Calendar";
import ClockIconPurple from "../UI/icons/ClockIconPurple";
import CloseIcon from "../UI/icons/CloseIcon";
import Hospitalicon from "../UI/icons/Hospitalicon";
import MedalIcon2 from "../UI/icons/MedalIcon2";
import SearchStatusicon from "../UI/icons/SearchStatusicon";
import UserHexagon from "../UI/icons/UserHexagon";
import RightSlider from "../auth/loginNew/RightSlider";
import "./style.scss";

const iconColor = "#78828A";

const segmentoptions = [
  {
    id: 1,
    title: "Scheduling",
    icon: <Calendar />,
    tag: "SPA- For Scheduling",
  },
  {
    id: 2,
    title: "Finding Locums",
    icon: <UserHexagon />,
    tag: "SPA- For Finding Locums",
  },
  {
    id: 3,
    title: "Employee Time Tracking",
    icon: <ClockIconPurple width={24} height={24} color={iconColor} />,
    tag: "SPA- For Employee Time Tracking",
  },
  {
    id: 4,
    title: "Accreditation Management",
    icon: <MedalIcon2 color={iconColor} />,
    tag: "SPA- For Accreditation Management",
  },
  {
    id: 5,
    title: "Optimising Pharmacy Operations",
    icon: <Hospitalicon />,
    tag: "SPA- For Optimizing Pharmacy Operations",
  },
  {
    id: 6,
    title: "I’m just looking around",
    icon: <SearchStatusicon />,
    tag: "SPA- Explore",
  },
];

const Segmentation = () => {
  const { user } = useSelector((state) => state.auth);
  const { openSegment } = useSelector((state) => state.dashboard);
  // "segmentSelected": [1,4]
  const [segmentSelected, setSegmentSelected] = useState([]);
  const [segmentSelectedLabel, setsegmentSelectedLabel] = useState([]);

  const dispatch = useDispatch();
  const { mutate } = useMutation({
    mutationFn: profileChooseSegment,
  });

  const handleContinue = () => {
    mutate(
      {
        segmentSelected,
      },
      {
        onSuccess: (res) => {
          if (res?.data?.status === "success") {
            dispatch(setopenSegment(false));
            segmentSelectedLabel.forEach((item) => {
              window.fbq("trackCustom", item, {
                id: user?.id,
                email: user?.email,
                userType: user?.group,
                name: user?.name,
                surname: user?.surname,
                role: user?.role,
                gphcNumber: user?.gphcNumber,
                device: "web",
                tag: item,
              });
            });
          }
        },
      }
    );
  };

  const handleSelect = (id, label) => {
    if (segmentSelected.includes(id)) {
      setSegmentSelected(segmentSelected.filter((i) => i !== id));
      setsegmentSelectedLabel(segmentSelectedLabel.filter((i) => i !== label));
    } else {
      setSegmentSelected([...segmentSelected, id]);
      setsegmentSelectedLabel([...segmentSelectedLabel, label]);
    }
  };

  const handleCancel = () => {
    dispatch(setopenSegment(false));
  };

  return (
    <Modal show={openSegment} size="lg" className="segmentaionModal">
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ gap: "15px", overflow: "hidden", height: "100%" }}
      >
        <div
          className="d-flex flex-column login_new locum_signup_new"
          style={{ width: "100%", padding: "40px 40px" }}
        >
          <div className="header">
            <div className="heading2 w-100 d-flex align-items-center">
              Hi {"  "}
              {user?.name},What brings you to Lopic?{" "}
              <img src={HandGesture} alt="hello" width={40} height={40} />
            </div>
            <div className="t1 sub_heading">
              We'll help you get going so you can get the best out of Lopic!
            </div>
          </div>

          <Row gutter={[24, 24]} className="segment-row">
            {segmentoptions.map((item) => (
              <Col span={12}>
                <div
                  className={`segment-each ${
                    segmentSelected.includes(item?.id) ? "selected" : ""
                  }`}
                  onClick={() => handleSelect(item.id, item?.tag)}
                >
                  <figure>{item.icon}</figure>
                  <Typography.Text className="segement-title" level={5}>
                    {item.title}
                  </Typography.Text>
                </div>
              </Col>
            ))}
          </Row>
          <div className="segment-footer d-flex justify-content-end gap-4">
            <Button
              disabled={!segmentSelected?.length}
              className="custom_btn auth_button button"
              type="primary"
              htmlType="submit"
              block
              onClick={handleContinue}
            >
              Continue
            </Button>
          </div>
        </div>

        <Button
          className=" w-auto ms-auto"
          type="text"
          block
          onClick={handleCancel}
          icon={<CloseIcon />}
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            zIndex: 5,
            background: "white",
            padding: "5px",
            borderRadius: "50px",
          }}
        />

        <RightSlider />
      </div>
    </Modal>
  );
};

export default Segmentation;
