import React from "react";

const PharmaIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M12.8327 12.3945H1.16602C0.926849 12.3945 0.728516 12.5929 0.728516 12.832C0.728516 13.0712 0.926849 13.2695 1.16602 13.2695H12.8327C13.0718 13.2695 13.2702 13.0712 13.2702 12.832C13.2702 12.5929 13.0718 12.3945 12.8327 12.3945Z"
        fill="#434343"
      />
      <path
        d="M9.91667 1.16797H4.08333C2.33333 1.16797 1.75 2.21214 1.75 3.5013V12.8346H5.25V9.29963C5.25 8.9963 5.495 8.7513 5.79833 8.7513H8.2075C8.505 8.7513 8.75583 8.9963 8.75583 9.29963V12.8346H12.2558V3.5013C12.25 2.21214 11.6667 1.16797 9.91667 1.16797ZM8.45833 5.39714H7.4375V6.41797C7.4375 6.65714 7.23917 6.85547 7 6.85547C6.76083 6.85547 6.5625 6.65714 6.5625 6.41797V5.39714H5.54167C5.3025 5.39714 5.10417 5.1988 5.10417 4.95964C5.10417 4.72047 5.3025 4.52214 5.54167 4.52214H6.5625V3.5013C6.5625 3.26214 6.76083 3.0638 7 3.0638C7.23917 3.0638 7.4375 3.26214 7.4375 3.5013V4.52214H8.45833C8.6975 4.52214 8.89583 4.72047 8.89583 4.95964C8.89583 5.1988 8.6975 5.39714 8.45833 5.39714Z"
        fill="#434343"
      />
    </svg>
  );
};

export default PharmaIcon;
