import React from "react";

import Nobranch from "../../EmptyStates/NoBranch/Nobranch";
import WeekViewRow from "./WeekViewRow";

const WeekViewNew = ({
  weekArray,
  branches,
  handleAddShift,
  weekDayArray,
  templateView = false,
  weekName,
  actionWeekTemplate,
  clearWeekTemplate,
  onDelete,
  weekNumber,
}) => {
  return (
    <>
      {" "}
      {branches?.length > 0 ? (
        branches?.map((_info) => {
          return (
            <WeekViewRow
              branchInfo={_info}
              key={_info?.id}
              weekArray={weekArray}
              weekDayArray={weekDayArray}
              handleAddShift={handleAddShift}
              templateView={templateView}
              weekName={weekName}
              actionWeekTemplate={
                actionWeekTemplate
              }
              clearWeekTemplate={
                clearWeekTemplate
              }
              onDelete={onDelete}
              weekNumber={weekNumber}
            />
          );
        })
      ) : (
        <div>
          {/* <EmptyData
            title="Branches"
            description="We cant find any of your branches, head over to the branches section to add your first branch!"
          /> */}
          <Nobranch />
        </div>
      )}
    </>
  );
};

export default WeekViewNew;
