import { Route, Routes } from "react-router-dom";
import { StaffContracts } from "./contractList";
import { ContractDetails } from "./details";
export const StaffContractPage = () => {
  // useEffect(() => {
  //     disatch(getRolesThunk({
  //         group: ["staff"],
  //         type: "Default"
  //     }))
  // }, [])
  return (
    <Routes>
      <Route path={`/`} element={<StaffContracts />} />
      <Route path="/:contractId" element={<ContractDetails />} />
    </Routes>
  );
};
