import { Button, Tabs } from "antd";
import React, { memo, useEffect, useState } from "react";
import { branchApiKeys } from "../../../utils/apiKeyConstants";
import { hasAccess } from "../../../utils/helper";
import "./style.scss";

export const CustomTabs = memo(
  ({
    tabs,
    onEditHandler,
    onApproveReject,
    showModal,
    setStatus,
    onActivateDeactivate,
    staffInfo,
    staffInfoStatus,
    currentActiveTab,
    setCurrentActiveTab,
    addLocumBank,
  }) => {
    const [panes, setPanes] = useState([]);

    useEffect(() => {
      if (!tabs) return;
      const link = [];
      tabs.map((data, idx) =>
        link.push({
          label: data.title,
          key: idx,
          children: data.content,
        }),
      );
      setPanes(link);
    }, [tabs]);

    const updateBranchBGenralPharmay = () => {
      const flag =
        panes[currentActiveTab]?.label.toLowerCase().includes("general") ||
        panes[currentActiveTab]?.label.toLowerCase().includes("pharmacy");
      const updatePermisson = hasAccess(branchApiKeys.UPDATE_BRANCH_BY_ID);
      return flag && updatePermisson;
    };

    const manageBranchOperatingTime = () => {
      const flag = panes[currentActiveTab]?.label
        .toLowerCase()
        .includes("branch");
      const managePermisson = hasAccess(
        branchApiKeys.MANAGE_BRANCH_OPERATING_TIME,
      );

      return flag && managePermisson;
    };
    return (
      <div className="custom-tabs">
        {onEditHandler &&
          !panes[currentActiveTab]?.label.includes("Pharmacy Information") &&
          !panes[currentActiveTab]?.label.includes("Branch Hours") && (
            <div
              className="edit-info cursor-pointer"
              onClick={() => onEditHandler(panes[currentActiveTab]?.label)}
            >
              EDIT
            </div>
          )}
        {addLocumBank && (
          <Button
            style={{
              top: 15,
            }}
            disabled={staffInfo?.locumBank !== null}
            type="default"
            className="rounded-pill edit-info cursor-pointer"
            onClick={() => addLocumBank(staffInfo)}
          >
            Add to Locum Bank
          </Button>
        )}
        {onEditHandler &&
          (updateBranchBGenralPharmay() || manageBranchOperatingTime()) && (
            <div
              className="edit-info cursor-pointer"
              onClick={() => onEditHandler(panes[currentActiveTab]?.label)}
            >
              EDIT
            </div>
          )}
        {(onApproveReject === "pending" ||
          onApproveReject === "onboarding" ||
          onApproveReject === "rejected") &&
          currentActiveTab === 0 &&
          staffInfoStatus !== "deactivated" &&
          staffInfoStatus !== "active" && (
            <div
              className="edit-info cursor-pointer d-flex gap-2"
              style={{ top: "15px" }}
            >
              <Button
                className="cursor-pointer rounded-pill"
                onClick={() => {
                  showModal();
                  setStatus("reject");
                }}
                type="default"
                disabled={staffInfoStatus === "reject"}
              >
                {staffInfoStatus === "reject" ? `Rejected` : "Reject"}
              </Button>
              <Button
                className="cursor-pointer rounded-pill"
                onClick={() => {
                  showModal();
                  setStatus("approve");
                }}
                type="primary"
              >
                {"Approve"}
              </Button>
            </div>
          )}
        {onActivateDeactivate && staffInfoStatus === "active" && (
          <div
            className="edit-info cursor-pointer d-flex"
            style={{ top: "15px" }}
          >
            <Button
              className="cursor-pointer rounded-0"
              onClick={() => {
                showModal();
                setStatus("deactivate");
              }}
              type="default"
            >
              {"Deactivate"}
            </Button>{" "}
          </div>
        )}
        {onActivateDeactivate && staffInfoStatus === "deactivated" && (
          <div className="edit-info cursor-pointer d-flex" style={{ top: 15 }}>
            <Button
              className="cursor-pointer rounded-0 "
              onClick={() => {
                showModal();
                setStatus("activate");
              }}
              type="default"
            >
              {"Activate"}
            </Button>
          </div>
        )}

        <Tabs
          activeKey={currentActiveTab}
          onChange={(e) => {
            setCurrentActiveTab(e);
          }}
          items={panes}
        />
      </div>
    );
  },
);

CustomTabs.displayName = "CustomTabs";
