import { createSlice } from "@reduxjs/toolkit";
import { getLocumBankListThunk, getLocumByGphCThunk } from "../locumBankThunk";

const initialState = {
    locumBank: [],
    locumData: {}
}

const locumBankSlicer = createSlice({
    name: "locumBankSlicer",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getLocumBankListThunk.fulfilled, (state, action) => {
            state.locumBank = action.payload
        })
        builder.addCase(getLocumByGphCThunk.fulfilled, (state, action) => {
            state.locumData = action.payload
        })
        // builder.addCase(editRegionThunk.fulfilled, (state, action) => {
        //     const index = state.regions.rows.findIndex((list) => list.id === action.payload.id)
        //     state.regions.rows[index] = action.payload
        // })
        // builder.addCase(createRegionThunk.fulfilled, (state, action) => {
        //     state.regions.rows.unshift(action.payload)
        // })
    }
})

export const locumBankReducer = locumBankSlicer