import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment/moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DropDown from "../../assets/icons/downDrop.svg";
import RightArrow from "../../assets/icons/right-arrow.png";
import PharmaImg from "../../assets/img/Pharma1.jpeg";
import { getLocumShiftInfoThunk } from "../../redux/locum-shifts/locumShiftsThunk";
import CalendarFilled from "../UI/icons/CalendarFilled";
import CloseIconFilled from "../UI/icons/CloseIconFilled";
import InfoQuestionIcon from "../UI/icons/InfoQuestionIcon";
import MoneyIcon from "../UI/icons/MoneyIcon";
import Peopleicon from "../UI/icons/Peopleicon";
import PharmaIcon from "../UI/icons/PharmaIcon";
import YellowClock from "../UI/icons/YellowClock";
import { AddModal } from "../UI/modals/addModal";
import { ConfirmModal } from "../UI/modals/confirmModal";
import AddJobFormNew from "../comon/AddJobFormNew";
import AssignedlocumCard from "./AssignedlocumCard";
import GiveRatingCard from "./GiveRatingCard";
import LocumJobStatusChip from "./LocumJobStatusChip";

const MillageRule = ({ rule }) => {
  const MillageRuleTooltip = useMemo(() => {
    if (rule) {
      const { maximumMileage, name, payMileageAfter, ratePerMile } = rule;

      return (
        <>
          <p>Rate per Mile : {ratePerMile} pence</p>
          <p>Pay Milage After : {payMileageAfter} mile</p>
          <p>Maximum Milage : {maximumMileage} mile</p>
        </>
      );
    }

    return <></>;
  }, [rule]);

  return (
    <div className="rules">
      <p>Milage Rule</p>
      <Space align="start" className="rule-space">
        <Tooltip title={MillageRuleTooltip}>
          <i>
            <InfoQuestionIcon />
          </i>
        </Tooltip>

        <Typography.Text>{rule?.name}</Typography.Text>
      </Space>
    </div>
  );
};

const AccommodationAndFoodRule = ({ rule }) => {
  const FoodRuleTooltip = useMemo(() => {
    if (rule) {
      const { name, upTo, payOver, foodCoverage } = rule;

      return (
        <>
          <p>Food Coverage : {foodCoverage ? "Yes" : "No"}</p>
          <p>Pay Over : {payOver} pence</p>
          <p>Up to : {upTo} pence</p>
        </>
      );
    }

    return <></>;
  }, [rule]);

  return (
    <div className="rules">
      <p>Accommodation & Food Rule</p>
      <Space align="start" className="rule-space">
        <Tooltip title={FoodRuleTooltip}>
          <i>
            <InfoQuestionIcon />
          </i>
        </Tooltip>

        <Typography.Text>{rule?.name}</Typography.Text>
      </Space>
    </div>
  );
};

const ParkingRule = ({ rule }) => {
  const ParkingRuleTooltip = useMemo(() => {
    if (rule) {
      const { upTo, name } = rule;

      return (
        <>
          <p>Up to : {upTo} pence</p>
        </>
      );
    }

    return <></>;
  }, [rule]);

  return (
    <div className="rules">
      <p>Parking Rule</p>
      <Space align="start" className="rule-space">
        <Tooltip title={ParkingRuleTooltip}>
          <i>
            <InfoQuestionIcon />
          </i>
        </Tooltip>

        <Typography.Text>{rule?.name}</Typography.Text>
      </Space>
    </div>
  );
};

const TransportRule = ({ rule }) => {
  const TransportRuleTooltip = useMemo(() => {
    if (rule) {
      const { upTo, name, payOver } = rule;

      return (
        <>
          <p>Pay Over : {payOver} pence</p>
          <p>Up to : {upTo} pence</p>
        </>
      );
    }

    return <></>;
  }, [rule]);

  return (
    <div className="rules">
      <p>Transport Rule</p>
      <Space align="start" className="rule-space">
        <Tooltip title={TransportRuleTooltip}>
          <i>
            <InfoQuestionIcon />
          </i>
        </Tooltip>

        <Typography.Text>{rule?.name}</Typography.Text>
      </Space>
    </div>
  );
};

const LocumJobDetailsModal = ({
  showModal,
  setshowModal,
  status,
  id,
  locumRolesOptions,
  branchOptions,
  handleRating,
  handleAcceptJobRequest,
  handleRejectJobRequest,
  handleUpdateJobStatus,
  onCancelJob,
  onInviveLocum,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formId = useMemo(() => "cancelForm", []);

  const [isEditor, setIsEditor] = useState(false);
  const [showDeleteModal, setshowDeleteModal] = useState(false);

  const { shiftInfo, cancelReasons, workedLocumsList } = useSelector(
    (s) => s.locumShifts
  );

  // console.log('shiftInfo: ', shiftInfo);
  // console.log('shiftInfo - status: ', shiftInfo?.status);

  const primaryAndSecondaryBtnTxt = useMemo(() => {
    if (status === "cancelled") {
      return { primaryBtnTxt: "Republish", secondaryBtnText: "Close" };
    }

    if (status === "payment_approved") {
      return { primaryBtnTxt: "Move to Paid", secondaryBtnText: "Close" };
    }

    if (status === "completed") {
      return {
        primaryBtnTxt: "Move to Payment Approved",
        secondaryBtnText: "Close",
      };
    }

    return {};
  }, [status]);

  const handleDeleteModal = () => {
    setshowDeleteModal(!showDeleteModal);
  };

  const visibleSections = useMemo(() => {
    const commonSections = {
      isEditButton: false,
      isDeleteButton: false,
      isCancellationReason: false,
      hasCoverExpense: !!(
        shiftInfo?.mileageRule ||
        shiftInfo?.accommodationAndFoodRule ||
        shiftInfo?.parkingRule ||
        shiftInfo?.transportRule
      ),
      isAssignedLocumForm: false,
      isAssignedLocum: false,
      isGiveRating: false,
    };

    switch (status) {
      case "active":
        return {
          ...commonSections,
          isDeleteButton: true,
          isGiveRating: false,
          isAssignedLocum: true,
          isGiveRating: false,
        };
      case "unfilled":
        return {
          ...commonSections,
          isEditButton: true,
          isDeleteButton: true,
        };
      case "pending":
        return {
          ...commonSections,
          isEditButton: true,
          isDeleteButton: true,
          isAssignedLocum: true,
        };
      case "completed":
        return {
          ...commonSections,
          isAssignedLocum: true,
        };
      case "payment_approved":
        return {
          ...commonSections,
          isAssignedLocum: true,
          isAssignedLocumForm: true,
        };
      case "paid":
        return {
          ...commonSections,
          isAssignedLocum: true,
          isGiveRating: true,
        };
      case "cancelled":
        return {
          ...commonSections,
          isCancellationReason: true,
        };
      default:
        return commonSections;
    }
  }, [status]);

  useEffect(() => {
    dispatch(getLocumShiftInfoThunk({ shiftId: id }));
  }, [id]);

  if (isEditor && shiftInfo) {
    return (
      <AddJobFormNew
        key="Edit-Job-Form"
        showModal={showModal}
        setshowModal={setshowModal}
        locumRolesOptions={locumRolesOptions}
        branchOptions={branchOptions}
        editProps={{
          role: shiftInfo?.roleType?.label,
          branchName: shiftInfo?.branchTitle,
          startDate: shiftInfo.startDate,
          endDate: shiftInfo.endDate,
          description: shiftInfo?.description,
          paidLunch: shiftInfo?.paidLunch,
          mileageRule: shiftInfo?.mileageRule,
          accommodationAndFoodRule: shiftInfo?.accommodationAndFoodRule,
          parkingRule: shiftInfo?.parkingRule,
          transportRule: shiftInfo?.transportRule,
        }}
        gapData={{
          breakInterval:
            shiftInfo?.lunchStartTime?.length > 0 &&
            shiftInfo?.lunchEndTime?.length > 0
              ? `${shiftInfo?.lunchStartTime}-${shiftInfo?.lunchEndTime}`
              : null,
          shiftInterval: `${shiftInfo?.startTime}-${shiftInfo?.endTime}`,
          hourlyRate: shiftInfo.hourlyRate,
        }}
      />
    );
  }

  return (
    <AddModal
      title={
        <Space align="center">
          <Typography.Title level={4}>Job Details</Typography.Title>{" "}
          <LocumJobStatusChip status={status} />
        </Space>
      }
      handleModal={showModal}
      setHandleModal={setshowModal}
      primaryButtonText={primaryAndSecondaryBtnTxt?.primaryBtnTxt}
      secondaryButtonText={primaryAndSecondaryBtnTxt?.secondaryBtnText}
      secondaryButtonHandler={setshowModal}
      primaryButtonHandler={() => handleUpdateJobStatus(id, shiftInfo?.status)}
      showDeleteButton={visibleSections.isDeleteButton}
      showEditButton={visibleSections.isEditButton}
      editButtonHandler={() => setIsEditor(true)}
      deleteButtonHandler={handleDeleteModal}
    >
      <div className="locum-job-detail-modal">
        <Space>
          <figure className="pharma-img-wrap ">
            <img src={PharmaImg} alt="" />
          </figure>
          <div className="pharma_info">
            <Typography.Title level={5}>
              {shiftInfo?.pharmacyName}
            </Typography.Title>
            <Typography.Text>
              <PharmaIcon /> {shiftInfo?.branchTitle}
            </Typography.Text>
          </div>
        </Space>

        <Row gutter={[16, 16]} className="jobinfo-details-row">
          <Col span={12}>
            <div className="jobdetailsinfo-cols">
              <CalendarFilled /> <p>Date</p>
              <Typography.Text>{shiftInfo.startDate}</Typography.Text>
            </div>
          </Col>
          <Col span={12}>
            <div className="jobdetailsinfo-cols">
              <Peopleicon /> <p>Role</p>
              <Typography.Text>{shiftInfo?.roleType?.label}</Typography.Text>
            </div>
          </Col>
          <Col span={12}>
            <div className="jobdetailsinfo-cols">
              <YellowClock color={"#9D9D9D"} /> <p>Hourly Rate</p>
              <Typography.Text>£{shiftInfo.hourlyRate}</Typography.Text>
            </div>
          </Col>
          <Col span={12}>
            <div className="jobdetailsinfo-cols">
              <MoneyIcon /> <p>Total Amount</p>
              <Typography.Text>£{shiftInfo.total}</Typography.Text>
            </div>
          </Col>
          <Col span={24}>
            <div className="jobdetailsinfo-cols">
              <YellowClock color={"#9D9D9D"} /> <p>Total hours</p>
              <Typography.Text>
                {" "}
                {moment(shiftInfo?.endTime, "HH:mm")?.diff(
                  moment(shiftInfo?.startTime, "HH:mm"),
                  "hours"
                )}{" "}
                hrs
              </Typography.Text>
            </div>
          </Col>
          <Col span={24}>
            <div className="jobdetailsinfo-cols">
              <YellowClock color={"#9D9D9D"} /> <p>Check in/out</p>
              <Typography.Text>
                {`${shiftInfo.startTime} - ${shiftInfo.endTime}`}{" "}
              </Typography.Text>
            </div>
          </Col>
        </Row>

        {/* shift_timing */}
        <ul className="shift_timing_wrapper">
          <li>
            <div className="shift_times">
              <p className="shift_no">Shift: 1</p>
              <div className="d-flex align-items-center duration">
                <p className="mb-0">
                  {moment(shiftInfo?.startTime, "HH:mm").format("hh:mm A")}
                </p>
                <img src={RightArrow} alt="" />
                <p className="mb-0">
                  {moment(shiftInfo?.endTime, "HH:mm").format("hh:mm A")}
                </p>
              </div>

              <div className="break_time">
                <span className="badge rounded-pill bg-light text-dark">
                  Break :{" "}
                  <b>
                    {shiftInfo?.lunchEndTime?.length > 0 &&
                    shiftInfo?.lunchStartTime?.length > 0 ? (
                      <>
                        {" "}
                        {moment(shiftInfo?.lunchEndTime, "HH:mm")?.diff(
                          moment(shiftInfo?.lunchStartTime, "HH:mm"),
                          "hours"
                        )}{" "}
                        h
                      </>
                    ) : (
                      "---"
                    )}
                  </b>
                </span>
              </div>
            </div>
          </li>
        </ul>

        {/* cover-expance */}
        {visibleSections.hasCoverExpense && (
          <div className="cover-expence-info">
            <Typography.Title level={5}>Cover Expenses</Typography.Title>

            {shiftInfo?.mileageRule && (
              <MillageRule rule={shiftInfo?.mileageRule} />
            )}

            {shiftInfo?.accommodationAndFoodRule && (
              <AccommodationAndFoodRule
                rule={shiftInfo?.accommodationAndFoodRule}
              />
            )}

            {shiftInfo?.parkingRule && (
              <ParkingRule rule={shiftInfo?.parkingRule} />
            )}

            {shiftInfo?.transportRule && (
              <TransportRule rule={shiftInfo?.transportRule} />
            )}
          </div>
        )}

        {/* description */}
        <div className="description-info">
          <Typography.Text className="heading">Description</Typography.Text>

          <div className="desc-box">
            <p>{shiftInfo.description}</p>
          </div>
        </div>

        {visibleSections.isCancellationReason && (
          <div className="cancel-reason">
            <div className="jobdetailsinfo-cols">
              <CloseIconFilled /> <p>Cancellation Reason</p>
              <Typography.Text>{shiftInfo?.cancelled_reason}</Typography.Text>
            </div>
            {shiftInfo?.cancelled_note && (
              <div className="description-info">
                <Typography.Text className="heading">Notes</Typography.Text>

                <div className="desc-box">
                  <p>{shiftInfo?.cancelled_note}</p>
                </div>
              </div>
            )}
          </div>
        )}

        <div className="p-3 border rounded ">
          <Typography.Title level={5}>Invite Locum</Typography.Title>

          <div className="p3">
            You can invite any locum from your current locum bank to apply for
            this particular Job.
          </div>
          <div className="mt-3" style={{ width: "100%" }}>
            <Select
              style={{ width: "100%" }}
              bordered={false}
              showSearch
              placeholder="Locum"
              value={null}
              optionFilterProp="children.props.children"
              filterOption={(inputValue, option) => {
                return option?.children?.props?.children
                  ?.join("")
                  ?.toLowerCase()
                  ?.includes(inputValue?.toLowerCase());
              }}
              onChange={onInviveLocum}
              className="text-input-field p-1 t1"
            >
              {workedLocumsList?.map((data, idx) => (
                <Select.Option value={data.id} key={idx}>
                  <div className="d-flex align-items-center gap-3">
                    {data.image ? (
                      <img
                        src={data.image}
                        alt=""
                        style={{
                          cursor: "pointer",
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                          border: "1px solid #F6F2F",
                          position: "relative",
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          width: 25,
                          height: 25,
                          borderRadius: "50%",
                          background: "#E1F4F4",
                          fontSize: "10px",
                          color: " #065A4F",
                          textAlign: "center",
                          lineHeight: "25px",
                          padding: 1,
                        }}
                      >
                        {`${data.name} ${data.surname}`
                          ?.split(" ")
                          ?.map((d, idx) =>
                            idx < 2 ? d?.charAt(0).toUpperCase() : null
                          )
                          .join(" ")}
                      </div>
                    )}
                    {data.name} {data.surname}
                  </div>
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>

        {visibleSections.isAssignedLocum && (
          <AssignedlocumCard
            jobId={id}
            staffDetails={shiftInfo.staff}
            handleAcceptJobRequest={handleAcceptJobRequest}
            handleRejectJobRequest={handleRejectJobRequest}
          />
        )}

        {visibleSections.isGiveRating && (
          <div className="assign-locum-cards mt-4">
            <GiveRatingCard handleRating={handleRating} />
          </div>
        )}
      </div>

      <ConfirmModal
        open={showDeleteModal}
        onClose={() => {
          handleDeleteModal();
          form.resetFields();
        }}
        title="Cancel Job"
        cancelButtonText="Keep Job"
        confirmButtonText="Cancel Job"
        formId={formId}
      >
        <Typography.Text className="confirm-delete">
          Are you sure you want to cancel this job for{" "}
          {shiftInfo?.roleType?.label} on {shiftInfo.startDate}?
        </Typography.Text>

        <Form
          form={form}
          id={formId}
          layout="vertical"
          autoComplete="off"
          onFinish={(values) => {
            onCancelJob(values);
            handleDeleteModal();
            setshowModal();
          }}
          className="add_shift_form"
        >
          <Form.Item
            label="Cancel Reason"
            name="cancelReason"
            rules={[
              { required: true, message: "Please Select Cancel Reason!" },
            ]}
            className=" mt-3 not-required"
          >
            <Select
              // disabled={gapData}
              className="text-input-field p-1 pe-3 t1"
              bordered={false}
              // onChange={(value) => {
              //   form.setFieldsValue({
              //     roleId: value,
              //     staffId: undefined,
              //     hasExternalLocum: false,
              //   });
              // }}
              dropdownStyle={{ zIndex: 2000 }}
              placeholder="Cancel Reason"
              suffixIcon={<img src={DropDown} alt="dropdown" />}
            >
              {cancelReasons.map((data, idx) => (
                <Select.Option key={idx} value={data.key}>
                  {data.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="cancelled_note"
            label="Note"
            className="mt-3 not-required"
            rules={[
              {
                required: true,
                message: "You'll Have To Enter A Description To Proceed!",
              },
            ]}
          >
            <Input.TextArea
              className="px-3 py-2 t1"
              rows={4}
              onBlur={(e) =>
                form.setFieldsValue({ cancelled_note: e.target.value.trim() })
              }
              maxLength={256}
              placeholder="Write any notes here..."
            />
          </Form.Item>
        </Form>
      </ConfirmModal>
    </AddModal>
  );
};

export default LocumJobDetailsModal;
