import { Avatar, Button, Tag } from "antd";
import React from "react";
import CloseIcon from "../../assets/icons/close.svg";
const DisPlayTags = ({ data, keyName, onDelete }) => (
  <div className="d-flex align-items-center">
    {data?.map((item, index) => (
      <Tag
        style={{
          marginRight: 3,
        }}
        className="select-tag"
        key={item?.id}
      >
        <Avatar
          src={item?.logo}
          alt={item[keyName]}
          className="me-2"
          size={26}
        />

        <p className="tag-label">{item[keyName]}</p>
        <Button onClick={() => onDelete(index)}>
          <img src={CloseIcon} alt="cross" width={12} />
        </Button>
      </Tag>
    ))}
  </div>
);

export default DisPlayTags;
