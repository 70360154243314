import { API } from '../../../axios';

export const getLocumShiftsList = (payload) => {
  const { filters, pagination } = payload;
  const request = API.post(`/jobs/list`, {
    filters,
    pagination,
  });
  return request;
};

export const getLocumShiftInfo = (payload) => {
  const { shiftId } = payload;
  const request = API.get(`/jobs/${shiftId}`);
  return request;
};
export const updateJobStatus = (payload) => {
  const { shiftId, status } = payload;
  const request = API.post(`/jobs/${shiftId}/status`, {
    status,
  });
  return request;
};
export const ratingShift = (payload) => {
  const { jobId, value } = payload;
  const request = API.post(`/locums/rating`, {
    value,
    jobId,
  });
  return request;
};
export const addJobRating = (payload) => {
  const request = API.post(`/locums/rating`, payload);
  return request;
};

export const getWorkedLocum = (payload) => {
  const { roleId } = payload;
  const request = API.get(`staff/worked-locums/${roleId}`);
  return request;
};

export const inviteLocumForJob = (payload) => {
  const { jobId, locumId } = payload;
  const request = API.post(`jobs/${jobId}/invite/${locumId}`);
  return request;
};

export const declineJobInvite = (payload) => {
  const { jobId, locumId } = payload;
  const request = API.post(`jobs/${jobId}/decline-invite/${locumId}`);
  return request;
};

export const acceptJobRequest = (payload) => {
  const { jobId, locumId } = payload;
  const request = API.post(`jobs/${jobId}/approve/${locumId}`);
  return request;
};

export const rejectJobRequest = (payload) => {
  const { jobId, locumId } = payload;
  const request = API.post(`jobs/${jobId}/reject/${locumId}`);
  return request;
};

export const cancelJob = (payload) => {
  const { jobId, cancelData } = payload;
  const request = API.post(`jobs/${jobId}/cancel`, { cancelData });
  return request;
};

export const updateMileage = (payload) => {
  const { jobId, formData } = payload;
  const request = API.post(`jobs/${jobId}/update-mileage-value`, formData);
  return request;
};

export const updateJob = (payload) => {
  const { jobId, formData } = payload;
  const request = API.post(`jobs/${jobId}`, formData);
  return request;
};

export const downloadJobsCSV = (payload) => {
  const { formData } = payload;
  const request = API.post(`jobs/csv-download`, formData);
  return request;
};

export const getJobCancelReasons = () => {
  const request = API.get(`jobs/cancellation-reasons`);
  return request;
};
