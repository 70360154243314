import React from "react";
import "./style.scss";
import DatePicker from "./datePicker/DatePicker";
import { Button, Dropdown, Input, Select } from "antd";
import { useSelector } from "react-redux";
import search from "../../../assets/icons/search.png";
import "./style.scss";

export const rolesColor = {
  '4': "#FF9200",
  '5': "#FFC500",
  '6': "#F67280",
  '7': "#1F87E5",
  '8': "#581845",
  '9': "#43A046",
  '10': "#68D9D3",
};

export const jobesOptions = [
  { value: "", label: "All Shifts & Jobs", color: "" },
  { value: "unfilled", label: "Posted" },
  { value: "pending", label: "Pending" },
];

const options = [
  { value: "monthly", label: "Month" },
  { value: "weekly", label: "Week" },
  { value: "daily", label: "Day" },
];

export default function Toolbar({
  setFlag,
  setFilterValue,
  filterValues,
  searchPlaceholder,
  showDatePicker,
  handleViewChange,
  view,
  mode,
  allViews,
  dateRange,
  showSearchField,
  primaryText,
  showJobFilter,
  showRegionFilter,
  showRolesFilter,
  showViewFilter,
  showActionBtn,
  actionItems,
  publishTemplateHandler,
  editTimeBtn,
  editTimeBtnHandler,
  cancelBtnHandler,
  cancelBtn,
  onDateChange,
  deleteShiftBtn,
  deleteShiftHandler,
  showPreviewBtnHandler
}) {
  const { roles } = useSelector((state) => {
    return state.schedule;
  });
  const { regionsListDropDown } = useSelector((state) => {
    return state.regions;
  });
  const SearchIcon = () => {
    return (
      
        <img src={search} alt="" />
      
    );
  };

  return (
    <div
      className="d-flex align-items-center justify-content-between filter-wrapper"
      style={{
        height: "80px",
        padding: `${mode === "monthly" ? "0px" : " 0px 24px"}`,
      }}
    >
      <div style={{ width: "fit-content", minWidth: "330px", height: "40px" }}>
        {primaryText && <div className="heading4 fw-bold">{primaryText}</div>}

        {showSearchField && (
          <Input.Search
            prefix={<SearchIcon />}
            className="searchField"
            enterButton={false}
            placeholder={searchPlaceholder}

            onChange={(e) => {
              if (e.target.value === "") {
                setFlag(true);
                setFilterValue({
                  ...filterValues,
                  branch: "",
                });
              }
            }}
            onSearch={(value) => {
              setFlag(true);
              setFilterValue({
                ...filterValues,
                branch: value.trim(),
              });
            }}
          />
        )}
      </div>
      <div
        className="d-flex align-items-center justify-content-between right-side-container"
        style={{ gap: 15 }}
      >
        {
          deleteShiftBtn &&
          <Button onClick={deleteShiftHandler} className="btn-style p-2 px-4" type="primary">Shift Delete</Button>
        }
        {
          cancelBtn &&
          <Button onClick={cancelBtnHandler} className="btn-style p-2 px-4">Cancel</Button>
        }
        {
          editTimeBtn &&
          <Button onClick={editTimeBtnHandler} className="btn-style p-2 px-4" type="primary">Edit Time</Button>
        }
        {
          showJobFilter &&
          <Select
            className="text-input-field rounded border p-1 pe-3 t1 bg-white"
            bordered={false}
            defaultValue=""
            dropdownStyle={{ zIndex: 2000 }}
            options={jobesOptions}
            onChange={(value) => {
              setFlag(true);
              setFilterValue({
                ...filterValues,
                status: value,
              });
            }}
          />
        }
        {showRolesFilter && (
          <Select
            defaultValue=""
            className="text-input-field rounded border p-1 pe-3 t1 bg-white"
            bordered={false}
            dropdownStyle={{ zIndex: 2000 }}
            onChange={(value) => {
              setFlag(true);
              setFilterValue({
                ...filterValues,
                roleId: value,
              });
            }}
          >
            <Select.Option value="">All Roles</Select.Option>
            {roles?.map((data, idx) => (
              <Select.Option value={data.id} key={idx}>
                <div className="d-flex align-items-center gap-2">
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: "50%",
                      background: rolesColor[data.id],
                    }}
                  ></div>
                  {data.label}
                </div>
              </Select.Option>
            ))}
          </Select>
        )}
        {showRegionFilter && (
          <Select
            placeholder="Select Regions"
            defaultValue=""
            className="text-input-field rounded border p-1 pe-3 t1 bg-white"
            bordered={false}
            dropdownStyle={{ zIndex: 2000 }}
            onChange={(value) => {
              setFlag(true);
              setFilterValue({
                ...filterValues,
                regions: value ? [value] : [],
              });
            }}
          >
            <Select.Option value="">All Regions</Select.Option>
            {regionsListDropDown?.map((data, idx) => (
              <Select.Option value={data.title} key={idx}>
                {data.title}
              </Select.Option>
            ))}
          </Select>
        )}
        {
          showDatePicker &&
          <DatePicker
            allViews={allViews}
            dateRange={dateRange}
            view={view}
            onDateChange={onDateChange}
          />
        }
        {
          showViewFilter &&
          <Select
            className="text-input-field rounded border p-1 pe-3 t1 bg-white"
            bordered={false}
            value={view}
            dropdownStyle={{ zIndex: 2000 }}
            defaultValue={mode}
            options={options?.filter(
              (item) => {
                if (allViews) {
                  return item
                }
                return item.value !== `${mode === "monthly" ? "weekly" : "monthly"}`
              }
            )}
            onChange={handleViewChange}
          />
        }
        {
          showActionBtn &&
          <Dropdown
            getPopupContainer={(trigger) => trigger.parentElement}
            placement="bottomRight"
            overlayStyle={{ zIndex: 2000 }}
            menu={{ items: actionItems }}
            trigger={['click']}
          >

            <div className="action-btn">
              <Button > Action</Button>
            </div>
          </Dropdown>
        }
        {
          showPreviewBtnHandler &&
          <Button className="btn-style" type="primary" onClick={showPreviewBtnHandler}>Template Preview</Button>
        }
        {
          publishTemplateHandler &&
          <Button className="btn-style" type="primary" onClick={publishTemplateHandler}>Publish</Button>
        }
      </div>
    </div>
  );
}
