import { Navigate, Route, Routes } from "react-router-dom";
import { ProfileDetail } from "./details";
import { ProfileInformation } from "./information";

export const ProfilePage = () => {
  return (
    <Routes>
      <Route path="/*" element={<ProfileDetail />}>
        <Route path="information" element={<ProfileInformation />} />
      </Route>
    </Routes>
  );
};
