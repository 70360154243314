import _ from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { getAllNotificationThunk, getNotificationByIdThunk } from "../../redux/locum-flow/Thunk";
import { EmptyData } from "../comon/EmptyData";

const NotificatioBar = ({ className, content, date, onNotificationClick }) => {
    const _content = JSON.parse(content)
    const altText = useMemo(() => {
        return _content?.notification.pharmacyName?.split(' ')?.map((d, idx) => idx < 2 ? d?.charAt(0).toUpperCase() : null).join('')
    }, [_content])
    return (
        <div onClick={onNotificationClick} className={`${className} cursor-pointer`}>
            {
                _content.notification.image ?
                    <img className="border rounded-circle" width={50} height={50} src={_content.notification.image} alt="avatar" />
                    :
                    <div
                        style={{
                            width: 50,
                            height: 50,
                            borderRadius: '50%',
                            background: '#E1F4F4',
                            fontSize: 35,
                            color: ' #065A4F',
                            textAlign: 'center',
                            lineHeight: '50px',
                            margin: '20px 0'
                        }}
                    >
                        {altText}
                    </div>
            }
            {/* <img width={50} src={_content.notification.image} alt=" " /> */}
            <div
                className="d-flex flex-column justify-content-center"
                style={{ isolation: "isolate", gap: "5px" }}
            >
                <span className="p3" style={{ color: "#434343" }}>
                    {_content.notification.body}
                </span>
                <div className="w-100 d-flex align-items-center justify-content-between">
                    <div className="t2" style={{ color: "#7B7B7B" }}>
                        {_content.notification.pharmacyName}
                    </div>
                    <span className="t2" style={{ color: "#7B7B7B" }}>
                        {moment(date).format('HH:mm')}
                    </span>
                </div>
            </div>
        </div>
    );
};

export const NotificationContent = ({
    isNotification,
    setNotification
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { notifications } = useSelector(state => state.locum)
    const [notificationData, setNotificationData] = useState([])

    const onNotificationClick = (data) => {
        setNotification(false)
        dispatch(getNotificationByIdThunk({
            id: data.id
        })).then(res => {
            if (res.payload.sourceType === "absence") {
                const { data } = JSON.parse(res.payload.content)
                const startDate = moment(data.startDate).format('YYYY-MM-DD')
                const endDate = moment(data.endDate).format('YYYY-MM-DD')
                navigate(`/leaves?startDate=${startDate}&endDate=${endDate}`)
                return
            }
            if (res.payload.sourceType === "shift") {
                const { data } = JSON.parse(res.payload.content)
                const startDate = moment(data.startDate).format('YYYY-MM-DD')
                navigate(`/schedule?date=${startDate}`)
                return
            }
            if (res.payload.sourceType === "contract") {
                navigate(generatePath(`/contract`))
                return
            }
            if (res.payload.sourceType === "accreditation") {
                navigate(`/information?accred=true`)
                return
            }
            if (res.payload.sourceType === "document") {
                navigate(`/verifications`)
                return
            }
            if (res.payload.sourceType === "job") {
                const { data } = JSON.parse(res.payload.content)
                if (data.notificationType === "alert") {
                    navigate(`/locum-market/?alert=true`)
                    return
                }
                if (data.notificationType === "reminder") {
                    navigate(generatePath(`/locum-market/${data.job ?? data.jobId}`))
                    return
                }
                if (data.notificationType === "update") {
                    navigate(generatePath(`/locum-market/${data.job ?? data.jobId}`))
                    return
                }
                navigate(generatePath(`/locum-market/${data.job ?? data.jobId}`))
                return
            }
        })
    }

    const {
        page,
        pages: totalPagesCount = 1,
    } = useMemo(() => notifications, [notifications]);

    const checkCommonObject = useCallback((nextPageData) => {
        const nextPage = [...nextPageData]
        var notificationList = _.cloneDeep(notificationData);
        let lastNotificationDataOfPreviousPage =
            notificationList[notificationList?.length - 1];
        const firstNotificationDataOfNextPage = nextPage[0];
        if (
            lastNotificationDataOfPreviousPage.title ===
            firstNotificationDataOfNextPage.title
        ) {
            lastNotificationDataOfPreviousPage.data = [
                ...lastNotificationDataOfPreviousPage?.data,
                ...firstNotificationDataOfNextPage?.data,
            ];
            nextPage.shift();
        }
        setNotificationData([...notificationList, ...nextPage]);
    }, [notificationData])

    const getAllNotifications = useCallback((page) => {
        dispatch(getAllNotificationThunk({
            pagination: { page, perPage: 10 },
        })).then(res => {
            if (res.payload.page === 1) {
                setNotificationData(res.payload.rows);
            } else {
                checkCommonObject(res.payload.rows);
            }

        })
    }, [dispatch, checkCommonObject])

    useEffect(() => {
        if (!isNotification) return
        getAllNotifications(1)
    }, [isNotification])

    const trackScrolling = (event) => {
        const target = event.target
        if (Math.floor(target.clientHeight) === Math.floor(target.scrollHeight - target.scrollTop) || Math.floor(target.clientHeight) === Math.floor(target.scrollHeight - target.scrollTop + 1)) {
            if (page + 1 <= totalPagesCount) {
                getAllNotifications(page + 1)
            }
        }
    }

    useEffect(() => {
        document.addEventListener("scroll", trackScrolling);
        return () => {
            document.removeEventListener("scroll", trackScrolling);
        };
    }, []);

    return (
        <div className="notificationContainer" >
            <div className="w-100 d-flex align-items-center justify-content-between">
                <span className="b1" style={{ color: "#000000" }}>
                    Notifications
                </span>
            </div>
            <div onScroll={trackScrolling} className="inner-container-wrapper">
                {
                    notificationData?.length ? notificationData?.map((item, idx) => (
                        <div key={idx} className="notification-inner-container my-3">
                            <span className="s1">{item?.title} </span>
                            {item?.data.map((value, i) => {
                                return (
                                    <NotificatioBar
                                        key={i}
                                        onNotificationClick={() => onNotificationClick(value)}
                                        content={value?.content}
                                        date={value?.createdAt}
                                        className={
                                            value?.status !== "Read" ? "unreaded-notification" : "readed-notification"
                                        }
                                    />
                                );
                            })}
                        </div>
                    ))
                        :
                        <EmptyData title="Notifications" />
                }
            </div>
        </div>
    )
}
