import React from "react";

const CloseIconFilled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.99935 1.66797C5.40768 1.66797 1.66602 5.40964 1.66602 10.0013C1.66602 14.593 5.40768 18.3346 9.99935 18.3346C14.591 18.3346 18.3327 14.593 18.3327 10.0013C18.3327 5.40964 14.591 1.66797 9.99935 1.66797ZM12.7993 11.918C13.041 12.1596 13.041 12.5596 12.7993 12.8013C12.6743 12.9263 12.516 12.9846 12.3577 12.9846C12.1993 12.9846 12.041 12.9263 11.916 12.8013L9.99935 10.8846L8.08268 12.8013C7.95768 12.9263 7.79935 12.9846 7.64102 12.9846C7.48268 12.9846 7.32435 12.9263 7.19935 12.8013C6.95768 12.5596 6.95768 12.1596 7.19935 11.918L9.11602 10.0013L7.19935 8.08464C6.95768 7.84297 6.95768 7.44297 7.19935 7.2013C7.44102 6.95964 7.84102 6.95964 8.08268 7.2013L9.99935 9.11797L11.916 7.2013C12.1577 6.95964 12.5577 6.95964 12.7993 7.2013C13.041 7.44297 13.041 7.84297 12.7993 8.08464L10.8827 10.0013L12.7993 11.918Z"
        fill="#D32F2F"
      />
    </svg>
  );
};

export default CloseIconFilled;
