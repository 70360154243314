import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createJobBulk,
  createShift,
  createShiftBulk,
  deleteBulkShift,
  deleteJob,
  deleteShift,
  editJob,
  editShift,
  getRoles,
  getScheduleList,
  getStaffs,
  searchLocumByFilter,
} from "../scheduleAPI";

export const getScheduleListThunk = createAsyncThunk(
  "get/schedules",
  async (payload) => {
    const request = await getScheduleList(payload);
    return request.data.data;
  }
);

export const getRolesThunk = createAsyncThunk("getRoles", async (payload) => {
  const request = await getRoles(payload);
  return request.data.data;
});

export const getStaffsThunk = createAsyncThunk("getStaffs", async () => {
  const request = await getStaffs();
  return request.data.data;
});

export const createShiftBulkThunk = createAsyncThunk(
  "createShiftBulk",
  async (payload) => {
    const request = await createShiftBulk(payload);
    return request.data.data;
  }
);

export const createJobBulkThunk = createAsyncThunk(
  "createJobBulk",
  async (payload) => {
    const request = await createJobBulk(payload);
    return request.data.data;
  }
);

export const createShiftThunk = createAsyncThunk(
  "createShift",
  async (payload) => {
    const request = await createShift(payload);
    return request.data;
  }
);

export const searchLocumByFilterThunk = createAsyncThunk(
  "searchLocumByFilter",
  async (payload) => {
    const request = await searchLocumByFilter(payload);
    return request.data.data;
  }
);

export const deleteJobThunk = createAsyncThunk("deleteJob", async (payload) => {
  const request = await deleteJob(payload);
  return request.data.data;
});

export const deleteShiftThunk = createAsyncThunk(
  "deleteShift",
  async (payload) => {
    const request = await deleteShift(payload);
    return request.data.data;
  }
);

export const editJobThunk = createAsyncThunk("deleteJob", async (payload) => {
  const request = await editJob(payload);
  return request.data.data;
});

export const editShiftThunk = createAsyncThunk("editShift", async (payload) => {
  const request = await editShift(payload);
  return request.data.data;
});

export const deleteBulkShiftThunk = createAsyncThunk(
  "deleteBulkShift",
  async (payload) => {
    const request = await deleteBulkShift(payload);
    return request.data.data;
  }
);
