import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export { PrivateRoute };

function PrivateRoute({ children, moduleKey, displayTabs }) {
  const { accessibleModules: modules } = useSelector((state) => state.auth);
  const isAccessible = () => {
    let flag = false;
    let modulesArray = Object.values(modules);

    for (let module of modulesArray) {
      if (moduleKey === module.moduleKey) {
        flag = true;
        break;
      }
    }
    return flag;
  };


  if (!isAccessible()) {
    // not logged in so redirect to login page with the return url
    return <Navigate to={displayTabs[0]?.to} />;
  }

  // authorized so return child components
  return children;
}
