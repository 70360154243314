import { API } from "../../../axios"



export const getPharmacyList = (payload) => {
  const { filters, pagination } = payload;

  const request = API.post(`/admin/pharmacy/list`, {
    filters,
    pagination,
  });;
  return request
}

export const getUserList = (payload) => {
  const { filters, pagination } = payload;

  const request = API.post(`/admin/users/list`, {
    filters,
    pagination,
  });;
  return request
}


export const getjobOffersList = (payload) => {
  const { filters, pagination } = payload;

  const request = API.post(`/admin/jobs/list`, {
    filters,
    pagination,
  });;
  return request
}
export const getbillingList = (payload) => {
  const { filters, pagination } = payload;

  const request = API.post(`/admin/invoices/list`, {
    filters,
    pagination,
  });;
  return request
}

export const updateZohoReportEmail = (payload) => {
  const request = API.post(`admin/invoices/update-zoho-report-email`,
    payload
  );
  return request
}

export const getPharmacyInformation = (payload) => {

  const request = API.get(`/admin/pharmacy/${payload}`);
  return request
}

export const getPharmacyBranchesList = (payload) => {
  const { filters, pagination, id } = payload;
  const request = API.post(`/admin/pharmacy/${id}/branches/list`, {
    filters,
    pagination,
  });
  return request
}

export const getPharmacyStaffList = (payload) => {
  const { pagination, id } = payload;

  const request = API.post(`/admin/pharmacy/${id}/staff/list`, {
    pagination,
  });
  return request
}

export const getPharmacyJobList = (payload) => {
  const { filters, pagination, id } = payload;

  const request = API.post(`/admin/pharmacy/${id}/jobs/list`, {
    filters,
    pagination,
  });
  return request
}


export const getPharmacySubscription = (id) => {

  const request = API.get(`/admin/pharmacy/${id}/get-subscription`)
  return request
}

export const activatePharmacy = (id) => {
  const request = API.post(`/admin/pharmacy/${id}/activate`)
  return request

}

export const deactivatePharmacy = (id) => {
  const request = API.post(`/admin/pharmacy/${id}/deactivate`)
  return request

}

export const approveUser = (id) => {
  const request = API.post(`/admin/users/${id}/approve`)
  return request
}

export const rejectUser = (id) => {
  const request = API.post(`/admin/users/${id}/reject`)
  return request
}

export const activateUser = (id) => {
  const request = API.post(`/admin/users/${id}/activate`)
  return request
}


export const deactivateUser = (id) => {
  const request = API.post(`/admin/users/${id}/deactivate`)
  return request
}

export const getUserData = (payload) => {
  const { staffId } = payload
  const request = API.get(`/admin/users/${staffId}`)
  return request
}