import React from "react";

const UserHexagon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M19.5861 6.35L13.6461 2.92C12.6761 2.36 11.4761 2.36 10.4961 2.92L4.56609 6.35C3.59609 6.91 2.99609 7.95 2.99609 9.08V15.92C2.99609 17.04 3.59609 18.08 4.56609 18.65L10.5061 22.08C11.4761 22.64 12.6761 22.64 13.6561 22.08L19.5961 18.65C20.5661 18.09 21.1661 17.05 21.1661 15.92V9.08C21.1561 7.95 20.5561 6.92 19.5861 6.35ZM12.0761 7.84C13.3661 7.84 14.4061 8.88 14.4061 10.17C14.4061 11.46 13.3661 12.5 12.0761 12.5C10.7861 12.5 9.74609 11.46 9.74609 10.17C9.74609 8.89 10.7861 7.84 12.0761 7.84ZM14.7561 17.16H9.39609C8.58609 17.16 8.11609 16.26 8.56609 15.59C9.24609 14.58 10.5661 13.9 12.0761 13.9C13.5861 13.9 14.9061 14.58 15.5861 15.59C16.0361 16.25 15.5561 17.16 14.7561 17.16Z"
        fill="#78828A"
      />
    </svg>
  );
};

export default UserHexagon;
