import React, { useMemo, useState } from "react"
import startRatingIcon from '../../assets/icons/star-rating.png'
import acceptIcon from '../../assets/icons/accept.png'
import rejectIcon from '../../assets/icons/reject.png'
import { ConfirmModal } from "../UI/modals/confirmModal"
import { hasAccess } from "../../utils/helper"
import { locumShiftApiKeys } from "../../utils/apiKeyConstants"
import { Button, Select } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { acceptJobRequestThunk, declineJobInviteThunk, inviteLocumForJobThunk, rejectJobRequestThunk } from "../../redux/locum-shifts/locumShiftsThunk"
import { useNavigate, useParams } from "react-router-dom"
import moment from "moment"

export const LocumsRequestsTab = ({
    setFlag
}) => {
    const { shiftId } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { shiftInfo, workedLocumsList } = useSelector(
        (state) => state.locumShifts
    );
    const [negotiableLocum, setNegotiableLocum] = useState();
    const [showNegotiableModal, setShowNegotiableModal] = useState(false);

    const totalHours = useMemo(() => {
        var startTime = moment(shiftInfo?.startTime, "hh:mm:ss a");
        var endTime = moment(shiftInfo?.endTime, "hh:mm:ss a");

        var mins = moment.utc(moment(endTime, "HH:mm:ss").diff(moment(startTime, "HH:mm:ss"))).format("mm")

        return endTime.diff(startTime, 'hours') + "h " + mins + "m";
    }, [shiftInfo])

    const onSelectLocum = (value) => {
        dispatch(
            inviteLocumForJobThunk({
                jobId: shiftId,
                locumId: value,
            })
        ).then(res => {
            if (res.payload) {
                window.analytics.track('Invite Locum', {
                    title: shiftInfo?.branchTitle,
                    date: shiftInfo?.startDate,
                    time: `${shiftInfo?.startTime} - ${shiftInfo?.endTime}`,
                    duration: totalHours,
                    totalFee: shiftInfo?.total,
                });
            }
        })
    }

    const onRemoveInvite = (locumId) => {
        dispatch(
            declineJobInviteThunk({
                jobId: shiftId,
                locumId,
            })
        );
    };

    const onAcceptJobRequest = (locumId) => {
        dispatch(
            acceptJobRequestThunk({
                jobId: shiftId,
                locumId,
            })
        ).then((res) => {
            if (res.payload) {
                window.analytics.track('Job Accepted', {
                    title: shiftInfo?.branchTitle,
                    date: shiftInfo?.startDate,
                    time: `${shiftInfo?.startTime} - ${shiftInfo?.endTime}`,
                    duration: totalHours,
                    totalFee: shiftInfo?.total,
                });
                setFlag(true)
                setShowNegotiableModal(false);
                setNegotiableLocum(undefined);
            }
        });
    };

    const onRejectJobRequest = (locumId) => {
        dispatch(
            rejectJobRequestThunk({
                jobId: shiftId,
                locumId,
            })
        ).then((res) => {
            setShowNegotiableModal(false);
            setNegotiableLocum(undefined);
        });
    };

    return (
        <>
            <div className="d-flex flex-column" style={{ height: '100%', gap: 20, width: '30%' }}>
                <div className="d-flex justify-content-between" style={{ height: 40 }}>
                    <div className="heading4">Locums</div>
                </div>
                <div className="p-3 border rounded">
                    <div className="s1">Invite Locum</div>
                    <div className="p3">
                        You can invite any locum from your current locum bank to apply for this particular Job.
                    </div>
                    <div className="mt-3" style={{ width: '100%' }}>
                        <Select
                            style={{ width: '100%' }}
                            bordered={false}
                            showSearch
                            placeholder="Locum"
                            value={null}
                            optionFilterProp="children.props.children"
                            filterOption={(inputValue, option) => {
                                return option?.children?.props?.children?.join('')?.toLowerCase()?.includes(inputValue?.toLowerCase())
                            }
                            }
                            onChange={onSelectLocum}
                            className="text-input-field p-1 t1"
                        >
                            {
                                workedLocumsList?.map((data, idx) => (
                                    <Select.Option value={data.id} key={idx}>
                                        <div className="d-flex align-items-center gap-3">
                                            {
                                                data.image ?
                                                    <img
                                                        src={data.image}
                                                        alt=""
                                                        style={{
                                                            cursor: "pointer",
                                                            width: "25px",
                                                            height: "25px",
                                                            borderRadius: "50%",
                                                            border: "1px solid #F6F2F",
                                                            position: "relative",
                                                        }}
                                                    />
                                                    :
                                                    <div
                                                        style={{
                                                            width: 25,
                                                            height: 25,
                                                            borderRadius: '50%',
                                                            background: '#E1F4F4',
                                                            fontSize: '10px',
                                                            color: ' #065A4F',
                                                            textAlign: 'center',
                                                            lineHeight: '25px',
                                                            padding: 1
                                                        }}
                                                    >
                                                        {`${data.name} ${data.surname}`?.split(' ')?.map((d, idx) => idx < 2 ? d?.charAt(0).toUpperCase() : null).join(' ')}
                                                    </div>
                                            }
                                            {data.name} {data.surname}
                                        </div>
                                    </Select.Option>
                                ))
                            }
                        </Select>
                    </div>
                </div>
                {
                    shiftInfo?.staff?.length ?
                        <div className="s1">Applicants List</div>
                        : null
                }
                {
                    shiftInfo?.staff?.map((data, index) => {
                        return (
                            <div key={index}
                                onClick={() => navigate(`/staff/${data?.id}/details/information`)}
                                className="p-3 cursor-pointer d-flex border rounded  align-items-center justify-content-between" style={{ overflow: "hidden" }}>
                                <div className="d-flex align-items-center" style={{ gap: 10 }}>
                                    {
                                        data?.image ?
                                            <img
                                                src={data.image}
                                                alt=""
                                                style={{
                                                    cursor: "pointer",
                                                    width: "80px",
                                                    height: "80px",
                                                    borderRadius: "50%",
                                                    border: "1px solid #F6F2F",
                                                    position: "relative",
                                                }}
                                            />
                                            :
                                            <div
                                                style={{
                                                    width: 80,
                                                    height: 80,
                                                    borderRadius: '50%',
                                                    background: '#E1F4F4',
                                                    fontSize: '30px',
                                                    color: ' #065A4F',
                                                    textAlign: 'center',
                                                    lineHeight: '80px',
                                                    padding: 1
                                                }}
                                            >
                                                {`${data.name} ${data.surname}`?.split(' ')?.map((d, idx) => idx < 2 ? d?.charAt(0).toUpperCase() : null).join(' ')}
                                            </div>
                                    }
                                    {/* <img width={80} height={80} className="border rounded-circle" src={data?.image || avatar} alt="avatar" /> */}
                                    <div className="d-flex flex-column" style={{ gap: 5, maxWidth: "229px", overflow: "hidden", marginRight: "5px" }}>
                                        <div className="b1">{data?.name} {data?.surname}</div>
                                        <div className="t2">{data?.email}</div>
                                        <div style={{ width: 'fit-content', gap: '3px', backgroundColor: 'white' }} className="px-3 lh-1 rounded-pill py-2 border d-flex align-items-center">
                                            <img src={startRatingIcon} alt="startRatingIcon" />
                                            {Number(data?.rating)?.toFixed(1) || 0.0}
                                            {/* {Number(data?.rating) || 0} */}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {
                                        data?.status === 'accepted' ?
                                            <div onClick={e => e.stopPropagation()} className="py-2 cursor-pointer px-4 border rounded">
                                                Accepted
                                            </div>
                                            :
                                            data?.status === 'invited' && hasAccess(locumShiftApiKeys.DECLINE_LOCUM_INVITE) ?
                                                <div
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        onRemoveInvite(data?.id)
                                                    }}
                                                    className="danger-links" style={{ gap: 10 }}>
                                                    CANCEL INVITE
                                                </div>
                                                : (
                                                    data?.locumHourlyRate ?
                                                        <div className="dashboard-table-links" onClick={(e) => {
                                                            e.stopPropagation()
                                                            setNegotiableLocum(data)
                                                            setShowNegotiableModal(!showNegotiableModal)
                                                        }}>
                                                            View negotiation
                                                        </div>
                                                        :
                                                        <div className="d-flex align-items-center" style={{ gap: 5 }}>
                                                            {hasAccess(locumShiftApiKeys.APPROVE_LOCUM) && <div className=" d-flex align-items-center cursor-pointer" onClick={(e) => {
                                                                e.stopPropagation()
                                                                onAcceptJobRequest(data?.id)
                                                            }} >
                                                                <img style={{ width: '100%' }} src={acceptIcon} alt="acceptIcon" />
                                                            </div>}
                                                            {hasAccess(locumShiftApiKeys.REJECT_LOCUM) && <div className=" d-flex align-items-center cursor-pointer" onClick={(e) => {
                                                                e.stopPropagation()
                                                                onRejectJobRequest(data?.id)
                                                            }} >
                                                                <img style={{ width: '100%' }} src={rejectIcon} alt="rejecticon" />
                                                            </div>}
                                                        </div>
                                                )
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <ConfirmModal
                title={`Negotiated Wage`}
                description={`Following is the preffered hourly rate from locum for the locum Job.`}
                open={showNegotiableModal}
                onClose={() => setShowNegotiableModal(false)}
            >
                <div className="text-input-field border rounded p-2 pe-3 t1">
                    {negotiableLocum?.locumHourlyRate}
                </div>
                <div
                    className="d-flex align-content-between align-items-end"
                    style={{ gap: "15px", flex: "1" }}
                >

                    <Button block size="large"
                        onClick={() => onRejectJobRequest(negotiableLocum?.id)}
                    >
                        Deny
                    </Button>
                    <Button type="primary" block size="large"
                        onClick={() => onAcceptJobRequest(negotiableLocum?.id)}
                    >
                        Accept
                    </Button>
                </div>
            </ConfirmModal>
        </>

    )
}