import React from "react";

const ArrowRight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M5.19776 12.0589C5.08693 12.0589 4.97609 12.018 4.88859 11.9305C4.71943 11.7614 4.71943 11.4814 4.88859 11.3122L8.69193 7.50885C8.97193 7.22885 8.97193 6.77385 8.69193 6.49385L4.88859 2.69052C4.71943 2.52135 4.71943 2.24135 4.88859 2.07219C5.05776 1.90302 5.33776 1.90302 5.50693 2.07219L9.31026 5.87552C9.60776 6.17302 9.77693 6.57552 9.77693 7.00135C9.77693 7.42719 9.61359 7.82969 9.31026 8.12719L5.50693 11.9305C5.41943 12.0122 5.30859 12.0589 5.19776 12.0589Z"
        fill="#434343"
      />
    </svg>
  );
};

export default ArrowRight;
