import { Checkbox } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import plusIcon from "../../../../src/assets/icons/plus.svg";
import lock from "../../../assets/icons/lock.png";
import warningIcon from "../../../assets/icons/warningIcon.png";
import { branchApiKeys } from "../../../utils/apiKeyConstants";
import { hasAccess } from "../../../utils/helper";
import ShiftDetailsViewBox from "./ShiftDetailsViewBox";

const ShiftDetailsWeekView = ({
  day,
  handleAddShift,
  operatingTime,
  branchId,
  dayNumber,
  locumFlow,
  showDateCheckbox,
  onDaySelect,
  type,
  templateView,
  onDelete,
  weekNumber,
  addShift,
  allShifts,
  uniqShiftByGphc,
}) => {
  const navigate = useNavigate();

  const sortData = useMemo(() => {
    // let sortedData = sortByGphcNumber(day);
    let temp = [];

    uniqShiftByGphc?.forEach((shift, i) => {
      let found = day?.shifts?.find((item) => {
        return (
          item?.staff?.gphcNumber ===
          shift?.staff?.gphcNumber
        );
      });

      if (!!found) {
        temp.push(found);
      } else {
        temp.push({ ...shift, isEmpty: true });
      }
    });

    return temp;
  }, [day]);

  const RenderDetails = useMemo(() => {
    if (day?.isEmpty && day?.staffLeave) {
      return <div className="empty_div" />;
    }

    if (!day?.active && !locumFlow) {
      return (
        <>
          <div
            className="week-day-detail-cell-closed d-flex flex-column gap-1 justify-content-between cursor-pointer"
            style={{ borderBottom: "none" }}
            onClick={() =>
              navigate(`/profile/subscription`)
            }
          >
            <div>
              {dayNumber && (
                <div className="d-flex align-items-center justify-content-between month-view-flex">
                  <span className="">
                    {" "}
                    {moment(dayNumber).format(
                      "DD"
                    )}
                  </span>
                  {showDateCheckbox &&
                    !dayjs(
                      day.startDate
                    ).isBefore(
                      dayjs(),
                      "days"
                    ) && (
                      <Checkbox
                        onChange={(e) =>
                          onDaySelect(
                            e.target.checked,
                            dayNumber
                          )
                        }
                      />
                    )}
                </div>
              )}
            </div>
            <div className="d-flex flex-column align-items-center gap-2">
              <img src={warningIcon} alt="" />
              <span className="t2">
                Billing Not Active
              </span>
            </div>
          </div>
        </>
      );
    }

    if (day?.branchClosed) {
      return (
        <div
          className="week-day-detail-cell-closed d-flex flex-column gap-1 justify-content-between cursor-pointer appear_on_hover"
          style={{ borderBottom: "none" }}
          onClick={() =>
            navigate(
              `/branches/${branchId}/details/information?tab=branch-hours`
            )
          }
        >
          <div>
            {dayNumber && (
              <div className="d-flex align-items-center justify-content-between month-view-flex">
                <span className="">
                  {" "}
                  {moment(dayNumber).format("DD")}
                </span>
                {showDateCheckbox &&
                  !dayjs(day.startDate).isBefore(
                    dayjs(),
                    "days"
                  ) && (
                    <Checkbox
                      onChange={(e) =>
                        onDaySelect(
                          e.target.checked,
                          dayNumber
                        )
                      }
                    />
                  )}
              </div>
            )}
          </div>
          <div className="d-flex flex-column align-items-center gap-2">
            <img src={lock} alt="" />
            <span className="t2">Closed</span>
          </div>
        </div>
      );
    }

    return sortData?.length > 0 &&
      day?.shifts?.length > 0 ? (
      <>
        {sortData?.map((shift, idx) => (
          <ShiftDetailsViewBox
            shift={shift}
            type={type}
            day={day}
            templateView={templateView}
            key={shift?.id + idx}
            onDelete={onDelete}
            handleAddShift={handleAddShift}
            weekNumber={weekNumber}
            operatingTime={operatingTime}
            allShifts={allShifts}
          />
        ))}
        <div
          className="add_shift_box"
          onClick={() =>
            (hasAccess(
              branchApiKeys.CREATE_BULK_JOB
            ) ||
              hasAccess(
                branchApiKeys.SAVE_SHIFT_BULK
              )) &&
            handleAddShift(
              operatingTime,
              day?.startDate,
              branchId,
              undefined,
              day?.day,
              type,
              weekNumber
            )
          }
        >
          <img src={plusIcon} alt="plus icon" />
        </div>
      </>
    ) : (
      <div
        className="add_shift_box empty_box"
        onClick={() =>
          (hasAccess(
            branchApiKeys.CREATE_BULK_JOB
          ) ||
            hasAccess(
              branchApiKeys.SAVE_SHIFT_BULK
            )) &&
          handleAddShift(
            operatingTime,
            day?.startDate,
            branchId,
            undefined,
            day?.day,
            type,
            weekNumber
          )
        }
        style={{
          display:
            addShift === true ? "flex" : "none",
        }}
      >
        <img src={plusIcon} alt="plus icon" />
      </div>
    );
  }, [day, sortData]);

  return RenderDetails;
};

export default ShiftDetailsWeekView;
