import { Navigate, Route, Routes } from "react-router-dom"
import { ProfileAccrediations } from "./accrediations"
import { ProfileBankHolidays } from "./bank-holidays"
import { ProfileDetail } from "./details"
import { ProfileInformation } from "./infromation"
import { ProfileSetting } from "./setting"
import { AdminStaff } from "./adminStaff"
import { SubscriptionNBilling } from "./subscrption&Billing"

export const ProfilePage = () => {
    return (
        <Routes>
            <Route path="/*" element={<ProfileDetail />}>
                <Route path="information" element={<ProfileInformation />} />
                <Route path="subscription" element={<SubscriptionNBilling />} />
                <Route path="bank-holidays" element={<ProfileBankHolidays />} />
                <Route path="accrediations" element={<ProfileAccrediations />} />
                <Route path="setting" element={<ProfileSetting />} />
                <Route path="admin-staff" element={<AdminStaff />} />
                <Route path="/*" element={<Navigate to="information" />} />
            </Route>
        </Routes>

    )
}