import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from "react";
import { useDispatch } from "react-redux";

import { Heading } from "../../../components/UI/heading";
import {
  getAllMonthDays,
  getCurrentWeekDays,
  minuteTohour,
} from "../../../utils/dates";

import plusIcon from "../../../../src/assets/icons/whiteplus.svg";

import { useQuery } from "@tanstack/react-query";
import { Input, Popover } from "antd";
import * as FileSaver from "file-saver";
import { ProgressBar } from "react-bootstrap";
import { toast } from "react-toastify";
import XLSX from "sheetjs-style";
import NoTimeSheet1 from "../../../components/EmptyStates/NoTimeSheet1/NoTimesheet1";
import FilterPopover from "../../../components/TimesheetNew/Filter/FilterPopover";
import TimeSheetNewTable from "../../../components/TimesheetNew/TimeSheetNewTable";
import CommonToolbar from "../../../components/Toolbar/CommonToolbar";
import AddAttendanceForm from "../../../components/comon/AddAttendanceForm";
import useDebounce from "../../../hooks/useDebounce";
import { getbranchesListThunk } from "../../../redux/branches/branchesThunk";
import { getRolesThunk } from "../../../redux/schedule/scheduleThunk";
import { getStaffAttendance } from "../../../redux/staffs/staffsAPI";
import {
  branchApiKeys,
  personalApiKeys,
} from "../../../utils/apiKeyConstants";
import { VIEW_OPTIONS_TIMESHEET } from "../../../utils/constant";
import { hasAccess } from "../../../utils/helper";
import {
  getLeaveCountForStaff,
  getScheduleHourAndWorkedHour,
  prepareSingleExcellCellForImport,
} from "../../../utils/timeshhet";
import "./style.scss";
const TimeSheetNew = () => {
  const [view, setView] = useState("weekly");
  const [isPending, setTransition] =
    useTransition();
  const [openExport, setOpenExport] =
    useState(false);

  const [fileName, setFileName] = useState("");

  const [
    showAddAttendanceModal,
    setShowAddAttendanceModal,
  ] = useState(false);

  const [startDate, setStartDate] = useState(
    moment()
  );
  const [endDate, setEndDate] = useState(
    moment().add(7, "day")
  );
  const [dateRange, setDateRange] = useState({
    startDateRange: startDate,
    endDateRange: endDate,
  });
  const [search, setSearch] = useState("");
  const _query = useDebounce(search, 500);

  const [filterValues, setFilterValue] = useState(
    {
      filters: {
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        query: "",
        branchIds: [],
        roleIds: [],
        regionIds: [],
      },
      pagination: {
        page: 1,
        perPage: 10,
      },
    }
  );

  const [isMonthGridView, setIsMonthGridView] =
    useState(true);
  const dispatch = useDispatch();

  const { data } = useQuery({
    queryKey: ["getStaffTimeSheet", filterValues],
    queryFn: () =>
      getStaffAttendance(filterValues),
    enabled: hasAccess(
      personalApiKeys.ATTENDANCE_LIST
    ),
  });

  const getWeekArray = useMemo(() => {
    let temp = [];
    if (view === "weekly") {
      temp = getCurrentWeekDays(
        dateRange?.startDateRange,
        (dateRange?.startDateRange, "YYYY-MM-DD"),
        ""
      );
    }

    if (view === "monthly") {
      temp = getAllMonthDays(
        dateRange?.startDateRange,
        dateRange?.endDateRange,
        "YYYY-MM-DD"
      );
    }

    return temp;
  }, [
    dateRange?.endDateRange,
    dateRange?.startDateRange,
    view,
  ]);

  const handleToggleMonthView = () => {
    const _isGridView = !isMonthGridView;
    setIsMonthGridView(_isGridView);
  };

  const handlePerPage = (perPage = 10) => {
    setFilterValue({
      ...filterValues,
      pagination: {
        ...filterValues.pagination,
        perPage,
      },
    });
  };

  const handleMoveToPage = (page = 1) => {
    setFilterValue({
      ...filterValues,
      pagination: {
        ...filterValues.pagination,
        page,
      },
    });
  };

  const handleFilterValues = (filter_val) => {
    setFilterValue({
      ...filterValues,
      filters: {
        ...filterValues.filters,
        ...filter_val,
      },
    });
  };

  const _hasFilter = useMemo(() => {
    return (
      filterValues.filters.branchIds.length > 0 ||
      filterValues.filters.roleIds.length > 0 ||
      filterValues.filters.regionIds.length > 0
    );
  }, [filterValues]);

  const handleAttandanceModal = () => {
    setShowAddAttendanceModal(
      !showAddAttendanceModal
    );
  };

  const handleViewChange = (view) => {
    setView(view);

    // setFlag(true);
  };

  const handlePrevious = useCallback(() => {
    if (view === "weekly") {
      const _dateStart = moment(
        dateRange?.startDateRange
      ).subtract(1, "weeks");
      const _dateEnd = moment(
        dateRange?.endDateRange
      ).subtract(1, "weeks");

      setDateRange({
        startDateRange: _dateStart,
        endDateRange: _dateEnd,
      });

      setFilterValue({
        ...filterValues,
        filters: {
          ...filterValues.filters,
          startDate:
            _dateStart.format("YYYY-MM-DD"),
          endDate: _dateEnd.format("YYYY-MM-DD"),
        },
      });
    }

    if (view === "monthly") {
      const _dateStart = moment(
        dateRange?.startDateRange
      ).subtract(1, "months");
      const _dateEnd = moment(
        dateRange?.endDateRange
      ).subtract(1, "months");

      setDateRange({
        startDateRange: _dateStart,
        endDateRange: _dateEnd,
      });

      setFilterValue({
        ...filterValues,
        filters: {
          ...filterValues.filters,
          startDate:
            _dateStart.format("YYYY-MM-DD"),
          endDate: _dateEnd.format("YYYY-MM-DD"),
        },
      });
    }

    if (view === "daily") {
      const _dateStart = moment(
        dateRange?.startDateRange
      ).subtract(1, "days");
      const _dateEnd = moment(
        dateRange?.endDateRange
      ).subtract(1, "days");

      setDateRange({
        startDateRange: _dateStart,
        endDateRange: _dateEnd,
      });

      setFilterValue({
        ...filterValues,
        filters: {
          ...filterValues.filters,
          startDate:
            _dateStart.format("YYYY-MM-DD"),
          endDate: _dateEnd.format("YYYY-MM-DD"),
        },
      });
    }
  }, [
    dateRange?.endDateRange,
    dateRange?.startDateRange,
    view,
  ]);

  const handleNext = useCallback(() => {
    if (view === "weekly") {
      const _dateStart = moment(
        dateRange?.startDateRange
      ).add(1, "weeks");
      const _dateEnd = moment(
        dateRange?.endDateRange
      ).add(1, "weeks");

      setDateRange({
        startDateRange: _dateStart,
        endDateRange: _dateEnd,
      });

      setFilterValue({
        ...filterValues,
        filters: {
          ...filterValues.filters,
          startDate:
            _dateStart.format("YYYY-MM-DD"),
          endDate: _dateEnd.format("YYYY-MM-DD"),
        },
      });
    }

    if (view === "monthly") {
      const _dateStart = moment(
        dateRange?.startDateRange
      ).add(1, "months");
      const _dateEnd = moment(
        dateRange?.endDateRange
      ).add(1, "months");

      setDateRange({
        startDateRange: _dateStart,
        endDateRange: _dateEnd,
      });

      setFilterValue({
        ...filterValues,
        filters: {
          ...filterValues.filters,
          startDate:
            _dateStart.format("YYYY-MM-DD"),
          endDate: _dateEnd.format("YYYY-MM-DD"),
        },
      });
    }

    if (view === "daily") {
      const _dateStart = moment(
        dateRange?.startDateRange
      ).add(1, "days");
      const _dateEnd = moment(
        dateRange?.endDateRange
      ).add(1, "days");

      setDateRange({
        startDateRange: _dateStart,
        endDateRange: _dateEnd,
      });

      setFilterValue({
        ...filterValues,
        filters: {
          ...filterValues.filters,
          startDate:
            _dateStart.format("YYYY-MM-DD"),
          endDate: _dateEnd.format("YYYY-MM-DD"),
        },
      });
    }
  }, [
    dateRange?.endDateRange,
    dateRange?.startDateRange,
    view,
  ]);

  const RenderValue = useMemo(() => {
    if (view === "weekly") {
      return `${dateRange?.startDateRange?.format(
        "DD MMM"
      )} - ${dateRange?.endDateRange?.format(
        "DD MMM YYYY"
      )}`;
    }

    if (view === "monthly") {
      return `${dateRange?.startDateRange?.format(
        "MMM YYYY"
      )}`;
    }

    if (view === "daily") {
      return `${dateRange?.startDateRange?.format(
        "DD MMM YYYY"
      )}`;
    }

    return "";
  }, [
    dateRange?.endDateRange,
    dateRange?.startDateRange,
    view,
  ]);

  const handlejobOptionChange = (value) => {
    setFilterValue({
      ...filterValues,
      status: value,
    });
  };

  const handleRoleOptionsChange = (value) => {
    setFilterValue({
      ...filterValues,
      roleId: value,
    });
  };

  const onSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      setFilterValue((prev) => ({
        ...prev,
        branch: e.target.value,
      }));
    }
  };

  const onSearchValueChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    //fetch branch list:
    if (
      hasAccess(branchApiKeys.GET_BRANCH_LIST)
    ) {
      dispatch(
        getbranchesListThunk({
          filters: {
            title: "",
          },
          pagination: { page: 1, perPage: 100 },
        })
      );
    }

    dispatch(
      getRolesThunk({
        group: ["staff"],
        type: "Default",
      })
    );
  }, []);

  useEffect(() => {
    const viewToDates = {
      weekly: {
        startDateRange:
          moment().startOf("isoWeek"),
        endDateRange: moment().endOf("isoWeek"),
      },
      daily: {
        startDateRange: moment(),
        endDateRange: moment(),
      },
      monthly: {
        startDateRange: moment().startOf("month"),
        endDateRange: moment().endOf("month"),
      },
    };

    if (viewToDates.hasOwnProperty(view)) {
      const dateData = viewToDates[view];
      setDateRange(dateData);

      if (view !== "daily") {
        setFilterValue((prevFilterValues) => ({
          ...prevFilterValues,
          filters: {
            ...prevFilterValues.filters,
            startDate:
              dateData.startDateRange.format(
                "YYYY-MM-DD"
              ),
            endDate:
              dateData.endDateRange.format(
                "YYYY-MM-DD"
              ),
          },
        }));
      }
    }
  }, [view]);

  const prepareData = () => {
    let temp = [];
    let allShifts = [];
    let obj = {};

    data?.data?.rows?.forEach((item) => {
      const wokredMin =
        getScheduleHourAndWorkedHour(item);

      let leaveCount = getLeaveCountForStaff(
        item?.shifts
      );

      // totalWorked += wokredMin.worked;
      allShifts = [...allShifts, ...item?.shifts];

      const shifts = [];

      getWeekArray.forEach((date) => {
        const findShifts = item?.shifts?.filter(
          (_s) =>
            _s?.startDate ===
            moment(date).format("YYYY-MM-DD")
        );

        shifts.push(
          prepareSingleExcellCellForImport(
            findShifts
          )
        );
      });

      // ADD WORK HOUR AT THE LAST
      shifts.push(
        minuteTohour(wokredMin.worked, "type1")
      );

      // ADD NUMBER OF LEAVE COUNT
      shifts.push(leaveCount);

      obj = {
        name: `${item?.name} ${item?.surname}`,
        shifts,
      };

      temp.push(obj);
    });

    return temp;
  };

  const exportTimeSheetToExcel = (e) => {
    e.preventDefault();
    setTransition(() => {
      setOpenExport(true);
      const dataSet = prepareData();

      const dates = getWeekArray?.map((date) => {
        const formattedDate = moment(date).format(
          "MMMM DD ddd"
        );
        return formattedDate;
      });

      dates.push("Summary");
      dates.push("Number of leaves");

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8";

      const fileExtension = ".xlsx";

      // Create a new worksheet
      const ws = XLSX.utils.aoa_to_sheet([]);

      // Add dates to the first row
      XLSX.utils.sheet_add_aoa(ws, [dates], {
        origin: "B1",
      });

      // Add data rows
      dataSet.forEach((row, index) => {
        const rowData = [
          row?.name,
          ...row?.shifts,
        ]; // Assuming the structure of row is { name: string, shifts: string[] }
        XLSX.utils.sheet_add_aoa(ws, [rowData], {
          origin: `A${index + 2}`,
        });
      });

      // Create workbook
      const wb = {
        Sheets: { data: ws },
        SheetNames: ["data"],
      };

      // Convert workbook to array buffer
      const excelBuffer = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
      });

      // Create blob from array buffer
      const blob = new Blob([excelBuffer], {
        type: fileType,
      });

      let DefaultfileName = `${moment(
        getWeekArray[0]
      ).format("YYYY-MM-DD")} to ${moment(
        getWeekArray[getWeekArray?.length - 1]
      ).format("YYYY-MM-DD")}`;

      let _name = fileName?.length
        ? fileName
        : DefaultfileName;

      _name = _name.replace(/\./g, "_");

      // Save blob as file
      FileSaver.saveAs(
        blob,
        _name + fileExtension
      );

      toast.success(
        "File has been downloaded 📥"
      );

      handleCloseExport();
    });
  };

  const handleCloseExport = () => {
    setFileName("");
    setOpenExport(false);
  };

  useEffect(() => {
    setFilterValue((prevFilterValues) => ({
      ...prevFilterValues,
      filters: {
        ...prevFilterValues.filters,
        query: _query,
      },
    }));
  }, [_query]);

  return (
    <div>
      <Heading
        label="Timesheet"
        downloadbtn={false}
      />
      <div className="container-fluid">
        <CommonToolbar
          searchInputOnChange={
            onSearchValueChange
          }
          searchPlaceHolder="Search By Name , Email"
          searchInputValue={search}
          searchInputonKeyDown={onSearchKeyDown}
          _hasFilter={_hasFilter}
          selectedView={view}
          handleViewChange={handleViewChange}
          handleJoboptionsChange={
            handlejobOptionChange
          }
          selectedJob={filterValues?.status}
          selectedRole={filterValues?.roleId}
          handleRoleOptionsChange={
            handleRoleOptionsChange
          }
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          RenderValue={RenderValue}
          handleToggleMonthView={
            handleToggleMonthView
          }
          isMonthGridView={isMonthGridView}
          type="timesheet"
          displayOptions={{
            hideRole: true,
            hideView: false,
            hideSearch: false,
            hideJobs: true,
            hideFilter: false,
          }}
          viewOptions={VIEW_OPTIONS_TIMESHEET}
          endAdroment={
            <div
              style={{
                display: "flex",
                direction: "row",
                justifyContent: "space-evenly",
              }}
            >
              <Popover
                open={openExport}
                onOpenChange={(val) =>
                  setOpenExport(val)
                }
                content={
                  <form
                    onSubmit={
                      exportTimeSheetToExcel
                    }
                  >
                    {isPending ? (
                      <div>
                        <ProgressBar
                          animated
                          now={100}
                        />
                        <p>Exporting excel...</p>
                      </div>
                    ) : (
                      <div>
                        <Input.Search
                          placeholder="Enter file name"
                          enterButton="Search"
                          size="large"
                          value={fileName}
                          suffix={<p>.xlsx</p>}
                          onChange={(e) =>
                            setFileName(
                              e.target?.value?.trimStart()
                            )
                          }
                        />
                        <div className="mt-2 d-flex justify-content-end">
                          <button
                            onClick={
                              handleCloseExport
                            }
                            type="button"
                            className="btn mx-2 "
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btn modal-primary-button  mx-2 "
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    )}
                  </form>
                }
                title="File name"
                trigger="click"
              >
                <button
                  // onClick={exportTimeSheetToExcel}
                  className="custom_btn attendence btn mx-2 "
                >
                  <DownloadOutlined />
                  <span className="mx-2">
                    {" "}
                    {isPending
                      ? "Exporting..."
                      : "Export to excel"}{" "}
                  </span>
                </button>
              </Popover>

              <button
                onClick={handleAttandanceModal}
                className="custom_btn attendence btn "
              >
                <img src={plusIcon} alt="plus" />{" "}
                <span>Add Attendance</span>
              </button>
            </div>
          }
          filterPopover={
            <FilterPopover
              handleFilterValues={
                handleFilterValues
              }
            />
          }
        />
        <AddAttendanceForm
          open={showAddAttendanceModal}
          onClose={handleAttandanceModal}
          staffList={data?.data?.rows}
        />

        {data?.data?.rows?.length > 0 ? (
          <TimeSheetNewTable
            data={data}
            startDate={
              filterValues.filters.startDate
            }
            endDate={filterValues.filters.endDate}
            initialView={view}
            weekArray={getWeekArray}
            handlePerPage={handlePerPage}
            perPage={
              filterValues.pagination.perPage
            }
            handleMoveToPage={handleMoveToPage}
            page={filterValues.pagination.page}
          />
        ) : (
          <NoTimeSheet1
            text="No Staff Member Found"
            subtext="Managing time sheets for your employees has never been easier, add your staff, schedule their shift and take it out for a spin"
          />
        )}
      </div>
    </div>
  );
};

export default TimeSheetNew;
