import {
  Button,
  Form,
  Input,
  List,
  Typography,
} from "antd";
import moment from "moment";
import React, {
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { useParams } from "react-router-dom";
import FaceImage from "../../../../assets/icons/face.svg";
import infoIcon from "../../../../assets/icons/infoIcon1.png";
import verificationIcon from "../../../../assets/icons/verificationIcon.png";
import CommonModal from "../../../../components/UI/modals/CommonModal/CommonModal";
import { ConfirmModal } from "../../../../components/UI/modals/confirmModal";
import { ContactDetail } from "../../../../components/locum-shifts/contactDetail";
import { CoveredExpense } from "../../../../components/locum-shifts/coveredExpense";
import { DetailsComponent } from "../../../../components/locum-shifts/detailsComponent";
import { VerificationForm } from "../../../../components/verifications/verificationForm";
import { authUserRequestThunk } from "../../../../redux/auth/authThunk";
import {
  acceptJobInviteThunk,
  applyJobThunk,
  cancelJobThunk,
  rejectJobInviteThunk,
} from "../../../../redux/locum-flow/Thunk";
import { getLocumShiftInfoThunk } from "../../../../redux/locum-shifts/locumShiftsThunk";
import {
  editVerificationThunk,
  getStaffVerificationThunk,
  yotiVerificationStartThunk,
} from "../../../../redux/staffs/staffsThunk";
import { notify } from "../../../../utils/helper";
import { AdditionalAccrediationForm } from "../components/additionalAccrediationForm";
import "./style.scss";

const data = [
  "Upload your profile picture.",
  "A quick scan of your face.",
  "You will receive an email in 24hours to let you know if your request is accepted.",
];

export const LocumJobDetails = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formId = useMemo(() => "form", []);
  const verificationFormId = useMemo(
    () => "verificationFormId",
    []
  );
  const [openVerifyModal, setopenVerifyModal] =
    useState(false);
  const ShowVerifyModal = () => {
    setopenVerifyModal(!openVerifyModal);
  };
  const [showConfirmModal, setShowConfirmModal] =
    useState(false);
  const [confrimText, setConfirmText] =
    useState("");

  const { id } = useParams();
  const [flag, setFlag] = useState(true);
  const { shiftInfo } = useSelector(
    (state) => state.locumShifts
  );
  const { user } = useSelector(
    (state) => state.auth
  );

  const [
    showNegotiableModal,
    setShowNegotiableModal,
  ] = useState(false);
  const [
    showAccreditionModal,
    setAccreditionModal,
  ] = useState(false);

  const [actionType, setActionType] = useState();
  const [type, setType] = useState("other");

  const [negotiableValue, setNegotiableValue] =
    useState();
  const [APIFlag, setAPIFlag] = useState(false);
  const [showInfoModal, setShowInfoModal] =
    useState(false);
  const [infoText, setInfoText] = useState(false);

  const [
    showVerificationModal,
    setShowVerificationModal,
  ] = useState(false);

  useEffect(() => {
    if (!flag) return;
    dispatch(
      getLocumShiftInfoThunk({
        shiftId: id,
      })
    ).then((res) => {
      if (res.payload) {
        setFlag(false);
      }
    });
    dispatch(
      getStaffVerificationThunk({
        staffId: user.id,
      })
    );
  }, [dispatch, id, flag, user.id]);

  useEffect(() => {
    setFlag(true);
  }, [id]);

  const totalHours = useMemo(() => {
    var startTime = moment(
      shiftInfo?.startTime,
      "hh:mm:ss a"
    );
    var endTime = moment(
      shiftInfo?.endTime,
      "hh:mm:ss a"
    );

    var mins = moment
      .utc(
        moment(endTime, "HH:mm:ss").diff(
          moment(startTime, "HH:mm:ss")
        )
      )
      .format("mm");

    return (
      endTime.diff(startTime, "hours") +
      "h " +
      mins +
      "m"
    );
  }, [shiftInfo]);

  const onAction = (actionType, value) => {
    setActionType(actionType);
    setNegotiableValue(value);
    setShowNegotiableModal(false);
    if (
      actionType === "cancelJob" ||
      actionType === "rejectJobInvite"
    ) {
      setAPIFlag(true);
      return;
    }
    if (
      user.status === "onboarding" ||
      !user.isVerified
    ) {
      setShowVerificationModal(true);
      // setShowInfoModal(true);
      // setInfoText(
      //   "Head on over to the 'Verification' tab to add in your mandatory documents."
      // );
      return;
    } else if (user.status === "pending") {
      setShowInfoModal(true);
      setInfoText(
        "Your submitted documents are under screening. Once they're done you can go ahead and apply to your Jobs."
      );
      return;
    } else if (user.status === "rejected") {
      setInfoText(
        "There was a problem with you verification process, please contact the Lopic administrator."
      );
      setShowInfoModal(true);
      return;
    }

    if (shiftInfo?.accreditationRules.length) {
      setAccreditionModal(true);
    } else {
      setAPIFlag(true);
    }
  };

  useEffect(() => {
    if (!APIFlag || !actionType) return;
    if (actionType === "applyJob") {
      const data = {
        ...(negotiableValue?.locumHourlyRate && {
          locumHourlyRate: Number(
            negotiableValue?.locumHourlyRate
          ),
          isNegotiable: 1,
        }),
      };
      dispatch(
        applyJobThunk({
          id,
          data,
        })
      ).then((res) => {
        if (res.payload) {
          setActionType("");
          setShowConfirmModal(false);
          setAPIFlag(false);
          setNegotiableValue(undefined);
          setFlag(true);
          notify(
            "success",
            "Job Applied Successfully."
          );
          window.analytics.track(
            `${
              data?.locumHourlyRate
                ? "Job Applied With Negotiation"
                : "Job Applied"
            }`,
            {
              title: shiftInfo?.branchTitle,
              date: shiftInfo?.startDate,
              time: `${shiftInfo?.startTime} - ${shiftInfo?.endTime}`,
              duration: totalHours,
              totalFee: shiftInfo?.total,
              negotiableRate:
                data?.locumHourlyRate
                  ? data?.locumHourlyRate
                  : undefined,
            }
          );
        }
      });
      return;
    }
    if (actionType === "rejectJobInvite") {
      dispatch(
        rejectJobInviteThunk({
          id,
        })
      ).then((res) => {
        if (res.payload) {
          setAPIFlag(false);
          setActionType("");
          setShowConfirmModal(false);
          setFlag(true);

          window.analytics.track(
            `Rejected Job Invite`,
            {
              title: shiftInfo?.branchTitle,
              date: shiftInfo?.startDate,
              time: `${shiftInfo?.startTime} - ${shiftInfo?.endTime}`,
              duration: totalHours,
              totalFee: shiftInfo?.total,
            }
          );
        }
      });
      return;
    }

    if (actionType === "acceptJobInvite") {
      dispatch(
        acceptJobInviteThunk({
          id,
        })
      ).then((res) => {
        if (res.payload) {
          setAPIFlag(false);
          setActionType("");
          setShowConfirmModal(false);
          setFlag(true);

          window.analytics.track(
            `Accept Job Invite`,
            {
              title: shiftInfo?.branchTitle,
              date: shiftInfo?.startDate,
              time: `${shiftInfo?.startTime} - ${shiftInfo?.endTime}`,
              duration: totalHours,
              totalFee: shiftInfo?.total,
            }
          );
        }
      });
      return;
    }
    if (actionType === "cancelJob") {
      dispatch(
        cancelJobThunk({
          id,
        })
      ).then((res) => {
        if (res.payload) {
          setActionType("");
          setShowConfirmModal(false);
          setAPIFlag(false);
          setFlag(true);
        }
      });
      return;
    }
  }, [
    APIFlag,
    actionType,
    dispatch,
    id,
    negotiableValue,
    shiftInfo?.branchTitle,
    shiftInfo?.endTime,
    shiftInfo?.startDate,
    shiftInfo?.startTime,
    shiftInfo?.total,
    totalHours,
  ]);

  const onSubmit = (formData) => {
    dispatch(
      editVerificationThunk({
        staffId: user.id,
        payload: formData,
      })
    ).then((res) => {
      if (res.payload) {
        window.analytics.track(
          "Verification Uploaded",
          {
            profilePicture: res.payload.image
              ? true
              : false,
            proofOfID:
              res.payload.proofOfId &&
              res.payload.proofOfIdExp
                ? true
                : false,
            indemnity:
              res.payload.professionalInsurance &&
              res.payload
                .professionalInsuranceExpDate
                ? true
                : false,
            enhancedDBS: res.payload
              .DBSCertificate
              ? true
              : false,
            workVise:
              res.payload.visa &&
              res.payload.visaExpDate
                ? true
                : false,
          }
        );
        if (!user.isVerified) {
          setType("yoti");
          return;
        }
        setShowVerificationModal(false);
        setType("basic");
        dispatch(authUserRequestThunk());
      }
    });
  };

  const onVerifyIdentity = () => {
    dispatch(yotiVerificationStartThunk()).then(
      (res) => {
        if (res.payload) {
          window.open(res.payload, "_self");
        }
      }
    );
  };

  return (
    <div
      className="border rounded d-flex flex-column"
      style={{
        height: "100%",
        gap: 20,
        width: "100%",
      }}
    >
      {showVerificationModal && (
        <ConfirmModal
          formId={verificationFormId}
          open={showVerificationModal}
          onConfirm={() => {}}
          onClose={() =>
            setShowVerificationModal(false)
          }
        >
          <div className="d-flex flex-column gap-1">
            <div className="h3 fw-bold">
              Before you start
            </div>
            <div>
              We need you to go through a 1-2
              minute routine to meet our community
              compliance standards
            </div>
          </div>
          {type !== "yoti" ? (
            <>
              <VerificationForm
                areSteps
                isVerified={user.isVerified}
                onSubmit={onSubmit}
                staffInfo={user}
                formId={formId}
                setShowEditInfoModal={
                  setShowVerificationModal
                }
                setActionType={setType}
                actionType={type}
              />
            </>
          ) : (
            <div>
              <div className="d-flex flex-column gap-1">
                <ul>
                  <li>
                    Before you start, ensure that
                    you have your identification
                    document with you- you'll need
                    to scan it during this
                    process.
                  </li>
                  <li>
                    You should be in a well lit
                    background so you can take a
                    great selfie that we'll use to
                    verify your identity.
                  </li>
                </ul>
                Let's get you verified and
                contribute towards making a safer
                and greater Locum community!
              </div>
              <div
                className="d-flex flex-column align-items-center gap-3 m-auto my-4"
                style={{ width: "70%" }}
              >
                <img
                  src={verificationIcon}
                  alt="verificationIcon"
                />
              </div>
            </div>
          )}
          <div
            className="d-flex align-content-between align-items-end"
            style={{ gap: "15px", flex: "1" }}
          >
            {type !== "other" && (
              <Button
                block
                size="large"
                onClick={() => {
                  if (type === "basic") {
                    setType("other");
                    return;
                  }
                  if (type === "docs") {
                    setType("basic");
                  }
                  if (type === "yoti") {
                    setType("docs");
                  }
                }}
              >
                Back
              </Button>
            )}
            {type !== "yoti" ? (
              <Button
                type="primary"
                htmlType="submit"
                form={formId}
                block
                size="large"
              >
                {type === "docs"
                  ? !user.isVerified
                    ? "Next"
                    : "Complete"
                  : "Next"}
              </Button>
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                block
                size="large"
                onClick={onVerifyIdentity}
              >
                Verify Identity
              </Button>
            )}
          </div>
          {console.log("type: ", type)}
          <div className="d-flex flex-column gap-2">
            <div className="b3 text-center">
              {type === "other"
                ? `Identity 1/${
                    user.isVerified ? 3 : 4
                  }`
                : type === "basic"
                ? `Personal 2/${
                    user.isVerified ? 3 : 4
                  }`
                : type === "docs"
                ? `Documents 3/${
                    user.isVerified ? 3 : 4
                  }`
                : `Verification 4/4`}
            </div>
          </div>
        </ConfirmModal>
      )}
      <ConfirmModal
        title="Confirmation"
        open={showConfirmModal}
        onConfirm={() =>
          onAction(
            shiftInfo?.status === "pending"
              ? "cancelJob"
              : "rejectJobInvite"
          )
        }
        confirmButtonText={confrimText}
        cancelButtonText="Cancel"
        onClose={() => setShowConfirmModal(false)}
      >
        <div>
          <div>
            Are you sure you want to {confrimText}
            ?
          </div>
        </div>
      </ConfirmModal>
      <ConfirmModal
        open={showInfoModal}
        onConfirm={() => {
          setShowInfoModal(false);
        }}
        confirmButtonText={
          user.status === "onboarding"
            ? "Let's Go"
            : "Okay"
        }
        image={infoIcon}
        onClose={() => setShowInfoModal(false)}
      >
        <div className="text-center">
          <div className="b1 h2">
            {user.status === "onboarding"
              ? "Before You Start"
              : "Not So Fast"}
          </div>
          <div>{infoText}</div>
        </div>
      </ConfirmModal>
      <DetailsComponent
        shiftDetails={shiftInfo}
      />
      <ContactDetail shiftDetails={shiftInfo} />
      <CoveredExpense shiftDetails={shiftInfo} />
      {shiftInfo?.termConditions && (
        <div
          className="p-5 d-flex flex-column border-bottom"
          style={{ gap: 29 }}
        >
          <div
            className="d-flex align-items-center"
            style={{ gap: 15 }}
          >
            <div className="h5 fw-bold">
              Terms & Condition
            </div>
          </div>
          <div className="p3">
            {shiftInfo?.termConditions}
          </div>
        </div>
      )}
      {(shiftInfo?.status === "pending" ||
        shiftInfo?.status === "unfilled") && (
        <div
          className="p-5 d-flex gap-3 justify-content-end"
          style={{ gap: 29 }}
        >
          <div
            className="d-flex gap-3 justify-content-end"
            style={{ width: "40%" }}
          >
            {(shiftInfo?.currentApplication
              ?.status === "applied" ||
              shiftInfo?.currentApplication
                ?.status === "invited") && (
              <Button
                className="btn-style"
                block
                danger
                type="primary"
                onClick={() => {
                  setShowConfirmModal(true);
                  if (
                    shiftInfo?.status ===
                    "pending"
                  ) {
                    setConfirmText(
                      "Withdraw Application"
                    );
                  } else {
                    setConfirmText(
                      "Cancel Invite"
                    );
                  }
                }}
              >
                {shiftInfo?.status === "pending"
                  ? "Withdraw Application"
                  : "Deny"}
              </Button>
            )}

            {(shiftInfo?.status === "unfilled" ||
              shiftInfo?.currentApplication
                ?.status === "invited") &&
              shiftInfo.isNegotiationAllowed ===
                1 && (
                <Button
                  onClick={() =>
                    setShowNegotiableModal(true)
                  }
                  className="btn-style p-2"
                  block
                >
                  Negotiate
                </Button>
              )}

            {(shiftInfo?.status === "unfilled" ||
              shiftInfo?.currentApplication ===
                null ||
              shiftInfo?.currentApplication
                ?.status === "invited") && (
              <Button
                onClick={() => {
                  if (!user?.isVerified) {
                    console.log("first");
                    ShowVerifyModal();
                    return;
                  }
                  onAction(
                    (shiftInfo?.status ===
                      "unfilled" ||
                      shiftInfo?.currentApplication ===
                        null) &&
                      shiftInfo
                        ?.currentApplication
                        ?.status !== "invited"
                      ? "applyJob"
                      : "acceptJobInvite"
                  );
                }}
                className="btn-style"
                type="primary"
                block
              >
                {(shiftInfo?.status ===
                  "unfilled" ||
                  shiftInfo?.currentApplication ===
                    null) &&
                shiftInfo?.currentApplication
                  ?.status !== "invited"
                  ? "Apply"
                  : "Accept"}
              </Button>
            )}
          </div>
        </div>
      )}
      <div class="mapouter">
        <iframe
          style={{ width: "100%", height: 350 }}
          title="Maps"
          src={`https://maps.google.com/maps?q=${shiftInfo?.latitude}, ${shiftInfo?.longitude}&t=&z=15&ie=UTF8&iwloc=&output=embed`}
        />
      </div>
      {showAccreditionModal && (
        <AdditionalAccrediationForm
          showAccreditionModal={
            showAccreditionModal
          }
          setAccreditionModal={
            setAccreditionModal
          }
          shiftInfo={shiftInfo}
          setAPIFlag={setAPIFlag}
        />
      )}
      <ConfirmModal
        title="Enter Desire Wage"
        description="Enter your preferred hourly rate below for this job post"
        open={showNegotiableModal}
        formId={formId}
        onClose={() =>
          setShowNegotiableModal(false)
        }
        cancelButtonText="Cancel"
        confirmButtonText="Submit"
      >
        <Form
          form={form}
          id={formId}
          layout="vertical"
          autoComplete="off"
          onFinish={(value) =>
            onAction("applyJob", value)
          }
        >
          <Form.Item
            label="Locum Hourly Rate"
            name="locumHourlyRate"
            rules={[
              {
                required: true,
                message:
                  "Please Enter Hourly Rate!",
              },
              () => ({
                validator(rule, value) {
                  if (value >= 1000) {
                    return Promise.reject(
                      "Locum Hourly Rate should be less than 1000"
                    ); // The validator should always return a promise on both success and error
                  } else if (value < 0) {
                    return Promise.reject(
                      "Locum Hourly Rate can not be less than 0"
                    ); // The validator should always return a promise on both success and error
                  }
                  return Promise.resolve(value);
                },
              }),
            ]}
          >
            <Input
              className="text-input-field px-4 t1"
              placeholder="Locum Hourly Rate"
              type="number"
            />
          </Form.Item>
        </Form>
      </ConfirmModal>

      <div className="verify_modal">
        <CommonModal
          title="Verify your Identity to apply for jobs"
          handleModal={openVerifyModal}
          setHandleModal={ShowVerifyModal}
          primaryButtonText="Continue"
          secondaryButtonText="Cancel"
          primaryButtonHandler={onVerifyIdentity}
        >
          <figure className="img_wrap">
            <img src={FaceImage} alt="faceimg" />
          </figure>

          <div className="modal_text">
            <p>
              To Verify your Identity you will
              have to{" "}
            </p>

            <List
              bordered={false}
              dataSource={data}
              renderItem={(item, index) => (
                <List.Item>
                  <strong>{index + 1}.</strong>{" "}
                  <Typography.Text>
                    {item}
                  </Typography.Text>
                </List.Item>
              )}
            />

            <Typography.Text>
              Some instructions....
            </Typography.Text>
          </div>
        </CommonModal>
      </div>
    </div>
  );
};
