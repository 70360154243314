import { Space, Typography } from "antd";
import { ArcElement, Chart } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import BuildingIcongrey from "../../UI/icons/BuildingIcongrey";
Chart.register(ArcElement);

const TotalStaffChart = (props) => {
  const { totalBranches, item1, item2 } = props;
  const data = {
    labels: [item1.name, item2.name],
    datasets: [
      {
        data: [item1.value, item2.value], // Example data values
        backgroundColor: ["#3F83CC", "#51C2BC"],
        hoverBackgroundColor: ["#3F83CC", "#51C2BC"],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
    rotation: -90,
    circumference: 360,
    cutout: "60%",
    maintainAspectRatio: true,
    responsive: true,
    hoverOffset: 5,
    borderWidth: 0,
    hoverBorderWidth: 0,
    borderAlign: "center",
  };

  return (
    <div className="staff-chart-wrap">
      <div className="chart-wrapper">
        <Doughnut data={data} options={options} />
        <div className="chart-data">
          <BuildingIcongrey />
          <p>
            {totalBranches} {totalBranches > 1 ? "Branches" : "Branch"}
          </p>
        </div>
      </div>

      <div className="chart-details-wrap">
        <div className="chart-details">
          <span className="list-dot" style={{ background: "#51C2BC" }}></span>
          <Space direction="vertical">
            <p>{item1.name}</p>
            <Typography.Title level={5}>{item1.value}</Typography.Title>
          </Space>
        </div>
        <div className="chart-details">
          <span className="list-dot" style={{ background: "#3F83CC" }}></span>
          <Space direction="vertical">
            <p>{item2.name}</p>
            <Typography.Title level={5}>{item2.value}</Typography.Title>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default TotalStaffChart;
