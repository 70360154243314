import { Form, Input } from "antd";
import { useState } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { EmptyData } from "../../../../../components/comon/EmptyData";
import { AddModal } from "../../../../../components/UI/modals/addModal";
import { TableComponet } from "../../../../../components/UI/table";
import {
  deleteMileageRulesThunk,
  saveMileageRulesThunk,
} from "../../../../../redux/profile/profileThunk";
import { settingsApiKeys } from "../../../../../utils/apiKeyConstants";
import { hasAccess, notify } from "../../../../../utils/helper";

export const MileageRules = () => {
  const dispatch = useDispatch();
  const [showMileageModal, setShowMileageModal] = useState(false);
  const formId = useMemo(() => "form", []);
  const [form] = Form.useForm();
  const { mileageRules } = useSelector((state) => state.profile);

  const mileageRulesColumn = useMemo(
    () => [
      {
        Header: "Rule Name",
        accessor: "name",
      },

      {
        Header: "Rate (Pence)",
        accessor: "ratePerMile",
      },

      {
        Header: "Pay After (Mile)",
        accessor: "payMileageAfter",
      },
      {
        Header: "Max Mileage (Mile)",
        accessor: "maximumMileage",
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row: { original } }) => {
          return (
            <div>
              <Dropdown icon="ellipsis vertical" pointing="top right">
                <Dropdown.Menu direction="left">
                  <Dropdown.Item
                    text="Edit"
                    onClick={() => {
                      form.setFieldsValue({
                        name: original.name,
                        ratePerMile: original.ratePerMile,
                        payMileageAfter: original.payMileageAfter,
                        maximumMileage: original.maximumMileage,
                        id: original.id,
                      });
                      setShowMileageModal(!showMileageModal);
                    }}
                  />
                  <Dropdown.Item
                    style={{ color: "#EF5350" }}
                    onClick={() => {
                      if (hasAccess(settingsApiKeys.DELETE_MILEAGE_RULES))
                        onDeleteRule(original.id);
                    }}
                    text="Delete"
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    []
  );

  const onSubmit = (values) => {
    dispatch(saveMileageRulesThunk(values)).then((res) => {
      if (res.payload) {
        notify("success", "Mileage Rule Saved");
        setShowMileageModal(false);
        form.resetFields(undefined);
      }
    });
  };

  const onDeleteRule = (id) => {
    dispatch(deleteMileageRulesThunk({ id })).then((res) => {
      if (res.payload.status === "success") {
        notify("success", "Mileage Rule Deleted");
      }
    });
  };

  return (
    <div className="border rounded">
      <div className="text-row d-flex align-items-center justify-content-between">
        <div className="s1 m-0">Mileage</div>
        <div
          className="cursor-pointer"
          onClick={() => setShowMileageModal(true)}
          style={{ color: "#51C2BC" }}
        >
          ADD RULE
        </div>
      </div>
      {mileageRules?.length <= 0 ? (
        <EmptyData title="Mileage Rules" />
      ) : (
        <TableComponet
          rowCursor="pointer"
          isBorderShown
          columns={mileageRulesColumn}
          data={mileageRules}
        />
      )}
      <AddModal
        title="Mileage"
        handleModal={showMileageModal}
        setHandleModal={setShowMileageModal}
        formId={formId}
        primaryButtonText="Save"
      >
        <Form
          onFinish={
            hasAccess(settingsApiKeys.SAVE_MILEAGE_RULES) ? onSubmit : undefined
          }
          form={form}
          id={formId}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            label="Rule Name"
            name="name"
            rules={[{ required: true, message: "Please Enter Rule Name!" }]}
          >
            <Input
              onBlur={(e) =>
                form.setFieldsValue({ name: e.target.value.trim() })
              }
              maxLength={50}
              placeholder="Rule Name"
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>
          <Form.Item
            label="Rate Per Mile (Pence)"
            name="ratePerMile"
            rules={[
              { required: true, message: "Please Enter Rate Per Mile!" },
              () => ({
                validator(rule, value) {
                  if (Number(value) >= 100) {
                    return Promise.reject(
                      "Rate Per Mile should be less than 100"
                    );
                  } else if (Number(value) < 0) {
                    return Promise.reject(
                      "Rate Per Mile can not be less than 0"
                    );
                  }
                  return Promise.resolve(value);
                },
              }),
            ]}
          >
            <Input
              type="number"
              placeholder="Rate Per Mile (Pence)"
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>

          <Form.Item
            label="Pay Mileage After (Mile)"
            name="payMileageAfter"
            rules={[
              {
                required: true,
                message: "Please Enter the Mileage to Pay After!",
              },
              () => ({
                validator(rule, value) {
                  if (value >= 100) {
                    return Promise.reject(
                      "Rate Per Mile should be less than 100"
                    ); // The validator should always return a promise on both success and error
                  } else if (value < 0) {
                    return Promise.reject(
                      "Rate Per Mile can not be less than 0"
                    ); // The validator should always return a promise on both success and error
                  }
                  return Promise.resolve(value);
                },
              }),
            ]}
          >
            <Input
              type="number"
              placeholder="Pay Mileage After"
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>
          <Form.Item
            label="Maximum Mileage (Mile)"
            name="maximumMileage"
            rules={[
              { required: true, message: "Please Enter Maximum Mileage!" },
              () => ({
                validator(rule, value) {
                  if (value >= 100) {
                    return Promise.reject(
                      "Rate Per Mile should be less than 100"
                    ); // The validator should always return a promise on both success and error
                  } else if (value < 0) {
                    return Promise.reject(
                      "Rate Per Mile can not be less than 0"
                    ); // The validator should always return a promise on both success and error
                  }
                  return Promise.resolve(value);
                },
              }),
            ]}
          >
            <Input
              type="number"
              placeholder="Maximum Mileage"
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>
          <Form.Item hidden label="id" name="id">
            <Input
              defaultValue={undefined}
              placeholder="id"
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>
        </Form>
      </AddModal>
    </div>
  );
};
