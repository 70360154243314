import { AutoComplete } from "antd";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  findAddressThunk,
  getAddressDetailsThunk,
} from "../../../redux/branches/branchesThunk";
import "./style.scss";

export const LocationSearchInput = ({
  value,
  name,
  onHandleSelect,
  onAdressChange,
}) => {
  const dispatch = useDispatch();
  const [address, setAddress] = useState("");
  const [addressOptions, setAddressOptions] = useState([]);

  useEffect(() => {
    if (value || value === "") {
      setAddress(value);
    }
  }, [value]);

  const onSelect = (id) => {
    dispatch(getAddressDetailsThunk(id)).then((res) => {
      onHandleSelect({ name, data: res.payload });
    });
  };

  const onSearch = useCallback(
    _.debounce((data) => {
      let placeList = [];
      dispatch(findAddressThunk(data)).then((res) => {
        placeList = res.payload.suggestions?.map((item, idx) => {
          return {
            key: idx,
            label: item.address,
            value: item.id,
          };
        });
        setAddressOptions(placeList);
      });
    }, 500),
    []
  );

  return (
    <AutoComplete
      dropdownStyle={{ zIndex: 2000 }}
      className="text-input-field pe-3 p-1 t1"
      onSelect={onSelect}
      bordered={false}
      value={address}
      options={addressOptions}
      // onChange={(val) => {
      //   // onAdressChange(val);
      // }}
      onSearch={(data) => {
        setAddress(data);
        onSearch(data);
      }}
      placeholder="Address"
    />
  );
};
