
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editVerificationThunk, getStaffVerificationThunk } from "../../../redux/staffs/staffsThunk";
import { VerificationForm } from "../../../components/verifications/verificationForm";
import { Verification } from "../../../components/verifications";
import { AddModal } from "../../../components/UI/modals/addModal";


export const StaffVerification = () => {
    const formId = useMemo(() => "form", [])
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.auth)
    const { staffVerification } = useSelector(state => state.staffs)
    const [showEditInfoModal, setShowEditInfoModal] = useState(false);
    const [actionType, setActionType] = useState('basic')

    useEffect(() => {
        dispatch(getStaffVerificationThunk({ staffId: user.id }));
    }, [dispatch, user]);

    const onSubmit = (formData) => {
        dispatch(
            editVerificationThunk({
                staffId: user.id,
                payload: formData
            })
        ).then(res => {
            setShowEditInfoModal(false)
            setActionType('basic')
        })
    }

    return (
        <>
            <Verification
                showEdit={user.role !== 'locum'}
                staffInfo={user}
                setShowEditInfoModal={setShowEditInfoModal}
                setActionType={setActionType}
                staffVerification={staffVerification}
            />
            <AddModal
                title={`${actionType === 'docs' ? 'Edit Document' : 'Edit Information'}`}
                handleModal={showEditInfoModal}
                setHandleModal={setShowEditInfoModal}
                formId={formId}
                primaryButtonText="Update"
            >
                <VerificationForm
                    isGrouped
                    staffInfo={user}
                    onSubmit={onSubmit}
                    formId={formId}
                    setShowEditInfoModal={setShowEditInfoModal}
                    setActionType={setActionType}
                    actionType={actionType}
                />
            </AddModal>
        </>
    );
};
