import { Button } from "antd";
import React from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "../../../assets/icons/close.svg";
export const ConfirmModal = ({
  open,
  onClose,
  description,
  title,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  children,
  primaryBtnDisable,
  formId,
  image,
  backdrop,
  className,
}) => {
  return (
    <Modal
      centered
      show={open}
      onHide={onClose}
      backdrop={backdrop}
      className={`confirmModal ${className || ""}`}
    >
      <Modal.Body
        className={`${
          image ? "align-items-center gap-2" : "gap-4"
        } rounded  d-flex flex-column t1 confirm_modal`}
        style={{ padding: 30, width: "100%" }}
      >
        {image && <img width={100} src={image} alt="" />}
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="heading4 modal_header">{title}</h3>{" "}
          
        </div>
        <div className="main_modal_body">
          {description && (
            <div className="s1" style={{ color: "#7B7B7B" }}>
              {description}
            </div>
          )}
          {children}
        </div>

        <div
          className="d-flex jusify-content-end align-items-end modal_bottom"
          style={{ flex: "1", width: "100%" }}
        >
          {cancelButtonText && (
            <Button
              onClick={onClose}
              block
              size="large"
              style={{ width: "100%" }}
              className="cancel_btn"
            >
              {cancelButtonText}
            </Button>
          )}
          {confirmButtonText && (
            <Button
              onClick={onConfirm}
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              form={formId}
              block
              size="large"
              className="save"
            >
              {confirmButtonText}
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
