/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import CommonToolbar from "../../../components/Toolbar/CommonToolbar";
import { Heading } from "../../../components/UI/heading";
import { ShiftJobFormNew } from "../../../components/comon/AddShiftFormNew";
import LeaveDetailsTable from "../../../components/schedule_new/Leave/LeaveDetailsTable";
import DayviewNew from "../../../components/schedule_new/views/DayviewNew";
import WeekViewNew from "../../../components/schedule_new/views/WeekViewNew";
import useDebounce from "../../../hooks/useDebounce";
import { getbranchesListThunk } from "../../../redux/branches/branchesThunk";
import {
  getAccommodationAndFoodRulesThunk,
  getMileageRulesThunk,
  getParkingRulesThunk,
  getTransportRulesThunk,
} from "../../../redux/profile/profileThunk";
import { getRegionsListDropDownThunk } from "../../../redux/regions/regionsThunk";
import {
  deleteJobThunk,
  deleteShiftThunk,
  getRolesThunk,
  getScheduleListThunk,
  getStaffsThunk,
} from "../../../redux/schedule/scheduleThunk";
import { getStaffLeavesListThunk } from "../../../redux/staffs/staffsThunk";
import {
  branchApiKeys,
  regionApiKeys,
  settingsApiKeys,
  staffApiKeys,
} from "../../../utils/apiKeyConstants";
import {
  VIEW_OPTIONS,
  defaultRoleOptions,
} from "../../../utils/constant";
import {
  getAllMonthDays,
  getCurrentWeekDays,
} from "../../../utils/dates";
import eventEmitter, {
  events,
} from "../../../utils/eventEmitter";
import {
  hasAccess,
  notify,
} from "../../../utils/helper";
import "./style.scss";

export const SchedulePageNew = () => {
  const [view, setView] = useState("weekly");
  const [
    showAddShiftModal,
    setShowAddshiftModal,
  ] = useState(false);
  const [operatingTime, setOperatingTime] =
    useState();
  const [selectedDate, setSelectedDate] =
    useState(null);
  const [selectId, setSelectedId] = useState();
  const [roleTypes, setRoleTypes] = useState([]);
  const [
    selectedPharmacies,
    setselectedPharmacies,
  ] = useState([]);
  const [filterValues, setFilterValue] = useState(
    {
      regions: [],
      roleId: "",
      status: "",
      branch: "",
    }
  );
  const [roleOptions, setRoleOptions] = useState(
    defaultRoleOptions
  );
  const { roles } = useSelector((state) => {
    return state.schedule;
  });
  const _debouncedFilterVal = useDebounce(
    filterValues,
    500
  );

  const [selectedData, setSelectedData] =
    useState();

  const [showDropdown, setshowDropdown] =
    useState(false);
  const [startDate, setStartDate] = useState(
    moment()
  );
  const [endDate, setEndDate] = useState(
    moment().add(7, "day")
  );
  const [dateRange, setDateRange] = useState({
    startDateRange: startDate,
    endDateRange: endDate,
  });
  const [isMonthGridView, setIsMonthGridView] =
    useState(true);
  const dispatch = useDispatch();
  const [flag, setFlag] = useState(false);

  const { branches } = useSelector((state) => {
    return state.schedule;
  });

  const getWeekArray = useMemo(() => {
    let temp = [];
    if (view === "weekly") {
      temp = getCurrentWeekDays(
        dateRange?.startDateRange,
        (dateRange?.startDateRange, "YYYY-MM-DD"),
        ""
      );
    }

    if (view === "monthly") {
      temp = getAllMonthDays(
        dateRange?.startDateRange,
        dateRange?.endDateRange,
        "YYYY-MM-DD"
      );
    }

    return temp;
  }, [
    dateRange?.endDateRange,
    dateRange?.startDateRange,
    view,
  ]);

  const handleAddShift = (
    operatingTime,
    date,
    id,
    data,
    day,
    _roleType,
    week
  ) => {
    const findOperatingTime = branches?.find(
      (item) => item?.id === id
    );

    setShowAddshiftModal(!showAddShiftModal);
    setOperatingTime(
      findOperatingTime?.operatingTime
    );
    setSelectedDate(date);
    setSelectedId(id);
    setSelectedData(data);
    setRoleTypes(_roleType);
  };

  const fetchScheduleAndLeaves = () => {
    if (
      hasAccess(
        branchApiKeys.GET_ALL_BRANCHES_SCHEDULE_LIST
      )
    ) {
      dispatch(
        getScheduleListThunk({
          endDateRange:
            dateRange?.endDateRange?.format(
              "YYYY-MM-DD"
            ),
          startDateRange:
            dateRange?.startDateRange?.format(
              "YYYY-MM-DD"
            ),
          filters: _debouncedFilterVal,
        })
      ).then((res) => {
        if (res.payload) {
          setFlag(false);
        }
      });

      fetchLeaves();
    }
  };

  const onDelete = (data) => {
    if (data.isJob) {
      dispatch(
        deleteJobThunk({
          jobId: data.id,
        })
      ).then((res) => {
        if (res.payload === "success") {
          setFlag(true);
          fetchScheduleAndLeaves();
          setShowAddshiftModal(false);
          notify(
            "success",
            "Job deleted successfully."
          );
        }
      });
    } else {
      dispatch(
        deleteShiftThunk({
          shiftId: data.id,
          branchId: data.branchId,
        })
      ).then((res) => {
        if (res.payload === "success") {
          fetchScheduleAndLeaves();
          notify(
            "success",
            "Shift deleted successfully."
          );
          setFlag(true);
          setShowAddshiftModal(false);
        }
      });
    }
  };

  const handleToggleMonthView = () => {
    const _isGridView = !isMonthGridView;
    setIsMonthGridView(_isGridView);
  };

  const handleViewChange = (view) => {
    setView(view);
    setshowDropdown(false);
    // setFlag(true);
  };

  const onEditHandler = (data) => {
    if (
      (!data?.staff &&
        data?.staff?.length &&
        data.staff[0]?.id === 100) ||
      data?.staff?.id === 101
    ) {
      return;
    }
    if (
      data.status === "unfilled" ||
      data.status === "pending" ||
      data.status === "active"
    ) {
      setShowAddshiftModal(!showAddShiftModal);
      handleAddShift(
        operatingTime,
        data?.startDate,
        data.branchId,
        data
      );
      return;
    }
    if (data.isJob) {
      if (data.status === "completed") {
        notify(
          "info",
          "Hold Up, Jobs with 'Complete' Status Can Not Be Edited Or Deleted."
        );
      }
      if (data.status === "payment_approved") {
        notify(
          "info",
          "Hold Up, Jobs with 'Payment Approved' Status Can Not Be Edited Or Deleted."
        );
      }
      if (data.status === "paid") {
        notify(
          "info",
          "Hold Up, Jobs with 'Paid' Status Can Not Be Edited Or Deleted."
        );
      }
    }
  };

  const handlePrevious = useCallback(() => {
    if (view === "weekly") {
      const _dateStart = moment(
        dateRange?.startDateRange
      ).subtract(1, "weeks");
      const _dateEnd = moment(
        dateRange?.endDateRange
      ).subtract(1, "weeks");

      setDateRange({
        startDateRange: _dateStart,
        endDateRange: _dateEnd,
      });
    }

    if (view === "monthly") {
      const _dateStart = moment(
        dateRange?.startDateRange
      ).subtract(1, "months");
      const _dateEnd = moment(
        dateRange?.endDateRange
      ).subtract(1, "months");

      setDateRange({
        startDateRange: _dateStart,
        endDateRange: _dateEnd,
      });
    }

    if (view === "daily") {
      const _dateStart = moment(
        dateRange?.startDateRange
      ).subtract(1, "days");
      const _dateEnd = moment(
        dateRange?.endDateRange
      ).subtract(1, "days");

      setDateRange({
        startDateRange: _dateStart,
        endDateRange: _dateEnd,
      });
    }

    setFlag(true);
  }, [
    dateRange?.endDateRange,
    dateRange?.startDateRange,
    view,
  ]);

  const handleNext = useCallback(() => {
    if (view === "weekly") {
      const _dateStart = moment(
        dateRange?.startDateRange
      ).add(1, "weeks");
      const _dateEnd = moment(
        dateRange?.endDateRange
      ).add(1, "weeks");

      setDateRange({
        startDateRange: _dateStart,
        endDateRange: _dateEnd,
      });
    }

    if (view === "monthly") {
      const _dateStart = moment(
        dateRange?.startDateRange
      ).add(1, "months");
      const _dateEnd = moment(
        dateRange?.endDateRange
      ).add(1, "months");

      setDateRange({
        startDateRange: _dateStart,
        endDateRange: _dateEnd,
      });
    }

    if (view === "daily") {
      const _dateStart = moment(
        dateRange?.startDateRange
      ).add(1, "days");
      const _dateEnd = moment(
        dateRange?.endDateRange
      ).add(1, "days");

      setDateRange({
        startDateRange: _dateStart,
        endDateRange: _dateEnd,
      });
    }

    setFlag(true);
  }, [
    dateRange?.endDateRange,
    dateRange?.startDateRange,
    view,
  ]);

  const RenderValue = useMemo(() => {
    if (view === "weekly") {
      return `${dateRange?.startDateRange?.format(
        "DD MMM"
      )} - ${dateRange?.endDateRange?.format(
        "DD MMM YYYY"
      )}`;
    }

    if (view === "monthly") {
      return `${dateRange?.startDateRange?.format(
        "MMM YYYY"
      )}`;
    }

    if (view === "daily") {
      return `${dateRange?.startDateRange?.format(
        "DD MMM YYYY"
      )}`;
    }

    return "";
  }, [
    dateRange?.endDateRange,
    dateRange?.startDateRange,
    view,
  ]);

  useEffect(() => {
    if (selectedPharmacies) {
      dispatch(
        getStaffLeavesListThunk({
          endDateRange:
            dateRange?.endDateRange?.format(
              "YYYY-MM-DD"
            ),
          startDateRange:
            dateRange?.startDateRange?.format(
              "YYYY-MM-DD"
            ),
          branchIds: selectedPharmacies,
        })
      );
    }
  }, [selectedPharmacies]);

  const fetchLeaves = useCallback(() => {
    if (view !== "daily") {
      setTimeout(() => {
        setFlag(true);
        dispatch(
          getStaffLeavesListThunk({
            endDateRange:
              dateRange?.endDateRange?.format(
                "YYYY-MM-DD"
              ),
            startDateRange:
              dateRange?.startDateRange?.format(
                "YYYY-MM-DD"
              ),
            branchIds: selectedPharmacies,
          })
        );
      }, 500);
    }
  }, [
    dateRange,
    view,
    dispatch,
    getStaffLeavesListThunk,
  ]);

  useEffect(() => {
    if (
      hasAccess(
        settingsApiKeys.GET_SETTINGS_ALL_MILEAGE_RULES
      )
    )
      dispatch(getMileageRulesThunk());
    if (
      hasAccess(
        settingsApiKeys.GET_SETTINGS_PARKING_RULES
      )
    )
      dispatch(getParkingRulesThunk());
    if (
      hasAccess(
        settingsApiKeys.GET_ALL_TRANSPORT_RULES
      )
    )
      dispatch(getTransportRulesThunk());
    if (
      hasAccess(
        settingsApiKeys.GET_ALL_FOOD_RULES
      )
    )
      dispatch(
        getAccommodationAndFoodRulesThunk()
      );
    if (
      hasAccess(
        regionApiKeys.GET_REGION_DROP_DOWN_LIST
      )
    )
      dispatch(getRegionsListDropDownThunk());
    dispatch(
      getRolesThunk({
        group: ["staff", "locum"],
        type: "Default",
      })
    );
    if (hasAccess(staffApiKeys.GET_ALL_STAFF))
      dispatch(getStaffsThunk());

    if (
      hasAccess(branchApiKeys.GET_BRANCH_LIST)
    ) {
      dispatch(
        getbranchesListThunk({
          filters: {
            title: "",
          },
          pagination: { page: 1, perPage: 100 },
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    eventEmitter.on(
      events.openEdit,
      onEditHandler
    );
    eventEmitter.on(
      events.fetchLeave,
      fetchLeaves
    );
    return () => {
      eventEmitter.off(events.openEdit, (data) =>
        onEditHandler(data)
      );
      eventEmitter.off(
        events.fetchLeave,
        fetchLeaves
      );
    };
  }, [dispatch, fetchLeaves, onEditHandler]);

  useEffect(() => {
    const dateRangeMap = {
      weekly: {
        startDateRange:
          moment().startOf("isoWeek"),
        endDateRange: moment().endOf("isoWeek"),
      },
      daily: {
        startDateRange: moment(),
        endDateRange: moment(),
      },
      monthly: {
        startDateRange: moment().startOf("month"),
        endDateRange: moment().endOf("month"),
      },
    };

    const selectedDateRange =
      dateRangeMap[view] || dateRangeMap.weekly;

    setDateRange(selectedDateRange);
    setFlag(true);
  }, [view]);

  useEffect(() => {
    if (!flag) return;

    fetchScheduleAndLeaves();
  }, [
    JSON.stringify(_debouncedFilterVal),
    dateRange,
  ]);

  const RenderView = useMemo(() => {
    if (view === "weekly" || view === "monthly") {
      return (
        <>
          <WeekViewNew
            addShift
            onDelete={onDelete}
            handleAddShift={handleAddShift}
            weekArray={getWeekArray}
            branches={branches ?? []}
            roleOptions={roleOptions}
          />
        </>
      );
    }

    if (view === "daily") {
      return (
        <DayviewNew
          addShift
          onDelete={onDelete}
          handleAddShift={handleAddShift}
          branches={branches ?? []}
        />
      );
    }
  }, [view, getWeekArray, branches]);

  const onSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      setFilterValue((prev) => ({
        ...prev,
        branch: e.target.value,
      }));
      setFlag(true);
    }
  };

  const onSearchValueChange = (e) => {
    setFilterValue((prev) => ({
      ...prev,
      branch: e.target.value,
    }));

    setFlag(true);
  };

  const handlejobOptionChange = (value) => {
    setFlag(true);
    setFilterValue({
      ...filterValues,
      status: value,
    });
  };

  const handleRoleOptionsChange = (value) => {
    setFlag(true);
    const _role = roles?.find(
      (item) => item?.id === value
    );

    eventEmitter.emit(
      events.roleId,
      _role?.label
    );
    setFilterValue({
      ...filterValues,
      roleId: value,
    });
  };

  useEffect(() => {
    !showAddShiftModal && setSelectedDate(null);
  }, [showAddShiftModal]);

  return (
    <div className="schedule-page">
      <div
        className="main-wrapper"
        style={{
          height: "100%",
          paddingBottom: "20px",
        }}
      >
        <div className="schedule_new">
          <Heading label="Schedule" />

          <div className="container-fluid">
            <CommonToolbar
              searchInputOnChange={
                onSearchValueChange
              }
              searchPlaceHolder="Search By Branch Name"
              searchInputValue={
                filterValues?.branch
              }
              searchInputonKeyDown={
                onSearchKeyDown
              }
              selectedView={view}
              handleViewChange={handleViewChange}
              handleJoboptionsChange={
                handlejobOptionChange
              }
              selectedJob={filterValues?.status}
              selectedRole={filterValues?.roleId}
              handleRoleOptionsChange={
                handleRoleOptionsChange
              }
              handlePrevious={handlePrevious}
              handleNext={handleNext}
              RenderValue={RenderValue}
              handleToggleMonthView={
                handleToggleMonthView
              }
              isMonthGridView={isMonthGridView}
              type="schedule"
              displayOptions={{
                hideRole: false,
                hideView: false,
                hideFilter: true,
              }}
              viewOptions={VIEW_OPTIONS}
            />
            <LeaveDetailsTable
              startDate={startDate.toISOString()}
              endDate={endDate.toString()}
              weekArray={getWeekArray}
              selectedView={view}
              selectedPharmacies={
                selectedPharmacies
              }
              setselectedPharmacies={
                setselectedPharmacies
              }
            />

            <ShiftJobFormNew
              onSuccess={() =>
                fetchScheduleAndLeaves()
              }
              branchId={selectId}
              onDelete={onDelete}
              operatingTime={operatingTime}
              selectedDate={selectedDate}
              showModal={showAddShiftModal}
              setHandleModal={() =>
                setShowAddshiftModal(
                  !showAddShiftModal
                )
              }
              data={selectedData}
              key={selectedDate}
              roleTypes={roleTypes}
            />

            {RenderView}
          </div>
        </div>
      </div>
    </div>
  );
};
