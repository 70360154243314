import moment from "moment/moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import startRatingIcon from "../../../assets/icons/star-rating.png";
import { Heading } from "../../../components/UI/heading";
import { ConfirmModal } from "../../../components/UI/modals/confirmModal";
import { TableComponet } from "../../../components/UI/table";
import { TableToolbar } from "../../../components/UI/table/tableToolBar";
import { EmptyData } from "../../../components/comon/EmptyData";
import { AddLocumForm } from "../../../components/locum-bank";
import { LocumBankFilterComponent } from "../../../components/locum-bank/filter";
import {
  getLocumBankListThunk,
  removeLocumBankThunk,
} from "../../../redux/locum-bank/locumBankThunk";
import { locumBankApiKeys } from "../../../utils/apiKeyConstants";
import { hasAccess } from "../../../utils/helper";
import NoLocumJob from "../../../components/EmptyStates/NoLocumJob/NoLocumJob";
import EmptylocumBank from "../../../components/EmptyStates/EmptyLocumBank/EmptylocumBank";
import useOpenAddModals from "../../../hooks/useOpenAddModals";


export const LocumBank = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { locumBank } = useSelector((state) => state.locumBank);
  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const {
    count: totalDataCount,
    page,
    pages: totalPagesCount,
    perPage,
    rows: locumBankData,
  } = useMemo(() => locumBank, [locumBank]);

  useEffect(() => {
    if (!page && !perPage) return;
    setPerPageCount(perPage);
    setCurrentPageCount(page);
  }, [page, perPage]);

  const getLocumBankList = useCallback(
    (filterData) => {
      if (hasAccess(locumBankApiKeys.LOCUM_BANK_GET_LIST)) {
        dispatch(
          getLocumBankListThunk({
            filters: {
              name: searchQuery.trim(),
              gphcNumber: filterData?.gphcNumber,
              dob: filterData?.dob && filterData?.dob.format("YYYY-MM-DD"),
              phone: filterData?.phNumber,
              rating: filterData?.rating,
            },
            pagination: {
              page: currentPageCount,
              perPage: perPageCount,
            },
          })
        );
      }
    },
    [currentPageCount, dispatch, perPageCount, searchQuery]
  );

  useEffect(() => {
    if (searchQuery !== "") return;
    getLocumBankList();
  }, [searchQuery, getLocumBankList]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Locum Name",
        accessor: "locum.name",
        Cell: ({ row: { original } }) => {
          return `${original.locum.name} ${original.locum.surname}`;
        },
      },
      {
        Header: "City",
        accessor: "locum.userProfile.city",
        Cell: ({ row: { original } }) => {
          return original.locum.userProfile.city ?? "-";
        },
      },
      {
        Header: "GPHC ID",
        accessor: "locum.gphcNumber",
      },
      {
        Header: "Date of Birth",
        accessor: "locum.userProfile.dob",
        Cell: ({ row: { original } }) => {
          return original.locum.userProfile.dob
            ? moment(original.locum.userProfile.dob).format("DD-MM-YYYY")
            : "-";
        },
      },
      {
        Header: "Phone Number",
        accessor: "locum.userProfile.phone",
      },
      {
        Header: "Rating",
        accessor: "locum.rating",
        Cell: ({ row: { original } }) => {
          return (
            <div
              style={{
                width: "fit-content",
                gap: "3px",
                backgroundColor: "white",
              }}
              className="px-4 lh-1 rounded-pill py-2 border d-flex align-items-center"
            >
              <img src={startRatingIcon} alt="startRatingIcon" />
              {Number(original.locum?.rating) || 0}
            </div>
          );
        },
      },
    ],
    []
  );

  const handleModalToggle = useCallback(() => {
    setShowCreateModal(!showCreateModal);
  }, [showCreateModal]);

  const onSearch = useCallback(
    (e) => {
      getLocumBankList();
    },
    [getLocumBankList]
  );

  const onAddLocumSuccess = () => {
    getLocumBankList();
  };

  const handleConfirmModal = useCallback(
    (med) => {
      setShowConfirmModal(!showConfirmModal);
    },
    [showConfirmModal]
  );

  const formatTrProps = ({ original }) => {
    return {
      onClick: () => {
        navigate(`/staff/${original.locum.id}/details/information`);
      },
    };
  };

  const removeLocumBank = () => {
    dispatch(removeLocumBankThunk(selectedRows)).then((res) => {
      if (res.payload) {
        setShowConfirmModal(false);
        setSelectedRows([]);
        getLocumBankList();
      }
    });
  };

  useOpenAddModals("addlocum",`/locum-bank`,()=>{
    handleModalToggle();
  })

  return (
    <>
      <div>
        <Heading label="Locum Bank" />
        <ConfirmModal
          title={`Do you really want to Remove Locum from Locum Bank?`}
          description={`You are about to remove the selected Locums from Locum Bank. This will Remove your locum from Locum Bank Permanently.`}
          open={showConfirmModal}
          confirmButtonText="Remove"
          cancelButtonText="Cancel"
          onClose={() => setShowConfirmModal(!showConfirmModal)}
          onConfirm={removeLocumBank}
        />
        <div className="">
          <TableToolbar
            searchPlaceholder="Locum Name"
            showSearchField
            FilterComponent={
              <LocumBankFilterComponent onApplyFilter={getLocumBankList} />
            }
            filterButton
            primaryBtnText={
              hasAccess(locumBankApiKeys.ADD_LOCUM_BANK) ? "Add Locum" : ""
            }
            deleteButton={selectedRows.length ? true : false}
            onSearch={onSearch}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            handleConfirmModal={handleConfirmModal}
            primaryBtnHandler={handleModalToggle}
          />
          <div style={{ padding: "5px 24px" }}>
            {locumBank?.rows?.length > 0 ? (
              <TableComponet
                rowCursor="pointer"
                showRowSelection
                isBorderShown
                currentPageCount={currentPageCount}
                perPageCount={perPageCount}
                setSelectedRows={setSelectedRows}
                totalDataCount={totalDataCount}
                setCurrentPage={setCurrentPageCount}
                setPerPageCount={setPerPageCount}
                showPagination
                maxPageCount={totalPagesCount}
                columns={columns}
                data={locumBankData || []}
                formatRowProps={formatTrProps}
              />
            ) : (
              <div style={{ width: "100%", marginTop: "60px" }}>
              <EmptylocumBank/>
               
              </div>
            )}
          </div>
        </div>
      </div>
      <AddLocumForm
        onAddLocumSuccess={onAddLocumSuccess}
        isOpen={showCreateModal}
        handleModalToggle={handleModalToggle}
      />
    </>
  );
};
