import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStaffTimeSheetThunk } from "../../redux/staffs/staffsThunk";
import { personalApiKeys } from "../../utils/apiKeyConstants";
import { hasAccess } from "../../utils/helper";
import { EmptyData } from "../comon/EmptyData";
import { TableComponet } from "../UI/table";
import NoStaff from "../EmptyStates/NoStaff/Nostaff";

export const StaffAttendancesTable = memo(
  ({
    searchQuery,
    setSelectedRows,
    setAttendanceAtcion,
    setShowMultiSelectBtn,
    rerender,
    setRerender,
  }) => {
    const dispatch = useDispatch();
    const { staffTimeSheet } = useSelector((state) => state.staffs);
    const [currentPageCount, setCurrentPageCount] = useState(1);
    const [perPageCount, setPerPageCount] = useState(10);

    const attendanceColumns = useMemo(
      () => [
        {
          Header: "Name",
          accessor: "user.fullName",
        },
        {
          Header: "Role",
          accessor: "user.userRole.label",
        },
        {
          Header: "Branch",
          accessor: "branch.title",
        },
        {
          Header: "Date",
          accessor: "date",
          Cell: ({ row: { original } }) => {
            return original.date
              ? moment(original.date).format("DD-MM-YYYY")
              : "-";
          },
        },
        {
          Header: "Previous Slot",
          accessor: "actualCheckIn",
          Cell: ({ row: { original } }) => {
            return (
              (original.actualCheckIn
                ? moment(original.actualCheckIn, "HH:mm:ss").format("HH:mm")
                : "00:00") +
              " - " +
              (original.actualCheckOut
                ? moment(original.actualCheckOut, "HH:mm:ss").format("HH:mm")
                : "00:00")
            );
          },
        },
        {
          Header: "Requested Slot",
          accessor: "manualCheckIn",
          Cell: ({ row: { original } }) => {
            return (
              moment(original.manualCheckIn, "HH:mm:ss").format("HH:mm") +
              " - " +
              moment(original.manualCheckOut, "HH:mm:ss").format("HH:mm")
            );
          },
        },
        {
          Header: "",
          accessor: "action",
          Cell: ({ row: { original } }) => {
            return (
              <div className="d-flex" style={{ gap: "5px" }}>
                <button
                  style={{
                    padding: "7px 16px",
                    borderRadius: "30px",
                    border: "none",
                    backgroundColor: "#FFEBEE",
                    color: "#B71B1C",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const id = [original.id];
                    setSelectedRows(id);
                    setAttendanceAtcion("reject");
                  }}
                >
                  Reject
                </button>
                <button
                  style={{
                    padding: "7px 16px",
                    borderRadius: "30px",
                    border: "none",
                    backgroundColor: "#E8F5E9",
                    color: "#1B5E1F",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const id = [original.id];
                    setSelectedRows(id);
                    setAttendanceAtcion("accept");
                  }}
                >
                  Accept
                </button>
              </div>
            );
          },
        },
      ],
      []
    );

    const { attendance } = useMemo(() => staffTimeSheet, [staffTimeSheet]);

    const {
      count: totalDataCount,
      page,
      pages: totalPagesCount,
      perPage,
      rows: attendanceData,
    } = useMemo(() => (attendance ? attendance : {}), [attendance]);

    useEffect(() => {
      if (!page && !perPage) return;
      setPerPageCount(perPage);
      setCurrentPageCount(page);
    }, [page, perPage]);

    useEffect(() => {
      if (!(searchQuery === "" || rerender)) return;
      if (hasAccess(personalApiKeys.ATTENDANCE_LIST))
        dispatch(
          getStaffTimeSheetThunk({
            filters: {
              staffIds: [],
              deviceType: "web",
              status: "pending",
              name: searchQuery.trim(),
            },
            pagination: { page: currentPageCount, perPage: perPageCount },
          })
        );
      return setRerender(false);
    }, [
      dispatch,
      perPageCount,
      currentPageCount,
      searchQuery,
      rerender,
      setRerender,
    ]);

    return (
      <div style={{ padding: "0px 24px" }}>
        {attendance?.rows?.length > 0 ? (
          <TableComponet
            rowCursor="pointer"
            isBorderShown
            currentPageCount={currentPageCount}
            setSelectedRows={setSelectedRows}
            setShowMultiSelectBtn={setShowMultiSelectBtn}
            perPageCount={perPageCount}
            totalDataCount={totalDataCount}
            setCurrentPage={setCurrentPageCount}
            setPerPageCount={setPerPageCount}
            showRowSelection
            showPagination
            maxPageCount={totalPagesCount}
            columns={attendanceColumns}
            data={attendance?.rows}
          />
        ) : (
          <div style={{ border: "1px solid #E9E9E9", paddingTop: "80px" , minHeight:"500px"  }}>
            {/* <EmptyData title="Staff Attendance" />
             */}
             <NoStaff hideButton text='No Staff Attendance Found '  />
          </div>
        )}
      </div>
    );
  }
);
