import React, { useMemo } from "react";
import RenderFileIcon from "../../../comon/RenderFileIcon";

const FileName = ({ filename, type, size, filePath }) => {
  const rendericon = useMemo(() => {
    if (filePath?.length === 0 || filePath === null || filePath === undefined) {
      return <>No file uploaded</>;
    }

    return (
      <div className="file_name">
        {/* <a href={filePath} target="_blank" rel="noreferrer"> */}
        <RenderFileIcon filePath={filePath} />
        {/* </a> */}
      </div>
    );
  }, [filePath]);

  return rendericon;
};

export default FileName;
