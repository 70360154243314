import { Switch } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlexRow } from "../../../components/comon/flexRows";
import {
  getLocumSettingDataThunk,
  updateLocumSettingDataThunk,
} from "../../../redux/locum-flow/Thunk";

export const StaffSetting = () => {
  const dispatch = useDispatch();
  const { settingInfo } = useSelector((state) => state.locum);

  useEffect(() => {
    dispatch(getLocumSettingDataThunk());
  }, [dispatch]);

  const updateSettings = (value, name) => {
    dispatch(
      updateLocumSettingDataThunk({
        [name]: value,
      })
    );
  };

  return (
    <div className="d-flex flex-column p-4 flex-1" style={{ gap: 30 }}>
      <div className="heading4">Notifications</div>
      <div className="border rounded">
        <div className="text-row d-flex align-items-center justify-content-between">
          <div className="s1 m-0">Daily Job Summary Emails</div>
        </div>
        <FlexRow
          primaryTitle="On"
          primaryDescription={
            <Switch
              onChange={(value) => updateSettings(value, "send_daily_emails")}
              checked={settingInfo.send_daily_emails}
              size="default"
            />
          }
        />
      </div>
      <div className="border rounded">
        <div className="text-row d-flex align-items-center justify-content-between">
          <div className="s1 m-0">
            Send me Emails When I am Invited to a Job
          </div>
        </div>
        <FlexRow
          primaryTitle="On"
          primaryDescription={
            <Switch
              onChange={(value) => updateSettings(value, "send_invite_emails")}
              checked={settingInfo.send_invite_emails}
              size="default"
            />
          }
        />
      </div>
    </div>
  );
};
