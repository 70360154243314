import React from 'react';

const BuildingIcongrey = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M4.96733 11.9997H3.26733C2.314 11.9997 1.83398 11.5197 1.83398 10.5663V2.76636C1.83398 1.81302 2.314 1.33301 3.26733 1.33301H6.13399C7.08733 1.33301 7.5673 1.81302 7.5673 2.76636V3.99967"
        stroke="#555555"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0801 5.61332V13.0533C12.0801 14.1267 11.5468 14.6667 10.4735 14.6667H6.58012C5.50679 14.6667 4.9668 14.1267 4.9668 13.0533V5.61332C4.9668 4.53999 5.50679 4 6.58012 4H10.4735C11.5468 4 12.0801 4.53999 12.0801 5.61332Z"
        stroke="#555555"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.43359 3.99967V2.76636C9.43359 1.81302 9.91357 1.33301 10.8669 1.33301H13.7336C14.6869 1.33301 15.1669 1.81302 15.1669 2.76636V10.5663C15.1669 11.5197 14.6869 11.9997 13.7336 11.9997H12.0802"
        stroke="#555555"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.16602 7.33301H9.83268"
        stroke="#555555"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.16602 9.33301H9.83268"
        stroke="#555555"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 14.667V12.667"
        stroke="#555555"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BuildingIcongrey;
