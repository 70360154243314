import { API } from "../../../axios";

export const getBranchesList = (payload) => {
  const { filters, pagination } = payload;
  const request = API.post(`/branches/list`, {
    filters,
    pagination,
  });
  return request;
};

export const getBranchData = ({ id }) => {
  const request = API.get(`/branches/${id}`);
  return request;
};

export const getBranchStaffList = (payload) => {
  const { id, pagination } = payload;
  const request = API.post(`/branches/${id}/staff`, {
    pagination,
  });
  return request;
};

export const getBranchBlockList = (payload) => {
  const { id, pagination } = payload;
  const request = API.post(`/locums/${id}/blocked/list`, {
    pagination,
  });
  return request;
};

export const getBranchRelatedData = () => {
  const request = API.get(`/branches/get-data`);
  return request;
};

export const createBranch = (payload) => {
  const request = API.post(`/branches`, payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return request;
};
export const branchDashboard = (payload) => {
  const { id, startDate, endDate } = payload;
  const request = API.post(`/branches/${id}/dashboard`, { startDate, endDate });
  return request;
};
export const getBranchGapsData = (payload) => {
  const { id, startDateRange, endDateRange } = payload;
  const request = API.post(`/branches/${id}/branch/gaps`, {
    startDateRange,
    endDateRange,
  });
  return request;
};


export const getPendingJobApprovalData = (payload) => {
  const { filters } = payload;
  const request = API.post(`/jobs/list`, {
    filters,
  });
  return request;
};

export const blockLocum = (payload) => {
  const { id, reqData } = payload;
  const request = API.post(`/locums/${id}/block`, reqData);
  return request;
};

export const unBlockLocum = (payload) => {
  const { id, locumIds } = payload;
  const request = API.post(`/locums/${id}/unblock`, locumIds);
  return request;
};

export const deleteBranch = (payload) => {
  const request = API.delete(`/branches`, { data: payload });
  return request;
};

export const archiveBranch = (payload) => {
  const { branchIds } = payload;
  const request = API.post(`/branches/archive/branches`, branchIds);
  return request;
};

export const unArchiveBranch = (payload) => {
  const { branchIds } = payload;
  const request = API.post(`/branches/un-archive/branches`, branchIds);
  return request;
};

export const updateBranch = (payload) => {
  const { id, formData } = payload;
  const request = API.post(`/branches/${id}`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return request;
};

export const updateBranchOperatingTime = (payload) => {
  const { id, data } = payload;
  const request = API.post(`/branches/${id}/operating-time`, data);
  return request;
};


export const getBranchSchedule = (payload) => {
  const { id, data } = payload;
  const request = API.post(`branches/${id}/schedule/list`, data);
  return request;
};

export const getBranchTemplate = (payload) => {
  const { id } = payload;
  const request = API.get(`branches/${id}/template/`);
  return request;
};

export const addStaffAbsence = (payload) => {
  const request = API.post(`branches/schedule/absence`, payload);
  return request;
};

export const applyBranchGap = (payload) => {
  const { id, data } = payload;
  const request = API.post(`branches/${id}/schedule-save-bulk/gap`, data);
  return request;
};


export const addTemplateShift = (payload) => {
  const { id, data } = payload;
  const request = API.post(`branches/${id}/template/`, data);
  return request;
};


export const actionTemplateWeek = (payload) => {
  const { id, data } = payload;
  const request = API.post(`branches/${id}/template/action`, data);
  return request;
};

export const publishTemplate = (payload) => {
  const { id, data } = payload;
  const request = API.post(`branches/${id}/template/publish`, data);
  return request;
};

export const deleteTemplateShift = (payload) => {
  const { branchId, shiftId, } = payload;
  const request = API.delete(`branches/${branchId}/template/${shiftId}`);
  return request;
};

export const editTemplateShift = (payload) => {
  const { branchId, shiftId, data } = payload;
  const request = API.post(`branches/${branchId}/template/${shiftId}`, data);
  return request;
};

export const editBranchOperatingTime = (payload) => {
  const { id, data } = payload;
  const request = API.post(`branches/${id}/operating-time-change`, data);
  return request;
};

export const findAddress = (payload) => {
  const request = API.get(`${process.env.REACT_APP_ADDRESS_FINDER_URL}/autocomplete/${payload}?api-key=${process.env.REACT_APP_ADDRESS_FINDER_KEY}`, {
    loader: false,
  });
  return request;
};

export const getAddressDetails = (payload) => {
  const request = API.get(`${process.env.REACT_APP_ADDRESS_FINDER_URL}/get/${payload}?api-key=${process.env.REACT_APP_ADDRESS_FINDER_KEY}`, {
    loader: false,
  });
  return request;
};


export const exportBranchGapsCSV = (payload) => {
  const { id, filter } = payload
  const request = API.post(`/branches/${id}/gaps/csv-export`,
    filter,
  );
  return request;
};

