import { Form, Input, Select } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addStaffThunk,
  getGphcInfoThunk,
  getStaffRelatedDataThunk,
  getStaffsListThunk,
} from "../../redux/staffs/staffsThunk";
import {
  adminApiKeys,
  authApiKeys,
  inviteApiKeys,
} from "../../utils/apiKeyConstants";
import { hasAccess, notify } from "../../utils/helper";
import { AddModal } from "../UI/modals/addModal";

export const CreatStaff = ({ showCreateModal, handleModalToggle }) => {
  const [form] = Form.useForm();
  const formId = useMemo(() => "addStaff", []);
  const { regions, branches, roles } = useSelector(
    (state) => state.staffs.staffRelatedData
  );
  const roleId = Form.useWatch("roleId", form);
  const dispatch = useDispatch();
  const [gphcInfo, setGphcInfo] = useState({});
  const gphcNumber = Form.useWatch("gphcNumber", form);

  useEffect(() => {
    if (hasAccess(adminApiKeys.GET_INVITE_DATA)) {
      dispatch(getStaffRelatedDataThunk());
    }
  }, []);

  const onFinish = (values) => {
    dispatch(addStaffThunk(values)).then((res) => {
      if (res.payload.status === "success") {
        notify("success", "Sign In link sent to your Email!");
        handleModalToggle();
        form.resetFields();
        window.analytics.track('Staff Added', {
          role: roles.find(i => i.id === values.roleId).label,
          gphcNumber: (values.roleId === 8 || values.roleId === 9 || values.roleId === 10) ? undefined : values.gphcNumber,
          email: values.email,
          phone: values.phone,
          branch: branches.find(i => i.id === values.branchId).title,
          region: regions.find(i => i.id === values.regionId).title,
        });
        dispatch(
          getStaffsListThunk({
            pagination: { page: 1, perPage: 10 },
          })
        );
      }
    });
  };

  useEffect(() => {
    if (Number(gphcNumber?.length) !== 7) {
      form.setFieldsValue({
        name: "",
        surname: "",
        roleId: null,
      });
      setGphcInfo(undefined);
      return;
    }

    if (hasAccess(authApiKeys.GET_GPHC_INFO))
      dispatch(getGphcInfoThunk({ gphcNumber })).then((res) => {
        if (!res.payload) {
          form.setFieldsValue({
            name: "",
            surname: "",
            roleId: null,
          });
          setGphcInfo(undefined);
          return;
        }
        setGphcInfo(res.payload);
      });
  }, [dispatch, gphcNumber]);

  useEffect(() => {
    if (_.isEmpty(gphcInfo)) return;
    if (!gphcInfo.type) {
      notify(
        "error",
        "There seems to be a problem with the GPHC number you entered; contact the system administrator for support"
      );
      form.setFieldsValue({
        name: "",
        surname: "",
        roleId: null,
      });
      return;
    }
    if (gphcInfo.type === "pharmacist") {
      form.setFieldsValue({
        roleId: 6,
      });
    } else {
      form.setFieldsValue({
        roleId: 7,
      });
    }

    form.setFieldsValue({
      name: gphcInfo.forenames,
      surname: gphcInfo.surname,
    });
  }, [gphcInfo]);

  return (
    <AddModal
      formId={formId}
      title={hasAccess(inviteApiKeys.CREATE_INVITE) ? `Add Staff` : undefined}
      handleModal={showCreateModal}
      setHandleModal={handleModalToggle}
      primaryButtonText={
        hasAccess(inviteApiKeys.CREATE_INVITE) ? `Send Invite` : undefined
      }
      secondaryButtonText="Cancel"
      secondaryButtonHandler={handleModalToggle}
    >
      <div style={{ width: "100%" }}>
        <Form
          id={formId}
          onFinish={onFinish}
          form={form}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            label="Role"
            name="roleId"
            rules={[{ required: true, message: "Please Select Role!" }]}
          >
            <Select
              disabled={gphcInfo?.type}
              aria-multiline
              dropdownStyle={{ zIndex: 2000 }}
              placeholder="Role"
              bordered={false}
              className="text-input-field  py-1 t1"
            >
              {roles.map((data, index) => (
                <Select.Option key={index} value={data.id}>
                  {data.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="GPhC Number"
            name="gphcNumber"
            hidden={roleId === 8 || roleId === 9 || roleId === 10}
            normalize={(value) => {
              if (value.length > 7) return value.slice(0, 7);
              return value;
            }}
            rules={[
              roleId === 8 || roleId === 9 || roleId === 10
                ? {}
                : {
                  required: true,
                  message: "Please Enter GPhC Number!",
                },
            ]}
          >
            <Input
              onBlur={(e) =>
                form.setFieldsValue({ gphcNumber: e.target.value.trim() })
              }
              type="number"
              className="text-input-field p-2 t1"
              placeholder="GPhC Number"
            />
          </Form.Item>
          {(roleId === 8 || roleId === 9 || roleId === 10) && (
            <div
              style={{ width: "100%" }}
              className="d-flex align-items-center gap-2"
            >
              <Form.Item
                label="First Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter First Name!",
                  },
                ]}
              >
                <Input
                  onBlur={(e) =>
                    form.setFieldsValue({ name: e.target.value.trim() })
                  }
                  className="text-input-field p-2 t1"
                  placeholder="First Name"
                />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="surname"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Last Name!",
                  },
                ]}
              >
                <Input
                  onBlur={(e) =>
                    form.setFieldsValue({ surname: e.target.value.trim() })
                  }
                  className="text-input-field p-2 t1"
                  placeholder="Last Name"
                />
              </Form.Item>
            </div>
          )}
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please Enter Email Address!",
              },
            ]}
          >
            <Input
              onBlur={(e) =>
                form.setFieldsValue({ email: e.target.value.trim() })
              }
              className="text-input-field p-2 t1"
              placeholder="Email Address"
            />
          </Form.Item>
          <Form.Item
            label="Phone No."
            name="phone"
            rules={[
              {
                required: true,
                message: "Please Enter Phone No.!",
              },
            ]}
          >
            <Input
              onBlur={(e) =>
                form.setFieldsValue({ phone: e.target.value.trim() })
              }
              type="number"
              className="text-input-field p-2 t1"
              placeholder="Phone No."
            />
          </Form.Item>
          <Form.Item
            label="Branch"
            name="branchId"
            rules={[{ required: true, message: "Please Select Branch!" }]}
          >
            <Select
              dropdownStyle={{ zIndex: 2000 }}
              placeholder="Branch"
              bordered={false}
              className="text-input-field py-1 t1"
            >
              {branches.map((data, index) => (
                <Select.Option key={index} value={data.id}>
                  {data.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Region"
            name="regionId"
          >
            <Select
              aria-multiselectable={true}
              dropdownStyle={{ zIndex: 2000 }}
              placeholder="Region"
              bordered={false}
              className="text-input-field py-1 t1"
            >
              {regions.map((data, index) => (
                <Select.Option key={index} value={data.id}>
                  {data.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </div>
    </AddModal>
  );
};
