import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isloading: false,
}

const loaderSlicer = createSlice({
    name: "loaderSlicer",
    initialState: initialState,
    reducers: {
        startLoading(state) {
            state.isloading = true
        },
        stopLoading(state) {
            state.isloading = false
        }
    },
})

export const { startLoading, stopLoading } = loaderSlicer.actions

export const loaderReducer = loaderSlicer