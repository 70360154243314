import { createAsyncThunk } from "@reduxjs/toolkit";
import { signin, authUserRequest, getAccessibleModules, getAccessibleApis, resetPasswordReq, resetPassword, logOutUser, signUp, deleteUser } from "../authAPI";
import cookies from "browser-cookies";

export const setAccessToken = (token) => {
    cookies.set("accessToken", token, { expires: 365 });
};

export const setRefreshToken = (token) => {
    cookies.set("refreshToken", token, { expires: 365 });
};

export const signinThunk = createAsyncThunk("signin", async (payload) => {
    const response = await signin(payload)
    setAccessToken(response.data.data.access);
    setRefreshToken(response.data.data.refresh);
    return response.data.data
})

export const authUserRequestThunk = createAsyncThunk("authUserRequest", async (payload) => {
    const response = await authUserRequest(payload)
    return response.data.data
})

export const getAccessibleModulesThunk = createAsyncThunk("getAccessibleModules", async (payload) => {
    const response = await getAccessibleModules(payload)
    return response.data.data
})


export const getAccessibleApisThunk = createAsyncThunk("getAccessibleApis", async (payload) => {
    const response = await getAccessibleApis(payload)
    return response.data.data
})

export const resetPasswordReqThunk = createAsyncThunk("resetPasswordReq", async (payload) => {
    const response = await resetPasswordReq(payload)
    return response.data
})

export const resetPasswordThunk = createAsyncThunk("resetPassword", async (payload) => {
    const response = await resetPassword(payload)
    return response.data
})

export const logOutUserThunk = createAsyncThunk("logOutUserThunk", async () => {
    const response = await logOutUser()
    if (response.data.status === "success") {
        cookies.erase('accessToken');
        cookies.erase('refreshToken');
    }
    return response.data.data
})

export const signUpThunk = createAsyncThunk("signUp", async (payload) => {
    const response = await signUp(payload)
    return response.data
})

export const deleteUserThunk = createAsyncThunk("deleteUser", async (payload) => {
    const response = await deleteUser(payload)
    if (response.data.status === "success") {
        cookies.erase('accessToken');
        cookies.erase('refreshToken');
    }
    return response.data.data
})