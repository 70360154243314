import React, {
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import {
  Outlet,
  generatePath,
  useParams,
} from "react-router-dom";
import { BranchCard } from "../../../../components/UI/cards/profileCard";
import { ContentContainer } from "../../../../components/UI/container";
// import { Sidebar } from "../../../../components/UI/sideBar";
import { Breadcrumb } from "antd";
// import RefreshIcon from "../../../../assets/icons/refresh.svg";
import BadgeIcon from "../../../../components/UI/icons/BadgeIcon";
import CalendarIcon from "../../../../components/UI/icons/CalendarIcon";
import ContractIcon from "../../../../components/UI/icons/ContractIcon";
import DocumentIcon from "../../../../components/UI/icons/DocumentIcon";
import ProfileIcon from "../../../../components/UI/icons/ProfileIcon";
import { SidebarNew } from "../../../../components/UI/sidebar-new/SidebarNew";
import { getRegionsBranchesListThunk } from "../../../../redux/regions/regionsThunk";
import { getRolesThunk } from "../../../../redux/schedule/scheduleThunk";
import {
  getStaffAccrediationsThunk,
  getStaffDataThunk,
  getStaffRelatedDataThunk,
  staffStatusChangeThunk,
} from "../../../../redux/staffs/staffsThunk";
import { RouterConfig } from "../../../../routerConfig";
import {
  inviteApiKeys,
  staffApiKeys,
} from "../../../../utils/apiKeyConstants";
import { THUNK_STATUS } from "../../../../utils/constant";
import { hasAccess } from "../../../../utils/helper";
import { moduleKeys } from "../../../../utils/modulesKeyConstants";
import "./style.scss";

export const StaffDetails = () => {
  const dispatch = useDispatch();
  const { staffId } = useParams();
  const { staffInfo, staffInfoStatus } =
    useSelector((state) => state.staffs);

  useEffect(() => {
    if (
      hasAccess(staffApiKeys.GET_STAFF_PROFILE)
    ) {
      dispatch(getStaffDataThunk({ staffId }));
    }
    if (
      hasAccess(inviteApiKeys.GET_SHORT_BRANCHES)
    )
      dispatch(getRegionsBranchesListThunk());
    dispatch(getStaffRelatedDataThunk());
    dispatch(
      getStaffAccrediationsThunk({ staffId })
    );
    dispatch(
      getRolesThunk({
        group: ["staff"],
        type: "Default",
      })
    );
  }, [dispatch, staffId]);

  const sidebarLinks = useMemo(
    () => [
      {
        text: "Personal Information",
        to: generatePath(
          RouterConfig.branchAdminStaffInformation,
          { staffId }
        ),
        key: moduleKeys.STAFF_PERSONAL_INFORMATION,
        disabled: false,
        hidden: false,
      },
      {
        text: "Verification",
        to: generatePath(
          RouterConfig.branchAdminStaffVerification,
          {
            staffId,
          }
        ),
        key: moduleKeys.STAFF_VERIFICATION,
        disabled: false,
        hidden: false,
      },
      {
        ...(staffInfo?.group === "staff" && {
          text: "Schedule",
          to: generatePath(
            RouterConfig.branchAdminStaffSchedule,
            { staffId }
          ),
          key: moduleKeys.STAFF_SCHEDULE,
          disabled: false,
          hidden: false,
        }),
      },
      {
        ...(staffInfo?.group === "staff" && {
          text: "Leave",
          to: generatePath(
            RouterConfig.branchAdminStaffAbsence,
            { staffId }
          ),
          key: moduleKeys.STAFF_LEAVES,
          disabled: false,
          hidden: false,
        }),
      },
      {
        ...(staffInfo?.group === "staff" && {
          text: "Document",
          to: generatePath(
            RouterConfig.branchAdminStaffDocument,
            { staffId }
          ),
          key: moduleKeys.BRANCH_INFORMATION,
          disabled: false,
          hidden: false,
        }),
      },
      {
        ...(staffInfo?.group === "staff" && {
          text: "Contract",
          to: generatePath(
            RouterConfig.branchAdminStaffContract,
            { staffId }
          ),
          key: moduleKeys.STAFF_CONTRACT,
          disabled: false,
          hidden: false,
        }),
      },
      {
        ...(staffInfo?.group === "staff" && {
          text: "Time Sheet",
          to: generatePath(
            RouterConfig.branchAdminStaffTimeSheet,
            { staffId }
          ),
          key: moduleKeys.STAFF_TIME_SHEET,
          disabled: false,
          hidden: false,
        }),
      },
    ],
    [staffId, staffInfo?.group]
  );

  const sidebarLinksNew = useMemo(
    () => [
      {
        text: "Employee Info",
        to: generatePath(
          RouterConfig.branchAdminStaffInformation,
          { staffId }
        ),
        key: moduleKeys.STAFF_PERSONAL_INFORMATION,
        disabled: false,
        hidden: false,
        icon: <ProfileIcon />,
      },
      {
        text: "Absence",
        to: generatePath(
          RouterConfig.branchAdminStaffAbsence,
          {
            staffId,
          }
        ),
        key: moduleKeys.STAFF_LEAVES,
        disabled: false,
        hidden: false,
        icon: <CalendarIcon />,
      },
      {
        ...(staffInfo?.group === "staff" && {
          text: "Documents",
          to: generatePath(
            RouterConfig.branchAdminStaffDocument,
            { staffId }
          ),
          key: moduleKeys.STAFF_VERIFICATION,
          disabled: false,
          hidden: false,
          icon: <DocumentIcon />,
        }),
      },
      {
        ...(staffInfo?.group === "staff" && {
          text: "Contract",
          to: generatePath(
            RouterConfig.branchAdminStaffContract,
            {
              staffId,
            }
          ),
          key: moduleKeys.STAFF_CONTRACT,
          disabled: false,
          hidden: false,
          icon: <ContractIcon />,
        }),
      },

      {
        ...(staffInfo?.group === "staff" && {
          text: "Performance Review",
          to: generatePath(
            RouterConfig.branchAdminStaffPerformanceReview,
            {
              staffId,
            }
          ),
          key: moduleKeys.STAFF_VERIFICATION,
          disabled: false,
          hidden: false,
          icon: <BadgeIcon />,
        }),
      },
    ],
    [staffId, staffInfo?.group]
  );
  const { accessibleModules: modules } =
    useSelector((state) => state.auth);
  const [
    displaySidebarLinks,
    setDisplaySidebarLinks,
  ] = useState([]);

  useEffect(() => {
    if (!modules) return;
    let modulesArray = Object.values(modules);
    let linksToDisplay = [];

    for (let link of sidebarLinksNew) {
      for (let module of modulesArray) {
        if (
          link?.key?.toLowerCase() ===
          module?.moduleKey?.toLowerCase()
        ) {
          linksToDisplay.push(link);
        }
      }
    }

    setDisplaySidebarLinks(linksToDisplay);
  }, [modules, sidebarLinksNew]);

  const handleDeactivateAccount = () => {
    dispatch(
      staffStatusChangeThunk({
        id: staffInfo?.id,
        data: {
          activate:
            staffInfo?.status === "active"
              ? false
              : true,
        },
      })
    );
  };

  return (
    <div className="content-view-page details-page">
      <div className="breadcrumb-wrapper">
        <Breadcrumb className="admin-breadcrumb">
          <Breadcrumb.Item>
            All Staff
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Staff Details
          </Breadcrumb.Item>
        </Breadcrumb>

        {/* <div className="last_updated">
          <i>
            <img src={RefreshIcon} alt="refresh" />
          </i>
          <p>Last Updated 10 min ago</p>
        </div> */}
      </div>
      {staffInfoStatus ===
      THUNK_STATUS.loading ? null : (
        <div className="main-wrapper">
          <div className="content-holder">
            <SidebarNew
              links={displaySidebarLinks}
              visible
              activateButtonText={
                staffInfo?.status === "active"
                  ? "Deactivate Account"
                  : "Activate account"
              }
              handleDeactivateAccount={() =>
                handleDeactivateAccount()
              }
              // completeProfileSection={<CreateProfileCard isGrouped />}
            >
              <BranchCard
                staffInfo={staffInfo}
                // userStatus={staffInfo?.isVerified}
                userStatus={
                  staffInfo?.status === "active"
                    ? 1
                    : 0
                }
                isBranch
                logo={staffInfo?.image}
                primaryText={`${staffInfo?.name} ${staffInfo?.surname}`}
                secondaryText={staffInfo?.label}
                hasRating={
                  staffInfo?.group === "locum"
                }
                stats={{
                  completed:
                    staffInfo?.stats
                      ?.completedShifts,
                  cancelled:
                    staffInfo?.stats
                      ?.cancelledShifts,
                  rating:
                    staffInfo?.rating?.value,
                }}
                showStatus={true}
                gphc_no={staffInfo?.gphcNumber}
              />
            </SidebarNew>
            <ContentContainer className="content-container">
              <Outlet />
            </ContentContainer>
          </div>
        </div>
      )}
    </div>
  );
};
