import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { AdminHeader } from "../../components/UI/header/AdminHeader";
import { GlobalLoader } from "../../components/UI/spiner/globalLoader";
import { PrivateRoute } from "../../components/componentTypes/PrivateComponent";
import { RouterConfig } from "../../routerConfig";
import { moduleKeys } from "../../utils/modulesKeyConstants";
import { BranchPage } from "./branches";
import { Dashboard } from "./dashboard";
import { LocumBank } from "./locum-bank";
import LocumJobsNew from "./locum-jobs-new";
import { LocumShifts } from "./locum-shifts";
import { ProfilePage } from "./profile";
import { RegionsPageNew } from "./regions";
import { SchedulePage } from "./schedule";
import { SchedulePageNew } from "./schedule-new";
import DayviewTablefullscreen from "./schedule-new/DayviewTablefullscreen";
import { StaffPage } from "./staffs";
import TimeSheetNew from "./timesheet-new/TimeSheetNew";
const tabs = [
  {
    id: 1,
    title: "Dashboard",
    moduleKey: moduleKeys.PHARMACY_DASHBOARD,
    to: RouterConfig.branchAdminDashboard,
  },
  {
    id: 2,
    title: "Schedule",
    moduleKey: moduleKeys.PHARMACY_SCHEDULER,
    to: RouterConfig.branchAdminSchedule,
  },
  {
    id: 3,
    title: "Branches",
    moduleKey: moduleKeys.BRANCHES,
    to: RouterConfig.branchAdminBranches,
  },
  {
    id: 4,
    title: "Regions",
    moduleKey: moduleKeys.REGIONS,
    to: RouterConfig.branchAdminRegions,
  },
  {
    id: 5,
    title: "Staff",
    moduleKey: moduleKeys.STAFF,
    to: RouterConfig.branchAdminStaff,
  },
  {
    id: 6,
    title: "Locum Jobs",
    moduleKey: moduleKeys.LOCUM_JOBS,
    to: RouterConfig.branchAdminPostedJobs,
  },
  {
    id: 7,
    title: "Locum Bank",
    moduleKey: moduleKeys.LOCUM_BANK,
    to: RouterConfig.branchAdminLocumBank,
  },
];

export const AdminView = () => {
  const { accessibleModules: modules } = useSelector((state) => state.auth);
  const [displayTabs, setDisplayTabs] = useState([]);

  useEffect(() => {
    if (!modules) return;
    let modulesArray = Object.values(modules);
    let tabsToDisplay = [];

    for (let tab of tabs) {
      for (let module of modulesArray) {
        if (tab.moduleKey === module.moduleKey) {
          tabsToDisplay.push(tab);
        }
      }
    }

    setDisplayTabs(tabsToDisplay);
  }, [modules]);

  return displayTabs.length > 0 ? (
    <div>
      <AdminHeader showProfile />
      <Routes>
        <Route
          path={RouterConfig.branchAdminDashboard}
          element={
            <PrivateRoute
              moduleKey={moduleKeys.PHARMACY_DASHBOARD}
              displayTabs={displayTabs}
            >
              {" "}
              <Dashboard />
            </PrivateRoute>
          }
          exact
        />
        <Route
          path={RouterConfig.branchAdminSchedule}
          element={
            <PrivateRoute
              moduleKey={moduleKeys.PHARMACY_SCHEDULER}
              displayTabs={displayTabs}
            >
              <SchedulePageNew />
            </PrivateRoute>
          }
          exact
        />
        <Route
          path={RouterConfig.branchAdminTimesheet}
          element={
            <PrivateRoute
              moduleKey={moduleKeys.PHARMACY_SCHEDULER}
              displayTabs={displayTabs}
            >
              <TimeSheetNew />
            </PrivateRoute>
          }
          exact
        />
        <Route
          path={`${RouterConfig.branchAdminSchedule}/old`}
          element={
            <PrivateRoute
              moduleKey={moduleKeys.PHARMACY_SCHEDULER}
              displayTabs={displayTabs}
            >
              <SchedulePage />
            </PrivateRoute>
          }
          exact
        />
        <Route
          path={`${RouterConfig.branchAdminSchedule}/full-screen`}
          element={
            <PrivateRoute
              moduleKey={moduleKeys.PHARMACY_SCHEDULER}
              displayTabs={displayTabs}
            >
              <DayviewTablefullscreen />
            </PrivateRoute>
          }
          exact
        />
        <Route
          path={RouterConfig.branchAdminLocumBank}
          element={
            <PrivateRoute
              moduleKey={moduleKeys.LOCUM_BANK}
              displayTabs={displayTabs}
            >
              <LocumBank />
            </PrivateRoute>
          }
          exact
        />
        <Route
          path={`${RouterConfig.branchAdminBranches}/*`}
          element={
            <PrivateRoute
              moduleKey={moduleKeys.BRANCHES}
              displayTabs={displayTabs}
            >
              <BranchPage />
            </PrivateRoute>
          }
        />
        <Route
          path={`${RouterConfig.branchAdminStaff}/*`}
          element={
            <PrivateRoute
              moduleKey={moduleKeys.STAFF}
              displayTabs={displayTabs}
            >
              <StaffPage />
            </PrivateRoute>
          }
        />

        <Route
          path={`${RouterConfig.branchAdminPostedJobs}/*`}
          element={
            <PrivateRoute
              moduleKey={moduleKeys.LOCUM_JOBS}
              displayTabs={displayTabs}
            >
              {/* <LocumShifts /> */}
              <LocumJobsNew />
            </PrivateRoute>
          }
        />
        <Route
          path={`${RouterConfig.branchAdminPostedJobs}/old`}
          element={
            <PrivateRoute
              moduleKey={moduleKeys.LOCUM_JOBS}
              displayTabs={displayTabs}
            >
              <LocumShifts />
            </PrivateRoute>
          }
        />
        <Route
          path={RouterConfig.branchAdminRegions}
          element={
            <PrivateRoute
              moduleKey={moduleKeys.REGIONS}
              displayTabs={displayTabs}
            >
              <RegionsPageNew />
            </PrivateRoute>
          }
          exact
        />
        <Route
          path={`${RouterConfig.branchAdminProfile}/*`}
          element={
            <PrivateRoute
              moduleKey={moduleKeys.PROFILE}
              displayTabs={displayTabs}
            >
              <ProfilePage />
            </PrivateRoute>
          }
          exact
        />
        <Route path="*" element={<Navigate to={displayTabs[0].to} />} />
      </Routes>
    </div>
  ) : (
    <GlobalLoader />
  );
};
