import { createSlice } from "@reduxjs/toolkit";
import { getPharmacySubscriptionSThunk, getPharmacyListThunk, getUserListThunk, getJobOffersThunk, getBillingThunk, getPharmacyInformationThunk, getPharmacyBranchesListThunk, getPharmacyStaffListThunk, getPharmacyJobListThunk, getUserDataThunk } from "../superAdminThunk";



const initialState = {
    pharmacyList: [],
    usersList: [],
    jobOffersList: [],
    billingList: [],
    pharmacyInformation: {},
    pharmacyBranchesList: [],
    pharmacyStaffList: [],
    pharmacyJobsList: [],
    subscription: { type: "" },
    userData: {}
}

const superAdminSlicer = createSlice({
    name: "superAdminSlicer",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPharmacyListThunk.fulfilled, (state, action) => {
                state.pharmacyList = action.payload
            }).addCase(getUserListThunk.fulfilled, (state, action) => {
                state.usersList = action.payload
            }).addCase(getJobOffersThunk.fulfilled, (state, action) => {
                state.jobOffersList = action.payload
            }).addCase(getBillingThunk.fulfilled, (state, action) => {
                state.billingList = action.payload
            }).addCase(getPharmacyInformationThunk.fulfilled, (state, action) => {
                state.pharmacyInformation = action.payload
            }).addCase(getPharmacyBranchesListThunk.fulfilled, (state, action) => {
                state.pharmacyBranchesList = action.payload
            }).addCase(getPharmacyStaffListThunk.fulfilled, (state, action) => {
                state.pharmacyStaffList = action.payload
            }).addCase(getPharmacyJobListThunk.fulfilled, (state, action) => {
                state.pharmacyJobsList = action.payload
            }).addCase(getPharmacySubscriptionSThunk.fulfilled, (state, action) => {
                state.subscription = action.payload
            }).addCase(getUserDataThunk.fulfilled, (state, action) => {
                state.userData = action.payload
            })
    }
})

export const superAdminReducer = superAdminSlicer