import { Skeleton, Typography } from "antd";
import React from "react";

const DashboardChartcontainer = ({
  title,
  subtitle,
  children,
  loading,
  withChart,
}) => {
  return (
    <>
     
      <Skeleton
        style={{
          background: "#fff",
          padding: 12,
          borderRadius: 12,
          height: 500,
        }}
        round
        active
        loading={loading}
        paragraph={{
          rows: withChart ? 4 : 24,
          width: "auto",
        }}
        avatar={withChart && { size: 250 }}
        // title={false}
        className={withChart ? "skeleton-with-chart" : "chart-skeleton"}
      >
        <div className="dashboard-chart-container cmn-db-box">
          <div className="chart-header">
            <Typography.Text>{title}</Typography.Text>
            <Typography.Title level={4}>{subtitle}</Typography.Title>
          </div>

          {children}
        </div>
      </Skeleton>
    </>
  );
};

export default DashboardChartcontainer;
