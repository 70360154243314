import moment from "moment";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { BulkDeleteShiftForm } from "../../../../../components/comon/deleteBulkShift";
import Toolbar from "../../../../../components/schedule/SchedularToolbar";
import { getRegionsBranchesListThunk } from "../../../../../redux/regions/regionsThunk";
import { getStaffsThunk } from "../../../../../redux/schedule/scheduleThunk";
import { getStaffScheduleThunk } from "../../../../../redux/staffs/staffsThunk";
import { staffApiKeys } from "../../../../../utils/apiKeyConstants";
import { hasAccess } from "../../../../../utils/helper";
import { MonthlyView } from "./monthView/MonthView";

export const StaffSchedule = () => {
  const { staffId } = useParams();
  const [dateRange, setDateRange] = useState();

  const [flag, setFlag] = useState(true);
  const [showDeleteShiftModal, setShowDeleteSHiftModal] = useState(false);
  const { staffSchedule } = useSelector((state) => state.staffs);
  const dispatch = useDispatch();


  useEffect(() => {
    // dispatch(getStaffsThunk())
    dispatch(getRegionsBranchesListThunk())
  }, [dispatch])

  useEffect(() => {
    if (!dateRange || !flag) return;

    if (hasAccess(staffApiKeys.GET_STAFF_SCHEDULE))
      dispatch(
        getStaffScheduleThunk({
          id: staffId,
          data: {
            endDateRange: dateRange?.endDateRange,
            startDateRange: dateRange?.startDateRange,
          },
        })
      ).then(res => {
        setFlag(false)
      })
  }, [dateRange, dispatch, flag, staffId]);

  useEffect(() => {
    setDateRange({
      startDateRange: moment(dateRange?.startDateRange)
        .startOf("month")
        .format("YYYY-MM-DD"),
      endDateRange: moment(dateRange?.startDate)
        .endOf("month")
        .format("YYYY-MM-DD"),
    });
    setFlag(true);
  }, []);

  const onDateChange = useCallback(
    (startDate, endDate) => {
      setDateRange({
        startDateRange: startDate,
        endDateRange: endDate
      });
      setFlag(true);
    },
    []
  );

  const dateLable = useMemo(() => {
    const startDate = `${moment(dateRange?.startDateRange, "YYYY-MM-DD").format(
      `MMMM, Y`
    )}`;
    return startDate;
  }, [dateRange]);

  return (
    <div className="branch-schedule-view-container">
      <Toolbar
        primaryText="Schedule"
        view="monthly"
        deleteShiftHandler={() => setShowDeleteSHiftModal(!showDeleteShiftModal)}
        deleteShiftBtn={true}
        dateRange={dateRange}
        dateLable={dateLable}
        onDateChange={onDateChange}
        showDatePicker
        setFlag={setFlag}
      />
      {
        showDeleteShiftModal &&
        <BulkDeleteShiftForm
          isStaff
          staffId={staffId}
          showDeleteShiftModal={showDeleteShiftModal}
          setShowDeleteShiftModal={setShowDeleteSHiftModal}
          setFlag={setFlag}
        />
      }
      {!staffSchedule ? undefined : (
        <MonthlyView scheduleData={staffSchedule} />
      )}
    </div>
  );
};
