import React from "react";

const BlueTickIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M10.3333 1.66797C5.74167 1.66797 2 5.40964 2 10.0013C2 14.593 5.74167 18.3346 10.3333 18.3346C14.925 18.3346 18.6667 14.593 18.6667 10.0013C18.6667 5.40964 14.925 1.66797 10.3333 1.66797ZM14.3167 8.08464L9.59167 12.8096C9.475 12.9263 9.31667 12.993 9.15 12.993C8.98333 12.993 8.825 12.9263 8.70833 12.8096L6.35 10.4513C6.10833 10.2096 6.10833 9.80964 6.35 9.56797C6.59167 9.3263 6.99167 9.3263 7.23333 9.56797L9.15 11.4846L13.4333 7.2013C13.675 6.95964 14.075 6.95964 14.3167 7.2013C14.5583 7.44297 14.5583 7.83464 14.3167 8.08464Z"
        fill="#0A92D0"
      />
    </svg>
  );
};

export default BlueTickIcon;
