import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { ATTENDANCE_TYPE } from "../../../utils/constant";
import { minuteTohour } from "../../../utils/dates";
import { getTotalMinutes } from "../../../utils/helper";

const TotalHours = ({ index }) => {
  const { staffTimeSheetEdited } = useSelector((s) => s.staffs);

  const shiftDetails = useMemo(() => {
    const details = staffTimeSheetEdited?.[index];

    return details;
  }, [index, staffTimeSheetEdited]);

  const isEmpty = !shiftDetails?.startTime || !shiftDetails?.endTime;

  const totalTime = useMemo(() => {
    if (isEmpty) {
      return null;
    }
    return minuteTohour(
      getTotalMinutes(shiftDetails?.startTime, shiftDetails?.endTime)
    );
  }, [isEmpty, shiftDetails?.endTime, shiftDetails?.startTime]);

  if (shiftDetails?.type === ATTENDANCE_TYPE.NO_SHIFT || !totalTime) {
    return <div />;
  }

  return <div className="no-user-select">{totalTime}</div>;
};

export default TotalHours;
