import { Popover, Tooltip } from "antd";
import moment from "moment";
import React, { useMemo, useState } from "react";
import Coffeicon from "../../../../src/assets/icons/Coffe.svg";
import dashboardIcon from "../../../../src/assets/icons/dashboardIcon.svg";
import ShiftDetailsView from "./ShiftDeatilsView";

const ShiftDetailsDayView = ({ index, shift, dayview, type, onDelete }) => {
  const [openPopover, setOpenPopover] = useState(false);

  const handlePopover = (newOpen) => {
    setOpenPopover(newOpen);
  };

  const calculateTdWidth = (startTime, endTime) => {
    const duration = moment.duration(
      moment(endTime, "HH:mm").diff(moment(startTime, "HH:mm"))
    );
    let minutesPassed = Math.floor(duration.asMinutes());

    const width =
      minutesPassed < 60 ? "100%" : (200 / 60) * minutesPassed + "px";

    return width;
  };

  const calculateMarginLeft = (startTime) => {
    const minutes = moment(startTime, "HH:mm").format("mm");

    return minutes + "px";
  };

  const _className = useMemo(() => {
    if (shift?.status === "unfilled" && shift?.isJob) {
      return "no_name";
    }

    if (shift?.status === "pending") {
      return "pending_review";
    }

    if (shift?.roleType?.role === "locum") {
      return "locum";
    }

    return "";
  }, [JSON.stringify(shift)]);

  const renederTitle = useMemo(() => {
    if (shift?.status === "unfilled") {
      return "Unfilled";
    }

    if (shift?.status === "pending") {
      return shift?.isJob ? "Locums to review" : "Shift Pending";
    }

    if (shift?.roleType?.role === "locum" && !!shift?.staff) {
      return `${shift?.staff[0]?.name} ${shift?.staff[0]?.surname}`;
    }

    if (shift?.status === "active" || shift?.status === "accepted") {
      return `${shift?.staff?.name} ${shift?.staff?.surname}`;
    }

    return `${shift?.staff?.name} ${shift?.staff?.surname}`;
  }, [JSON.stringify(shift)]);

  return type?.includes(shift?.roleType?.id) ? (
    <React.Fragment key={`${index}_shift`}>
      <div
        className={`leave_table_td ${_className}`}
        style={{
          width: calculateTdWidth(shift?.startTime, shift?.endTime),
          position: "relative",
          zIndex: "1 !important",
          marginLeft: calculateMarginLeft(shift?.startTime),
        }}
      >
        <Popover
          content={
            <ShiftDetailsView
              shift={shift}
              onClose={() => setOpenPopover(false)}
              type={type}
              onDelete={onDelete}
            />
          }
          trigger="click"
          open={openPopover}
          showArrow={false}
          placement="rightTop"
          onOpenChange={handlePopover}
        >
          {" "}
          <div className="d-flex justify-content-between review">
            <Tooltip title={renederTitle}>
              <h6>{renederTitle}</h6>
            </Tooltip>
            {shift?.reviewApplications > 0 && (
              <Tooltip
                title={`${shift?.reviewApplications} Locum requests to review`}
              >
                <span>{shift?.reviewApplications}</span>
              </Tooltip>
            )}
          </div>
        </Popover>

        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <p className="shift_time">
              {moment(shift?.startTime, "HH:mm").format("hh A")} -{" "}
              {moment(shift?.endTime, "HH:mm").format("hh A")}
            </p>
            {shift?.lunchStartTime && (
              <Tooltip
                title={`${moment
                  .duration(
                    moment(shift.lunchEndTime, "HH:mm").diff(
                      moment(shift.lunchStartTime, "HH:mm")
                    )
                  )
                  .asHours()} Break`}
              >
                {" "}
                <span className="d-flex breakTimeSpan">
                  <img src={Coffeicon} alt="break" />{" "}
                  {parseFloat(
                    moment
                      .duration(
                        moment(shift.lunchEndTime, "HH:mm").diff(
                          moment(shift.lunchStartTime, "HH:mm")
                        )
                      )
                      .asHours()
                  ).toFixed(2)}
                </span>
              </Tooltip>
            )}
          </div>
          <div>
            {!!shift?.isCreatedFromTemplate && (
              <Tooltip title="Template">
                <img src={dashboardIcon} alt="" />
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      {/* {shift?.isLocumReviewPending ? (
        <div
          className={`leave_table_td ${
            shift.isLocum
              ? "locum"
              : shift.name === ""
              ? "no_name"
              : dayview
              ? "dayView"
              : ""
          }`}
          style={{ width: calculateTdWidth(shift.startTime, shift.endTime) }}
        >
          <h6>{!shift.name ? "Unfilled" : shift.name} </h6>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              {" "}
              <p className="shift_time">
                {moment(shift.startTime, "HH").format("hh A")} -
                {moment(shift.endTime, "HH").format("hh A")}
              </p>
              {shift.isStaff && (
                <Tooltip title={`${shift.breakDuration} Break`}>
                  {" "}
                  <span className="d-flex breakTimeSpan">
                    <img src={Coffeicon} alt="break" /> {shift.breakDuration}
                  </span>
                </Tooltip>
              )}
            </div>
            <Tooltip title="Template">
              <img src={dashboardIcon} alt="" />
            </Tooltip>
          </div>
        </div>
      ) : null} */}
    </React.Fragment>
  ) : (
    <div></div>
  );
};

export default ShiftDetailsDayView;
