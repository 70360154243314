import React from "react";
import { useSelector } from "react-redux";
import { Verification } from "../../../../../components/verifications";
import "../style.scss";

export const VerificationInformation = () => {
  const { userData } = useSelector((state) => state.superAdmin)
  return (
    <div>
      <Verification
        staffInfo={userData.personal}
        staffVerification={{ ...userData.personal, ...userData?.compliance }}
      />
    </div>
  );
};
