import React from 'react';
import peopleIcon from '../../../../../src/assets/icons/people.png';
import LeaveDetails from '../LeaveDetails';
// import plusIcon from "../../../../../src/assets/icons/plus.svg";

import moment from 'moment';
import { useSelector } from 'react-redux';
import { showLabel } from '../../../../utils/timeshhet';

const LeaveDetailsWeekView = ({ weekArray }) => {
  const { roles } = useSelector((state) => {
    return state.schedule;
  });

  const { staffLeaves } = useSelector((state) => state.staffs);

  const findLabel = (key) => {
    let temp = roles?.find((item) => item?.role === key);

    if (!!temp) {
      return temp?.label?.toLowerCase().replace('staff', '');
    }

    return '';
  };

  return (
    <div className="table_container">
      {' '}
      <table className="table table-bordered bg-white weekview_table">
        <thead>
          <tr>
            <th scope="col">
              <div className="p-3 roles">
                <img src={peopleIcon} alt="people" /> Roles
              </div>
            </th>
            {weekArray.map((item, index) => (
              <th scope="col" className="week_array">
                <p className="p-3 dates">
                  {moment(item).format('ddd')}{' '}
                  <b>{moment(item).format('D MMM')}</b>
                </p>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(staffLeaves.leaves)?.map((key) => (
            <tr>
              <th scope="row">
                <p className="item_type text-capitalize">{findLabel(key)}</p>{' '}
              </th>
              {staffLeaves?.leaves[key].map((leaves) => (
                <td className="leaves_table">
                  {leaves?.leaves?.length
                    ? leaves?.leaves?.map((_leave) => (
                        <LeaveDetails
                          role={showLabel(findLabel(key))}
                          leave={_leave}
                          weekview={true}
                        />
                      ))
                    : null}

                  {/* <div className="add_shift_box">
                    <img src={plusIcon} alt="plus icon" />
                  </div> */}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LeaveDetailsWeekView;
