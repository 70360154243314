import React, { useMemo } from 'react';
import { CustomFooter } from '../TimesheetNew/TimeSheetNewTable';
import { TableComponet } from '../UI/table';

const Locumtable = ({
  tableCols,
  data,
  hideFooter,
  perPage,
  page,
  handlePerPage,
  handleMoveToPage,
}) => {
  const _list = useMemo(() => {
    return data?.data;
  }, [JSON.stringify(data)]);

  return (
    <div className="locum-table">
      <div className="table-wrapper">
        <TableComponet
          columns={tableCols}
          data={_list?.rows ? _list.rows : []}
        />
      </div>
      {!hideFooter && (
        <div className="footer-wrapper">
          <CustomFooter
            handlePerPage={handlePerPage}
            data={data}
            perPage={perPage}
            page={page}
            handleMoveToPage={handleMoveToPage}
          />
        </div>
      )}
    </div>
  );
};

export default Locumtable;
