import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmModal } from "../../../components/UI/modals/confirmModal";
import { CustomTabs } from "../../../components/UI/tabs";
import PersonalInfo from "../../../components/staff-information/PersonalInfo";
import { deleteUserThunk } from "../../../redux/auth/authThunk";
import { getProfileDataThunk } from "../../../redux/profile/profileThunk";
import { getStaffAccrediationsThunk } from "../../../redux/staffs/staffsThunk";
// import './style.scss'

export const LocumInformation = () => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  // const [showAccrediationModal, setShowAccrediationModal] = useState();
  // const { staffAccrediations } = useSelector((state) => state.staffs);
  const { profileInfo } = useSelector((state) => state.profile);
  // const [editTab, setEditTab] = useState("");
  // const [accrediationData, setAccreditationData] = useState();
  const [currentActiveTab, setCurrentActiveTab] = useState(0);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const onDeleteUser = () => {
    dispatch(
      deleteUserThunk({
        id: profileInfo?.id,
      })
    );
  };

  const [tabs, setTabs] = useState([]);

  const onSuccess = () => {
    dispatch(getProfileDataThunk());
  };

  useEffect(() => {
    const tabs = [
      {
        title: "Personal Information",
        content: <PersonalInfo onSuccess={onSuccess} details={profileInfo} />,
      },
      // {
      //   title: "Job Info",
      //   content: <JobInfoTab />,
      // },
    ];
    setTabs(tabs);
  }, [JSON.stringify(profileInfo)]);

  useEffect(() => {
    dispatch(getProfileDataThunk());
    dispatch(getStaffAccrediationsThunk({ staffId: user?.id }));
  }, [dispatch, user]);

  // useEffect(() => {
  //   if (!staffAccrediations.length) return;
  //   const data = staffAccrediations?.map((data) => {
  //     return {
  //       name: data.accreditationLabel,
  //       label: data.accreditationName,
  //       filePath: {
  //         fileList: data?.filePath
  //           ? [
  //               {
  //                 uid: Math.random(),
  //                 name: `${profileInfo?.name}_${
  //                   data?.accreditationName
  //                 }.${data?.filePath?.split(".").pop()}`,
  //                 status: "done",
  //                 url: data?.filePath,
  //               },
  //             ]
  //           : [],
  //       },
  //     };
  //   });
  //   setAccreditationData(data);
  // }, [staffAccrediations]);

  // const onEditHandler = useCallback((data) => {
  //   if (data === "Accrediations") {
  //     setShowAccrediationModal(true);
  //   }
  //   setEditTab(data);
  // }, []);

  // const _onChangeHandler = (value, name) => {
  //   const allowedAccreditaionList = [...accrediationData];
  //   const index = allowedAccreditaionList.findIndex((obj) => obj.name === name);
  //   allowedAccreditaionList[index] = {
  //     ...allowedAccreditaionList[index],
  //     filePath: value,
  //   };
  //   setAccreditationData([...allowedAccreditaionList]);
  // };

  // const onAccrediationEdit = useCallback(() => {
  //   const data = accrediationData?.map((item) => {
  //     if (item.filePath.file && item.filePath.file.status !== "removed") {
  //       return {
  //         [item.name]: item.filePath.file,
  //       };
  //     } else if (item?.filePath?.fileList.length) {
  //       return {
  //         [item.name]: item.filePath.fileList[0].url,
  //       };
  //     } else {
  //       return {
  //         [item.name]: "",
  //       };
  //     }
  //   });

  //   let finalObj = {};
  //   for (let i = 0; i < data.length; i++) {
  //     Object.assign(finalObj, data[i]);
  //   }

  //   let _formData = new FormData();
  //   for (let key in finalObj) {
  //     if (finalObj[key] instanceof Blob) {
  //       _formData.append(key, finalObj[key], finalObj[key]?.name);
  //     } else {
  //       _formData.append(key, finalObj[key]);
  //     }
  //   }
  //   _formData.append("id", user?.id);

  //   dispatch(editStaffAccrediationsThunk(_formData)).then((res) => {
  //     if (res.payload.status === "success") {
  //       setShowAccrediationModal(false);
  //       let obj = {};
  //       for (var d in finalObj) {
  //         Object.assign(obj, { [d]: finalObj[d] !== "" ? true : false });
  //       }
  //       window.analytics.track("Accreditation Uploaded", obj);
  //       notify("success", "Locum Accrediations Updated!");
  //       setEditTab("");
  //       dispatch(getStaffAccrediationsThunk({ staffId: user.id }));
  //     }
  //   });
  // }, [accrediationData, dispatch, user.id]);

  return (
    <div className="branch-info-page  rounded-4 position-relative">
      <ConfirmModal
        title="We're sad to see you go 😪"
        open={showConfirmModal}
        onConfirm={onDeleteUser}
        confirmButtonText="Proceed With Deletion"
        cancelButtonText="Cancel"
        onClose={() => setShowConfirmModal(false)}
      >
        <div>
          <div>You've been such a valuable part of the community. 🤝</div>
          <br />
          <div>
            If you've been facing problems with Lopic we'd be more than happy to
            work them out with you- just hit 'Chat' to talk to us. ✒️{" "}
          </div>
        </div>
      </ConfirmModal>
      <CustomTabs
        currentActiveTab={currentActiveTab}
        setCurrentActiveTab={setCurrentActiveTab}
        tabs={tabs}
        // onEditHandler={onEditHandler}
      />
      {currentActiveTab === 0 && (
        <div
          className="d-flex justify-content-between gap-3 position-absolute"
          style={{ bottom: 20, right: 20 }}
        >
          <div></div>
          <Button
            className="btn-style"
            block
            danger
            type="primary"
            style={{ width: 250 }}
            onClick={() => setShowConfirmModal(true)}
          >
            Delete User
          </Button>
        </div>
      )}
      {/* {editTab === "Personal Information" ? (
        <div
          className="d-flex flex-column align-items-start add-shift-modal-content"
          style={{ gap: "15px", color: "#7B7B7B", paddingTop: "25px" }}
        >
          <PersonalInfoForm showChangePass setEditTab={setEditTab} />
        </div>
      ) : (
        <AddModal
          title={`Edit Accreditations`}
          handleModal={showAccrediationModal}
          setHandleModal={() => {
            setEditTab("");
            setShowAccrediationModal(!showAccrediationModal);
          }}
          primaryButtonHandler={onAccrediationEdit}
          primaryButtonText="Update"
          secondaryButtonText="Cancel"
          fixWidth
        >
          <div className="d-flex gap-3 flex-column">
            {accrediationData?.map((data, idx) => (
              <div key={idx}>
                <div>{data.label}</div>
                <Upload
                  accept={"image/*, .doc, .pdf"}
                  fileList={data?.filePath?.fileList}
                  onChange={async ({ file, fileList }) => {
                    try {
                      const compressedFile = await compressImage(file);
                      _onChangeHandler(
                        { file: compressedFile, fileList },
                        data.name
                      );
                    } catch (error) {
                      notify("error", "Error compressing the image:");
                    }
                  }}
                  onPreview={() => {}}
                  beforeUpload={beforeUpload}
                  multiple={false}
                  maxCount={1}
                  listType="picture"
                >
                  {(!data?.filePath?.fileList?.length ||
                    data?.filePath?.fileList[0]?.status === "removed") && (
                    <Button
                      className="my-3"
                      style={{
                        width: "100%",
                        height: "40px",
                        color: "#51C2BC",
                        border: "2px dashed #51C2BC",
                        backgroundColor: "#F0F9F9",
                      }}
                      icon={<UploadOutlined />}
                    >
                      Upload Document
                    </Button>
                  )}
                </Upload>
              </div>
            ))}
          </div>
        </AddModal>
      )} */}
    </div>
  );
};
