import { Route, Routes } from "react-router-dom";
import { UsersListPage } from "./usersList";
import { UserDetails } from "./details";
import { UserInformation } from "./details/information";
import { VerificationInformation } from "./details/verification";

export const UsersPage = () => {
  return (
    <Routes>
      <Route path={`/`} element={<UsersListPage />} />
      <Route path="/:id/details/*" element={<UserDetails />}>
        <Route path="information" element={<UserInformation />} />
        <Route path="verification" element={<VerificationInformation />} />
      </Route>
    </Routes>
  );
};
