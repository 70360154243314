import { Checkbox, Empty, Input } from "antd";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import CustomAvatar from "../../comon/CustomAvatar";
import DisPlayTags from "../../comon/DisPlayTags";
import "./style.scss";

const LeaveFilter = ({ selectedPharmacies, setselectedPharmacies }) => {
  const { branches } = useSelector((state) => state.branches);
  const [inputvalue, setinputvalue] = useState("");
  const [selectedObj, setSelectedObj] = useState([]);

  const handleOnChange = (item) => {
    const index = selectedPharmacies.indexOf(item?.id);
    const newSelectedPharmacies = [...selectedPharmacies];
    const _temp = [...selectedObj];

    if (index === -1) {
      newSelectedPharmacies.push(item?.id);
      _temp.push(item);
    } else {
      newSelectedPharmacies.splice(index, 1);
      _temp.splice(index, 1);
    }

    setSelectedObj(_temp);
    setselectedPharmacies(newSelectedPharmacies);
  };

  const handleDelete = (index) => {
    const newSelectedPharmacies = [...selectedPharmacies];
    const _temp = [...selectedObj];
    newSelectedPharmacies.splice(index, 1);
    _temp.splice(index, 1);
    setSelectedObj(_temp);
    setselectedPharmacies(newSelectedPharmacies);
  };

  const _filterItems = useMemo(() => {
    const _filter = branches?.rows?.filter((item) =>
      item?.title?.toLowerCase()?.includes(inputvalue?.toLowerCase())
    );

    return _filter;
  }, [JSON.stringify(branches), inputvalue]);

  return (
    <div className="leave_filter">
      <div className="d-flex align-items-center customInputWithTags mb-2">
        <DisPlayTags
          data={selectedObj}
          keyName="title"
          onDelete={handleDelete}
        />
        <Input
          bordered={false}
          value={inputvalue}
          onChange={(e) => setinputvalue(e?.target?.value)}
          size="large"
          placeholder="Select One Or More Options"
          style={{
            minWidth: 250,
            border: "none",
          }}
        />
      </div>

      <ul className="pharma_options_wrapper">
        {/* {JSON.stringify(selectedPharmacies)} */}
        {_filterItems?.length ? (
          _filterItems?.map((item) => (
            <li
              key={item?.id}
              className="d-flex align-items-center justify-content-between"
            >
              <div className="pharma_info">
                <i>
                  <CustomAvatar
                    size="small"
                    src={item?.logo}
                    alt={item?.title}
                  />
                </i>
                <p>{item?.title}</p>
              </div>

              <Checkbox
                checked={selectedPharmacies?.includes(item?.id)}
                value={item?.id}
                onChange={() => handleOnChange(item)}
              />
            </li>
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </ul>
    </div>
  );
};

export default LeaveFilter;
