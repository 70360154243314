import { DatePicker, Form, Input } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { AddModal } from "../../../../../../../components/UI/modals/addModal";
import { blockLocumThunk } from "../../../../../../../redux/branches/branchesThunk";
import { locumApiKeys } from "../../../../../../../utils/apiKeyConstants";
import { hasAccess } from "../../../../../../../utils/helper";
import { notify } from "../../../../../../../utils/helper";

export const BlockLocumForm = ({
  showBlockLocumModal,
  setShowBlockLocumModal,
  branchId,
}) => {
  const formId = useMemo(() => "form", []);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const startedAt = Form.useWatch("startedAt", form);

  const onFormSubmit = (values) => {
    const reqData = {
      ...values,
      startedAt: values.startedAt.format("YYYY-MM-DD"),
      endedAt: values.endedAt.format("YYYY-MM-DD"),
    };
    dispatch(
      blockLocumThunk({
        id: branchId,
        reqData,
      })
    ).then((res) => {
      if (res.payload) {
        notify("success", "Locum Blocked");
        form.resetFields();
        setShowBlockLocumModal(false);
      }
    });
  };

  return (
    <AddModal
      formid={formId}
      title={`Block Locum`}
      handleModal={showBlockLocumModal}
      setHandleModal={setShowBlockLocumModal}
      primaryButtonText={
        hasAccess(locumApiKeys.LOCUM_BLOCK) ? "Block" : undefined
      }
      secondaryButtonText="Cancel"
      secondaryButtonHandler={() =>
        setShowBlockLocumModal(!showBlockLocumModal)
      }
      primaryButtonHandler={form.submit}
    >
      <Form
        onFinish={
          hasAccess(locumApiKeys.LOCUM_BLOCK) ? onFormSubmit : undefined
        }
        form={form}
        layout="vertical"
        id={formId}
        autoComplete="off"
      >
        <Form.Item
          label="GPhC Number"
          name="gphcNumber"
          normalize={(value) => {
            if (value.length > 7) return value.slice(0, 7);
            return value;
          }}
          rules={[
            {
              required: true,
              message: "Please input your Gphc Number!",
            },
            { len: 7, message: "Length should be 7 characters!" },
          ]}
        >
          <Input
            type="number"
            className="text-input-field p-2 t1"
            placeholder="GPhC Number"
          />
        </Form.Item>
        <div className="d-flex align-items-center gap-3">
          <Form.Item
            label="Start Date"
            name="startedAt"
            rules={[
              {
                required: true,
                message: "Please Select Start Date!",
              },
            ]}
          >
            <DatePicker
              inputReadOnly
              disabledDate={(current) => {
                return moment().add(-1, "day") >= current;
              }}
              format="DD-MM-YYYY"
              getPopupContainer={(trigger) => trigger.parentElement}
              className="text-input-field p-2 pe-3 t1"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label="End Date"
            name="endedAt"
            rules={[
              {
                required: true,
                message: "Please Select End Date!",
              },
            ]}
          >
            <DatePicker
              inputReadOnly
              format="DD-MM-YYYY"
              disabledDate={(current) => {
                return dayjs(startedAt) >= current;
              }}
              getPopupContainer={(trigger) => trigger.parentElement}
              className="text-input-field p-2 pe-3 t1"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </div>
        <Form.Item label="Note" name="note">
          <Input.TextArea
            rows={5}
            onBlur={(e) => form.setFieldsValue({ note: e.target.value.trim() })}
            maxLength={256}
            className="text-input-field p-2 t1"
            placeholder="Note"
          />
        </Form.Item>
      </Form>
    </AddModal>
  );
};
