/* eslint-disable react-hooks/exhaustive-deps */
import { Popover, Select } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { Icon } from "semantic-ui-react";
import searchIcon from "../../../src/assets/icons/Search.svg";
import ArrowLeftIcon from "../../../src/assets/icons/arrow-left.png";
import Dropdown from "../../../src/assets/icons/downDrop.svg";
import ArrowRightIcon from "../../../src/assets/icons/right-arrow.png";
import CalenderIcon from "../../../src/assets/icons/schedule_calendar.svg";
import Filter from "../../assets/icons/filtergreen.svg";
import { JOBS_OPTIONS, ROLES_COLOR } from "../../utils/constant";
import "./style.scss";

const CommonToolbar = ({
  searchInputValue, // Define and pass the value for the search input
  searchInputonKeyDown, // Define and pass the onKeyDown function for the search input
  searchInputOnChange, // Define and pass the onChange function for the search input
  selectedView, // Define and pass the selected view value
  handleViewChange, // Define and pass the function to handle view change
  handleJoboptionsChange, // Define and pass the function to handle job options change
  selectedRole, // Define and pass the selected role value
  handleRoleOptionsChange, // Define and pass the function to handle role options change
  handlePrevious, // Define and pass the function to handle previous button click
  handleNext, // Define and pass the function to handle next button click
  RenderValue, // Define and pass the value to render in the date selector
  handleToggleMonthView, // Define and pass the function to toggle the month view
  isMonthGridView,
  displayOptions,
  type,
  endAdroment,
  disabled,
  filterPopover,
  popoverOpen,
  handlePopoverOpenChange,
  viewOptions,
  searchPlaceHolder,
  _hasFilter,
}) => {
  const { roles } = useSelector((state) => {
    return state.schedule;
  });

  return (
    <div
      className="d-flex justify-content-between align-items-center mb-3 "
      style={{ marginTop: "16px" }}
    >
      <div className="d-flex align-items-center main-toolbar-content">
        {Boolean(displayOptions?.hideSearch) ? null : (
          <div className="position-relative me-4">
            <img
              className="position-absolute top-50 translate-middle-y ms-3"
              src={searchIcon}
              alt="search"
              width="20px"
              height="20px"
            />{" "}
            <input
              type="text"
              placeholder={searchPlaceHolder || "Search"}
              className="rounded-pill  ps-5 border search_field"
              value={searchInputValue}
              onChange={searchInputOnChange}
              onKeyDown={searchInputonKeyDown}
            />
          </div>
        )}

        {Boolean(displayOptions.hideView) ? null : (
          <Select
            className=" me-4 d-flex justify-content-between align-items-center select_field rounded-pill border pe-3  bg-white"
            bordered={false}
            value={selectedView}
            dropdownStyle={{ zIndex: 2000 }}
            options={viewOptions?.filter((item) => {
              return item.value;
            })}
            onChange={handleViewChange}
            style={{ minWidth: "200px" }}
            suffixIcon={<img src={Dropdown} alt="dropdown" />}
            disabled={Boolean(disabled)}
          />
        )}

        {Boolean(displayOptions.hideJobs) ? null : (
          <Select
            className=" me-4 d-flex justify-content-between align-items-center select_field rounded-pill border  pe-3  bg-white"
            bordered={false}
            defaultValue=""
            dropdownStyle={{ zIndex: 2000 }}
            options={JOBS_OPTIONS}
            onChange={(value) => {
              handleJoboptionsChange(value);
            }}
            suffixIcon={<img src={Dropdown} alt="dropdown" />}
          />
        )}

        {Boolean(displayOptions.hideRole) ? null : (
          <Select
            defaultValue=""
            className="me-4 d-flex justify-content-between align-items-center select_field rounded-pill border  pe-3  bg-white"
            bordered={false}
            dropdownStyle={{ zIndex: 2000 }}
            value={selectedRole}
            onChange={(value) => {
              handleRoleOptionsChange(value);
            }}
            style={{ minwidth: "auto", width: "230px" }}
            suffixIcon={<img src={Dropdown} alt="dropdown" />}
          >
            <Select.Option value="">All Roles</Select.Option>
            {roles?.map((data, idx) => (
              <Select.Option value={data.id} key={idx}>
                <div className="d-flex align-items-center gap-2">
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: "50%",
                      background: ROLES_COLOR[data.id],
                    }}
                  ></div>
                  {data.label}
                </div>
              </Select.Option>
            ))}
          </Select>
        )}

        {Boolean(displayOptions.hideCalendar) ? null : (
          <div
            className="rounded-pill bg-white date_selector me-4 border d-flex justify-content-between align-items-center"
            style={{
              minWidth: "200px",
              cursor: Boolean(disabled) ? "not-allowed" : "auto",
            }}
          >
            <button
              onClick={handlePrevious}
              className="btn"
              style={{
                cursor: Boolean(disabled) ? "not-allowed" : "pointer",
                border: "none",
              }}
              disabled={disabled}
            >
              <img src={ArrowLeftIcon} alt="arrow-left" />
            </button>
            <div className="d-flex justify-content-center align-items-center selected_date">
              <img src={CalenderIcon} alt="" />
              <p className="mb-0" style={{ fontSize: "16px" }}>
                {RenderValue}
              </p>
            </div>
            <button
              onClick={handleNext}
              className="btn"
              style={{
                cursor: Boolean(disabled) ? "not-allowed" : "pointer",
                border: "none",
              }}
              disabled={disabled}
            >
              <img src={ArrowRightIcon} alt="arrow-right" />
            </button>
          </div>
        )}

        {selectedView === "Month" ? (
          <button
            type="button"
            className="btn btn-light  toggle_btn rounded-pill p-0 px-1 border bg-white d-flex align-items-center justify-content-center"
            style={{ color: "#02988E" }}
          >
            <span
              className="rounded-pill d-flex align-items-center justify-content-center "
              style={{
                width: "44px",
                height: "44px",
                backgroundColor: "#E1F4F4",
              }}
              onClick={handleToggleMonthView}
            >
              <Icon
                size="large"
                name={isMonthGridView ? "grid layout" : "list"}
                className="me-0"
              />
            </span>{" "}
          </button>
        ) : null}

        <Popover
          title=""
          content={filterPopover}
          showArrow={false}
          placement="rightBottom"
          trigger="click"
          open={popoverOpen}
          onOpenChange={handlePopoverOpenChange}
        >
          {Boolean(displayOptions.hideFilter) ? null : (
            <button
              className={`btn filter_btn  ${
                _hasFilter ? "filter_btn_active" : ""
              }`}
              // onClick={handlePopoverOpenChange}
            >
              <span>Filters</span>
              <figure>
                <img src={Filter} alt="filter" />
              </figure>
            </button>
          )}
        </Popover>
      </div>

      {!!endAdroment ? <div>{endAdroment}</div> : null}
    </div>
  );
};

CommonToolbar.propTypes = {
  searchInputValue: PropTypes.string,
  searchInputonKeyDown: PropTypes.func,
  searchInputOnChange: PropTypes.func,
  selectedView: PropTypes.string,
  handleViewChange: PropTypes.func,
  viewOptions: PropTypes.array,
  jobesOptions: PropTypes.array,
  handleJoboptionsChange: PropTypes.func,
  selectedRole: PropTypes.string,
  handleRoleOptionsChange: PropTypes.func,
  handlePrevious: PropTypes.func,
  handleNext: PropTypes.func,
  RenderValue: PropTypes.string,
  handleToggleMonthView: PropTypes.func,
  isMonthGridView: PropTypes.bool,
  type: PropTypes.oneOf(["schedule", "timesheet", "template"]),
  displayOptions: PropTypes.shape({
    hideView: PropTypes.bool,
    hideRole: PropTypes.bool,
    hideSearch: PropTypes.bool,
    hideJob: PropTypes.bool,
    hideFilter: PropTypes.bool,
    hideCalendar: PropTypes.bool,
  }),
  endAdroment: PropTypes.elementType,
  disabled: PropTypes.bool,
  filterPopover: PropTypes.any,
};

export default CommonToolbar;
