import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";

import { AbilityContext, updateAbility } from "../../utils/ability";
import { userRoles } from "../../utils/constant";

import { useLocation } from "react-router-dom";
import Segmentation from "../../components/segmentation";
import { AdminView } from "../admin-view";
import { ErrorPage } from "../errorPage/ErrorPage";
import { LocumView } from "../locum-view";
import { StaffView } from "../staff-view";
import { SuperAdminView } from "../super-admin";

export const Root = () => {
  const ability = useContext(AbilityContext);
  const { user } = useSelector((state) => state.auth);
  const { group } = user;

  useEffect(() => {
    updateAbility(ability, group);
  }, [ability, group]);

  let appView;
  const location = useLocation();

  if (location.pathname === "/page-not-found") {
    return <ErrorPage />;
  }
  switch (group) {
    case userRoles.ADMIN:
      appView = <SuperAdminView />;
      break;
    case userRoles.PHARMACY_ADMIN:
      appView = <AdminView />;
      break;
    case userRoles.STAFF:
      appView = <StaffView />;
      break;
    case userRoles.LOCUM:
      appView = <LocumView />;
      break;
    default:
      appView = <ErrorPage />;
  }

  return (
    <AbilityContext.Provider value={ability}>
      {appView}
      <Segmentation />
    </AbilityContext.Provider>
  );
};
