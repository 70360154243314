import React from "react";
import { useNavigate } from "react-router-dom";
import NoBranchImg from "../../../assets/img/emptyFolder2.png";
const NoPharmacyGap = ({text}) => {

  return (
    <div className=" d-flex align-items-center justify-content-center flex-column px-2 py-4" style={{height:'calc(100% - 100px)'}}>
      <img src={NoBranchImg} alt="nobranch" className="mb-3" />

      <p
        className="text-center "
        style={{
          color: "#000",
          maxWidth: "249px",
        }}
      >
        {text}
      </p>
    </div>
  );
};

export default NoPharmacyGap;
