import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import editIcon from "../../../../../assets/icons/edit.png";
import deleteIcon from "../../../../../assets/icons/delete.png";
import { ConfirmModal } from "../../../../../components/UI/modals/confirmModal";
import { TableComponet } from "../../../../../components/UI/table";
import "./style.scss";
import { CreateAdminRoles } from "./CreateAdminRoles";
import { EmptyData } from "../../../../../components/comon/EmptyData";
import { getAdminRolesThunk } from "../../../../../redux/profile/profileThunk";

export const AdminRolesTable = (props) => {
  const [currentPageCount, setCurrentPageCount] = useState(1);
  const dispatch = useDispatch();
  const [perPageCount, setPerPageCount] = useState(10);
  const { regions, regionBranches } = useSelector((state) => state.regions);
  const [selectedRegion, setSelectedRegion] = useState();
  const [regionId, setRegionId] = useState();
  const [requestType, setRequestType] = useState("Create");
  const [selectedRows, setSelectedRows] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { adminRoles } = useSelector((state) => state.profile);
  const [editRole, setEditRole] = useState();
  const rolesColumns = React.useMemo(
    () => [
      {
        Header: "Role Name",
        accessor: "label",
      },
      {
        Header: "Role Type",
        accessor: "roleType",
      },

      {
        Header: "",
        accessor: "status",
        Cell: ({ row: { original } }) => {
          return (
            <div>
              <Dropdown icon="ellipsis vertical" pointing="top right">
                <Dropdown.Menu direction="left">
                  <Dropdown.Item
                    icon={<img className="pt-2" src={editIcon} alt="" />}
                    text="Edit "
                    onClick={() => {
                      props.setShowCreateModal(!props.showCreateModal);

                      props.setActionType("Edit");
                      setEditRole(original);
                    }}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div style={{ padding: "0px 24px" }}>
      {showConfirmModal && (
        <ConfirmModal
          title={`Do you really want to Delete Admin role?`}
          description={`You are about to Delete the Role. This will Delete your Role and other information permanently.`}
          open={showConfirmModal}
          confirmButtonText="Delete"
          cancelButtonText="Cancel"
          onClose={() => setShowConfirmModal(!showConfirmModal)}
        // onConfirm={onRegionSubmit}
        />
      )}
      {/* {staffs.rows === undefined ? null :
         ()} */}
      {adminRoles?.length <= 0 ? (
        <EmptyData title="Admin Roles " />
      ) : (
        <TableComponet
          rowCursor="pointer"
          isBorderShown
          currentPageCount={currentPageCount}
          perPageCount={perPageCount}
          totalDataCount={adminRoles.length}
          setCurrentPage={setCurrentPageCount}
          setPerPageCount={setPerPageCount}
          showPagination
          // maxPageCount={totalPagesCount}
          columns={rolesColumns}
          data={adminRoles}
        // formatRowProps={formatTrProps}
        />
      )}
      <CreateAdminRoles
        editRole={editRole}
        setEditRole={setEditRole}
        actionType={props.actionType}
        setActionType={props.setActionType}
        showCreateModal={props.showCreateModal}
        handleModalToggle={() =>
          props.setShowCreateModal(!props.showCreateModal)
        }
      />
    </div>
  );
};
