import React, { memo } from "react";

export const FlexRow = memo(
  ({
    primaryTitle,
    primaryDescription,
    secondaryTitle,
    secondaryDescription,
  }) => {
    return (
      <div className="d-flex justify-content-between text-row">
        <div className="p3 primary-text">{primaryTitle}</div>
        <div className="p3 secondary-text" >
          {primaryDescription}
        </div>
        {secondaryTitle && (
          <div className="p3 primary-text">{secondaryTitle}</div>
        )}
        {secondaryDescription && (
          <div className="p3 secondary-text " >
            {secondaryDescription}
          </div>
        )}
      </div>
    );
  }
);

FlexRow.displayName = "FlexRow";
