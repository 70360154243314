import { Avatar, Badge, Tooltip } from "antd";
import React, { useMemo, useState } from "react";
import { minuteTohour } from "../../utils/dates";
import { truncateString } from "../../utils/helper";
import { TimeSheetModal } from "../UI/modals/TimesheetModal";

const Employeename = ({
  name,
  img,
  role,
  s,
  w,
  userId,
  roleId,
  initialStartDate,
  initialEndDate,
  initialView,
}) => {
  const [openModal, setopenModal] = useState(false);
  const handleclose = () => {
    setopenModal(false);
  };

  const keyForRemount = useMemo(() => {
    return Math.random();
  }, [openModal]);

  return (
    <>
      <div className="d-flex employee_Name_td">
        {/* <figure className="emp_img"> */}
        {!!img ? <Avatar src={img} alt="" /> : <Avatar>{name[0]}</Avatar>}
        {/* </figure> */}
        <div className="title mx-2">
          <h6>
            <button onClick={() => setopenModal(true)} className="btn">
              {truncateString(name, 15)}
            </button>
          </h6>
          <span className="text-capitalize">{role}</span>
        </div>

        <div className="badge_wrapper">
          <Tooltip
            title={
              <>
                <p className="mb-0">Scheduled: {minuteTohour(s)}</p>
                <p>Worked: {minuteTohour(w)}</p>
              </>
            }
          >
            <Badge>
              <span>S:{minuteTohour(s, "type1")}</span>{" "}
              <span>W:{minuteTohour(w, "type1")}</span>
            </Badge>
          </Tooltip>
        </div>
      </div>
      <TimeSheetModal
        key={keyForRemount}
        open={openModal}
        title="Timesheet Details"
        onClose={handleclose}
        name={name}
        role={role}
        image={img}
        userId={userId}
        scheduled={s}
        roleId={roleId}
        initialStartDate={initialStartDate}
        initialEndDate={initialEndDate}
        initialView={initialView}
      />
    </>
  );
};

export default Employeename;
