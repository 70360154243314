import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FlexRow } from "../../../../../../components/comon/flexRows";
import {
  ContractForm,
  contractTypeOptions,
} from "../../../../../../components/staffs/contracts/contractForm";
import { getStaffContractThunk } from "../../../../../../redux/staffs/staffsThunk";
import { staffApiKeys } from "../../../../../../utils/apiKeyConstants";
import { hasAccess } from "../../../../../../utils/helper";

export const ContractDetails = () => {
  const dispatch = useDispatch();
  const { contractId } = useParams();
  const { staffContract, staffInfo } = useSelector((state) => state.staffs);
  const { roles } = useSelector((state) => {
    return state.schedule;
  });
  const [showEditContractModal, setShowEditContractModal] = useState(false);

  useEffect(() => {
    if (hasAccess(staffApiKeys.GET_CONTRACT)) {
      dispatch(
        getStaffContractThunk({
          contractId,
        })
      );
    }
  }, [contractId, dispatch]);

  const role = roles.find(
    (data) => data.id === staffContract?.jobRoleId
  )?.label;
  return (
    <div
      className="d-flex flex-column flex-1"
      style={{ padding: "5px 24px", gap: 30 }}
    >
      <div className="heading4">{staffContract?.title}</div>
      <div className="border rounded">
        <div className="text-row d-flex align-items-center justify-content-between">
          <div className="s1 m-0">Basic Information</div>
          {hasAccess(staffApiKeys.UPDATE_CONTRACT) && (
            <div
              className="s1 m-0 cursor-pointer"
              onClick={() => setShowEditContractModal(true)}
              style={{ color: "#51C2BC" }}
            >
              Edit
            </div>
          )}
        </div>
        <FlexRow primaryTitle="Job Title" primaryDescription={role} />
        <FlexRow
          primaryTitle="Start Date"
          primaryDescription={
            moment(staffContract?.startDate).format("YYYY-MM-DD") || "-"
          }
        />
        <FlexRow
          primaryTitle="End Date"
          primaryDescription={
            moment(staffContract?.endDate).format("YYYY-MM-DD") || "-"
          }
        />
        <FlexRow
          primaryTitle="Contract Type"
          primaryDescription={
            contractTypeOptions?.find((i) => i.value === staffContract?.type)
              ?.label || "-"
          }
        />
        <FlexRow
          primaryTitle="Working Hours"
          primaryDescription={staffContract?.workingOurs}
        />

        <FlexRow
          primaryTitle="Absence Days"
          primaryDescription={staffContract?.absenceDays}
        />
      </div>
      <div className="border rounded">
        <div className="text-row d-flex align-items-center justify-content-between">
          <div className="s1 m-0">Earned Wage</div>
        </div>
        <FlexRow
          primaryTitle="Gross Salary"
          primaryDescription={
            `${staffContract?.grossSalaryPay}/${staffContract?.grossSalaryPeriodType}` ||
            "-"
          }
        />
        <FlexRow
          primaryTitle="Overtime Salary"
          primaryDescription={`${staffContract?.overTimePayHourly}/hour` || "-"}
        />
      </div>
      {staffContract?.contractAdditionCompensations?.length ? (
        <div className="border rounded">
          <div className="text-row d-flex align-items-center justify-content-between">
            <div className="s1 m-0">Compensation</div>
          </div>
          {staffContract?.contractAdditionCompensations?.map((data, index) => {
            return (
              <FlexRow
                key={index}
                primaryTitle={data.name}
                primaryDescription={`${data.payAmount}/${data.timePeriod}`}
              />
            );
          })}
        </div>
      ) : null}
      <ContractForm
        mode="Edit"
        showContractModal={showEditContractModal}
        setShowContractModal={() =>
          setShowEditContractModal(!showEditContractModal)
        }
      />
    </div>
  );
};
