import React from "react";

const StarIcon = ({ iconColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M12.0417 2.92422L13.5084 5.85755C13.7084 6.26589 14.2417 6.65755 14.6917 6.73255L17.35 7.17422C19.05 7.45755 19.45 8.69089 18.225 9.90755L16.1584 11.9742C15.8084 12.3242 15.6167 12.9992 15.725 13.4826L16.3167 16.0409C16.7834 18.0659 15.7084 18.8492 13.9167 17.7909L11.425 16.3159C10.975 16.0492 10.2334 16.0492 9.77502 16.3159L7.28336 17.7909C5.50002 18.8492 4.41669 18.0576 4.88336 16.0409L5.47502 13.4826C5.58336 12.9992 5.39169 12.3242 5.04169 11.9742L2.97502 9.90755C1.75836 8.69089 2.15002 7.45755 3.85002 7.17422L6.50836 6.73255C6.95002 6.65755 7.48336 6.26589 7.68335 5.85755L9.15002 2.92422C9.95002 1.33255 11.25 1.33255 12.0417 2.92422Z"
        fill={iconColor ? iconColor : "#FFC043"}
      />
    </svg>
  );
};

export default StarIcon;
