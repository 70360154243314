import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { BranchCard } from "../../components/UI/cards/profileCard";

import { ContentContainer } from "../../components/UI/container";
import BadgeIcon from "../../components/UI/icons/BadgeIcon";
import CalendarIcon from "../../components/UI/icons/CalendarIcon";
import ContractIcon from "../../components/UI/icons/ContractIcon";
import DocumentIcon from "../../components/UI/icons/DocumentIcon";
import NotificationIcon from "../../components/UI/icons/NotificationIcon";
import ProfileIcon from "../../components/UI/icons/ProfileIcon";
import TimeSheetIcon from "../../components/UI/icons/TimeSheetIcon";
import { SidebarNew } from "../../components/UI/sidebar-new/SidebarNew";
import CreateProfileCard from "../../components/complete-profile/CreateProfileCard/CreateProfileCard";
import { getRolesThunk } from "../../redux/schedule/scheduleThunk";
import "./style.scss";

export const StaffDetails = () => {
  const { user } = useSelector((state) => state.auth);
  const pathname = window.location.pathname;
  const dispatch = useDispatch();

  const sidebarLinks = useMemo(
    () => [
      ...(user.roleId === 6
        ? [
            {
              text: "Shift Gaps",
              to: "shift-gaps",
              disabled: false,
              hidden: false,
              icon: <ProfileIcon />,
            },
          ]
        : []),
      {
        text: "Personal Information",
        to: "information",
        disabled: false,
        hidden: false,
        icon: <ProfileIcon />,
      },
      {
        text: "Schedule",
        to: "schedule",
        disabled: false,
        hidden: false,
        icon: <CalendarIcon />,
      },
      {
        text: "Time Sheet",
        to: "time-sheet",
        disabled: false,
        hidden: false,
        icon: <TimeSheetIcon />,
      },
      {
        text: "Documents",
        to: "document",
        disabled: false,
        hidden: false,
        icon: <DocumentIcon />,
      },
      {
        text: "Leaves",
        to: "leaves",
        disabled: false,
        hidden: false,
        icon: <CalendarIcon />,
      },
      {
        text: "Contract",
        to: "contract",
        disabled: false,
        hidden: false,
        icon: <ContractIcon />,
      },
      {
        text: "Verification",
        to: "verifications",
        disabled: false,
        hidden: false,
        icon: <BadgeIcon />,
      },
      {
        text: "Notification Settings",
        to: "setting",
        disabled: false,
        hidden: false,
        icon: <NotificationIcon />,
      },
    ],
    [user.roleId]
  );

  const _status = useMemo(() => {
    if (!user) return null;

    if (user?.group === "locum") {
      return user?.status;
    }

    if (user?.status === "active" && Boolean(user?.isVerified)) {
      return "active";
    }

    return null;
  }, [user]);

  const _title = useMemo(() => {
    const link = sidebarLinks.find((link) => pathname?.includes(link.to));
    return link ? link.text : "";
  }, [sidebarLinks, pathname]);

  useEffect(() => {
    dispatch(
      getRolesThunk({
        group: ["staff"],
        type: "Default",
      })
    );
  }, []);

  return (
    <div className="content-view-page details-page">
      <div className="breadcrumb-wrapper">
        <h3 className="heading4">{_title}</h3>
      </div>
      <div className="main-wrapper ">
        <div className="content-holder">
          <SidebarNew
            links={sidebarLinks}
            visible
            hideDeactivate={true}
            completeProfileSection={<CreateProfileCard isGrouped />}
          >
            <BranchCard
              // userStatus={_status}
              userStatus={user?.status === "active" ? 1 : 0}
              logo={user.image}
              primaryText={`${user?.name} ${user?.surname}`}
              secondaryText={user.label}
              showStatus={true}
              gphc_no={user?.gphc_no}
              isBranch
            />
          </SidebarNew>
          <ContentContainer className="content-container">
            <Outlet />
          </ContentContainer>
        </div>
      </div>
    </div>
  );
};
