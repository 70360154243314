import { Checkbox, Popover, Tooltip } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Coffeicon from "../../../../src/assets/icons/Coffe.svg";
import StethoscopeIcon from "../../../../src/assets/icons/Stethoscope.svg";
import dashboardIcon from "../../../../src/assets/icons/dashboardIcon.svg";
import GearIcon from "../../../../src/assets/icons/gear.svg";
import lock from "../../../assets/icons/lock.png";
import ShiftDetailsView from "./ShiftDeatilsView";

const ShiftDetailsMonthView = ({
  index,
  shifts,
  monthview,
  type,
  events,
  dayNumber,
  locumFlow,
  showDateCheckbox,
  onDaySelect,
  branchId,
}) => {
  const navigate = useNavigate();
  const [openPopover, setOpenPopover] = useState(false);

  const handlePopover = (visible) => {
    setOpenPopover(visible);
  };

  if (events?.isEmpty && events?.staffLeave) {
    return <div />;
  }

  if (events?.branchClosed) {
    return (
      <div
        className="week-day-detail-cell-closed d-flex flex-column gap-1 justify-content-between cursor-pointer appear_on_hover"
        style={{ borderBottom: "none" }}
        onClick={() =>
          navigate(`/branches/${branchId}/details/information?tab=branch-hours`)
        }
      >
        <div>
          {dayNumber && (
            <div className="d-flex align-items-center justify-content-between month-view-flex">
              <span className=""> {moment(dayNumber).format("DD")}</span>
              {showDateCheckbox &&
                !dayjs(events.startDate).isBefore(dayjs(), "days") && (
                  <Checkbox
                    onChange={(e) => onDaySelect(e.target.checked, dayNumber)}
                  />
                )}
            </div>
          )}
        </div>
        <div className="d-flex flex-column align-items-center gap-2">
          <img src={lock} alt="" />
          <span className="t2">Closed</span>
        </div>
        <div></div>
      </div>
    );
  }

  return shifts?.length > 0 ? (
    shifts.map((shift) => {
      return (
        <React.Fragment key={shift?.id}>
          <div
            className={`leave_table_td ${monthview ? "monthview" : ""} ${
              shift?.status === "unfilled"
                ? "no_name"
                : shift?.status === "applied"
                ? "pending_review"
                : !shift?.lunchStartTime
                ? "locum"
                : ""
            }`}
          >
            <Popover
              content={
                <ShiftDetailsView
                  shifts={shift}
                  onClose={() => setOpenPopover(false)}
                  type={type}
                />
              }
              trigger="click"
              open={openPopover}
              showArrow={false}
              placement="rightTop"
              onOpenChange={handlePopover}
            >
              <div className="d-flex justify-content-between review">
                <Tooltip title={shift?.isStaff ? "Staff" : "Locum"}>
                  <h6>
                    {!shift?.staff?.name
                      ? "Unfilled"
                      : shift?.isLocumReviewPending
                      ? "Locums to review"
                      : shift?.staff?.name}{" "}
                  </h6>
                </Tooltip>
                {shift?.isLocumReviewPending && (
                  <Tooltip
                    title={`${shift?.reviewPendingCount} Locum requests to review`}
                  >
                    <span>{shift?.reviewPendingCount}</span>
                  </Tooltip>
                )}
              </div>
            </Popover>

            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <p className="shift_time">
                  {shift?.startTime} - {shift?.endTime}
                </p>
                {shift?.isStaff && (
                  <Tooltip title={`${shift?.breakDuration} Break`}>
                    <span className="d-flex breakTimeSpan">
                      <img src={Coffeicon} alt="break" />
                      {shift?.breakDuration}
                    </span>
                  </Tooltip>
                )}
              </div>
              <div>
                <img
                  src={
                    shift?.isLocum
                      ? GearIcon
                      : shift?.isStaff && StethoscopeIcon
                  }
                  alt=""
                  className="me-1"
                />
                {!shift?.isLocumReviewPending && (
                  <Tooltip title="Pattern">
                    <img src={dashboardIcon} alt="" />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          {shift?.isLocumReviewPending ? (
            <div
              className={`leave_table_td ${
                shift?.isLocum
                  ? "locum"
                  : shift?.staff?.name === ""
                  ? "no_name"
                  : monthview
                  ? "monthview"
                  : ""
              }`}
            >
              <Popover
                content={
                  <ShiftDetailsView
                    shifts={shift}
                    onClose={() => setOpenPopover(false)}
                    type={type}
                  />
                }
                trigger="click"
                open={openPopover}
                showArrow={false}
                placement="rightTop"
                onOpenChange={handlePopover}
              >
                <h6>
                  {!shift?.staff?.name ? "Unfilled" : shift?.staff?.name}{" "}
                </h6>
              </Popover>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  {" "}
                  <p className="shift_time">
                    {shift?.startTime} - {shift?.endTime}
                  </p>
                  {shift?.isStaff && (
                    <Tooltip title={`${shift?.breakDuration} Break`}>
                      {" "}
                      <span className="d-flex breakTimeSpan">
                        <img src={Coffeicon} alt="break" />{" "}
                        {shift?.breakDuration}
                      </span>
                    </Tooltip>
                  )}
                </div>
                <Tooltip title="Pattern">
                  <img src={dashboardIcon} alt="" />
                </Tooltip>
              </div>
            </div>
          ) : (
            !monthview && <div className="empty_td"></div>
          )}
        </React.Fragment>
      );
    })
  ) : (
    <div />
  );
};

export default ShiftDetailsMonthView;
