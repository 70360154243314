import React from "react";

const ClockIconPurple = ({ height, width, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : "17"}
      height={height ? height : "16"}
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M8.60026 1.33301C4.92693 1.33301 1.93359 4.32634 1.93359 7.99967C1.93359 11.673 4.92693 14.6663 8.60026 14.6663C12.2736 14.6663 15.2669 11.673 15.2669 7.99967C15.2669 4.32634 12.2736 1.33301 8.60026 1.33301ZM11.5003 10.3797C11.4069 10.5397 11.2403 10.6263 11.0669 10.6263C10.9803 10.6263 10.8936 10.6063 10.8136 10.553L8.74693 9.31967C8.23359 9.01301 7.85359 8.33967 7.85359 7.74634V5.01301C7.85359 4.73967 8.08026 4.51301 8.35359 4.51301C8.62693 4.51301 8.85359 4.73967 8.85359 5.01301V7.74634C8.85359 7.98634 9.05359 8.33967 9.26026 8.45967L11.3269 9.69301C11.5669 9.83301 11.6469 10.1397 11.5003 10.3797Z"
        fill={color ? color : "#9245F0"}
      />
    </svg>
  );
};

export default ClockIconPurple;
