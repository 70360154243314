import { useQuery } from "@tanstack/react-query";
import {
  Alert,
  Avatar,
  Badge,
  Button,
  Table,
  Tooltip,
} from "antd";
import { isEqual } from "lodash";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Modal from "react-bootstrap/Modal";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { queryClient } from "../../..";
import ClockIcon from "../../../assets/icons/clock-grey.svg";
import CloseCircle from "../../../assets/icons/close-circle.svg";
import CloseIcon from "../../../assets/icons/close.svg";
import CoffeIcon from "../../../assets/icons/coffe-grey.svg";
import EditIcon from "../../../assets/icons/edit-2.svg";
import TickCircle from "../../../assets/icons/tick-circle.svg";
import { getStaffTimeSheetDetailsStaff } from "../../../redux/staffs/staffsAPI";
import {
  setStaffTimeSheetEditedUsingCases,
  setstaffTimeSheetEdited,
} from "../../../redux/staffs/staffsSlicer";
import {
  createAttendanceThunk,
  updateAttendanceThunk,
} from "../../../redux/staffs/staffsThunk";
import { personalApiKeys } from "../../../utils/apiKeyConstants";
import {
  ATTENDANCE_TYPE,
  VIEW_OPTIONS_TIMESHEET,
  shiftStatus,
} from "../../../utils/constant";
import {
  getAllMonthDays,
  getCurrentWeekDays,
  minuteTohour,
} from "../../../utils/dates";
import eventEmitter, {
  events,
} from "../../../utils/eventEmitter";
import {
  hasAccess,
  notify,
} from "../../../utils/helper";
import {
  calculateTotalHourForEmployee,
  showLabel,
} from "../../../utils/timeshhet";
import TableHeader from "../../TimesheetNew/CustomTableHeader";
import BreakTime from "../../TimesheetNew/TableCellComponents/BreakTime";
import CheckinOut from "../../TimesheetNew/TableCellComponents/CheckinOut";
import PharmacyDetails from "../../TimesheetNew/TableCellComponents/PharmacyDetails";
import ShiftTime from "../../TimesheetNew/TableCellComponents/ShiftTime";
import StatusBadge from "../../TimesheetNew/TableCellComponents/StatusBadge";
import TotalHours from "../../TimesheetNew/TableCellComponents/TotalHours";
import CommonToolbar from "../../Toolbar/CommonToolbar";
import "./style.scss";

const renderDate = (Day, Date) => {
  return (
    <div className="date_render">
      <p>{Day}</p>
      <b>{Date}</b>
    </div>
  );
};

const WorkStatusChip = (key, value, icon) => {
  return (
    <Badge className="workstatus">
      <img src={icon} alt={key} />
      <p>{key}</p>
      <span>{value}</span>
    </Badge>
  );
};

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    width: 200,
    //   rowScope: "row",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: 180,
  },
  {
    title: "Branch",
    dataIndex: "presence",
    width: 200,
  },
  {
    title: "Shift Timings",
    dataIndex: "shifttime",
    width: 150,
  },
  {
    title: "Break",
    dataIndex: "break",
    width: 100,
  },
  {
    title: "Total",
    dataIndex: "total",
    width: 100,
  },
  {
    title: "Check In/Out",
    dataIndex: "checkin",
    width: 150,
  },
  {
    title: "Actions",
    dataIndex: "action",
    width: 120,
    //   fixed: "right",
  },
];

const format = "YYYY-MM-DD";

export const TimeSheetModal = ({
  open,
  onClose,
  description,
  title,
  formId,
  image,
  backdrop,
  name,
  role,
  userId,
  roleId,
  initialEndDate,
  initialStartDate,
  initialView,
}) => {
  const [edit, setedit] = useState(false);
  const dispatch = useDispatch();
  const { staffTimeSheetEdited } = useSelector(
    (s) => s.staffs
  );
  const beforeEditEditingDataset = useRef([]);

  const [selectedWeek, setSelectedWeek] =
    useState(1);
  const [startDate, setStartDate] = useState(
    moment(initialStartDate, format)
  );
  const [endDate, setEndDate] = useState(
    moment(initialEndDate, format)
  );
  const allShiftsRef = useRef([]);
  const [
    timeSheeiEditError,
    setTimeSheetEditError,
  ] = useState(null);

  const [filterValues, setFilterValue] = useState(
    {
      filters: {
        startDate: startDate.format(format),
        endDate: endDate.format(format),
        query: "",
      },
      // pagination: {
      //   page: 1,
      //   perPage: 10,
      // },
    }
  );

  const { data } = useQuery({
    queryKey: [
      `getStaffTimeSheetDetails_${userId}`,
      filterValues,
      initialStartDate,
    ],
    queryFn: () =>
      getStaffTimeSheetDetailsStaff(
        filterValues,
        userId
      ),
    enabled:
      hasAccess(
        personalApiKeys.ATTENDANCE_LIST
      ) && open,
    refetchOnMount: true,
  });

  const [view, setView] = useState(
    initialView || "weekly"
  );

  const [dateRange, setDateRange] = useState({
    startDateRange: startDate,
    endDateRange: endDate,
  });

  const handleViewChange = (view) => {
    setView(view);
  };

  const updateDateRange = useCallback(
    (addOrSubtract) => {
      if (
        view === "weekly" ||
        view === "monthly" ||
        view === "daily"
      ) {
        const interval =
          view === "weekly"
            ? "weeks"
            : view === "monthly"
            ? "months"
            : "days";
        const modifier =
          addOrSubtract === "next" ? 1 : -1;

        const _dateStart = moment(
          dateRange?.startDateRange
        ).add(modifier, interval);
        const _dateEnd = moment(
          dateRange?.endDateRange
        ).add(modifier, interval);

        setDateRange({
          startDateRange: _dateStart,
          endDateRange: _dateEnd,
        });

        setFilterValue({
          ...filterValues,
          filters: {
            ...filterValues.filters,
            startDate: _dateStart.format(format),
            endDate: _dateEnd.format(format),
          },
        });
      }
    },
    [
      dateRange?.endDateRange,
      dateRange?.startDateRange,
      filterValues,
      view,
    ]
  );

  const handlePrevious = useCallback(() => {
    updateDateRange("previous");
  }, [updateDateRange]);

  const handleNext = useCallback(() => {
    updateDateRange("next");
  }, [updateDateRange]);

  const handleAttandanceUpdateBulk = () => {
    let payload = [];
    let newShift = [];
    let noShift = [];
    let hasError = false;
    setTimeSheetEditError(null);

    if (staffTimeSheetEdited.length === 0) return;

    staffTimeSheetEdited?.forEach(
      (_entry, index) => {
        if (hasError) {
          return;
        }

        const isFutureDate = moment(
          _entry?.startDate
        )
          ?.endOf("date")
          .isAfter(moment()?.endOf("date"));

        if (!_entry?.branchId) {
          return;
        }

        // check no shift and not new shift
        if (
          _entry?.type ===
            ATTENDANCE_TYPE.NO_SHIFT &&
          !Boolean(_entry?.isNew)
        ) {
          noShift.push({
            recordId: _entry?.recordId,
            branchId: _entry?.branchId,
            checkIn: _entry?.checkIn,
            checkOut: _entry?.checkOut,
            type: _entry?.type,
            status: _entry?.status,
            leaveType: _entry?.leaveType,
            absenceId: _entry?.absenceId,
            date: _entry?.startDate,
            shiftId: _entry?.shiftId,
          });
          return;
        }

        //if this is true checkin checkout time validation will be applicable otherwise not
        const isTypePresent =
          ATTENDANCE_TYPE.PRESENT ===
            _entry?.type ||
          _entry?.status === shiftStatus.UNFILLED;

        console.log(_entry, "_entry");

        // if checkout times are empty then show error
        if (
          !isFutureDate &&
          isTypePresent &&
          (!_entry?.checkIn?.length ||
            !_entry?.checkOut?.length) &&
          _entry?.type !== ATTENDANCE_TYPE.LEAVE
        ) {
          hasError = true;
          setTimeSheetEditError(
            "Please enter valid check-in and check-out times."
          );
          return;
        }

        const previousData =
          beforeEditEditingDataset?.current?.[
            index
          ];
        const noChangesMade = isEqual(
          JSON.stringify(_entry),
          JSON.stringify(previousData)
        );

        if (noChangesMade) {
          return;
        }

        if (
          isTypePresent &&
          (!_entry?.checkIn || !_entry?.checkOut)
        ) {
          hasError = true;

          return;
        }

        const momentCheckIn = moment(
          _entry?.checkIn,
          "HH:mm"
        );
        const momentCheckOut = moment(
          _entry?.checkOut,
          "HH:mm"
        );

        if (
          isTypePresent &&
          (!momentCheckIn.isValid() ||
            !momentCheckOut.isValid() ||
            momentCheckIn.isAfter(
              momentCheckOut
            ) ||
            _entry?.checkIn === _entry?.checkOut)
        ) {
          hasError = true;
          return;
        }

        if (
          _entry?.isNew === true &&
          _entry?.type !==
            ATTENDANCE_TYPE.NO_SHIFT
        ) {
          newShift.push({
            startDate: _entry?.startDate,
            endDate: _entry?.endDate,
            lunchStartTime:
              _entry?.lunchStartTime,
            lunchEndTime: _entry?.lunchEndTime,
            startTime: _entry?.startTime || null,
            endTime: _entry?.endTime || null,
            branchId: _entry?.branchId,
            staffId: userId,
            days: _entry?.days || [],
            roleId: roleId,
            checkIn: _entry?.checkIn,
            checkOut: _entry?.checkOut,
            leaveType: _entry?.leaveType,
            type: _entry?.type,
            absenceId: _entry?.absenceId,
            date: _entry?.startDate,
            shiftId: _entry?.shiftId,
          });
          return;
        }

        const payloadData = {
          recordId: _entry?.recordId,
          branchId: _entry?.branchId,
          checkIn: _entry?.checkIn,
          checkOut: _entry?.checkOut,
          type: _entry?.type,
          status: _entry?.status,
          leaveType: _entry?.leaveType,
          absenceId: _entry?.absenceId,
          date: _entry?.startDate,
          shiftId: _entry?.shiftId,
        };

        if (
          _entry?.status === shiftStatus.UNFILLED
        ) {
          payloadData.status =
            shiftStatus?.PRESENT;
          payloadData.type =
            ATTENDANCE_TYPE?.PRESENT;
        }
        payload.push(payloadData);
      }
    );

    if (hasError) {
      return;
    }
    const promises = [];

    if (payload?.length > 0) {
      // let _temp=payload.filter()
      promises.push(
        dispatch(
          updateAttendanceThunk({
            payload,
            userId,
          })
        )
      );
    }

    if (noShift.length > 0) {
      promises.push(
        dispatch(
          updateAttendanceThunk({
            payload: noShift,
            userId,
          })
        )
      );
    }

    if (newShift?.length > 0) {
      let _temp = [];
      newShift.forEach((item) => {
        let type = item?.type;

        if (
          item?.type !== ATTENDANCE_TYPE.LEAVE
        ) {
          type =
            item?.checkIn?.length > 0 &&
            item?.checkOut?.length > 0
              ? ATTENDANCE_TYPE.PRESENT
              : ATTENDANCE_TYPE.ABSENT;
        }
        _temp.push({
          ...item,
          type,
        });
      });

      promises.push(
        dispatch(createAttendanceThunk(_temp))
      );
    }

    if (promises?.length > 0) {
      Promise.all(promises)
        .then(() => {
          queryClient.invalidateQueries(
            `getStaffTimeSheetDetails_${userId}`
          );
          queryClient.invalidateQueries(
            "getStaffTimeSheet"
          );
        })
        ?.finally(() => {
          setedit(false);
        });
    } else {
      setedit(false);
    }
  };

  const handleAttandanceAcceptOrReject = (
    data,
    type
  ) => {
    let actualCheckIn =
      data?.actualCheckIn || data?.manualCheckIn;
    let actualCheckOut =
      data?.actualCheckOut ||
      data?.manualCheckOut;

    if (data?.status === "pending") {
      actualCheckIn = data?.manualCheckIn;
      actualCheckOut = data?.manualCheckOut;
    }

    const payload = [
      {
        recordId: data?.id,
        checkIn: actualCheckIn,
        checkOut: actualCheckOut,
        type,
      },
    ];

    dispatch(
      updateAttendanceThunk({ payload, userId })
    ).then((res) => {
      if (res.payload) {
        queryClient.invalidateQueries(
          `getStaffTimeSheetDetails_${userId}`
        );
        queryClient.invalidateQueries(
          "getStaffTimeSheet"
        );
        notify(
          "success",
          "Shifts updated successfully."
        );
      }
    });
  };

  const handleCellEdit = useCallback(
    (data) => {
      dispatch(
        setStaffTimeSheetEditedUsingCases(data)
      );
    },
    [dispatch]
  );

  const splitShiftPerWeek = () => {
    const start =
      selectedWeek === 1
        ? 0
        : (selectedWeek - 1) * 6;
    const end = start + 7;
    return getWeekArray.slice(start, end);
  };

  const handleEditMode = () => {
    setedit(!edit);
  };

  const handleAttandanceAcceptOrRejectAll = (
    type
  ) => {
    let payload = [];

    if (allShiftsRef.current?.length) {
      allShiftsRef.current.forEach((_shift) => {
        if (
          _shift?.attendance?.status === "pending"
        ) {
          payload?.push({
            recordId: _shift?.attendance?.id,
            checkIn:
              _shift?.attendance?.manualCheckIn,
            checkOut:
              _shift?.attendance?.manualCheckOut,
            type,
          });
        }
      });
    }

    if (payload?.length) {
      payload?.length &&
        dispatch(
          updateAttendanceThunk({
            payload,
            userId,
          })
        ).then((res) => {
          if (res.payload) {
            queryClient.invalidateQueries(
              `getStaffTimeSheetDetails_${userId}`
            );
            queryClient.invalidateQueries(
              "getStaffTimeSheet"
            );
            notify(
              "success",
              "Shifts updated successfully."
            );
          }
        });
    } else {
      notify(
        "error",
        "There are no pending shifts"
      );
    }
  };

  const disableApproveAll = useMemo(() => {
    let temp = [];

    if (allShiftsRef.current?.length) {
      temp = allShiftsRef.current.filter(
        (i) => i?.attendance?.status === "pending"
      );

      if (temp?.length === 0) {
        return true;
      }
    }

    return false;
  }, [allShiftsRef?.current]);

  const getWeekArray = useMemo(() => {
    let temp = [];
    if (view === "weekly") {
      temp = getCurrentWeekDays(
        dateRange?.startDateRange,
        (dateRange?.startDateRange, format),
        ""
      );
    }

    if (view === "monthly") {
      temp = getAllMonthDays(
        dateRange?.startDateRange,
        dateRange?.endDateRange,
        format
      );
    }

    return temp;
  }, [
    dateRange?.endDateRange,
    dateRange?.startDateRange,
    view,
  ]);

  const dataset = useMemo(() => {
    let temp = [];

    const allDates =
      view === "monthly"
        ? splitShiftPerWeek()
        : getWeekArray;
    let all = [];

    const editingDataset = [];
    allDates?.forEach((date) => {
      const _shifts =
        data?.data[0]?.shifts?.filter(
          (_shift) =>
            _shift?.startDate ===
            moment(date).format(format)
        );

      // absence
      const _absences =
        data?.data[0]?.absences?.filter(
          (_leave) =>
            moment(_leave?.from).format(
              format
            ) === moment(date).format(format)
        );

      if (_absences?.length > 0) {
        _absences?.forEach((item) => {
          all.push({
            startDate:
              moment(date).format(format),
            endDate: moment(date).format(format),
            date: moment(date).format(format),
            isNew: true,
            branchId:
              data?.data[0]?.staffBranch?.id,
            branch: data?.data[0]?.staffBranch,
            absenceId: item?.id,
            attendance: {
              actualCheckIn: null,
              actualCheckOut: null,
              manualCheckIn: null,
              manualCheckOut: null,
              id: item?.id,
              type: item?.type,
              status: "leave",
              date: item?.from,
            },
          });
        });
      }

      if (_shifts?.length > 0) {
        all = [...all, ..._shifts];
      }

      if (
        _shifts?.length === 0 &&
        _absences?.length === 0
      ) {
        all = [
          ...all,
          {
            startDate:
              moment(date).format(format),
            endDate: moment(date).format(format),
            isEmpty: true,
          },
        ];
      }
    });

    all.forEach((shift, _index) => {
      //jsx
      temp.push({
        date: renderDate(
          moment(shift?.startDate, format).format(
            "ddd"
          ),
          moment(shift?.startDate, format).format(
            "DD MMM"
          )
        ),
        status: <StatusBadge index={_index} />,
        presence: (
          <PharmacyDetails
            isInEditMode={edit}
            index={_index}
          />
        ),
        shifttime: (
          <ShiftTime
            isInEditMode={edit}
            index={_index}
          />
        ),
        break: (
          <BreakTime
            index={_index}
            isInEditMode={false}
            shift={shift}
          />
        ),
        total: <TotalHours index={_index} />,
        checkin: (
          <CheckinOut
            index={_index}
            isInEditMode={edit}
          />
        ),
        action: shift?.isEmpty ? (
          <div className="action_buttons"></div>
        ) : (
          <div className="action_buttons">
            {shift?.attendance?.status ===
            "pending" ? (
              <>
                <Tooltip title="Approve">
                  <Button
                    className="btn"
                    icon={
                      <img
                        src={TickCircle}
                        alt="tick"
                      />
                    }
                    onClick={() =>
                      handleAttandanceAcceptOrReject(
                        shift?.attendance,
                        shiftStatus.PRESENT
                      )
                    }
                  />
                </Tooltip>
                <Tooltip title="Decline">
                  <Button
                    className="btn"
                    onClick={() =>
                      handleAttandanceAcceptOrReject(
                        shift?.attendance,
                        shiftStatus.ABSENT
                      )
                    }
                    icon={
                      <img
                        src={CloseCircle}
                        alt="close"
                      />
                    }
                  />
                </Tooltip>
              </>
            ) : null}
          </div>
        ),
      });

      if (!shift?.isEmpty) {
        let actualCheckIn =
          shift?.attendance?.actualCheckIn ||
          shift?.attendance?.manualCheckIn;
        let actualCheckOut =
          shift?.attendance?.actualCheckOut ||
          shift?.attendance?.manualCheckOut;

        if (
          shift?.attendance?.status === "pending"
        ) {
          actualCheckIn =
            shift?.attendance?.manualCheckIn;

          actualCheckOut =
            shift?.attendance?.manualCheckOut;
        }

        editingDataset.push({
          ...shift,
          recordId:
            shift?.attendance?.id || _index,
          checkIn: actualCheckIn || null,
          checkOut: actualCheckOut || null,
          status:
            shift?.attendance?.status || null,
          type: shift?.attendance?.type || null,
          leaveType:
            shift?.attendance?.leaveType || null, //TODO:
          branchId: shift?.branchId || null,
          shiftId: shift?.id,
        });
      } else {
        editingDataset.push({
          recordId: _index,
          type: null,
          checkIn: null,
          checkOut: null,
          status: null,
          leaveType: null,
          branchId: null,
          isNew: true,
          startDate: shift?.startDate,
          endDate: shift?.startDate,
          lunchStartTime: null,
          lunchEndTime: null,
          startTime: null,
          endTime: null,
          staffId: userId,
          days: [
            moment(
              shift?.startDate,
              format
            ).format("dddd"),
          ],
          roleId,
        });
      }
    });

    allShiftsRef.current = all;
    dispatch(
      setstaffTimeSheetEdited(editingDataset)
    );
    beforeEditEditingDataset.current = [
      ...editingDataset,
    ];

    return temp;
  }, [
    JSON.stringify(data?.data),
    JSON.stringify(getWeekArray),
    edit,
    selectedWeek,
    view,
    roleId,
    userId,
  ]);

  const RenderValue = useMemo(() => {
    if (view === "weekly") {
      return `${dateRange?.startDateRange?.format(
        "DD MMM"
      )} - ${dateRange?.endDateRange?.format(
        "DD MMM YYYY"
      )}`;
    }

    if (view === "monthly") {
      return `${dateRange?.startDateRange?.format(
        "MMM YYYY"
      )}`;
    }

    // if (view === "daily") {
    //   return `${dateRange?.startDateRange?.format("DD MMM YYYY")}`;
    // }

    return "";
  }, [
    dateRange?.endDateRange,
    dateRange?.startDateRange,
    view,
    initialView,
  ]);

  const _totalInfo = useMemo(() => {
    if (!!data?.data) {
      return calculateTotalHourForEmployee(
        data?.data[0]?.shifts
      );
    }

    return {
      scheduleHour: 0,
      breakTime: 0,
      total: 0,
    };
  }, [JSON.stringify(data?.data)]);

  const hasFutureDate = useMemo(() => {
    const _dates = getWeekArray.map((_date) => {
      return moment(_date).format(format);
    });

    return _dates.some((_date) => {
      return moment(_date).isAfter(moment());
    });
  }, [getWeekArray]);

  const isSomethingEdited = useMemo(() => {
    return (
      JSON.stringify(
        beforeEditEditingDataset.current
      ) !== JSON.stringify(staffTimeSheetEdited)
    );
  }, [staffTimeSheetEdited]);

  useEffect(() => {
    !edit && setTimeSheetEditError(null);
  }, [edit]);

  useEffect(() => {
    if (open) {
      setedit(false);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      eventEmitter.on(
        events.timeSheetCelledit,
        handleCellEdit
      );
    }

    return () => {
      eventEmitter.off(
        events.timeSheetCelledit,
        handleCellEdit
      );
    };
  }, [handleCellEdit, open]);

  useEffect(() => {
    const viewToDates = {
      weekly: {
        startDateRange: moment(
          initialStartDate,
          format
        ).startOf("isoWeek"),
        endDateRange: moment(
          initialStartDate,
          format
        ).endOf("isoWeek"),
      },
      daily: {
        startDateRange: moment(),
        endDateRange: moment(),
      },
      monthly: {
        startDateRange: moment(
          initialStartDate,
          format
        ).startOf("month"),
        endDateRange: moment(
          initialStartDate,
          format
        ).endOf("month"),
      },
    };

    if (viewToDates.hasOwnProperty(view)) {
      const dateData = viewToDates[view];
      setDateRange(dateData);

      if (view !== "daily") {
        setFilterValue((prevFilterValues) => ({
          ...prevFilterValues,
          filters: {
            ...prevFilterValues.filters,
            startDate:
              dateData.startDateRange.format(
                format
              ),
            endDate:
              dateData.endDateRange.format(
                format
              ),
          },
        }));
      }
    }
  }, [view]);

  return (
    <Modal
      centered
      show={open}
      onHide={onClose}
      backdrop={backdrop}
      className="timesheet_modal"
      autoFocus={false}
      enforceFocus={false}
    >
      <Modal.Header style={{ padding: 16 }}>
        <div className="d-flex justify-content-between align-items-center w-100">
          <h3 className="heading4 modal_header">
            {title}
          </h3>{" "}
          <Button
            onClick={onClose}
            style={{
              padding: 0,
              margin: 0,
              background: "none",
              border: "none",
              marginBottom: "8px",
            }}
          >
            <img
              src={CloseIcon}
              alt="close"
              style={{
                filter:
                  "brightness(0) saturate(100%) invert(37%) sepia(0%) saturate(0%) hue-rotate(190deg) brightness(98%) contrast(85%)",
              }}
            />
          </Button>{" "}
        </div>
      </Modal.Header>
      <Modal.Body
        className={`gap-4 rounded  d-flex flex-column t1 timesheet_modal`}
        style={{
          padding: "20px 16px",
          width: "100%",
        }}
      >
        <div className="main_modal_body">
          <div
            className="s1"
            style={{ color: "#7B7B7B" }}
          >
            {description}
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex employee_Name_td">
              {!!image ? (
                <Avatar
                  src={image}
                  size="large"
                  alt={name}
                />
              ) : (
                <Avatar size="large">
                  {name[0]}
                </Avatar>
              )}

              <div
                className="title mx-2"
                style={{
                  lineHeight: "16px",
                }}
              >
                <h6>
                  <a href="#">{name}</a>
                </h6>
                <span className="role text-capitalize">
                  {showLabel(role)}
                </span>
              </div>
            </div>
            <div className="modal_header_right">
              {!edit && (
                <Button
                  className="btn edit_button"
                  icon={
                    <img
                      src={EditIcon}
                      alt="edit"
                    />
                  }
                  onClick={() => handleEditMode()}
                />
              )}
              {/* <button className="btn custom_btn outlined">
                Copy Branch Time{" "}
                <img
                  src={DrowdownIcon}
                  className="me-0 ms-1"
                  style={{ width: 12, height: 12 }}
                  alt="dropdown"
                />
              </button> */}
              {!disableApproveAll && (
                <button
                  onClick={() =>
                    handleAttandanceAcceptOrRejectAll(
                      shiftStatus.PRESENT
                    )
                  }
                  className="btn custom_btn attendence"
                  disabled={disableApproveAll}
                >
                  Approve All
                </button>
              )}
            </div>
          </div>
          <CommonToolbar
            selectedView={view}
            handleViewChange={handleViewChange}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            RenderValue={RenderValue}
            isMonthGridView={false}
            type="timesheet"
            displayOptions={{
              hideRole: true,
              hideView: false,
              hideSearch: true,
              hideJobs: true,
              hideFilter: true,
            }}
            disabled={edit}
            viewOptions={VIEW_OPTIONS_TIMESHEET}
            endAdroment={
              <div className="modal_filter_wrapper">
                <div className="filter-left"></div>
                <div className="filter_right">
                  {WorkStatusChip(
                    "Scheduled",
                    minuteTohour(
                      _totalInfo.scheduleHour
                    ),
                    ClockIcon
                  )}
                  {WorkStatusChip(
                    "Total ",
                    minuteTohour(
                      _totalInfo.total
                    ),
                    ClockIcon
                  )}
                  {WorkStatusChip(
                    "Break",
                    minuteTohour(
                      _totalInfo.breakTime
                    ),
                    CoffeIcon
                  )}
                </div>
              </div>
            }
          />
          {!!timeSheeiEditError && edit && (
            <Alert
              className="mb-2"
              message={timeSheeiEditError}
              type="error"
            />
          )}

          {hasFutureDate && edit && (
            <Alert
              className="mb-2"
              message="⚠️ Please note that editing is not allowed for future dates. Changes can only be made to content within the current date or earlier. Thank you for your understanding!"
              type="warning"
            />
          )}

          <div className="timesheet_modal_table_wrapper">
            {view === "monthly" ? (
              <Table
                columns={columns}
                dataSource={dataset}
                bordered
                title={() =>
                  view === "monthly" ? (
                    <TableHeader
                      weekCount={5}
                      selectedWeek={selectedWeek}
                      setSelectedWeek={
                        setSelectedWeek
                      }
                    />
                  ) : (
                    <></>
                  )
                }
                pagination={false}
                scroll={{
                  x: 1200,
                  y: "calc(100vh - 460px)",
                }}
              />
            ) : (
              <Table
                columns={columns}
                dataSource={dataset}
                bordered
                pagination={false}
                scroll={{
                  x: 1200,
                  y: "calc(100vh - 460px)",
                }}
              />
            )}
          </div>
        </div>
      </Modal.Body>
      {edit && (
        <Modal.Footer>
          <div
            className="d-flex jusify-content-end align-items-end modal_bottom"
            style={{ flex: "1", width: "100%" }}
          >
            <button
              onClick={() => setedit(false)}
              block
              size="large"
              className="cancel_btn custom_btn outlined btn"
            >
              Cancel
            </button>

            {isSomethingEdited ? (
              <button
                onClick={
                  handleAttandanceUpdateBulk
                }
                type="primary"
                htmlType="submit"
                form={formId}
                block
                size="large"
                className="save custom_btn btn"
                // disabled={staffTimeSheetEdited.length === 0}
              >
                Save & Approve All
              </button>
            ) : null}
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};
