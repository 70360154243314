import { Button, Form, Popover, Radio } from "antd";
import React, { memo, useEffect, useState } from "react";
import infoIcon from "../../assets/icons/infoIcon1.png";
import { AddModal } from "../UI/modals/addModal";

import { CopyOutlined } from "@ant-design/icons";

import { AutoComplete, Checkbox, DatePicker, Input, Select } from "antd";
import dayjs from "dayjs";
import moment from "moment/moment";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createJobBulkThunk,
  createShiftBulkThunk,
  editJobThunk,
  editShiftThunk,
  searchLocumByFilterThunk,
} from "../../redux/schedule/scheduleThunk";
import { branchApiKeys, locumShiftApiKeys } from "../../utils/apiKeyConstants";
import { copyToClipBoard, hasAccess } from "../../utils/helper";
import { ConfirmModal } from "../UI/modals/confirmModal";

import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import _ from "lodash";
import {
  addTemplateShiftThunk,
  editTemplateShiftThunk,
} from "../../redux/branches/branchesThunk";
import { getDuration, weekDays } from "../../utils/dates";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
const { RangePicker } = DatePicker;

const options = [
  { value: "Shift", label: "Staff Shift" },
  { value: "Job", label: "Locum Job" },
];

export const ShiftJobFormUpdated = memo(
  ({
    gapData,
    showModal,
    setHandleModal,
    branchId,
    operatingTime,
    selectedDate,
    onSuccess,
    data,
    isTemplate,
    selectedDay,
    weekNumber,
    onDelete,
  }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const formId = useMemo(() => "jobShiftForm", []);
    const roleId = Form.useWatch("roleId", form);
    const dateRange = Form.useWatch("dateRange", form);
    const timeObj = Form.useWatch("timeObj", form);
    const hasCoverExpense = Form.useWatch("hasCoverExpense", form);
    const hasExternalLocum = Form.useWatch("hasExternalLocum", form);
    const externalLocumBooking = Form.useWatch("externalLocumBooking", form);
    const paidLunch = Form.useWatch("paidLunch", form);
    const [memoDate, setMemoDate] = useState(null);
    const [dates, setDates] = useState([]);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorData, setErrorData] = useState([]);
    const [type, setType] = useState("Shift");
    const [locumRoles, setLocumRoles] = useState();
    const [staffRoles, setStaffRoles] = useState();

    const { roles } = useSelector((state) => {
      return state.schedule;
    });

    const { staffs, locums } = useSelector((state) => {
      return state.schedule;
    });
    const {
      mileageRules,
      accommodationAndFoodRules,
      parkingRules,
      transportRules,
    } = useSelector((state) => state.profile);

    const locumOptions = useMemo(
      () =>
        locums
          ?.filter(
            (i) =>
              i?.roleId === roleId ||
              (roleId === 4 && i.type === "pharmacist") ||
              (roleId === 5 && i.type === "technician")
          )
          ?.map((data, idx) => {
            return {
              key: idx,
              label: `${
                data?.name ? data?.name : data?.forenames ? data?.forenames : ""
              } ${data?.surname ? data?.surname : ""}`,
              value: data?.gphcNumber,
            };
          }),
      [locums, roleId]
    );

    useEffect(() => {
      if (type !== "Job") return;
      let filters = {};
      if (externalLocumBooking?.gphcNumber) {
        filters["name"] = externalLocumBooking?.gphcNumber;
        filters["gphcNumber"] = externalLocumBooking?.gphcNumber;
        filters["roleId"] = roleId;
      }
      dispatch(searchLocumByFilterThunk({ filters }));
    }, [dispatch, externalLocumBooking?.gphcNumber, type, roleId]);

    useEffect(() => {
      if (!selectedDate) return;
      form.setFieldsValue({
        dateRange: [dayjs(selectedDate), dayjs(selectedDate)],
      });
    }, [selectedDate]);

    useEffect(() => {
      if (isTemplate) {
        const weekDaysValue = weekDays
          .map((d) => {
            const weekTime = operatingTime?.find(
              (ot) => ot.dayOfWeek.toUpperCase() === d.toUpperCase()
            );
            const weekDay = d;
            const lunchStartTime = data
              ? data?.lunchStartTime
              : gapData
              ? gapData?.lunchStartTime
              : weekTime
              ? weekTime?.lunchStartTime
              : null;
            const lunchEndTime = data
              ? data?.lunchEndTime
              : gapData
              ? gapData?.lunchEndTime
              : weekTime
              ? weekTime?.lunchEndTime
              : null;
            const checked = d === selectedDay;
            const time =
              data || gapData
                ? `${data ? data.startTime : gapData.startTime}-${
                    data ? data.endTime : gapData.endTime
                  }`
                : ``;
            return weekTime
              ? {
                  weekDay,
                  lunchStartTime,
                  lunchEndTime,
                  checked,
                  time,
                }
              : undefined;
          })
          .filter(
            (d) =>
              !_.isEmpty(d) &&
              (data ? d.weekDay.toLowerCase() === selectedDay.toLowerCase() : d)
          );

        form.setFieldsValue({
          timeObj: weekDaysValue,
          staffId: data?.staff?.id,
        });
        return;
      }
      if (!dateRange?.length) return;
      let startDate = dateRange[0];
      let endDate = dateRange[1];

      let days = [];
      while (
        moment(startDate.format("YYYY-MM-DD")).isSameOrBefore(
          endDate.format("YYYY-MM-DD")
        )
      ) {
        const day = startDate.format("dddd");
        if (!days.includes(day)) days.push(day);
        startDate = startDate.add(1, "day");
      }

      const weekDaysValue = days
        .map((d) => {
          const weekTime = operatingTime?.find(
            (ot) => ot.dayOfWeek.toUpperCase() === d.toUpperCase()
          );
          const weekDay = d;
          const lunchStartTime = data
            ? data?.lunchStartTime
            : gapData
            ? gapData?.lunchStartTime
            : weekTime
            ? weekTime?.lunchStartTime
            : null;
          const lunchEndTime = data
            ? data?.lunchEndTime
            : gapData
            ? gapData?.lunchEndTime
            : weekTime
            ? weekTime?.lunchEndTime
            : null;
          const checked = true;
          const time =
            data || gapData
              ? `${data ? data.startTime : gapData.startTime}-${
                  data ? data.endTime : gapData.endTime
                }`
              : ``;
          const hourlyRate = data && data.isJob ? data?.hourlyRate : null;
          return weekTime || gapData
            ? {
                weekDay,
                lunchStartTime,
                lunchEndTime,
                checked,
                time,
                hourlyRate,
              }
            : undefined;
        })
        .filter((d) => !_.isEmpty(d));

      form.setFieldsValue({
        timeObj: weekDaysValue,
        staffId: data && !data.isJob ? data?.staff?.id : null,
        description: data && data.isJob ? data.description : "",
        paidLunch: data && data.isJob ? data.paidLunch : false,
        externalLocumBooking:
          data && data.isJob
            ? {
                gphcNumber: data.staff && data?.staff[0]?.gphcNumber,
                email: data.staff && data?.staff[0]?.email,
                phone: data.staff && data?.staff[0]?.phone,
                id: data.staff && data?.staff[0]?.id,
              }
            : {},
        hasExternalLocum:
          data && data.isJob && data?.staff?.length ? true : false,
      });
    }, [dateRange, operatingTime, data, gapData, isTemplate, selectedDay]);

    useEffect(() => {
      if (!data) return;
      setType(data?.isJob ? "Job" : "Shift");
      if (isTemplate) {
        const role = roles?.find((i) => i.role === data?.staff?.role);
        form.setFieldsValue({
          roleId: role.id,
        });
        return;
      }
      form.setFieldsValue({
        roleId: data?.roleType?.id,
      });
    }, [data, isTemplate, roles]);

    useEffect(() => {
      if (!roles) return;
      const _locumRoles = roles
        .filter((item) => item.group === "locum")
        .map((i) => {
          return {
            label: i.label,
            value: i.id,
          };
        });
      const _staffRoles = roles
        .filter((item) => item.group === "staff")
        .map((i) => {
          return {
            label: i.label,
            value: i.id,
          };
        });
      setLocumRoles(_locumRoles);
      setStaffRoles(_staffRoles);
    }, [type, roles]);

    useEffect(() => {
      if (_.isEmpty(gapData)) return;
      form.setFieldsValue({
        roleId: type === "Shift" ? 6 : 4,
      });
    }, [gapData, type]);

    const addShift = (values) => {
      if (isTemplate) {
        const reqData = values.timeObj
          .filter((i) => i.checked === true)
          .map((d) => ({
            dayOfWeek: d.weekDay,
            startTime: d.time.split("-")[0],
            endTime: d.time.split("-")[1],
            staffId: values?.staffId,
            roleId,
            weekNumber: `${weekNumber}`,
          }));

        if (!data) {
          dispatch(
            addTemplateShiftThunk({
              id: branchId,
              data: reqData,
            })
          ).then((res) => {
            if (res.payload) {
              setHandleModal();
              form.resetFields();
              onSuccess();
            }
          });
        } else {
          dispatch(
            editTemplateShiftThunk({
              branchId: Number(branchId),
              shiftId: data.id,
              data: reqData[0],
            })
          ).then((res) => {
            if (res.payload) {
              setHandleModal();
              form.resetFields();
              onSuccess();
            }
          });
        }

        return;
      }
      const newShifts = [];
      let newJobs = [];
      const id = data ? data?.id : undefined;
      let editableStartDate = values.dateRange[0].format("YYYY-MM-DD");
      const newEndDate = values.dateRange[1].format("YYYY-MM-DD");
      while (moment(editableStartDate).isSameOrBefore(newEndDate)) {
        const dayOfWeek = moment(editableStartDate)
          .format("dddd")
          .toLowerCase();
        const selectedWeek = values.timeObj?.find(
          (week) => week.weekDay.toLowerCase() === dayOfWeek
        );
        if (selectedWeek && selectedWeek.checked)
          if (type === "Shift") {
            newShifts.push({
              startDate: editableStartDate,
              endDate: editableStartDate,
              lunchStartTime: selectedWeek && selectedWeek.lunchStartTime,
              lunchEndTime: selectedWeek && selectedWeek.lunchEndTime,
              startTime: selectedWeek.time.split("-")[0],
              endTime: selectedWeek.time.split("-")[1],
              staffId: values.staffId,
              days: [dayOfWeek],
              roleId: values.roleId,
            });
          } else {
            newJobs.push({
              startDate: editableStartDate,
              endDate: editableStartDate,
              lunchStartTime: selectedWeek && selectedWeek.lunchStartTime,
              lunchEndTime: selectedWeek && selectedWeek.lunchEndTime,
              startTime: selectedWeek.time.split("-")[0],
              endTime: selectedWeek.time.split("-")[1],
              hourlyRate: selectedWeek.hourlyRate,
              roleId,
              description: values.description,
              paidLunch: values.paidLunch,
              branchId: branchId,
              ...(hasCoverExpense && !data && values.coverExpenses),
              externalLocumBooking: values?.hasExternalLocum
                ? values?.externalLocumBooking
                : undefined,
              ...(data?.isJob && { hasExternalLocum }),
              ...(data?.isJob && { id: data.id }),
              ...(data && { split: true }),
            });
          }
        editableStartDate = moment(editableStartDate)
          .add(1, "day")
          .format("YYYY-MM-DD");
      }

      if (type === "Shift") {
        if (!data) {
          if (hasAccess(branchApiKeys.SAVE_SHIFT_BULK)) {
            dispatch(
              createShiftBulkThunk({
                branchId,
                data: newShifts,
              })
            ).then((res) => {
              if (res.payload.length) {
                form.resetFields();
                onSuccess();
                setHandleModal();
                for (var shift in newShifts) {
                  window.analytics.track("Shift Added", newShifts[shift]);
                }
              }
              if (res.payload.code === "validation") {
                const thirdArray = newShifts.filter((elem) => {
                  return res.payload.array?.some((ele) => {
                    return ele.date !== elem.startDate;
                  });
                });
                for (var data in thirdArray) {
                  window.analytics.track("Shift Added", thirdArray[data]);
                }
                setShowErrorModal(true);
                setErrorData(res.payload);
              }
            });
          }
        } else {
          if (hasAccess(branchApiKeys.UPDATE_SHIFT))
            dispatch(
              editShiftThunk({
                shiftId: id,
                branchId: branchId,
                data: newShifts[0],
              })
            ).then((res) => {
              if (res.payload.length) {
                form.resetFields();
                onSuccess();
                setHandleModal();
              }
            });
        }
      }
      if (type === "Job") {
        if (!data) {
          if (hasAccess(branchApiKeys.CREATE_BULK_JOB))
            dispatch(
              createJobBulkThunk({
                branchId,
                data: newJobs,
              })
            ).then((res) => {
              if (res.payload.length) {
                for (var job in newJobs) {
                  window.analytics.track("Job Added", newJobs[job]);
                }
                form.resetFields();
                onSuccess();
                setHandleModal();
              }
              if (res.payload.code === "validation") {
                const thirdArray = newJobs.filter((elem) => {
                  return res.payload.array?.some((ele) => {
                    return (
                      ele.date !== moment(elem.startDate).format("DD-MM-YYYY")
                    );
                  });
                });
                for (var data in thirdArray) {
                  window.analytics.track("Job Added", thirdArray[data]);
                }
                setShowErrorModal(true);
                setErrorData(res.payload);
              }
            });
        } else {
          dispatch(
            editJobThunk({
              jobId: id,
              data: newJobs[0],
            })
          ).then((res) => {
            if (res.payload) {
              form.resetFields();
              onSuccess();
              setHandleModal();
            }
          });
        }
      }
    };

    const onSelect = (value) => {
      const data = locums.find((data) => data.gphcNumber === value);
      form.setFieldsValue({
        externalLocumBooking: {
          gphcNumber: data?.gphcNumber,
          email: data?.email,
          phone: data?.userProfile?.phone,
          id: data.type ? undefined : data?.id,
        },
      });
    };

    const calculateTotal = (day) => {
      const startTime = day.time.split("-")[0];
      const endTime = day.time.split("-")[1];
      const duration = getDuration(
        dayjs(startTime, "HH:mm"),
        dayjs(endTime, "HH:mm")
      );
      const lunchDuration = getDuration(
        dayjs(day?.lunchStartTime, "HH:mm"),
        dayjs(day?.lunchEndTime, "HH:mm")
      );
      const d1 = day.lunchStartTime
        ? dayjs(day.lunchStartTime, "HH:mm").isSameOrAfter(endTime)
        : false;
      const d2 = day.lunchEndTime
        ? dayjs(day.lunchEndTime, "HH:mm").isSameOrBefore(startTime)
        : false;
      const actualAmount = (duration / 60) * Number(day.hourlyRate) || 0;
      const lunchAmount =
        ((lunchDuration ?? 0) / 60) * Number(day.hourlyRate) || 0;

      if (!day.hourlyRate) {
        return 0;
      }
      if (
        dayjs(day.lunchStartTime, "HH:mm").diff(dayjs(endTime, "HH:mm")) > 0
      ) {
        return actualAmount < 0 ? 0 : actualAmount;
      } else if (
        dayjs(day.lunchEndTime, "HH:mm").diff(dayjs(startTime, "HH:mm")) < 0
      ) {
        return actualAmount < 0 ? 0 : actualAmount;
      } else {
        return actualAmount < 0
          ? 0
          : actualAmount - (!paidLunch && lunchAmount);
      }
    };

    const disabledDate = (current) => {
      if (!dates || !dates.length) {
        return false;
      }
      const tooLate = dates[0] && current.diff(dates[0], "days") >= 1;
      const tooEarly = dates[1] && dates[1].diff(current, "days") >= 1;
      return !!tooEarly || !!tooLate;
    };
    const onOpenChange = (open) => {
      setMemoDate(form.getFieldValue("dateRange"));
      if (open) {
        setDates([null, null]);
        form.setFieldsValue({
          dateRange: null,
        });
      } else {
        form.setFieldsValue({
          dateRange: dates.length && dates[0] && dates[1] ? dates : memoDate,
        });
        setDates(null);
      }
    };

    return (
      <>
        <ConfirmModal
          backdrop="static"
          title="Hold up!"
          open={showErrorModal}
          confirmButtonText="Close"
          image={infoIcon}
          onConfirm={() => {
            setErrorData(undefined);
            setHandleModal();
            form.resetFields();
            setShowErrorModal(false);
            onSuccess();
          }}
        >
          <div className="d-flex flex-column gap-3">
            <div>
              There seem to be a few conflicts within this schedule attempt.
              However, we've created shifts for dates prior to and following the
              date(s) of conflict.
            </div>
            <div>
              You can resolve these conflicts and schedule again for the
              following days,
            </div>
          </div>
          <div className="d-flex flex-column gap-3 align-items-center">
            {errorData?.array?.map((data, idx) => (
              <div className="d-flex align-items-center gap-2" key={idx}>
                <div style={{ whiteSpace: "nowrap" }}>{data.date}</div>
                <div style={{ border: "1px solid", height: 20 }} />
                <div>{data.day[0].toUpperCase() + data.day.slice(1)}</div>
                <div style={{ border: "1px solid", height: 20 }} />
                <div>{data.reason}</div>
              </div>
            ))}
          </div>
          {errorData?.successCount > 0 && (
            <div>
              {errorData?.successCount} Shifts/Jobs Were Scheduled Successfully
            </div>
          )}
        </ConfirmModal>
        <AddModal
          title={`${data ? "Edit" : "Add"} ${type}`}
          handleModal={showModal}
          formId={formId}
          primaryButtonText={
            type === "Job" && hasAccess(locumShiftApiKeys.UPDATE_JOB)
              ? data
                ? data.status === "unfilled" || data.status === "active"
                  ? "Edit Job"
                  : ""
                : "Add Job"
              : type === "Shift" && hasAccess(branchApiKeys.UPDATE_SHIFT)
              ? data
                ? data.status === "unfilled" ||
                  data.status === "active" ||
                  isTemplate
                  ? "Edit Shift"
                  : ""
                : "Add Shift"
              : ""
          }
          secondaryButtonText={
            data &&
            (hasAccess(locumShiftApiKeys.UPDATE_JOB) ||
              hasAccess(branchApiKeys.UPDATE_SHIFT))
              ? "Delete"
              : data && isTemplate
              ? "Delete"
              : "Cancel"
          }
          setHandleModal={setHandleModal}
          isDelete={
            data &&
            (hasAccess(locumShiftApiKeys.UPDATE_JOB) ||
              hasAccess(branchApiKeys.UPDATE_SHIFT))
              ? true
              : false
          }
          secondaryButtonHandler={
            data &&
            (hasAccess(locumShiftApiKeys.UPDATE_JOB) ||
              hasAccess(branchApiKeys.UPDATE_SHIFT))
              ? () => onDelete(data)
              : setHandleModal
          }
        >
          {isTemplate ? undefined : (
            <div>
              <Radio.Group
                disabled={data ? true : false}
                className=" p2 mb-3"
                options={
                  data
                    ? options
                    : hasAccess(branchApiKeys.CREATE_BULK_JOB) &&
                      hasAccess(branchApiKeys.SAVE_SHIFT_BULK)
                    ? options
                    : hasAccess(branchApiKeys.CREATE_BULK_JOB)
                    ? options.filter((option) => option.value === "job")
                    : options.filter((option) => option.value === "shift")
                }
                onChange={(e) => {
                  setType(e.target.value);
                  form.setFieldsValue({
                    roleId: undefined,
                    staffId: undefined,
                  });
                }}
                value={type}
              />
            </div>
          )}
          <Form
            form={form}
            id={formId}
            onFinish={addShift}
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              label="Role"
              name="roleId"
              rules={[
                {
                  required: true,
                  message: "You'll Have To Select A Role To Proceed!",
                },
              ]}
            >
              <Select
                disabled={gapData}
                className="text-input-field p-1 pe-3 t1"
                bordered={false}
                onChange={(value) => {
                  form.setFieldsValue({
                    roleId: value,
                    staffId: undefined,
                    hasExternalLocum: false,
                  });
                }}
                options={type === "Shift" ? staffRoles : locumRoles}
                dropdownStyle={{ zIndex: 2000 }}
                placeholder="Select Role"
              />
            </Form.Item>
            <Form.Item
              label="Date"
              name="dateRange"
              hidden={isTemplate}
              rules={
                isTemplate
                  ? undefined
                  : [
                      {
                        required: true,
                        message:
                          "You'll Have To Select A Start/End Date To Proceed!",
                      },
                    ]
              }
            >
              <RangePicker
                disabled={gapData}
                disabledDate={data && disabledDate}
                onCalendarChange={
                  data
                    ? (val) => {
                        setDates(val);
                      }
                    : undefined
                }
                onOpenChange={data && onOpenChange}
                allowClear={false}
                inputReadOnly
                className="text-input-field px-3 t1"
                popupStyle={{ zIndex: 1000000 }}
                format="DD-MM-YYYY"
              />
            </Form.Item>
            <Form.List
              name="timeObj"
              rules={[
                {
                  validator: async (_, names) => {
                    const count = names.filter((obj) => obj.checked).length;
                    if (count < 1) {
                      return Promise.reject(
                        new Error("One Day Must Be Selected To Proceed")
                      );
                    }
                    for (const name of names) {
                      if (!name.checked) return;
                      if (name?.time.length === 0) {
                        return Promise.reject(
                          new Error("Please Enter Start & End Times ")
                        );
                      } else if (
                        name?.time.length > 0 &&
                        name?.time.length < 11
                      ) {
                        return Promise.reject(
                          new Error(
                            "Entered Time Should Follow The Format, HH:MM-HH:MM!"
                          )
                        );
                      } else if (name?.time.length === 11) {
                        const startTime = name?.time.split("-")[0];
                        const endTime = name?.time.split("-")[1];
                        const d = getDuration(
                          dayjs(startTime, "HH:mm"),
                          dayjs(endTime, "HH:mm")
                        );
                        if (d < 0) {
                          return Promise.reject(
                            new Error(
                              "Entered Start Time Can Not Precede The End Time!"
                            )
                          );
                        } else if (d === 0) {
                          return Promise.reject(
                            new Error(
                              "Entered Start/End Times Can Not Be Identical!"
                            )
                          );
                        } else if (d > 0 && d < 5) {
                          return Promise.reject(
                            new Error(
                              "Duration Should Be Longer Than 5 Minutes!"
                            )
                          );
                        }
                      }
                      if (!name.hourlyRate && type === "Job") {
                        return Promise.reject(
                          new Error(
                            "You'll Have To Enter In The 'Hourly Rate' To Proceed!"
                          )
                        );
                      }
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => {
                return (
                  <div className="d-flex flex-column align-items-center gap-4 p-3 day-box">
                    {fields.map((field, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex align-items-center gap-2"
                          style={{ width: "100%" }}
                        >
                          <Form.Item
                            name={[field.name, "checked"]}
                            className="m-0"
                            style={{ width: 80 }}
                          >
                            <Button
                              onClick={() => {
                                const data = _.cloneDeep(timeObj);
                                data[index].checked = !data[index].checked;
                                form.setFieldsValue({
                                  timeObj: data,
                                });
                              }}
                              type={
                                timeObj[field.name]?.checked
                                  ? "primary"
                                  : "default"
                              }
                              className="btn-style py-2 px-3"
                              style={{ width: 80 }}
                            >
                              {timeObj &&
                                timeObj[field.name]?.weekDay[0].toUpperCase() +
                                  timeObj[field.name].weekDay.slice(1, 3)}
                            </Button>
                          </Form.Item>
                          <Form.Item
                            style={{ width: "100%" }}
                            className="m-0"
                            name={[field.name, "time"]}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                validator: async (a, names) => {
                                  if (timeObj[field.name]?.checked) {
                                    if (
                                      timeObj[field.name]?.time.length === 0
                                    ) {
                                      return Promise.reject();
                                    } else if (
                                      timeObj[field.name]?.time.length > 0 &&
                                      timeObj[field.name]?.time.length < 11
                                    ) {
                                      return Promise.reject();
                                    } else if (
                                      timeObj[field.name]?.time.length === 11
                                    ) {
                                      const startTime =
                                        timeObj[field.name]?.time.split("-")[0];
                                      const endTime =
                                        timeObj[field.name]?.time.split("-")[1];
                                      const d = getDuration(
                                        dayjs(startTime, "HH:mm"),
                                        dayjs(endTime, "HH:mm")
                                      );
                                      if (d < 0) {
                                        return Promise.reject();
                                      } else if (d === 0) {
                                        return Promise.reject();
                                      } else if (d > 0 && d < 5) {
                                        return Promise.reject();
                                      } else {
                                        return Promise.resolve();
                                      }
                                    } else {
                                      return Promise.resolve();
                                    }
                                  }
                                },
                              },
                            ]}
                          >
                            <Input
                              className="text-input-field p-2 t1"
                              suffix={
                                <Popover
                                  content="Copy To ClipBoard"
                                  placement="left"
                                  zIndex={2000}
                                >
                                  <CopyOutlined
                                    className="cursor-pointer"
                                    onClick={() =>
                                      copyToClipBoard(timeObj[field.name].time)
                                    }
                                  />
                                </Popover>
                              }
                              value={timeObj[field.name].time}
                              placeholder="Eg: 01:00-15:00"
                              disabled={!timeObj[field.name]?.checked}
                              onChange={(e) => {
                                e.stopPropagation();
                                const input = e.target.value;
                                const digitsOnly = input.replace(/\D/g, ""); // remove non-digits
                                let formattedTime = "";
                                if (digitsOnly.length < 3) {
                                  formattedTime = digitsOnly;
                                } else if (digitsOnly.length === 3) {
                                  formattedTime = `${
                                    digitsOnly[0]
                                  }:${digitsOnly.substring(1, 3)}`;
                                } else if (
                                  digitsOnly.length > 3 &&
                                  digitsOnly.length < 5
                                ) {
                                  const hours = digitsOnly.substring(0, 2);
                                  const minutes = digitsOnly.substring(2, 4);
                                  if (hours > 23 && minutes > 59) {
                                    formattedTime = `23:59`;
                                  } else if (hours > 23) {
                                    formattedTime = `23:${minutes}`;
                                  } else if (minutes > 59) {
                                    formattedTime = `${hours}:59`;
                                  } else {
                                    formattedTime = `${hours}:${minutes}`;
                                  }
                                } else if (digitsOnly.length < 7) {
                                  formattedTime = `${digitsOnly.substring(
                                    0,
                                    2
                                  )}:${digitsOnly.substring(
                                    2,
                                    4
                                  )}-${digitsOnly.substring(4, 6)}`;
                                } else if (digitsOnly.length === 7) {
                                  formattedTime = `${digitsOnly.substring(
                                    0,
                                    2
                                  )}:${digitsOnly.substring(
                                    2,
                                    4
                                  )}-${digitsOnly.substring(
                                    4,
                                    5
                                  )}:${digitsOnly.substring(5, 7)}`;
                                } else if (
                                  digitsOnly.length > 7 &&
                                  digitsOnly.length < 8
                                ) {
                                  const hours = digitsOnly.substring(4, 6);
                                  const minutes = digitsOnly.substring(6, 8);
                                  if (hours > 23 && minutes > 59) {
                                    formattedTime = `${digitsOnly.substring(
                                      0,
                                      2
                                    )}:${digitsOnly.substring(2, 4)}-23:59`;
                                  } else if (hours > 23) {
                                    formattedTime = `${digitsOnly.substring(
                                      0,
                                      2
                                    )}:${digitsOnly.substring(
                                      2,
                                      4
                                    )}-23:${minutes}`;
                                  } else if (minutes > 59) {
                                    formattedTime = `${digitsOnly.substring(
                                      0,
                                      2
                                    )}:${digitsOnly.substring(
                                      2,
                                      4
                                    )}-${hours}:59`;
                                  } else {
                                    formattedTime = `${digitsOnly.substring(
                                      0,
                                      2
                                    )}:${digitsOnly.substring(
                                      2,
                                      4
                                    )}-${hours}:${minutes}`;
                                  }
                                } else {
                                  const hours = digitsOnly.substring(4, 6);
                                  const minutes = digitsOnly.substring(6, 8);
                                  if (hours > 23 && minutes > 59) {
                                    formattedTime = `${digitsOnly.substring(
                                      0,
                                      2
                                    )}:${digitsOnly.substring(2, 4)}-23:59`;
                                  } else if (hours > 23) {
                                    formattedTime = `${digitsOnly.substring(
                                      0,
                                      2
                                    )}:${digitsOnly.substring(
                                      2,
                                      4
                                    )}-23:${minutes}`;
                                  } else if (minutes > 59) {
                                    formattedTime = `${digitsOnly.substring(
                                      0,
                                      2
                                    )}:${digitsOnly.substring(
                                      2,
                                      4
                                    )}-${hours}:59`;
                                  } else {
                                    formattedTime = `${digitsOnly.substring(
                                      0,
                                      2
                                    )}:${digitsOnly.substring(
                                      2,
                                      4
                                    )}-${hours}:${minutes}`;
                                  }
                                }
                                const data = _.cloneDeep(timeObj);
                                data[index].time = formattedTime;
                                form.setFieldsValue({
                                  timeObj: data,
                                });
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, "hourlyRate"]}
                            className="m-0"
                            hidden={type === "Shift"}
                            normalize={(value) => {
                              if (value.length > 3) return value.slice(0, 3);
                              return value;
                            }}
                            rules={[
                              {
                                validator: async (a, names) => {
                                  if (
                                    !names &&
                                    timeObj[field.name]?.checked &&
                                    type === "Job"
                                  ) {
                                    return Promise.reject();
                                  } else {
                                    return Promise.resolve();
                                  }
                                },
                              },
                            ]}
                          >
                            <Input
                              disabled={!timeObj[field.name]?.checked}
                              className="text-input-field  px-3 pe-3 t1"
                              placeholder="Amount"
                            />
                          </Form.Item>
                          {type !== "Shift" && (
                            <div
                              className="t1"
                              style={{ whiteSpace: "nowrap", color: "#7B7B7B" }}
                            >
                              Â£{calculateTotal(timeObj[field.name]).toFixed(2)}
                            </div>
                          )}
                        </div>
                      );
                    })}
                    <Form.ErrorList className="text-danger" errors={errors} />
                  </div>
                );
              }}
            </Form.List>
            <div className="d-flex gap-3 align-items-center">
              <Form.Item
                name="paidLunch"
                className="mt-3"
                valuePropName="checked"
                hidden={type === "Shift"}
              >
                <Checkbox>Paid Lunch</Checkbox>
              </Form.Item>
              <Form.Item
                name="hasCoverExpense"
                className="mt-3"
                valuePropName="checked"
                hidden={type === "Shift" || data}
              >
                <Checkbox>Cover Expense</Checkbox>
              </Form.Item>
            </div>
            {type === "Job" && hasCoverExpense && (
              <div
                className="border rounded p-3 d-flex flex-column day-box"
                style={{
                  width: "100%",
                }}
              >
                <div className="b1">Rules</div>
                <Form.Item
                  name={["coverExpenses", "mileageRuleId"]}
                  label="Mileage Rule"
                  className="m-0"
                  valuePropName="checked"
                  hidden={type === "Shift"}
                >
                  <Select
                    className="text-input-field p-1 pe-3 t1 bg-white"
                    bordered={false}
                    dropdownStyle={{ zIndex: 2000 }}
                    placeholder="Mileage Rule"
                  >
                    {mileageRules.map((data, idx) => (
                      <Select.Option key={idx} value={data.id}>
                        {data.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={["coverExpenses", "accommodationAndFoodRuleId"]}
                  label="Accommodation & Food Rule"
                  className="m-0"
                  valuePropName="checked"
                  hidden={type === "Shift"}
                >
                  <Select
                    className="text-input-field p-1 pe-3 t1 bg-white"
                    bordered={false}
                    dropdownStyle={{ zIndex: 2000 }}
                    placeholder="Accommodation & Food Rule"
                  >
                    {accommodationAndFoodRules.map((data, idx) => (
                      <Select.Option key={idx} value={data.id}>
                        {data.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={["coverExpenses", "parkingRuleId"]}
                  label="Parking Rule"
                  className="m-0"
                  valuePropName="checked"
                  hidden={type === "Shift"}
                >
                  <Select
                    className="text-input-field p-1 pe-3 t1 bg-white"
                    bordered={false}
                    dropdownStyle={{ zIndex: 2000 }}
                    placeholder="Parking Rule"
                  >
                    {parkingRules.map((data, idx) => (
                      <Select.Option key={idx} value={data.id}>
                        {data.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={["coverExpenses", "transportRuleId"]}
                  label="Transport Rule"
                  className="m-0"
                  valuePropName="checked"
                  hidden={type === "Shift"}
                >
                  <Select
                    className="text-input-field p-1 pe-3 t1 bg-white"
                    bordered={false}
                    dropdownStyle={{ zIndex: 2000 }}
                    placeholder="Transport Rule"
                  >
                    {transportRules.map((data, idx) => (
                      <Select.Option key={idx} value={data.id}>
                        {data.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            )}
            <Form.Item
              label="Description"
              name="description"
              className="mt-3"
              hidden={type === "Shift"}
              rules={
                type === "Shift"
                  ? undefined
                  : [
                      {
                        required: true,
                        message:
                          "You'll Have To Enter A Description To Proceed!",
                      },
                    ]
              }
            >
              <Input.TextArea
                className="px-3 py-2 t1"
                rows={5}
                maxLength={256}
                name="description"
                placeholder="Job Description"
              />
            </Form.Item>
            <Form.Item
              label="Assign Staff"
              name="staffId"
              className="mt-3"
              hidden={type !== "Shift"}
              rules={
                type === "Shift" && (gapData || isTemplate)
                  ? [
                      {
                        required: true,
                        message: "You'll Have To Assign A Staff To Proceed!",
                      },
                    ]
                  : undefined
              }
            >
              <Select
                allowClear
                showSearch
                className="text-input-field p-1 pe-3 t1"
                bordered={false}
                optionFilterProp="label"
                dropdownStyle={{ zIndex: 2000 }}
                placeholder="Find Staff"
              >
                {staffs
                  ?.filter((i) => i.roleId === roleId)
                  .map((data, idx) => (
                    <Select.Option key={idx} value={data.id}>
                      {data.name} {data.surname}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="hasExternalLocum"
              className="mt-3"
              valuePropName="checked"
              hidden={type === "Shift"}
              rules={
                type === "Job" && gapData
                  ? [
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(
                                  "You'll Have To Assign A Locum To Proceed!"
                                )
                              ),
                      },
                    ]
                  : undefined
              }
            >
              <Checkbox>Assign Locum</Checkbox>
            </Form.Item>

            {(type === "Job" || data?.status === "active") &&
              hasExternalLocum && (
                <Form.Item
                  className="d-flex flex-column gap-2"
                  name="externalLocumBooking"
                  style={{ gap: "15px" }}
                >
                  <Form.Item
                    label="GPhC No. / Name"
                    name={["externalLocumBooking", "gphcNumber"]}
                    className="m-0"
                    hidden={type === "Shift"}
                    rules={
                      type !== "Shift" && hasExternalLocum
                        ? [
                            {
                              required: true,
                              message:
                                "You'll Have To Enter The GPhC Number To Proceed!",
                            },
                          ]
                        : undefined
                    }
                  >
                    <AutoComplete
                      dropdownStyle={{ zIndex: 2000 }}
                      className="text-input-field rounded pe-3 p-1 t1"
                      options={locumOptions}
                      maxLength={7}
                      onSelect={onSelect}
                      bordered={false}
                      onSearch={(e) => {
                        if (
                          e.length < 7 ||
                          e !== externalLocumBooking?.gphcNumber
                        ) {
                          form.setFieldsValue({
                            externalLocumBooking: {
                              gphcNumber: e,
                              email: "",
                              phone: "",
                              id: undefined,
                            },
                          });
                          return;
                        }
                        form.setFieldsValue({
                          externalLocumBooking: {
                            gphcNumber: e,
                            ...externalLocumBooking,
                          },
                        });
                      }}
                      placeholder="GPhC No."
                    />
                  </Form.Item>
                  <Form.Item
                    label="Id"
                    name={["externalLocumBooking", "id"]}
                    className="m-0"
                    hidden={true}
                  >
                    <Input
                      className="text-input-field px-3 pe-3 t1"
                      placeholder="Email Address"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Email Address"
                    name={["externalLocumBooking", "email"]}
                    className="m-0"
                    hidden={type === "Shift"}
                    rules={
                      type !== "Shift" && hasExternalLocum
                        ? [
                            {
                              type: "email",
                              required: true,
                              message:
                                "You'll Have To Enter The Email Address To Proceed!",
                            },
                          ]
                        : undefined
                    }
                  >
                    <Input
                      className="text-input-field px-3 pe-3 t1"
                      placeholder="Email Address"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Phone No."
                    className="m-0"
                    name={["externalLocumBooking", "phone"]}
                    hidden={type === "Shift"}
                    rules={
                      type !== "Shift" && hasExternalLocum
                        ? [
                            {
                              required: true,
                              message:
                                "You'll Have To Enter The Phone Number To Proceed!",
                            },
                          ]
                        : undefined
                    }
                  >
                    <Input
                      className="text-input-field px-3 t1"
                      placeholder="Phone No."
                    />
                  </Form.Item>
                </Form.Item>
              )}
          </Form>
        </AddModal>
      </>
    );
  }
);
