import { Badge, Tooltip } from "antd";
import React, { useMemo } from "react";
import BlueClock from "../../assets/icons/blueclock.svg";
import Danger from "../../assets/icons/danger.svg";
import ArrowDown from "../../assets/icons/yellow-arrow-down.svg";
import ArrowUp from "../../assets/icons/yellow-arrow-up.svg";
import {
  leaveTypeIcons,
  leaveTypes,
  staffAbsencesType,
} from "../../utils/constant";
import {
  getTotalWorkingHours,
  minuteTohour,
} from "../../utils/dates";
import { calculateSingleDayShiftHours } from "../../utils/timeshhet";

const ScheduledAndWorkedInfo = ({
  scheduled,
  worked,
}) => (
  <>
    <p className="mb-0">Scheduled: {scheduled}</p>
    <p>Worked: {worked}</p>
  </>
);

const ShiftTimeBox = ({
  item,
  type,
  absence,
}) => {
  const getShiftStatus = useMemo(() => {
    return calculateSingleDayShiftHours({
      item,
      type,
    });
  }, [item, type]);

  const className = useMemo(() => {
    const _status = getShiftStatus.status;

    if (_status === "pending")
      return "pending_badge w-100";
    if (_status === "leave" || type === "leave")
      return "leave_badge w-100";
    if (_status === "incomplete")
      return "incomplete_badge w-100";
    if (_status === "unfilled")
      return "nocheckin_badge w-100";
    if (_status === "overtime")
      return "overtime_badge w-100";
    return "w-100";
  }, [JSON.stringify(getShiftStatus), type]);

  const badgeContent = useMemo(() => {
    const _status = getShiftStatus.status;
    const actualCheckIn =
      item?.attendance?.actualCheckIn ||
      item?.attendance?.manualCheckIn;
    const actualCheckOut =
      item?.attendance?.actualCheckOut ||
      item?.attendance?.manualCheckOut;

    switch (_status) {
      case "leave":
        return (
          <>
            <i>
              {
                leaveTypeIcons[
                  item?.attendance?.leaveType ||
                    absence?.type ||
                    leaveTypes.annual_leave
                ]
              }
            </i>

            <span>
              {staffAbsencesType[
                item?.attendance?.leaveType ||
                  absence?.type
              ] || "Leave"}
            </span>
          </>
        );
      case "pending":
        return (
          <>
            <i>
              <img src={BlueClock} alt="clock" />
            </i>
            <p>
              {getTotalWorkingHours(
                actualCheckIn,
                actualCheckOut
              )}
            </p>
          </>
        );

      case "incomplete":
        return (
          <>
            <i>
              <img
                src={ArrowDown}
                alt="arrow-down"
              />
            </i>

            <p>
              {getTotalWorkingHours(
                actualCheckIn,
                actualCheckOut
              )}
            </p>
          </>
        );

      case "overtime":
        return (
          <>
            <i>
              <img src={ArrowUp} alt="clock" />
            </i>
            <p>
              {getTotalWorkingHours(
                actualCheckIn,
                actualCheckOut
              )}
            </p>
          </>
        );
      case "unfilled":
        return (
          <>
            <i>
              <img
                src={Danger}
                alt="arrow-down"
              />
            </i>
            <p>
              {getTotalWorkingHours(
                item?.startTime,
                item?.endTime
              )}
            </p>
          </>
        );
      case "complete":
        return (
          <>
            <p>
              {getTotalWorkingHours(
                item?.startTime,
                item?.endTime
              )}
            </p>
          </>
        );
      default:
        return (
          <span className="empty_box"></span>
        );
    }
  }, [JSON.stringify(getShiftStatus)]);

  const tooltipContent = useMemo(() => {
    const _status = getShiftStatus.status;

    switch (_status) {
      case "pending":
        return (
          <>
            <b>Pending Approval</b>
            <ScheduledAndWorkedInfo
              scheduled={getShiftStatus.scheduled}
              worked={getShiftStatus.worked}
            />
          </>
        );
      case "incomplete":
        return (
          <>
            <b>
              Incomplete Hours:{" "}
              {minuteTohour(
                getShiftStatus.incompleteHour
              )}
            </b>
            <ScheduledAndWorkedInfo
              scheduled={getShiftStatus.scheduled}
              worked={getShiftStatus.worked}
            />
          </>
        );
      case "unfilled":
        return (
          <>
            <ScheduledAndWorkedInfo
              scheduled={getShiftStatus.scheduled}
              worked={getShiftStatus.worked}
            />
          </>
        );
      case "overtime":
        return (
          <>
            <b>
              Overtime:{" "}
              {minuteTohour(
                getShiftStatus.overtimeHour
              )}
            </b>
            <ScheduledAndWorkedInfo
              scheduled={getShiftStatus.scheduled}
              worked={getShiftStatus.worked}
            />
          </>
        );
      default:
        return null;
    }
  }, [JSON.stringify(getShiftStatus)]);

  return (
    <div className="shifttime_wrapper ">
      {badgeContent && (
        <Tooltip title={tooltipContent}>
          <Badge className={className}>
            {badgeContent}
          </Badge>
        </Tooltip>
      )}
    </div>
  );
};

export default ShiftTimeBox;
