import { createSlice } from "@reduxjs/toolkit";
import { createRegionThunk, deleteRegionsThunk, editRegionThunk, getRegionsBranchesListThunk, getRegionsListDropDownThunk, getRegionsListThunk } from "../regionsThunk";

const initialState = {
    regions: {},
    regionsListDropDown: [],
    regionBranches: []
}

const regionsSlicer = createSlice({
    name: "regionsSlicer",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getRegionsListThunk.fulfilled, (state, action) => {
            state.isloading = false
            state.regions = action.payload
        })
        builder.addCase(deleteRegionsThunk.fulfilled, (state, action) => {
            state.regions.rows = state.regions.rows.filter((item) => {
                return !action.payload.find((list) => {
                    return item.id === list
                })
            })
        })
        builder.addCase(editRegionThunk.fulfilled, (state, action) => {
            const index = state.regions.rows.findIndex((list) => list.id === action.payload.id)
            state.regions.rows[index] = action.payload
        })
        builder.addCase(getRegionsBranchesListThunk.fulfilled, (state, action) => {
            state.regionBranches = action.payload
        })
        builder.addCase(createRegionThunk.fulfilled, (state, action) => {
            state.regions.rows.unshift(action.payload)
        })
        builder.addCase(getRegionsListDropDownThunk.fulfilled, (state, action) => {
            state.regionsListDropDown = action.payload
        })
    }
})

export const regionsReducer = regionsSlicer