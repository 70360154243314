import { Button, Form, Input } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Link,
  useNavigate,
} from "react-router-dom";
import { signUpThunk } from "../../../redux/auth/authThunk";
import { RouterConfig } from "../../../routerConfig";
import {
  handleWheel,
  notify,
} from "../../../utils/helper";
import { phoneValidation } from "../../../utils/validation.message";
import "../style.scss";

export const PharmacySignUpFormNew = ({
  convertedUser,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [btndisabled, setbtndisabled] =
    useState(true);

  const onValuesChange = (
    changedValues,
    allValues
  ) => {
    if (
      allValues.pharmacyName !== "" &&
      allValues.email !== "" &&
      allValues.password !== "" &&
      allValues.name !== "" &&
      allValues.surname !== "" &&
      allValues.phone !== undefined &&
      allValues.phone !== ""
    ) {
      setbtndisabled(false);
    } else {
      setbtndisabled(true);
    }
  };

  const onFinish = (value) => {
    const reqData = {
      ...value,
      pharmacyName: value?.pharmacyName?.trim(),
      roleId: 2,
    };
    dispatch(signUpThunk(reqData)).then((res) => {
      if (res.payload.status === "success") {
        notify(
          "success",
          "Sign In link sent to your Email!"
        );
        form.resetFields();
        navigate(RouterConfig.login);
        window.analytics.identify(
          res.payload.data.id,
          {
            id: res.payload.data.id,
            email: res.payload.data.email,
            userType: res.payload.data.group,
            name: res.payload.data.name,
            surname: res.payload.data.surname,
            pharmacyName:
              res.payload.data.pharmacyName,
            phone: res.payload.data.phone,
            device: "web",
          }
        );

        window.analytics.track(
          "Sign Up Confirmed- Pharmacy",
          {
            id: res.payload.data.id,
            email: res.payload.data.email,
            userType: res.payload.data.group,
            name: res.payload.data.name,
            surname: res.payload.data.surname,
            pharmacyName:
              res.payload.data.pharmacyName,
            phone: res.payload.data.phone,
            device: "web",
          }
        );
        if (!convertedUser) {
          window.fbq(
            "trackCustom",
            "SignUp Pharmacy",
            {
              id: res.payload.data.id,
              email: res.payload.data.email,
              userType: res.payload.data.group,
              name: res.payload.data.name,
              surname: res.payload.data.surname,
              pharmacyName:
                res.payload.data.pharmacyName,
              phone: res.payload.data.phone,
              device: "web",
            }
          );
          return;
        }
        window.fbq(
          "trackCustom",
          "SignUp Pharmacy - Convert",
          {
            id: res.payload.data.id,
            email: res.payload.data.email,
            userType: res.payload.data.group,
            name: res.payload.data.name,
            surname: res.payload.data.surname,
            pharmacyName:
              res.payload.data.pharmacyName,
            phone: res.payload.data.phone,
            device: "web",
          }
        );
      }
    });
  };

  return (
    <div className="locum_signup_new">
      <div className="header text-center">
        <div className="heading2">
          Getting Started
        </div>
        <div className="t1 sub_heading">
          Create an account to continue.
        </div>
      </div>
      <Form
        onFinish={onFinish}
        form={form}
        onValuesChange={onValuesChange}
        layout="vertical"
        autoComplete="off"
        className="locum_signup_form_new"
      >
        <Form.Item
          label="Pharmacy Name"
          name="pharmacyName"
          rules={[
            {
              message:
                "Please Enter your Pharmacy Name!",
              required: true,
            },
          ]}
          // normalize={(value, prevVal, prevVals) => value.trim()}
        >
          <Input
            onBlur={(e) =>
              form.setFieldsValue({
                pharmacyName: e.target.value,
              })
            }
            placeholder="Enter name"
            className="text-input-field p-2 pe-3 t1"
            maxLength={50}
          />
        </Form.Item>
        <div
          className="d-flex align-items-center gap-3"
          style={{ width: "100%" }}
        >
          <Form.Item
            label="First Name"
            style={{ width: "100%" }}
            name="name"
            rules={[
              {
                message:
                  "Please Enter your First Name !",
                required: true,
              },
            ]}
            normalize={(
              value,
              prevVal,
              prevVals
            ) => value.trim()}
          >
            <Input
              onBlur={(e) =>
                form.setFieldsValue({
                  name: e.target.value.trim(),
                })
              }
              placeholder="Enter First Name"
              className="text-input-field p-2 pe-3 t1"
              maxLength={50}
            />
          </Form.Item>
          <Form.Item
            style={{ width: "100%" }}
            label="Last Name"
            name="surname"
            normalize={(
              value,
              prevVal,
              prevVals
            ) => value.trim()}
            rules={[
              {
                message:
                  "Please Enter your Last Name !",
              },
            ]}
          >
            <Input
              onBlur={(e) =>
                form.setFieldsValue({
                  surname: e.target.value.trim(),
                })
              }
              placeholder="Enter Last Name"
              className="text-input-field p-2 pe-3 t1"
              maxLength={50}
            />
          </Form.Item>
        </div>
        <Form.Item
          label="Email Address"
          normalize={(value, prevVal, prevVals) =>
            value.trim()
          }
          name="email"
          rules={[
            {
              type: "email",
              message: "Please Enter your Email!",
              required: true,
            },
          ]}
        >
          <Input
            onBlur={(e) =>
              form.setFieldsValue({
                email: e.target.value.trim(),
              })
            }
            placeholder="Enter address"
            className="text-input-field p-2 pe-3 t1"
            maxLength={50}
          />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phone"
          normalize={(value, prevVal, prevVals) =>
            value.trim()
          }
          rules={phoneValidation}
        >
          <Input
            onBlur={(e) =>
              form.setFieldsValue({
                phone: e.target.value.trim(),
              })
            }
            type="number"
            placeholder="Enter phone number"
            className="text-input-field p-2 pe-3 t1"
            onWheel={handleWheel}
          />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              message:
                "Please Enter your password!",
              required: true,
            },
            {
              pattern:
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
              message: `Password doesn't match the Criteria!`,
            },
          ]}
        >
          <Input.Password
            onBlur={(e) =>
              form.setFieldsValue({
                password: e.target.value.trim(),
              })
            }
            // prefix={
            //     <Popover
            //         content={PasswordCriteria} placement="left" title="Password Criteria">
            //         <img src={infoIcon} alt="" />
            //     </Popover>
            // }

            placeholder="Enter password"
            className="text-input-field p-2 pe-3 t1"
            maxLength={50}
          />
        </Form.Item>
        <div
          className="p2 d-flex justify-content-between align-items-center form_bottom"
          style={{
            color: "#000",
            paddingTop: "10px",
          }}
        >
          <span className="">
            Already have an account?
            <Link
              to={RouterConfig.login}
              className="text-decoration-none"
              style={{ color: "#09988D" }}
            >
              Login
            </Link>
          </span>
          <Form.Item className="mb-0">
            <Button
              disabled={btndisabled}
              id="signUpPharmacy_confirmed"
              className="btn-style custom_btn auth_button"
              type="primary"
              htmlType="submit"
              block
            >
              Sign Up
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
