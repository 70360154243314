import { Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import NoBranchImg from "../../../assets/img/EmptyLocumBranch.png";
import { generateRandomNumber } from "../../../utils/helper";

const EmptylocumBank = () => {
  const navigate = useNavigate();
  return (
    <div className=" d-flex align-items-center justify-content-center flex-column px-2 py-4">
      <img src={NoBranchImg} alt="nobranch" className="mb-4" />
      <Typography.Title level={3} className="mb-2">
        No Locum Found
      </Typography.Title>
      <p
        className="text-center mb-4"
        style={{
          maxWidth: "448px",
          color: "#7B7B7B",
        }}
      >
        We cant find any locum, head over to add locum section to add a locum!
      </p>

      <button
        onClick={() =>
          navigate(`/locum-bank?addlocum=${generateRandomNumber()}`, {
            replace: true,
          })
        }
        className="btn custom_btn no-branch-btn"
      >
        Add Locum
      </button>
    </div>
  );
};

export default EmptylocumBank;
