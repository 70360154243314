import { createAsyncThunk } from "@reduxjs/toolkit";
import { AddLocumBank, getLocumBankList, getLocumByGphC, removeLocumBank } from "../locumBankAPI";


export const getLocumBankListThunk = createAsyncThunk("getLocumBankList", async (payload) => {
    const request = await getLocumBankList(payload)
    return request.data.data
})

export const getLocumByGphCThunk = createAsyncThunk("getLocumByGphC", async (payload) => {
    const request = await getLocumByGphC(payload)
    return request.data.data
})

export const AddLocumBankThunk = createAsyncThunk("AddLocumBank", async (payload) => {
    const request = await AddLocumBank(payload)
    return request.data.data
})

export const removeLocumBankThunk = createAsyncThunk("removeLocumBank", async (payload) => {
    const request = await removeLocumBank(payload)
    return request.data.data
})