import React from 'react';
import './style.scss';

export const GlobalLoader = ({ color, size = 'huge', loaderState }) => {
  return (
    <div className={`global-loader overlay ${loaderState ? 'isActive' : ''}`}>
      <div className="load">
        <hr />
        <hr />
        <hr />
        <hr />
      </div>
    </div>
  );
};
