import { Col, Form, Row, Tag, Typography } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editContractThunk } from "../../redux/staffs/staffsThunk";
import CustomIconButton from "../UI/IconButton/IconButton";
import EditIcon2 from "../UI/icons/EditIcon2";
import MedalIcon from "../UI/icons/MedalIcon";
import CommonModal from "../UI/modals/CommonModal/CommonModal";
import { FlexRow } from "../comon/flexRows";
import { contractTypeOptions } from "../staffs/contracts/contractForm";
import HourlyRateEditModal from "./HourlyRateEditModal";
const HourlyRateInfo = ({ data, canEdit }) => {
  const [showModal, setshowModal] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const formId = useMemo(() => "contractForm", []);
  const { roles } = useSelector((state) => {
    return state.schedule;
  });

  const handleModal = () => {
    setshowModal(!showModal);
  };

  const renderRoleInfo = useMemo(() => {
    if (roles?.length) {
      const role = roles.find((i) => i?.id === data?.jobRoleId);
      return role?.label;
    }
  }, [data, roles]);

  const employeeType = useMemo(() => {
    if (!!data) {
      const type = contractTypeOptions?.find((i) => i?.value === data?.type);
      return type?.label;
    }
    return "";
  }, [data]);

  const handleSave = () => {
    form.validateFields().then((values) => {
      dispatch(
        editContractThunk({
          staffId: data?.staffId,
          contractId: data?.id,
          payload: {
            ...data,
            ...values,
            endDate: dayjs(values?.endDate).format("YYYY-MM-DD"),
          },
        })
      ).then((res) => {
        if (res.payload) {
          handleModal();
          form.resetFields();
        }
      });
    });
  };

  useEffect(() => {
    // if (mode !== "Edit") return;
    if (!!data) {
      form.setFieldsValue({
        jobRoleId: data?.jobRoleId,
        absenceDays: data?.absenceDays,
        overTimePayHourly: data?.overTimePayHourly,
        startDate: dayjs(data?.startDate),
        endDate: dayjs(data?.endDate),
        title: data?.title,
        hourlyRate: data?.hourlyRate,
        workingOurs: data?.workingOurs,
        workingPeriodType: data?.workingPeriodType,
        grossSalaryPay: data?.grossSalaryPay,
        grossSalaryPeriodType: data?.grossSalaryPeriodType,
        includeBankHolidays: data?.includeBankHolidays,
        type: data?.type,
        contractAdditionCompensations: data?.contractAdditionCompensations,
      });
    }
  }, [JSON.stringify(data)]);

  return (
    <>
      {" "}
      <div className="personal-info-wrapper">
        <div className="header">
          <Typography.Title level={4}>Position & Hourly Rate</Typography.Title>
          {canEdit && (
            <CustomIconButton icon={<EditIcon2 />} onClick={handleModal} />
          )}
        </div>

        <div className="info-list-wrap hourly-rate">
          <Row>
            <Col span={10}>
              <FlexRow
                primaryTitle="Position"
                primaryDescription={
                  <Tag icon={<MedalIcon />} className="custom-tags green">
                    {renderRoleInfo}
                  </Tag>
                }
              />
              <FlexRow
                primaryTitle="Hire Date"
                primaryDescription="12 Jun, 2023"
              />
              <FlexRow
                primaryTitle="Absence Allowance"
                primaryDescription={
                  data?.absenceDays ? `${data?.absenceDays} days` : "-"
                }
              />
              <FlexRow
                primaryTitle="Employment Type"
                primaryDescription={employeeType}
              />
              <FlexRow primaryTitle="Pay Type" primaryDescription="14 Days" />
            </Col>
            <Col span={10}>
              <FlexRow
                primaryTitle="Hourly Rate"
                primaryDescription={
                  <Tag className="custom-tags">£ {data?.hourlyRate || "-"}</Tag>
                }
              />
              <FlexRow
                primaryTitle="Overtime Rate"
                primaryDescription={`£${data?.overTimePayHourly} /hr`}
              />
              <FlexRow
                primaryTitle="Gross"
                primaryDescription={`£${data?.grossSalaryPay || "-"} / ${
                  data?.grossSalaryPeriodType
                }`}
              />
              <FlexRow
                primaryTitle="Effective Date"
                primaryDescription={dayjs(data?.startDate, "YYYY-MM-DD").format(
                  "DD MMMM,YYYY"
                )}
              />
              <FlexRow
                primaryTitle="Expiration Date"
                primaryDescription={dayjs(data?.endDate, "YYYY-MM-DD").format(
                  "DD MMMM,YYYY"
                )}
              />
            </Col>
          </Row>
        </div>
      </div>
      <CommonModal
        handleModal={showModal}
        setHandleModal={handleModal}
        title="Edit Position & Hourly Rate"
        secondaryButtonText="Cancel"
        primaryButtonText="Save"
        primaryButtonHandler={handleSave}
      >
        <HourlyRateEditModal form={form} formId={formId} data={data} />
      </CommonModal>
    </>
  );
};

export default HourlyRateInfo;
