import React from "react";

const Peopleicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.49935 1.66797C5.31602 1.66797 3.54102 3.44297 3.54102 5.6263C3.54102 7.76797 5.21602 9.5013 7.39935 9.5763C7.46602 9.56797 7.53268 9.56797 7.58268 9.5763C7.59935 9.5763 7.60768 9.5763 7.62435 9.5763C7.63268 9.5763 7.63268 9.5763 7.64102 9.5763C9.77435 9.5013 11.4493 7.76797 11.4577 5.6263C11.4577 3.44297 9.68268 1.66797 7.49935 1.66797Z"
        fill="#9D9D9D"
      />
      <path
        d="M11.7328 11.7914C9.40781 10.2414 5.61615 10.2414 3.27448 11.7914C2.21615 12.4997 1.63281 13.4581 1.63281 14.4831C1.63281 15.5081 2.21615 16.4581 3.26615 17.1581C4.43281 17.9414 5.96615 18.3331 7.49948 18.3331C9.03281 18.3331 10.5661 17.9414 11.7328 17.1581C12.7828 16.4497 13.3661 15.4997 13.3661 14.4664C13.3578 13.4414 12.7828 12.4914 11.7328 11.7914Z"
        fill="#9D9D9D"
      />
      <path
        d="M16.6578 6.11512C16.7911 7.73179 15.6411 9.14846 14.0495 9.34012C14.0411 9.34012 14.0411 9.34012 14.0328 9.34012H14.0078C13.9578 9.34012 13.9078 9.34012 13.8661 9.35679C13.0578 9.39846 12.3161 9.14012 11.7578 8.66512C12.6161 7.89846 13.1078 6.74846 13.0078 5.49846C12.9495 4.82346 12.7161 4.20679 12.3661 3.68179C12.6828 3.52346 13.0495 3.42346 13.4245 3.39012C15.0578 3.24846 16.5161 4.46512 16.6578 6.11512Z"
        fill="#9D9D9D"
      />
      <path
        d="M18.3249 13.8266C18.2582 14.635 17.7415 15.335 16.8749 15.81C16.0415 16.2683 14.9915 16.485 13.9499 16.46C14.5499 15.9183 14.8999 15.2433 14.9665 14.5266C15.0499 13.4933 14.5582 12.5016 13.5749 11.71C13.0165 11.2683 12.3665 10.9183 11.6582 10.66C13.4999 10.1266 15.8165 10.485 17.2415 11.635C18.0082 12.2516 18.3999 13.0266 18.3249 13.8266Z"
        fill="#9D9D9D"
      />
    </svg>
  );
};

export default Peopleicon;
