import dayjs from "dayjs";
import { isNull } from "lodash";
import moment from "moment";
import { staffAbsencesType } from "./constant";
import {
  getDuration,
  getTotalMinutes,
  getTotalWorkingHours,
} from "./dates";

export const showLabel = (role) => {
  if (!!role) {
    let str = role
      ?.toLowerCase()
      .replace("staff", "");
    str = role
      ?.toLowerCase()
      .replace("locum", "");
    return str;
  }

  return "";
};

export const convertToUniqueLabel = (label) => {
  let category = "pharmacist";

  if (
    label.toLowerCase().includes("technician")
  ) {
    category = "technician";
  }

  if (label.toLowerCase().includes("dispenser")) {
    category = "dispenser";
  }
  if (label.toLowerCase().includes("pre-reg")) {
    category = "pre-reg";
  }

  if (label.toLowerCase().includes("counter")) {
    category = "counter";
  }

  return category;
};

export const getScheduleHourAndWorkedHour = (
  item
) => {
  let scheduled = 0;
  let worked = 0;

  item?.shifts?.forEach((_shifts) => {
    scheduled =
      scheduled +
      getTotalMinutes(
        _shifts?.startTime,
        _shifts?.endTime
      );

    if (
      !!_shifts?.attendance &&
      _shifts?.attendance?.status !== "pending" &&
      _shifts?.attendance?.status !==
        "unfilled" &&
      _shifts?.attendance?.status !== "absent"
    ) {
      const actualCheckIn =
        _shifts?.attendance?.actualCheckIn ||
        _shifts?.attendance?.manualCheckIn;
      const actualCheckOut =
        _shifts?.attendance?.actualCheckOut ||
        _shifts?.attendance?.manualCheckOut;

      worked =
        worked +
        getTotalMinutes(
          actualCheckIn,
          actualCheckOut
        );
    }
  });

  return { scheduled, worked };
};

export const getWorkedHourPerDay = (
  item,
  date
) => {
  let scheduled = 0;
  let worked = 0;

  item?.shifts?.forEach((_shifts) => {
    scheduled =
      scheduled +
      getTotalMinutes(
        _shifts?.startTime,
        _shifts?.endTime
      );

    if (_shifts?.attendance) {
      const actualCheckIn =
        _shifts?.attendance?.actualCheckIn ||
        _shifts?.attendance?.manualCheckIn;
      const actualCheckOut =
        _shifts?.attendance?.actualCheckOut ||
        _shifts?.attendance?.manualCheckOut;

      worked =
        worked +
        getTotalMinutes(
          actualCheckIn,
          actualCheckOut
        );
    }
  });

  return { scheduled, worked };
};
// calculate total scheduled hour , break time , total time = break time+ schedule time
export const calculateTotalHourForEmployee = (
  data
) => {
  let scheduleHour = 0;
  let breakTime = 0;

  if (data?.length) {
    data.forEach((_shifts) => {
      scheduleHour =
        scheduleHour +
        getTotalMinutes(
          _shifts?.startTime,
          _shifts?.endTime
        );
      breakTime =
        breakTime +
        getTotalMinutes(
          _shifts?.lunchStartTime,
          _shifts?.lunchEndTime
        );
    });
  }

  return {
    scheduleHour,
    breakTime,
    total: breakTime + scheduleHour,
  };
};

export const isLate = (shift) => {
  const _shiftStartTime = moment(
    shift?.startTime,
    "HH:mm"
  );
  let actualCheckIn =
    shift?.attendance?.actualCheckIn ||
    shift?.attendance?.manualCheckIn;

  if (shift?.attendance?.status === "pending") {
    actualCheckIn =
      shift?.attendance?.manualCheckIn;
  }

  const checkInTime = moment(
    actualCheckIn,
    "HH:mm"
  );
  return checkInTime.isAfter(_shiftStartTime);
};

export const checkAnyPendingShift = (shifts) => {
  let temp = false;

  if (shifts?.length) {
    for (let i = 0; i < shifts?.length; i++) {
      if (
        shifts[i]?.attendance?.status ===
        "pending"
      ) {
        temp = true;
        break;
      }
    }
  }

  return temp;
};

export const validateTime = (field, timeObj) => {
  if (timeObj[field.name]?.checked) {
    if (timeObj[field.name]?.time.length === 0) {
      return Promise.reject();
    } else if (
      timeObj[field.name]?.time.length > 0 &&
      timeObj[field.name]?.time.length < 11
    ) {
      return Promise.reject();
    } else if (
      timeObj[field.name]?.time.length === 11
    ) {
      const startTime =
        timeObj[field.name]?.time.split("-")[0];
      const endTime =
        timeObj[field.name]?.time.split("-")[1];
      const d = getDuration(
        dayjs(startTime, "HH:mm"),
        dayjs(endTime, "HH:mm")
      );
      if (d < 0) {
        return Promise.reject();
      } else if (d === 0) {
        return Promise.reject();
      } else if (d > 0 && d < 5) {
        return Promise.reject();
      } else {
        return Promise.resolve();
      }
    } else {
      return Promise.resolve();
    }
  }
};

export const validateBreakTime = (
  breakTime,
  required
) => {
  if (!breakTime) {
    return Boolean(required)
      ? Promise.reject(
          new Error(
            "Please Enter  Start & End Times"
          )
        )
      : Promise.resolve();
  }

  const breakTimeParts = breakTime.split("-");
  if (
    breakTimeParts.length !== 2 ||
    breakTimeParts.some(
      (part) => part.trim().length !== 5
    )
  ) {
    return Promise.reject(
      new Error(
        "Entered Break Time Should Follow The Format, HH:MM-HH:MM!"
      )
    );
  }

  const [lunchStartTime, lunchEndTime] =
    breakTimeParts.map((part) =>
      dayjs(part, "HH:mm")
    );
  const breakTimeDuration = lunchEndTime.diff(
    lunchStartTime,
    "minute"
  );

  if (breakTimeDuration < 0) {
    return Promise.reject(
      new Error(
        "Entered Break Start Time Can Not Precede The Break End Time!"
      )
    );
  } else if (breakTimeDuration === 0) {
    return Promise.reject(
      new Error(
        "Entered Break Start/End Times Can Not Be Identical!"
      )
    );
  }

  return Promise.resolve();
};

export const validateTimeObj = (
  names,
  dates,
  operatingTime,
  type
) => {
  const selectedNames = names.filter(
    (name) => name.checked
  );
  if (dates?.length) {
    const startDate = moment(
      dates[0].format("DD-MM-YYYY"),
      "DD-MM-YYYY"
    );
    const endDate = moment(
      dates[1].format("DD-MM-YYYY"),
      "DD-MM-YYYY"
    );

    const availableOpenDays = [];
    let currentDate = startDate.clone(); // Clone the startDate moment object

    while (currentDate.isSameOrBefore(endDate)) {
      availableOpenDays.push(
        currentDate.format("dddd")
      );
      currentDate.add(1, "days");
    }

    const currentOpenDays = operatingTime?.map(
      (obj) => obj.dayOfWeek
    );

    const commonElements =
      availableOpenDays.filter((day) =>
        currentOpenDays.includes(
          day.toLowerCase()
        )
      );

    if (commonElements.length <= 0) {
      return Promise.reject(
        new Error("Select valid dates")
      );
    }

    if (currentOpenDays.includes(selectedNames)) {
      return Promise.reject(
        new Error("Select valid dates")
      );
    }
  }

  if (selectedNames.length < 1) {
    return Promise.reject(
      new Error(
        "At least one day should be active"
      )
    );
  }

  for (const name of selectedNames) {
    // check time validation
    if (!name.time) {
      return Promise.reject(
        new Error(
          "Please Enter Start & End Times"
        )
      );
    }

    const timeParts = name.time.split("-");
    if (
      timeParts.length !== 2 ||
      timeParts.some(
        (part) => part.trim().length !== 5
      )
    ) {
      return Promise.reject(
        new Error(
          "Entered Time Should Follow The Format, HH:MM-HH:MM!"
        )
      );
    }

    const [startTime, endTime] = timeParts.map(
      (part) => dayjs(part, "HH:mm")
    );
    const duration = endTime.diff(
      startTime,
      "minute"
    );

    if (duration < 0) {
      return Promise.reject(
        new Error(
          "Entered Start Time Can Not Precede The End Time!"
        )
      );
    } else if (duration === 0) {
      return Promise.reject(
        new Error(
          "Entered Start/End Times Can Not Be Identical!"
        )
      );
    } else if (duration < 5) {
      return Promise.reject(
        new Error(
          "Duration Should Be Longer Than 5 Minutes!"
        )
      );
    }

    // Break time validation
    const breakTimeValidation = validateBreakTime(
      name.breakTime
    );

    if (breakTimeValidation !== null)
      return breakTimeValidation;

    // Hourly rate validation

    if (!name.hourlyRate && type === "Job") {
      return Promise.reject(
        new Error(
          "You'll Have To Enter The 'Hourly Rate' To Proceed!"
        )
      );
    }
  }

  return Promise.resolve();
};

export const calculateSingleDayShiftHours = ({
  item,
  type,
}) => {
  let obj = {
    scheduled: `${item?.startTime} - ${item?.endTime}`,
    worked: "",
    status: "",
    overtimeHour: "",
  };

  if (
    type === "leave" ||
    item?.attendance?.type === "leave"
  ) {
    obj = {
      scheduled: 0,
      worked: 0,
      status: "leave",
      overtimeHour: 0,
    };
    return obj;
  }

  let actualCheckIn =
    item?.attendance?.actualCheckIn ||
    item?.attendance?.manualCheckIn;
  let actualCheckOut =
    item?.attendance?.actualCheckOut ||
    item?.attendance?.manualCheckOut;
  const attanDancetype = item?.attendance?.status;
  // No check in check out time

  if (item?.attendance?.status === "pending") {
    actualCheckIn =
      item?.attendance?.manualCheckIn;

    actualCheckOut =
      item?.attendance?.manualCheckOut;
  }

  if (attanDancetype === "unfilled") {
    obj = {
      ...obj,
      worked: "No check in/out time",
      status: "unfilled",
    };
    return obj;
  }

  // pending approval & manual check in /Out
  if (attanDancetype === "pending") {
    obj = {
      ...obj,
      worked: `${item?.attendance?.manualCheckIn} - ${item?.attendance?.manualCheckOut}`,
      status: "pending",
    };
    return obj;
  }

  // overtime , incomplete  check
  if (
    !isNull(actualCheckIn) ||
    !isNull(actualCheckOut)
  ) {
    const _scheduledTime = getTotalMinutes(
      item?.startTime,
      item?.endTime
    );
    const _workedTime = getTotalMinutes(
      actualCheckIn,
      actualCheckOut
    );

    if (_workedTime === _scheduledTime) {
      obj = {
        ...obj,
        worked: `${actualCheckIn} - ${
          actualCheckOut || ""
        }`,
        status: "complete",
        overtimeHour:
          _workedTime - _scheduledTime,
      };
      return obj;
    }

    if (_workedTime > _scheduledTime) {
      obj = {
        ...obj,
        worked: `${actualCheckIn} - ${
          actualCheckOut || ""
        }`,
        status: "overtime",
        overtimeHour:
          _workedTime - _scheduledTime,
      };
    } else {
      obj = {
        ...obj,
        worked: `${actualCheckIn} - ${
          actualCheckOut || ""
        }`,
        status: "incomplete",
        incompleteHour:
          _scheduledTime - _workedTime,
      };
    }

    return obj;
  }

  //Not started

  return obj;
};

export function getAbbreviationForLeave(
  absenceType
) {
  if (
    typeof absenceType !== "string" ||
    absenceType.trim() === ""
  ) {
    return "Unknown";
  }

  const key = absenceType
    .toLowerCase()
    .replace(/\s+/g, "_");

  const abbreviation = staffAbsencesType[key];

  if (!abbreviation) {
    return absenceType;
  }

  // Map words to their first characters and join them
  return abbreviation
    .split(" ")
    .map((word) => word.charAt(0))
    .join("");
}

export const getShiftType = (attendance) => {
  if (!attendance) return "";
  switch (attendance.type) {
    case "leave":
      return getAbbreviationForLeave(
        staffAbsencesType[
          attendance?.leaveType
        ] || "Leave"
      );
    case "pending":
      return "P";
    case "absent":
      if (attendance?.status === "pending") {
        return "P";
      }
      return "A";
    case "present":
      if (attendance?.status === "pending") {
        return "P";
      } else {
        const actualCheckIn =
          attendance.actualCheckIn ||
          attendance.manualCheckIn;
        const actualCheckOut =
          attendance.actualCheckOut ||
          attendance.manualCheckOut;
        return getTotalWorkingHours(
          actualCheckIn,
          actualCheckOut
        );
      }

    default:
      return "";
  }
};

export const getLeaveCountForStaff = (shifts) => {
  let leaveCount = 0;

  if (shifts?.length) {
    shifts?.forEach((shift) => {
      if (shift?.attendance.type === "leave") {
        leaveCount += 1;
      }
    });
  }

  return leaveCount;
};

export const prepareSingleExcellCellForImport = (
  shiftsInOneDay
) => {
  if (!shiftsInOneDay) return "";

  let formattedShifts = shiftsInOneDay.map(
    (shifts) => {
      return getShiftType(shifts?.attendance);
    }
  );

  const validShifts = formattedShifts.filter(
    (value) => value !== ""
  );

  return validShifts.join(",");
};
