import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomTabs } from "../../../components/UI/tabs";
import DocumentTab from "../../../components/documents/DocumentTab";
import { getProfileDataThunk } from "../../../redux/profile/profileThunk";
import { getRightToWorkList } from "../../../redux/staffs/staffsAPI";
import { getStaffAccrediationsThunk } from "../../../redux/staffs/staffsThunk";
import {
  allowedAccreditations,
  enhanceAccreditations,
  findAnswer,
  prepareRightToWorkData,
} from "../../../utils/document";

const StaffDocument = () => {
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { staffAccrediations } = useSelector((state) => state.staffs);

  useEffect(() => {
    dispatch(getProfileDataThunk());
    dispatch(getStaffAccrediationsThunk({ staffId: user?.id }));
  }, [dispatch, user]);

  const { data } = useQuery({
    queryKey: ["getRightToWorkList"],
    queryFn: () => getRightToWorkList({ userid: user.id }),
  });
  const rightToWorkDataSetForStaff = useMemo(() => {
    const temp = prepareRightToWorkData(data?.data);

    const _t = temp.filter((item) => {
      for (const condition of allowedAccreditations) {
        if (
          (condition.roles && condition.roles.includes(user?.roleId)) ||
          (condition.labels &&
            condition.labels.includes(item?.accreditationLabel))
        ) {
          return true;
        }
      }
      return false;
    });

    return _t;
  }, [data?.data, user]);

  const allAccreditations = useMemo(() => {
    if (!!staffAccrediations) {
      const systemAccreditation = enhanceAccreditations(
        staffAccrediations?.systemAccreditation,
        {
          isExpiryRequired: 0,
          isDocument: 1,
          isMandatory: 1,
          type: "systemAccreditation",
          document_type: "document",
        }
      );

      const accreditationRules = enhanceAccreditations(
        staffAccrediations?.pharmacyAccreditation?.accreditationRules,
        {
          accreditationName: (accr) => accr?.title,
          accreditationLabel: (accr) => accr?.title,
          date_key: (accr) => accr?.title,
          filePath: (accr) => {
            const ans = findAnswer(
              staffAccrediations?.pharmacyAccreditation?.staffAccreditations,
              accr?.id,
              "answer"
            );

            return accr?.isDocument ? ans : null;
          },
          answer: (accr) =>
            findAnswer(
              staffAccrediations?.pharmacyAccreditation?.staffAccreditations,
              accr?.id,
              "answer"
            ),
          expiryDate: (accr) =>
            findAnswer(
              staffAccrediations?.pharmacyAccreditation?.staffAccreditations,
              accr?.id,
              "expiryDate"
            ),
          accreditationRuleId: (accr) => accr?.id,
          type: "pharmacyAccreditation",
          edit: true,
          document_type: (accr) => (accr?.isDocument ? "document" : "answer"),
        }
      );

      return [...(accreditationRules || []), ...(systemAccreditation || [])];
    }
    return [];
  }, [JSON.stringify(staffAccrediations), staffAccrediations]);

  const tabs = useMemo(() => {
    return [
      {
        title: "Right To Work",
        content: (
          <DocumentTab
            staffId={user?.id}
            tab="rightToWork"
            fileList={rightToWorkDataSetForStaff}
          />
        ),
      },
      {
        title: "Accreditations",
        content: (
          <DocumentTab
            tab="accrediations"
            staffId={user?.id}
            fileList={allAccreditations}
            accreditationRules={
              staffAccrediations?.pharmacyAccreditation?.accreditationRules
            }
          />
        ),
      },
    ];
  }, [
    user?.id,
    JSON.stringify(staffAccrediations),
    JSON.stringify(rightToWorkDataSetForStaff),
  ]);

  return (
    <div className="staff_document_tab">
      <CustomTabs
        tabs={tabs}
        currentActiveTab={currentActiveTab}
        setCurrentActiveTab={setCurrentActiveTab}
      />
    </div>
  );
};

export default StaffDocument;
