import { Avatar } from "antd";
import React, { useEffect, useRef, useState } from "react";
const CustomAvatar = ({ src, alt, size = "small", ...rest }) => {
  const ref = useRef(null);
  const [source, setSource] = useState(src);

  useEffect(() => {
    setSource(src);
  }, [src]);
  return (
    <>
      {!!source ? (
        <Avatar
          {...rest}
          ref={ref}
          size={size}
          src={source}
          alt={alt}
          onError={() => {
            setSource(null);
            return false;
          }}
        />
      ) : (
        <Avatar {...rest} size={size}>
          {!!alt && alt[0]}
        </Avatar>
      )}
    </>
  );
};

export default CustomAvatar;
