import { Route, Routes } from "react-router-dom"
import { LocumJobDetails } from "./details"
import { LocumShiftListPage } from "./locumShifts"

export const LocumShifts = () => {
    return (
        <Routes>
            <Route
                path={`/`}
                element={<LocumShiftListPage />}
            />
            <Route path="/:shiftId/details/*" element={<LocumJobDetails />} />
        </Routes>

    )
}