import React from "react";
import { Link } from "react-router-dom";
import { RouterConfig } from "../../../routerConfig";

import locumIcon from "../../../assets/icons/locum2.png";
import ArrowRight from "../../../assets/icons/arrow-right-white.svg";
import pharmacyIcon from "../../../assets/icons/drugstore.png";
import { Button, Radio } from "antd";
import "../style.scss";

export const SignUpSelectionNew = ({
  setSignUpMode,
  signUpMode,
  setShowSignUpForm,
}) => {
  return (
    <div className="locum_signup_new">
      <div className="sign_up_new text-center">
        <h2 className="heading2">Signup</h2>
        <p className="t1 sub_heading">Choose an option to continue further.</p>
      </div>
      <div className="" style={{ width: "100%", gap: 20, marginBottom: 24 }}>
        <div
          style={{ width: "100%" }}
          id="signUpLocum"
          onClick={() => setSignUpMode("locum")}
          className={`selection-tab   cursor-pointer ${
            signUpMode === "locum" && "active-tab"
          }`}
        >
          <div className="d-flex align-items-start justify-content-between mb-3">
            <div className="d-flex align-items-center ">
              <img
                width={42}
                height={42}
                src={locumIcon}
                alt="locumIcon"
                className="me-2"
              />
              <h4 className="heading4">I am a Locum</h4>
            </div>
            <Radio checked={signUpMode === "locum"} />
          </div>
          <div className="t1">
            You're a pharmacist, technicians looking for jobs you can work on
            your will at your own terms.{" "}
          </div>
        </div>
        <div
          style={{ width: "100%" }}
          id="signUpPharmacy"
          onClick={() => setSignUpMode("pharmacy")}
          className={`  selection-tab  cursor-pointer ${
            signUpMode === "pharmacy" && "active-tab"
          }`}
        >
          <div className="d-flex align-items-start justify-content-between mb-3">
            <div className="d-flex align-items-center ">
              {" "}
              <img
                width={42}
                height={42}
                src={pharmacyIcon}
                alt="locumIcon"
                className="me-2"
              />
              <h4 className="heading4">I am a Pharmacy</h4>
            </div>
            <Radio checked={signUpMode === "pharmacy"} />
          </div>
          <div className="t1">
            You're a pharmacy looking to source locums at a moment's notice,
            manage your staff and do all your shift scheduling from one place.
          </div>
        </div>
      </div>

      <div
        className="p2  d-flex justify-content-between align-items-center form_bottom"
        style={{ color: "#000" }}
      >
        <span className="">
          Already have an account?
          <Link
            to={RouterConfig.login}
            className="text-decoration-none"
            style={{ color: "#09988D" }}
          >
            {" "}
            Login
          </Link>
        </span>
        <Button
          disabled={!signUpMode}
          onClick={() => setShowSignUpForm(true)}
          type="primary"
          htmlType="submit"
          block
          className="custom_btn auth_button d-flex flex-row-reverse gap-2"
          style={{ width: "auto" }}
          icon={<img src={ArrowRight} alt="arrow" />}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
