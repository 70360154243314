import React from "react";

const CustomTableHeader = ({ weekCount, selectedWeek, setSelectedWeek }) => {
  const Weeks = Array.from(
    { length: Math.round(weekCount) },
    (_, index) => index + 1
  );

  return (
    <div className="custom_table_header">
      {Weeks.map((item) =>
        item === selectedWeek ? (
          <button
            className="custom_btn btn week_button"
            onClick={() => setSelectedWeek(item)}
          >
            Week {item}
          </button>
        ) : (
          <button
            className="btn week_button"
            onClick={() => setSelectedWeek(item)}
          >
            {item}
          </button>
        )
      )}
    </div>
  );
};

export default CustomTableHeader;
