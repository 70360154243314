import React, { useMemo, useState } from "react";
import { Card } from "semantic-ui-react";

import { Button, List, Popover, Tag, Tooltip, Typography } from "antd";
import _ from "lodash";
import { useDispatch } from "react-redux";
import FaceImage from "../../../assets/icons/face.svg";
import { yotiVerificationStartThunk } from "../../../redux/staffs/staffsThunk";
import { fallBack } from "../../comon/CustomImage";
import CommonModal from "../modals/CommonModal/CommonModal";
import "./style.scss";

// Sample Data

// const data = {
//   "full_name": "PETER ONEIL",
//   "date_of_birth": "1966-12-16",
//   "given_names": "PETER",
//   "family_name": "ONEIL",
//   "place_of_birth": "ENGLAND",
//   "gender": "MALE",
//   "structured_postal_address": {
//     "address_format": 1,
//     "building_number": "92",
//     "address_line1": "92 SHEARWATER",
//     "address_line2": "WHITBURN",
//     "town_city": "SUNDERLAND",
//     "postal_code": "SR6 7SG",
//     "country_iso": "GBR",
//     "country": "United Kingdom",
//     "formatted_address": "92 SHEARWATER\nWHITBURN\nSUNDERLAND\nSR6 7SG\nUnited Kingdom"
//   },
//   "array": [
//     {
//       "saad": 'hello',
//       "basharat": "bye",
//       'third': 'ahemd',
//       'array3': [
//         {
//           "raja": 'gandu'
//         }
//       ]
//     },
//     {
//       "a": 'hello',
//       "b": "bye",
//       'c': 'ahemd'
//     },
//   ],
//   "array2": [
//     {
//       "c": 'hello',
//       "d": "bye",
//       'e': 'ahemd'
//     },
//     {
//       "f": 'hello',
//       "g": "bye",
//       'h': 'ahemd'
//     },
//   ],
//   "document_type": "DRIVING_LICENCE",
//   "issuing_country": "GBR",
//   "document_number": "ONEIL612166P99KF54",
//   "expiration_date": "2031-11-04",
//   "date_of_issue": "2021-11-05",
//   "issuing_authority": "DVLA"
// }

const ExtractedObject = ({ data }) => {
  return Object.keys(data).map((key) => {
    return (
      <div key={key} className="d-flex gap-2">
        <div style={{ textTransform: "capitalize" }} className="fw-bold">
          {key?.split("_").join(" ")} :
        </div>
        <div>{data[key]}</div>
      </div>
    );
  });
};

export const BranchCard = ({
  logo,
  primaryText,
  secondaryText,
  userStatus,
  hasRating,
  stats,
  isStaff,
  staffInfo,
  showStatus,
  gphc_no,
  showVerifyButton,
  isBranch,
}) => {
  const dispatch = useDispatch();
  let result = {};
  const [hovered, setHovered] = useState(false);
  const [openVerifyModal, setopenVerifyModal] = useState(false);
  const ShowVerifyModal = () => {
    setopenVerifyModal(!openVerifyModal);
  };
  const data = [
    "Upload your profile picture.",
    "A quick scan of your face.",
    "You will receive an email in 24hours to let you know if your request is accepted.",
  ];
  const altText = useMemo(() => {
    return primaryText
      ?.split(" ")
      ?.map((d, idx) => (idx < 2 ? d?.charAt(0).toUpperCase() : null))
      .join("");
  }, [primaryText]);

  const handleHoverChange = (open) => {
    setHovered(open);
  };

  const recursiveFunction = (obj) => {
    for (const key in obj) {
      if (typeof obj[key] === "object" && Array.isArray(obj[key]) === false) {
        recursiveFunction(obj[key]);
      } else if (Array.isArray(obj[key]) === true) {
        recursiveFunction(obj[key]);
      } else {
        result = { ...result, [key]: obj[key] };
      }
    }
  };

  const jsonObject = useMemo(() => {
    if (_.isEmpty(staffInfo?.userExtractedData)) return;
    recursiveFunction(JSON.parse(staffInfo?.userExtractedData));
    return result;
  }, [staffInfo?.userExtractedData, result]);

  const onVerifyIdentity = () => {
    dispatch(yotiVerificationStartThunk()).then((res) => {
      if (res.payload) {
        window.open(res.payload, "_self");
      }
    });
  };

  const _renderStatus = useMemo(() => {
    let text = Boolean(userStatus) ? "Verified" : "Not Verified";

    if (userStatus === 2) {
      text = "In Progress";
    }

    if (Boolean(isBranch)) {
      text = Boolean(userStatus) ? "Active" : "Inactive";
    }

    return (
      <>
        {showStatus && (
          <Tag
            color="success"
            className={userStatus === 1 ? "status" : "status notVerified"}
          >
            {text}
          </Tag>
        )}
      </>
    );
  }, [isBranch, showStatus, userStatus]);

  return (
    <>
      <Card className="branch-card">
        {logo ? (
          <div
            className={
              showStatus && userStatus === 1
                ? "profile_img_wrapper"
                : "profile_img_wrapper notVerified"
            }
            style={{
              width: "121px",
              height: "121px",
              borderRadius: "50%",
              margin: "0 auto",
              marginBottom: "10px",
              position: "relative",
              padding: "3px",
            }}
          >
            <img
              src={logo}
              alt={altText}
              style={{
                width: logo ? "100%" : "23px",
                height: logo ? "100%" : "23px",
                borderRadius: logo ? "50%" : "0%",
                objectFit: "cover",
              }}
              onError={e=>e.target.src=fallBack}
            />
            {_renderStatus}
          </div>
        ) : (
          <>
            <div></div>
            <div
              className={
                userStatus === 1
                  ? "profile_img_wrapper"
                  : "profile_img_wrapper notVerified"
              }
              style={{
                width: "131px",
                height: "131px",
                borderRadius: "50%",
                margin: "0 auto",
                marginBottom: "10px",
                position: "relative",
                padding: "6px",
                fontSize: 35,
                color: " #065A4F",
                textAlign: "center",
              }}
              text={altText}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  background: "#E1F4F4",
                  fontSize: 35,
                  color: " #065A4F",
                  textAlign: "center",
                  // lineHeight: "134px",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p>{altText}</p>
              </div>

              {_renderStatus}
            </div>
          </>
        )}
        <Popover
          className="header-component-container"
          title="Verification Extracted Data"
          placement="right"
          open={hovered}
          content={
            <div
              className="border-4 border-start ps-3"
              style={{ borderColor: "red" }}
            >
              <ExtractedObject data={jsonObject} />
            </div>
          }
          trigger="hover"
          onOpenChange={
            staffInfo?.userExtractedData ? handleHoverChange : undefined
          }
        >
          {/* {userStatus && (
            <span
              style={{
                position: "absolute",
                top: "150px",
                left: "12%",
                height: "35px",
                padding: "0px 17px",
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
                color: `${
                  userStatus === "active" ? "rgb(27, 94, 31)" : "#B71B1C"
                }`,
                border: "1px solid #FFFFFF",
                background: `${
                  userStatus === "active" ? "rgb(232, 245, 233)" : "#FFEBEE"
                }`,
                borderRadius: "36px",
                width: "115px",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              
            </span>
          )} */}
        </Popover>

        <Card.Content className="text-content">
          <Card.Header>
            <p className="h5 fw-bold">{primaryText ?? "Demo"}</p>
          </Card.Header>
          <Card.Description>
            <p className="t2 ">{secondaryText} </p>
          </Card.Description>
        </Card.Content>

        {gphc_no && (
          <Tag color="default" className="gphc_no">
            GPhC :{gphc_no}
          </Tag>
        )}
        <div></div>
      </Card>
      {hasRating && (
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ margin: "20px" }}
        >
          <div
            className="d-flex flex-column  "
            style={{ maxWidth: "67px", overflow: "hidden" }}
          >
            <span className="b1">{stats.completed}</span>
            <div
              className=" t2 breadCrumps-role-detail"
              style={{ color: "#7B7B7B" }}
            >
              Completed
            </div>
          </div>
          <div
            style={{
              border: "1px solid  #D9D9D9",
              height: "24px",
              borderRadius: "24px",
              background: "#D9D9D9",
            }}
          >
            {" "}
          </div>
          <div className="d-flex flex-column  ">
            <span className="b1">{stats?.cancelled}</span>
            <div
              className=" t2 breadCrumps-role-detail"
              style={{ color: "#7B7B7B" }}
            >
              Cancelled
            </div>
          </div>
          <div
            style={{
              border: "1px solid  #D9D9D9",
              height: "24px",
              borderRadius: "24px",
              background: "#D9D9D9",
            }}
          >
            {" "}
          </div>
          <div className="d-flex flex-column  ">
            <span className="b1">{Number(stats?.rating)?.toFixed(1)}</span>
            <div
              className=" t2 breadCrumps-role-detail"
              style={{ color: "#7B7B7B" }}
            >
              Rating
            </div>
          </div>
        </div>
      )}

      {showVerifyButton && (
        <div style={{ padding: "0 16px" }}>
          <Tooltip
            placement="right"
            className="verify_tooltip"
            title={
              <>
                <p>Identity Verification is a Must!</p>
                <span>
                  To apply for jobs, please complete the identity verification
                  process.
                </span>
              </>
            }
          >
            <Button className="verify_btn" onClick={ShowVerifyModal}>
              Verify Your Identity
            </Button>
          </Tooltip>
        </div>
      )}
      <div className="verify_modal">
        <CommonModal
          title="Verify your Identity to apply for jobs"
          handleModal={openVerifyModal}
          setHandleModal={ShowVerifyModal}
          primaryButtonText="Continue"
          secondaryButtonText="Cancel"
          primaryButtonHandler={onVerifyIdentity}
        >
          <figure className="img_wrap">
            <img src={FaceImage} alt="faceimg" />
          </figure>

          <div className="modal_text">
            <p>To Verify your Identity you will have to </p>

            <List
              bordered={false}
              dataSource={data}
              renderItem={(item, index) => (
                <List.Item>
                  <strong>{index + 1}.</strong>{" "}
                  <Typography.Text>{item}</Typography.Text>
                </List.Item>
              )}
            />

            <Typography.Text>Some instructions....</Typography.Text>
          </div>
        </CommonModal>
      </div>
    </>
  );
};
