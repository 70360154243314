import { InfoCircleFilled } from "@ant-design/icons";
import { Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Danger from "../../../assets/icons/danger-yellow.svg";
import PencilIcon from "../../../assets/icons/pencil.svg";
import { ATTENDANCE_TYPE } from "../../../utils/constant";
import eventEmitter, { events } from "../../../utils/eventEmitter";
import { isLate } from "../../../utils/timeshhet";
import TimeInput from "../../UI/input/TimeInput";

const CheckinOut = ({ isInEditMode, index }) => {
  const [input, setinput] = useState("");
  const [isValueEdited, setIsValueEdited] = useState(false);
  const [editInput, seteditInput] = useState(isInEditMode);
  const { staffTimeSheetEdited } = useSelector((s) => s.staffs);

  const [error, setError] = useState("");

  const shiftDetails = useMemo(() => {
    const details = staffTimeSheetEdited?.[index];
    return details;
  }, [index, staffTimeSheetEdited]);

  useEffect(() => {
    if (!isInEditMode) {
      seteditInput(false);
      setIsValueEdited(false);
      setError("");
    }
  }, [isInEditMode]);

  const isFutureDate = useMemo(() => {
    return moment(shiftDetails?.startDate)
      ?.endOf("date")
      .isAfter(moment()?.endOf("date"));
  }, [shiftDetails]);

  const checkOutCheckInTime = useMemo(() => {
    if (!shiftDetails?.checkIn || !shiftDetails?.checkOut) {
      if (!isValueEdited) {
        setinput("");
      }
      return null;
    }
    const time = `${shiftDetails?.checkIn}-${shiftDetails?.checkOut}`;
    if (!isValueEdited) {
      setinput(time);
    }
    return time;
  }, [isValueEdited, shiftDetails?.checkIn, shiftDetails?.checkOut]);

  const isEmpty = !shiftDetails?.checkIn || !shiftDetails?.checkOut;

  const isLateMemo = useMemo(() => {
    return isLate(shiftDetails);
  }, [shiftDetails]);

  const handleChange = (value) => {
    setIsValueEdited(true);

    setinput(value);

    const splitby_ = value?.split("-");

    const checkInTime = splitby_?.[0] || null;
    const checkOutTime = splitby_?.[1] || null;

    let errorText = "";
    const momentCheckIn = moment(checkInTime, "HH:mm");
    const momentCheckOut = moment(checkOutTime, "HH:mm");

    if (checkInTime === checkOutTime) {
      errorText = "Check in & Check out time cannot be same";
    }

    if (momentCheckIn.isAfter(momentCheckOut)) {
      errorText = "Enter valid time range";
    }

    if (!momentCheckIn.isValid() || !momentCheckOut.isValid()) {
      errorText = "Enter valid Check in/Check out time";
    }
    if (!checkInTime) {
      errorText = "Check in time is required.";
    }
    if (!checkOutTime) {
      errorText = "Check out time is required.";
    }
    if (!checkOutTime && !checkInTime) {
      errorText = "Check in/Check out time is required.";
    }

    setError(errorText);
    let payload = {
      recordId: shiftDetails?.attendance?.id || shiftDetails?.recordId,
      checkIn: checkInTime,
      checkOut: checkOutTime,
      status: shiftDetails?.attendance?.status,
      mutationType: "updateFields",
    };
    if (typeof shiftDetails?.recordId === "undefined") {
      payload = {
        ...payload,
        recordId: index,
        status: null,
      };
    }

    eventEmitter.emit(events.timeSheetCelledit, payload);
  };

  if (
    shiftDetails?.type === ATTENDANCE_TYPE.NO_SHIFT ||
    shiftDetails?.type === ATTENDANCE_TYPE.LEAVE
  ) {
    return <div />;
  }

  if (isEmpty && !isInEditMode) {
    return <div />;
  }
  return (
    <div
      className={`${error ? "has-error" : ""} ${isInEditMode ? "edit_td" : ""}`}
    >
      {editInput ? (
        <div className="modal_table_input">
          <TimeInput
            value={input}
            className="modal_table_input"
            onChange={handleChange}
          />

          {error ? (
            <Tooltip title={error} className="error-icon">
              <InfoCircleFilled />
            </Tooltip>
          ) : null}
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-start">
          {isLateMemo && (
            <i className="d-flex align-items-center justify-content-start me-1">
              <img src={Danger} alt="danger" />
            </i>
          )}

          <div className="d-flex align-items-center justify-content-between flex-grow-1">
            <p className="d-flex align-items-center justify-content-start m-0 ">
              {checkOutCheckInTime}
            </p>
            {isInEditMode && shiftDetails?.branchId && !isFutureDate && (
              <button
                className="btn edit_button flex-grow-1 justify-content-end border-0"
                onClick={() => seteditInput(!isFutureDate)}
              >
                <img src={PencilIcon} alt="pencil" />
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckinOut;
