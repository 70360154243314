import { DatePicker } from "antd";
import React from "react";

export const MultipleDatePicker = ({
  startDate,
  endDate,
  onChange,
  form,
  excludeDays = [],
}) => {
  // const [showDatePicker, setShowDatePicker] =
  //   useState(false);
  const onValueChange = (date) => {
    const newDate = date.format("YYYY-MM-DD");
    if (excludeDays?.includes(newDate)) {
      form.setFieldsValue({
        excludeDays: [
          ...excludeDays?.filter(
            (item) => item !== newDate
          ),
        ],
      });
    } else {
      form.setFieldsValue({
        excludeDays: [...excludeDays, newDate],
      });
    }
  };

  const dateRender = (currentDate) => {
    const isSelected = excludeDays?.includes(
      currentDate.format("YYYY-MM-DD")
    );
    let selectStyle = isSelected
      ? {
          position: "relative",
          zIndex: 2,
          display: "inlineBlock",
          width: "24px",
          height: "22px",
          lineHeight: "22px",
          backgroundColor: "#51c2bc",
          color: "#fff",
          margin: "auto",
          borderRadius: "3px",
          transition:
            "background 0.3s, border 0.3s",
        }
      : {};
    return (
      <div style={selectStyle}>
        {" "}
        {currentDate.date()}{" "}
      </div>
    );
  };

  const customFormat = (value) =>
    `${excludeDays.join(", ")}`;

  return (
    <div>
      <DatePicker
        disabled={!startDate || !endDate}
        disabledDate={(current) => {
          return (
            (startDate
              ? startDate >= current
              : null) ||
            (endDate
              ? endDate.add(1, "day") <= current
              : null)
          );
        }}
        allowClear={false}
        // onBlur={() => setShowDatePicker(false)}
        onChange={onValueChange}
        // onFocus={() => setShowDatePicker(true)}
        inputReadOnly
        getPopupContainer={(trigger) =>
          trigger.parentElement
        }
        className="text-input-field p-2 pe-3 t1"
        style={{ width: "100%" }}
        // open={showDatePicker}
        dateRender={dateRender}
        showToday={false}
        format={customFormat}
      />
    </div>
  );
};
