import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import editIcon from "../../../../../assets/icons/edit.png";
import deleteIcon from "../../../../../assets/icons/delete.png";
import { ConfirmModal } from "../../../../../components/UI/modals/confirmModal";
import { TableComponet } from "../../../../../components/UI/table";

import "./style.scss";
import { useNavigate } from "react-router-dom";
import { CreateAdminStaff } from "./createStaff/CreateAdminStaff";
import { getAdminStaffThunk } from "../../../../../redux/profile/profileThunk";
import { Form } from "antd";
import { EmptyData } from "../../../../../components/comon/EmptyData";

export const AdminStaffTable = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);
  const { regions, regionBranches } = useSelector((state) => state.regions);
  const [selectedStaffEdit, setSelectedStaffEdit] = useState();
  const [staffId, setStaffId] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [form] = Form.useForm();
  const { adminStaffList } = useSelector((state) => state.profile);
  const staffsColumns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row: { original } }) => {
          return <div>{original.name ? original.name : "-"}</div>;
        },
      },
      {
        Header: "Role",
        accessor: "label",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ row: { original } }) => {
          return <div>{original.email ? original.email : "-"}</div>;
        },
      },

      {
        Header: "",
        accessor: "status",
        Cell: ({ row: { original } }) => {
          return (
            <div>
              <Dropdown icon="ellipsis vertical">
                <Dropdown.Menu direction="left">
                  <Dropdown.Item
                    icon={<img className="pt-2" src={editIcon} alt="" />}
                    text="Edit"
                    onClick={() => {
                      form.setFieldsValue({
                        name: original.name,
                        surname: original.surname,
                        email: original.email,
                        phone: original.phone,
                        adminrole: original.label,
                      });
                      setStaffId(original.id);
                      props.setActionType("Edit");
                      props.setShowCreateModal(!props.showCreateModal);
                    }}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    []
  );
  useEffect(() => {
    getAdminStaff();
  }, [dispatch, perPageCount, currentPageCount, props.searchQuery]);

  const getAdminStaff = () => {
    dispatch(
      getAdminStaffThunk({
        filters: { name: props.searchQuery.trim() },
        pagination: { page: currentPageCount, perPage: perPageCount },
      })
    );
  };
  const {
    count: totalDataCount,
    page,
    pages: totalPagesCount,
    perPage,
    rows: staffsData,
  } = useMemo(() => adminStaffList, [adminStaffList]);

  const handleConfirmModal = useCallback(
    (med) => {
      setShowConfirmModal(!showConfirmModal);
      // setRequestType(med);
    },

    [showConfirmModal]
  );
  useEffect(() => {
    if (props.actionType === "Add") {
      form.setFieldsValue({
        fullname: "",
        email: "",
        phone: "",
        adminrole: "",
      });
    }
  }, [props.actionType]);

  return (
    <div style={{ padding: "0px 24px" }}>
      {showConfirmModal && (
        <ConfirmModal
          title={`Do you really want to Delete admin role?`}
          description={`You are about to Delete the admin role. This will Delete your admin role and other information permanently.`}
          open={showConfirmModal}
          confirmButtonText="Delete"
          cancelButtonText="Cancel"
          onClose={() => setShowConfirmModal(!showConfirmModal)}
        // onConfirm={onRegionSubmit}
        />
      )}
      {adminStaffList?.rows?.length <= 0 ? (
        <EmptyData title="Admin Staffs " />
      ) : (
        <TableComponet
          rowCursor="pointer"
          isBorderShown
          currentPageCount={currentPageCount}
          perPageCount={perPageCount}
          totalDataCount={totalDataCount}
          setCurrentPage={setCurrentPageCount}
          setPerPageCount={setPerPageCount}
          showPagination
          maxPageCount={totalPagesCount}
          columns={staffsColumns}
          data={staffsData ? staffsData : []}
        // formatRowProps={formatTrProps}
        />
      )}
      <CreateAdminStaff
        setActionType={props.setActionType}
        actionType={props.actionType}
        showCreateModal={props.showCreateModal}
        handleModalToggle={() =>
          props.setShowCreateModal(!props.showCreateModal)
        }
        form={form}
        id={staffId}
        getAdminStaff={getAdminStaff}
      />
    </div>
  );
};
