import moment from "moment";
import React, { useEffect, useState } from "react";
import arrowDownIcon from "../../../../../../../assets/icons/arrowDown.png";
import { UserProfileModal } from "../../../../../../../components/comon/userProfileModal";
import { rolesColor } from "../../../../../../../components/schedule/SchedularToolbar";
import { BranchItemCell } from "../../../../../../../components/schedule/schedule-Week-view/week-items/BranchItem";

const rolesEnum = {
  staff_pharmacist: "Pharmacists",
  staff_technician: "Technicians",
  staff_counter: "Staff Counter",
  staff_prereg: "Staff Pre-Reg",
  staff_dispenser: "Staff Dispenser",
};

export const BranchWeeklyView = ({
  scheduleData,
  dateRange,
  showDateCheckbox,
  handleAddShift,
  onDelete,
  operatingTime,
  onDaySelect,
  active,
  branch,
}) => {
  const [resDateRange, setResDateRange] = useState([]);
  const [showUserProfileModal, setShowUserProfileModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  const [isCollapse, setIsCollapse] = useState({
    0: true,
    1: false,
    2: false,
    3: false,
    4: false,
  });

  useEffect(() => {
    const start = moment(dateRange?.startDateRange, "YYYY-MM-DD");
    const end = moment(dateRange?.endDateRange, "YYYY-MM-DD");
    const diff = end.diff(start, "days");
    const days = [];
    for (let i = 0; i <= diff; i++) {
      days.push({
        day: moment(start).add(i, "days").format("dddd"),
        date: moment(start).add(i, "days").format("Do MMMM"),
      });
    }
    setResDateRange(days);
  }, [dateRange?.endDateRange, dateRange?.startDateRange]);

  return (
    <div className="weekly-view-container">
      {showUserProfileModal && (
        <UserProfileModal
          showUserProfileModal={showUserProfileModal}
          selectedUser={selectedUser}
          setShowUserProfileModal={setShowUserProfileModal}
        />
      )}
      <div className="week-view-wraper">
        <table
          cellSpacing={0}
          cellPadding={0}
          style={{
            width: "100%",
            tableLayout: `${resDateRange.length <= 7 ? "fixed" : ""}`,
          }}
        >
          <thead style={{ height: "58px" }}>
            <tr
              className=""
              style={{
                height: "100%",
                width: "100%",
                borderBottom: "1px solid #e9e9e9",
              }}
            >
              <th className="sticky-col first-col branch-header">
                <div className="branch-name-heading">
                  <span className="heading5">Roles</span>
                </div>
              </th>
              {resDateRange?.map((data, idx) => {
                return (
                  <th
                    key={idx}
                    className="week-day-heading-cell"
                    style={{ verticalAlign: "middle", minWidth: 166 }}
                  >
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <span className="s1" style={{ fontWeight: "bold" }}>
                        {data.day}
                      </span>
                      <span className="s2" style={{ color: "#7B7B7B" }}>
                        {data.date}
                      </span>
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody style={{ height: "100%", width: "100%" }}>
            {scheduleData?.map((data, i) => {
              return (
                <>
                  <tr key={i}>
                    <td
                      onClick={() => {
                        setIsCollapse({
                          ...isCollapse,
                          [i]: isCollapse[i] ? false : true,
                        });
                      }}
                      className="sticky-col first-col"
                      style={{ width: "100%", height: 60 }}
                    >
                      <div
                        className="p3 p-3 border-0 rounded cursor-pointer d-flex justify-content-between"
                        style={{
                          width: `${
                            resDateRange.length <= 7 ? "67vw" : "70vw"
                          }`,
                          position: "absolute",
                          top: 8,
                          left: 6,
                          backgroundColor: "#E1F4F4",
                        }}
                      >
                        <div className="d-flex gap-3">
                          {rolesEnum[data.title]}
                          <div
                            className="t2 mb-1"
                            style={{
                              width: 24,
                              textAlign: "center",
                              backgroundColor: "#09988d",
                              borderRadius: 5,
                              color: "white",
                            }}
                          >
                            {data.users.length}
                          </div>
                        </div>
                        <div>
                          <img src={arrowDownIcon} alt="collapse" />
                        </div>
                      </div>
                    </td>
                  </tr>
                  {isCollapse[i] &&
                    data.users.map((user, idx) => {
                      return (
                        <tr
                          key={idx}
                          className=" table-row weeklyTableView"
                          style={{
                            height: "130px",
                            borderBottom: "1px solid #e9e9e9",
                          }}
                        >
                          <td
                            className="sticky-col first-col"
                            style={{ height: "130px" }}
                          >
                            <div
                              className={`branch-name-cell`}
                              style={{ height: "100%" }}
                            >
                              <div className="row-1">
                                <span className="t2"> # {user.id} </span>
                              </div>
                              <div
                                className="d-flex gap-2 align-items-center"
                                onClick={() => {
                                  const data = {
                                    ...user,
                                  };
                                  delete data.events;
                                  setSelectedUser(data);
                                  setShowUserProfileModal(true);
                                }}
                              >
                                <div style={{ width: 50, height: 50 }}>
                                  {user.image ? (
                                    <img
                                      src={user.image}
                                      alt=""
                                      className="rounded-circle"
                                      style={{
                                        width: 50,
                                        height: 50,
                                        minHeight: 50,
                                        minWidth: 50,
                                        maxWidth: 50,
                                        maxHeight: 50,
                                      }}
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        width: 50,
                                        height: 50,
                                        borderRadius: "50%",
                                        background: "#E1F4F4",
                                        fontSize: "18px",
                                        color: " #065A4F",
                                        textAlign: "center",
                                        lineHeight: "50px",
                                      }}
                                    >
                                      {`${user.name} ${user.surname}`
                                        ?.split(" ")
                                        ?.map((d, idx) =>
                                          idx < 2
                                            ? d?.charAt(0).toUpperCase()
                                            : null
                                        )
                                        .join("")}
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="d-flex flex-column"
                                  title={user.name + user.surname}
                                  style={{ overflow: "hidden" }}
                                >
                                  <span
                                    className="p3"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {user.name} {user.surname}
                                  </span>
                                  <div className="d-flex align-items-center">
                                    <div
                                      style={{
                                        width: 8,
                                        height: 8,
                                        borderRadius: "50%",
                                        marginRight: 8,
                                        background:
                                          rolesColor[user.userRole.id],
                                      }}
                                    />
                                    <span className="t4">
                                      {user?.roleTitle}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          {user.events?.map((day, i) => {
                            return (
                              <td
                                key={i}
                                className="week-schedular-slot-container"
                                style={{ height: "130px" }}
                              >
                                <BranchItemCell
                                  locumFlow
                                  viewDetails
                                  addShift
                                  onDelete={onDelete}
                                  handleAddShift={handleAddShift}
                                  branchName={branch.name}
                                  branchId={branch.id}
                                  day={{
                                    ...day,
                                    shifts: day?.shifts?.map((d) => ({
                                      ...d,
                                      staff:
                                        user.role === "locum"
                                          ? [
                                              {
                                                email: user.email,
                                                stats: user.stats,
                                                absenceCount: user.absenceCount,
                                                gphcNumber: user.gphcNumber,
                                                id: user.id,
                                                image: user.image,
                                                name: user.name,
                                                phone: user.phone,
                                                role: user.role,
                                                roleTitle: user.roleTitle,
                                                surname: user.surname,
                                              },
                                            ]
                                          : {
                                              email: user.email,
                                              gphcNumber: user.gphcNumber,
                                              stats: user.stats,
                                              absenceCount: user.absenceCount,
                                              id: user.id,
                                              image: user.image,
                                              name: user.name,
                                              phone: user.phone,
                                              role: user.role,
                                              roleTitle: user.roleTitle,
                                              surname: user.surname,
                                            },
                                      roleType: user.userRole,
                                    })),
                                  }}
                                  operatingTime={operatingTime}
                                />
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
