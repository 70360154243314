/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */

import { useMemo } from "react";
import CustomImage from "./CustomImage";

const PdfIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-label="PDF"
    role="img"
    viewBox="0 0 512 512"
  >
    <rect
      width="512"
      height="512"
      rx="15%"
      fill="#c80a0a"
    />
    <path
      fill="#ffffff"
      d="M413 302c-9-10-29-15-56-15-16 0-33 2-53 5a252 252 0 0 1-52-69c10-30 17-59 17-81 0-17-6-44-30-44-7 0-13 4-17 10-10 18-6 58 13 100a898 898 0 0 1-50 117c-53 22-88 46-91 65-2 9 4 24 25 24 31 0 65-45 91-91a626 626 0 0 1 92-24c38 33 71 38 87 38 32 0 35-23 24-35zM227 111c8-12 26-8 26 16 0 16-5 42-15 72-18-42-18-75-11-88zM100 391c3-16 33-38 80-57-26 44-52 72-68 72-10 0-13-9-12-15zm197-98a574 574 0 0 0-83 22 453 453 0 0 0 36-84 327 327 0 0 0 47 62zm13 4c32-5 59-4 71-2 29 6 19 41-13 33-23-5-42-18-58-31z"
    />
  </svg>
);

const DocumentIcon = () => (
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 1024 1024"
    className="icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M719.8 651.8m-10 0a10 10 0 1 0 20 0 10 10 0 1 0-20 0Z"
      fill="#E73B37"
    />
    <path
      d="M512.1 64H172v896h680V385.6L512.1 64z m278.8 324.3h-280v-265l280 265zM808 916H216V108h278.6l0.2 0.2v296.2h312.9l0.2 0.2V916z"
      fill="#39393A"
    />
    <path
      d="M280.5 530h325.9v16H280.5z"
      fill="#39393A"
    />
    <path
      d="M639.5 530h90.2v16h-90.2z"
      fill="#E73B37"
    />
    <path
      d="M403.5 641.8h277v16h-277z"
      fill="#39393A"
    />
    <path
      d="M280.6 641.8h91.2v16h-91.2z"
      fill="#E73B37"
    />
    <path
      d="M279.9 753.7h326.5v16H279.9z"
      fill="#39393A"
    />
    <path
      d="M655.8 753.7h73.9v16h-73.9z"
      fill="#E73B37"
    />
  </svg>
);

const RenderFileIcon = ({ filePath }) => {
  const renderIcon = useMemo(() => {
    if (!filePath) {
      return (
        <a
          href={filePath}
          target="_blank"
          rel="noreferrer"
        >
          <PdfIcon />
        </a>
      );
    }

    const extension = filePath
      .split(".")
      .pop()
      .toLowerCase();

    if (extension === "pdf") {
      return (
        <a
          href={filePath}
          target="_blank"
          rel="noreferrer"
          style={{
            height: "50px",
            width: "50px",
          }}
        >
          <PdfIcon />
        </a>
      );
    } else if (
      ["doc", "docx", "xls", "xlsx"].includes(
        extension
      )
    ) {
      return <DocumentIcon />;
    } else if (
      ["jpg", "jpeg", "png", "gif"].includes(
        extension
      )
    ) {
      return (
        <CustomImage
          src={filePath}
          style={{
            width: "50px",
            height: "50px",
            objectFit: "cover",
            borderRadius: "5px",
          }}
        />
      );
    } else {
      return (
        <CustomImage
          src={filePath}
          style={{
            width: "50px",
            height: "50px",
            objectFit: "cover",
            borderRadius: "5px",
          }}
        />
      );
    }
  }, [filePath]);

  return (
    <div
      style={{
        width: "50px",
        height: "50px",
        objectFit: "cover",
        borderRadius: "5px",
      }}
    >
      {renderIcon}
    </div>
  );
};

export default RenderFileIcon;
