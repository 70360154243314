import { groupBy, mapValues, omit } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Heading } from "../../../components/UI/heading";
import { getPendingJobDetailThunk } from "../../../redux/branches/branchesThunk";
import {
  exportGapsCSVThunk,
  getPharmacyDashboardDetailThunk,
  getPharmacyGapsDataThunk,
} from "../../../redux/dashboard/dashboardThunk";

import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Popover,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import ArrowrightIcon from "../../../assets/icons/arrow-right.png";
import ArrowLeftIcon from "../../../assets/icons/back.png";
import TotalExpenceChart from "../../../components/DashboardNew/Charts/TotalExpenceChart";
import TotalStaffChart from "../../../components/DashboardNew/Charts/TotalStaffChart";
import CommonDataTab from "../../../components/DashboardNew/CommonDataTab";
import DashboardChartcontainer from "../../../components/DashboardNew/DashboardChartcontainer";
import DashboardTableContainer from "../../../components/DashboardNew/DashboardTableContainer";
import DashboardCommonTable from "../../../components/DashboardNew/Tables/DashboardCommonTable";
import CustomIconButton from "../../../components/UI/IconButton/IconButton";
import AddIcon from "../../../components/UI/icons/AddIcon";
import BriefCaseIcon from "../../../components/UI/icons/BriefCaseIcon";
import BuildingsIcon from "../../../components/UI/icons/BuildingsIcon";
import CalendarIconNormal from "../../../components/UI/icons/CalendarIconNormal";
import ClockIconPurple from "../../../components/UI/icons/ClockIconPurple";
import CloseIcon from "../../../components/UI/icons/CloseIcon";
import DocumentDownloadBtn from "../../../components/UI/icons/DocumentDownloadBtn";
import DropdownIcon from "../../../components/UI/icons/DropdownIcon";
import InfoCircleIcon from "../../../components/UI/icons/InfoCircleIcon";
import UserTagIcon from "../../../components/UI/icons/UserTagIcon";
import CommonModal from "../../../components/UI/modals/CommonModal/CommonModal";
import { ShiftJobFormNew } from "../../../components/comon/AddShiftFormNew";

import {
  getAccommodationAndFoodRulesThunk,
  getMileageRulesThunk,
  getParkingRulesThunk,
  getTransportRulesThunk,
} from "../../../redux/profile/profileThunk";
import { getRegionsListDropDownThunk } from "../../../redux/regions/regionsThunk";

import NoExpense from "../../../components/EmptyStates/NoExpence/NoExpence";
import NoPendingJob from "../../../components/EmptyStates/NoPendingJob/NoPendingJob";
import NoPharmacyGap from "../../../components/EmptyStates/NoPharmacyGaps/NoPharmacyGaps";
import NoStaff from "../../../components/EmptyStates/NoStaff/Nostaff";
import {
  getRolesThunk,
  getStaffsThunk,
} from "../../../redux/schedule/scheduleThunk";
import {
  branchApiKeys,
  regionApiKeys,
  settingsApiKeys,
  staffApiKeys,
} from "../../../utils/apiKeyConstants";
import { DASHBOARD_DETAILS_DATA } from "../../../utils/constant";
import { hasAccess } from "../../../utils/helper";
import "./style.scss";

const { RangePicker } = DatePicker;

dayjs.extend(isoWeek);

const FilterPopover = ({
  handlePopover,
  setFlag,
  setDateRange,
  filterDateRange,
  setFilterDateRange,
}) => {
  return (
    <div className="filter-popup-wrapper">
      <div className="popup-header modal-header">
        <Typography.Title level={5} className="modal-title">
          Filter
        </Typography.Title>
        <CustomIconButton icon={<CloseIcon />} onClick={handlePopover} />
      </div>

      <div className="export-gap-modal popup-body">
        <div className="range-picker-wrap filter-modal">
          <Form.Item label="Start Date" name="filterDateRange" colon={false}>
            <RangePicker
              // disabled={gapData}
              // disabledDate={data && disabledDate}
              // onCalendarChange={(val) => {
              //   setDates(val);
              // }}
              // value={dates}
              // onOpenChange={data && onOpenChange}
              value={[
                filterDateRange?.startDateRange,
                filterDateRange?.endDateRange,
              ]}
              onChange={(value) => {
                setFilterDateRange({
                  startDateRange: value[0],
                  endDateRange: value[1],
                });
              }}
              allowClear={false}
              inputReadOnly
              className="text-input-field px-3 t1 picker"
              popupStyle={{ zIndex: 1000000 }}
              // // format={customFormat}
              superNextIcon={null}
              superPrevIcon={null}
              suffixIcon={null}
              prevIcon={<img src={ArrowLeftIcon} alt="calendar" />}
              nextIcon={<img src={ArrowrightIcon} alt="calendar" />}
              separator={null}
              // renderExtraFooter={CalenderPopupFooter}
            />
          </Form.Item>
        </div>
      </div>
      <div className="popup-footer modal-footer">
        <div className="b1 d-flex justify-content-between align-items-center add-shift-footer gap-3">
          <Button
            className="p-2 btn-style secondary-btn"
            block
            onClick={handlePopover}
          >
            Cancel
          </Button>

          <Button
            className="p-2 btn-style primary-btn"
            type="primary"
            htmlType="submit"
            block
            onClick={() => {
              setDateRange(filterDateRange);
              setFlag(true);
              handlePopover();
            }}
          >
            Apply Filter
          </Button>
        </div>
      </div>
    </div>
  );
};

export const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showAddShiftModal, setShowAddShiftModal] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const [openPopover, setOpenPopover] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDateRange: dayjs().startOf("isoWeek"),
    endDateRange: dayjs().endOf("isoWeek"),
  });
  const [dateRangeForgap, setDateRangeForgap] = useState();
  const [filterDateRange, setFilterDateRange] = useState();

  const [gapData, setGapData] = useState();
  const [flag, setFlag] = useState(true);
  const [showExportModal, setshowExportModal] = useState(false);
  const [showFilterModal, setshowFilterModal] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setisLoading(false);
    }, 2000);
  }, []);

  const handleShowExportModal = () => {
    setshowExportModal(!showExportModal);
  };
  const handleShowFilterModaL = () => {
    setshowFilterModal(!showFilterModal);
  };

  const { pharmacyDashboardData, pharamcyGapsData, pendingJobApprovalData } =
    useSelector((state) => state.dashboard);

  const DashboardHeaderData = useMemo(() => {
    const { pharmacy_jobs, pharmacy_staff, activeLocums, branches } =
      pharmacyDashboardData || {};

    const activeJobs =
      pharmacy_jobs?.find((val) => val.status === "active")?.total_jobs || 0;
    const pendingJobs =
      pharmacy_jobs?.find((val) => val.status === "pending")?.total_jobs || 0;

    const staffs = {
      pharmacists: pharmacy_staff?.total_staff || 0,
      locum: activeLocums?.total_locums || 0,
    };

    const locumPharmacist = activeLocums?.locum_jobs?.filter(
      (item) => item?.roleId === 4
    );
    const locumtechnician = activeLocums?.locum_jobs?.filter(
      (item) => item?.roleId === 5
    );

    return {
      activeJobs,
      pendingInvoices: 0,
      pendingJobs,
      staffs,
      branches,
      activeLocums: {
        locumPharmacist: locumPharmacist?.length,
        locumtechnician: locumtechnician?.length,
      },
    };
  }, [pharmacyDashboardData]);

  const pendingJobsColumns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "startDate",
        Cell: ({ row: { original } }) => {
          return moment(original.startDate).format("DD-MM-YYYY");
        },
      },
      {
        Header: "Job ID",
        accessor: "jobId",
        Cell: () => {
          return <p>23534</p>;
        },
      },
      {
        Header: "Branch",
        accessor: "branchTitle",
      },
      {
        Header: "Duration",
        accessor: "startTime",
        Cell: ({ row: { original } }) => {
          return original.startTime + " - " + original.endTime;
        },
      },
      {
        Header: "Total Rate",
        accessor: "hourlyRate",
        Cell: ({ row: { original } }) => {
          return `£${original?.hourlyRate}`;
        },
      },
      {
        Header: "Requests",
        accessor: "requests",
        Cell: ({ row: { original } }) => {
          return `£${original?.hourlyRate}`;
        },
      },
      // {
      //   Header: "",
      //   accessor: "action",
      //   Cell: ({ row: { original } }) => {
      //     if (hasAccess(locumShiftApiKeys.GET_JOB_BY_ID))
      //       return (
      //         <img
      //           style={{ cursor: "pointer" }}
      //           src={actionBtnArr}
      //           alt=""
      //           onClick={() => {
      //             navigate(
      //               generatePath(RouterConfig.branchAdminJobPreview, {
      //                 shiftId: original.id,
      //               })
      //             );
      //           }}
      //         />
      //       );
      //   },
      // },
    ],
    []
  );

  const brancGapColumns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ row: { original } }) => {
          return moment(original.date).format("DD-MM-YYYY") ?? "---";
        },
      },
      {
        Header: "ID",
        accessor: "id",
        Cell: ({ row: { original } }) => {
          return <p>{original.branchId ?? "---"}</p>;
        },
      },
      {
        Header: "Branch",
        accessor: "branchName",
        Cell: ({ row: { original } }) => {
          return <p>{original.branchName ?? "---"}</p>;
        },
      },
      {
        Header: "Region",
        accessor: "branchRegion",
        Cell: ({ row: { original } }) => {
          return <p>{original?.region ?? "---"}</p>;
        },
      },
      {
        Header: "Time Slot",
        accessor: "startTime",
        Cell: ({ row: { original } }) => {
          return (
            original.startTime ?? "---" + " - " + original.endTime ?? "---"
          );
        },
      },

      {
        Header: "",
        accessor: "action",

        Cell: ({ row: { original } }) => {
          if (
            hasAccess(branchApiKeys.CREATE_BULK_JOB) ||
            hasAccess(branchApiKeys.SAVE_SHIFT_BULK)
          )
            return (
              <div className="d-flex justify-content-center">
                <CustomIconButton
                  icon={<AddIcon />}
                  onClick={() => {
                    setGapData(original);
                    setShowAddShiftModal(!showAddShiftModal);
                  }}
                />
              </div>
            );
          else return <></>;
        },
      },
    ],
    []
  );

  const pharmacyExpenses = useMemo(() => {
    const expenses = pharmacyDashboardData?.pharmacy_expenses;

    if (!expenses || expenses.length === 0) {
      return { totalExpense: 0, highestExpense: 0 };
    }

    const totalExpense = expenses.reduce((sum, item) => sum + item.total, 0);

    const highestExpense = expenses.reduce(
      (maxExpensePharmacy, currentPharmacy) => {
        return currentPharmacy.total > maxExpensePharmacy.total
          ? currentPharmacy
          : maxExpensePharmacy;
      },
      expenses[0]
    );

    return {
      totalExpense,
      highestExpense: highestExpense.total,
    };
  }, [pharmacyDashboardData?.pharmacy_expenses]);

  const { rows: pendingJobsTableData } = useMemo(
    () => pendingJobApprovalData,
    [pendingJobApprovalData]
  );

  useEffect(() => {
    var grouped = mapValues(
      groupBy(pharmacyDashboardData?.pharmacy_staff?.staff_list, "role"),
      (staffList) => staffList.map((car) => omit(car, "role"))
    );
    setStaffData(Object.values(grouped));
  }, [pharmacyDashboardData]);

  useEffect(() => {
    if (!dateRange || !flag) return;
    dispatch(
      getPharmacyDashboardDetailThunk({
        startDate: dayjs(dateRange?.startDateRange).format("YYYY-MM-DD"),
        endDate: dayjs(dateRange?.endDateRange).format("YYYY-MM-DD"),
      })
    );

    if (hasAccess(branchApiKeys.PHARMACY_GAPS))
      dispatch(
        getPharmacyGapsDataThunk({
          startDateRange: dayjs(dateRange?.startDateRange).format("YYYY-MM-DD"),
          endDateRange: dayjs(dateRange?.endDateRange).format("YYYY-MM-DD"),
        })
      );
    dispatch(
      getPendingJobDetailThunk({
        filters: {
          status: "pending",
          startDate: dayjs(dateRange?.startDateRange).format("YYYY-MM-DD"),
          endDate: dayjs(dateRange?.endDateRange).format("YYYY-MM-DD"),
        },
      })
    );
    setFlag(false);
  }, [dateRange, dispatch, flag]);

  useEffect(() => {
    if (hasAccess(settingsApiKeys.GET_SETTINGS_ALL_MILEAGE_RULES))
      dispatch(getMileageRulesThunk());
    if (hasAccess(settingsApiKeys.GET_SETTINGS_PARKING_RULES))
      dispatch(getParkingRulesThunk());
    if (hasAccess(settingsApiKeys.GET_ALL_TRANSPORT_RULES))
      dispatch(getTransportRulesThunk());
    if (hasAccess(settingsApiKeys.GET_ALL_FOOD_RULES))
      dispatch(getAccommodationAndFoodRulesThunk());
    if (hasAccess(regionApiKeys.GET_REGION_DROP_DOWN_LIST))
      dispatch(getRegionsListDropDownThunk());
    dispatch(
      getRolesThunk({
        group: ["staff", "locum"],
        type: "Default",
      })
    );
    if (hasAccess(staffApiKeys.GET_ALL_STAFF)) dispatch(getStaffsThunk());
  }, [dispatch]);

  const downloadGapsCSV = () => {
    dispatch(
      exportGapsCSVThunk({
        startDateRange: dateRangeForgap?.startDateRange.format("YYYY-MM-DD"),
        endDateRange: dateRangeForgap?.endDateRange.format("YYYY-MM-DD"),
      })
    ).then((res) => {
      if (res.payload.status === "success") {
        let fetchDataModified = `data:application/csv;base64,${res.payload.data}`;
        let a = document.createElement("a");
        a.href = fetchDataModified;
        a.download = "result.csv";
        a.click();
        return;
      }
    });
  };

  const handlePopover = () => {
    setOpenPopover(!openPopover);
  };

  useEffect(() => {
    openPopover &&
      setFilterDateRange({
        startDateRange: dateRange?.startDateRange,
        endDateRange: dateRange?.endDateRange,
      });
  }, [openPopover]);

  return (
    <div className="content-view-page details-page">
      <div className="main-wrapper">
        <div className="dashboard-page">
          <Heading
            label="Dashboard"
            dateFilter
            lastupated={"10 min"}
            filterChildren={
              <Popover
                content={
                  <FilterPopover
                    setFilterDateRange={setFilterDateRange}
                    filterDateRange={filterDateRange}
                    handlePopover={handlePopover}
                    setFlag={setFlag}
                    setDateRange={setDateRange}
                    key={openPopover}
                  />
                }
                title=""
                trigger="click"
                showArrow={false}
                placement="bottomLeft"
                open={openPopover}
                onOpenChange={(v) => setOpenPopover(v)}
              >
                <Button
                  className="date-filter-button"
                  // onClick={handleShowFilterModaL}
                >
                  <Space>
                    <i>
                      <CalendarIconNormal />
                    </i>
                    <p>
                      {`${dayjs(dateRange?.startDateRange).format(
                        "DD MMM, YYYY"
                      )} - 
                     ${dayjs(dateRange?.endDateRange).format("DD MMM, YYYY")}`}
                    </p>

                    <i>
                      <DropdownIcon />
                    </i>
                  </Space>
                </Button>
              </Popover>
            }
            view="weekly"
          />

          <div className="content-container-dashboard p-4">
            <div
              className="d-flex"
              style={{ flex: 1, gap: "15px", margin: "20px 0px" }}
            >
              <div
                className="d-flex "
                style={{
                  flex: 1,
                  gap: "15px",
                  justifyContent: "space-between",
                }}
              >
                {/* Active Jobs */}
                <CommonDataTab
                  Title={"Active Jobs"}
                  icon={<BriefCaseIcon />}
                  iconBackground={"#E1F4F4"}
                  loading={isLoading}
                >
                  <Space size={16}>
                    <Typography.Title level={4}>
                      {DashboardHeaderData?.activeJobs}
                    </Typography.Title>
                    {/* <CustomTag
                      text="23%" // this needs to be dynamic
                      icon={<UpArrowIcon />}
                      color="success"
                    /> */}
                  </Space>
                </CommonDataTab>

                {/* Pending Job Approvals */}
                <CommonDataTab
                  Title={"Pending Job Approvals"}
                  icon={<ClockIconPurple />}
                  iconBackground={"#F4EBFF"}
                  loading={isLoading}
                >
                  <Typography.Title level={4}>
                    {DashboardHeaderData?.pendingJobs}
                  </Typography.Title>
                </CommonDataTab>

                {/* Pending Invoices */}
                {/* <CommonDataTab
                  Title={"Pending Invoices"}
                  icon={<RecieptIcon />}
                  iconBackground={"#FFF3E0"}
                  loading={isLoading}
                >
                  <Typography.Title level={4}>
                    {" "}
                    {DashboardHeaderData?.pendingInvoices}
                  </Typography.Title>
                </CommonDataTab> */}

                {/* Active Locums */}
                <CommonDataTab
                  Title={"Active Locums"}
                  icon={<UserTagIcon />}
                  iconBackground={"#E2E4EC"}
                  loading={isLoading}
                >
                  <Space className="locum-card-space-items">
                    <div className="separated-item">
                      <Typography.Title level={4}>
                        {DashboardHeaderData?.activeLocums?.locumPharmacist ||
                          0}
                      </Typography.Title>
                      <p>Pharmacists</p>
                    </div>
                    <Divider type="vertical" style={{ height: "100%" }} />
                    <div className="separated-item">
                      <Typography.Title level={4}>
                        {DashboardHeaderData?.activeLocums?.locumtechnician ||
                          0}
                      </Typography.Title>
                      <p>Technician</p>
                    </div>
                  </Space>
                </CommonDataTab>

                {/* Active Branches */}
                <CommonDataTab
                  Title={"Active Branches"}
                  icon={<BuildingsIcon />}
                  iconBackground={"#E3F2FD"}
                  loading={isLoading}
                >
                  <Space className="locum-card-space-items">
                    <div className="separated-item">
                      <Typography.Title level={4}>
                        {DashboardHeaderData?.branches?.open ?? 0}
                      </Typography.Title>
                      <p>Open</p>
                    </div>
                    <Divider type="vertical" style={{ height: "100%" }} />
                    <div className="separated-item">
                      <Typography.Title level={4}>
                        {DashboardHeaderData?.branches?.closed ?? 0}
                      </Typography.Title>
                      <p>Close</p>
                    </div>
                  </Space>
                </CommonDataTab>
              </div>
            </div>

            <Row gutter={[24, 24]}>
              <Col lg={16}>
                <Row gutter={[24, 24]}>
                  {/** Pharmacy Gaps */}
                  <Col span={24}>
                    <DashboardTableContainer
                      title={`Pharmacy Gaps : ${pharamcyGapsData?.length}`}
                      loading={isLoading}
                      tableRight={
                        <Space size={1}>
                          <Tooltip title="Export CSV">
                            <CustomIconButton
                              icon={<DocumentDownloadBtn />}
                              onClick={handleShowExportModal}
                            />{" "}
                          </Tooltip>
                          <Divider type="vertical" />{" "}
                          <Tooltip title={DASHBOARD_DETAILS_DATA.pharmacy_gap}>
                            {" "}
                            <CustomIconButton icon={<InfoCircleIcon />} />
                          </Tooltip>
                        </Space>
                      }
                    >
                      {pharamcyGapsData?.length > 0 ? (
                        <DashboardCommonTable
                          tableCols={brancGapColumns}
                          data={pharamcyGapsData}
                        />
                      ) : (
                        <NoPharmacyGap text="No Pharmacy Gaps" />
                      )}
                    </DashboardTableContainer>
                  </Col>

                  {/* Pending Job Approvals */}
                  <Col lg={24}>
                    <DashboardTableContainer
                      title={`Pending Job Approvals : ${pendingJobsTableData?.length}`}
                      loading={isLoading}
                      tableRight={
                        <Tooltip title={DASHBOARD_DETAILS_DATA.pending_jobs}>
                          {" "}
                          <CustomIconButton icon={<InfoCircleIcon />} />
                        </Tooltip>
                      }
                    >
                      {pendingJobsTableData?.length > 0 ? (
                        <DashboardCommonTable
                          tableCols={pendingJobsColumns}
                          data={pendingJobsTableData}
                        />
                      ) : (
                        <NoPendingJob />
                      )}
                    </DashboardTableContainer>
                  </Col>
                </Row>
              </Col>

              <Col lg={8}>
                <Row gutter={[24, 24]}>
                  {/* Total Expense */}
                  <Col lg={24}>
                    <DashboardChartcontainer
                      title="Total Expense"
                      loading={isLoading}
                      subtitle={`£${Number(
                        pharmacyExpenses.totalExpense
                      ).toFixed(2)}`}
                    >
                      {pharmacyDashboardData?.pharmacy_expenses?.length > 0 ? (
                        <div className="total-expence-chart-wrap">
                          <ul>
                            {pharmacyDashboardData?.pharmacy_expenses?.map(
                              (expense, index) => (
                                <TotalExpenceChart
                                  key={index}
                                  count={index + 1}
                                  branch={expense.branchTitle}
                                  branchExpense={expense.total}
                                  highestExpense={
                                    pharmacyExpenses.highestExpense
                                  }
                                />
                              )
                            )}
                          </ul>
                        </div>
                      ) : (
                        <NoExpense />
                      )}
                    </DashboardChartcontainer>
                  </Col>

                  {/* Total Staff */}
                  <Col lg={24}>
                    <DashboardChartcontainer
                      loading={isLoading}
                      title="Total Staff"
                      subtitle={
                        pharmacyDashboardData?.pharmacy_staff?.total_staff +
                        pharmacyDashboardData?.activeLocums?.total_locums
                      }
                      withChart
                    >
                      {pharmacyDashboardData?.pharmacy_staff?.total_staff +
                        pharmacyDashboardData?.activeLocums?.total_locums >
                      0 ? (
                        <TotalStaffChart
                          totalBranches={
                            pharmacyDashboardData?.branches?.total_branches
                          }
                          item1={{
                            name: "Pharmacists",
                            value:
                              pharmacyDashboardData?.pharmacy_staff
                                ?.total_staff,
                          }}
                          item2={{
                            name: "Locum",
                            value:
                              pharmacyDashboardData?.activeLocums?.total_locums,
                          }}
                        />
                      ) : (
                        <NoStaff subtext="No Employees" />
                      )}
                    </DashboardChartcontainer>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {/* FOR ADD Staff Shift & Locum Job FORM */}
      {showAddShiftModal && (
        <ShiftJobFormNew
          isGap={true}
          gapData={gapData}
          onSuccess={() => setFlag(true)}
          branchId={gapData?.branchId}
          selectedDate={gapData?.date}
          showModal={showAddShiftModal}
          setHandleModal={() => setShowAddShiftModal(!showAddShiftModal)}
          operatingTime={gapData?.branchTimings}
        />
      )}

      {/* Export Gaps Modal */}
      <CommonModal
        handleModal={showExportModal}
        setHandleModal={handleShowExportModal}
        title="Export Gaps"
        secondaryButtonText="Cancel"
        primaryButtonText="Export"
        primaryButtonHandler={downloadGapsCSV}
        secondaryButtonHandler={handleShowExportModal}
      >
        <div className="export-gap-modal">
          <Typography.Text>
            Select a starting and ending date for the pharmacy gaps you just
            created.
          </Typography.Text>

          <div className="range-picker-wrap">
            <Form.Item label="Start Date" name="dateRange" colon={false}>
              <RangePicker
                value={[
                  dateRangeForgap?.startDateRange,
                  dateRangeForgap?.endDateRange,
                ]}
                onChange={(value) => {
                  setDateRangeForgap({
                    startDateRange: value[0],
                    endDateRange: value[1],
                  });
                  // setFlag(true);
                }}
                // onOpenChange={data && onOpenChange}

                allowClear={false}
                inputReadOnly
                className="text-input-field px-3 t1 picker"
                popupStyle={{ zIndex: 1000000 }}
                // // format={customFormat}
                superNextIcon={null}
                superPrevIcon={null}
                suffixIcon={null}
                prevIcon={<img src={ArrowLeftIcon} alt="calendar" />}
                nextIcon={<img src={ArrowrightIcon} alt="calendar" />}
                separator={null}
                // renderExtraFooter={CalenderPopupFooter}
              />
            </Form.Item>
          </div>
        </div>
      </CommonModal>

      {/* Filter Modal */}
      {/* <CommonModal
        handleModal={showFilterModal}
        setHandleModal={handleShowFilterModaL}
        title="Filter"
        secondaryButtonText="Cancel"
        primaryButtonText="Apply Filter"
        primaryButtonHandler={downloadGapsCSV}
        secondaryButtonHandler={handleShowExportModal}
      >
        <div className="export-gap-modal">
          <div className="range-picker-wrap filter-modal">
            <Form.Item label="Start Date" name="dateRange" colon={false}>
              <RangePicker
                // disabled={gapData}
                // disabledDate={data && disabledDate}
                // onCalendarChange={(val) => {
                //   setDates(val);
                // }}
                // value={dates}
                // onOpenChange={data && onOpenChange}
                value={[dateRange?.startDateRange, dateRange?.endDateRange]}
                onChange={(value) => {
                  setDateRange({
                    startDateRange: value[0],
                    endDateRange: value[1],
                  });
                  setFlag(true);
                }}
                allowClear={false}
                inputReadOnly
                className="text-input-field px-3 t1 picker"
                popupStyle={{ zIndex: 1000000 }}
                // // format={customFormat}
                superNextIcon={null}
                superPrevIcon={null}
                suffixIcon={null}
                prevIcon={<img src={ArrowLeftIcon} alt="calendar" />}
                nextIcon={<img src={ArrowrightIcon} alt="calendar" />}
                separator={null}
                // renderExtraFooter={CalenderPopupFooter}
              />
            </Form.Item>
          </div>
        </div>
      </CommonModal> */}
    </div>
  );
};
