import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, Outlet, useParams } from "react-router-dom";
import { BranchCard } from "../../../../components/UI/cards/profileCard";
import { ContentContainer } from "../../../../components/UI/container";
import { Sidebar } from "../../../../components/UI/sideBar";
import { getPharmacyInformation } from "../../../../redux/super-admin/superAdminAPI";
import { RouterConfig } from "../../../../routerConfig";
import "./style.scss";

export const BranchDetails = () => {
  const { id } = useParams();
  const [pharmacyInformation, setPharmacyInformation] = useState("");

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await getPharmacyInformation(id);
      setPharmacyInformation(response?.data?.data);
    }
    fetchData();
  }, [id]);
  const sidebarLinks = useMemo(
    () => [
      {
        text: "Information",
        to: generatePath(RouterConfig.superAdminPharmacyInformation, { id }),
        disabled: false,
        hidden: false,
      },
      {
        text: "Branches",
        to: generatePath(RouterConfig.superAdminPharmacyBranches, { id }),
        disabled: false,
        hidden: false,
      },
      {
        text: "Staff",
        to: generatePath(RouterConfig.superAdminPharmacyStaff, { id }),
        disabled: false,
        hidden: false,
      },
      {
        text: "Jobs",
        to: generatePath(RouterConfig.superAdminPharmacyJobs, { id }),
        disabled: false,
        hidden: false,
      },
      {
        text: "Subsciption & Billing",
        to: generatePath(RouterConfig.superAdminPharmacyBilling, { id }),
        disabled: false,
        hidden: false,
      },
    ],
    [id]
  );
  return (
    <div className="content-view-page details-page">
      <div className="main-wrapper p-5">
        <div className="content-holder">
          <Sidebar links={sidebarLinks} visible>
            <BranchCard
              logo={pharmacyInformation.image}
              primaryText={`${pharmacyInformation.name} ${pharmacyInformation?.surname}`}
              secondaryText={pharmacyInformation.label}
            />
          </Sidebar>
          <ContentContainer className="content-container">
            <Outlet />
          </ContentContainer>
        </div>
      </div>
    </div>
  );
};
