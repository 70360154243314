
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { editVerificationThunk, getStaffVerificationThunk } from "../../../../../redux/staffs/staffsThunk";
import "./style.scss";
import { AddModal } from "../../../../../components/UI/modals/addModal";
import { Verification } from "../../../../../components/verifications";
import { VerificationForm } from "../../../../../components/verifications/verificationForm";

export const StaffVerification = () => {
  const formId = useMemo(() => "form", [])
  const dispatch = useDispatch()
  const { staffId } = useParams()
  const { staffVerification } = useSelector(state => state.staffs)
  const [showEditInfoModal, setShowEditInfoModal] = useState(false);
  const [actionType, setActionType] = useState('basic')
  const { staffInfo } = useSelector((state) => state.staffs)

  useEffect(() => {
    dispatch(getStaffVerificationThunk({ staffId }));
  }, [dispatch, staffId]);

  const onSubmit = (formData) => {
    dispatch(
      editVerificationThunk({
        staffId,
        payload: formData
      })
    ).then(res => {
      if (res.payload) {
        setShowEditInfoModal(false)
        setActionType('basic')
      }
    })
  }

  return (
    <>
      <Verification
        showEdit={staffInfo.role !== 'locum'}
        staffInfo={staffInfo}
        setShowEditInfoModal={setShowEditInfoModal}
        setActionType={setActionType}
        staffVerification={staffVerification}
      />
      <AddModal
        title='Edit Verification'
        handleModal={showEditInfoModal}
        setHandleModal={setShowEditInfoModal}
        formId={formId}
        primaryButtonText="Update"
      >
        <VerificationForm
          isGrouped
          staffInfo={staffInfo}
          onSubmit={onSubmit}
          formId={formId}
          setShowEditInfoModal={setShowEditInfoModal}
          setActionType={setActionType}
          actionType={actionType}
        />
      </AddModal>
    </>
  );
};
