import { Button, Form, Input, Rate, Typography } from 'antd';
import React, { useMemo } from 'react';

const GiveRatingCard = ({ handleRating }) => {
  const [form] = Form.useForm();
  const formId = useMemo(() => 'jobShiftForm', []);

  return (
    <div className="rating-card">
      <Typography.Title level={5}>Give Ratings</Typography.Title>

      <Form
        form={form}
        id={formId}
        onFinish={(value) => handleRating(value)}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          label="Rate Us"
          name="value"
          className="rating-form-item "
          style={{ fontWeight: '500', color: '#000' }}
          rules={[
            {
              required: true,
              message: "You'll Have To Enter Rate To Proceed!",
            },
          ]}
        >
          <Rate className="rating-star" />
        </Form.Item>

        <Form.Item
          label="Feedback"
          name="feedback"
          className="rating-form-item"
          style={{ fontWeight: '500', color: '#000' }}
        >
          <Input.TextArea
            className="px-3 py-2 t1"
            rows={3}
            maxLength={256}
            name="description"
            placeholder="Write your job feedback here..."
          />
        </Form.Item>

        <button
          className="custom_btn outlined w-100 d-flex justify-content-center"
          htmlType="submit"
        >
          Write a Review
        </button>
      </Form>
    </div>
  );
};

export default GiveRatingCard;
