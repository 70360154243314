import React from "react";
export const ProfileInfo = (props) => {
  return (
    <div className=" d-flex align-items-center gap-3 profile-row">
      <span className="info-heading p3" style={{ whiteSpace: "nowrap" }}>
        {props.label}{" "}
      </span>
      <span
        className="p3"
        style={{
          color: props.color,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {props.value}{" "}
      </span>
    </div>
  );
};
