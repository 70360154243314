import { Route, Routes } from "react-router-dom";
import { JobOffersListPage } from "./jobOffersList";

export const JobOffersPage = () => {
  return (
    <Routes>
      <Route path={`/`} element={<JobOffersListPage />} />
    </Routes>
  );
};
