import React, { useMemo } from "react";
import "./style.scss";
import { FilterComponent } from "../../../../components/Filter";
import { Form, Select } from "antd";
const STATUS = [
  "onboarding",
  "pending",
  "active",
  "new",
  "rejected",
  "deactivated",
];
export const UserFilterComponent = ({ regions, onApplyFilter }) => {
  const [form] = Form.useForm();
  const formId = useMemo(() => "filter-form", []);

  const clearFilterFormHandler = () => {
    form.resetFields();
    onApplyFilter();
  };

  const onSubmit = (values) => {
    onApplyFilter(values);
  };
  return (
    <FilterComponent
      formId={formId}
      onApplyFilter={form.submit}
      clearFormHandler={form.isFieldsTouched() && clearFilterFormHandler}
    >
      <Form
        id={formId}
        onFinish={onSubmit}
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item label="Status" name="status">
          <Select
            dropdownStyle={{ zIndex: 2000 }}
            placeholder="Status"
            allowClear
            bordered={false}
            className="text-input-field p-1 t1"
          >
            {STATUS.map((status) => (
              <Select.Option value={status}>{status}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </FilterComponent>
  );
};
