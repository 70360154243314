import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import { RouterConfig } from "../../routerConfig";
import { AppleAppAssosiation } from "../appleAppAssosiation";
import { ErrorPage } from "../errorPage/ErrorPage";
import Nostates from "../nostates";
import { PrivacyPolicy } from "../privacyPolicy/privacyPolicy";
import { LoginPageNew } from "./login-new";
import { SignUpPageNew } from "./signup-new";
export const Authentication = (props) => {
  return (
    <Routes>
      {/* <Route path={RouterConfig.login}>
        <Route path=":token" element={<LoginPage />} exact />
        <Route path="" element={<LoginPage />} exact />
      </Route> */}
      <Route path={RouterConfig.login}>
        <Route path=":token" element={<LoginPageNew />} exact />
        <Route path="" element={<LoginPageNew />} exact />
      </Route>
      {/* <Route path={RouterConfig.signup} element={<SignUpPage />} exact /> */}
      <Route path={RouterConfig.signup} element={<SignUpPageNew />} exact />

      <Route
        path={RouterConfig.privacyPolicy}
        element={<PrivacyPolicy />}
        exact
      />
      <Route path={RouterConfig.pageNotFound} element={<ErrorPage />} exact />
      <Route
        path={RouterConfig.appleAppSiteAssosiation}
        element={<AppleAppAssosiation />}
        exact
      />
      <Route path="*" element={<Navigate to={RouterConfig.login} />} />
      <Route path="/nostates-demo" element={<Nostates />} exact />
    </Routes>
  );
};
