import React, { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { TableToolbar } from "../../../../components/UI/table/tableToolBar";
import {
  getAdminRolesThunk,
  getAdminStaffThunk,
  getPharmacyFlowsThunk,
} from "../../../../redux/profile/profileThunk";
import {
  moduleApiKeys,
  userRoleApiKeys,
} from "../../../../utils/apiKeyConstants";
import { hasAccess } from "../../../../utils/helper";
import { AdminRolesTable } from "./roles";
import { AdminStaffTable } from "./staff";
import "./style.scss";

export const AdminStaff = () => {
  const [adminStaffView, setAdminStaffView] = useState("staff");
  const [rerender, setRerender] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showCreateStaffModal, setShowCreateStaffModal] = useState(false);
  const [showCreateRoleModal, setShowCreateRoleModal] = useState(false);
  const [actionType, setActionType] = useState("");
  const dispatch = useDispatch();
  const tabsOptions = useMemo(
    () => [
      {
        label: "Admin Staff",
        value: "staff",
        onClickHandler: () => setAdminStaffView("staff"),
      },
      {
        label: "Admin Roles",
        value: "roles",
        onClickHandler: () => setAdminStaffView("roles"),
      },
    ],
    []
  );

  useEffect(() => {
    if (hasAccess(userRoleApiKeys.GET_PHARMACY_ROLES))
      dispatch(getAdminRolesThunk());
    if (hasAccess(moduleApiKeys.GET_PHARMACY_MODULES))
      dispatch(getPharmacyFlowsThunk());
  }, []);
  const onSearch = (e) => {
    setRerender(true);
  };
  return (
    <div className="admin-staff-tab ">
      <div className="">
        <TableToolbar
          primaryBtnText={adminStaffView === "staff" ? "Add Admin" : "Add Role"}
          showSearchField={true}
          staffTableView={adminStaffView}
          tabsOptions={tabsOptions}
          onSearch={onSearch}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          primaryBtnHandler={() => {
            adminStaffView === "staff"
              ? setShowCreateStaffModal(!showCreateStaffModal)
              : setShowCreateRoleModal(!showCreateRoleModal);

            setActionType("Add");
          }}
          // acceptButton={showMultiSelectBtn}
          // rejectButton={showMultiSelectBtn}
          // setActionType={setActionType}
        />
        {adminStaffView === "staff" && (
          <AdminStaffTable
            rerender={rerender}
            setRerender={setRerender}
            searchQuery={searchQuery}
            actionType={actionType}
            setActionType={setActionType}
            showCreateModal={showCreateStaffModal}
            setShowCreateModal={setShowCreateStaffModal}
          />
        )}
        {adminStaffView === "roles" && (
          <AdminRolesTable
            rerender={rerender}
            setRerender={setRerender}
            searchQuery={searchQuery}
            actionType={actionType}
            setActionType={setActionType}
            showCreateModal={showCreateRoleModal}
            setShowCreateModal={setShowCreateRoleModal}
          />
        )}
      </div>
      <Outlet />
    </div>
  );
};
