import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NoStaff from "../../../../../../components/EmptyStates/NoStaff/Nostaff";
import { ConfirmModal } from "../../../../../../components/UI/modals/confirmModal";
import { TableComponet } from "../../../../../../components/UI/table";
import { TableToolbar } from "../../../../../../components/UI/table/tableToolBar";
import {
  getBranchBlockListThunk,
  unBlockLocumThunk,
} from "../../../../../../redux/branches/branchesThunk";
import { locumApiKeys } from "../../../../../../utils/apiKeyConstants";
import { hasAccess } from "../../../../../../utils/helper";
import { BlockLocumForm } from "./components/blockLocumForm";

export const BranchBlockList = () => {
  const { id } = useParams();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showBlockLocumModal, setShowBlockLocumModal] = useState(false);
  const [showMultiSelectBtn, setShowMultiSelectBtn] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();
  const { branchBlockList } = useSelector((state) => state.branches);
  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "locum.name",
      },

      {
        Header: "Start Date",
        accessor: "startedAt",
        Cell: ({ row: { original } }) => {
          return moment(original.startedAt).format("DD-MM-YYYY");
        },
      },
      {
        Header: "End Date",
        accessor: "endedAt",
        Cell: ({ row: { original } }) => {
          return moment(original.endedAt).format("DD-MM-YYYY");
        },
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row: { original } }) => {
          return (
            <div className="d-flex" style={{ gap: "5px" }}>
              <span
                className="px-4 border rounded-pill py-2"
                style={{
                  backgroundColor: "white",
                  color: "#0BA69B",
                  cursor: "pointer",
                }}
                onClick={() => {
                  const id = [original.id];
                  setSelectedRows(id);
                  setShowConfirmModal(true);
                }}
              >
                Unblock
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  const {
    count: totalDataCount,
    page,
    pages: totalPagesCount,
    perPage,
    rows: blockList,
  } = useMemo(() => branchBlockList, [branchBlockList]);

  useEffect(() => {
    if (!page && !perPage) return;
    setPerPageCount(perPage);
    setCurrentPageCount(page);
  }, [page, perPage]);

  useEffect(() => {
    if (hasAccess(locumApiKeys.LOCUM_BLOCKED_LIST)) {
      dispatch(
        getBranchBlockListThunk({
          id,
          pagination: {
            page: currentPageCount,
            perPage: perPageCount,
          },
        })
      );
    }
  }, [perPageCount, currentPageCount, id, dispatch]);

  const handleConfirmModal = useCallback(
    (med) => {
      setShowConfirmModal(!showConfirmModal);
    },
    [showConfirmModal]
  );
  const onRemoveLocum = () => {
    dispatch(
      unBlockLocumThunk({
        id,
        locumIds: selectedRows,
      })
    ).then((res) => {
      if (res.payload) {
        setShowConfirmModal(false);
        setSelectedRows([]);
        dispatch(
          getBranchBlockListThunk({
            id,
            pagination: {
              page: currentPageCount,
              perPage: perPageCount,
            },
          })
        );
      }
    });
  };

  return (
    <div className="">
      {showConfirmModal && (
        <ConfirmModal
          title={`Do you want to Unblock Locum${
            showMultiSelectBtn ? "s" : ""
          }?`}
          description={`You are about to Unblock ${
            showMultiSelectBtn ? "these" : "this"
          } Locum${showMultiSelectBtn ? "s" : ""}.`}
          open={showConfirmModal}
          confirmButtonText={
            hasAccess(locumApiKeys.LOCUM_UNBLOCK) ? "Ublock" : undefined
          }
          cancelButtonText="Cancel"
          onClose={() => setShowConfirmModal(!showConfirmModal)}
          onConfirm={
            hasAccess(locumApiKeys.LOCUM_UNBLOCK) ? onRemoveLocum : undefined
          }
        />
      )}
      <div>
        <TableToolbar
          headingText="Locum Black List"
          primaryBtnText="Block Locum"
          primaryBtnHandler={() => {
            setShowBlockLocumModal(!showBlockLocumModal);
          }}
          secondaryButtonText={showMultiSelectBtn ? "Unblock" : ""}
          handleConfirmModal={handleConfirmModal}
        />
        <div style={{ padding: "5px 24px" }}>
          {branchBlockList?.rows?.length > 0 ? (
            <TableComponet
              rowCursor="pointer"
              isBorderShown
              setSelectedRows={setSelectedRows}
              currentPageCount={currentPageCount}
              setShowMultiSelectBtn={setShowMultiSelectBtn}
              perPageCount={perPageCount}
              totalDataCount={totalDataCount}
              setCurrentPage={setCurrentPageCount}
              setPerPageCount={setPerPageCount}
              showPagination
              showRowSelection
              maxPageCount={totalPagesCount}
              columns={columns}
              data={blockList || []}
            />
          ) : (
            <div style={{ width: "100%", marginTop: "60px" }}>
              {/* <EmptyData title="Block Employees" />
               */}
              <NoStaff hideButton text="No Block Employees" subText="" />
            </div>
          )}
        </div>
      </div>
      <BlockLocumForm
        branchId={id}
        perPage={perPage}
        page={currentPageCount}
        showBlockLocumModal={showBlockLocumModal}
        setShowBlockLocumModal={setShowBlockLocumModal}
      />
    </div>
  );
};
