export const adminApiKeys = {
  GET_ENV: "getEnv",
  TRANSACTIONS_LIST: "getSubscription",
  TRANSACTIONS_ID: "getTransactionsList",
  INVOICE_LIST: "getInvoiceList",
  UPDATE_ZOHO_REPORT: "updateZohoReport",
  ADMIN_JOBS_LIST: "getJobsList",
  DELETE_PHARMACY_JOBS: "deletePharmacyJobs",
  GET_PHARMACY_LIST: "getPharmacyList",
  GET_PHARMACY: "getPharmacy",
  GET_PHARMACY_BRANCHES_LIST: "getPharmacyBranchesList",
  GET_PHARMACY_STAFF_LIST: "getPharmacyStaffList",
  GET_PHARMACY_JOB_LIST: "getPharmacyJobList",
  SET_PHARMACY_ACTIVE: "setPharmacyActive",
  DEACTIVATE_PHARMACY: "deactivatePharmacy",
  DELETE_PHARMACY: "deletePharmacy",
  DELETE_HARD_PHARMACY: "deleteHardPharmacy",
  GET_PHARMACY_INVOICE_LIST: "getPharmacyInvoiceList",
  GET_PHARMACY_BRANCHES: "getPharmacyBranches",
  GET_PHARMACY_SUBSCRIPTION: "getPharmacySubscription",
  DEACTIVATE_PHARMACY_BRANCH: "deactivatePharmacyBranch",
  UPDATE_PHARMACY_SUBSCRIPTION: "updatePharmacySubscription",
  IMPORT_OLD_USERS: "importOldUsers",
  UPDATE_GPHC_DATA: "updateGphcData",
  GET_INVITE_DATA: "getInviteData",
  GET_USERS_LIST: "getUsersList",
  GET_USER: "getUser",
  APPROVE_USER: "approveUser",
  REJECT_USER: "rejectUser",
  SET_ACTIVE_USER: "setActiveUser",
  DEACTIVATE_USER: "deactivateUser",
  HARD_DELETE_LOCUM: "hardDeleteLocum",
};

export const authApiKeys = {
  AUTH_LOGIN: "authLogin",
  AUTH_LOGOUT: "authLogOut",
  AUTH_REFRESH: "authRefresh",
  SING_UP: "singUp",
  VALIDATE_GPHC: "validateGphc",
  RESEND_ACTIVATION_LINK: "resendActivationLink",
  REQUEST_PASSWORD_RESET: "requestPasswordReset",
  PASSWORD_RESET_BY_TOKEN: "passwordResetByToken",
  PASSWORD_RESET: "passwordReset",
  CHANGE_PASSWORD: "changePassword",
  GET_GPHC_INFO: "getGphcInfo",
};

export const branchApiKeys = {
  BRANCH_DASHBOARD: "branchDashboard",
  GET_BRANCH_DATA: "getBranchData",
  GET_BRANCH_LIST: "getBranchList",
  UPDATE_BRANCH_BY_ID: "updateBranchById",
  CREATE_BRANCH: "createBranch",
  BRANCH_UPDATE_SUBSCRIPTION: "brachUpdateSubscription",
  UPDATE_RATE: "updateRate",
  DELETE_BRANCH_LOGO: "deleteBranchLogo",
  GET_BRANCH: "getBranch",
  DELETE_BRANCH: "deleteBranch",
  GET_BRANCH_STAFF: "getBranchStaff",
  ARCHIVE_BRANCH: "archiveBranch",
  UN_ARCHIVE_BRANCH: "unArchiveBranch",
  DEACTIVATE_BRANCH: "deactivateBranch",
  ACTIVATE_BRANCH: "activateBranch",
  CREATE_DRAFT: "createDraft",
  CREATE_DRAFT_BULK: "createDraftBulk",
  CREATE_UPDATE_DRAFT: "createUpdateDraft",
  DELETE_DRAFT: "deleteDraft",
  PUBLISH_DRAFT: "publishDraft",
  MANAGE_BRANCH_OPERATING_TIME: "manageBranchOperatingTime",
  GET_BRANCH_OPERATING_TIME: "getBranchOperatingTime",
  CREATE_SHIFT: "createShift",
  GET_SHIFT_LIST: "getShiftList",
  SAVE_SHIFT_BULK: "saveShiftBulk",
  CREATE_BULK_JOB: "createBulkJob",
  GET_ALL_BRANCHES_SCHEDULE_LIST: "getAllBranchesScheduleList",
  GET_BRANCH_SCHEDULE_LIST: "getBranchScheduleList",
  ADD_BRANCH_ABSENCE: "addBrachAbsence",
  UPDATE_SHIFT: "updateShift",
  DELETE_SHIFT: "deleteShift",
  GET_BRANCH_TEMPLATE_ACTIONS: "getBranchTemplateActions",
  HANDLE_TEMPLATE_ACTION: "handleTemplateAction",
  PUBLISH_TEMPLATE: "publishTemplate",
  GET_TEMPLATE: "getTemplate",
  GET_TEMPLATE_LIST: "getTemplateList",
  DELETE_TEMPLATE: "deleteTemplate",
  UPDATE_TERM_CONDITIONS: "updateTermConditions",
  BRANCH_GAPS: "branchGaps",
  PHARMACY_GAPS: "pharmacyGaps",
  EXPORT_BRANCH_GAPS_CSV: "exportBranchGapsCsv",
  EXPORT_PHARMACY_GAPS_CSV: "exportPharmacyGapsCsv",
  CHANGE_BRANCH_OPERATING_TIME: "changeBranchOperatingTime",
  SAVE_GAP_SHIFT_BULK: "saveGapShiftBulk",
  GET_ALL_BRANCHES_GAP_LIST: "getAllBranchesGapList",
  REJECT_GAP_APPLICATION: "rejectGapApplication",
  ACCEPT_GAP_APPLICATION: "acceptGapApplication",
};

export const fileApiKeys = {
  DOWNLOAD_FILES: "downloadFiles",
};

export const inviteApiKeys = {
  GET_BASIC_DATA: "getBasicData",
  INVITE_SING_UP: "inviteSingUp",
  VALIDATE_GPHC: "validateGphc",
  CANCEL_GPHC: "cancelGphc",
  GET_INVITE_DATA: "getInviteData",
  CREATE_INVITE: "createInvite",
  GET_SHORT_BRANCHES: "getShortBranches",
  ADD_ADMIN: "addAdmin",
};

export const locumShiftApiKeys = {
  CREATE_JOB: "createJob",
  DELETE_JOB: "deleteJob",
  GET_JOB_LIST: "getJobList",
  GET_MIN_MAX_JOB: "getMinMaxJob",
  DOWNLOAD_CSV: "downloadCsv",
  GET_CANCELLATION_REASON: "getCancellationReason",
  GET_EXTERNAL_LOCUMS_BY_FILTER: "getExternalLocumsByFilter",
  GET_JOB_BY_ID: "getJobById",
  UPDATE_MILAGE_VALUE: "updateMilageValue",
  UPDATE_JOB: "updateJob",
  APPLY_FOR_JOB: "applyForJob",
  CANCEL_JOB: "cancelJob",
  UPDATE_JOB_STATUS: "updateJobStatus",
  APPROVE_LOCUM: "approveLocum",
  REJECT_LOCUM: "rejectLocum",
  ACTIVATE_JOB: "activateJob",
  PAUSE_JOB: "pauseJob",
  INVITE_LOCUM: "inviteLocum",
  ACCEPT_JOB_INVITE: "acceptJobInvite",
  DECLINE_JOB_INVITE: "declineJobInvite",
  DECLINE_LOCUM_INVITE: "declineLocumInvite",
  JOB_REQUIRED_ACCREDITATIONS: "jobRequiredAccreditations",
  GET_JOB_ACCREDITATIONS: "getJobAccreditations",
};

export const locumApiKeys = {
  GET_LOCUM_GPHC: "getLocumGphc",
  GET_LOCUM_SCHEDULE_AVAILABLE: "getLocumScheduleAvailable",
  GET_RATING: "getRating",
  CREATE_UPDATE_RATING: "createUpdateRating",
  POST_RATING: "postRating",
  LOCUM_BLOCK: "locumBlock",
  LOCUM_UNBLOCK: "locumUnBlock",
  LOCUM_BLOCKED_LIST: "locumBlockedlist",
};

export const locumBankApiKeys = {
  GET_GPHC_NUMBER: "getGphcNumber",
  LOCUM_BANK_GET_LIST: "locumBankGetList",
  ADD_LOCUM_BANK: "addLocumBank",
  TOGGLE_ADD_RELEASE: "toggleAddRelease",
};

export const paymentApiKeys = {
  POST_STRIPE_HOOK: "postStripeHook",
  UPDATE_SUBSCRIPTION: "updateSubscription",
  PAYMENT_INTENT: "paymentIntent",
  GET_PAYMENT_METHOD: "getPaymentMethod",
  GET_SUBSCRIPTION_STANDARD_PLAN: "getSubscriptionStandardPlan",
  GET_ADDONS: "getAddons",
  GET_PAYMENT_BRANCHES: "getPaymentBranches",
  GET_CURRENT_PLAN: "getCurrentPlan",
  GET_INVOICES_LIST: "getInvoicesList",
  INVOICES_PAY: "invoicesPay",
  GET_PLANS: "getPlans",
  CREATE_PLAN: "createPlan",
  UPDATE_PLAN: "updatePlan",
  POST_SUBSCRIBE: "postSubscribe",
  CANCEL_SUBSCRIPTION: "cancelSubscription",
  ABORT_CANCELLATION: "abortCancellation",
  ABORT_DOWNGRADE: "abortDowngrade",
};

export const personalApiKeys = {
  GET_PERSON_SCHEDULE: "getPersonSchedule",
  GET_ATTENDANCE_SHIFT: "getAttendanceShift",
  MARK_ATTENDANCE: "markAttendance",
  MANUAL_ATTENDANCE: "manualAttendance",
  ATTENDANCE_LIST: "attendanceList",
  MANUAL_ATTENDANCE_APPROVE: "manualAttendanceApprove",
  GET_ATTENDANCE_STATS: "getAttendanceStats",
  GET_ATTENDANCE_DETAILS: "getAttendanceDetails",
};

export const settingsApiKeys = {
  GET_LOCUM_SETTINGS: "getLocumSettings",
  UPDATE_LOCUM_SETTINGS: "updateLocumSettings",
  GET_SETTINGS_MILEAGE_RULES: "getSettingsMileageRules",
  GET_SETTINGS_ALL_MILEAGE_RULES: "getSettingsAllMileageRules",
  GET_SETTINGS_PARKING_RULES: "getSettingsParkingRules",
  GET_ALL_TRANSPORT_RULES: "getAllTransportRules",
  GET_ALL_FOOD_RULES: "getAllFoodRules",
  SAVE_MILEAGE_RULES: "saveMileageRules",
  SAVE_TRANSPORT_RULES: "saveTransportRules",
  SAVE_PARKING_RULES: "saveParkingRules",
  SAVE_FOOD_RULES: "saveFoodRules",
  DELETE_MILEAGE_RULES: "deleteMileageRules",
  DELETE_TRANSPORT_RULE: "deleteTransportRule",
  DELETE_FOOD_RULES: "deleteFoodRules",
  DELETE_PARKING_RULE: "deleteParkingRule",
  GET_PHARMACY_SETTINGS: "getPharmacySetting",
  UPDATE_PHARMACY_SETTINGS: "updatePharmacySetting",
  GET_ACCREDITATION_RULES: "getAccreditationRules",
  GET_ALL_ACCREDITATION_RULES: "getAllAccreditationRules",
  GET_ACCREDITATION_RULE_BY_ID: "getAccreditationRuleById",
  SAVE_ACCREDITATION_RULES: "saveAccreditationRules",
  DELETE_ACCREDITATION_RULES: "deleteAccreditationRules",
  GET_BANK_HOLIDAYS: "getBankHolidays",
  ADD_BANK_HOLIDAY: "addBankHoliday",
};

export const staffApiKeys = {
  GET_CONTRACT_LIST: "getContractList",
  GET_CONTRACT: "getContract",
  GET_USER_CONTRACT: "getUserContract",
  GET_MIN_CONTRACT_DATE: "getMinContractDate",
  CREATE_CONTRACT: "createContract",
  DUPLICATE_CONTRACT: "duplicateContract",
  DELETE_CONTRACT: "deleteContract",
  UPDATE_CONTRACT: "updateContract",
  START_CONTRACT: "startContract",
  GET_STAFF_LIST: "getStaffList",
  GET_STAFF_ABSENCE: "getStaffAbsence",
  GET_ALL_STAFF: "getAllStaff",
  GET_AVAILABLE_STAFF: "getAvailableStaff",
  GET_STAFF_WORKED_LOCUMS: "getStaffWorkedLocums",
  GET_STAFF_PROFILE: "getStaffProfile",
  GET_STAFF_SKILLS: "getStaffSkills",
  GET_STAFF_ACCREDITATIONS: "getStaffAccreditations",
  GET_STAFF_RIGHT_TO_WORK: "getStaffRightToWork",
  GET_STAFF_SCHEDULE: "getStaffSchedule",
  STAFF_CALCULATED_SHIFT_HOURS: "staffCalculatedShiftHours",
};

export const userApiKeys = {
  GET_PROFILE_DATA: "getProfileData",
  GET_PROFILE_SHORT: "getProfileShort",
  GET_PROFILE_FULL: "getProfileFull",
  GET_PERSONAL_INFO: "getPersonalInfo",
  CREATE_UPDATE_PROFILE: "createUpdateProfile",
  UPDATE_HOSTED_PAGE: "updateHostedPage",
  GET_SUBSCRIPTION: "getSubscription",
  GET_RIGHT_TO_WORK: "getRightToWork",
  CREATE_UPDATE_RIGHT_TO_WORK: "createUpdateRightToWork",
  GET_SKILLS: "getSkills",
  UPDATE_SKILLS: "updateSkills",
  GET_ACCREDITATIONS: "getAccreditations",
  UPDATE_ACCREDITATIONS: "updateAccreditations",
  DELETE_FILE: "deleteFile",
  GET_USER: "getUser",
};

export const regionApiKeys = {
  GET_REGION: "getRegion",
  CREATE_REGION: "createRegion",
  GET_REGION_LIST: "getRegionList",
  GET_REGION_BY_ID: "getRegionById",
  UPDATE_REGION: "updateRegion",
  DELETE_REGION: "deleteRegion",
  GET_REGION_DROP_DOWN_LIST: "getRegionDropDownList",
};

export const userRoleApiKeys = {
  GET_ALL_MODULE: "getAllModule",
  GET_MODULE_BY_ID: "getModuleById",
  ADD_MODULE: "addModule",
  UPDATE_MODULE: "updateModule",
  GET_ALL_MODULE_APIS: "getAllModuleApis",
  GET_API_BY_ID: "getApiById",
  ADD_MODULE_API: "addModuleApi",
  UPDATE_MODULE_API: "updateModuleApi",
  GET_ALL_USER_ROLE: "getAllUserRole",
  GET_ROLE_BY_ID: "getRoleById",
  ADD_USER_ROLE: "addUserRole",
  UPDATE_USER_ROLE: "updateUserRole",
  GET_ALL_ROLE_RESOURCE: "getAllRoleResource",
  ADD_ROLE_RESOURCE: "addRoleResource",
  GET_ROLE_RESOURCE: "getRoleResource",
  UPDATE_ROLE_RESOURCES: "updateRoleResources",
  GET_ROLE_ALLOWED_RESOURCE: "getRoleAllowedResource",
  GET_ROLE_NOT_ALLOWED_RESOURCE: "getRoleNotAllowedResource",
  GET_USER_MODULE_ACCESS: "getUserModuleAccess",
  GET_USER_ACCESS_BY_MODULE: "getUserAccessByModule",
  GET_PHARMACY_ROLES: "getPharmacyRoles",
};

export const moduleApiKeys = {
  GET_PHARMACY_MODULES: "getPharmacyModules",
};
