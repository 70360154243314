import { Button } from "antd";
import { useMemo, useState } from "react";
import Uploadicon from "../../../assets/icons/upload-cloud-green.svg";
import { prepareFilePreview } from "../../../utils/document";
import { uploadImageAndPdfOnly } from "../../../utils/helper";
import CommonModal from "../../UI/modals/CommonModal/CommonModal";
import FileUploadRows from "./FileUploadRow";
import UploadDocumentDrangAndDrop from "./UploadDocumentDrangAndDrop";

const DragNDrop = ({
  handleOpenDeleteModal,
  handleOpenUploadModal,
  fileList,
  handleInput,
  onAccrediationEdit,
  form,
  openUploadModal,
  setNewUploadModal,
  formID,
  tab,
}) => {
  const [openModal, setopenModal] =
    useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const droppedFiles = e.dataTransfer.files;

    // Assuming you want to handle only one file, you can get the first file from the dropped files.
    const selectedFile = droppedFiles[0];
    const isImage =
      uploadImageAndPdfOnly(selectedFile);

    if (isImage) {
      // Do something with the selected file, for example, update the state.
      form.setFieldsValue({
        filePath: {
          file: selectedFile,
          fileList: prepareFilePreview(
            URL.createObjectURL(selectedFile),
            selectedFile?.name
          ).fileList,
        },
      });
      // You can also open the modal or perform other actions based on the dropped file.
      setNewUploadModal(true);
    }
  };

  const isAnyFileUploaded = useMemo(() => {
    if (fileList?.length) {
      const t = fileList?.filter(
        (i) => i?.filePath?.length > 0
      );

      return Boolean(t?.length);
    }

    return false;
  }, [fileList]);

  return (
    <>
      <div
        className="dragndrop"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <figure className="icon_wrapper">
          <img src={Uploadicon} alt="" />
        </figure>

        <label
          for="file_input"
          className="file_input_area"
        >
          <button
            className="btn"
            onClick={() => {
              isAnyFileUploaded
                ? setopenModal(true)
                : setNewUploadModal(true);
            }}
          >
            <span>Click to upload</span>
          </button>
          or drag and drop files
          {/* <input type="file" id="file_input" /> */}
        </label>
        <p className="filetypes">
          SVG, PNG, JPG or JPEG{" "}
        </p>
      </div>

      <CommonModal
        handleModal={openModal}
        setHandleModal={setopenModal}
        title="Upload Documents"
        secondaryButtonText="Cancel"
        primaryButtonText="Save"
        secondaryButtonHandler={() =>
          setopenModal(false)
        }
      >
        <div className="upload_documents_modal_body">
          {" "}
          <FileUploadRows
            fileList={fileList}
            handleOpenDeleteModal={
              handleOpenDeleteModal
            }
            setopenModal={setopenModal}
          />
          <Button
            className="btn upload_btn"
            icon={
              <img
                src={Uploadicon}
                alt="upload"
              />
            }
            onClick={() => {
              setopenModal(false);
              setNewUploadModal(true);
            }}
          >
            Upload Document
          </Button>
        </div>
      </CommonModal>
      <CommonModal
        handleModal={openUploadModal}
        setHandleModal={setNewUploadModal}
        title="Upload Documents"
        secondaryButtonText="Cancel"
        primaryButtonText="Save"
        primaryButtonHandler={onAccrediationEdit}
        secondaryButtonHandler={() =>
          setNewUploadModal(false)
        }
      >
        <UploadDocumentDrangAndDrop
          form={form}
          formID={formID}
          accredationList={fileList}
          handleInput={handleInput}
          valueKey="accreditationLabel"
          displayKey="accreditationName"
        />
      </CommonModal>
    </>
  );
};

export default DragNDrop;
