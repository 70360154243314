import { Tooltip } from "antd";
import React from "react";
import EditIcon from "../../../../assets/icons/EditIcon.svg";
import DeleteIcon from "../../../../assets/icons/trash.svg";
import UploadIcon from "../../../../assets/icons/upload-cloud.svg";

const Actions = ({ editable, onDeleteClick, onUploadClick, disabled }) => {
  if (disabled) {
    return null;
  }

  return (
    <div className="action_wrapper">
      {editable ? (
        <div className="d-flex align-items-center justify-content-start">
          <button className="btn" onClick={onUploadClick}>
            <img src={EditIcon} alt="edit" />
          </button>
          <button className="btn" onClick={onDeleteClick}>
            <img src={DeleteIcon} alt="delete" />
          </button>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-start">
          <Tooltip title="Browse or drag drop">
            <button className="btn" onClick={onUploadClick}>
              <img src={UploadIcon} alt="upload" />
            </button>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default Actions;
