import {
  DatePicker,
  Form,
  Input,
  Select,
  Upload,
} from "antd";
import React, { useEffect, useMemo } from "react";
import CalendarIcon from "../../../assets/icons/calendar-grey.svg";
import Dropdown from "../../../assets/icons/downDrop.svg";
import { prepareFilePreview } from "../../../utils/document";
import {
  beforeUpload,
  compressImage,
  disabledDate,
  notify,
  replaceAlphabet,
  uploadImageAndPdfOnly,
} from "../../../utils/helper";
import ClickToUpload from "../../comon/ClickToUpload";
import "../document.scss";

const UploadDocumentDrangAndDrop = ({
  handleInput,
  accredationList,
  displayKey,
  valueKey,
  onEditClick,
  form,
  formID,
}) => {
  const id = Form.useWatch("id", form);

  const accreditationRuleId = Form.useWatch(
    "accreditationRuleId",
    form
  );
  const accreditationName = Form.useWatch(
    "accreditationName",
    form
  );
  const accreditationLabel = Form.useWatch(
    "accreditationLabel",
    form
  );
  const filePath = Form.useWatch(
    "filePath",
    form
  );
  const expiryDate = Form.useWatch(
    "expiryDate",
    form
  );
  const type = Form.useWatch("type", form);
  const date_key = Form.useWatch(
    "date_key",
    form
  );
  const isExpiryRequired = Form.useWatch(
    "isExpiryRequired",
    form
  );
  const answer = Form.useWatch("answer", form);
  const isNumber = Form.useWatch(
    "isNumber",
    form
  );
  const number_key = Form.useWatch(
    "number_key",
    form
  );
  const document_type = Form.useWatch(
    "document_type",
    form
  );
  const number_label = Form.useWatch(
    "number_label",
    form
  );
  const value = Form.useWatch("value", form);

  const payload = useMemo(() => {
    return {
      id,
      accreditationName,
      accreditationLabel,
      accreditationRuleId,
      filePath,
      expiryDate,
      type,
      isExpiryRequired,
      date_key,
      answer,
      isNumber,
      number_key,
      number_label,
      value,
      document_type,
    };
  }, [
    id,
    accreditationName,
    accreditationRuleId,
    accreditationLabel,
    filePath,
    expiryDate,
    type,
    isExpiryRequired,
    date_key,
    answer,
    isNumber,
    number_key,
    number_label,
    value,
    document_type,
  ]);

  const onChange = (date, dateString) => {
    form.setFieldValue("expiryDate", date);
  };

  const findObj = useMemo(() => {
    if (
      accreditationLabel?.length &&
      accredationList?.length
    ) {
      const _file = accredationList?.find(
        (item) =>
          item?.accreditationLabel ===
          accreditationLabel
      );

      return {
        ..._file,
        filePath,
      };
    }

    return null;
  }, [accredationList, accreditationLabel]);

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const droppedFiles = e.dataTransfer.files;

    // Assuming you want to handle only one file, you can get the first file from the dropped files.
    const selectedFile = droppedFiles[0];

    const isImage =
      uploadImageAndPdfOnly(selectedFile);

    if (isImage) {
      // Do something with the selected file, for example, update the state.
      form.setFieldsValue({
        filePath: {
          file: selectedFile,
          fileList: prepareFilePreview(
            URL.createObjectURL(selectedFile),
            selectedFile?.name
          ).fileList,
        },
      });
      // You can also open the modal or perform other actions based on the dropped file.
    }
  };

  useEffect(() => {
    form.setFieldsValue(findObj);
  }, [
    accreditationLabel,
    findObj,
    form,
    JSON.stringify(findObj),
  ]);

  useEffect(() => {
    return () => {
      form?.resetFields();
    };
  }, [form]);

  console.log(payload, "payload");

  return (
    <div className="fileupload_modal">
      <Form
        form={form}
        id={formID}
        className="fileupload_form"
      >
        <Form.Item
          name="accreditationLabel"
          rules={[
            {
              required: true,
              message:
                "Please Select Document Type",
            },
          ]}
        >
          <label>Document Type</label>
          <Select
            placeholder="Select"
            bordered={false}
            className="text-input-field pe-3 p-1 t1"
            suffixIcon={
              Boolean(
                payload?.filePath
              ) ? null : (
                <img src={Dropdown} alt="img" />
              )
            }
            onChange={(v) => {
              form.setFieldValue(
                "accreditationLabel",
                v
              );
            }}
            value={payload?.accreditationLabel}
            // disabled={Boolean(payload?.filePath)}
          >
            {accredationList?.length &&
              accredationList?.map(
                (data, index) => (
                  <Select.Option
                    key={data?.id}
                    value={data[valueKey]}
                  >
                    {data[displayKey] ||
                      data["title"]}
                  </Select.Option>
                )
              )}
          </Select>
        </Form.Item>

        <Form.Item
          hidden={
            !Boolean(payload?.isExpiryRequired)
          }
          required={Boolean(
            payload?.isExpiryRequired
          )}
          rules={[
            {
              required: Boolean(
                payload?.isExpiryRequired
              ),
              message: "Please fill expiry date",
            },
          ]}
          name="expiryDate"
          valuePropName="expiryDate"
        >
          <label>Expiry Date</label>
          <DatePicker
            format="YYYY-MM-DD"
            onChange={onChange}
            // value={payload?.expiryDate}
            suffixIcon={
              <img
                src={CalendarIcon}
                alt="calender"
              />
            }
            placeholder="Select"
            disabledDate={disabledDate}
          />
        </Form.Item>

        <Form.Item
          hidden={
            payload?.document_type !== "number"
          }
          // required={Boolean(payload?.isDocument)}
          rules={[
            {
              required:
                payload?.document_type ===
                "number",
              message: "This field is required",
            },
            {
              max: 20,
              message: `Maximum 20 ${
                payload?.accreditationLabel ===
                "DBSAnnualUpdate"
                  ? "digits"
                  : "characters"
              }`,
            },
          ]}
          name="value"
          valuePropName="value"
        >
          <label>
            {payload?.accreditationName}
          </label>
          <Input
            onChange={(e) =>
              handleInput(
                "value",
                payload?.accreditationLabel ===
                  "DBSAnnualUpdate"
                  ? replaceAlphabet(
                      e.target.value
                    )
                  : e.target.value
              )
            }
            placeholder="Enter value"
            value={payload?.value}
          />
        </Form.Item>

        <Form.Item
          hidden={
            payload?.document_type !== "answer"
          }
          rules={[
            {
              required:
                Boolean(payload?.isMandatory) &&
                !Boolean(payload?.isDocument),
              message: "This field is required",
            },
          ]}
          name="answer"
          valuePropName="answer"
        >
          <label>Answer</label>
          <Input
            onChange={(e) =>
              form.setFieldValue(
                "answer",
                e.target.value
              )
            }
            placeholder="Enter answer"
            value={payload?.answer}
          />
        </Form.Item>

        <Form.Item
          hidden={
            payload?.document_type !== "document"
          }
          // required={Boolean(payload?.isDocument)}
          rules={[
            {
              required:
                payload?.document_type ===
                "document",
              message: "Please select a file",
            },
          ]}
          name="filePath"
          validateTrigger="onBlur"
        >
          <Upload
            // accept={"image/*"}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            fileList={payload?.filePath?.fileList}
            showUploadList={{
              showPreviewIcon: false,
              showRemoveIcon: true,
            }}
            onPreview={() => {}}
            beforeUpload={beforeUpload}
            onChange={async ({
              file,
              fileList,
            }) => {
              try {
                if (file?.status === "removed") {
                  form.setFieldsValue({
                    filePath: null,
                  });
                  return;
                }

                const isImage =
                  uploadImageAndPdfOnly(file);

                if (isImage) {
                  const compressedFile =
                    await compressImage(file);
                  form.setFieldsValue({
                    filePath: {
                      file: compressedFile,
                      fileList,
                    },
                  });
                }
              } catch (error) {
                notify(
                  "error",
                  "Error compressing the image:"
                );
              }
            }}
            onRemove={() => {
              form.setFieldValue(
                "filePath",
                null
              );
            }}
            multiple={false}
            maxCount={1}
            name="filePath"
            listType="picture"
          >
            <ClickToUpload />
          </Upload>{" "}
        </Form.Item>
        <Form.Item name="date_key" hidden />
        <Form.Item name="type" hidden />
        <Form.Item name="document_type" hidden />
        <Form.Item name="isMandatory" hidden />
        <Form.Item name="number_key" hidden />
        <Form.Item name="number_label" hidden />
        <Form.Item
          name="isExpiryRequired"
          hidden
        />
        <Form.Item
          name="accreditationLabel"
          hidden
        />
        <Form.Item
          name="accreditationName"
          hidden
        />
        <Form.Item
          name="accreditationRuleId"
          hidden
        />
      </Form>
    </div>
  );
};

export default UploadDocumentDrangAndDrop;
