import { Form, Input, Popover } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import acceptIcons from "../../assets/icons/accept.png";
import infoIcon from "../../assets/icons/infoIcon.png";
import infoIcon1 from "../../assets/icons/infoIcon1.png";
import resendEmailIcon from "../../assets/icons/resendEmail.png";
import {
  getStaffsListThunk,
  resendStaffEmailThunk,
} from "../../redux/staffs/staffsThunk";
import { RouterConfig } from "../../routerConfig";
import { staffApiKeys } from "../../utils/apiKeyConstants";
import { userStatuses } from "../../utils/constant";
import { hasAccess } from "../../utils/helper";
import NoStaff from "../EmptyStates/NoStaff/Nostaff";
import { ConfirmModal } from "../UI/modals/confirmModal";
import { TableComponet } from "../UI/table";

export const StaffListTable = ({
  searchQuery,
  rerender,
  setRerender,
  filterValue,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { staffs } = useSelector((state) => state.staffs);
  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);

  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState({});
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState();

  const [form] = Form.useForm();
  const formId = useMemo(() => "resendEmail", []);

  const staffColumns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "fullName",
      },
      {
        Header: "Role",
        accessor: "roleTitle",
      },
      {
        Header: "Assigned Branch",
        accessor: "branchName",
      },
      {
        Header: "Date of Birth",
        accessor: "dob",
        Cell: ({ row: { original } }) => {
          return original.dob ? moment(original.dob).format("DD-MM-YYYY") : "-";
        },
      },
      {
        Header: "Phone Number",
        accessor: "phone",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row: { original } }) => {
          return (
            <div className="d-flex justify-content-between align-items-center">
              {original.status === "onboarding" ||
              original.status === "new" ||
              original.status === "pending" ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#FEFDE9", color: "#EE872D" }}
                  >
                    {userStatuses[original.status]}
                  </span>
                </div>
              ) : original.status === "active" ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#E8F5E9", color: "#1B5E1F" }}
                  >
                    {userStatuses[original.status]}
                  </span>
                </div>
              ) : (
                <span
                  className="px-4 rounded-pill py-2"
                  style={{ backgroundColor: "#FFEBEE", color: "#EF5350" }}
                >
                  {userStatuses[original.status]}
                </span>
              )}
            </div>
          );
        },
      },
      {
        Header: "Resend Email",
        accessor: "resend",
        Cell: ({ row: { original } }) => {
          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {!original.lastLogin && original.status !== "deactivated" ? (
                <img
                  onClick={(e) => {
                    if (
                      !original.lastLogin &&
                      moment().diff(
                        moment(original.lastInviteMail),
                        "minutes"
                      ) < 5
                    ) {
                      setSelectedRowData(original);
                      onResendEmail(undefined, original);
                      return;
                    }
                    e.stopPropagation();
                    setSelectedRowData(original);
                    setShowChangeEmailModal(true);
                    form.setFieldsValue({
                      email: original.email,
                    });
                  }}
                  width={30}
                  className="cursor-pointer"
                  src={resendEmailIcon}
                  alt=""
                />
              ) : (
                <Popover
                  placement="left"
                  overlayStyle={{ width: 300 }}
                  content={
                    <div>
                      {original.status === "deactivated"
                        ? "E-Mail Can Not Be Re-Sent To De-Activated Users"
                        : "Unable To Re-Send Email To This User For Security Purposes. Contact Lopic Administrator"}
                    </div>
                  }
                  trigger="hover"
                >
                  <img src={infoIcon} alt="" />
                </Popover>
              )}
            </div>
          );
        },
      },
      // {
      //   Header: "",
      //   accessor: "action",
      //   Cell: ({ row: { original } }) => {
      //     return (
      //       <div>
      //         <Dropdown icon="ellipsis vertical" pointing="top right">
      //           <Dropdown.Menu direction="left">
      //             <Dropdown.Item
      //               style={{ color: "#EF5350" }}
      //               text="Delete Branch"
      //             />
      //           </Dropdown.Menu>
      //         </Dropdown>
      //       </div>
      //     );
      //   },
      // },
    ],
    []
  );

  const {
    count: totalDataCount,
    page,
    pages: totalPagesCount,
    perPage,
    rows: staffsData,
  } = useMemo(() => staffs, [staffs]);

  useEffect(() => {
    if (!page && !perPage) return;
    setPerPageCount(perPage);
    setCurrentPageCount(page);
  }, [page, perPage]);

  useEffect(() => {
    setRerender(true);
  }, [currentPageCount, perPageCount, setRerender]);

  useEffect(() => {
    if (searchQuery !== "") return;
    setRerender(true);
  }, [searchQuery, setRerender]);

  useEffect(() => {
    if (!rerender) return;
    if (hasAccess(staffApiKeys.GET_STAFF_LIST)) {
      dispatch(
        getStaffsListThunk({
          filters: {
            name: searchQuery.trim(),
            ...filterValue,
          },
          pagination: { page: currentPageCount, perPage: perPageCount },
        })
      );
    }
    return setRerender(false);
  }, [
    dispatch,
    perPageCount,
    currentPageCount,
    searchQuery,
    rerender,
    setRerender,
    filterValue,
  ]);

  const onResendEmail = useCallback(
    (values, original) => {
      if (
        !original?.lastLogin &&
        original?.lastInviteMail &&
        moment().diff(moment(original?.lastInviteMail), "minutes") < 5
      ) {
        setShowModal(true);
        setShowChangeEmailModal(false);
        setModalText({
          mode: "wait",
          text: "Seems Like You've Already Re-Sent An E-Mail, Please Wait For 5 Minutes Before You Re-Send Another Email. The Re-Send Icon May Be Disabled In Some Cases- Contact The Lopic Administrator For Further Help If This Case Persists.",
        });
        return;
      }

      let isUpdatedEmail = false;
      if (values?.email !== original?.email) isUpdatedEmail = true;
      if (
        !original?.lastLogin &&
        (!original?.lastInviteMail ||
          moment().diff(moment(original?.lastInviteMail), "minutes") > 5)
      ) {
        dispatch(
          resendStaffEmailThunk({
            id: original?.id,
            email: values?.email ? values?.email : original?.email,
            name: original?.name ?? "",
            surname: original?.surname ?? "",
            isUpdatedEmail,
          })
        ).then((res) => {
          if (res.payload) {
            setShowModal(true);
            setShowChangeEmailModal(false);
            setModalText({
              mode: "success",
              text: "Email Re-Sent Successfully!",
            });
          }
        });
      }
    },
    [dispatch]
  );

  const formatTrProps = ({ original }) => {
    return {
      onClick: () =>
        navigate(
          generatePath(RouterConfig.branchAdminStaffInformation, {
            staffId: original.id,
          })
        ),
    };
  };

  return (
    <div style={{ padding: "0px 24px" }}>
      <ConfirmModal
        backdrop="static"
        title={modalText.mode === "success" ? "Success!" : "Hold up!"}
        open={showModal}
        confirmButtonText="Close"
        description={modalText.text}
        image={modalText.mode === "success" ? acceptIcons : infoIcon1}
        onConfirm={() => {
          setShowModal(false);
          setRerender(true);
        }}
      ></ConfirmModal>
      {showChangeEmailModal && (
        <ConfirmModal
          title="Re-Send Email"
          confirmButtonText="Resend"
          cancelButtonText="Cancel"
          open={showChangeEmailModal}
          formId={formId}
          onClose={() => setShowChangeEmailModal(false)}
        >
          <Form
            onFinish={(values) => onResendEmail(values, selectedRowData)}
            form={form}
            id={formId}
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please Enter Email Address!",
                },
              ]}
            >
              <Input
                className="text-input-field p-2 t1"
                placeholder="Email Address"
              />
            </Form.Item>
          </Form>
        </ConfirmModal>
      )}
      {staffs?.rows?.length > 0 ? (
        <TableComponet
          rowCursor="pointer"
          isBorderShown
          currentPageCount={currentPageCount}
          perPageCount={perPageCount}
          totalDataCount={totalDataCount}
          setCurrentPage={setCurrentPageCount}
          setPerPageCount={setPerPageCount}
          showPagination
          maxPageCount={totalPagesCount}
          columns={staffColumns}
          data={staffsData}
          formatRowProps={
            hasAccess(staffApiKeys.GET_STAFF_PROFILE)
              ? formatTrProps
              : undefined
          }
        />
      ) : (
        <div
          style={{
            border: "1px solid #E9E9E9",
            paddingTop: "80px",
            minHeight: "500px",
          }}
        >
          {/* <EmptyData title="Staff Member" /> */}
          <NoStaff
            text="Add Staff Member"
            subText="After adding your staff you can populate your time sheets, schedule shifts, manage compliance data and optimise your pharmacy operations with Lopic like never before."
          />
        </div>
      )}
    </div>
  );
};
