import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const useOpenAddModals = (paramKey, route, callBack) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!!params) {
      if (!!params.get(paramKey) && parseInt(params.get(paramKey)) > 0) {
        callBack();
        navigate(route, {
          replace: true,
        });
      }
    }
  }, [params, params.get(paramKey)]);
};

export default useOpenAddModals;
