import { DatePicker, Form, Select } from "antd";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteBulkShiftThunk } from "../../redux/schedule/scheduleThunk";
import { AddModal } from "../UI/modals/addModal";

export const BulkDeleteShiftForm = ({
    showDeleteShiftModal,
    setShowDeleteShiftModal,
    staffId,
    setFlag,
    isStaff,
    branchId,
    isBranch
}) => {
    const dispatch = useDispatch();
    const formId = useMemo(() => "form", []);
    const [form] = Form.useForm();
    const startDate = Form.useWatch("startDate", form);
    const { regionBranches } = useSelector(state => state.regions)
    const { staffs } = useSelector((state) => {
        return state.schedule;
    });

    const onFinish = (values) => {
        const { startDate, endDate } = values
        dispatch(
            deleteBulkShiftThunk({
                branchId: branchId ? branchId : values.branchId,
                staffId: staffId ? staffId : values.staffId,
                data: {
                    startDate: startDate.format('YYYY-MM-DD'),
                    endDate: endDate.format('YYYY-MM-DD')
                }
            })
        ).then((res) => {
            if (res.payload) {
                form.resetFields()
                setShowDeleteShiftModal(false)
                setFlag(true)
            }
        });
    };

    return (

        <AddModal
            title={"Delete Shift"}
            formId={formId}
            handleModal={showDeleteShiftModal}
            setHandleModal={setShowDeleteShiftModal}
            primaryButtonText={"Delete Shift"}
            secondaryButtonText="Cancel"
            secondaryButtonHandler={() => {
                setShowDeleteShiftModal(false);
            }}
        >
            <Form
                onFinish={onFinish}
                form={form}
                id={formId}
                style={{ width: "100%" }}
                layout="vertical"
                autoComplete="off"
            >
                <div
                    className="d-flex align-items-center gap-4"
                    style={{ color: "#7B7B7B", width: "100%" }}
                >
                    <Form.Item
                        label="Start Date"
                        name="startDate"
                        rules={[
                            {
                                required: true,
                                message: "Please Select Start Date!",
                            },
                        ]}
                    >
                        <DatePicker
                            format="DD-MM-YYYY"
                            placeholder="Start Date"
                            getPopupContainer={(trigger) => trigger.parentElement}
                            className="text-input-field p-2 pe-3 t1"
                            style={{ width: "100%" }}
                            inputReadOnly
                            onChange={(val) => {
                                form.setFieldsValue({
                                    endDate: val
                                })
                            }}
                            disabledDate={(current) => {
                                return dayjs().add(-1, 'day') >= current;
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="End Date"
                        name="endDate"
                        rules={[
                            {
                                required: true,
                                message: "Please Select End Date!",
                            },
                        ]}
                    >
                        <DatePicker
                            placeholder="End Date"
                            format="DD-MM-YYYY"
                            inputReadOnly
                            disabledDate={(current) => {
                                return dayjs(startDate).add(-1, 'day') >= current;
                            }}
                            getPopupContainer={(trigger) => trigger.parentElement}
                            className="text-input-field p-2 pe-3 t1"
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </div>
                {
                    isStaff &&
                    <Form.Item
                        name="branchId"
                        label="Branches"
                        rules={[
                            {
                                required: true,
                                message: "Please Select Branch!",
                            },
                        ]}
                    >
                        <Select
                            dropdownStyle={{ zIndex: 2000 }}
                            placeholder="Select Branch"
                            bordered={false}
                            className='text-input-field pe-3 t1'
                        >
                            {
                                regionBranches?.map((data, idx) => (
                                    <Select.Option key={idx} value={data.id}>{data.title}</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                }
                {
                    isBranch &&
                    <Form.Item
                        name="staffId"
                        label="Staffs"
                    >
                        <Select
                            dropdownStyle={{ zIndex: 2000 }}
                            placeholder="Staff Members"
                            bordered={false}
                            className='text-input-field pe-3 t1'
                        >
                            {
                                staffs.map((data, idx) => (
                                    <Select.Option key={idx} value={data.id}>
                                        {data.name} {data.surname}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                }
            </Form>
        </AddModal>
    );
};
