import React from "react";
// import WorkContractInfo from "./WorkContractInfo";
import PersonalInfoSection from "./PersonalInfoSection";
import "./style.scss";
const PersonalInfo = ({ details, onSuccess }) => {
  return (
    <div>
      <PersonalInfoSection onSuccess={onSuccess} details={details} />
      {/* <WorkContractInfo details={details} /> */}
    </div>
  );
};

export default PersonalInfo;
