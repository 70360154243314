import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EmptyData } from "../../../../../components/comon/EmptyData";
import { Heading } from "../../../../../components/UI/heading";
import { TableComponet } from "../../../../../components/UI/table";
import { getPharmacyJobListThunk } from "../../../../../redux/super-admin/superAdminThunk";
import { notify } from "../../../../../utils/helper";

export const JobsListPage = (props) => {
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState("");
  let { id } = useParams();

  const { pharmacyJobsList } = useSelector((state) => state.superAdmin);

  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);

  const columns = useMemo(
    () => [
      {
        Header: "Branch ID",
        accessor: "id",
      },
      {
        Header: "Title",
        accessor: "branchTitle",
      },

      {
        Header: "Address",
        accessor: "city",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row: { original } }) => {
          return (
            <>
              {original.status === "isArchived" ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#FEFDE9", color: "#EE872D" }}
                  >
                    {original.status}
                  </span>
                </div>
              ) : original.status === "active" ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#E8F5E9", color: "#1B5E1F" }}
                  >
                    {original.status}
                  </span>
                </div>
              ) : original.status === "unfilled" ||
                original.status === "pending" ? (
                <div>
                  <span
                    className="px-4 rounded-pill py-2"
                    style={{ backgroundColor: "#FEFDE9", color: "#EE872D" }}
                  >
                    {" "}
                    {original.status}
                  </span>
                </div>
              ) : original.status === "completed" ? (
                <span
                  className="px-4 rounded-pill py-2"
                  style={{ backgroundColor: "#E3F2FD", color: "#1045A1" }}
                >
                  {original.status}
                </span>
              ) : (
                <span
                  className="px-4 rounded-pill py-2"
                  style={{ backgroundColor: "#FFEBEE", color: "#EF5350" }}
                >
                  {original.status}
                </span>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  const {
    count: totalDataCount,
    page,
    pages: totalPagesCount,
    perPage,
    rows: pharmacyData,
  } = useMemo(() => pharmacyJobsList, [pharmacyJobsList]);

  useEffect(() => {
    if (!page && !perPage) return;
    setPerPageCount(perPage);
    setCurrentPageCount(page);
  }, [page, perPage]);

  const getJobsList = useCallback(
    async (values) => {
      try {
        dispatch(
          getPharmacyJobListThunk({
            filters: {
              pharmacy: searchQuery,
              ...values,
            },
            pagination: { page: currentPageCount, perPage: perPageCount },
            id: id,
          })
        );
      } catch (error) {
        notify("error", error.message);
      }
    },
    [currentPageCount, dispatch, perPageCount, searchQuery]
  );

  useEffect(() => {
    getJobsList();
  }, [currentPageCount, perPageCount]);

  return (
    <>
      <div>
        <Heading style={{ position: "relative", right: "24px" }} label="Jobs" />

        <div>
          {pharmacyJobsList?.rows?.length > 0 ? (
            <TableComponet
              rowCursor="pointer"
              isBorderShown
              currentPageCount={currentPageCount}
              perPageCount={perPageCount}
              totalDataCount={totalDataCount}
              setCurrentPage={setCurrentPageCount}
              setPerPageCount={setPerPageCount}
              showPagination
              maxPageCount={totalPagesCount}
              columns={columns}
              data={pharmacyData ?? []}
            />
          ) : (
            <div style={{ width: "100%", marginTop: "60px" }}>
              <EmptyData title="Jobs" subtitle="Job" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
