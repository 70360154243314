import {
  DatePicker,
  Form,
  Input,
  Select,
  Upload,
} from "antd";
import React, { useEffect } from "react";
import CalendarIcon from "../../../assets/icons/calendar-grey.svg";
import Dropdown from "../../../assets/icons/downDrop.svg";
import { prepareFilePreview } from "../../../utils/document";
import {
  beforeUpload,
  compressImage,
  disabledDate,
  notify,
  replaceAlphabet,
  uploadImageAndPdfOnly,
} from "../../../utils/helper";
import ClickToUpload from "../../comon/ClickToUpload";
import "../document.scss";

const UploadDocument = ({
  payload,
  handleInput,
  accredationList,
  displayKey,
  valueKey,
  onEditClick,
  setItem,
  formID,
  form,
}) => {
  const onChange = (date, dateString) => {
    handleInput("expiryDate", date);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const droppedFiles = e.dataTransfer.files;

    // Assuming you want to handle only one file, you can get the first file from the dropped files.
    const selectedFile = droppedFiles[0];

    const isImage =
      uploadImageAndPdfOnly(selectedFile);

    if (isImage) {
      form.setFieldsValue({
        filePath: {
          file: selectedFile,
          fileList: prepareFilePreview(
            URL.createObjectURL(selectedFile),
            selectedFile?.name
          ).fileList,
        },
      });
    }
  };
  useEffect(() => {
    return () => {
      form?.resetFields();
    };
  }, [form]);

  return (
    <div className="fileupload_modal">
      <Form
        form={form}
        id={formID}
        onFinish={(v) => console.log(v)}
        className="fileupload_form"
      >
        <Form.Item name="accreditationLabel">
          <label>Document Type</label>
          <Select
            placeholder="Select"
            bordered={false}
            className="text-input-field pe-3 p-1 t1"
            suffixIcon={
              <img src={Dropdown} alt="img" />
            }
            onChange={(v) => {
              handleInput(
                "accreditationLabel",
                v
              );
              setItem(v);
            }}
            value={payload?.accreditationLabel}
            // disabled={Boolean(payload?.filePath)}
          >
            {accredationList?.length &&
              accredationList?.map(
                (data, index) => (
                  <Select.Option
                    key={data?.id}
                    value={data[valueKey]}
                  >
                    {data[displayKey] ||
                      data["title"]}
                  </Select.Option>
                )
              )}
          </Select>
        </Form.Item>

        <Form.Item
          hidden={
            !Boolean(payload?.isExpiryRequired)
          }
          required={Boolean(
            payload?.isExpiryRequired
          )}
          rules={[
            {
              required: Boolean(
                payload?.isExpiryRequired
              ),
              message: "Please fill expiry date",
            },
          ]}
          name="expiryDate"
          valuePropName="expiryDate"
        >
          <label>Expiry Date</label>
          <DatePicker
            format="YYYY-MM-DD"
            onChange={onChange}
            value={payload?.expiryDate}
            suffixIcon={
              <img
                src={CalendarIcon}
                alt="calender"
              />
            }
            placeholder="Select"
            disabledDate={disabledDate}
          />
        </Form.Item>

        <Form.Item
          hidden={
            payload?.document_type !== "answer"
          }
          // required={Boolean(payload?.isDocument)}
          rules={[
            {
              required:
                Boolean(payload?.isMandatory) ||
                payload?.document_type ===
                  "answer",
              message: "This field is required",
            },
          ]}
          name="answer"
          valuePropName="answer"
        >
          <label>Answer</label>
          <Input
            onChange={(e) =>
              handleInput(
                "answer",
                e.target.value
              )
            }
            placeholder="Enter answer"
            value={payload?.answer}
          />
        </Form.Item>

        <Form.Item
          hidden={
            payload?.document_type !== "number"
          }
          // required={Boolean(payload?.isDocument)}
          rules={[
            {
              required:
                payload?.document_type ===
                "number",
              message: "This field is required",
            },
            {
              max: 20,
              message: `Maximum 20 ${
                payload?.accreditationLabel ===
                "DBSAnnualUpdate"
                  ? "digits"
                  : "characters"
              }`,
            },
          ]}
          name="value"
          valuePropName="value"
        >
          <label>
            {payload?.accreditationName}
          </label>
          <Input
            onChange={(e) =>
              handleInput(
                "value",
                payload?.accreditationLabel ===
                  "DBSAnnualUpdate"
                  ? replaceAlphabet(
                      e.target.value
                    )
                  : e.target.value
              )
            }
            placeholder="Enter value"
            value={payload?.value}
          />
        </Form.Item>

        <Form.Item
          hidden={
            payload?.document_type !== "document"
          }
          // required={payload?.document_type !== "number"}
          rules={[
            {
              required:
                payload?.document_type ===
                "document",
              message: "Please select a file",
            },
          ]}
          name="filePath"
        >
          <Upload
            // accept={"image/*,.pdf"}
            fileList={payload?.filePath?.fileList}
            showUploadList={{
              showPreviewIcon: false,
              showRemoveIcon: true,
            }}
            onPreview={() => {}}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            beforeUpload={beforeUpload}
            onChange={async ({
              file,
              fileList,
            }) => {
              try {
                if (file?.status === "removed") {
                  form.setFieldsValue({
                    filePath: null,
                  });
                  return;
                }

                const isImage =
                  uploadImageAndPdfOnly(file);

                if (isImage) {
                  const compressedFile =
                    await compressImage(file);
                  form.setFieldsValue({
                    filePath: {
                      file: compressedFile,
                      fileList,
                    },
                  });
                } else {
                  form.setFieldsValue({
                    filePath: null,
                  });
                }
              } catch (error) {
                notify(
                  "error",
                  "Error compressing the image:"
                );
              }
            }}
            multiple={false}
            maxCount={1}
            name="filePath"
            listType="picture"
          >
            <ClickToUpload />
          </Upload>{" "}
          {/* <div className="upload_box dragndrop">
            {isUploading ? (
              <>
                {" "}
                <figure className="icon_wrapper">
                  <img src={FileIcon} alt="file" />
                </figure>
                <div className="w-100">
                  <div for="file_input" className="file_input_area">
                    <span className="filename">{payload?.filePath?.name}</span>
                  </div>
                  <p className="filesize">
                    {parseInt(payload?.filePath?.size / 1000)}KB
                  </p>
                  <Progress percent={40} />
                </div>
                <button className="cross btn" onClick={removeFile}>
                  <img src={CloseIcon} alt="" />
                </button>
              </>
            ) : isUploadComplete ? (
              <>
                {" "}
                <figure className="icon_wrapper">
                  <img src={FileIcon} alt="file" />
                </figure>
                <div className="w-100">
                  <div for="file_input" className="file_input_area">
                    <span className="filename">{payload?.filePath?.name}</span>
                  </div>
                  <p className="filesize">
                    {parseInt(payload?.filePath?.size / 1000)}KB -{" "}
                    <span className="completed">Completed</span>
                  </p>
                </div>
                <button className="cross btn" onClick={removeFile}>
                  <img src={CloseIcon} alt="" />
                </button>
              </>
            ) : isFailed ? (
              <>
                <div className="w-100">
                  <div for="file_input" className="file_input_area">
                    <span className="filename">
                      Upload failed, please try again
                    </span>
                  </div>
                  <p className="filesize failed">
                    {parseInt(payload?.filePath?.name / 1000)}KB
                  </p>
                </div>
              </>
            ) : (
              <>
                <figure className="icon_wrapper">
                  {!!payload?.filePath ? (
                    <RenderFileIcon filePath={payload?.filePath} />
                  ) : (
                    <img src={Uploadicon} alt="upload" />
                  )}
                </figure>
                <div>
                  <label for="file_input" className="file_input_area">
                    <span>Click to upload</span>
                    or drag and drop files
                    <input
                      type="file"
                      id="file_input"
                      onChange={handleFileSelect}
                    />
                  </label>
                  <p className="filetypes">
                    SVG, PNG, JPG or JPEG 
                  </p>
                </div>
              </>
            )}
          </div> */}
        </Form.Item>
        <Form.Item name="date_key" hidden />
        <Form.Item name="type" hidden />
        <Form.Item name="document_type" hidden />
        <Form.Item name="isMandatory" hidden />
        <Form.Item
          name="accreditationRuleId"
          hidden
        />
        <Form.Item name="number_key" hidden />
        <Form.Item name="number_label" hidden />
        <Form.Item
          name="isExpiryRequired"
          hidden
        />
        <Form.Item
          name="accreditationLabel"
          hidden
        />
        <Form.Item
          name="accreditationName"
          hidden
        />
      </Form>
    </div>
  );
};

export default UploadDocument;
