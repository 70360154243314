import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import PencilIcon from "../../../assets/icons/pencil.svg";
import { ATTENDANCE_TYPE } from "../../../utils/constant";
import eventEmitter, { events } from "../../../utils/eventEmitter";
import TimeInput from "../../UI/input/TimeInput";
const ShiftTime = ({ isInEditMode, index }) => {
  const [editInput, seteditInput] = useState(isInEditMode);
  const [isValueEdited, setIsValueEdited] = useState(false);
  const { staffTimeSheetEdited } = useSelector((s) => s.staffs);
  const { branches } = useSelector((state) => state.branches);
  const handleChange = (value) => {
    setinput(value);
    setIsValueEdited(true);

    const splitby_ = value?.split("-");

    let payload = {
      recordId: shiftDetails?.attendance?.id || shiftDetails?.recordId,
      startTime: splitby_?.[0],
      endTime: splitby_?.[1],
      mutationType: "updateFields",
    };

    eventEmitter.emit(events.timeSheetCelledit, payload);
  };

  useEffect(() => {
    if (!isInEditMode) {
      seteditInput(false);
      setIsValueEdited(false);
    }
  }, [isInEditMode]);

  const [input, setinput] = useState("");

  const shiftDetails = useMemo(() => {
    const details = staffTimeSheetEdited?.[index];

    if (!details?.branchId) {
      seteditInput(false);
    }

    return details;
  }, [index, staffTimeSheetEdited]);

  const isEmpty =
    !shiftDetails?.branchId ||
    !shiftDetails?.startTime ||
    !shiftDetails?.endTime;

  const hasOperatingTime = useMemo(() => {
    let temp = false;
    const _branchDetails = branches?.rows?.find(
      (item) => item?.id === shiftDetails?.branchId
    );

    if (!!_branchDetails) {
      const findByDate = _branchDetails?.operatingTime?.find(
        (item) =>
          item?.dayOfWeek?.toLowerCase() ===
          moment(shiftDetails?.startDate, "YYYY-MM-DD")
            .format("dddd")
            ?.toLowerCase()
      );

      if (!!findByDate) temp = true;
    }

    return temp;
  }, [shiftDetails?.branchId]);

  const shiftTime = useMemo(() => {
    if (isEmpty) {
      if (!isValueEdited) {
        setinput("");
      }
      return null;
    }
    const time = `${shiftDetails?.startTime}-${shiftDetails?.endTime}`;
    if (!isValueEdited) {
      setinput(time);
    }
    return time;
  }, [isEmpty, isValueEdited, shiftDetails?.endTime, shiftDetails?.startTime]);

  // const isFutureDate = useMemo(() => {
  //   return moment(shiftDetails?.startDate)
  //     ?.endOf("date")
  //     .isAfter(moment()?.endOf("date"));
  // }, [shiftDetails]);

  if (shiftDetails?.type === ATTENDANCE_TYPE.NO_SHIFT) {
    return <div />;
  }

  if (isEmpty && !isInEditMode) {
    return <div />;
  }

  return (
    <div className={isInEditMode ? "edit_td" : ""}>
      {editInput ? (
        <div className="modal_table_input">
          <TimeInput
            value={input}
            className="modal_table_input"
            onChange={handleChange}
          />
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-start no-user-select">
          <p className="time w-100">{shiftTime}</p>

          {isInEditMode &&
            shiftDetails?.branchId &&
            hasOperatingTime === false && (
              <button
                className="btn edit_button"
                onClick={() => seteditInput(true)}
              >
                <img src={PencilIcon} alt="pencil" />
              </button>
            )}
        </div>
      )}
    </div>
  );
};

export default ShiftTime;
