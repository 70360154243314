import React from "react";

const BuildingsIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M7.46771 2.76634C7.46771 2.89301 7.36104 2.99967 7.23438 2.99967H6.48104C5.04104 2.99967 3.86771 4.17301 3.86771 5.61301V11.7663C3.86771 11.893 3.76104 11.9997 3.63437 11.9997H3.16771C2.37438 11.9997 1.73438 11.3597 1.73438 10.5663V2.76634C1.73438 1.97301 2.37438 1.33301 3.16771 1.33301H6.03438C6.82771 1.33301 7.46771 1.97301 7.46771 2.76634Z"
        fill={color ? color : "#1F87E5"}
      />
      <path
        d="M15.0673 2.76634V10.5663C15.0673 11.3597 14.4273 11.9997 13.634 11.9997H13.214C13.0873 11.9997 12.9807 11.893 12.9807 11.7663V5.61301C12.9807 4.17301 11.8073 2.99967 10.3673 2.99967H9.56732C9.44065 2.99967 9.33398 2.89301 9.33398 2.76634C9.33398 1.97301 9.97398 1.33301 10.7673 1.33301H13.634C14.4273 1.33301 15.0673 1.97301 15.0673 2.76634Z"
        fill={color ? color : "#1F87E5"}
      />
      <path
        d="M10.3672 4H6.48052C5.58719 4 4.86719 4.72 4.86719 5.61333V13.0533C4.86719 13.9467 5.58719 14.6667 6.48052 14.6667H7.56719C7.75385 14.6667 7.90052 14.52 7.90052 14.3333V12.6667C7.90052 12.3933 8.12719 12.1667 8.40052 12.1667C8.67385 12.1667 8.90052 12.3933 8.90052 12.6667V14.3333C8.90052 14.52 9.04719 14.6667 9.23385 14.6667H10.3739C11.2605 14.6667 11.9805 13.9467 11.9805 13.06V5.61333C11.9805 4.72 11.2605 4 10.3672 4ZM9.73385 9.83333H7.06719C6.79385 9.83333 6.56719 9.60667 6.56719 9.33333C6.56719 9.06 6.79385 8.83333 7.06719 8.83333H9.73385C10.0072 8.83333 10.2339 9.06 10.2339 9.33333C10.2339 9.60667 10.0072 9.83333 9.73385 9.83333ZM9.73385 7.83333H7.06719C6.79385 7.83333 6.56719 7.60667 6.56719 7.33333C6.56719 7.06 6.79385 6.83333 7.06719 6.83333H9.73385C10.0072 6.83333 10.2339 7.06 10.2339 7.33333C10.2339 7.60667 10.0072 7.83333 9.73385 7.83333Z"
        fill={color ? color : "#1F87E5"}
      />
    </svg>
  );
};

export default BuildingsIcon;
