import React from "react";

const SearchStatusicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M11.5762 2.5C6.33617 2.5 2.07617 6.76 2.07617 12C2.07617 17.24 6.33617 21.5 11.5762 21.5C16.8162 21.5 21.0762 17.24 21.0762 12C21.0762 6.76 16.8162 2.5 11.5762 2.5ZM11.5762 14.25H8.57617C8.16617 14.25 7.82617 13.91 7.82617 13.5C7.82617 13.09 8.16617 12.75 8.57617 12.75H11.5762C11.9862 12.75 12.3262 13.09 12.3262 13.5C12.3262 13.91 11.9862 14.25 11.5762 14.25ZM14.5762 11.25H8.57617C8.16617 11.25 7.82617 10.91 7.82617 10.5C7.82617 10.09 8.16617 9.75 8.57617 9.75H14.5762C14.9862 9.75 15.3262 10.09 15.3262 10.5C15.3262 10.91 14.9862 11.25 14.5762 11.25Z"
        fill="#78828A"
      />
      <path
        d="M21.3767 22.4986C21.1967 22.4986 21.0167 22.4286 20.8867 22.2986L19.0267 20.4386C18.7567 20.1686 18.7567 19.7286 19.0267 19.4486C19.2967 19.1786 19.7367 19.1786 20.0167 19.4486L21.8767 21.3086C22.1467 21.5786 22.1467 22.0186 21.8767 22.2986C21.7367 22.4286 21.5567 22.4986 21.3767 22.4986Z"
        fill="#78828A"
      />
    </svg>
  );
};

export default SearchStatusicon;
