import { Button } from "antd";
import React from "react";
import Modal from "react-bootstrap/Modal";

export const AccreditionModal = ({
  open,
  onClose,
  description,
  title,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  children,
  primaryBtnDisable,
  formId,
}) => {
  return (
    <Modal centered show={open} onHide={onClose}>
      <Modal.Body
        className=" rounded gap-4 d-flex flex-column t1"
        style={{ padding: 47 }}
      >
        <div
          style={{
            fontWeight: 600,
            fontSize: "32px",
            lineHeight: "48px",
            color: "#000000",
          }}
          className=" text-center"
        >
          {title}
        </div>
        <div
          style={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            color: "#000000",
          }}
          className=" text-center"
        >
          {description}
        </div>

        {children}
        <div
          className="d-flex align-content-between align-items-end"
          style={{ gap: "15px", flex: "1" }}
        >
          {cancelButtonText && (
            <Button onClick={onClose} block size="large">
              {cancelButtonText}
            </Button>
          )}
          {confirmButtonText && (
            <Button
              onClick={onConfirm}
              type="primary"
              htmlType="submit"
              form={formId}
              block
              size="large"
            >
              {confirmButtonText}
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
