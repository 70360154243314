import { Tooltip, Typography } from "antd";
import React from "react";

const TotalExpenceChart = (props) => {
  const { count, branch, branchExpense, highestExpense } = props;

  const colors = ["#51C2BC", "#63B4F6", "#F6CD63", "#5351C2", "#81C784"];

  // console.log(
  //   branchExpense,
  //   highestExpense,
  //   (Number(branchExpense) / Number(highestExpense)) * 100,
  // );

  return (
    <li>
      <p style={{ textTransform: "capitalize" }}>{`${count} ${branch}`}</p>

      <div className="progress-wrap">
        <Tooltip title={`£${Number(branchExpense).toFixed(2)}`}>
          {" "}
          <Typography.Text>£{Number(branchExpense).toFixed(2)}</Typography.Text>
        </Tooltip>

        <div
          className="progress-bar"
          style={{
            width: `${(Number(branchExpense) / Number(highestExpense)) * 100}%`,
            backgroundColor: colors[Math.floor(Math.random() * 4)],
            height: "16px",
          }}
        ></div>
      </div>
    </li>
  );
};

export default TotalExpenceChart;
