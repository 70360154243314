import { Form, Radio, TimePicker } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { useParams } from "react-router-dom";
import informationIcon from "../../../../../../assets/icons/infoIcon.png";
import infoIcon from "../../../../../../assets/icons/infoIcon1.png";
import { AddModal } from "../../../../../../components/UI/modals/addModal";
import { ConfirmModal } from "../../../../../../components/UI/modals/confirmModal";
import { ShiftJobFormUpdated } from "../../../../../../components/comon/addShiftForm";
import { BulkDeleteShiftForm } from "../../../../../../components/comon/deleteBulkShift";
import DayView from "../../../../../../components/schedule/DayView/DayView";
import Toolbar from "../../../../../../components/schedule/SchedularToolbar";
import {
  editBranchOperatingTimeThunk,
  getBranchScheduleThunk,
} from "../../../../../../redux/branches/branchesThunk";
import {
  deleteJobThunk,
  deleteShiftThunk,
  getRolesThunk,
  getStaffsThunk,
} from "../../../../../../redux/schedule/scheduleThunk";
import {
  branchApiKeys,
  staffApiKeys,
} from "../../../../../../utils/apiKeyConstants";
import { hasAccess } from "../../../../../../utils/helper";
import { BranchWeeklyView } from "./branchWeeklyView";
import { AddAbsenceForm } from "./components";
import { MonthlyView } from "./monthView/MonthView";
import "./style.scss";

export const BranchSchedule = () => {
  const { id } = useParams();
  const [dateRange, setDateRange] = useState();

  const [addAbsenceModal, setAddAbsenceModal] =
    useState(false);
  const { branchInfo } = useSelector(
    (state) => state.branches
  );
  const [
    showDeleteShiftModal,
    setShowDeleteSHiftModal,
  ] = useState(false);

  const formId = useMemo(() => "form", []);
  const [form] = Form.useForm();
  const branchClosed = Form.useWatch(
    "branchClosed",
    form
  );
  const [showErrorModal, setShowErrorModal] =
    useState(false);
  const [errorData, setErrorData] = useState([]);
  const startTime = Form.useWatch(
    "startTime",
    form
  );
  const endTime = Form.useWatch("endTime", form);
  const [flag, setFlag] = useState(true);
  const [view, setView] = useState("weekly");
  const [
    showAddShiftModal,
    setShowAddshiftModal,
  ] = useState(false);
  const [
    showEditTimeModal,
    setShowEditTimeModal,
  ] = useState(false);
  const [selectedDate, setSelectedDate] =
    useState();
  const [selectId, setSelectedId] = useState();
  const [selectedData, setSelectedData] =
    useState();
  const [showDateCheckbox, setShowDateCheckbox] =
    useState();
  const [
    selectedMonthDays,
    setSelectedMonthDays,
  ] = useState([]);
  const [editedData, setEditedData] = useState(
    []
  );
  const { branchSchedule } = useSelector(
    (state) => state.branches
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedMonthDays.length) return;
    const data = [...selectedMonthDays];
    const _data = data?.map((d) => {
      const found =
        branchSchedule?.operatingTime.find(
          (i) => i.date === d.date
        );
      d.startTime = found?.startTime;
      d.endTime = found?.endTime;
      return d;
    });
    setEditedData(_data);
  }, [
    branchSchedule?.operatingTime,
    selectedMonthDays,
  ]);

  useEffect(() => {
    if (!flag || !dateRange) return;
    if (
      hasAccess(
        branchApiKeys.GET_BRANCH_SCHEDULE_LIST
      )
    ) {
      dispatch(
        getBranchScheduleThunk({
          id,
          data: {
            endDateRange: dateRange?.endDateRange,
            startDateRange:
              dateRange?.startDateRange,
            weeklySchedule: view === "weekly",
          },
        })
      ).then((res) => {
        if (res.payload) {
          setFlag(false);
          setShowDateCheckbox(false);
        }
      });
    }
  }, [dispatch, flag, dateRange, id]);

  useEffect(() => {
    if (hasAccess(staffApiKeys.GET_ALL_STAFF)) {
      dispatch(getStaffsThunk());
    }
    dispatch(
      getRolesThunk({
        group: ["staff", "locum"],
        type: "Default",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (view === "monthly") {
      setDateRange({
        startDateRange: moment(
          dateRange?.startDateRange
        )
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDateRange: moment(
          dateRange?.startDateRange
        )
          .endOf("month")
          .format("YYYY-MM-DD"),
      });
    }
    if (view === "weekly") {
      setDateRange({
        startDateRange: moment()
          .startOf("isoWeek")
          .format("YYYY-MM-DD"),
        endDateRange: moment()
          .endOf("isoWeek")
          .format("YYYY-MM-DD"),
      });
    }
    if (view === "daily") {
      setDateRange({
        startDateRange: moment(
          dateRange?.endDateRange
        )
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDateRange: moment(
          dateRange?.endDateRange
        )
          .startOf("month")
          .format("YYYY-MM-DD"),
      });
    }
    setFlag(true);
  }, [view]);

  const onDelete = (data) => {
    if (data.isJob) {
      dispatch(
        deleteJobThunk({
          jobId: data.id,
        })
      ).then((res) => {
        if (res.payload === "success") {
          setFlag(true);
          setShowAddshiftModal(false);
        }
      });
    } else {
      dispatch(
        deleteShiftThunk({
          shiftId: data.id,
          branchId: data.branchId,
        })
      ).then((res) => {
        if (res.payload === "success") {
          setFlag(true);
          setShowAddshiftModal(false);
        }
      });
    }
  };
  const handleAddShift = (
    operatingTime,
    date,
    id,
    data
  ) => {
    setShowAddshiftModal(!showAddShiftModal);
    setSelectedDate(date);
    setSelectedId(id);
    setSelectedData(data);
  };

  const onDateChange = useCallback(
    (startDate, endDate) => {
      setDateRange({
        startDateRange: startDate,
        endDateRange:
          view === "monthly" || view === "weekly"
            ? endDate
            : startDate,
      });
      setFlag(true);
    },
    [view]
  );

  const actionItems = [
    {
      key: 1,
      label: (
        <div
          onClick={() =>
            setShowDateCheckbox(true)
          }
        >
          Select Date
        </div>
      ),
    },
    {
      key: 2,
      label: (
        <div
          onClick={() => setAddAbsenceModal(true)}
        >
          Add Absence
        </div>
      ),
    },
  ];

  const onDaySelect = (value, dayNumber) => {
    const idx = selectedMonthDays.findIndex(
      (i) => i.date === dayNumber
    );
    if (idx >= 0) {
      const data = [...selectedMonthDays];
      data.splice(idx, 1);
      setSelectedMonthDays(data);
    } else {
      const data = [...selectedMonthDays];
      data.push({
        date: dayNumber,
      });
      setSelectedMonthDays(data);
    }
  };
  const onEditBranchOperatingTime = (values) => {
    const data = editedData.map((d) => ({
      startTime: values.startTime
        ? dayjs(values.startTime, "HH:mm").format(
            "HH:mm"
          )
        : null,
      endTime: values.endTime
        ? dayjs(values.endTime, "HH:mm").format(
            "HH:mm"
          )
        : null,
      dayOfWeek: dayjs(d.date)
        .format("dddd")
        .toLowerCase(),
      branchClosed: values.branchClosed,
      date: d.date,
    }));

    dispatch(
      editBranchOperatingTimeThunk({
        id,
        data,
      })
    ).then((res) => {
      if (res.payload.length) {
        setShowDateCheckbox(false);
        setShowEditTimeModal(false);
        form.resetFields();
        setFlag(true);
        setSelectedMonthDays([]);
      }
      if (res.payload.code === "validation") {
        setShowErrorModal(true);
        setErrorData(res.payload);
      }
    });
  };

  const _disabledHours = (disableFor) => {
    let disabledHours = [];

    if (disableFor === "startTime") {
      for (
        let i = Number(endTime?.format("H")) + 1;
        i < 24;
        i++
      ) {
        disabledHours.push(i);
      }
      return [...disabledHours];
    }

    if (disableFor === "endTime") {
      for (
        let i = 0;
        i < Number(startTime?.format("H"));
        i++
      ) {
        disabledHours.push(i);
      }
      return [...disabledHours];
    }

    return disabledHours;
  };

  const _disabledMinute = (
    disableFor,
    selectedHour
  ) => {
    const minutes = [];

    if (disableFor === "startTime") {
      if (
        selectedHour ===
        Number(endTime?.format("H"))
      ) {
        const currentMinute = Number(
          endTime?.format("mm")
        );
        for (
          let i = currentMinute + 1;
          i <= 60;
          i++
        ) {
          minutes.push(i);
        }
        return minutes;
      }
    }

    if (disableFor === "endTime") {
      if (
        selectedHour ===
        Number(startTime?.format("H"))
      ) {
        const currentMinute = Number(
          startTime?.format("mm")
        );
        for (
          let i = currentMinute - 1;
          i >= 0;
          i--
        ) {
          minutes.push(i);
        }
        return minutes;
      }
    }
  };

  return (
    <div className="branch-schedule-view-container">
      <ConfirmModal
        backdrop="static"
        title="Hold up!"
        open={showErrorModal}
        confirmButtonText="Close"
        image={infoIcon}
        onConfirm={() => {
          setShowDateCheckbox(false);
          setShowEditTimeModal(false);
          form.resetFields();
          setFlag(true);
          setSelectedMonthDays([]);
          setShowErrorModal(false);
        }}
      >
        <div>
          This actions seems to have triggered a
          conflict with one of your shifts or
          jobs.
          <br />
          However, we've executed the action prior
          to and following the date(s) of
          conflict.
        </div>
        <div>
          You can resolve these conflicts and
          perform this action again for the
          following days,
        </div>

        <div className="d-flex flex-column gap-3 align-items-center">
          {errorData?.array?.map((data, idx) => (
            <div
              className="d-flex align-items-center gap-3"
              key={idx}
            >
              <div
                style={{ whiteSpace: "nowrap" }}
              >
                {data.date}
              </div>
              <div>
                {data.day[0].toUpperCase() +
                  data.day.slice(1)}
              </div>
              {/* <div>{data.reason}</div> */}
            </div>
          ))}
        </div>
        {errorData?.successCount > 0 && (
          <div>
            The Timing For '
            {errorData?.successCount}' Days Was
            Changed Successfully
          </div>
        )}
      </ConfirmModal>
      {addAbsenceModal && (
        <AddAbsenceForm
          setFlag={setFlag}
          setAddAbsenceModal={setAddAbsenceModal}
          addAbsenceModal={addAbsenceModal}
        />
      )}
      {showAddShiftModal && (
        <ShiftJobFormUpdated
          onSuccess={() => setFlag(true)}
          branchId={selectId}
          onDelete={onDelete}
          operatingTime={
            branchInfo?.operatingTime
          }
          selectedDate={selectedDate}
          showModal={showAddShiftModal}
          setHandleModal={() =>
            setShowAddshiftModal(
              !showAddShiftModal
            )
          }
          data={selectedData}
        />
      )}
      {showDeleteShiftModal && (
        <BulkDeleteShiftForm
          isBranch
          branchId={id}
          showDeleteShiftModal={
            showDeleteShiftModal
          }
          setShowDeleteShiftModal={
            setShowDeleteSHiftModal
          }
          setFlag={setFlag}
        />
      )}
      <AddModal
        title="Edit Time"
        handleModal={showEditTimeModal}
        setHandleModal={setShowEditTimeModal}
        primaryButtonText={`${
          branchClosed
            ? "Close Branch"
            : hasAccess(
                branchApiKeys.CHANGE_BRANCH_OPERATING_TIME
              )
            ? "Edit Time"
            : undefined
        }`}
        secondaryButtonText="Cancel"
        formId={formId}
        secondaryButtonHandler={() =>
          setShowEditTimeModal(false)
        }
      >
        <Form
          initialValues={{
            branchClosed: false,
          }}
          onFinish={onEditBranchOperatingTime}
          form={form}
          style={{ width: "100%" }}
          id={formId}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            label="Would you like to close the branch?"
            name="branchClosed"
          >
            <Radio.Group defaultValue={true}>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
          {branchClosed && (
            <div className="d-flex gap-2 align-items-start">
              <img
                className="mt-2"
                src={informationIcon}
                alt=""
              />
              <div>
                Proceeding with this action will
                result in the cancellation &
                deletion of all locum jobs and
                staff shifts respectively for all
                selected days.{" "}
              </div>
            </div>
          )}
          <div
            style={{ width: "100%" }}
            className="d-flex align-items-center gap-3"
          >
            <Form.Item
              hidden={branchClosed}
              label="Start Time"
              style={{ width: "100%" }}
              name="startTime"
              rules={[
                branchClosed
                  ? {}
                  : {
                      required: true,
                      message:
                        "Please Select Start Time",
                    },
              ]}
            >
              <TimePicker
                disabledHours={() =>
                  _disabledHours("startTime")
                }
                disabledMinutes={(time) =>
                  _disabledMinute(
                    "startTime",
                    time
                  )
                }
                inputReadOnly
                allowClear={false}
                style={{ width: "100%" }}
                className="text-input-field p-2 pe-3 t1"
                format={"HH:mm"}
                getPopupContainer={(trigger) =>
                  trigger.parentElement
                }
                minuteStep={5}
                onSelect={(value) =>
                  form.setFieldsValue({
                    startTime: value,
                  })
                }
              />
            </Form.Item>
            <Form.Item
              hidden={branchClosed}
              label="End Time"
              style={{ width: "100%" }}
              name="endTime"
              rules={[
                branchClosed
                  ? {}
                  : {
                      required: true,
                      message:
                        "Please Select Start Time",
                    },
              ]}
            >
              <TimePicker
                disabledHours={() =>
                  _disabledHours("endTime")
                }
                disabledMinutes={(time) =>
                  _disabledMinute("endTime", time)
                }
                allowClear={false}
                inputReadOnly
                className="text-input-field p-2 pe-3 t1"
                format={"HH:mm"}
                style={{ width: "100%" }}
                getPopupContainer={(trigger) =>
                  trigger.parentElement
                }
                minuteStep={5}
                onSelect={(value) =>
                  form.setFieldsValue({
                    endTime: value,
                  })
                }
              />
            </Form.Item>
          </div>
        </Form>
      </AddModal>
      <Toolbar
        primaryText="Schedule"
        showViewFilter
        dateRange={dateRange}
        mode="monthly"
        allViews
        view={view}
        showDatePicker
        onDateChange={onDateChange}
        handleViewChange={setView}
        setFlag={setFlag}
        showActionBtn
        actionItems={actionItems}
        cancelBtn={showDateCheckbox}
        cancelBtnHandler={() => {
          setShowDateCheckbox(false);
          setSelectedMonthDays([]);
        }}
        editTimeBtnHandler={() =>
          setShowEditTimeModal(true)
        }
        editTimeBtn={
          selectedMonthDays?.length > 0
        }
        deleteShiftHandler={() =>
          setShowDeleteSHiftModal(
            !showDeleteShiftModal
          )
        }
        deleteShiftBtn={true}
      />

      {!branchSchedule ? undefined : (
        <>
          {view === "daily" ? (
            <DayView
              handleAddShift={handleAddShift}
              onDelete={onDelete}
              branches={branchSchedule ?? []}
              branchDayView
              height="81px"
            />
          ) : view === "weekly" &&
            branchSchedule.length ? (
            <BranchWeeklyView
              dateRange={dateRange}
              showDateCheckbox={showDateCheckbox}
              onDaySelect={onDaySelect}
              addShift
              operatingTime={
                branchInfo?.operatingTime
              }
              scheduleData={branchSchedule}
              active={branchInfo.active}
              branch={branchInfo}
              onDelete={onDelete}
              handleAddShift={handleAddShift}
            />
          ) : (
            <>
              {view === "monthly" &&
                branchSchedule && (
                  <MonthlyView
                    showDateCheckbox={
                      showDateCheckbox
                    }
                    onDaySelect={onDaySelect}
                    addShift
                    operatingTime={
                      branchInfo?.operatingTime
                    }
                    scheduleData={branchSchedule}
                    active={branchInfo.active}
                    onDelete={onDelete}
                    handleAddShift={
                      handleAddShift
                    }
                  />
                )}
            </>
          )}
        </>
      )}
    </div>
  );
};
