import React from "react";

const RocketIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M2.05724 18.487C0.10501 20.4392 0 23.9976 0 23.9976C0 23.9976 3.55602 23.8926 5.51064 21.9404C7.46526 19.9882 8.27431 17.6326 7.31967 16.6804C6.36504 15.7257 4.00947 16.5348 2.05724 18.487Z"
        fill="url(#paint0_linear_21548_7423)"
      />
      <path
        opacity="0.2"
        d="M6.20514 16.7566C6.54642 16.7566 6.80656 16.8449 6.98078 17.0168C7.63709 17.6731 7.05476 19.7184 5.17174 21.6038C3.81139 22.9641 1.54651 23.3651 0.520277 23.4796C0.634833 22.4534 1.03578 20.1885 2.39375 18.8282C3.65148 17.5681 5.14788 16.7566 6.20514 16.7566ZM6.20514 16.2793C5.05719 16.2793 3.4534 17.0907 2.05724 18.4893C0.10501 20.4415 0 23.9999 0 23.9999C0 23.9999 3.55602 23.8949 5.51064 21.9427C7.46526 19.9904 8.27431 17.6349 7.31967 16.6826C7.0476 16.4058 6.66336 16.2793 6.20514 16.2793Z"
        fill="#424242"
      />
      <path
        d="M2.64919 19.1742C1.4201 20.4033 1.35327 22.642 1.35327 22.642C1.35327 22.642 3.59189 22.5751 4.82099 21.346C6.05008 20.1169 6.56081 18.6325 5.95939 18.0335C5.35797 17.4344 3.88067 17.9428 2.64919 19.1742Z"
        fill="url(#paint1_linear_21548_7423)"
      />
      <path
        d="M5.16938 19.1958C6.33881 20.444 7.87339 20.3128 7.99988 20.1075L9.06907 18.3701C9.13351 18.2651 9.07384 18.098 9.0285 18.0551L6.14311 15.1697C6.09777 15.1243 5.93786 15.0718 5.83285 15.1363L4.09542 16.2054C3.89256 16.3319 3.66344 17.5849 5.16938 19.1958Z"
        fill="#546E7A"
      />
      <path
        d="M13.0265 22.3701C12.7879 22.5634 12.4681 22.5204 12.4275 22.234C12.3153 21.4417 12.3893 19.8498 11.3917 18.8522C10.418 17.8785 8.83569 17.7353 8.83569 17.7353L14.7926 14.7998C14.7926 14.7998 15.9143 16.5301 15.2962 18.993C14.7807 21.0288 13.9 21.6565 13.0265 22.3701Z"
        fill="#0288D1"
      />
      <path
        opacity="0.2"
        d="M14.4553 15.7616C14.4983 15.8785 14.5436 16.0122 14.5842 16.1602C14.7465 16.752 14.8825 17.6947 14.5985 18.8164C14.2381 20.2532 13.6988 20.8689 13.0663 21.4083C12.9852 20.4966 12.8086 19.2556 11.8945 18.3439C11.5604 18.0098 11.169 17.7568 10.7848 17.5682L14.4553 15.7616ZM14.7894 14.7998L8.83252 17.7353C8.83252 17.7353 10.4172 17.8809 11.3886 18.8522C12.3862 19.8498 12.3122 21.4417 12.4243 22.234C12.4482 22.4035 12.5723 22.4894 12.7179 22.4894C12.8181 22.4894 12.9279 22.4488 13.0234 22.3701C13.8969 21.6565 14.7775 21.0288 15.2906 18.993C15.9135 16.5277 14.7894 14.7998 14.7894 14.7998Z"
        fill="#424242"
      />
      <path
        d="M1.69814 11.3845C1.46426 11.585 1.54779 11.9429 1.83418 11.9835C2.62653 12.0957 4.21838 12.0217 5.21598 13.0193C6.18971 13.993 6.39257 15.3725 6.39257 15.3725L9.2708 9.27951C9.2708 9.27951 7.74815 8.56592 5.53339 9.14109C4.00598 9.53965 2.49049 10.7019 1.69814 11.3845Z"
        fill="#0288D1"
      />
      <path
        opacity="0.2"
        d="M7.25253 9.63273C7.65109 9.63273 7.99476 9.67568 8.26683 9.72819L6.47688 13.5229C6.28596 13.1816 6.04014 12.8308 5.72272 12.5157C4.87548 11.6685 3.74901 11.4561 2.8612 11.3654C3.63207 10.7783 4.67978 10.1053 5.71079 9.83559C6.22629 9.69955 6.74418 9.63273 7.25253 9.63273ZM7.25253 8.91675C6.74896 8.91675 6.16901 8.97641 5.53418 9.14109C4.00676 9.53965 2.49366 10.7019 1.70132 11.3845C1.46743 11.585 1.55096 11.9429 1.83735 11.9835C2.6297 12.0957 4.22156 12.0217 5.21915 13.0193C6.19288 13.993 6.39574 15.3725 6.39574 15.3725L9.27158 9.27951C9.27158 9.27951 8.49833 8.91675 7.25253 8.91675Z"
        fill="#424242"
      />
      <path
        opacity="0.2"
        d="M5.89972 15.9381L8.26721 18.3056L7.52021 19.5204C7.49634 19.5228 7.47009 19.5228 7.44145 19.5228C7.20995 19.5228 6.40329 19.4631 5.69208 18.7042C4.79234 17.7424 4.65153 16.9906 4.66346 16.6971L5.89972 15.9381ZM5.94983 15.1052C5.90926 15.1052 5.86869 15.1148 5.83289 15.1339L4.09545 16.2031C3.89021 16.3295 3.66109 17.5825 5.16703 19.1935C5.93313 20.0121 6.85674 20.2388 7.43907 20.2388C7.74455 20.2388 7.95457 20.1767 7.99753 20.1051L9.06672 18.3677C9.13116 18.2627 9.07149 18.0956 9.02615 18.0527L6.14315 15.1697C6.11451 15.141 6.03336 15.1052 5.94983 15.1052Z"
        fill="#424242"
      />
      <path
        d="M16.8298 1.32251C17.7964 1.76642 19.0995 2.53012 20.3429 3.77354C20.782 4.21267 21.1567 4.66135 21.4813 5.10048C22.109 5.94772 22.5385 6.75678 22.8202 7.38922C22.1925 9.03836 21.0087 10.9572 19.8512 12.1147C15.1234 16.8425 9.90155 19.5417 7.19993 17.6301C7.04241 17.5179 6.88728 17.4153 6.74648 17.2888C4.4291 15.2053 7.08298 9.24122 11.9039 4.4203C13.1139 3.2103 15.1306 1.97405 16.8298 1.32251Z"
        fill="url(#paint2_radial_21548_7423)"
      />
      <path
        d="M15.5713 10.2938C16.7444 10.2938 17.6954 9.34281 17.6954 8.16972C17.6954 6.99663 16.7444 6.04565 15.5713 6.04565C14.3982 6.04565 13.4473 6.99663 13.4473 8.16972C13.4473 9.34281 14.3982 10.2938 15.5713 10.2938Z"
        fill="#455A64"
      />
      <path
        opacity="0.2"
        d="M15.5723 6.52297C16.0114 6.52297 16.4267 6.69481 16.7369 7.00506C17.0472 7.31532 17.219 7.73059 17.219 8.16972C17.219 8.60885 17.0472 9.02412 16.7369 9.33438C16.4267 9.64463 16.0114 9.81647 15.5723 9.81647C15.1331 9.81647 14.7179 9.64463 14.4076 9.33438C13.7656 8.69238 13.7656 7.64706 14.4076 7.00506C14.7179 6.69481 15.1307 6.52297 15.5723 6.52297ZM15.5723 6.04565C15.0281 6.04565 14.484 6.25329 14.0687 6.66855C13.2382 7.49909 13.2382 8.84274 14.0687 9.67327C14.484 10.0885 15.0281 10.2962 15.5723 10.2962C16.1164 10.2962 16.6605 10.0885 17.0758 9.67327C17.9063 8.84274 17.9063 7.49909 17.0758 6.66855C16.6605 6.25329 16.1164 6.04565 15.5723 6.04565Z"
        fill="#424242"
      />
      <path
        d="M11.191 14.3581C12.0399 14.3581 12.728 13.67 12.728 12.8211C12.728 11.9723 12.0399 11.2842 11.191 11.2842C10.3422 11.2842 9.65405 11.9723 9.65405 12.8211C9.65405 13.67 10.3422 14.3581 11.191 14.3581Z"
        fill="#455A64"
      />
      <path
        d="M16.8303 1.32262C16.3888 3.50157 20.236 7.7688 22.8206 7.38933C23.6249 5.31061 23.9543 3.90013 24.1738 1.47297C24.286 0.243876 23.9256 -0.106953 22.7872 0.0266964C19.7682 0.379912 18.5749 0.609024 16.8303 1.32262Z"
        fill="#E53935"
      />
      <path
        opacity="0.2"
        d="M23.1949 0.716466C23.3381 0.716466 23.4097 0.733172 23.4359 0.742718C23.455 0.785677 23.5027 0.952738 23.4622 1.40858C23.2498 3.75937 22.9443 5.09586 22.1543 7.13401C21.5481 8.72109 20.393 10.5612 19.3453 11.6089C15.6914 15.2627 11.8538 17.5348 9.32877 17.5348C8.64859 17.5348 8.07103 17.3701 7.61281 17.0455L7.53405 16.9906C7.41233 16.9071 7.30971 16.8331 7.22379 16.7567C6.83717 16.4083 6.63192 15.8761 6.61283 15.1744C6.546 12.6208 8.92782 8.40606 12.4075 4.92641C13.4981 3.83574 15.4217 2.62812 17.0828 1.99329L17.0971 1.98613C18.7844 1.29641 19.9586 1.07923 22.8655 0.737945C22.992 0.723626 23.1018 0.716466 23.1949 0.716466ZM23.1949 0.000488281C23.0708 0.000488281 22.9371 0.0100348 22.7868 0.0267409C19.7677 0.379956 18.5744 0.609069 16.8298 1.32266C15.1306 1.97181 13.1139 3.20807 11.9039 4.41807C7.08298 9.23898 4.4291 15.2031 6.74648 17.2866C6.8849 17.4107 7.04003 17.5157 7.19993 17.6278C7.79658 18.0503 8.51733 18.2484 9.32877 18.2484C12.1879 18.2484 16.1687 15.7973 19.8536 12.1148C21.0111 10.9573 22.1949 9.03851 22.8226 7.38937C23.6268 5.31065 23.9562 3.90018 24.1757 1.47302C24.2688 0.406209 24.0111 0.000488281 23.1949 0.000488281Z"
        fill="#424242"
      />
      <path
        opacity="0.2"
        d="M11.1895 11.7617C11.4735 11.7617 11.7384 11.8715 11.9389 12.072C12.1393 12.2725 12.2491 12.5374 12.2491 12.8214C12.2491 13.1054 12.1393 13.3703 11.9389 13.5708C11.7384 13.7713 11.4735 13.881 11.1895 13.881C10.9055 13.881 10.6406 13.7713 10.4401 13.5708C10.2396 13.3703 10.1298 13.1054 10.1298 12.8214C10.1298 12.5374 10.2396 12.2725 10.4401 12.072C10.6429 11.8715 10.9079 11.7617 11.1895 11.7617ZM11.1895 11.2844C10.7957 11.2844 10.4043 11.4348 10.1036 11.7355C9.50454 12.3345 9.50454 13.3083 10.1036 13.9073C10.4043 14.208 10.7957 14.3584 11.1895 14.3584C11.5833 14.3584 11.9747 14.208 12.2754 13.9073C12.8744 13.3083 12.8744 12.3345 12.2754 11.7355C11.977 11.4348 11.5833 11.2844 11.1895 11.2844Z"
        fill="#424242"
      />
      <defs>
        <linearGradient
          id="paint0_linear_21548_7423"
          x1="7.7168"
          y1="20.138"
          x2="-0.00238654"
          y2="20.138"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF6D00" />
          <stop offset="0.2487" stop-color="#FF9B00" />
          <stop offset="0.5135" stop-color="#FFC600" />
          <stop offset="0.721" stop-color="#FFE000" />
          <stop offset="0.8439" stop-color="#FFEA00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_21548_7423"
          x1="5.09903"
          y1="18.8559"
          x2="1.10268"
          y2="22.9737"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0044475" stop-color="#C62828" />
          <stop offset="0.2711" stop-color="#D75327" />
          <stop offset="0.5983" stop-color="#E98126" />
          <stop offset="0.8504" stop-color="#F59D25" />
          <stop offset="0.9953" stop-color="#F9A825" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_21548_7423"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.3263 4.66612) rotate(180) scale(8.70461)"
        >
          <stop offset="0.0112" stop-color="#F5F5F5" />
          <stop offset="0.9995" stop-color="#BDBDBD" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default RocketIcon;
