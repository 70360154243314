import moment from "moment/moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { TableComponet } from "../../../../components/UI/table";
import { TableToolbar } from "../../../../components/UI/table/tableToolBar";
import { startLoading, stopLoading } from "../../../../redux/loader";
import { getBankHolidaysThunk } from "../../../../redux/profile/profileThunk";
import { AddScheduleHoliday } from "./components/addScheduleHoliday";

export const ProfileBankHolidays = () => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [selectedHolidayDate, setSelectedHolidayDate] = useState();

    const [holidays, setHolidays] = useState([])
    const columns = React.useMemo(
        () => [
            {
                Header: "Title",
                accessor: "title",

            },

            {
                Header: "Date",
                accessor: "date",
                Cell: ({ row: { original } }) => {
                    return moment(original.date).format('DD-MM-YYYY')
                },
            },
            {
                Header: "",
                accessor: "action",
                Cell: ({ row: { original } }) => {
                    return (
                        <div
                            className="py-1 px-3 m-auto"
                            style={{ border: '1px solid #51C2BC', color: '#51C2BC', borderRadius: '50px', width: 'fit-content' }}
                            onClick={() => {
                                setShowModal(true)
                                setSelectedHolidayDate(
                                    original.date
                                )
                            }}
                        >

                            Add to Schedule
                        </div>
                    );
                },
            },
        ],
        []
    );

    useEffect(() => {
        dispatch(
            getBankHolidaysThunk()
        ).then(res => {
            dispatch(startLoading())
            const holidaysJson = res.payload
            let comingEvents = [];
            const currentDate = moment(new Date()).format('YYYY-MM-DD');
            const endDate = moment().add(1, 'years').format('YYYY-MM-DD');
            for (var object in holidaysJson) {
                if (holidaysJson[object] instanceof Object) {
                    if (holidaysJson[object].division === 'england-and-wales') {
                        const events = holidaysJson[object].events
                        for (let event of events) {
                            if (event.date >= currentDate && event.date <= endDate) {
                                comingEvents.push({
                                    division: holidaysJson[object].division,
                                    title: event.title,
                                    date: event.date,
                                    notes: event.notes
                                });
                            }
                        }
                    }
                }
            }
            setHolidays(comingEvents)
            dispatch(stopLoading())
        })
    }, [dispatch]);


    return (
        <div className="">
            <TableToolbar headingText="Bank Holidays" />
            <div style={{ padding: "5px 24px" }}>
                {
                    holidays.length <= 0 ? null :
                        <TableComponet
                            rowCursor="pointer"
                            isBorderShown
                            columns={columns}
                            data={holidays}
                        />
                }
            </div>
            {
                showModal &&
                <AddScheduleHoliday
                    showModal={showModal}
                    setShowModal={setShowModal}
                    selectedHolidayDate={selectedHolidayDate}
                />
            }
        </div>
    );
};
