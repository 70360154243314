import { useMutation } from "@tanstack/react-query";
import { Form, Input, Select } from "antd";
import React, { useEffect, useId, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import Dropwdown from "../../../../../assets/icons/downDrop.svg";
import CommonModal from "../../../../../components/UI/modals/CommonModal/CommonModal";
import { ConfirmModal } from "../../../../../components/UI/modals/confirmModal";
import CustomAvatar from "../../../../../components/comon/CustomAvatar";
import { addStaffReview } from "../../../../../redux/staffs/staffsAPI";
import { getStaffsListThunk } from "../../../../../redux/staffs/staffsThunk";
import { RouterConfig } from "../../../../../routerConfig";
import { notify, validateEmptySpace } from "../../../../../utils/helper";

import dayjs from "dayjs";
import { QUESTIONS } from "../../../../../utils/constant";
import AddQuestionCard from "./components/AddQuestionCard";
import "./style.scss";
const AddQuestion = () => {
  const [showDeleteReview, setshowDeleteReview] = useState(false);

  const [addQuestionModal, setaddQuestionModal] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [supervisorId, setsupervisorId] = useState("");
  const { staffs, staffInfo } = useSelector((state) => state.staffs);
  const [selectedItem, setSelectedItem] = useState(null);
  const [form] = Form.useForm();
  const question = Form.useWatch("question", form);
  const answer = Form.useWatch("answer", form);
  const { staffId } = useParams();
  const dispatch = useDispatch();
  const [showDeleteQuestion, setshowDeleteQuestion] = useState(false);
  const navigate = useNavigate();
  const toggleshowDeleteQuestion = () => {
    setshowDeleteQuestion(!showDeleteQuestion);
  };

  // add review
  const { mutate } = useMutation({
    mutationFn: addStaffReview,
  });

  const toggleaddQuestionModal = () => {
    setaddQuestionModal(!addQuestionModal);
  };
  const toggleshowDeleteReview = () => {
    setshowDeleteReview(!showDeleteReview);
  };

  const handleAnswerInput = (v, index) => {
    const newQuestions = [...questions];
    newQuestions[index].answer = v;
    setQuestions(newQuestions);
  };

  const handleAddNewQuestion = () => {
    form.validateFields().then((values) => {
      let temp = [...questions];
      temp.push({ ...values, id: dayjs().unix() });
      setQuestions(temp);
      toggleaddQuestionModal();
    });
  };

  const onSubmit = () => {
    if (supervisorId?.length === 0) {
      notify("error", "Please select supervisor");
      return;
    }

    //check answer is empty or not
    let flag = false;
    let temp = [...questions];
    questions?.forEach((item, index) => {
      if (item?.answer?.trim()?.length === 0) {
        flag = true;
        temp[index] = {
          ...item,
          hasError: true,
        };
      } else {
        temp[index] = {
          ...item,
          hasError: false,
        };
      }
    });

    setQuestions(temp);
    if (flag) return;

    let reviews = [];

    questions?.forEach((item) => {
      reviews.push({
        question: item.question,
        answer: item.answer,
        supervisorId,
      });
    });

    const payload = {
      id: staffId,
      data: {
        reviews,
      },
    };

    mutate(payload, {
      onSuccess: () => {
        form.resetFields();
        setQuestions([...QUESTIONS]);
        navigate(
          generatePath(RouterConfig.branchAdminStaffPerformanceReview, {
            staffId,
          })
        );
      },
    });
  };

  const handleDelete = () => {
    let temp = [...questions];
    temp.splice(selectedItem.index, 1);
    setQuestions(temp);
    toggleshowDeleteQuestion();
  };

  useEffect(() => {
    dispatch(
      getStaffsListThunk({
        pagination: { page: 1, perPage: 1000 },
      })
    );
  }, []);

  useEffect(() => {
    setsupervisorId(staffInfo?.id);
  }, [staffInfo, staffs]);

  const id = useId();

  useEffect(() => {
    const temp = [];
    QUESTIONS?.forEach((item, index) => {
      temp.push({ ...item, id: dayjs().unix() + index });
    });
    setQuestions(temp);
  }, [id]);

  return (
    <div id={id} style={{ position: "relative" }} className="staffReview">
      <div className="staffReview-inner">
        <>
          <div className="search-section inside-card">
            <div className="input-wrap">
              {/* <label htmlFor="answer">Supervisor</label> */}
              <div className="select-wrap">
                <Select
                  placeholder="Select supervisor"
                  bordered={false}
                  className="text-input-field p-1 t1"
                  suffixIcon={<img src={Dropwdown} alt="dropdown" />}
                  onChange={(val) => {
                    setsupervisorId(val);
                  }}
                  value={supervisorId}
                >
                  {staffs?.rows?.map((data, index) => (
                    <Select.Option key={data?.id} value={data?.id}>
                      <CustomAvatar src={data?.image} alt={data?.name} />
                      <span className="ms-1" />
                      {data?.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="body-section ">
            {questions?.map((question, index) => (
              <AddQuestionCard
                key={question?.id + id}
                staffId={staffId}
                data={question}
                index={index}
                handleAnswerInput={handleAnswerInput}
                toggleshowDeleteQuestion={() => {
                  toggleshowDeleteQuestion();
                  setSelectedItem({ ...question, index });
                }}
                hasError={Boolean(question?.hasError)}
              />
            ))}

            <button
              className="btn custom_btn outlined bordered-btn"
              onClick={toggleaddQuestionModal}
            >
              + Add Custom Question
            </button>
            <div className="bottom-section">
              <button
                className="btn custom_btn outlined"
                onClick={() => {
                  navigate(
                    generatePath(
                      RouterConfig.branchAdminStaffPerformanceReview,
                      {
                        staffId,
                      }
                    )
                  );
                }}
              >
                {" "}
                Cancel
              </button>
              {questions?.length > 0 ? (
                <button className="btn custom_btn" onClick={onSubmit}>
                  {" "}
                  Publish
                </button>
              ) : null}
            </div>
          </div>
        </>
      </div>
      <ConfirmModal
        title="Delete Performance Review"
        open={showDeleteReview}
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        onClose={toggleshowDeleteReview}
      >
        <div className="performance-review-modal">
          <p className="confirm_text">
            Are you sure you want to delete performance review ?
          </p>
        </div>
      </ConfirmModal>

      <CommonModal
        title="Add Custom Question"
        handleModal={addQuestionModal}
        primaryButtonText="Add"
        secondaryButtonText="Cancel"
        setHandleModal={toggleaddQuestionModal}
        primaryButtonHandler={handleAddNewQuestion}
        secondaryButtonHandler={toggleaddQuestionModal}
      >
        <div className="add-q-modal">
          <Form
            form={form}
            layout="vertical"
            // onValuesChange={onValuesChange}
            autoComplete="off"
          >
            <Form.Item
              label="Question"
              name="question"
              rules={[
                {
                  required: true,
                  message: "This is a required field.",
                },
                {
                  validator: validateEmptySpace,
                },
              ]}
            >
              <div className="input-wrap">
                <Input.TextArea rows={2} placeholder="Your Text..." />
              </div>
            </Form.Item>
            <Form.Item
              label="Answer"
              name="answer"
              rules={[
                {
                  required: true,
                  message: "This is a required field.",
                },
                {
                  validator: validateEmptySpace,
                },
              ]}
            >
              <div className="input-wrap">
                <Input.TextArea rows={3} placeholder="Your Text..." />
              </div>
            </Form.Item>
          </Form>
        </div>
      </CommonModal>

      <ConfirmModal
        title="Delete Question"
        open={showDeleteQuestion}
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        onClose={toggleshowDeleteQuestion}
        onConfirm={handleDelete}
      >
        <div className="performance-review-modal">
          <p className="confirm_text">
            Are you sure you want to delete this{" "}
            <span>“{selectedItem?.question}” </span>
          </p>
        </div>
      </ConfirmModal>
    </div>
  );
};

export default AddQuestion;
