import { Form, Input, Radio, Select } from "antd";
import moment from "moment/moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { AddModal } from "../../../../components/UI/modals/addModal";
import { TableComponet } from "../../../../components/UI/table";
import { TableToolbar } from "../../../../components/UI/table/tableToolBar";
import { EmptyData } from "../../../../components/comon/EmptyData";
import {
  delteProfileAccrediationsThunk,
  getProfileAccrediationsThunk,
  saveProfileAccrediationsThunk,
} from "../../../../redux/profile/profileThunk";
import { getRolesThunk } from "../../../../redux/schedule/scheduleThunk";
import { settingsApiKeys } from "../../../../utils/apiKeyConstants";
import { hasAccess, notify } from "../../../../utils/helper";

const documentTypeOptions = [
  { value: 0, label: "Free Text" },
  { value: 1, label: "Document" },
];

export const ProfileAccrediations = () => {
  const { staffId } = useParams();
  const dispatch = useDispatch();
  const { accrediationsList } = useSelector((state) => state.profile);
  const { roles } = useSelector((state) => {
    return state.schedule;
  });

  const formId = useMemo(() => "form", []);
  const [form] = Form.useForm();
  const isDocument = Form.useWatch("isDocument", form);

  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);

  const [showAccrediationModal, setShowAccrediationModal] = useState(false);

  const [actionType, setActionType] = useState();
  const [accrediationId, setAccrediationId] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const columns = React.useMemo(
    () => [
      {
        Header: "Accreditation Title",
        accessor: "title",
      },
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: ({ row: { original } }) => {
          return original.createdAt
            ? moment(original.createdAt).format("DD-MM-YYYY")
            : "-";
        },
      },
      {
        Header: "Mandatory",
        accessor: "isMandatory",
        Cell: ({ row: { original } }) => {
          return original.isMandatory ? "Yes" : "No";
        },
      },
      {
        Header: "Field For",
        accessor: "field",
        Cell: ({ row: { original } }) => {
          const data = roles.find((data) => data.id === original.roleId);
          return data?.label ?? "Both";
        },
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row: { original } }) => {
          return (
            <div>
              <Dropdown icon="ellipsis vertical" pointing="top right">
                <Dropdown.Menu direction="left">
                  <Dropdown.Item
                    text="Edit"
                    onClick={() => {
                      form.setFieldsValue({
                        title: original.title,
                        isExpiryRequired: original.isExpiryRequired,
                        isDocument: original.isDocument,
                        isMandatory: original.isMandatory,
                        roleId: original.roleId ? original.roleId : "all",
                      });
                      setAccrediationId(original.id);
                      setActionType("Edit");
                      setShowAccrediationModal(true);
                    }}
                  />
                  <Dropdown.Item
                    style={{ color: "#EF5350" }}
                    onClick={() => {
                      setActionType("Delete");
                      setAccrediationId(original.id);
                    }}
                    text="Delete"
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [roles]
  );

  const getAccrediationList = useCallback(() => {
    if (hasAccess(settingsApiKeys.GET_ACCREDITATION_RULES))
      dispatch(
        getProfileAccrediationsThunk({
          filters: {
            title: searchQuery.trim(),
          },
          pagination: { page: currentPageCount, perPage: perPageCount },
        })
      );
  }, [currentPageCount, dispatch, perPageCount, searchQuery]);

  useEffect(() => {
    if (actionType === "Delete" && accrediationId) {
      dispatch(
        delteProfileAccrediationsThunk({
          id: accrediationId,
        })
      ).then((res) => {
        if (res.payload.status === "success") {
          notify("success", "Deleted Accrediations");
          setAccrediationId(undefined);
          setActionType("");
          getAccrediationList();
        }
      });
    }
  }, [actionType, accrediationId, dispatch, getAccrediationList]);

  const {
    count: totalDataCount,
    page,
    pages: totalPagesCount,
    perPage,
    rows: accrediations,
  } = useMemo(() => accrediationsList, [accrediationsList]);

  useEffect(() => {
    if (searchQuery !== "") return;
    getAccrediationList();
    dispatch(
      getRolesThunk({
        group: ["locum", "staff"],
        type: "Default",
      })
    );
  }, [
    dispatch,
    staffId,
    currentPageCount,
    perPageCount,
    searchQuery,
    getAccrediationList,
  ]);

  useEffect(() => {
    if (!page && !perPage) return;
    setPerPageCount(perPage);
    setCurrentPageCount(page);
  }, [page, perPage]);

  const onAction = useCallback(
    (values) => {
      const reqBody = {
        ...values,
        roleId: values?.roleId === "all" ? null : values.roleId,
        id: actionType === "Edit" ? accrediationId : undefined,
      };
      dispatch(saveProfileAccrediationsThunk(reqBody)).then((res) => {
        if (res.payload) {
          setShowAccrediationModal(false);
          form.resetFields();
          setAccrediationId(undefined);
          setActionType("");
        }
      });
    },
    [accrediationId, actionType, dispatch, form]
  );

  const onSearch = useCallback(
    (e) => {
      getAccrediationList();
    },
    [getAccrediationList]
  );

  return (
    <div>
      <div className="">
        <TableToolbar
          headingText="Accrediations"
          showSecondarySearchField
          primaryBtnHandler={() => {
            setShowAccrediationModal(true);
            setActionType("Add");
            form.resetFields();
          }}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onSearch={onSearch}
          primaryBtnText="New Accrediation"
          SecondarySearchFieldPlaceHolder="Search by Accreditation Title"
        />
        <div style={{ padding: "5px 24px" }}>
          {accrediationsList?.rows?.length <= 0 ? (
            <EmptyData title="Accrediations" />
          ) : (
            <TableComponet
              currentPageCount={currentPageCount}
              rowCursor="pointer"
              perPageCount={perPageCount}
              totalDataCount={totalDataCount}
              setCurrentPage={setCurrentPageCount}
              setPerPageCount={setPerPageCount}
              showPagination
              maxPageCount={totalPagesCount}
              isBorderShown
              columns={columns}
              data={accrediations || []}
            />
          )}
        </div>
      </div>

      <AddModal
        title={`${actionType} Accrediation`}
        handleModal={showAccrediationModal}
        setHandleModal={setShowAccrediationModal}
        primaryButtonText={`${actionType} Accrediations`}
        secondaryButtonText="Cancel"
        formId={formId}
        secondaryButtonHandler={() => setShowAccrediationModal(false)}
      >
        <Form
          onFinish={onAction}
          form={form}
          id={formId}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Please Enter Title!" }]}
          >
            <Input
              onBlur={(e) =>
                form.setFieldsValue({ title: e.target.value.trim() })
              }
              maxLength={50}
              placeholder="Title"
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>
          <Form.Item
            label="Get it in a form of"
            name="isDocument"
            rules={[{ required: true, message: "Please Select Type!" }]}
          >
            <Select
              placeholder="Type"
              label="Type"
              dropdownStyle={{ zIndex: 2000 }}
              options={documentTypeOptions}
              bordered={false}
              className="text-input-field p-1 pe-3 t1"
            />
          </Form.Item>

          <Form.Item
            label="Publish for"
            name="roleId"
            rules={[{ required: true, message: "Please Select Role!" }]}
          >
            <Select
              // defaultValue='all'
              className="text-input-field p-1 pe-3 t1"
              bordered={false}
              dropdownStyle={{ zIndex: 2000 }}
              placeholder="Publish For"
            >
              <Select.Option value="all">ALL</Select.Option>
              {roles.map((data, idx) => (
                <Select.Option key={idx} value={data.id}>
                  {data.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Do you want this Accrediation to be mandatory?"
            name="isMandatory"
            rules={[{ required: true, message: "Select an Option!" }]}
          >
            <Radio.Group>
              <Radio value={1}>Yes</Radio>
              <Radio value={0}>No</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Do you want the expiry date for this Accrediation?"
            name="isExpiryRequired"
            hidden={isDocument === 0 ? true : false}
            rules={
              isDocument !== 0
                ? [{ required: true, message: "Select an Option!" }]
                : []
            }
          >
            <Radio.Group>
              <Radio value={1}>Yes</Radio>
              <Radio value={0}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </AddModal>
    </div>
  );
};
