import { Tooltip } from "antd";
import moment from "moment/moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { TableComponet } from "../../../components/UI/table";
import { TableToolbar } from "../../../components/UI/table/tableToolBar";
import { EmptyData } from "../../../components/comon/EmptyData";
import { getStaffAbsencesThunk } from "../../../redux/staffs/staffsThunk";
import { staffAbsencesType } from "../../../utils/constant";
import { truncateString } from "../../../utils/helper";

export const StaffAbsences = () => {
  const dispatch = useDispatch();
  const { staffAbsences } = useSelector((state) => state.staffs);
  const { user } = useSelector((state) => state.auth);

  const [currentPageCount, setCurrentPageCount] = useState(1);
  const [perPageCount, setPerPageCount] = useState(10);
  const [searchParams] = useSearchParams();

  const columns = React.useMemo(
    () => [
      {
        Header: "From",
        accessor: "from",
        Cell: ({ row: { original } }) => {
          return original.from
            ? moment(original.from).format("DD-MM-YYYY")
            : "-";
        },
      },
      {
        Header: "Absence Type",
        accessor: "type",
        Cell: ({ row: { original } }) => {
          return staffAbsencesType[original.type];
        },
      },
      {
        Header: "Notes",
        accessor: "notes",
        Cell: ({ row: { original } }) => {
          return original.notes ? (
            <Tooltip title={original?.notes}>
              {truncateString(original.notes, 120)}
            </Tooltip>
          ) : (
            "-"
          );
        },
      },
    ],
    []
  );

  const {
    count: totalDataCount,
    page,
    pages: totalPagesCount,
    perPage,
    rows: staffAbsencesList,
  } = useMemo(() => staffAbsences, [staffAbsences]);

  useEffect(() => {
    dispatch(
      getStaffAbsencesThunk({
        staffId: user.id,
        filters: {
          from: searchParams.get("startDate") ?? undefined,
          to: searchParams.get("endDate") ?? undefined,
        },
        pagination: { page: currentPageCount, perPage: perPageCount },
      })
    );
  }, [dispatch, user, currentPageCount, perPageCount, searchParams]);

  useEffect(() => {
    if (!page && !perPage) return;
    setPerPageCount(perPage);
    setCurrentPageCount(page);
  }, [page, perPage]);

  return (
    <div>
      <div className="">
        <TableToolbar headingText="Leaves" />
        <div style={{ padding: "5px 24px" }}>
          {staffAbsencesList?.length <= 0 ? (
            <EmptyData title="Leave Data " />
          ) : (
            <TableComponet
              currentPageCount={currentPageCount}
              rowCursor="pointer"
              perPageCount={perPageCount}
              totalDataCount={totalDataCount}
              setCurrentPage={setCurrentPageCount}
              setPerPageCount={setPerPageCount}
              showPagination
              maxPageCount={totalPagesCount}
              isBorderShown
              columns={columns}
              data={staffAbsencesList || []}
            />
          )}
        </div>
      </div>
    </div>
  );
};
