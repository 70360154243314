import { Input } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import PencilIcon from "../../../assets/icons/pencil.svg";
import { ATTENDANCE_TYPE } from "../../../utils/constant";
import { minuteTohour } from "../../../utils/dates";
import { getTotalMinutes } from "../../../utils/helper";

const BreakTime = ({ isInEditMode, index }) => {
  const [input, setinput] = useState("");
  const [editInput, seteditInput] = useState(false);
  const { staffTimeSheetEdited } = useSelector((s) => s.staffs);
  const [isValueEdited, setIsValueEdited] = useState(false);
  const handleChange = (event) => {
    setinput(event.target.value);
    setIsValueEdited(true);
  };

  useEffect(() => {
    if (!isInEditMode) {
      seteditInput(false);
      setIsValueEdited(false);
    }
  }, [isInEditMode]);

  const shiftDetails = useMemo(() => {
    const details = staffTimeSheetEdited?.[index];

    if (
      details?.type === ATTENDANCE_TYPE.NO_SHIFT ||
      details?.leaveType?.length > 0
    ) {
      setinput("");
    }

    return details;
  }, [index, staffTimeSheetEdited]);

  const isEmpty = !shiftDetails?.lunchStartTime || !shiftDetails?.lunchEndTime;

  const breakTime = useMemo(() => {
    if (isEmpty) {
      if (!isValueEdited) {
        setinput("");
      }
      return null;
    }
    const time = getTotalMinutes(
      shiftDetails?.lunchStartTime,
      shiftDetails?.lunchEndTime
    );

    if (!isValueEdited) {
      setinput(time);
    }

    return minuteTohour(time);
  }, [
    isEmpty,
    isValueEdited,
    shiftDetails?.lunchEndTime,
    shiftDetails?.lunchStartTime,
  ]);

  if (shiftDetails?.type === ATTENDANCE_TYPE.NO_SHIFT) {
    return <div />;
  }

  return (
    <div className={`no-user-select ${isInEditMode ? "edit_td" : ""}`}>
      {editInput ? (
        <Input
          value={input}
          className="modal_table_input"
          onChange={handleChange}
        />
      ) : (
        <div className="d-flex align-items-center justify-content-start">
          <p className="time">{breakTime}</p>{" "}
          {isInEditMode && (
            <button
              className="btn edit_button"
              onClick={() => seteditInput(true)}
            >
              <img src={PencilIcon} alt="pencil" />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default BreakTime;
