import React from "react";
import NoBranchImg from "../../../assets/img/blankExpense.png";
const NoExpense = () => {
  return (
    <div className=" d-flex align-items-center justify-content-center flex-column px-2 py-4" style={{height:'calc(100% - 100px)'}}>
      <img src={NoBranchImg} alt="nobranch" className="mb-3" />

      <p
        className="text-center "
        style={{
          color: "#000",
          maxWidth: "263px",
        }}
      >
      No Expenses 
      </p>
    </div>
  );
};

export default NoExpense;
