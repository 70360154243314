import React from "react";

const GreenRightArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.77487 1.83737C3.81261 1.79954 3.85744 1.76952 3.90679 1.74904C3.95615 1.72856 4.00906 1.71802 4.06249 1.71802C4.11593 1.71802 4.16884 1.72856 4.2182 1.74904C4.26755 1.76952 4.31238 1.79954 4.35012 1.83737L9.22512 6.71237C9.26295 6.75011 9.29297 6.79494 9.31345 6.84429C9.33393 6.89365 9.34447 6.94656 9.34447 6.99999C9.34447 7.05343 9.33393 7.10634 9.31345 7.1557C9.29297 7.20505 9.26295 7.24988 9.22512 7.28762L4.35012 12.1626C4.27384 12.2389 4.17037 12.2818 4.06249 12.2818C3.95461 12.2818 3.85115 12.2389 3.77487 12.1626C3.69859 12.0863 3.65573 11.9829 3.65573 11.875C3.65573 11.7671 3.69859 11.6637 3.77487 11.5874L8.36306 6.99999L3.77487 2.41262C3.73704 2.37488 3.70702 2.33005 3.68654 2.2807C3.66606 2.23134 3.65552 2.17843 3.65552 2.12499C3.65552 2.07156 3.66606 2.01865 3.68654 1.96929C3.70702 1.91994 3.73704 1.87511 3.77487 1.83737Z"
        fill="#0BA69B"
        stroke="#0BA69B"
      />
    </svg>
  );
};

export default GreenRightArrow;
