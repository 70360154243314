import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  cancelJobThunk,
  getJobCancelReasonsThunk,
  getLocumShiftInfoThunk,
  getWorkedLocumThunk,
  updateJobStatusThunk,
  ratingShiftThunk,
} from "../../../../redux/locum-shifts/locumShiftsThunk";

import { useState } from "react";
import { DetailsComponent } from "../../../../components/locum-shifts/detailsComponent";
import { ContactDetail } from "../../../../components/locum-shifts/contactDetail";
import { CoveredExpense } from "../../../../components/locum-shifts/coveredExpense";
import { LocumsRequestsTab } from "../../../../components/locum-shifts/locumsDetailsTab";
import { ConfirmModal } from "../../../../components/UI/modals/confirmModal";
import { notify } from "../../../../utils/helper";
import { hasAccess } from "../../../../utils/helper";
import {
  locumApiKeys,
  locumShiftApiKeys,
  staffApiKeys,
} from "../../../../utils/apiKeyConstants";
import { Button, Form, Input, Rate, Select } from "antd";
import { useMemo } from "react";
import { ShiftJobForm } from "../../../../components/comon/form";
import { deleteJobThunk, getRolesThunk } from "../../../../redux/schedule/scheduleThunk";
import { ShiftJobFormUpdated } from "../../../../components/comon/addShiftForm";

const options = [
  { value: "edit", label: "Edit Job" },
  { value: "cancel", label: "Cancel Job" },
];

export const LocumJobDetails = () => {
  const dispatch = useDispatch();
  const { shiftId } = useParams();
  const [shiftDetails, setShiftDetails] = useState();
  const [showEditJobModal, setShowEditJobModal] = useState(false);
  const [showCancelJobModal, setShowCancelJobModal] = useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [rating, setRating] = useState();
  const [ratingValue, setRatingValue] = useState();
  const [flag, setFlag] = useState(true);
  const { shiftInfo, workedLocumsList, cancelReasons } = useSelector(
    (state) => state.locumShifts
  );

  const navigate = useNavigate()

  const [form] = Form.useForm();
  const formId = useMemo(() => "cancelForm", []);

  useEffect(() => {
    if (!shiftInfo) return;
    setShiftDetails({
      ...shiftInfo,
      isJob: true,
    });
    setRatingValue(shiftInfo?.staff && shiftInfo?.staff[0]?.rating);
  }, [shiftInfo, workedLocumsList]);

  useEffect(() => {
    if (!flag) return;

    if (hasAccess(locumShiftApiKeys.GET_JOB_BY_ID)) {
      dispatch(
        getLocumShiftInfoThunk({
          shiftId,
        })
      ).then(res => {
        if (res.payload)
          if (hasAccess(staffApiKeys.GET_STAFF_WORKED_LOCUMS)) {
            dispatch(getWorkedLocumThunk({ roleId: res.payload?.roleType.id }));
          }
      })
    }
    dispatch(
      getRolesThunk({
        group: ["staff", "locum"],
        type: "Default",
      })
    );
    if (hasAccess(locumShiftApiKeys.GET_CANCELLATION_REASON)) {
      dispatch(getJobCancelReasonsThunk());
    }
    setFlag(false);
  }, [dispatch, shiftId, flag]);

  // const handleSelect = (e, { name, value }) => {
  //   if (name === 'locum' && hasAccess(locumShiftApiKeys.INVITE_LOCUM)) {
  //     dispatch(inviteLocumForJobThunk({
  //       jobId: shiftId,
  //       locumId: value
  //     }))
  //     return
  //   }
  //   if (name === "action" && value === "cancel") {
  //   if (!flag) return
  //   dispatch(
  //     getLocumShiftInfoThunk({
  //       shiftId,
  //     })
  //   ).then(res => {
  //     setFlag(false)
  //   });
  //   dispatch(getWorkedLocumThunk());
  //   dispatch(getJobCancelReasonsThunk());
  //   dispatch(getRolesThunk({
  //     group: ["locum"],
  //     type: "Default"
  //   }))
  // }, [dispatch, shiftId, flag]);

  const handleSelect = (value) => {
    if (value === "cancel") {
      setShowCancelJobModal(true);
      return;
    }
    setShowEditJobModal(true);
  };

  const onCancelJob = (values) => {
    dispatch(
      cancelJobThunk({
        jobId: shiftId,
        cancelData: values,
      })
    ).then((res) => {
      setShowCancelJobModal(false);
    });
  };

  const handleLocumStatus = (btnStatus) => {
    if (shiftInfo?.status === "completed") {
      dispatch(
        updateJobStatusThunk({
          shiftId,
          status: "payment_approved",
        })
      ).then((res) => {
        if (res) {
          notify(
            "success",
            'The current job has been shifted to the "Payment Approved" status. '
          );
        }
      });
    } else if (shiftInfo?.status === "payment_approved") {
      dispatch(
        updateJobStatusThunk({
          shiftId,
          status: "paid",
        })
      ).then((res) => {
        if (res) {
          notify(
            "success",
            'The current job has been shifted the "Paid" status, you can now rate this Locum Job.'
          );
        }
      });
    } else if (btnStatus === "Rate Locum") {
      setShowRatingModal(!showRatingModal);
    }
  };

  const handleRatings = () => {
    dispatch(
      ratingShiftThunk({
        jobId: shiftId * 1,
        value: rating,
      })
    ).then((res) => {
      dispatch(
        getLocumShiftInfoThunk({
          shiftId,
        })
      );
    });
    setShowRatingModal(false);
  };

  const shiftData = useMemo(() => {
    const data = { ...shiftDetails }
    if (data.status === "pending" || data.status === 'unfilled') {
      delete data.staff
      return data
    }
    return data

  }, [shiftDetails])

  const onDelete = (data) => {
    dispatch(
      deleteJobThunk({
        jobId: data.id,
      })
    ).then((res) => {
      if (res.payload === "success") {
        navigate('/locum-jobs')
      }
    });
  }

  return (
    <div className="d-flex p-5" style={{ gap: 30 }}>
      <div
        className="d-flex flex-column"
        style={{
          height: "100%",
          gap: 20,
          width: shiftDetails?.status === "cancelled" ? "100%" : "70%",
        }}
      >
        <div className="d-flex justify-content-between">
          <div className="heading4">Job Details</div>
          <div className="d-flex align-items-center gap-3">
            {(shiftInfo?.status === "completed" ||
              shiftInfo?.status === "payment_approved" ||
              shiftInfo?.status === "paid") && (
                <Button
                  size="large"
                  type="primary"
                  onClick={(e) => handleLocumStatus(e.target.innerText)}
                >
                  {(shiftInfo?.status === "completed" &&
                    " Move to Payment Approved") ||
                    (shiftInfo?.status === "payment_approved" &&
                      "Move to Paid") ||
                    (shiftInfo?.status === "paid" &&
                      hasAccess(locumApiKeys.CREATE_UPDATE_RATING) &&
                      "Rate Locum")}
                </Button>
              )}
            {(shiftDetails?.status === "unfilled" || shiftDetails?.status === 'pending' || shiftDetails?.status === 'active') && (
              <div style={{ width: 200 }}>
                <Select
                  placeholder="Actions"
                  value={null}
                  className="text-input-field  p-1 t1"
                  bordered={false}
                  options={
                    hasAccess(locumShiftApiKeys.CANCEL_JOB)
                      ? options
                      : options.filter((option) => option.value !== "cancel")
                  }
                  onChange={handleSelect}
                />
              </div>
            )}
          </div>
        </div>

        <div
          className="border rounded d-flex flex-column"
          style={{ height: "100%", gap: 20 }}
        >
          <DetailsComponent shiftDetails={shiftDetails} />
          <ContactDetail shiftDetails={shiftDetails} />
          <CoveredExpense editExpense shiftDetails={shiftDetails} />
          {
            shiftDetails?.termConditions &&
            <div className="p-5 d-flex flex-column" style={{ gap: 29 }}>
              <div className="d-flex align-items-center" style={{ gap: 15 }}>
                <div className="h5 fw-bold">Terms & Condition</div>
              </div>
              <div className="p3">{shiftDetails?.termConditions}</div>
            </div>
          }
        </div>
      </div>
      {shiftDetails?.status !== "cancelled" && <LocumsRequestsTab setFlag={setFlag} />}
      <ConfirmModal
        title="Cancel Job"
        description="Are you sure you want to cancel this job?"
        open={showCancelJobModal}
        onClose={() => setShowCancelJobModal(false)}
        confirmButtonText="Confirm"
        formId={formId}
      >
        <Form
          form={form}
          id={formId}
          layout="vertical"
          onFinish={onCancelJob}
          autoComplete="off"
        >
          <Form.Item
            label="Cancel Reason"
            name="cancelReason"
            rules={[
              { required: true, message: "Please Select Cancel Reason!" },
            ]}
          >
            <Select
              placeholder="Cancel Reason"
              className="text-input-field  p-1 t1"
              dropdownStyle={{ zIndex: 2000 }}
              bordered={false}
            >
              {cancelReasons.map((data, idx) => (
                <Select.Option key={idx} value={data.key}>
                  {data.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="cancelled_note" label="Note">
            <Input.TextArea
              rows={4}
              onBlur={(e) =>
                form.setFieldsValue({ cancelled_note: e.target.value.trim() })
              }
              maxLength="200"
              placeholder="Note"
              className="text-input-field  p-1 t1"
            />
          </Form.Item>
        </Form>
      </ConfirmModal>

      <ConfirmModal
        title="Rate Locum"
        description="Give rating to locum according to your recent experince."
        open={showRatingModal}
        confirmButtonText="Submit"
        cancelButtonText="Cancel"
        onClose={() => setShowRatingModal(!showRatingModal)}
        onConfirm={handleRatings}
      >
        <Rate
          defaultValue={ratingValue}
          onChange={(val) => setRating(val)}
          style={{ fontSize: 70 }}
        />
      </ConfirmModal>

      {/* <ShiftForm
        showModal={showEditJobModal}
        setHandleModal={setShowEditJobModal}
        view="edit"
        jobType="job"
        jobData={shiftDetails}
      /> */}
      {showEditJobModal && (
        <ShiftJobFormUpdated
          onSuccess={() => setFlag(true)}
          onDelete={onDelete}
          branchId={shiftDetails?.branchId}
          operatingTime={shiftDetails?.branchOperatingTime}
          selectedDate={shiftDetails?.startDate}
          showModal={showEditJobModal}
          setHandleModal={() => setShowEditJobModal(!showEditJobModal)}
          data={shiftData}
        />
      )}
    </div>
  );
};
