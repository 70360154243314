import { DatePicker, Form, Input } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { duplicateContractThunk } from "../../../redux/staffs/staffsThunk";
import { notify } from "../../../utils/helper";
import { AddModal } from "../../UI/modals/addModal";

export const DuplicateContractForm = ({
  selectedId,
  showContractModal,
  setShowContractModal,
  selectedContract,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { staffId } = useParams();
  const formId = useMemo(() => "contractForm", []);

  const onFinish = (values) => {
    const reqBody = {
      title: values?.title,
      startDate: moment(values?.startDate).format("YYYY-MM-DD"),
      endDate: moment(values?.endDate).format("YYYY-MM-DD"),
    };
    dispatch(
      duplicateContractThunk({
        staffId,
        contractId: selectedId,
        payload: reqBody,
      })
    ).then((res) => {
      if (res.payload) {
        onSuccess();
        setShowContractModal();
        notify("success", "Contract duplicated successfully");
        form.resetFields();
      }
    });
  };

  useEffect(() => {
    if (!!selectedContract) {
      form.setFieldsValue({
        title: selectedContract?.title,
        startDate: dayjs(selectedContract?.startDate, "YYYY-MM-DD"),
        endDate: dayjs(selectedContract?.endDate, "YYYY-MM-DD"),
      });
    }
  }, [selectedContract]);

  return (
    <AddModal
      formId={formId}
      title={`Duplicate Contract`}
      handleModal={showContractModal}
      setHandleModal={setShowContractModal}
      primaryButtonText="Duplicate"
      secondaryButtonText="Cancel"
      secondaryButtonHandler={setShowContractModal}
    >
      <Form
        id={formId}
        onFinish={onFinish}
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please input your Title!" }]}
        >
          <Input
            onBlur={(e) =>
              form.setFieldsValue({ title: e.target.value.trim() })
            }
            placeholder="Title"
            className="text-input-field p-2 pe-3 t1"
          />
        </Form.Item>
        <div
          className="d-flex align-items-center"
          style={{ width: "100%", gap: 15 }}
        >
          <Form.Item
            label="Start Date"
            name="startDate"
            style={{ width: "100%" }}
            rules={[{ required: true, message: "Please Enter Start Date!" }]}
          >
            <DatePicker
              inputReadOnly
              format="DD-MM-YYYY"
              getPopupContainer={(trigger) => trigger.parentElement}
              className="text-input-field p-2 pe-3 t1"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label="End Date"
            name="endDate"
            style={{ width: "100%" }}
            rules={[{ required: true, message: "Please Enter End Date!" }]}
          >
            <DatePicker
              inputReadOnly
              format="DD-MM-YYYY"
              getPopupContainer={(trigger) => trigger.parentElement}
              className="text-input-field p-2 pe-3 t1"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </div>
      </Form>
    </AddModal>
  );
};
