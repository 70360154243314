import React, { useEffect } from "react";
import "./style.scss";

import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SignUpSelectionNew } from "../../../components/auth/Signupnew/signUpSelectionNew";

import LopicLogo from "../../../assets/img/LopicLogo.svg";
import { LocumSignUpFormNew } from "../../../components/auth/Signupnew/locumSignUpFormNew";
import { PharmacySignUpFormNew } from "../../../components/auth/Signupnew/pharmacySignUpFormNew";

export const SignUpPageNew = () => {
  const [signUpMode, setSignUpMode] =
    useState("");
  const [showSignUpForm, setShowSignUpForm] =
    useState(false);
  const [searchParams] = useSearchParams();
  const [convertedUser, setConvertedUser] =
    useState("");

  useEffect(() => {
    if (!searchParams.get("source")) return;
    if (
      searchParams.get("source") === "get-started"
    ) {
      setConvertedUser(true);
      window.fbq(
        "trackCustom",
        "Get Started - Pharmacy Convert",
        {}
      );
    }
  }, [searchParams]);

  return (
    <div
      className="d-flex align-items-center sign_up_page "
      style={{ height: "100vh", gap: "15px" }}
    >
      <div
        className="d-flex flex-column mx-auto align-items-center justify-content-center"
        style={{
          maxWidth: "543px",
          width: "100%",
        }}
      >
        <div className="logo-wrap mb-4">
          <img src={LopicLogo} alt="logo" />
        </div>
        {!showSignUpForm ? (
          <SignUpSelectionNew
            setSignUpMode={setSignUpMode}
            signUpMode={signUpMode}
            setShowSignUpForm={setShowSignUpForm}
          />
        ) : signUpMode === "locum" ? (
          <LocumSignUpFormNew />
        ) : (
          <PharmacySignUpFormNew
            convertedUser={convertedUser}
          />
        )}
      </div>
    </div>
  );
};
