import { ArcElement, Chart, Tooltip } from "chart.js";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";
// import "./style.scss";
const ExpenseBarChart = ({ barChartData }) => {
  const [chartData, setChartData] = useState([]);
  useEffect(() => {
    if (!barChartData) return;
    const result = barChartData?.map((day) => {
      return {
        name: moment(day?.startDate).format("ddd"),
        pv: day?.locum_expenses * 1,
      };
    });
    setChartData(result);
  }, [barChartData]);

  const CustomTooltip = ({ active, payload }) => {
    return (
      <div
        style={{
          height: "41px",
          width: "60px",
          background: "#51C2BC",
          borderRadius: "5px",
          border: "none",
        }}
      >
        <div className="d-flex flex-column align-items-center justify-content-center">
          <span
            style={{
              color: " #FFFFFF",
              fontWeight: "400px",
              fontSize: "8px",
              lineHeight: "12px",
              marginTop: "6px",
            }}
          >
            {payload && payload[0]?.payload?.name}
          </span>
          <span className="t1" style={{ color: "#ffffff" }}>
            £ {payload && payload[0]?.payload?.pv}
          </span>
        </div>
        <div className="tooltipArrow"></div>
      </div>
    );
  };
  Chart.register(ArcElement);

  return (
    <div>
      <div style={{ width: "100%" }}>
        <BarChart
          width={500}
          height={350}
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 10,
            bottom: 30,
          }}
          barSize={16}
        >
          <XAxis
            dataKey="name"
            scale="point"
            padding={{ left: 10, right: 10 }}
            border={{ left: "none", right: "none" }}
            axisLine={false}
            dy={20}
            tick={{
              fontSize: 14,
              fontWeight: 400,
              fill: "#888888",
              lineHeight: "20",
            }}
          />
          <YAxis
            axisLine={false}
            type="number"
            // domain={[0, 5000]}
            tickCount={11}
            tick={{
              fontSize: 14,
              fontWeight: 400,
              fill: "#888888",
              lineHeight: "18",
            }}
            interval={0}
            // tickFormatter={DataFormater}
            dx={-20}
          />

          <Tooltip
            content={<CustomTooltip />}
            wrapperStyle={{ outline: "none" }}
          />
          <CartesianGrid
            horizontal={true}
            vertical={false}
            strokeDasharray="3 3"
            stroke="#E4E9F9"
            background={{ fill: "#E4E9F9" }}
          />

          <Bar
            dataKey="pv"
            fill="#E1F4F4"
            background={{ fill: "transparent" }}
            radius={[25, 25, 25, 25]}
          />
        </BarChart>
      </div>
    </div>
  );
};

export default ExpenseBarChart;
