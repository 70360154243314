import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Dropdown, Modal, Tooltip } from "antd";
import React from "react";
import CopyIcon from "../../../../src/assets/icons/copy.svg";
import DropDownIcon from "../../../../src/assets/icons/downDrop.svg";
import DropUp from "../../../../src/assets/icons/dropUp.png";
import TrashIcon from "../../../../src/assets/icons/trash.svg";
import { branchApiKeys } from "../../../utils/apiKeyConstants";
import { hasAccess } from "../../../utils/helper";

const TemplateViewHead = ({
  openTable,
  toggleTable,
  weekName,
  actionWeekTemplate,
  clearWeekTemplate,
}) => {
  const [modal, contextHolder] = Modal.useModal();

  const items = [
    {
      key: 1,
      label: (
        <div
          onClick={() =>
            actionWeekTemplate("copy", 1, parseInt(weekName.split(" ")[1]))
          }
          style={{ margin: 0 }}
        >
          Copy Week 1
        </div>
      ),
    },
    {
      key: 2,
      label: (
        <div
          onClick={() =>
            actionWeekTemplate("copy", 2, parseInt(weekName.split(" ")[1]))
          }
          style={{ margin: 0 }}
        >
          Copy Week 2
        </div>
      ),
    },
    {
      key: 3,
      label: (
        <div
          onClick={() =>
            actionWeekTemplate("copy", 3, parseInt(weekName.split(" ")[1]))
          }
          style={{ margin: 0 }}
        >
          Copy Week 3
        </div>
      ),
    },
    {
      key: 4,
      label: (
        <div
          onClick={() =>
            actionWeekTemplate("copy", 4, parseInt(weekName.split(" ")[1]))
          }
          style={{ margin: 0 }}
        >
          Copy Week 4
        </div>
      ),
    },
  ];

  const confirm = () => {
    modal.confirm({
      title: "Are you sure you want to delete all shifts?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: clearWeekTemplate,
      className: "confirm_modal",
    });
  };

  return (
    <div
      className={`bg-white p-4 d-flex align-items-center justify-content-between  ${
        openTable ? "border-bottom-0" : ""
      }`}
      style={{
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        borderBottomLeftRadius: openTable ? 0 : 16,
        borderBottomRightRadius: openTable ? 0 : 16,
      }}
    >
      <div className="d-flex justify-content-start align-items-center pharma_time_sheet">
        <h5 className="fw-semibold">{weekName}</h5>
      </div>

      <div className=" d-flex align-items-center">
        <ul className="d-flex rgt_side">
          <li>
            <button
              className="btn"
              onClick={(e) => {
                e.stopPropagation();
                confirm();
              }}
            >
              <Tooltip title="Delete">
                <img
                  src={TrashIcon}
                  alt="Delete"
                  style={{ width: 23, height: 23 }}
                />
              </Tooltip>
            </button>
          </li>
          {/* <li>
            <button className="btn">
              <Tooltip title="Clipboard Close">
                <img src={ClipboardIcon} alt="Clipboard Close" />
              </Tooltip>
            </button>
          </li> */}
          <li>
            <button className="btn" onClick={(e) => e.stopPropagation()}>
              {hasAccess(branchApiKeys.HANDLE_TEMPLATE_ACTION) && (
                <Dropdown
                  getPopupContainer={(trigger) => trigger.parentElement}
                  placement="bottomRight"
                  overlayStyle={{ zIndex: 20000, width: 130 }}
                  menu={{
                    items: items.filter(
                      (i) => i.key !== parseInt(weekName.split(" ")[1])
                    ),
                  }}
                  trigger={["click"]}
                >
                  <Tooltip title="Copy">
                    <img src={CopyIcon} alt="copy" />
                  </Tooltip>
                </Dropdown>
              )}
            </button>
          </li>
          <li>
            <button className="dropdown_btn btn" onClick={toggleTable}>
              <img src={openTable ? DropUp : DropDownIcon} alt="" />
            </button>
          </li>
        </ul>
      </div>
      {contextHolder}
    </div>
  );
};

export default TemplateViewHead;
