import fileIcon from "../../assets/icons/file.png";

export const DocumentSlide = ({
  value,
  expDate,
  label,
  name,
  isDocument = 1,
}) => {
  return (
    <a
      target="_blank"
      href={value && isDocument ? value : null}
      className="d-flex text-decoration-none  cursor-pointer align-items-center justify-content-between gap-3 border rounded p-3"
      style={{
        width: "100%",
        maxWidth: "49%",
        overflow: "hidden",
        backgroundColor: `${value ? "#4af9f02e" : "transparent"}`,
      }}
      rel="noreferrer"
    >
      <div
        className="d-flex align-items-center gap-3"
        style={{ height: "100%" }}
      >
        <div className="border rounded-circle p-2">
          <img src={fileIcon} width={40} height={40} alt="fileIcon" />
        </div>
        <div className="d-flex flex-column">
          <span className="p3 fw-bolder">{label}</span>
          {value ? (
            typeof value === "string" ? (
              isDocument ? (
                <span
                  className="b1"
                  style={{ lineBreak: "anywhere" }}
                >{`${name}_${label}.${value?.split(".").pop()}`}</span>
              ) : (
                <span className="b1" style={{ lineBreak: "anywhere" }}>
                  {value}
                </span>
              )
            ) : (
              <span className="b1" style={{ lineBreak: "anywhere" }}>
                {value?.name}
              </span>
            )
          ) : (
            <div className="b1">To Be Uploaded</div>
          )}
        </div>
      </div>
      {expDate && (
        <div className="text-decoration-none p3">Expires on {expDate}</div>
      )}
    </a>
  );
};
