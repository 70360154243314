import { Button, Form, Input } from "antd"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { resetPasswordReqThunk } from "../../../redux/auth/authThunk"
import { notify } from "../../../utils/helper";
import "../style.scss";

export const ResetPassEmailForm = ({
    setFormView
}) => {
    const [form] = Form.useForm();
    const dispatach = useDispatch()
    const [btndisabled, setbtndisabled] = useState(true);

    const onFinish = (values) => {
        dispatach(
            resetPasswordReqThunk(values)
        ).then((res) => {
            if (res.payload.status) {
                form.resetFields();
                notify('success', 'Reset Password link sent to your Email!')
            }
        })
    }

    const onValuesChange = (changedValues, allValues) => {

        if (allValues.email !== undefined && allValues.email !== '') {
            setbtndisabled(false);
        } else {
            setbtndisabled(true);
        }
    };

    return (
        <div className="d-flex flex-column mx-auto mt-4" style={{ maxWidth: '50%', width: '450px', gap: '50px' }}>
            <div className="text-center">
                <div className="heading2">Forgot Password</div>
                <div className="t1">Enter your registered email to recover your password.</div>
            </div>
            <Form
                onFinish={onFinish}
                form={form}
                onValuesChange={onValuesChange}
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item
                    label="Email Address"
                    name="email"
                    rules={[{
                        type: "email",
                        message: 'Please Enter your email!'
                    }]}
                >
                    <Input
                        onBlur={e => form.setFieldsValue({ 'email': e.target.value.trim() })}
                        className='text-input-field  p-2 t1'
                        placeholder='Email Address'
                        maxLength={50}
                    />
                </Form.Item>
                <Form.Item>
                    <Button disabled={btndisabled} className="p-2 btn-style" type="primary" htmlType="submit" block>
                        Continue
                    </Button>
                </Form.Item>
            </Form>

            <div className="p2 text-center" style={{ color: '#9D9D9D' }}>
                <span className="m-auto ">
                    Back to <span onClick={() => setFormView('login')} className="text-decoration-none cursor-pointer" style={{ color: '#51C2BC' }}>Login</span>
                    ?
                </span>
            </div>
        </div>
    )
}