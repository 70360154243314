import { Skeleton, Typography } from "antd";
import React from "react";

const DashboardTableContainer = ({ title, tableRight, children, loading }) => {
  return (
    <Skeleton
      style={{ background: "#fff", padding: 12, borderRadius: 12, height: 500 }}
      active
      loading={loading}
      paragraph={{ rows: 7, width: "auto" }}
      className="table-skeleton"
      title={{ width: 164 }}
      avatar={{ size: 24,  }}
    >
      <div className="dashboard-table-container cmn-db-box">
        <div className="table-header">
          <Typography.Title level={5}>{title}</Typography.Title>
          {tableRight}
        </div>

        {children}
      </div>
    </Skeleton>
  );
};

export default DashboardTableContainer;
