import { Breadcrumb } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, generatePath, useNavigate, useParams } from "react-router-dom";
import { BranchCard } from "../../../../../components/UI/cards/profileCard";
import { ContentContainer } from "../../../../../components/UI/container";
import { WarningBar } from "../../../../../components/UI/header/warningBar";
import { SidebarNew } from "../../../../../components/UI/sidebar-new/SidebarNew";
import {
  getBranchDataThunk,
  getBranchRelatedDataThunk,
} from "../../../../../redux/branches/branchesThunk";
import { RouterConfig } from "../../../../../routerConfig";
import { branchApiKeys } from "../../../../../utils/apiKeyConstants";
import { hasAccess } from "../../../../../utils/helper";
import { moduleKeys } from "../../../../../utils/modulesKeyConstants";
import "./style.scss";

export const BranchDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { branchInfo } = useSelector((state) => state.branches);

  useEffect(() => {
    if (hasAccess(branchApiKeys.GET_BRANCH_DATA)) {
      dispatch(getBranchRelatedDataThunk());
    }
    if (hasAccess(branchApiKeys.GET_BRANCH)) {
      dispatch(getBranchDataThunk({ id }));
    }
  }, [dispatch, id]);

  const sidebarLinks = useMemo(
    () => [
      {
        text: "Information",
        to: generatePath(RouterConfig.branchAdminBranchInformation, { id }),
        key: moduleKeys.BRANCH_INFORMATION,
        disabled: false,
        hidden: false,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22ZM12.75 16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16L11.25 11C11.25 10.59 11.59 10.25 12 10.25C12.41 10.25 12.75 10.59 12.75 11L12.75 16ZM11.08 7.62C11.13 7.49 11.2 7.39 11.29 7.29C11.39 7.2 11.5 7.13 11.62 7.08C11.74 7.03 11.87 7 12 7C12.13 7 12.26 7.03 12.38 7.08C12.5 7.13 12.61 7.2 12.71 7.29C12.8 7.39 12.87 7.49 12.92 7.62C12.97 7.74 13 7.87 13 8C13 8.13 12.97 8.26 12.92 8.38C12.87 8.5 12.8 8.61 12.71 8.71C12.61 8.8 12.5 8.87 12.38 8.92C12.14 9.02 11.86 9.02 11.62 8.92C11.5 8.87 11.39 8.8 11.29 8.71C11.2 8.61 11.13 8.5 11.08 8.38C11.03 8.26 11 8.13 11 8C11 7.87 11.03 7.74 11.08 7.62Z"
              fill="#434343"
            />
          </svg>
        ),
      },
      {
        text: "Schedule",
        to: generatePath(RouterConfig.branchAdminBranchSchedule, { id }),
        key: moduleKeys.BRANCH_SCHEDULE,
        disabled: false,
        hidden: false,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M16.7502 3.56V2C16.7502 1.59 16.4102 1.25 16.0002 1.25C15.5902 1.25 15.2502 1.59 15.2502 2V3.5H8.75023V2C8.75023 1.59 8.41023 1.25 8.00023 1.25C7.59023 1.25 7.25023 1.59 7.25023 2V3.56C4.55023 3.81 3.24023 5.42 3.04023 7.81C3.02023 8.1 3.26023 8.34 3.54023 8.34H20.4602C20.7502 8.34 20.9902 8.09 20.9602 7.81C20.7602 5.42 19.4502 3.81 16.7502 3.56Z"
              fill="#434343"
            />
            <path
              d="M20 9.83984H4C3.45 9.83984 3 10.2898 3 10.8398V16.9998C3 19.9998 4.5 21.9998 8 21.9998H16C19.5 21.9998 21 19.9998 21 16.9998V10.8398C21 10.2898 20.55 9.83984 20 9.83984ZM9.21 18.2098C9.11 18.2998 9 18.3698 8.88 18.4198C8.76 18.4698 8.63 18.4998 8.5 18.4998C8.37 18.4998 8.24 18.4698 8.12 18.4198C8 18.3698 7.89 18.2998 7.79 18.2098C7.61 18.0198 7.5 17.7598 7.5 17.4998C7.5 17.2398 7.61 16.9798 7.79 16.7898C7.89 16.6998 8 16.6298 8.12 16.5798C8.36 16.4798 8.64 16.4798 8.88 16.5798C9 16.6298 9.11 16.6998 9.21 16.7898C9.39 16.9798 9.5 17.2398 9.5 17.4998C9.5 17.7598 9.39 18.0198 9.21 18.2098ZM9.42 14.3798C9.37 14.4998 9.3 14.6098 9.21 14.7098C9.11 14.7998 9 14.8698 8.88 14.9198C8.76 14.9698 8.63 14.9998 8.5 14.9998C8.37 14.9998 8.24 14.9698 8.12 14.9198C8 14.8698 7.89 14.7998 7.79 14.7098C7.7 14.6098 7.63 14.4998 7.58 14.3798C7.53 14.2598 7.5 14.1298 7.5 13.9998C7.5 13.8698 7.53 13.7398 7.58 13.6198C7.63 13.4998 7.7 13.3898 7.79 13.2898C7.89 13.1998 8 13.1298 8.12 13.0798C8.36 12.9798 8.64 12.9798 8.88 13.0798C9 13.1298 9.11 13.1998 9.21 13.2898C9.3 13.3898 9.37 13.4998 9.42 13.6198C9.47 13.7398 9.5 13.8698 9.5 13.9998C9.5 14.1298 9.47 14.2598 9.42 14.3798ZM12.71 14.7098C12.61 14.7998 12.5 14.8698 12.38 14.9198C12.26 14.9698 12.13 14.9998 12 14.9998C11.87 14.9998 11.74 14.9698 11.62 14.9198C11.5 14.8698 11.39 14.7998 11.29 14.7098C11.11 14.5198 11 14.2598 11 13.9998C11 13.7398 11.11 13.4798 11.29 13.2898C11.39 13.1998 11.5 13.1298 11.62 13.0798C11.86 12.9698 12.14 12.9698 12.38 13.0798C12.5 13.1298 12.61 13.1998 12.71 13.2898C12.89 13.4798 13 13.7398 13 13.9998C13 14.2598 12.89 14.5198 12.71 14.7098Z"
              fill="#434343"
            />
          </svg>
        ),
      },
      {
        text: "Template",
        to: generatePath(RouterConfig.branchAdminBranchTemplate, { id }),
        key: moduleKeys.BRANCH_TEMPLATE,
        disabled: false,
        hidden: false,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M21.3304 14.67C20.2804 13.62 19.4504 13.96 18.7304 14.67L14.5804 18.82C14.4204 18.98 14.2704 19.29 14.2304 19.51L14.0004 21.1C13.9204 21.67 14.3204 22.08 14.8904 21.99L16.4804 21.76C16.7004 21.73 17.0104 21.57 17.1704 21.41L21.3204 17.26C22.0504 16.55 22.3804 15.72 21.3304 14.67Z"
              fill="#434343"
            />
            <path
              d="M6.74683 2.06549C7.29669 1.99425 7.75 2.45112 7.75 3.00557V6.8C7.75 7.35229 7.30228 7.8 6.75 7.8H3C2.44772 7.8 1.9918 7.35027 2.05867 6.80204C2.38633 4.11548 4.07187 2.41205 6.74683 2.06549Z"
              fill="#434343"
            />
            <path
              d="M20.93 7.8H17.25C16.6977 7.8 16.25 7.35228 16.25 6.8V3.00625C16.25 2.45153 16.7041 1.99459 17.2542 2.06633C19.8878 2.40978 21.5645 4.0681 21.93 6.68C22.01 7.27 21.53 7.8 20.93 7.8Z"
              fill="#434343"
            />
            <path
              d="M6.75 16.2998C7.30228 16.2998 7.75 16.7475 7.75 17.2998V20.9198C7.75 21.5198 7.22 21.9998 6.62 21.9198C4.05088 21.5375 2.43008 19.8909 2.07373 17.3099C1.99752 16.7579 2.4555 16.2998 3.01274 16.2998H6.75Z"
              fill="#434343"
            />
            <path
              d="M7.75 10.2998C7.75 9.74752 7.30228 9.2998 6.75 9.2998H3C2.44772 9.2998 2 9.74752 2 10.2998V13.7998C2 14.3521 2.44772 14.7998 3 14.7998H6.75C7.30228 14.7998 7.75 14.3521 7.75 13.7998V10.2998Z"
              fill="#434343"
            />
            <path
              d="M14.75 10.2998C14.75 9.74752 14.3023 9.2998 13.75 9.2998H10.25C9.69772 9.2998 9.25 9.74752 9.25 10.2998V13.7998C9.25 14.3521 9.69772 14.7998 10.25 14.7998H13.75C14.3 14.7998 14.75 14.3498 14.75 13.7998V10.2998Z"
              fill="#434343"
            />
            <path
              d="M14.75 3C14.75 2.44771 14.3023 2 13.75 2H10.25C9.69772 2 9.25 2.44772 9.25 3V6.8C9.25 7.35229 9.69772 7.8 10.25 7.8H13.75C14.3023 7.8 14.75 7.35228 14.75 6.8V3Z"
              fill="#434343"
            />
          </svg>
        ),
      },
      {
        text: "Staff",
        to: generatePath(RouterConfig.branchAdminBranchStaff, { id }),
        key: moduleKeys.BRANCH_STAFF,
        disabled: false,
        hidden: false,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M17.5291 7.77C17.4591 7.76 17.3891 7.76 17.3191 7.77C15.7691 7.72 14.5391 6.45 14.5391 4.89C14.5391 3.3 15.8291 2 17.4291 2C19.0191 2 20.3191 3.29 20.3191 4.89C20.3091 6.45 19.0791 7.72 17.5291 7.77Z"
              fill="#434343"
            />
            <path
              d="M20.7896 14.6999C19.6696 15.4499 18.0996 15.7299 16.6496 15.5399C17.0296 14.7199 17.2296 13.8099 17.2396 12.8499C17.2396 11.8499 17.0196 10.8999 16.5996 10.0699C18.0796 9.86991 19.6496 10.1499 20.7796 10.8999C22.3596 11.9399 22.3596 13.6499 20.7896 14.6999Z"
              fill="#434343"
            />
            <path
              d="M6.44016 7.77C6.51016 7.76 6.58016 7.76 6.65016 7.77C8.20016 7.72 9.43016 6.45 9.43016 4.89C9.43016 3.29 8.14016 2 6.54016 2C4.95016 2 3.66016 3.29 3.66016 4.89C3.66016 6.45 4.89016 7.72 6.44016 7.77Z"
              fill="#434343"
            />
            <path
              d="M6.54914 12.8501C6.54914 13.8201 6.75914 14.7401 7.13914 15.5701C5.72914 15.7201 4.25914 15.4201 3.17914 14.7101C1.59914 13.6601 1.59914 11.9501 3.17914 10.9001C4.24914 10.1801 5.75914 9.8901 7.17914 10.0501C6.76914 10.8901 6.54914 11.8401 6.54914 12.8501Z"
              fill="#434343"
            />
            <path
              d="M12.1208 15.87C12.0408 15.86 11.9508 15.86 11.8608 15.87C10.0208 15.81 8.55078 14.3 8.55078 12.44C8.56078 10.54 10.0908 9 12.0008 9C13.9008 9 15.4408 10.54 15.4408 12.44C15.4308 14.3 13.9708 15.81 12.1208 15.87Z"
              fill="#434343"
            />
            <path
              d="M8.87078 17.9401C7.36078 18.9501 7.36078 20.6101 8.87078 21.6101C10.5908 22.7601 13.4108 22.7601 15.1308 21.6101C16.6408 20.6001 16.6408 18.9401 15.1308 17.9401C13.4208 16.7901 10.6008 16.7901 8.87078 17.9401Z"
              fill="#434343"
            />
          </svg>
        ),
      },
      {
        text: "Block List",
        to: generatePath(RouterConfig.branchAdminBranchBlockList, { id }),
        key: moduleKeys.BRANCH_BLOCK_LIST,
        disabled: false,
        hidden: false,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12.0002 14C6.99016 14 2.91016 17.36 2.91016 21.5C2.91016 21.78 3.13016 22 3.41016 22H20.5902C20.8702 22 21.0902 21.78 21.0902 21.5C21.0902 17.36 17.0102 14 12.0002 14Z"
              fill="#434343"
            />
            <path
              d="M16.8506 5.80008C16.7306 5.31008 16.5306 4.83008 16.2506 4.39008C16.0606 4.07008 15.8106 3.75008 15.5406 3.47008C14.6406 2.57008 13.4706 2.08008 12.2706 2.02008C10.9106 1.93008 9.52058 2.43008 8.47058 3.47008C7.48058 4.45008 6.98058 5.76008 7.00058 7.08008C7.01058 8.33008 7.51058 9.58008 8.46058 10.5401C9.12058 11.2001 9.93058 11.6401 10.8006 11.8401C11.2706 11.9601 11.7706 12.0201 12.2706 11.9801C13.4606 11.9301 14.6206 11.4601 15.5306 10.5401C16.8206 9.25008 17.2606 7.44008 16.8506 5.80008ZM14.0006 9.00008C13.6406 9.36008 13.0406 9.36008 12.6806 9.00008L11.9906 8.31008L11.3306 8.97008C10.9706 9.33008 10.3706 9.33008 10.0106 8.97008C9.64058 8.60008 9.64058 8.01008 10.0006 7.65008L10.6606 6.99008L10.0206 6.37008C9.66058 6.00008 9.66058 5.41008 10.0206 5.03008C10.3906 4.67008 10.9806 4.67008 11.3606 5.03008L11.9806 5.67008L12.6506 5.00008C13.0106 4.64008 13.6006 4.64008 13.9706 5.00008C14.3306 5.36008 14.3306 5.96008 13.9706 6.32008L13.3106 6.98008L14.0006 7.68008C14.3606 8.04008 14.3606 8.64008 14.0006 9.00008Z"
              fill="#434343"
            />
          </svg>
        ),
      },
    ],
    [id]
  );

  const { accessibleModules: modules } = useSelector((state) => state.auth);
  const [displaySidebarLinks, setDisplaySidebarLinks] = useState([]);
  useEffect(() => {
    if (!modules) return;
    let modulesArray = Object.values(modules);
    let linksToDisplay = [];

    for (let link of sidebarLinks) {
      for (let module of modulesArray) {
        if (link?.key?.toLowerCase() === module?.moduleKey?.toLowerCase()) {
          linksToDisplay.push(link);
        }
      }
    }

    setDisplaySidebarLinks(linksToDisplay);
  }, [modules]);

  return (
    <div className="content-view-page details-page">
      {branchInfo && !branchInfo.active && (
        <WarningBar>
          This Branch Does Not Seem To Have An Active Subscription, To Enjoy
          Un-Interrupted Access, Please
          <u
            className="cursor-pointer"
            onClick={() => navigate("/profile/subscription")}
          >
            Activate Your Subscription!
          </u>
        </WarningBar>
      )}
      <div className="breadcrumb-wrapper">
        <Breadcrumb className="admin-breadcrumb">
          <Breadcrumb.Item>All branch</Breadcrumb.Item>
          <Breadcrumb.Item>Branch Details</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="main-wrapper ">
        <div className="content-holder">
          <SidebarNew links={displaySidebarLinks} hideDeactivate visible>
            <BranchCard
              showStatus
              isBranch
              logo={branchInfo?.logo}
              primaryText={branchInfo?.title}
              secondaryText={branchInfo?.city}
              userStatus={branchInfo?.active ? 1 : 0}
            />
          </SidebarNew>
          <ContentContainer className="content-container" fullWidth>
            <Outlet />
          </ContentContainer>
        </div>
      </div>
    </div>
  );
};
