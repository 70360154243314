import React from 'react';
import './style.scss';
import { dayviewdataMock } from '../../../lib/mock/Dayview.mock';
import moment from 'moment';
import ShiftDetails from '../../../components/schedule_new/ShiftDeatails/ShiftDetails';
const DayviewTablefullscreen = () => {
  return (
    <div className="container-fluid">
      <div className="table_container ">
        <table className="table table-bordered bg-white weekview_table ">
          <thead>
            <tr>
              <th scope="col" colSpan={3}>
                <p className="date_th">
                  Mon <b>17 July</b>
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            {dayviewdataMock[0].timings.map((timings, id) => (
              <tr id={id}>
                <th
                  scope="col"
                  style={{ width: 140 }}
                  className="vertical_day_th"
                >
                  {moment(timings.startTime, 'HH').format('hh A')}
                </th>
                {timings.shifts.map((shift, idx) => (
                  <td key={idx} className="month-schedular-slot-container">
                    <ShiftDetails
                      shift={shift}
                      vertical={true}
                      weekview={true}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DayviewTablefullscreen;
