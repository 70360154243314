import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { signinThunk } from "../../../redux/auth/authThunk";
import { RouterConfig } from "../../../routerConfig";
import "../style.scss";

export const PasswordCriteria = (
  <ul>
    <li>At least 8 characters.</li>
    <li>Must contain at least 1 Uppercase Letter</li>
    <li>Must contain at least 1 Lowercase Letter</li>
    <li>Must contain at least 1 Number Letter</li>
    <li>Must contain at least 1 Special Characters</li>
  </ul>
);

export const LoginForm = ({ setFormView }) => {
  const [form] = Form.useForm();
  const dispatach = useDispatch();

  const [btndisabled, setbtndisabled] = useState(true);

  const onValuesChange = (changedValues, allValues) => {
    if (
      allValues.email !== undefined &&
      allValues.password !== undefined &&
      allValues.email !== "" &&
      allValues.password !== ""
    ) {
      setbtndisabled(false);
    } else {
      setbtndisabled(true);
    }
  };

  const onFinish = (values) => {
    dispatach(signinThunk(values)).then((res) => {
      if (res.payload) {
        window.analytics.identify(res.payload.id, {
          lastLogin: res.payload.lastLogin,
          totalLogin: res.payload.totalLogin,
          firstLogin: res.payload.firstLogin,
          email: res.payload.email,
          roleId: res.payload.roleId,
        });
        if (res.payload.role === "pharmacy_admin") {
          window.fbq("trackCustom", "PA Transition", {
            id: res.payload.data.id,
            email: res.payload.data.email,
            userType: res.payload.data.group,
            name: res.payload.data.name,
            surname: res.payload.data.surname,
            role: res.payload.data.role,
            gphcNumber: res.payload.data.gphcNumber,
            device: "web",
          });
        }
        form.resetFields();
      }
    });
  };

  return (
    <div
      className="d-flex flex-column m-auto"
      style={{ maxWidth: "50%", width: "450px", gap: "50px" }}
    >
      <div>
        <div className="heading2">Welcome Back!</div>
        <div className="t1">Sign in to your account</div>
      </div>
      <Form
        onFinish={onFinish}
        form={form}
        layout="vertical"
        onValuesChange={onValuesChange}
        autoComplete="off"
      >
        <Form.Item
          label=" Email Address"
          name="email"
          rules={[
            {
              type: "email",
              message: "Please Enter your email!",
            },
          ]}
        >
          <Input
            onBlur={(e) =>
              form.setFieldsValue({ email: e.target.value.trim() })
            }
            className="text-input-field  p-2 t1"
            placeholder="Email Address"
            maxLength={50}
          />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          className="position-relative"
        >
          <Input.Password
            onBlur={(e) =>
              form.setFieldsValue({ password: e.target.value.trim() })
            }
            placeholder="Password"
            className="text-input-field  p-2 pe-3 t1"
            maxLength={50}
          />
        </Form.Item>
        <div className="d-flex align-items-center mb-4 justify-content-end">
          <span
            className="p2 text-decoration-none  cursor-pointer"
            style={{ color: "#51C2BC" }}
            onClick={() => setFormView("forgotPass")}
          >
            Forgot Password?
          </span>
        </div>

        <Form.Item>
          <Button
            disabled={btndisabled}
            className="p-2 btn-style button"
            type="primary"
            htmlType="submit"
            block
          >
            Login
          </Button>
        </Form.Item>
      </Form>
      <div className="p2 text-center" style={{ color: "#9D9D9D" }}>
        <span className=" m-auto">
          Don’t have an account?
          <Link
            to={RouterConfig.signup}
            className="text-decoration-none"
            style={{ color: "#51C2BC" }}
          >
            {" "}
            Create one
          </Link>
        </span>
      </div>
    </div>
  );
};
