import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "antd";
import { activatePharmacy, deactivatePharmacy, getPharmacyInformation } from "../../../../../redux/super-admin/superAdminAPI";
import { FlexRow } from "../../../../../components/comon/flexRows";

export const BranchInformation = () => {
  const [pharmacyInformation, setPharmacyInformation] = useState("");
  const params = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState("");

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await getPharmacyInformation(params.id);
      setPharmacyInformation(response?.data?.data);
      setStatus(response?.data?.data?.status);
    }

    fetchData();
  }, [params.id]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    changeStatus();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const changeStatus = async () => {
    if (status === "active") {
      const res = await deactivatePharmacy(params.id);
      setStatus("inactive");
    } else {
      const res = await activatePharmacy(params.id);
      setStatus("active");
    }
  };
  return (
    <div className="d-flex flex-column flex-1" style={{ gap: 30 }}>
      <div className="heading4 d-flex align-items-center justify-content-between">
        Pharmacy Information
        <div
          className="s1 m-0 cursor-pointer py-2 px-3 rounded"
          onClick={showModal}
          style={{
            color: "#51C2BC",
            border: "2px solid #51C2BC",
            backgroundColor: "white",
          }}
        >
          {status === "active" ? "Deactivate" : "Activate"}
        </div>
      </div>
      <div className="border rounded">
        <div className="text-row d-flex align-items-center justify-content-between">
          <div className="s1 m-0">Basic Information</div>
        </div>
        <FlexRow
          primaryTitle="Pharmacy Admin"
          primaryDescription={
            pharmacyInformation?.name + " " + pharmacyInformation?.surname
          }
        />
        <FlexRow
          primaryTitle="Pharmacy Name"
          primaryDescription={pharmacyInformation?.pharmacyName || "-"}
        />
      </div>
      <div className="border rounded">
        <div className="text-row d-flex align-items-center justify-content-between">
          <div className="s1 m-0">Stats</div>
        </div>
        <FlexRow
          primaryTitle="Branches"
          primaryDescription={pharmacyInformation?.branchesCount}
        />
        <FlexRow
          primaryTitle="Jobs Count"
          primaryDescription={pharmacyInformation?.currentJobsCount}
        />
        <FlexRow
          primaryTitle="Staff"
          primaryDescription={pharmacyInformation?.staffCount}
        />
        <FlexRow
          primaryTitle="Subscription"
          primaryDescription={
            pharmacyInformation?.userSubscription === null
              ? "none"
              : pharmacyInformation?.userSubscription
          }
        />
      </div>
      <Modal
        title={
          status === "active"
            ? "Deactivate Pharmacy"
            : status !== "active"
              ? "Activate Pharmacy"
              : undefined
        }
        bodyStyle={{ height: 70 }}
        centered={true}
        width={350}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          Are you sure you want to{" "}
          {`${status === "active" ? "deactivate" : "activate"}`} this pharmacy
        </p>
      </Modal>
    </div>
  );
};
