import { Avatar, Button, Form, Input, Popover } from "antd";
import moment from "moment";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import SearchIcon from "../../../assets/icons/Search.svg";
import ChevornRight from "../../../assets/icons/chevron-right.svg";
import { ATTENDANCE_TYPE, absenceType } from "../../../utils/constant";
import eventEmitter, { events } from "../../../utils/eventEmitter";
import DropdownIcon from "../../UI/icons/DropdownIcon";
// import { DropdownSubMenuBranch } from "./DropdownSubmenu";
import DeleteIcon from "../../UI/icons/DeleteIcon";

const PopoverContent = ({ shiftDetails, handlePopOpen }) => {
  const { branches } = useSelector((state) => state.branches);
  const [inputvalue, setinputvalue] = useState("");
  const [branchOpen, setBranchOpen] = useState(false);
  const [leaveOpen, setLeaveOpen] = useState(false);
  const handleInputValue = (e) => {
    setinputvalue(e.target.value);
  };

  const leaveType = useMemo(() => {
    if (!!shiftDetails?.leaveType) return shiftDetails?.leaveType;

    if (!!shiftDetails?.attendance?.type) return shiftDetails?.attendance?.type;

    return "";
  }, [shiftDetails?.attendance?.type, shiftDetails?.leaveType]);

  const handleSelect = (key, data) => {
    let operatingTime = {
      startTime: null,
      endTime: null,
      lunchStartTime: null,
      lunchEndTime: null,
    };

    const branchId = key === "branchId" ? data : shiftDetails?.branchId;

    const _branchDetails = branches?.rows?.find(
      (item) => item?.id === branchId
    );

    if (!!_branchDetails) {
      const findByDate = _branchDetails?.operatingTime?.find(
        (item) =>
          item?.dayOfWeek?.toLowerCase() ===
          moment(shiftDetails?.startDate, "YYYY-MM-DD")
            .format("dddd")
            ?.toLowerCase()
      );
      operatingTime = {
        startTime: findByDate?.startTime || "09:00",
        endTime: findByDate?.endTime || "21:00",
        lunchStartTime: findByDate?.lunchStartTime,
        lunchEndTime: findByDate?.lunchEndTime,
        hasOperatingTime: !!findByDate?.startTime ? true : false,
      };
    }

    let payload = {
      recordId: shiftDetails?.attendance?.id || shiftDetails?.recordId,
      // leaveType: null,
      [key]: data,
      mutationType: "updateFields",
      ...operatingTime,
    };

    switch (key) {
      case "leaveType":
        payload = {
          ...payload,
          type: ATTENDANCE_TYPE.LEAVE,
          checkIn: null,
          checkOut: null,
          status: null,
          leaveType: data,
        };
        if (shiftDetails?.leaveType === data) {
          payload.type = ATTENDANCE_TYPE.PRESENT;
          payload.leaveType = "";
        }
        break;

      case "deleteLeave":
        payload = {
          ...payload,
          type: ATTENDANCE_TYPE.PRESENT,
          checkIn: null,
          checkOut: null,
          status: null,
          leaveType: "",
        };

        break;

      default:
        break;
    }

    // console.log(payload, "sent");

    eventEmitter.emit(events.timeSheetCelledit, payload);
    handlePopOpen(false);
  };
  const handleNoshift = () => {
    const payload = {
      recordId: shiftDetails?.attendance?.id || shiftDetails?.recordId,
      type: ATTENDANCE_TYPE.NO_SHIFT,
      checkIn: null,
      checkOut: null,
      leaveType: null,
      status: null,
      branchId: shiftDetails?.branchId,
      mutationType: "updateFields",
    };
    if (shiftDetails?.type === ATTENDANCE_TYPE.NO_SHIFT) {
      payload.type = ATTENDANCE_TYPE.PRESENT;
    }
    // console.log(payload, "sent");
    eventEmitter.emit(events.timeSheetCelledit, payload);
    handlePopOpen(false);
  };

  return (
    <>
      <ul className="modal_popover">
        <li
          onClick={handleNoshift}
          className={`${
            shiftDetails?.type === ATTENDANCE_TYPE.NO_SHIFT ? "activeLi" : ""
          }`}
        >
          <button className="btn">No shifts</button>
        </li>

        <li>
          <Popover
            open={branchOpen}
            onOpenChange={(v) => setBranchOpen(v)}
            content={() => (
              <div className="leave_dropdown">
                <div className="dropdown_input">
                  <Form>
                    <Form.Item>
                      <Input
                        id="form"
                        placeholder="Search"
                        type="text"
                        value={inputvalue}
                        onChange={handleInputValue}
                        prefix={<img src={SearchIcon} alt="search" />}
                      />
                    </Form.Item>
                  </Form>
                </div>
                <div className="dropdownItems">
                  <ul className="pharma_options_wrapper">
                    {branches?.rows
                      ?.filter(
                        (item) =>
                          item?.active &&
                          item?.title
                            ?.toLowerCase()
                            ?.includes(inputvalue?.toLowerCase())
                      )
                      .map(
                        (item) =>
                          item?.active && (
                            <li
                              className={`d-flex align-items-center cursor-pointer justify-content-between ${
                                shiftDetails?.branchId === item?.id
                                  ? "activeLi"
                                  : ""
                              }`}
                              key={item?.id}
                              onClick={() => {
                                handleSelect("branchId", item?.id);
                                setBranchOpen(false);
                              }}
                            >
                              <div className="pharma_info">
                                <i>
                                  {!!item?.logo ? (
                                    <Avatar
                                      src={item?.logo}
                                      alt={item?.title}
                                    />
                                  ) : (
                                    <Avatar>{item?.title?.charAt(0)}</Avatar>
                                  )}
                                </i>
                                <p>{item?.title}</p>
                              </div>
                            </li>
                          )
                      )}
                  </ul>
                </div>
              </div>
            )}
            trigger="click"
            showArrow={false}
            placement="right"
          >
            <div
              className="d-flex justify-content-between align-items-center"
              // onClick={() => setopenPopover(true)}
            >
              <p className="mb-0 ant_sub_menu">Branch</p>
              <img src={ChevornRight} alt="right" />
            </div>
          </Popover>
        </li>
        <li>
          <Popover
            open={leaveOpen}
            onOpenChange={(v) => setLeaveOpen(v)}
            content={() => (
              <div className="leave_dropdown">
                <div className="dropdown_input">
                  <Form>
                    <Form.Item>
                      <Input
                        id="form"
                        placeholder="Search"
                        type="text"
                        value={inputvalue}
                        onChange={handleInputValue}
                        prefix={<img src={SearchIcon} alt="search" />}
                      />
                    </Form.Item>
                  </Form>
                </div>
                <div className="dropdownItems">
                  <ul className="pharma_options_wrapper">
                    {absenceType
                      ?.filter((item) =>
                        item?.label
                          ?.toLowerCase()
                          ?.includes(inputvalue?.toLowerCase())
                      )
                      .map((item) => (
                        <li
                          className={`d-flex align-items-center cursor-pointer justify-content-between ${
                            leaveType === item?.value ? "activeLi" : ""
                          }`}
                          key={item?.id}
                          onClick={() => {
                            handleSelect("leaveType", item?.value);
                            setLeaveOpen(false);
                          }}
                        >
                          <div className="pharma_info">
                            <i>{item.icon}</i>

                            <p>{item?.label}</p>
                          </div>

                          {leaveType === item?.value && (
                            <Button
                              icon={<DeleteIcon height="18" width="18" />}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleSelect("deleteLeave", "");
                                setLeaveOpen(false);
                              }}
                              style={{
                                background: "transparent",
                                border: "none",
                              }}
                            />
                          )}
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            )}
            trigger="click"
            showArrow={false}
            placement="right"
          >
            <div
              className="d-flex justify-content-between align-items-center"
              // onClick={() => setopenPopover(true)}
            >
              <p className="mb-0 ant_sub_menu">Leaves</p>
              <img src={ChevornRight} alt="right" />
            </div>
          </Popover>
        </li>
      </ul>
    </>
  );
};

const PharmacyDetails = ({ isInEditMode, index }) => {
  const { staffTimeSheetEdited } = useSelector((s) => s.staffs);
  const { branches } = useSelector((state) => state.branches);
  const [open, setOpen] = useState(false);

  const shiftDetails = useMemo(() => {
    const details = staffTimeSheetEdited?.[index];
    return details;
  }, [index, staffTimeSheetEdited]);

  const isEmpty = !shiftDetails?.branchId;

  const branchInfo = useMemo(() => {
    if (shiftDetails?.branchId) {
      const _branch = branches?.rows?.find(
        (item) => item?.id === staffTimeSheetEdited[index]?.branchId
      );

      return {
        name: _branch?.title,
        logo: _branch?.logo,
      };
    }
    return {
      name: shiftDetails?.branch?.title,
      logo: shiftDetails?.branch?.logo,
    };
  }, [
    shiftDetails?.branchId,
    shiftDetails?.branch?.title,
    shiftDetails?.branch?.logo,
    branches?.rows,
    staffTimeSheetEdited,
    index,
  ]);

  const handlePopOpen = (v) => setOpen(v);

  const isFutureDate = useMemo(() => {
    return moment(shiftDetails?.startDate)
      ?.endOf("date")
      .isAfter(moment()?.endOf("date"));
  }, [shiftDetails]);

  if (isEmpty && !isInEditMode) {
    return <div />;
  }

  if (isInEditMode && !isFutureDate) {
    return (
      <div className={isInEditMode ? "edit_td" : ""}>
        <Popover
          title=""
          placement="bottom"
          open={open}
          showArrow={false}
          content={() => (
            <PopoverContent
              open={open}
              handlePopOpen={handlePopOpen}
              shiftDetails={shiftDetails}
            />
          )}
          trigger="click"
          className="presence_dropdown"
          onOpenChange={handlePopOpen}
        >
          <div className="pharmacy_details_dropdown d-flex align-items-end  cursor-pointer">
            <figure>
              {!!branchInfo.logo ? (
                <Avatar src={branchInfo.logo} alt="" />
              ) : !!branchInfo.name ? (
                <Avatar>{!!branchInfo.name && branchInfo.name[0]}</Avatar>
              ) : null}
            </figure>

            <p className="mx-2">{branchInfo.name}</p>
            {isInEditMode && (
              <button
                className="ms-auto"
                style={{
                  padding: 0,
                  margin: 0,
                  border: "none",
                  background: "none",
                }}
              >
                {" "}
                <i>
                  <DropdownIcon />
                </i>
              </button>
            )}
          </div>
        </Popover>
      </div>
    );
  }

  return (
    <div>
      <div className="pharmacy_details_dropdown d-flex align-items-end">
        <figure>
          {!!branchInfo.logo ? (
            <Avatar src={branchInfo.logo} alt="" />
          ) : !!branchInfo.name ? (
            <Avatar>{!!branchInfo.name && branchInfo.name[0]}</Avatar>
          ) : null}
        </figure>

        <p className="mx-2">{branchInfo.name}</p>
      </div>
    </div>
  );
};

export default PharmacyDetails;
