import { Form, Input, Radio } from "antd";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { EmptyData } from "../../../../../components/comon/EmptyData";
import { AddModal } from "../../../../../components/UI/modals/addModal";
import { TableComponet } from "../../../../../components/UI/table";
import {
  deleteAccommodationAndFoodRulesThunk,
  saveAccommodationAndFoodRulesThunk,
} from "../../../../../redux/profile/profileThunk";
import { settingsApiKeys } from "../../../../../utils/apiKeyConstants";
import { hasAccess, notify } from "../../../../../utils/helper";

export const AccommodationAndFoodRules = () => {
  const formId = useMemo(() => "form", []);
  const [form] = Form.useForm();
  const { accommodationAndFoodRules } = useSelector((state) => state.profile);
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const accommodationAndFoodRulesColumn = useMemo(
    () => [
      {
        Header: "Rule Name",
        accessor: "name",
      },

      {
        Header: "Food Coverage",
        accessor: "foodCoverage",
        Cell: ({ row: { original } }) => {
          return original.foodCoverage ? "Yes" : "NO";
        },
      },

      {
        Header: "Pay Over (Pence)",
        accessor: "payOver",
      },
      {
        Header: "Up to (Pence)",
        accessor: "upTo",
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row: { original } }) => {
          return (
            <div>
              <Dropdown icon="ellipsis vertical" pointing="top right">
                <Dropdown.Menu direction="left">
                  <Dropdown.Item
                    text="Edit"
                    onClick={() => {
                      form.setFieldsValue({
                        name: original.name,
                        foodCoverage: original.foodCoverage,
                        payOver: original.payOver,
                        upTo: original.upTo,
                        id: original.id,
                      });
                      setShowModal(!showModal);
                    }}
                  />
                  <Dropdown.Item
                    style={{ color: "#EF5350" }}
                    onClick={() => {
                      if (hasAccess(settingsApiKeys.DELETE_FOOD_RULES))
                        onDeleteRule(original.id);
                    }}
                    text="Delete"
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    []
  );

  const onSubmit = (values) => {
    dispatch(saveAccommodationAndFoodRulesThunk(values)).then((res) => {
      if (res.payload) {
        notify("success", "Accommodation & Food Rule Saved!");
        setShowModal(false);
        form.resetFields();
      }
    });
  };

  const onDeleteRule = (id) => {
    dispatch(deleteAccommodationAndFoodRulesThunk({ id })).then((res) => {
      if (res.payload.status === "success") {
        notify("success", "Accommodation & Food Rule Deleted!");
      }
    });
  };

  return (
    <div className="border rounded">
      <div className="text-row d-flex align-items-center justify-content-between">
        <div className="s1 m-0">Accommodation & Food</div>
        <div
          className="cursor-pointer"
          onClick={() => setShowModal(true)}
          style={{ color: "#51C2BC" }}
        >
          ADD RULE
        </div>
      </div>
      {accommodationAndFoodRules?.length <= 0 ? (
        <EmptyData title="Accommodation Rules" />
      ) : (
        <TableComponet
          rowCursor="pointer"
          isBorderShown
          columns={accommodationAndFoodRulesColumn}
          data={accommodationAndFoodRules}
        />
      )}
      <AddModal
        title="Accommodation & Food"
        handleModal={showModal}
        setHandleModal={setShowModal}
        primaryButtonText="Save"
        formId={formId}
      >
        <Form
          form={form}
          onFinish={onSubmit}
          id={formId}
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            label="Rule Name"
            name="name"
            rules={[{ required: true, message: "Please Enter Rule Name!" }]}
          >
            <Input
              maxLength={50}
              onBlur={(e) =>
                form.setFieldsValue({ name: e.target.value.trim() })
              }
              placeholder="Rule Name"
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>
          <Form.Item
            label="Food Coverage"
            name="foodCoverage"
            rules={[{ required: true, message: "Please Select Option!" }]}
          >
            <Radio.Group className="mb-3">
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Pay Over (Pence)"
            rules={[
              { required: true, message: "Please Enter Pay Over Amount!" },
              { min: 0, message: "Minimum Character limit is 5!" },
              () => ({
                validator(rule, value) {
                  if (value >= 100) {
                    return Promise.reject("Pay Over should be less than 100"); // The validator should always return a promise on both success and error
                  } else if (value < 0) {
                    return Promise.reject("Pay Over can not be less than 0"); // The validator should always return a promise on both success and error
                  }
                  return Promise.resolve(value);
                },
              }),
            ]}
            name="payOver"
          >
            <Input
              type="number"
              placeholder="Pay Over"
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>

          <Form.Item
            label="Up To (Pence)"
            name="upTo"
            rules={[
              { required: true, message: "Please Enter Up to Amount!" },
              () => ({
                validator(rule, value) {
                  if (value >= 100) {
                    return Promise.reject("Up To should be less than 100"); // The validator should always return a promise on both success and error
                  } else if (value < 0) {
                    return Promise.reject("Up To can not be less than 0"); // The validator should always return a promise on both success and error
                  }
                  return Promise.resolve(value);
                },
              }),
            ]}
          >
            <Input
              type="number"
              placeholder="Up To"
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>
          <Form.Item hidden label="id" name="id">
            <Input
              placeholder="id"
              className="text-input-field p-2 pe-3 t1"
            />
          </Form.Item>
        </Form>
      </AddModal>
    </div>
  );
};
