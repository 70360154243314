import { DatePicker, Form, Input, Select } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import infoIcon from "../../../../../../../assets/icons/infoIcon1.png";
import { AddModal } from "../../../../../../../components/UI/modals/addModal";
import { ConfirmModal } from "../../../../../../../components/UI/modals/confirmModal";
import { addStaffAbsenceThunk } from "../../../../../../../redux/branches/branchesThunk";
import { branchApiKeys } from "../../../../../../../utils/apiKeyConstants";
import { absenceType } from "../../../../../../../utils/constant";
import { hasAccess, notify } from "../../../../../../../utils/helper";

export const AddAbsenceForm = ({
  addAbsenceModal,
  setAddAbsenceModal,
  setFlag,
  hideEmployee,
  employeeId,
}) => {
  const dispatch = useDispatch();
  const { staffs } = useSelector((state) => state.schedule);
  const formId = useMemo(() => "form", []);
  const [form] = Form.useForm();
  const { user } = useSelector((state) => state.auth);
  const startDate = Form.useWatch("absence_from", form);
  const [validtionModal, setValidtionModal] = useState(false);
  const [errorData, setErrorData] = useState();
  const onFininsh = (values) => {
    let startDate = values.absence_from;
    let endDate = values.absence_to;
    const absences = [];

    while (
      moment(startDate.format("YYYY-MM-DD")).isSameOrBefore(
        endDate.format("YYYY-MM-DD")
      )
    ) {
      const data = {
        absence_from: startDate.format("YYYY-MM-DD"),
        absence_to: startDate.format("YYYY-MM-DD"),
        absence_type: values.absence_type,
        absence_note: values.absence_note,
        employeeId: hideEmployee ? Number(employeeId) : values.employeeId,
        timeStart: null,
        timeFinish: null,
        requestedById: user?.id,
        calculatedTimeMS: 0,
      };
      absences.push(data);
      startDate = startDate.add(1, "day");
    }

    dispatch(addStaffAbsenceThunk(absences)).then((res) => {
      if (res.payload.code === "validation") {
        setValidtionModal(true);
        setErrorData(res.payload);
        form.resetFields();
        return;
      }
      if (res.payload.message === "success") {
        notify("success", "Absence Added");
        setAddAbsenceModal(false);
        setFlag(true);
        form.resetFields();
      }
    });
  };

  return (
    <>
      <ConfirmModal
        backdrop="static"
        title="Hold up!"
        open={validtionModal}
        confirmButtonText="Close"
        image={infoIcon}
        onConfirm={() => {
          setValidtionModal(false);
          setFlag(true);
          setAddAbsenceModal(false);
        }}
      >
        <div className="d-flex flex-column gap-3">
          <div>
            There seem to be a few conflicts within this Leaves attempt.
            However, we've marked leaves for dates prior to and following the
            date(s) of conflict.
          </div>
        </div>
        <div className="d-flex flex-column gap-3 align-items-center">
          {errorData?.array?.map((data, idx) => (
            <div className="d-flex align-items-center gap-2" key={idx}>
              <div style={{ whiteSpace: "nowrap" }}>{data.date}</div>
              <div style={{ border: "1px solid", height: 20 }} />
              <div>{data.day[0].toUpperCase() + data.day.slice(1)}</div>
              <div style={{ border: "1px solid", height: 20 }} />
              <div>{data.reason}</div>
            </div>
          ))}
        </div>
        {errorData?.successCount > 0 && (
          <div>{errorData?.successCount} Leaves Were Added Successfully</div>
        )}
      </ConfirmModal>
      <AddModal
        title={hasAccess(branchApiKeys.ADD_BRANCH_ABSENCE) ? "Add Absence" : ""}
        formId={formId}
        handleModal={addAbsenceModal}
        setHandleModal={setAddAbsenceModal}
        primaryButtonText={"Add Absence"}
        secondaryButtonText="Cancel"
        secondaryButtonHandler={() => {
          setAddAbsenceModal(false);
        }}
      >
        <Form
          onFinish={onFininsh}
          form={form}
          id={formId}
          style={{ width: "100%" }}
          layout="vertical"
          autoComplete="off"
        >
          <div
            className="d-flex align-items-center gap-4"
            style={{ color: "#7B7B7B", width: "100%" }}
          >
            <Form.Item
              label="Start Date"
              name="absence_from"
              rules={[
                {
                  required: true,
                  message: "Please Select Start Date!",
                },
              ]}
            >
              <DatePicker
                format="DD-MM-YYYY"
                placeholder="Start Date"
                getPopupContainer={(trigger) => trigger.parentElement}
                className="text-input-field p-2 pe-3 t1"
                style={{ width: "100%" }}
                inputReadOnly
                onChange={(val) => {
                  form.setFieldsValue({
                    absence_to: val,
                  });
                }}
                // disabledDate={(current) => {
                //   return dayjs(startDate) >= current;
                // }}
              />
            </Form.Item>
            <Form.Item
              label="End Date"
              name="absence_to"
              rules={[
                {
                  required: true,
                  message: "Please Select End Date!",
                },
              ]}
            >
              <DatePicker
                placeholder="End Date"
                format="DD-MM-YYYY"
                inputReadOnly
                disabledDate={(current) => {
                  return dayjs(startDate).add(-1, "day") >= current;
                }}
                getPopupContainer={(trigger) => trigger.parentElement}
                className="text-input-field p-2 pe-3 t1"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </div>
          <Form.Item
            label="Absence Type"
            name="absence_type"
            rules={[
              {
                required: true,
                message: "Please Select Absence Type!",
              },
            ]}
          >
            <Select
              className="text-input-field p-1 pe-3 t1"
              bordered={false}
              options={absenceType}
              dropdownStyle={{ zIndex: 2000 }}
              placeholder="Absence Type"
            />
          </Form.Item>
          <Form.Item
            label="Employee"
            name="employeeId"
            hidden={hideEmployee ? true : false}
            rules={[
              hideEmployee
                ? {}
                : {
                    required: true,
                    message: "Please Select Employee!",
                  },
            ]}
          >
            <Select
              className="text-input-field p-1 pe-3 t1"
              bordered={false}
              showSearch
              optionFilterProp="label"
              dropdownStyle={{ zIndex: 2000 }}
              placeholder="Select Employee"
            >
              {staffs.map((data, idx) => (
                <Select.Option key={idx} value={data.id}>
                  {data.name} {data.surname}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Note" name="absence_note">
            <Input.TextArea
              rows={5}
              maxLength={256}
              onBlur={(e) =>
                form.setFieldsValue({ absence_note: e.target.value.trim() })
              }
              className="text-input-field p-2 t1"
              placeholder="Note"
            />
          </Form.Item>
        </Form>
      </AddModal>
    </>
  );
};
